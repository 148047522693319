import { FontTypes } from './Fonts.types';

export const BaseFonts = `
  /* cyrillic-ext */
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

  .material-icons {
    font-family: 'Material Icons';
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
    /* Support for IE. */
    font-feature-settings: 'liga';
  }
`;

export const Fonts: Record<FontTypes, string> = {
  headlineLarge: `
    font-family: Capisce-Display;
    font-size: 48px;
    line-height: 48px;
  `,
  headline: `
    font-family: Capisce-Display;
    font-size: 24px;
    line-height: 28px;
  `,
  subheadSerif: `
    font-family: Capisce-Display;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
  `,
  subhead: `
    font-family: Inter;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
  `,
  subheadMedium: `
    font-family: Inter;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
  `,
  subheadSmall: `
    font-family: Inter;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  `,
  capsHeading: `
    font-family: Inter;
    font-weight: Bold;
    text-transform: Uppercase;
    letter-spacing: 1px;
    font-size: 12px;
    line-height: 16px;
  `,
  bodyLarge: `
    font-family: Inter;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  `,
  body: `
    font-family: Inter;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  `,
  bodySmall: `
    font-family: Inter;
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
  `,
  dividerTitle: `
    font-family: Inter;
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 1px;
  `,
  propertyLabel: `
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0px;
  `,
  link: `
    font-family: Inter;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
  `,
  linkSecondary: `
    font-family: Inter;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    // NOTE: Color variables not resolving
    // Colors.grey_blue
    color: #1D1E2E;
  `,
  linkSmall: `
    font-family: Inter;
    font-size: 12px;
    line-height: 20px;
    white-space: nowrap;
    // NOTE: Color variables not resolving
    // Colors.accent
    color: #1515FF;
  `,
  code: `
    font-family: 'Source Code Pro';
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  `,
  caption: `
    font-family: Inter;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
  `,
  success: `
    font-family: Inter;
    font-size: 12px;
    font-weight: bold;
    line-height: 2px;
    // NOTE: Color variables not resolving
    // Colors.success
    color: #45DB81;
  `,
  error: `
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    // NOTE: Color variables not resolving
    // Colors.error
    color: #D30D28;
  `,
};

import styled from 'styled-components';
import { Colors } from '../../Foundation/Colors';
import {
  GLOBAL_NAV_DEPTH,
  NEW_GLOBAL_NAV_WIDTH,
  TRANSITION_TIME,
} from '../../Foundation/Spacing';

export const GlobalNavigationStyleWrapper = styled.div`
  height: 100%;
  min-height: 476px;
  position: fixed;
  z-index: ${GLOBAL_NAV_DEPTH};
  width: ${NEW_GLOBAL_NAV_WIDTH};
  background-color: ${Colors.grey_blue};
`;

export const GlobalNavigationHeader = styled.div`
  width: 100%;
  padding: 26px 0;
  background-color: ${Colors.background_dark};

  svg {
    fill: ${Colors.grey_blue_2};
    transition: fill ${TRANSITION_TIME};
    margin: 0 16px;
    width: 32px;
    height: 32px;
  }

  a.active > svg,
  svg:hover {
    fill: ${Colors.white};
  }

  .MuiAvatar-root {
    margin: 22px 22px 0;
    cursor: pointer;
  }
`;

export const GlobalNavigationFooter = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;

  > div {
    width: 100%;
  }
`;

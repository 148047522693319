import React from 'react';

import { TextCounterTypes } from './TextCounter.types';
import { StyledTextCounter } from './TextCounter.style';

export const TextCounter = ({
  currentLength,
  maxLength,
}: TextCounterTypes): JSX.Element => {
  const valueString = `${currentLength}/${maxLength}`;
  const className = currentLength > maxLength ? 'error' : 'primary';
  return (
    <StyledTextCounter className={className}>{valueString}</StyledTextCounter>
  );
};

import { Colors } from '../../../Foundation/Colors';

export const richTextBasicBlockModel = (id: string) => ({
  id,
  type: 'Text',
  label: 'Text',
  properties: [
    {
      name: 'raw',
      label: 'raw',
      control: 'none',
      value: null,
      __typename: 'ComponentProperty',
    },
    {
      name: 'text',
      label: 'Styles',
      control: 'TitleDivider',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'textColor',
      label: 'Text',
      control: 'ColorPicker',
      value: Colors.black,
      __typename: 'ComponentProperty',
    },
    {
      name: 'backgroundColor',
      label: 'Background Colour',
      control: 'ColorPicker',
      value: Colors.white,
      __typename: 'ComponentProperty',
    },
    {
      name: 'alignment',
      label: 'Text Alignment',
      control: 'TextAlignmentSelector',
      value: 'left',
      __typename: 'ComponentProperty',
    },
    {
      name: 'padding',
      label: 'Padding',
      control: 'TitleDividerNoLine',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'paddingTop',
      label: 'Top',
      control: 'Slider',
      value: '20',
      __typename: 'ComponentProperty',
    },
    {
      name: 'paddingBottom',
      label: 'Bottom',
      control: 'Slider',
      value: '20',
      __typename: 'ComponentProperty',
    },
  ],
  __typename: 'Component',
});

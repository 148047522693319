import React from 'react';

import { DragDropContext } from 'react-beautiful-dnd';
import { DragAndDropProvider } from '../Context';
import { DroppableTargetItems } from '../DroppableTargetItems';
import { DroppableTargetItemsWrapperProps } from './DroppableTargetItemsWrapper.types';

export const DroppableTargetItemsWrapper = (
  props: DroppableTargetItemsWrapperProps
) => {
  const {
    activeChannel = '',
    saveTemplate = () => ({}),
    localDraft = {},
    ...otherProps
  } = props;

  return (
    <DragAndDropProvider>
      <DragDropContext>
        <DroppableTargetItems
          activeChannel={activeChannel}
          saveTemplate={saveTemplate}
          localDraft={localDraft}
          {...otherProps}
        />
      </DragDropContext>
    </DragAndDropProvider>
  );
};

DroppableTargetItemsWrapper.displayName = 'DroppableTargetItemsWrapper';

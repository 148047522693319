import React, { useState } from 'react';
import { v4 as uuid } from 'uuid';
import { Button } from '../../Atoms/Navigations';
import { Modal } from '../../Atoms/Dialogs';
import { ToneOfVoiceBetaHeader } from '../ToneOfVoiceBetaHeader';
import { Chip } from '../../Atoms/DataDisplay/Chip';
import { TextArea } from '../../Atoms/Forms';
import { Illustrations } from '../../Foundation/Illustrations';
import {
  HeadingContainer,
  StyledAgreement,
  StyledBody,
  StyledDescription,
  StyledFooter,
  ToneFeedbackButtonWrapper,
} from './ToneFeedbackModal.style';
import { ToneFeedbackModalType } from './ToneFeedbackModal.types';

export const ToneFeedbackModal = ({
  missingTones,
  onLeaveFeedback,
  handleExit,
  isOpen,
}: ToneFeedbackModalType): JSX.Element => {
  const [feedbackText, setFeedbackText] = useState('');
  const [selectedTones, setSelectedTones] = useState<Array<string>>([]);
  const [thankyouOpen, setThankyouOpen] = useState(false);

  const handleToneClicked = (tone: string) => {
    if (selectedTones.includes(tone)) {
      setSelectedTones(selectedTones.filter((el) => el !== tone));
    } else {
      setSelectedTones([...selectedTones, tone]);
    }
  };

  const handleLeaveFeedbackClicked = () => {
    setThankyouOpen(true);
    onLeaveFeedback(selectedTones, feedbackText);
  };

  const missingAndSelectedTones = missingTones.map((tone) => ({
    tone,
    isSelected: selectedTones.includes(tone),
  }));

  return (
    <Modal
      size='standard'
      width={thankyouOpen ? '480px' : '680px'}
      isOpen={isOpen}
      handleExit={handleExit}
      headerContent={
        !thankyouOpen ? (
          <HeadingContainer>
            <ToneOfVoiceBetaHeader minimal />
            <div className='tov-feedback-heading'>Feedback</div>
          </HeadingContainer>
        ) : (
          <StyledAgreement>
            <Illustrations.Agreement />
          </StyledAgreement>
        )
      }
      bodyContent={
        !thankyouOpen ? (
          <StyledDescription>
            <span className='tov-feedback-body-label'>
              1. Are there any tones missing?
            </span>
            <div>
              {missingAndSelectedTones.length &&
                missingAndSelectedTones.map(({ tone, isSelected }) => (
                  <Chip
                    key={uuid()}
                    text={tone}
                    size='medium'
                    onClick={() => handleToneClicked(tone)}
                    color={isSelected ? 'accent' : undefined}
                  />
                ))}
            </div>
            <span className='tov-feedback-body-label'>
              2. Do you have any comments on what we can improve?
            </span>
            <div className='tov-feedback-text-area'>
              <TextArea
                variant='filled'
                resize='none'
                rowsMax={2}
                height='64px'
                onChange={setFeedbackText}
              />
            </div>
          </StyledDescription>
        ) : (
          <StyledBody>Thanks!</StyledBody>
        )
      }
      footerContent={
        !thankyouOpen ? (
          <ToneFeedbackButtonWrapper>
            <Button
              component='button'
              text='Cancel'
              variant='link'
              onClick={handleExit}
            />
            <Button
              component='button'
              text='Leave Feedback'
              variant='button'
              onClick={handleLeaveFeedbackClicked}
            />
          </ToneFeedbackButtonWrapper>
        ) : (
          <StyledFooter>
            We really appreciate your feedback. If you need more help,
            <br />
            feel free to reach out to our friendly team.
          </StyledFooter>
        )
      }
    />
  );
};

import styled from 'styled-components';
import { Colors } from '../../Foundation/Colors';
import { Fonts } from '../../Foundation/Fonts';

import type { GoogleMapProps } from './GoogleMap.types';

export const MapHeader = styled.div`
  ${Fonts.subheadSmall};
  color: ${Colors.grey_blue};
  padding-bottom: 8px;
`;

export const MapSubHeader = styled.div`
  ${Fonts.body};
  color: ${Colors.grey_blue};
  padding-bottom: 16px;
`;

export const MapStyleWrapper = styled.div<GoogleMapProps>`
  padding: 0px;
  width: 100%;
  height: 100%;
  &.google-map-wrapper {
    padding: ${({ paddingTop, paddingRight, paddingBottom, paddingLeft }) =>
      `${paddingTop}px ${paddingRight}px ${paddingBottom}px ${paddingLeft}px`};
  }
`;

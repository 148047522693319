import styled from 'styled-components';
import { richTextEditorMenuStyles } from '../../DraftailComponents/richTextEditorMenuStyles';
import { Colors } from '../../Foundation/Colors';

import { StyledRichTextFullWidthBannerBlockProps } from './RichTextFullWidthBannerBlock.types';

export const justifyContentPosition = {
  'top-left': 'flex-start',
  'top-middle': 'center',
  'top-right': 'flex-end',
  'middle-left': 'flex-start',
  'middle-middle': 'center',
  'middle-right': 'flex-end',
  'bottom-left': 'flex-start',
  'bottom-middle': 'center',
  'bottom-right': 'flex-end',
};

export const alignItemsPosition = {
  'top-left': 'flex-start',
  'top-middle': 'flex-start',
  'top-right': 'flex-start',
  'middle-left': 'center',
  'middle-middle': 'center',
  'middle-right': 'center',
  'bottom-left': 'flex-end',
  'bottom-middle': 'flex-end',
  'bottom-right': 'flex-end',
};

export const textAlignPosition = {
  'top-left': 'left',
  'top-middle': 'center',
  'top-right': 'right',
  'middle-left': 'left',
  'middle-middle': 'center',
  'middle-right': 'right',
  'bottom-left': 'left',
  'bottom-middle': 'center',
  'bottom-right': 'right',
};

export const TextEditorContainer = styled.div<
  Pick<
    StyledRichTextFullWidthBannerBlockProps,
    | 'marginTop'
    | 'marginBottom'
    | 'height'
    | 'width'
    | 'focus'
    | 'imageSource'
    | 'backgroundColor'
  >
>`
  position: relative;
  margin-top: ${(props) => props.marginTop}px;
  margin-bottom: ${(props) => props.marginBottom}px;
  box-sizing: border-box;
  height: ${(props) => props.height}px;
  width: ${(props) => props.width};
  outline: ${(props) => props.focus && `1px solid ${Colors.accent}`};
  z-index: 0;
  width: 100%;
  :after {
    content: '';
    z-index: -1;
    position: absolute;
    transform: translate(-50%, 0);
    left: 50%;
    top: 0;
    height: ${(props) => props.height}px;
    width: 250%;
    background-color: ${(props) => props.backgroundColor};
    background-image: url(${(props) => props.imageSource});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }

  .variable-selector {
    position: absolute;
    right: 12px;
    bottom: 12px;
  }

  :hover {
    z-index: 1;
    outline: 1px solid ${Colors.accent};
  }
`;

export const HeadingContainer = styled.div<
  Pick<StyledRichTextFullWidthBannerBlockProps, 'textPadding' | 'height'>
>`
  box-sizing: border-box;
  width: 100%;
  margin: auto;
  padding: 0 ${(props) => props.textPadding}px 0
    ${(props) => props.textPadding}px;
`;

export const DraftailEditorWrapper = styled.div<
  Pick<
    StyledRichTextFullWidthBannerBlockProps,
    'height' | 'width' | 'textPosition' | 'textColor'
  >
>`
  ${richTextEditorMenuStyles}
  height: ${(props) => props.height}px;
  width: ${(props) => props.width};
  overflow: hidden;

  .public-DraftEditor-content {
    &:focus {
      cursor: text;
    }
  }

  .Draftail-Editor {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: ${({ textPosition }) =>
      justifyContentPosition[textPosition]};
    align-items: ${({ textPosition }) => alignItemsPosition[textPosition]};
    text-align: ${({ textPosition }) => textAlignPosition[textPosition]};
  }

  .DraftEditor-editorContainer {
    min-width: 200px;
    max-width: 600px;
  }

  .Draftail-block {
    &--unstyled,
    &--header-one,
    &--header-two,
    &--header-three {
      font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
      color: ${(props) => props.textColor};
    }
  }
`;

import { UseLoadScriptOptions } from '@react-google-maps/api/dist/useLoadScript';

export const GOOGLE_MAP_API_KEY = 'AIzaSyCPZPjfQ-L909PPzjTHd9d48_fzSTJ1Rn8';

export const MAP_DEFAULT_LOCATION = {
  lat: -37.816272,
  lng: 144.962327,
};

export const mapLoadScriptConfig: UseLoadScriptOptions = {
  googleMapsApiKey: GOOGLE_MAP_API_KEY,
  libraries: ['places'],
};

import React, { MouseEvent, ReactElement } from 'react';

import TableSortLabel from '@material-ui/core/TableSortLabel';
import moment from 'moment';
import {
  SimpleTableRowProps,
  RowData,
  Cell,
} from '../../Organisms/SimpleTable/SimpleTable.types';

import { Radio, Checkbox } from '../../Atoms/Forms';
import { Icon } from '../../Atoms/Icon';
import { PopoverMenu } from '../PopoverMenu';
import {
  SimpleTableRowWrapper,
  SimpleTableCell,
} from './SimpleTableRow.styles';
import { SideActionProps } from './SimpleTableRow.types';

const handleSortChange = (
  _event: MouseEvent<HTMLSelectElement>,
  rowData: RowData,
  name: string,
  isCurrentlyInAscending: boolean,
  cell: Cell
) => {
  if (rowData.handleSortChange) {
    rowData.cells.forEach((cell) => {
      if (cell.order && cell.sort) {
        cell.order = 'default';
      }
    });
    const newOrder = isCurrentlyInAscending ? 'desc' : 'asc';
    rowData.handleSortChange.call(null, name, newOrder);
    cell.order = newOrder;
  }
};

const getSortingIcon = (cell: Cell) => {
  if (cell.order === 'asc') {
    return (
      <div className='sort-action sort--asc'>
        <Icon icon='UpArrow' />
      </div>
    );
  } else if (cell.order === 'desc') {
    return (
      <div className='sort-action sort--desc'>
        <Icon icon='DownArrow' />
      </div>
    );
  }
  return (
    <div className='sort-action'>
      <Icon icon='SortArrows' />
    </div>
  );
};

type CellProps = {
  rowData: RowData;
  cell: Cell;
};

const HeaderSortCell = ({ rowData, cell }: CellProps) => {
  if (cell.order && cell.sort) {
    const tableSortLabelProps = {
      onClick: (e) =>
        handleSortChange(e, rowData, cell.name, cell.order === 'asc', cell),
      hideSortIcon: true,
    };
    return (
      <TableSortLabel
        {...tableSortLabelProps}
        className={`${cell.order !== 'default' ? 'cell--sort-active' : ''}`}
      >
        <span>{cell.name}</span>
        {getSortingIcon(cell)}
      </TableSortLabel>
    );
  }
  return <span>{cell.name}</span>;
};

const SimpleTableCellWrapper = ({ cell, rowData }: CellProps) => {
  const cellClassName = `cell${cell.ellipsis ? ' ellipsis' : ''}`;
  if (rowData.type === 'header') {
    return (
      <SimpleTableCell key={cell.key}>
        <div className={cellClassName}>
          <HeaderSortCell rowData={rowData} cell={cell} />
        </div>
      </SimpleTableCell>
    );
  }

  const getValue = () => {
    if (cell.isDate) {
      return moment(cell.name).format(cell.dateFormat || 'Do MMM hh:mm');
    }
    return cell.name;
  };

  return (
    <SimpleTableCell key={cell.key}>
      <div className={cellClassName}>
        <span>{cell.child || getValue()}</span>
      </div>
    </SimpleTableCell>
  );
};

const renderCells = (rowData: RowData) =>
  rowData.cells.map((cell: Cell) => (
    <SimpleTableCellWrapper key={cell.key} cell={cell} rowData={rowData} />
  ));

const renderLeftAction = ({
  rowData,
  checkboxSelectedValues,
  radioSelectedValue,
  numberOfRows = 0,
}: SideActionProps) => {
  switch (rowData.leftActionType) {
    case 'radio':
      return (
        <Radio
          name={rowData.group as string}
          value={rowData.value as string}
          checked={radioSelectedValue === rowData.value}
          onChange={(event) =>
            rowData.handleRadioChange &&
            rowData.handleRadioChange(event, rowData)
          }
          size='small'
        />
      );
    case 'checkbox':
      return rowData.type === 'header' ? (
        <Checkbox
          value={rowData.value as string}
          isIndeterminated={
            checkboxSelectedValues.length > 0 &&
            checkboxSelectedValues.length < numberOfRows
          }
          isChecked={checkboxSelectedValues.length === numberOfRows}
          onChange={() =>
            rowData.handleMultiCheck &&
            rowData.handleMultiCheck(
              numberOfRows,
              checkboxSelectedValues.length
            )
          }
          size='small'
        />
      ) : (
        <Checkbox
          value={rowData.value as string}
          isChecked={checkboxSelectedValues.includes(rowData.value)}
          onChange={(event) =>
            rowData.handleCheckboxChange &&
            rowData.handleCheckboxChange(event, rowData, checkboxSelectedValues)
          }
          size='small'
        />
      );
    default:
      return null;
  }
};

const LeftActions = ({
  rowData,
  checkboxSelectedValues,
  radioSelectedValue,
  numberOfRows,
}: SideActionProps) => {
  if (rowData.group || rowData.hasLeftActionCell) {
    if (rowData.type === 'header' && rowData.leftActionType === 'radio') {
      return <SimpleTableCell className='radio-table-cell' />;
    }

    return (
      <SimpleTableCell className='radio-table-cell'>
        <div className='left-render'>
          {renderLeftAction({
            rowData,
            checkboxSelectedValues,
            radioSelectedValue,
            numberOfRows,
          })}
        </div>
      </SimpleTableCell>
    );
  }
  return null;
};

const RightActions = ({ rowData }: SideActionProps) => {
  if (rowData.actionItems || rowData.hasRightActionCell) {
    if (rowData.type === 'header') {
      return <SimpleTableCell className='right-actions-table-cell' />;
    }
    return (
      <SimpleTableCell className='right-actions-table-cell'>
        <div className='right-render'>
          <PopoverMenu
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            linkItems={rowData.actionItems && rowData.actionItems(rowData)}
          >
            <Icon icon='Elipsis' />
          </PopoverMenu>
        </div>
      </SimpleTableCell>
    );
  }
  return null;
};

export const SimpleTableRow = (
  props: SimpleTableRowProps
): ReactElement | null => {
  const {
    rowData,
    activeRowValue,
    checkboxSelectedValues,
    radioSelectedValue,
    numberOfRows,
  } = props;
  if (rowData) {
    const { value, handleRowClick } = rowData;
    const active = value && value === activeRowValue ? 'active' : '';

    return (
      <SimpleTableRowWrapper
        className={`${rowData.type || 'row'} ${active}`}
        onClick={(event) => handleRowClick && handleRowClick(rowData, event)}
      >
        <LeftActions
          rowData={rowData}
          checkboxSelectedValues={checkboxSelectedValues}
          radioSelectedValue={radioSelectedValue}
          numberOfRows={numberOfRows}
        />
        {renderCells(rowData)}
        <RightActions
          rowData={rowData}
          checkboxSelectedValues={checkboxSelectedValues}
          radioSelectedValue={radioSelectedValue}
        />
      </SimpleTableRowWrapper>
    );
  }
  return null;
};

import { Colors } from '../../../Foundation/Colors';
import { ModelType } from '../../utilityTypes';

export const richTextFooterBlockModel = (id: string): ModelType => ({
  id,
  type: 'Footer',
  label: 'Footer',
  properties: [
    {
      name: 'text_1',
      label: 'Styles',
      control: 'TitleDivider',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'textColor',
      label: 'Text Colour',
      control: 'ColorPicker',
      value: Colors.grey_blue_4,
      __typename: 'ComponentProperty',
    },
    {
      name: 'backgroundColor',
      label: 'Background Colour',
      control: 'ColorPicker',
      value: Colors.grey_blue_0,
      __typename: 'ComponentProperty',
    },
    {
      name: 'logoOptions',
      label: 'Logo Options',
      control: 'TitleDividerNoLine',
      value: 'Logo Options',
      __typename: 'ComponentProperty',
    },
    {
      name: 'logoLink',
      label: 'Image Source',
      control: 'VariableTextInput',
      value:
        'https://cdn-au.whispir.com/public/resources/31f6ed3d480a67001efb340291a858c2d4.png',
      __typename: 'ComponentProperty',
    },
    {
      name: 'logoWidth',
      label: 'Max Width',
      control: 'Slider480',
      value: '100',
      __typename: 'ComponentProperty',
    },
    {
      name: 'logoAltText',
      label: 'Alt Description',
      control: 'TextFieldFilled',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'padding_8px_2',
      label: '',
      control: 'PaddingControl8px',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'Footer Text',
      label: 'Footer Text',
      control: 'TitleDividerNoLine',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'textContent',
      label: '',
      control: 'VariableTextInput',
      value:
        'Cras montes amet fusce. Turpis quis porta platea! Conubia primis metus, magnis viverra ante quisque. Platea primis litora lorem. Mus quis volutpat sollicitudin aenean euismod sagittis ac.',
      __typename: 'ComponentProperty',
    },
    {
      name: 'padding_8px_3',
      label: '',
      control: 'PaddingControl8px',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'Link Options',
      label: 'Link Options',
      control: 'TitleDividerNoLine',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'linkGenerator',
      label: 'Options Picker',
      control: 'LinkGeneratorWithoutColorPicker',
      value: [
        {
          id: 1,
          text: 'Company Name',
          link: 'Enter a link',
        },
        {
          id: 2,
          text: 'Privacy',
          link: 'Enter a link',
        },
        {
          id: 3,
          text: 'Unsubscribe',
          link: 'Enter a link',
        },
      ],
      __typename: 'ComponentProperty',
    },
    {
      name: 'padding_8px_4',
      label: '',
      control: 'PaddingControl8px',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'text_2',
      label: 'Padding',
      control: 'TitleDividerNoLine',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'paddingTop',
      label: 'Top',
      control: 'Slider',
      value: '20',
      __typename: 'ComponentProperty',
    },
    {
      name: 'paddingBottom',
      label: 'Bottom',
      control: 'Slider',
      value: '0',
      __typename: 'ComponentProperty',
    },
  ],
  __typename: 'Component',
});

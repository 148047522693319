import React, { PropsWithChildren } from 'react';
import * as Utils from '@whispir/utils-js';

const defaultContext = {
  trackIdentity: Utils.trackIdentity,
  trackGroup: Utils.trackGroup,
  trackPage: Utils.trackPage,
  trackEvent: Utils.trackEvent,
  getUserAgent: Utils.getUserAgent,
};

const SegmentContext = React.createContext(defaultContext);

export const SegmentContextProvider = (
  props: PropsWithChildren<Partial<typeof defaultContext>>
) => {
  const {
    trackIdentity = Utils.trackIdentity,
    trackGroup = Utils.trackGroup,
    trackPage = Utils.trackPage,
    trackEvent = Utils.trackEvent,
    getUserAgent = Utils.getUserAgent,
    children = null,
  } = props;

  return (
    <SegmentContext.Provider
      value={{
        trackIdentity,
        trackGroup,
        trackPage,
        trackEvent,
        getUserAgent,
      }}
    >
      {children}
    </SegmentContext.Provider>
  );
};

// Use all of them
export const useSegmentContext = () => {
  const segmentContext = React.useContext(SegmentContext);
  return segmentContext;
};

export const useTrackIdentity = () => {
  const segmentContext = React.useContext(SegmentContext);
  return segmentContext.trackIdentity;
};

export const useTrackGroup = () => {
  const segmentContext = React.useContext(SegmentContext);
  return segmentContext.trackGroup;
};

export const useTrackPage = () => {
  const segmentContext = React.useContext(SegmentContext);
  return segmentContext.trackPage;
};

export const useTrackEvent = () => {
  const segmentContext = React.useContext(SegmentContext);
  return segmentContext.trackEvent;
};

export const useGetUserAgent = () => {
  const segmentContext = React.useContext(SegmentContext);
  return segmentContext.getUserAgent;
};

export const withSegmentContext = (Component) => (props) => {
  const segmentContext = useSegmentContext();
  return <Component {...props} {...segmentContext} />;
};

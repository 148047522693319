export const mapBlockModel = (id: string) => ({
  id,
  type: 'Map',
  label: 'Map',
  properties: [
    {
      name: 'text',
      label: 'Styles',
      control: 'TitleDivider',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'mapHeader',
      label: 'Heading',
      control: 'TextFieldFilled',
      value: 'My Awesome Map',
      __typename: 'ComponentProperty',
    },
    {
      name: 'mapSubHeading',
      label: 'Subheading',
      control: 'TextFieldFilled',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'padding_8px_2',
      label: '',
      control: 'PaddingControl8px',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'locationText',
      label: 'Display Location',
      control: 'GoogleAutoSuggest',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'padding_8px_3',
      label: '',
      control: 'PaddingControl8px',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'zoom',
      label: 'Zoom',
      control: 'Slider',
      value: 80,
      __typename: 'ComponentProperty',
    },
    {
      name: 'paddings',
      label: 'Padding',
      control: 'TitleDividerNoLine',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'paddingTop',
      label: 'Top',
      control: 'Slider',
      value: '20',
      __typename: 'ComponentProperty',
    },
    {
      name: 'paddingBottom',
      label: 'Bottom',
      control: 'Slider',
      value: '20',
      __typename: 'ComponentProperty',
    },
    {
      name: 'paddingLeft',
      label: 'Left',
      control: 'Slider',
      value: '0',
      __typename: 'ComponentProperty',
    },
    {
      name: 'paddingRight',
      label: 'Right',
      control: 'Slider',
      value: '0',
      __typename: 'ComponentProperty',
    },
  ],
  __typename: 'Component',
});

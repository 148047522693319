import React from 'react';

import { StyledAvatar } from './LetterAvatar.styles';
import { LetterAvatarTypes } from './LetterAvatar.types';

export const LetterAvatar = ({
  content,
  ...otherProps
}: LetterAvatarTypes): JSX.Element => {
  const abbreviate = () =>
    content
      .split(/[ -]+/)
      .map((e, i) => (i < 2 ? e.charAt(0) : ''))
      .join('');

  return (
    <StyledAvatar variant='rounded' {...otherProps}>
      {abbreviate()}
    </StyledAvatar>
  );
};

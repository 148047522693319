import styled from 'styled-components';
import { Colors } from '../../Foundation/Colors';
import { Fonts } from '../../Foundation/Fonts';
import { richTextEditorMenuStyles } from '../../DraftailComponents/richTextEditorMenuStyles';
import {
  DraftailEditorWrapperProps,
  CalendarButtonProps,
  CalendarIconWrapperProps,
  StyledCalendarWrapperProps,
  CalendarButtonWrapperProps,
} from './RichTextCalendarBlock.types';

export const StyledCalendarWrapper = styled.div<StyledCalendarWrapperProps>`
  padding-top: ${(props) => props.paddingTop}px;
  padding-bottom: ${(props) => props.paddingBottom}px;
  background-color: ${(props) => props.backgroundColor};
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
`;

export const DraftailEditorWrapper = styled.div<DraftailEditorWrapperProps>`
  padding-left: calc(${(props) => props.paddingLeft}px + 32px);
  padding-right: calc(${(props) => props.paddingRight}px + 32px);
  box-sizing: border-box;
  position: relative;
  ${richTextEditorMenuStyles}
  display: inline-block;
  width: 100%;

  .variable-selector {
    position: absolute;
    right: 12px;
    bottom: 12px;
  }

  .Draftail-block {
    &--unstyled,
    &--header-one,
    &--header-two,
    &--header-three {
      font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
      color: ${(props) => props.textColor};
    }
  }

  .Draftail-Editor {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .public-DraftEditor-content {
    &:focus {
      cursor: text;
    }
  }

  .DraftEditor-editorContainer {
    max-width: 600px;
  }

  .public-DraftEditorPlaceholder-inner {
    color: ${Colors.grey_blue_4};
    font-style: italic;
    position: absolute;
    pointer-events: none;
  }
  transition: all 0.2s ease-in-out;
`;

export const CalendarButtonWrapper = styled.div<CalendarButtonWrapperProps>`
  padding-left: calc(${(props) => props.paddingLeft}px + 32px);
  padding-right: calc(${(props) => props.paddingRight}px + 32px);
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  transition: all 0.2s ease-in-out;
`;

export const CalendarButton = styled.a<CalendarButtonProps>`
  box-sizing: border-box;
  min-width: ${(props) => (props.fullWidth === 'true' ? '100%' : '40px')};
  ${Fonts.body};
  background-color: ${(props) => props.buttonColor};
  color: ${(props) => props.buttonTextColor};
  text-decoration: none;
  border: 1px solid ${(props) => props.buttonBorderColor};
  border-radius: ${(props) => props.borderRadius}px;
  text-align: center;
  word-break: break-word;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 11px;
  transition: all 0.2s ease-in-out;
  align-self: ${({ alignment }) =>
    (alignment === 'left' && 'flex-start') ||
    (alignment === 'center' && 'center') ||
    (alignment === 'right' && 'flex-end')};
`;

export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  img {
    height: 20px;
  }
  padding-right: 5px;
  padding-left: 5px;
`;

export const LabelWrapper = styled.div`
  padding-right: 5px;
  padding-left: 5px;
`;

export const IconWrapper = styled.div<CalendarIconWrapperProps>`
  height: 20px;
  padding-right: 5px;
  padding-left: 5px;

  svg {
    fill: ${(props) => props.iconColor};
  }
`;

import React from 'react';

import { DashboardCard } from '../../Molecules/DashboardCard';
import { DashboardCardListType } from './DashboardCardList.types';
import {
  StyledDashboardCardList,
  StyledListItem,
} from './DashboardCardList.style';

export const DashboardCardList = ({
  items,
}: DashboardCardListType): JSX.Element => {
  return (
    <StyledDashboardCardList>
      {items.map((item) => (
        <StyledListItem key={Math.random()}>
          <DashboardCard {...item} />
        </StyledListItem>
      ))}
    </StyledDashboardCardList>
  );
};

import React, { useState, useEffect } from 'react';

import { constantGenerators } from '@whispir/expression-helper';

import { WrappedComponentProps } from '../../../definitions/operatorAndOperand';
import { TextField } from '../../../../../Atoms/Forms';
import {
  BetweenNumberWrapper,
  AndWrapper,
} from './BetweenNumberComponent.style';

const { generateNumberBetweenConstant } = constantGenerators;

const betweenNumberDefaultValueTransform = (defaultValue) => {
  if (!defaultValue) {
    return {
      greaterThanValue: '0',
      lessThanValue: '0',
    };
  }

  const { context, value = 0 } = defaultValue;

  const newNumberRange = {
    greaterThanValue: value,
    lessThanValue: value,
  };

  if (context) {
    return {
      ...newNumberRange,
      lessThanValue: context.lessThan.value,
    };
  }

  return newNumberRange;
};

export const BetweenNumberComponent = ({
  defaultValue,
  onChange = () => undefined,
  ...rest
}: WrappedComponentProps) => {
  const defaultNumberRange = betweenNumberDefaultValueTransform(defaultValue);
  const [state, setState] = useState(defaultNumberRange);

  useEffect(() => {
    if (state) {
      const { greaterThanValue, lessThanValue } = state;
      const betweenNumberConstant = generateNumberBetweenConstant({
        greaterThanValue,
        lessThanValue,
      });
      onChange(betweenNumberConstant);
    }
  }, [state]);

  const handleGreaterThanChange = (value) => {
    setState({
      ...state,
      greaterThanValue: value,
    });
  };

  const handleLessThanChange = (value) => {
    setState({
      ...state,
      lessThanValue: value,
    });
  };

  // the value passed back by material ui is in the form of string
  const firstTextFieldHasError =
    state && parseInt(state.greaterThanValue) > parseInt(state.lessThanValue);
  const secondTextFieldHasError =
    state && parseInt(state.lessThanValue) < parseInt(state.greaterThanValue);

  return (
    <BetweenNumberWrapper>
      <TextField
        inputProps={{
          min: '0',
        }}
        error={firstTextFieldHasError}
        autoFocus
        type='number'
        defaultValue={state && state.greaterThanValue}
        onChange={handleGreaterThanChange}
        helperText={
          firstTextFieldHasError
            ? `Value must be less than ${state.lessThanValue}`
            : undefined
        }
      />
      <AndWrapper>and</AndWrapper>
      <TextField
        inputProps={{
          min: '0',
        }}
        error={secondTextFieldHasError}
        type='number'
        defaultValue={state && state.lessThanValue}
        onChange={handleLessThanChange}
        helperText={
          secondTextFieldHasError
            ? `Value must be greater than ${state.greaterThanValue}`
            : undefined
        }
      />
    </BetweenNumberWrapper>
  );
};

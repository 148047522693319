import React, { createElement } from 'react';

import { ListItem, ListItemText, ListItemIcon } from '@material-ui/core';

import { Popover } from '../../Atoms/Dialogs';
import { Icons } from '../../Foundation/Icons';
import { PopoverMenuType } from './PopoverMenu.types';
import { StyledPopoverMenu } from './PopoverMenu.styles';

export const PopoverMenu = ({
  menuHeader,
  linkItems = [],
  children,
  anchorOrigin,
  transformOrigin,
  ...otherProps
}: PopoverMenuType): JSX.Element => {
  const LinkItems = (): JSX.Element | null => (
    <StyledPopoverMenu aria-label='secondary navigation'>
      {linkItems &&
        linkItems.map(
          ({ title, iconName, action, isActive }, index): JSX.Element => (
            <ListItem
              button
              selected={isActive}
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              onClick={() => action()}
            >
              {iconName ? (
                <ListItemIcon>
                  {createElement(Icons[iconName]) || null}
                </ListItemIcon>
              ) : null}
              <ListItemText primary={title} />
            </ListItem>
          )
        )}
    </StyledPopoverMenu>
  );

  return (
    <Popover
      content={
        <>
          {menuHeader}
          <LinkItems />
        </>
      }
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      {...otherProps}
    >
      {children}
    </Popover>
  );
};

import React from 'react';

import { SecondaryNavigationTypes } from '../GlobalNavigation.types';
import { Tooltip } from '../../../Atoms/Dialogs/Tooltip';
import { Icons } from '../../../Foundation/Icons';
import { PopoverMenu } from '../../../Molecules/PopoverMenu';
import {
  StyledSecondaryNavigation,
  StyledTooltipContent,
} from './SecondaryNavigation.styles';
import { WorkspaceSelector } from './WorkspaceSelector/WorkspaceSelector';

export const SecondaryNavigation = ({
  hasWorkspaceSelector,
  secondaryLinkItems,
  workspaceLinkItems,
}: SecondaryNavigationTypes): JSX.Element => (
  <PopoverMenu
    menuHeader={
      hasWorkspaceSelector &&
      workspaceLinkItems && (
        <WorkspaceSelector workspaceLinkItems={workspaceLinkItems} />
      )
    }
    linkItems={secondaryLinkItems}
  >
    <Tooltip
      arrow
      ariaLabel='Tooltip'
      placement='right'
      title={<StyledTooltipContent>Account</StyledTooltipContent>}
    >
      <StyledSecondaryNavigation>
        <Icons.AccountCircle />
      </StyledSecondaryNavigation>
    </Tooltip>
  </PopoverMenu>
);

import React, { useState, useCallback } from 'react';

import { MultiValueProps, OptionProps, IndicatorProps } from 'react-select';
import { wrapMenuList } from 'react-select-async-paginate'; // Is anyone else getting an error message here - please let me know
import { Dropdown } from '../../Atoms/Forms';
import { StyledReactSelectChip } from '../../Atoms/Forms/StyledReactSelect/StyledReactSelect';

import {
  StyledAsyncPaginate,
  StyledMenuItem,
  StyledLoadingIndicator,
  StyledIconButton,
  ContactsSelectorWrapper,
  StyledMenuList,
} from './LazyLoadingContactsSelector.styles';
import {
  ContactType,
  LazyLoadingContactsSelectorProps,
} from './LazyLoadingContactsSelector.types';

const searchOptionsMap = {
  firstName: 'First Name',
  lastName: 'Last Name',
};

const emailSearchOptions = {
  ...searchOptionsMap,
  workEmailAddress1: 'Email',
};

const smsSearchOptions = {
  ...searchOptionsMap,
  workMobilePhone1: 'Phone Number',
};

const allSearchOptions = {
  ...searchOptionsMap,
  workMobilePhone1: 'Phone Number',
  workEmailAddress1: 'Email',
};

const getDropdownOptions = (map: Record<string, string>) =>
  Object.entries(map).map(([key, value]) => ({
    label: value,
    value: key,
  }));

const CustomMenuList = wrapMenuList((props) => {
  const { children, isLoading, innerRef } = props;
  return (
    <StyledMenuList ref={innerRef}>
      {children}
      {isLoading && <StyledLoadingIndicator />}
    </StyledMenuList>
  );
});

const menuItem = (mode: string) => (props: OptionProps<ContactType>) => {
  const { data, innerProps, isFocused } = props;
  const { firstName, lastName, workPhoneNumber, workEmail } = data;
  return (
    <StyledMenuItem {...innerProps} selected={isFocused}>
      <span className='name'>
        {firstName} {lastName}{' '}
      </span>
      {mode === 'sms' ? (
        <span className='description'>{workPhoneNumber}</span>
      ) : mode === 'email' ? (
        <span className='description'>{workEmail}</span>
      ) : (
        <span className='description'>
          {workEmail && workEmail} {workPhoneNumber && workPhoneNumber}
        </span>
      )}
    </StyledMenuItem>
  );
};

export const SearchIcon = <T,>(props: IndicatorProps<T>) => {
  const { isFocused } = props;
  return (
    <StyledIconButton
      icon='Search'
      size='small'
      className={isFocused ? 'is-focused' : ''}
    />
  );
};

const Chip = (props: MultiValueProps<ContactType>) => {
  const { removeProps, data } = props;
  const { onClick } = removeProps;
  const { firstName, lastName } = data;
  return (
    <StyledReactSelectChip
      color='accent'
      text={`${firstName} ${lastName}`}
      onDeleteClick={onClick}
    />
  );
};

export const LazyLoadingContactsSelector = (
  props: LazyLoadingContactsSelectorProps
) => {
  const { mode, value, loadMoreFunction, onChange } = props;

  const [selectedSearchKey, setSelectedSearchKey] = useState('firstName');
  const loadOptions = useCallback(
    (search: string, prevOptions: Array<ContactType>) => {
      return loadMoreFunction(search, selectedSearchKey, prevOptions);
    },
    [selectedSearchKey, loadMoreFunction]
  );

  const handleChange = useCallback(
    (values: Array<ContactType>) => {
      if (values === null) {
        onChange([]);
      } else {
        onChange(values);
      }
    },
    [onChange]
  );

  const dropdownOptions = getDropdownOptions(
    mode === 'sms'
      ? smsSearchOptions
      : mode === 'email'
      ? emailSearchOptions
      : allSearchOptions
  );

  return (
    <ContactsSelectorWrapper className='contacts-selector-wrapper'>
      <Dropdown
        variant='filled'
        options={dropdownOptions}
        onChange={setSelectedSearchKey}
        label=''
        value={selectedSearchKey}
        isSearchable={false}
      />
      <StyledAsyncPaginate
        className='contacts-search-input'
        value={value}
        loadOptions={loadOptions}
        isMulti
        getOptionValue={(v) => v.id}
        getOptionLabel={(v) => v.firstName}
        placeholder='Search Available Contacts'
        onChange={handleChange}
        additional={{
          page: 1,
        }}
        components={{
          IndicatorSeparator: null,
          DropdownIndicator: SearchIcon,
          MultiValue: Chip,
          MenuList: CustomMenuList,
          Option: menuItem(mode),
        }}
      />
    </ContactsSelectorWrapper>
  );
};

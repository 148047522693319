import styled from 'styled-components';
import { Fonts } from '../../Foundation/Fonts';
import { Colors } from '../../Foundation/Colors';
import { TextField } from '../../Atoms/Forms/TextField';

export const StyledUrlTextField = styled(TextField)`
  && {
    .url-text-adornment {
      ${Fonts.link};
      line-height: 20px;
      height: 100%;
      padding-top: 12px;
      padding-bottom: 12px;
      color: ${Colors.grey_blue_4};

      & ~ input {
        padding-left: 0px;
      }
    }
  }
`;

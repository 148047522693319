import styled from 'styled-components';
import { Colors } from '../../Foundation/Colors';
import { Fonts } from '../../Foundation/Fonts';
import {
  fieldWrapperStyles,
  fieldHorizontalSpacing,
  fieldVerticalSpacing,
} from '../../Foundation/GlobalStyles';

export const OptionsContainer = styled.div`
  margin-top: -6px;
  > div:first-child {
    min-width: 330px;
  }

  &.button-picker {
    .text-field-wrapper {
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

export const AddAnotherBtn = styled.button`
  display: flex;
  padding: 0 0 8px 2px;
  border: none;
  align-items: center;
  background-color: transparent;
  cursor: pointer;
  background: transparent;
  align-items: flex-end;
  svg {
    padding-right: 4px;
    fill: ${Colors.accent};
  }
  p {
    ${Fonts.bodySmall};
    margin: 8px 0 0;
    color: ${Colors.accent};
  }
`;

export const ComponentBox = styled.div<{
  filledTextAreaStyle?: boolean;
  isExtension?: boolean;
}>`
  display: flex;
  align-items: center;
  margin: 0;
  height: ${({ filledTextAreaStyle, isExtension }) =>
    isExtension ? 'auto' : filledTextAreaStyle ? '120px' : '95px'};
  padding: 0 0 16px;
  background: ${Colors.white};
  position: relative;
`;

export const DragHandleWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 16px 0 0;
  fill: ${Colors.grey_blue_3};
`;

export const ComponentBoxProperties = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  .text-field-form-control {
    label {
      text-align: left;
    }
  }

  ${fieldWrapperStyles}

  > div:first-child.text-field-form-control {
    padding: 0;
  }
`;

export const DefaultOptionsWrapper = styled.div<{
  disableDeleteButton?: boolean;
  isExtension?: boolean;
}>`
  align-items: center;
  justify-content: ${(props) =>
    props.isExtension ? 'flex-end' : 'space-between'};
  display: flex;
  margin: ${(props) =>
    !props.isExtension
      ? `0px ${fieldHorizontalSpacing}px 0px ${fieldHorizontalSpacing}px`
      : `${fieldVerticalSpacing}px ${fieldHorizontalSpacing}px`};

  > label.checkbox-control-form {
    width: auto;
    text-align: left;
  }

  > div.label.span:last-child {
    padding: 24px 0;
  }

  .MuiRadio-colorSecondary.Mui-checked {
    color: ${Colors.accent};
  }

  .options-picker__delete-btn {
    fill: ${(props) =>
      props.disableDeleteButton ? Colors.grey_blue_2 : Colors.grey_blue_3};
    pointer-events: ${(props) => props.disableDeleteButton && 'none'};

    :hover {
      cursor: pointer;
      fill: ${Colors.accent};
    }
  }

  .options-picker__delete-btn {
    width: 24px;
    height: 24px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .MuiFormControlLabel-root {
    .MuiButtonBase-root {
      width: 16px;
      height: 16px;
    }
  }

  .MuiFormControlLabel-label {
    ${Fonts.link};
    line-height: 22px;
    margin: 0px 8px;
  }
`;

export const StyledSubHeader = styled.div`
  ${Fonts.link};
  margin: 15px 15px 0px 15px;
`;

import styled from 'styled-components';
import { Fonts } from '../../Foundation/Fonts';

import {
  StyledButtonGroupWrapperProps,
  StyledLinkProps,
} from './RichTextButtonGroupBlock.types';

export const StyledButtonGroupWrapper = styled.div<StyledButtonGroupWrapperProps>`
  display: flex;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: ${(props) =>
    props.vertStacked === 'true' ? 'column' : 'row'};
  align-items: ${(props) => props.vertStacked === 'true' && 'center'};
  padding-top: ${(props) => props.paddingTop}px;
  padding-bottom: ${(props) => props.paddingBottom}px;
  background-color: ${(props) => props.backgroundColor};
`;

export const StyledLink = styled.a<StyledLinkProps>`
  width: ${(props) => props.fixedWidth}px;
  margin: ${(props) => props.buttonMargin}px;
  ${Fonts.body};
  background-color: ${(props) => props.background};
  color: ${(props) => props.textColor};
  text-decoration: none;
  border-radius: ${(props) => props.borderRadius}px;
  text-align: center;
  word-break: break-word;
  transition: all 0.2s ease-in-out;
  padding: 10px 24px;
`;

import React, { useState, useEffect } from 'react';
import { Icon } from '../../Atoms/Icon';
import { NumberCircle } from '../../Atoms/NumberCircle';
import { Button } from '../../Atoms/Navigations';
import {
  NumberCircleWrapper,
  ScoreTextWrapper,
  ScoreTitleWrapper,
  ToneDetectorScoreWrapper,
} from './ToneDetectorScore.styles';
import { ToneDetectorScoreProps } from './ToneDetectorScore.types';

export const ToneDetectorScore = ({
  recalculateScore,
  canRecalculate,
  score,
}: ToneDetectorScoreProps): JSX.Element => (
  <ToneDetectorScoreWrapper>
    <ScoreTextWrapper>
      <ScoreTitleWrapper>
        <Icon icon='SmartReview' />
        <span>SCORE</span>
      </ScoreTitleWrapper>
      <span className='ScoreText'>
        This score represents how well your message text reflects your desired
        Tone of Voice.
      </span>
      <div>
        <Button
          disabled={!canRecalculate}
          text='Recalculate'
          variant='link'
          onClick={() => {
            if (recalculateScore) {
              recalculateScore();
            }
          }}
        />
      </div>
    </ScoreTextWrapper>
    <NumberCircleWrapper>
      <NumberCircle number={score} svgSize={62} />
    </NumberCircleWrapper>
  </ToneDetectorScoreWrapper>
);

import React from 'react';

type DebugPanelProps = {
  item: unknown;
  header?: string;
  paragraph?: string;
  showCopyButton?: boolean;
};

export const DebugPanel = (props: DebugPanelProps) => {
  const { item, header, paragraph, showCopyButton } = props;

  const itemToRender =
    typeof item === 'string' ? item : JSON.stringify(item, null, 2);
  const handleCopyClick = () => {
    navigator.clipboard.writeText(itemToRender);
  };

  return (
    <div>
      <h1> {header}</h1>
      <p>{paragraph}</p>
      {showCopyButton && (
        <button type='button' onClick={handleCopyClick}>
          copy
        </button>
      )}
      <pre>
        {typeof item === 'string' && '(string)'}
        {itemToRender}
      </pre>
    </div>
  );
};

import styled from 'styled-components';
import { Colors } from '../../Foundation/Colors';
import { Fonts } from '../../Foundation/Fonts';

export const EmptyTemplateWrapper = styled.div`
  text-align: center;
  padding-top: 110px;

  .title {
    display: block;
    margin: 24px 0 0 0;
    border-bottom: none;
    ${Fonts.headline};
  }

  .description {
    color: ${Colors.grey_blue_4};
    display: block;
    margin: 8px 0px 0px 0px;
    ${Fonts.bodySmall};
  }

  .description:last-child {
    margin: 5px 0px 24px 0px;
  }

  .btn-router-link {
    margin: 20px 0;
  }
`;

import styled from 'styled-components';
import { Colors } from '../../Foundation/Colors';
import { Fonts } from '../../Foundation/Fonts';

export const StyledIconSubtitle = styled.div`
  display: flex;
  align-items: center;
  ${Fonts.subheadSerif};
  color: ${Colors.grey_blue};
`;

export const StyledIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background: ${Colors.accent_5};
  fill: ${Colors.white};
  border-radius: 4px;
  margin-right: 16px;

  svg {
    width: 20px;
    height: 20px;
  }
`;

import React, { memo, useEffect, useState, useCallback } from 'react';

import {
  GoogleMap as GoogleMapComp,
  Marker,
  useLoadScript,
} from '@react-google-maps/api';
import { mapLoadScriptConfig, MAP_DEFAULT_LOCATION } from './Google.service';
import type { GoogleMapProps } from './GoogleMap.types';
import { MapHeader, MapStyleWrapper, MapSubHeader } from './GoogleMap.styles';

const defaultCenter = MAP_DEFAULT_LOCATION;
const MAX_ZOOM = 16;

export const MapWrapper = (props: GoogleMapProps): JSX.Element => {
  const [map, setMap] = useState(null);
  const { locationText, zoom = 14, mapHeader, mapSubHeading } = props;

  const containerStyle = {
    width: '100%',
    height: '200px',
  };

  const onLoad = useCallback((map) => {
    setMap(map);
  }, []);

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  const actualCenter =
    (locationText && JSON.parse(locationText)) || defaultCenter;
  const [location, setLocation] = useState(actualCenter);

  useEffect(() => {
    if (!locationText) {
      navigator.geolocation.getCurrentPosition(function (position) {
        setLocation({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      });
    }
  }, []);

  useEffect(() => {
    setLocation((locationText && JSON.parse(locationText)) || defaultCenter);
  }, [locationText]);

  return (
    <MapStyleWrapper {...props} className='google-map-wrapper'>
      {mapHeader && <MapHeader>{mapHeader}</MapHeader>}
      {mapSubHeading && <MapSubHeader>{mapSubHeading}</MapSubHeader>}
      <GoogleMapComp
        mapContainerStyle={containerStyle}
        center={location}
        zoom={(zoom && (zoom * MAX_ZOOM) / 100) || 14}
        onLoad={onLoad}
        onUnmount={onUnmount}
        options={{
          draggable: false,
          fullscreenControl: false,
          zoomControl: false,
          streetViewControl: false,
          mapTypeControl: false,
        }}
      >
        <Marker onLoad={onLoad} position={location} />
      </GoogleMapComp>
    </MapStyleWrapper>
  );
};

export const MapComponent = (props: GoogleMapProps): JSX.Element => {
  const { isLoaded, loadError } = useLoadScript(mapLoadScriptConfig);

  if (isLoaded) {
    return <MapWrapper {...props} />;
  }
  return <div></div>;
};

export const GoogleMap = memo(MapComponent);

import styled from 'styled-components';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Colors } from '../../../Foundation/Colors';
import { Fonts } from '../../../Foundation/Fonts';

export const StyledBreadcrumbs = styled.div`
  display: flex;
  align-items: center;
  gap: 46px;
`;

export const StyledBreadcrumbsLinks = styled(Breadcrumbs)`
  .MuiButton-text {
    ${Fonts.headline};
  }

  li:last-child {
    .MuiButton-text {
      color: ${Colors.grey_blue};
    }
  }

  .breadcrumbs-separator {
    fill: ${Colors.accent};
  }
`;

import React, { createElement } from 'react';

import ReactSelectMaterialUi, { SelectOption } from 'react-select-material-ui';
import { DropdownFilledProps } from './DropdownFilled.types';
import {
  DropdownWrapper,
  EmptyResultsMessage,
  DownArrow,
} from './DropdownFilled.style';

// Override default filter to a strict comparaison
const defaultFilter = ({ label }, filterStr) =>
  new RegExp(filterStr, 'i').test(label);

const DownArrowComponent = createElement(DownArrow);

const EmptyResultsMessageComponent = () => (
  <EmptyResultsMessage>No results</EmptyResultsMessage>
);

// Custom component overrides for ReactSelect components
// Reference: https://react-select.com/components
const dropDownElements = {
  DropdownIndicator: () => DownArrowComponent,
  IndicatorSeparator: () => null,
  NoOptionsMessage: EmptyResultsMessageComponent,
};

/**
 * DEPRECATION NOTICE
 *
 *
 * This thing should be deleted, and the styles be consolidated into Dropdown
 */

export const DropdownFilled = ({
  id,
  value,
  customRenderers,
  components,
  options,
  onChange,
  className,
  isSearchable = true,
}: DropdownFilledProps) => {
  const handleChange = (
    value: string,
    option: SelectOption | Array<SelectOption> | undefined
  ) => onChange && onChange(value, option);

  return (
    <DropdownWrapper className='dropdown-filled'>
      <ReactSelectMaterialUi
        id={id}
        className={className}
        SelectProps={{
          classNamePrefix: 'dropdown',
          blurInputOnSelect: true,
          filterOption: defaultFilter,
          components: {
            ...components,
            ...dropDownElements,
            ...customRenderers,
          },
          isSearchable,
        }}
        value={value}
        options={options}
        onChange={handleChange}
      />
    </DropdownWrapper>
  );
};

import React, { useState, ReactElement, Fragment } from 'react';
import { formatDistanceToNow, isToday, format } from 'date-fns';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { Checkbox } from '../../Atoms/Forms';
import { Divider } from '../../Atoms/Layout';
import { Button } from '../../Atoms/Navigations';
import { Icons } from '../../Foundation/Icons';
import { Chip } from '../../Atoms/DataDisplay/Chip';
import { SearchFilterWrapper, FilterWrapper } from './SearchFilter.styles';
import {
  SentTimeType,
  SearchFilterType,
  StateType,
  DividerAndDoneType,
  SearchFilterOptionType,
  MultiselectDisplayType,
} from './SearchFilter.types';

export const MultiselectDisplay = ({
  tags,
  isNoOptions,
  onDeleteClick,
}: MultiselectDisplayType) => {
  if (!tags.length && !isNoOptions) {
    return <span className='placeholder'>Please select a message</span>;
  }

  return (
    <Fragment>
      {tags.map((tag, index) => {
        if (index < 2) {
          return (
            <Chip
              className='search-filter-chip'
              key={tag.label}
              text={tag.label}
              color='accent'
              onDeleteClick={() => onDeleteClick(tag, 'Chip')}
            />
          );
        }
      })}
      {tags.length > 2 ? (
        <span className='more-than-two-suffix'>
          {tags.length - 2} More Selected
        </span>
      ) : null}
    </Fragment>
  );
};

export const DividerAndDone = ({
  onClick,
}: DividerAndDoneType): ReactElement => (
  <div
    className='divider-and-done'
    data-testid='search-filter-divider-and-done'
  >
    <Divider dividerType='solid' isHorizontal />
    <Button variant='link' text='Done' size='medium' onClick={onClick} />
  </div>
);

export const SentTime = ({ time }: SentTimeType): ReactElement => {
  const dateTime = new Date(time);
  const timeString = isToday(dateTime)
    ? formatDistanceToNow(dateTime, { includeSeconds: true, addSuffix: true })
    : format(dateTime, 'dd MMM yyyy');
  return <span className='time-text'>{timeString}</span>;
};

export const SearchFilterOption = ({
  selected,
  option,
  onChange,
  onClick,
  singleSelect,
}: SearchFilterOptionType): ReactElement => {
  if (singleSelect) {
    return (
      <div className='option-wrapper'>
        <Button
          type='secondary'
          variant='link'
          text={option.label}
          size='medium'
          onClick={() => onClick(option)}
        >
          {
            // Using null instead of undefined as per React doc:
            // https://reactjs.org/docs/conditional-rendering.html
            option.sentTime ? <SentTime time={option.sentTime} /> : null
          }
        </Button>
      </div>
    );
  }

  return (
    <div className='option-wrapper'>
      <Checkbox
        label={option.label}
        isChecked={selected}
        onChange={() => onChange(option)}
        value={option.value}
      />
      {
        // Using null instead of undefined as per React doc:
        // https://reactjs.org/docs/conditional-rendering.html
        option.sentTime ? <SentTime time={option.sentTime} /> : null
      }
    </div>
  );
};

export const SearchFilter = ({
  filterSelections,
  filterTitle,
  filterPlaceholder = 'Search Available Messages',
  notFoundMessage = 'No messages match your search.',
  noItemsMessage = 'No messages have been sent yet',
  selectedFilterOptions,
  onChange,
  onClick,
  singleSelect = false,
}: SearchFilterType): ReactElement => {
  const isNoOptions = !filterSelections.length;
  const defaultTags = selectedFilterOptions ? selectedFilterOptions : [];
  const [filterValues, setFilterValues] = useState<StateType>({
    tags: defaultTags,
    showMenu: false,
    isFound: true,
    inputValue: '',
  });
  const { tags, showMenu, isFound, inputValue } = filterValues;

  const notFoundComponent = (
    <span className='no-option-message'>{notFoundMessage}</span>
  );

  const noMessageComponent = (
    <span className='no-message'>
      <Icons.Processing />
      {noItemsMessage}
    </span>
  );

  const handleInputChange = (value) => {
    setFilterValues({
      ...filterValues,
      inputValue: value,
    });
  };

  const handleFilterNoMatch = (noMatch) => {
    if (isFound === noMatch) {
      setTimeout(() =>
        setFilterValues({
          ...filterValues,
          isFound: !noMatch,
        })
      );
    }
  };

  const customFilterOptions = (options, { inputValue, getOptionLabel }) => {
    const input = inputValue.toLowerCase();
    const filteredOptions = options.filter((option) => {
      let candidate = getOptionLabel(option);
      candidate = candidate.toLowerCase();

      return candidate.indexOf(input) > -1;
    });

    handleFilterNoMatch(!filteredOptions.length);

    return filteredOptions;
  };

  const handleButtonClick = () => {
    setFilterValues({
      ...filterValues,
      showMenu: !showMenu,
    });

    return onClick && onClick();
  };

  const handleSelectedOptionChange = (checkboxValue, source) => {
    // Checked checkbox option
    if (!tags.find((tag) => tag.id === checkboxValue.id)) {
      tags.push(checkboxValue);
      return setFilterValues({ ...filterValues });
    }

    // Unchecked checkbox option
    const newTags = tags.filter((tag) => checkboxValue.id !== tag.id);

    if (source === 'Chip') {
      onChange(newTags);
    }

    return setFilterValues({
      ...filterValues,
      tags: newTags,
    });
  };

  const handleClear = () => {
    setFilterValues({
      ...filterValues,
      inputValue: '',
    });
  };

  const handleDoneClick = () => {
    setFilterValues({
      ...filterValues,
      showMenu: !showMenu,
    });

    onChange(tags);
  };

  const handleOptionButtonClick = (value) => {
    const [currentTag] = tags;

    if (currentTag.id !== value.id) {
      onChange(value);
    }

    return setFilterValues({
      ...filterValues,
      showMenu: false,
      tags: [value],
    });
  };

  const getButtonText = (tags) => {
    if (singleSelect && tags.length) {
      return `${tags[0].label}`;
    }

    if (isNoOptions) {
      return 'No  Messages Available';
    }

    if (!tags.length) {
      return 'Message:';
    }

    return '';
  };

  return (
    <SearchFilterWrapper
      className={singleSelect ? 'single-select' : 'multi-select'}
    >
      <span className='filter-title'>{filterTitle}</span>
      <Button
        type='secondary'
        size='medium'
        text={getButtonText(tags)}
        startIcon='MessagesOutline'
        endIcon='DropdownArrow'
        onClick={handleButtonClick}
        disabled={showMenu}
        data-testid='search-filter-dropdown-button'
      >
        {!singleSelect ? (
          // Using null instead of undefined as per React doc:
          // https://reactjs.org/docs/conditional-rendering.html
          <MultiselectDisplay
            tags={tags}
            isNoOptions={isNoOptions}
            onDeleteClick={handleSelectedOptionChange}
          />
        ) : null}
      </Button>
      <FilterWrapper
        className={`filter-wrapper ${showMenu ? 'open' : ''}`}
        data-testid='search-filter-filter-wrapper'
      >
        <Autocomplete
          fullWidth
          classes={{
            option: `autocomplete-option ${
              singleSelect ? 'button' : 'checkbox'
            }`,
            tag: 'autocomplete-tag',
            popper: 'autocomplete-popper',
            paper: 'autocomplete-paper',
            noOptions: 'autocomplete-no-options',
            endAdornment: 'autocomplete-end-adornment',
            popupIndicator: 'autocomplete-popup-indicator',
          }}
          multiple={!singleSelect}
          disablePortal
          blurOnSelect={false}
          className='search-filter-wrapper'
          noOptionsText={isNoOptions ? noMessageComponent : notFoundComponent}
          open={showMenu}
          getOptionLabel={(option) => option.label}
          filterOptions={(options, state) =>
            customFilterOptions(options, state)
          }
          value={singleSelect ? tags[0] : tags}
          inputValue={inputValue}
          onInputChange={(event, value, reason) =>
            reason === 'input' && handleInputChange(value)
          }
          onChange={(event, value, reason) =>
            reason === 'clear' && handleClear()
          }
          getOptionSelected={(option, value) => option.id === value.id}
          options={filterSelections}
          disabled={isNoOptions}
          disableCloseOnSelect={!singleSelect}
          closeIcon={!singleSelect && <Icons.Close />}
          renderOption={(option, { selected }) => (
            <SearchFilterOption
              option={option}
              selected={selected}
              onChange={handleSelectedOptionChange}
              onClick={handleOptionButtonClick}
              singleSelect={singleSelect}
            />
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant='filled'
              placeholder={filterPlaceholder}
            />
          )}
        />
        {isFound && !isNoOptions && !singleSelect && (
          <DividerAndDone onClick={handleDoneClick} />
        )}
      </FilterWrapper>
    </SearchFilterWrapper>
  );
};

import {
  formComponentTypes,
  formComponentRemasteredTypes,
} from '../definitions/formComponentTypes';

import { genericComponentTypes } from '../definitions/genericComponentTypes';
import { recipientComponentTypes } from '../definitions/recipientComponentTypes';
import { OperatorConfiguration } from '../definitions/operatorAndOperand';
import {
  textfieldOperatorComponents,
  optionOperandComponents,
  multiSelectOperandComponents,
  dateOperandComponents,
  numberOperandComponents,
} from './operands/baseOperandComponents';

import { dateFilterComponents } from './operands/filterComponents';

const {
  FORM_TEXT_INPUT,
  FORM_TEXT_AREA,
  FORM_DATE_PICKER,
  FORM_RADIO_LIST,
  FORM_CHECKBOX_LIST,
  FORM_DROPDOWN,
  FILTER_DATE_PICKER,
  FORM_NUMERIC_SLIDER,
  FORM_YES_NO_BUTTONS,
} = formComponentTypes;

const {
  REMASTERED_FORM_TEXT_INPUT,
  REMASTERED_FORM_TEXT_AREA,
  REMASTERED_FORM_DATE_PICKER,
  REMASTERED_FORM_RADIO_LIST,
  REMASTERED_FORM_CHECKBOX_LIST,
  REMASTERED_FORM_DROPDOWN,
  REMASTERED_FORM_NUMERIC_SLIDER,
  REMASTERED_FORM_YES_NO_BUTTONS,
  REMASTERED_FORM_LOCATION,
  REMASTERED_FORM_RATING,
} = formComponentRemasteredTypes;

const { PHONE_NUMBER, EMAIL_ADDRESS } = recipientComponentTypes;

const { TEXT, NUMBER, DATE } = genericComponentTypes;

export const formControlDefinitions: Record<string, OperatorConfiguration> = {
  // Form Variables
  [FORM_TEXT_INPUT]: textfieldOperatorComponents,
  [FORM_TEXT_AREA]: textfieldOperatorComponents,
  [FORM_DATE_PICKER]: dateOperandComponents,
  [FORM_RADIO_LIST]: optionOperandComponents,
  [FORM_CHECKBOX_LIST]: multiSelectOperandComponents,
  [FORM_DROPDOWN]: optionOperandComponents,
  [FILTER_DATE_PICKER]: dateFilterComponents,
  [FORM_NUMERIC_SLIDER]: numberOperandComponents,
  [FORM_YES_NO_BUTTONS]: optionOperandComponents,

  // Recipient Variables
  [PHONE_NUMBER]: textfieldOperatorComponents,
  [EMAIL_ADDRESS]: textfieldOperatorComponents,

  // Generic Variables
  [TEXT]: textfieldOperatorComponents,
  [NUMBER]: numberOperandComponents,
  [DATE]: dateOperandComponents,

  // Remastered form component types
  [REMASTERED_FORM_TEXT_INPUT]: textfieldOperatorComponents,
  [REMASTERED_FORM_TEXT_AREA]: textfieldOperatorComponents,
  [REMASTERED_FORM_DATE_PICKER]: dateOperandComponents,
  [REMASTERED_FORM_RADIO_LIST]: optionOperandComponents,
  [REMASTERED_FORM_CHECKBOX_LIST]: multiSelectOperandComponents,
  [REMASTERED_FORM_DROPDOWN]: optionOperandComponents,
  [REMASTERED_FORM_NUMERIC_SLIDER]: numberOperandComponents,
  [REMASTERED_FORM_YES_NO_BUTTONS]: optionOperandComponents,
  [REMASTERED_FORM_LOCATION]: textfieldOperatorComponents,
  [REMASTERED_FORM_RATING]: numberOperandComponents, // i think it's supposed to be number
};

import React from 'react';

import { Tooltip } from '@material-ui/core';
import styled from 'styled-components';
import { Colors } from '../../../Foundation/Colors';

// Note the funkyness with the how we target the generated class to the `classes` object!
// This is because the tooltip actually appears in a portal, and so won't inherit from the base object.
// https://material-ui.com/guides/interoperability/#portals

// Custom ClassName is assigned to Tooltip popper component
// to allow overriding arrow margin-top to 0 for Tooltip with right placement

export const StyledTooltip = styled(({ className, ...rest }) => (
  <Tooltip
    classes={{
      popper: className,
    }}
    {...rest}
  />
))`
  &.MuiTooltip-popperArrow[x-placement*='right'] .MuiTooltip-arrow {
    margin-top: 0;
  }

  .MuiTooltip-tooltip {
    background: ${Colors.black};
    border-radius: 4px;
    padding: 16px;
    font-family: 'Inter';
    padding: ${(props) => props.padding};
    font-family: 'Inter';
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    max-width: 182px;

    // !important is to overide inline default transition causing odd UI behaviour
    transition: none !important;
  }

  .MuiTooltip-arrow {
    color: ${Colors.black};
  }
`;

import moment from 'moment';
import { Colors } from '../../../Foundation/Colors';

// format: 2020-12-03T16:48
const now = () => moment().format('YYYY-MM-DD[T]HH:mm');

export const richTextCalendarBlockModel = (id: string) => ({
  id,
  type: 'Calendar',
  label: 'Add to Calendar',
  properties: [
    {
      name: 'raw',
      label: 'raw',
      control: 'none',
      value: '[]',
      __typename: 'ComponentProperty',
    },
    {
      name: 'titleDividerLine',
      label: '',
      control: 'TitleDividerLine',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'buttonLabel',
      label: 'Button Label',
      control: 'VariableTextInput',
      value: 'Add to Calendar',
      __typename: 'ComponentProperty',
    },
    {
      name: 'padding_8px_1',
      label: '',
      control: 'PaddingControl8px',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'invite',
      label: 'Invite',
      control: 'TitleDividerNoLine',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'inviteFromTo',
      label: '',
      control: 'DateTimePickerFromTo',
      value: { from: now(), to: now() },
      __typename: 'ComponentProperty',
    },
    {
      name: 'inviteFromToAllDay',
      label: '',
      control: 'DatePickerFromTo',
      value: { from: now(), to: now() },
      __typename: 'ComponentProperty',
    },
    {
      name: 'inviteAllDay',
      label: 'All Day',
      control: 'Checkbox',
      value: 'false',
      __typename: 'ComponentProperty',
    },
    {
      name: 'padding_8px_2',
      label: '',
      control: 'PaddingControl8px',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'inviteTitle',
      label: 'Invite Title',
      control: 'TextFieldFilled',
      value: 'Event Title',
      __typename: 'ComponentProperty',
    },
    {
      name: 'inviteLocation',
      label: 'Event Location',
      control: 'TextFieldFilled',
      value: 'Whispir, Melbourne',
      __typename: 'ComponentProperty',
    },
    {
      name: 'inviteDescription',
      label: 'Description',
      control: 'TextFieldFilled',
      value: 'Type your description here...',
      __typename: 'ComponentProperty',
    },
    {
      name: 'inviteFileName',
      label: 'V-Card File Name (.ics)',
      control: 'TextFieldFilled',
      value: 'Calendar-Event',
      __typename: 'ComponentProperty',
    },
    {
      name: 'padding_8px_3',
      label: '',
      control: 'PaddingControl8px',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'styles',
      label: 'Styles',
      control: 'TitleDividerNoLine',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'textColor',
      label: 'Text Colour',
      control: 'ColorPicker',
      value: Colors.grey_blue_4,
      __typename: 'ComponentProperty',
    },
    {
      name: 'backgroundColor',
      label: 'Background Colour',
      control: 'ColorPicker',
      value: Colors.white,
      __typename: 'ComponentProperty',
    },
    {
      name: 'hideText',
      label: 'Hide Text',
      control: 'ToggleSwitch',
      value: 'false',
      __typename: 'ComponentProperty',
    },
    {
      name: 'buttonTextColor',
      label: 'Button Text Colour',
      control: 'ColorPicker',
      value: Colors.white,
      __typename: 'ComponentProperty',
    },
    {
      name: 'buttonColor',
      label: 'Button Colour',
      control: 'ColorPicker',
      value: Colors.accent_dnd,
      __typename: 'ComponentProperty',
    },
    {
      name: 'buttonBorderColor',
      label: 'Border Colour',
      control: 'ColorPicker',
      value: Colors.accent_dnd,
      __typename: 'ComponentProperty',
    },
    {
      name: 'borderRadius',
      label: 'Border Radius',
      control: 'Slider',
      value: '5',
      __typename: 'ComponentProperty',
    },
    {
      name: 'fullWidth',
      label: 'Full Width',
      control: 'ToggleSwitch',
      value: 'false',
      __typename: 'ComponentProperty',
    },
    {
      name: 'iconColor',
      label: 'Icon Colour',
      control: 'ColorPicker',
      value: Colors.white,
      __typename: 'ComponentProperty',
    },
    {
      name: 'iconAlignment',
      label: 'Icon Alignment',
      control: 'IconAlignmentSelector',
      value: 'left',
      __typename: 'ComponentProperty',
    },
    {
      name: 'customIcon',
      label: 'Custom Icon',
      control: 'ToggleSwitch',
      value: 'false',
      __typename: 'ComponentProperty',
    },
    {
      name: 'iconSrc',
      label: 'Icon Image Source',
      control: 'TextFieldFilled',
      value:
        'https://cdn-au.whispir.com/public/resources/31f5fe1a7f0222902696e68705d0cca003.svg',
      __typename: 'ComponentProperty',
    },
    {
      name: 'padding_4px_1',
      label: '',
      control: 'PaddingControl4px',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'hideIcon',
      label: 'Hide Icon',
      control: 'ToggleSwitch',
      value: 'false',
      __typename: 'ComponentProperty',
    },
    {
      name: 'padding_8px_4',
      label: '',
      control: 'PaddingControl8px',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'alignmentTitle',
      label: 'Alignment',
      control: 'TitleDividerNoLine',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'alignment',
      label: '',
      control: 'ItemsAlignmentSelector',
      value: 'center',
      __typename: 'ComponentProperty',
    },
    {
      name: 'padding_8px_5',
      label: '',
      control: 'PaddingControl8px',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'padding',
      label: 'Padding',
      control: 'TitleDividerNoLine',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'paddingTop',
      label: 'Top',
      control: 'Slider',
      value: '20',
      __typename: 'ComponentProperty',
    },
    {
      name: 'paddingBottom',
      label: 'Bottom',
      control: 'Slider',
      value: '20',
      __typename: 'ComponentProperty',
    },
    {
      name: 'paddingLeft',
      label: 'Left',
      control: 'Slider',
      value: '0',
      __typename: 'ComponentProperty',
    },
    {
      name: 'paddingRight',
      label: 'Right',
      control: 'Slider',
      value: '0',
      __typename: 'ComponentProperty',
    },
  ],
  __typename: 'Component',
});

import React from 'react';

import { StyledTooltip } from './Tooltip.style';
import { TooltipProps } from './Tooltip.types';

// Using popper options to position tooltip as per design in a clean way as suggested by below link
// https://stackoverflow.com/questions/56703306/how-to-overwrite-material-ui-tooltip-inline-styles

export const Tooltip = ({
  ariaLabel,
  title,
  disablePortal = false,
  offset = '0, 0',
  placement = 'right',
  padding = '16px',
  ...otherProps
}: TooltipProps): JSX.Element => {
  return (
    <StyledTooltip
      title={title || ariaLabel}
      aria-label={ariaLabel}
      PopperProps={{
        popperOptions: {
          modifiers: {
            offset: {
              enabled: true,
              offset,
            },
          },
        },
        disablePortal,
      }}
      placement={placement}
      padding={padding}
      {...otherProps}
    />
  );
};

import styled from 'styled-components';

import { Dropdown } from '../../../../../Atoms/Forms/Dropdown';

export const WithinRangeWrapper = styled.div`
  display: grid;
  grid-template-columns: 60px 100px;
  grid-column-gap: 12px;
  align-items: end;
`;

export const StyledDropdown = styled(Dropdown)`
  &&&.dropdown {
    min-width: initial;
    margin: 0;
  }
`;

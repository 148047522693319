// $FlowFixMe

import React, { useState } from 'react';
import { Illustrations } from '../../Foundation/Illustrations';
import { IconButton } from '../Navigations';
import {
  TemporaryInfoBannerWrapper,
  StartIllustrationWrapper,
  EndIllustrationWrapper,
  CloseIconWrapper,
  CardContentWrapper,
} from './TemporaryInfoBanner.style';
import { TemporaryInfoBannerType } from './TemporaryInfoBanner.types';

const Illustration = ({ type }) => (type ? Illustrations[type]() : null);

export const TemporaryInfoBanner = (
  props: TemporaryInfoBannerType
): JSX.Element => {
  const {
    backgroundColor,
    textColor,
    content,
    link,
    linkText,
    disableCloseButton,
    startIllustration,
    endIllustration,
  } = props;
  const [showBanner, toggleBanner] = useState(true);

  return (
    <TemporaryInfoBannerWrapper
      color={textColor}
      style={{
        backgroundColor,
        display: showBanner ? 'flex' : 'none',
      }}
    >
      <StartIllustrationWrapper>
        <Illustration type={startIllustration} />
      </StartIllustrationWrapper>
      <CardContentWrapper className='banner-content'>
        {content}
        {link}
      </CardContentWrapper>
      <EndIllustrationWrapper>
        <Illustration type={endIllustration} />
      </EndIllustrationWrapper>
      {link && <a href={link}>{linkText}</a>}
      <CloseIconWrapper color={textColor}>
        {!disableCloseButton && (
          <IconButton
            id='temporary-banner-close'
            icon='Close'
            color='primary'
            onClick={() => {
              toggleBanner(!showBanner);
            }}
          />
        )}
      </CloseIconWrapper>
    </TemporaryInfoBannerWrapper>
  );
};

import styled from 'styled-components';
import { Colors } from '../../Foundation/Colors';
import { Fonts } from '../../Foundation/Fonts';

export const FileSelectWrapper = styled.div`
  text-align: center;

  input {
    display: none;
  }

  > div {
    margin: 0;
  }

  .file-name {
    display: block;
    height: 30px;
    color: ${Colors.grey_blue_4};
    ${Fonts.caption};
    padding-top: 8px;

    .error {
      color: ${Colors.error};
    }
  }
`;

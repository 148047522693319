import styled from 'styled-components';
import { Colors } from '../../Foundation/Colors';
import { Fonts } from '../../Foundation/Fonts';

import { StyledInfoPanelProp } from './InfoPanel.types';

export const StyledInfoPanel = styled.div<StyledInfoPanelProp>`
  display: flex;
  width: 648px;
  min-height: 40px;
  height: 100%;
  align-items: center;
  ${Fonts.caption};
  margin: 0 auto;
  color: ${Colors.grey_blue};

  svg {
    margin: 0 16px;
  }

  &.warning {
    background-color: ${Colors.accent}10;
    svg {
      fill: ${Colors.accent};
    }
  }
  &.alert {
    background-color: ${Colors.error}10;
    svg {
      fill: ${Colors.error};
    }
  }
  &.hint {
    background-color: ${Colors.warning_light};
    svg {
      fill: ${Colors.warning};
    }
  }
`;

import React from 'react';

import { ResponsiveBullet } from '@nivo/bullet';
import { BulletChartTypes, BulletMarkerTypes } from './BulletChart.types';
import { BulletChartWrapper } from './BulletChart.styles';

const CustomMarker = ({
  x,
  size,
  color,
  onMouseEnter,
  onMouseMove,
  onMouseLeave,
}: BulletMarkerTypes): JSX.Element => (
  <g
    transform={`translate(${x},0)`}
    onMouseEnter={onMouseEnter}
    onMouseMove={onMouseMove}
    onMouseLeave={onMouseLeave}
  >
    <line
      x1={0}
      x2={0}
      y1={0}
      y2={size}
      stroke={color}
      strokeWidth={2}
      fill='none'
    />
  </g>
);

export const BulletChart = ({
  data,
  width,
  height,
  layout,
  reverse,
  margin,
  spacing,
  measureSize,
  markerSize,
  rangeComponent,
  rangeColors,
  measureComponent,
  measureColors,
  markerComponent = CustomMarker,
  markerColors,
  axisPosition,
  titlePosition,
  titleAlign,
  titleOffsetX,
  titleOffsetY,
  titleRotation,
  onRangeClick,
  onMeasureClick,
  onMarkerClick,
  animate,
  motionStiffness,
  motionDamping,
  isInteractive = false,
}: BulletChartTypes): JSX.Element => (
  <BulletChartWrapper width={width} height={height}>
    <ResponsiveBullet
      data={data}
      width={width}
      height={height}
      layout={layout}
      reverse={reverse}
      margin={margin}
      spacing={spacing}
      measureSize={measureSize}
      markerSize={markerSize}
      rangeComponent={rangeComponent}
      rangeColors={rangeColors}
      measureComponent={measureComponent}
      measureColors={measureColors}
      markerComponent={markerComponent}
      markerColors={markerColors}
      axisPosition={axisPosition}
      titlePosition={titlePosition}
      titleAlign={titleAlign}
      titleOffsetX={titleOffsetX}
      titleOffsetY={titleOffsetY}
      titleRotation={titleRotation}
      onRangeClick={onRangeClick}
      onMeasureClick={onMeasureClick}
      onMarkerClick={onMarkerClick}
      animate={animate}
      motionStiffness={motionStiffness}
      motionDamping={motionDamping}
      isInteractive={isInteractive}
    />
  </BulletChartWrapper>
);

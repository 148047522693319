import React from 'react';

import { HeaderNavigation } from './HeaderNavigation/HeaderNavigation';
import { Navigation } from './Navigation/Navigation';
import { SecondaryNavigation } from './SecondaryNavigation/SecondaryNavigation';
import { SideDrawer } from './SideDrawer/SideDrawer';
import { GlobalNavigationTypes } from './GlobalNavigation.types';
import {
  GlobalNavigationStyleWrapper,
  GlobalNavigationHeader,
  GlobalNavigationFooter,
} from './GlobalNavigation.styles';

export const GlobalNavigation = ({
  workspaceLinkItems,
  headerLink,
  linkItems,
  hasWorkspaceSelector,
  secondaryLinkItems,
  isSubnavOpen,
  subNavMenuItems,
  hasSubNavigation = true,
  toggleDrawer,
  ...otherProps
}: GlobalNavigationTypes): JSX.Element => {
  return (
    <>
      <GlobalNavigationStyleWrapper
        role='navigation'
        aria-label='global navigation'
        {...otherProps}
      >
        <GlobalNavigationHeader>
          <HeaderNavigation headerLink={headerLink} />
        </GlobalNavigationHeader>
        <Navigation linkItems={linkItems} />
        <GlobalNavigationFooter>
          <SecondaryNavigation
            hasWorkspaceSelector={hasWorkspaceSelector}
            secondaryLinkItems={secondaryLinkItems}
            workspaceLinkItems={workspaceLinkItems}
          />
        </GlobalNavigationFooter>
      </GlobalNavigationStyleWrapper>
      {hasSubNavigation && (
        <SideDrawer
          subNavMenuItems={subNavMenuItems}
          isSubnavOpen={isSubnavOpen}
          toggleDrawer={toggleDrawer}
        />
      )}
    </>
  );
};

import styled from 'styled-components';
import { Colors } from '../../Foundation/Colors';
import { Fonts } from '../../Foundation/Fonts';

export const PositionSelectorWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  div .toggle-selected {
    background-color: ${Colors.accent_2};
    box-shadow: none;
  }

  .placement-label {
    ${Fonts.link};
    color: ${Colors.grey_blue};
  }

  .placement-group {
    width: 200px;
    height: 132px;
    box-shadow: none;
    display: flex;
    flex-wrap: wrap;

    .MuiToggleButton-root {
      border: none;
    }

    .MuiToggleButton-root:hover {
      background-color: transparent;
    }

    .MuiToggleButton-root.Mui-selected:hover {
      background-color: ${Colors.accent_2};
    }

    .placement-item {
      flex: 0 0 33.3333%;
      padding: 0;
      height: 42px;

      svg {
        width: 28px;
        height: 28px;
      }
      .middle-middle-position {
        width: 20px;
        height: 20px;
      }
    }
  }
`;

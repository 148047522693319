import { useEffect, useRef } from 'react';

const useHandleClickOutsideOfElement = (clickedOutside: () => void) => {
  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      const clickedOutsideToneScoreCards = (e: MouseEvent) => {
        if (!containerRef.current.contains(e.target) && clickedOutside) {
          clickedOutside();
        }
      };

      document.addEventListener('mousedown', clickedOutsideToneScoreCards);

      return () =>
        document.removeEventListener('mousedown', clickedOutsideToneScoreCards);
    }
  }, [containerRef, clickedOutside]);

  return {
    containerRef,
  };
};

// eslint-disable-next-line import/no-default-export
export default useHandleClickOutsideOfElement;

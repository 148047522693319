import React, { useRef, useEffect, ReactNode } from 'react';
import { TextField, InputAdornment } from '@material-ui/core';
import { Icons } from '../../Foundation/Icons';
import { HeaderTitleProps } from './HeadeTitle.types';
import {
  StyledHeaderTitle,
  StyledEditButton,
  StyledStaticTitle,
} from './HeaderTitle.style';

export const HeaderTitle = <T extends ReactNode>({
  text,
  isEditable,
  onChange = undefined,
}: HeaderTitleProps<T>): JSX.Element => {
  const inputElement = useRef(null);

  // copied from workflow studio ui
  const setTextFieldWidth = (title, titleInputRef) => {
    const { length } = title;
    const inputElWidth = titleInputRef ? titleInputRef.current : null;

    if (inputElWidth) {
      const { style } = inputElWidth;
      switch (true) {
        case length <= 7:
          style.width = '125px';
          break;
        case length <= 10:
          style.width = '160px';
          break;
        case length <= 12:
          style.width = '195px';
          break;
        case length <= 15:
          style.width = '230px';
          break;
        case length <= 18:
          style.width = '265px';
          break;
        case length <= 21:
          style.width = '300px';
          break;
        case length <= 24:
          style.width = '335px';
          break;
        case length <= 27:
          style.width = '370px';
          break;
        case length <= 30:
          style.width = '400px';
          break;
        case length <= 34:
          style.width = '435px';
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    if (text && inputElement) {
      setTextFieldWidth(text, inputElement);
    }
  }, [text]);

  const handleTitleChange = (event) => {
    if (onChange) onChange(event);
  };

  const handleClickEdit = ({ current }) => current.focus();

  if (!isEditable) return <StyledStaticTitle>{text}</StyledStaticTitle>;

  return (
    <StyledHeaderTitle>
      <TextField
        inputRef={inputElement}
        margin='normal'
        id='header-title'
        variant='outlined'
        value={text}
        autoComplete='off'
        onChange={handleTitleChange}
        InputProps={{
          endAdornment: (
            <InputAdornment
              position='start'
              className='edit-icon'
              onClick={() => handleClickEdit(inputElement)}
            >
              <StyledEditButton>
                <Icons.EditPen />
              </StyledEditButton>
            </InputAdornment>
          ),
        }}
        fullWidth
      />
    </StyledHeaderTitle>
  );
};

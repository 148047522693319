import React from 'react';

import { Button, StyledButtonWrapper } from './RichTextButtonBlock.style';
import { RichTextButtonBlockProps } from './RichTextButtonBlock.types';

export const RichTextButtonBlock = (
  props: RichTextButtonBlockProps
): JSX.Element => {
  const {
    label,
    paddingTop,
    paddingBottom,
    backgroundColor,
    ...styles
  } = props;

  // if formVariables have been injected with draftail -> inject that markup to be display in button text
  return (
    <StyledButtonWrapper
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      backgroundColor={backgroundColor}
    >
      <Button {...styles} dangerouslySetInnerHTML={{ __html: label }} />
    </StyledButtonWrapper>
  );
};

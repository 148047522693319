import styled from 'styled-components';

import { RichTextDividerBlockProps } from './RichTextDividerBlock.types';

export const Divider = styled.div<RichTextDividerBlockProps>`
  display: block;
  margin: 0 auto;
  width: 100%;
  background: ${(props) => props.backgroundColor};

  hr {
    height: ${(props) => props.lineThickness}px;
    background: ${(props) => props.dividerColor};
    border: none;
    margin-top: ${(props) => props.marginTop}px;
    margin-bottom: ${(props) => props.marginBottom}px;
  }
`;

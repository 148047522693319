import styled from 'styled-components';
import { Colors } from '../../Foundation/Colors';
import { Elevations } from '../../Foundation/Elevations';
import { Fonts } from '../../Foundation/Fonts';
import { HEADER_HEIGHT } from '../../Foundation/Spacing';

export const StyledHeader = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  width: auto;
  height: ${HEADER_HEIGHT};
  background-color: ${Colors.white};
  box-shadow: ${Elevations.elevation_1};
  color: ${Colors.grey_blue};
  z-index: 9;

  &.sticky-top {
    position: sticky;
    top: 0;
    box-shadow: unset;
  }
`;

export const StyledTitle = styled.div`
  display: block;
  padding-left: 32px;
  ${Fonts.headline};
`;

export const StyledIndicator = styled.div`
  display: block;
  ${Fonts.headline};
  margin-top: 40px;
  flex: 1;
`;

export const StyledActionsContainer = styled.div`
  padding-right: 32px;
  text-align: right;

  & > *:not(:last-child) {
    margin-right: 24px;
  }
`;

import styled from 'styled-components';
import { Colors } from '../../../Foundation/Colors';
import { Elevations } from '../../../Foundation/Elevations';
import { Fonts } from '../../../Foundation/Fonts';
import { TRANSITION_TIME } from '../../../Foundation/Spacing';

import { DropdownFilledWrapperProps } from './DropdownFilled.types';

export const DropdownWrapper = styled.div<DropdownFilledWrapperProps>`
  margin: 0;

  .MuiFormControl-root {
    width: 100%;
    ${Fonts.body};
    color: ${Colors.grey_blue_4};
    border-radius: 4px;
    line-height: 28px;
    background-color: ${Colors.grey_blue_0} !important;

    .dropdown__value-container {
      padding: 0 4px;
      height: 32px;
    }

    div.dropdown__single-value {
      ${Fonts.body};
    }

    .dropdown__control {
      border-color: transparent;
      border-width: 0 0 2px 0;
      padding: 3px 8px;

      &.dropdown__control--is-focused {
        border-color: ${Colors.accent};
        border-width: 0 0 2px 0;
      }
    }

    &:hover {
      .dropdown__control {
        border-color: ${Colors.accent_dark};
        border-width: 0 0 2px 0;
        background: none;
        margin-right: 0;
      }

      .dropdown__control--is-focused {
        border-color: ${Colors.accent};
        border-width: 0 0 2px 0;
      }
    }
  }

  .dropdown__control {
    box-shadow: none;
    min-height: 28px;
    margin-right: 0;
  }

  .dropdown__input {
    margin-left: 0;
    color: ${Colors.grey_blue_4};
  }

  .dropdown__menu {
    margin-top: 0;
    border-radius: 8px;
    box-shadow: ${Elevations.elevation_3};
    background-color: ${Colors.white};
    width: 100%;
  }

  .dropdown__menu-list {
    margin: 8px 0;
  }

  .dropdown__option {
    ${Fonts.body};
    color: ${Colors.grey_blue};
    padding: 6px 16px;
    cursor: pointer;
    transition: background-color ${TRANSITION_TIME};
    background-color: ${Colors.white};
  }

  .dropdown__option--is-focused {
    background-color: ${Colors.grey_blue_1};
  }

  .dropdown__indicators {
    margin-right: 12px;
  }

  .dropdown__clear-indicator {
    margin-top: -4px;

    svg {
      fill: ${Colors.grey_blue_3};
    }
  }

  .dropdown__control--menu-is-open > .dropdown__indicators > div {
    transform: rotate(180deg);
  }
`;

export const DownArrow = styled.div`
  border: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid ${Colors.grey_blue};
  margin: -6px 8px 0 0;
  transition: all ${TRANSITION_TIME};
`;

export const EmptyResultsMessage = styled.div`
  color: ${Colors.grey_blue};
  padding: 10px 20px;
  ${Fonts.body};
  color: Colors.grey_blue;
`;

import Agreement from '../../Assets/Illustrations/Agreement.svg';
import AreYouSafe from '../../Assets/Illustrations/AreYouSafe.svg';

import Astronaut from '../../Assets/Illustrations/Astronaut.svg';
import Confirm from '../../Assets/Illustrations/Confirm.svg';
import Contact from '../../Assets/Illustrations/Contact.svg';
import Climber from '../../Assets/Illustrations/Climber.svg';
import InsightBanner from '../../Assets/Illustrations/InsightBanner.svg';
import Delete from '../../Assets/Illustrations/Delete.svg';
import List from '../../Assets/Illustrations/List.svg';
import ListDesaturated from '../../Assets/Illustrations/ListDesaturated.svg';
import MotivationSurvey from '../../Assets/Illustrations/MotivationSurvey.svg';
import NewHire from '../../Assets/Illustrations/NewHire.svg';
import Template from '../../Assets/Illustrations/Template.svg';
import TemplateSm from '../../Assets/Illustrations/TemplateSm.svg';
import Settings from '../../Assets/Illustrations/Settings.svg';
import SpiderDiagram from '../../Assets/Illustrations/SpiderDiagram.svg';
import WebToSms from '../../Assets/Illustrations/WebToSms.svg';
import UploadCsv from '../../Assets/Illustrations/UploadCsv.svg';
import PlusThin from '../../Assets/Illustrations/PlusThin.svg';
import PMFSurvey from '../../Assets/Illustrations/PMFSurvey.svg';
import SatSurvey from '../../Assets/Illustrations/SatSurvey.svg';
import StaffFeedback from '../../Assets/Illustrations/StaffFeedback.svg';
import CallCentre from '../../Assets/Illustrations/CallCentre.svg';
import Worklfow from '../../Assets/Illustrations/Workflow.svg';
import WomanEdit from '../../Assets/Illustrations/womanEdit.svg';
import ContactBanner from '../../Assets/Illustrations/ContactBanner.svg';
import Cloud from '../../Assets/Illustrations/Cloud.svg';
import Workplace from '../../Assets/Illustrations/Workplace.svg';
import CampFire from '../../Assets/Illustrations/CampFire.svg';
import EmptyState from '../../Assets/Illustrations/EmptyState.svg';
import WorkLocation from '../../Assets/Illustrations/WorkLocation.svg';
import LeaveRequest from '../../Assets/Illustrations/LeaveRequest.svg';
import EventConfirmation from '../../Assets/Illustrations/EventConfirmation.svg';
import Plus from '../../Assets/Illustrations/Plus.svg';
import AstronautCloud from '../../Assets/Illustrations/AstronautCloud.svg';
import { PhoneClouds } from './PhoneClouds';

export const Illustrations = {
  Agreement,
  AreYouSafe,
  Astronaut,
  AstronautCloud,
  Contact,
  Climber,
  Delete,
  InsightBanner,
  List,
  ListDesaturated,
  MotivationSurvey,
  NewHire,
  Template,
  TemplateSm,
  Worklfow,
  Settings,
  SpiderDiagram,
  UploadCsv,
  Confirm,
  PhoneClouds,
  WomanEdit,
  WebToSms,
  Workplace,
  PlusThin,
  PMFSurvey,
  SatSurvey,
  StaffFeedback,
  CallCentre,
  ContactBanner,
  Cloud,
  CampFire,
  EmptyState,
  WorkLocation,
  LeaveRequest,
  EventConfirmation,
  Plus,
};

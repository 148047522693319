import React, { forwardRef } from 'react';
import { ButtonProps, Button } from '../../Atoms/Navigations';

import { PopoverMenuBase, PopoverMenuBaseProps } from '../PopoverMenuBase';

type PopoverMenuSimpleProps = {
  menuItems: PopoverMenuBaseProps['menuItems'];
  anchorOrigin?: PopoverMenuBaseProps['anchorOrigin'];
  onOpen?: () => void;
} & Exclude<ButtonProps, 'onClick'>;

/**
 * Simple Popover Menu with a Button as a trigger.
 * Note that all of the button props can be passed in to style, etc.
 * @param props
 */
export const PopoverMenuSimpleButton = (
  props: PopoverMenuSimpleProps
): JSX.Element => {
  const { menuItems, anchorOrigin, onOpen, ...rest } = props;
  return (
    <PopoverMenuBase
      menuItems={menuItems}
      anchorOrigin={anchorOrigin}
      TriggerComponent={forwardRef(({ onClick }, ref) => (
        <Button
          onClick={(e) => {
            onClick(e);
            if (onOpen) onOpen();
          }}
          ref={ref}
          {...rest}
        />
      ))}
    />
  );
};

import React from 'react';

import { Icon } from '../Icon';
import { IconSubtitleType } from './IconSubtitle.types';
import { StyledIconSubtitle, StyledIcon } from './IconSubtitle.style';

export const IconSubtitle = ({ icon, title }: IconSubtitleType) => (
  <StyledIconSubtitle>
    <StyledIcon>
      <Icon icon={icon} />
    </StyledIcon>
    <div>{title}</div>
  </StyledIconSubtitle>
);

import React, { useState } from 'react';
import { OptionsPicker } from '../../Molecules/OptionsPicker';
import { TextField } from '../../Atoms/Forms/TextField';
import { Dropdown, DropdownProps } from '../../Atoms/Forms';
import {
  SocialIconsOptionsPickerFieldsProps,
  SocialIconItemValues,
} from './SocialIconsOptionsPicker.types';
import { StyledUrlTextField } from './SocialIconsOptionsPicker.styles';

export const SocialIconsOptionsPickerFields = ({
  item,
  updateItem,
  options,
  handleDropdownChange,
}: SocialIconsOptionsPickerFieldsProps) => {
  const [dropdownValue, setDropdownValue] = useState<string | undefined>(
    item.selectedIcon
  );

  const networkDropdownOptions: Array<string> = options;

  const networkDropdownProps: DropdownProps = {
    label: 'Social Network',
    value: dropdownValue as string,
    variant: 'filled',
    options: networkDropdownOptions,
    onChange: (index, dropdownItem): void => {
      const selectedItem = dropdownItem as SocialIconItemValues;
      const selectedValue = selectedItem.label;
      item.selectedIcon = selectedValue;
      updateItem('selectedIcon')(selectedValue);
      setDropdownValue(selectedValue);
      handleDropdownChange(item);
    },
  };

  const altTextProps = {
    label: 'Alt Text',
    onChange: updateItem('altText'),
    placeholder: 'Enter Your Image Description',
    value: item.altText,
  };

  const imageSourceTextProps = {
    label: 'Image Source',
    onChange: updateItem('imageSourceText'),
    placeholder: 'Enter Your Image URL',
    value: item.imageSourceText,
  };

  const urlTextProps = {
    label: item.urlLabel || 'URL',
    onChange: updateItem('urlText'),
    placeholder: item.urlPlaceholder || 'Enter URL',
    value: item.urlText,
    InputProps: {
      startAdornment: (
        <div className='url-text-adornment'>{item.urlTextAdornment}</div>
      ),
    },
    className: 'url-text-field',
  };

  return (
    <>
      <Dropdown {...networkDropdownProps} />
      {dropdownValue === 'Custom Icon' && (
        <TextField {...imageSourceTextProps} variant='filled' />
      )}
      <TextField {...altTextProps} variant='filled' />
      <StyledUrlTextField {...urlTextProps} variant='filled' />
    </>
  );
};

export const SocialIconsOptionsPicker = (props): JSX.Element => {
  const { handleDropdownChange, options, defaultSelectedOption } = props;
  return (
    <OptionsPicker
      {...props}
      filledTextAreaStyle
      optionsType='radio-buttons/dropdown-menu'
      isExtension
      addLinkText='Add Network'
      renderChildren={(item, updateItem) => (
        <SocialIconsOptionsPickerFields
          item={item}
          updateItem={updateItem}
          options={options}
          handleDropdownChange={handleDropdownChange}
        />
      )}
      defaultSelectedOption={defaultSelectedOption}
    />
  );
};

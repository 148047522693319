import styled from 'styled-components';
import { Fonts } from '../../Foundation/Fonts';

import { RichSubmitButtonBlockProps } from './RichSubmitButtonBlock.types';

export const Button = styled.button<Omit<RichSubmitButtonBlockProps, 'label'>>`
  display: block;
  min-width: ${(props) => (props.fullWidth === 'true' ? '100%' : '200px')};
  margin: 0 auto;
  ${Fonts.body};
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.textColor};
  border-radius: 5px;
  border: none;
  text-align: center;
  word-break: break-word;
  transition: all 0.2s ease-in-out;
  padding: 10px;
  margin-top: ${(props) => props.marginTop}px;
  margin-bottom: ${(props) => props.marginBottom}px;
`;

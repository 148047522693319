import styled from 'styled-components';
import { Colors } from '../../Foundation/Colors';
import { Elevations } from '../../Foundation/Elevations';
import { Fonts } from '../../Foundation/Fonts';

import {
  StyledBarChartPropTypes,
  RelativeWrapperPropTypes,
  TooltipProps,
} from './BarChart.types';

export const defaultMargin = { top: 10, right: 0, bottom: 40, left: 0 };

export const defaultColors = [Colors.blue, Colors.accent, Colors.grey_blue_3];

export const defaultTheme = {
  tooltip: {
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '30px',
      borderRadius: '8px',
      boxShadow: Elevations.elevation_2,
    },
  },
};

export const RelativeWrapper = styled.div<RelativeWrapperPropTypes>`
  position: relative;
  display: ${(props): string =>
    props.customLegendPosition === 'left' ? 'flex' : 'block'};
  width: ${(props): string => props.width || '660px'};
  height: ${(props): string => props.height || '80px'};

  .tooltip-wrapper {
    top: 24px;
    left: -17px;
  }
`;

export const StyledBarChart = styled.div<StyledBarChartPropTypes>`
  position: absolute;
  right: 0;
  display: ${(props): string =>
    props.customLegendPosition === 'left' ? 'flex' : 'block'};
  width: ${(props): string =>
    props.customLegendPosition === 'left' ? '85%' : props.width || '100%'};
  height: ${(props): string => props.height || '80px'};
  background-color: ${(props): string => props.backgroundColor || Colors.white};
  border-radius: 2px;
`;

export const StyledTooltip = styled.div<TooltipProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 7px 0 0;
  height: auto;
  svg {
    height: ${({ size }) => size};
    width: ${({ size }) => size};
    fill: ${({ color }) => color};
    margin: 0 6px 0 0;
  }
  ${Fonts.caption};
  font-weight: 500;
`;

import { variableIds } from '@whispir/expression-helper';

import { ModelType } from '../../utilityTypes';

const { DATE } = variableIds;
export const formDatePickerModel = (id: string): ModelType => ({
  id,
  type: 'FormDatePicker',
  label: 'Date',
  componentType: 'form',
  componentValueType: DATE,
  properties: [
    {
      name: 'text',
      label: 'Styles',
      control: 'TitleDivider',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'label',
      label: 'Label',
      control: 'TextFieldFilled',
      value: 'Date',
      __typename: 'ComponentProperty',
    },
    {
      name: 'padding_8px_1',
      label: '',
      control: 'PaddingControl8px',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'helperText',
      label: 'Helper Text',
      control: 'TextFieldFilled',
      value: 'Pick your date',
      __typename: 'ComponentProperty',
    },
    {
      name: 'requiredField',
      label: 'Required Field',
      control: 'Checkbox',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'options',
      label: 'Options',
      control: 'TitleDividerNoLine',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'defaultValueToday',
      label: 'Current date as default',
      control: 'ToggleSwitch',
      value: 'false',
      __typename: 'ComponentProperty',
    },
    {
      name: 'dateFormat',
      label: 'Date Format',
      control: 'DateFormatPickerFilled',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'padding_8px_3',
      label: '',
      control: 'PaddingControl8px',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'text2',
      label: 'Padding',
      control: 'TitleDividerNoLine',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'paddingTop',
      label: 'Top',
      control: 'Slider',
      value: '20',
      __typename: 'ComponentProperty',
    },
    {
      name: 'paddingBottom',
      label: 'Bottom',
      control: 'Slider',
      value: '20',
      __typename: 'ComponentProperty',
    },
  ],
  __typename: 'Component',
});

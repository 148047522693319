import { Colors } from '../../../Foundation/Colors';
import { ModelType } from '../../utilityTypes';

export const richTextFullWidthBannerBlockModel = (id: string): ModelType => ({
  id,
  type: 'FullWidthBanner',
  label: 'Full Width Banner',
  properties: [
    {
      name: 'raw',
      label: 'raw',
      control: 'none',
      value:
        '{"blocks":[{"key":"65smf","text":"","type":"","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"65smf","text":"Message Title","type":"header-one","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"itu6","text":"Message Subtitle","type":"header-three","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
      __typename: 'ComponentProperty',
    },
    {
      name: 'text',
      label: 'Styles',
      control: 'TitleDivider',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'textPosition',
      label: 'Position',
      control: 'TextPosition',
      value: 'middle-middle',
      __typename: 'ComponentProperty',
    },
    {
      name: 'padding_8px_1',
      label: '',
      control: 'PaddingControl8px',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'textColor',
      label: 'Text Colour',
      control: 'ColorPicker',
      value: Colors.black,
      __typename: 'ComponentProperty',
    },
    {
      name: 'textPadding',
      label: 'Text Padding',
      control: 'Slider',
      value: '0',
      __typename: 'ComponentProperty',
    },
    {
      name: 'image',
      label: 'Image',
      control: 'TitleDividerNoLine',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'src',
      label: 'Background image source',
      control: 'VariableTextInput',
      value:
        'https://cdn-au.whispir.com/public/resources/313a588f0b0ae6c3caa57f6536a540ab50.jpeg',
      __typename: 'ComponentProperty',
    },
    {
      name: 'padding_16px',
      label: '',
      control: 'PaddingControl16px',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'backgroundColor',
      label: 'Background Colour',
      control: 'ColorPicker',
      value: Colors.accent_1,
      __typename: 'ComponentProperty',
    },
    {
      name: 'padding_8px_2',
      label: '',
      control: 'PaddingControl8px',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'height',
      label: 'Banner Height',
      control: 'Slider800',
      value: '320',
      __typename: 'ComponentProperty',
    },
    {
      name: 'margins',
      label: 'padding',
      control: 'TitleDividerNoLine',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'marginTop',
      label: 'Top',
      control: 'Slider',
      value: '0',
      __typename: 'ComponentProperty',
    },
    {
      name: 'marginBottom',
      label: 'Bottom',
      control: 'Slider',
      value: '0',
      __typename: 'ComponentProperty',
    },
  ],
  __typename: 'Component',
});

import React from 'react';
import { Icon } from '../../Atoms/Icon';
import { Button } from '../../Atoms/Navigations';
import { ToneDetectorFeedbackType } from './ToneDetectorFeedback.types';
import {
  ToneDetectorFeedbackContainer,
  StyledIcon,
  HeadingContainer,
  StyledDescription,
  StyledHeadingText,
  ToneFeedbackButtonWrapper,
} from './ToneDetectorFeedback.style';

export const ToneDetectorFeedback = ({
  heading,
  feedback,
  buttonText = '',
  handleButtonClick,
  disabled = false,
}: ToneDetectorFeedbackType): JSX.Element => (
  <ToneDetectorFeedbackContainer
    disabled={disabled}
    data-testid='ToneDetectorFeedback'
  >
    <HeadingContainer>
      <StyledIcon>
        <Icon icon='HelpWheel' />
      </StyledIcon>
      <StyledHeadingText>
        <span>{heading}</span>
      </StyledHeadingText>
    </HeadingContainer>

    <StyledDescription>
      <span>{feedback}</span>
      {buttonText && (
        <ToneFeedbackButtonWrapper>
          <Button
            disabled={disabled}
            component='button'
            text={buttonText}
            variant='link'
            onClick={handleButtonClick}
          />
        </ToneFeedbackButtonWrapper>
      )}
    </StyledDescription>
  </ToneDetectorFeedbackContainer>
);

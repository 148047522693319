import { Colors } from '../Foundation/Colors';
import { Elevations } from '../Foundation/Elevations';

// This file is in two places:
// src/Foundation/richTextEditorMenuStyles.ts
// src/DraftailComponents/richTextEditorMenuStyles.tsx
// There are no references to the one in Foundation but tests fail when it's deleted

export const richTextEditorMenuStyles = `
  .Draftail-ToolbarButton {
    background: ${Colors.grey_blue};
    color: ${Colors.white};
    padding: 10px 0;
    border: none;
    width: 42px;
    height: 42px;
    cursor: pointer;
    font-size: 13px;

    &:hover {
      background-color: ${Colors.grey_blue_4}; 
    }

    &[name='BOLD'] {
      font-weight: bold;
    }

    &[name='UNDERLINE'] {
      text-decoration: underline;
    }

    &[name='STRIKETHROUGH'] {
      text-decoration: line-through;
    }

    &[name='SYSTEM_VAR'], &[name='FORM_VAR'] {
      display: none;
    }

    &[name='FORM_VARIABLES'] {
      svg {
        width: 20px;
        height: 20px;
        margin-top: 2px;
      }
    }

    &[name='unordered-list-item'],
    &[name='ordered-list-item']
      margin: 0 12px;
      svg {
        fill: ${Colors.white};
      }
    }
  }

  .Draftail-block {
    &--unstyled,
    &--header-one,
    &--header-two,
    &--header-three {
      font-family: Helvetica, "Helvetica Neue", Arial, Roboto, sans-serif;
      line-height: 1.5;
    }

    &--unstyled {}

    &--header-one,
    &--header-two,
    &--header-three {
      margin: 8px 0;
    }

    &--header-one {
      line-height: 34px;
      font-size: 32px;
      .blockstyle--override {
        font-size: 32px !important;
      }
    }

    &--header-two {
      font-size: 24px;
      line-height: 28px;
      .blockstyle--override {
        font-size: 24px !important;
      }
    }

    &--header-three {
      font-size: 20px;
      line-height: 22px;
      .blockstyle--override {
        font-size: 20px !important;
      }
    }
  }

  .Draftail-Toolbar {
    display: flex;
    visibility: hidden;
    position: absolute;
    white-space: nowrap;
    top: -45px;
    left: 50%;
    transform: translateX(-50%);
    background: ${Colors.grey_blue};
    border-radius: 8px;
    padding: 0 12px;
    box-shadow: ${Elevations.elevation_1};
  }

  .Draftail-ToolbarButton--active {
    background-color: ${Colors.accent};
    &:hover {
      background-color: ${Colors.accent};
    }
  }

  .Draftail-ToolbarButton--active {
    background-color: ${Colors.accent};
    &:hover {
      background-color: ${Colors.accent};
    }
  }

  .Draftail-Editor--focus {
    .Draftail-Toolbar {
      visibility: visible;
    }
  }

  .Draftail-ToolbarGroup {
    display: inline-flex;
    &:after {
      content: '';
      border: 1px solid ${Colors.grey_blue_4};
      margin: 6px 2px;
    }
    &:last-child:after {
      border: none;
      margin: 0;
    }
  }

  [data-draftail-balloon] {
    position: relative;
    cursor: pointer;
  }

  [data-draftail-balloon]::before,
  [data-draftail-balloon]::after {
    content: '';
    position: absolute;
    z-index: 5;
    opacity: 0;
    pointer-events: none;
    font-size: 12px;
    color: ${Colors.grey_blue_4};
    left: 50%;
    transform: translate(-50%, -5px);
  }

  [data-draftail-balloon]::before {
    content: '▲';
    top: 40px;
  }

  [data-draftail-balloon]::after {
    content: attr(aria-label);
    background: ${Colors.black};
    border-radius: 4px;
    color: ${Colors.white};
    padding: 2px 10px;
    white-space: pre;
    overflow: hidden;
    height: 18px;
    line-height: 1.5;
    margin-top: 7px;
    top: 42px;
  }

  [data-draftail-balloon]:hover::before,
  [data-draftail-balloon]:hover::after {
    opacity: 1;
    transition: all 0.1s ease-out 1s;
    transform: translate(-50%, 0);
  }
`;

import React from 'react';

import { ResponsiveBar } from '@nivo/bar';
import {
  exampleBarChartData,
  exampleBarChartLegendItems,
} from '../../Utils/exampleData';

import { Colors } from '../../Foundation/Colors';
import { Icons } from '../../Foundation/Icons';
import { CustomLegends } from '../CustomLegends';
import {
  defaultMargin,
  defaultColors,
  defaultTheme,
  StyledBarChart,
  RelativeWrapper,
  StyledTooltip,
} from './BarChart.styles';
import { BarChartPropTypes, BarData, CustomLegendData } from './BarChart.types';

const { Iux } = Icons;

const prepareCustomLegendData = (
  data: BarData,
  keys: Array<string>
): CustomLegendData => {
  // Because the data in the BarChart will go from bottom to top,
  // the custom legend data will need to be reversed

  const reverseData = data.slice().reverse();
  const customLegendData = [{}];
  reverseData.forEach((item) => {
    keys.map((k) => {
      Object.keys(item).forEach((key) => {
        if (k === key) {
          customLegendData[0][key] = item[key];
        }
      });
    });
  });
  return customLegendData;
};

const thousandSeparator = (num) =>
  num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

const handleCustomTooltip = (
  value,
  indexValue,
  color,
  tooltipLabelValueProvided
) => {
  const MAX_TOOLTIP_LABEL_VALUE_SIZE = 102;
  let tooltipLabel = '';
  if (!tooltipLabelValueProvided) {
    const [channel, status] = indexValue.split(/(?=[A-Z])/);
    tooltipLabel = `${channel.toUpperCase()} ${status}: ${thousandSeparator(
      value
    )}`;
  } else {
    const ellipsisSize = '...'.length;
    const tooltipLabelSize = indexValue.length;
    tooltipLabel =
      tooltipLabelSize < MAX_TOOLTIP_LABEL_VALUE_SIZE
        ? indexValue
        : `${indexValue.slice(
            0,
            MAX_TOOLTIP_LABEL_VALUE_SIZE - ellipsisSize
          )}...`;
  }

  return (
    <StyledTooltip size='16px' color={color}>
      <Iux />
      {tooltipLabel}
    </StyledTooltip>
  );
};

export const BarChart = ({
  width = '100%',
  height = '72px',
  backgroundColor = Colors.white,
  data = exampleBarChartData,
  barComponent,
  keys = ['SMS Sent', 'SMS Received', 'SMS Failed'],
  indexBy = 'type',
  margin = defaultMargin,
  layout = 'horizontal',
  colors = defaultColors,
  enableLabel = false,
  labelFormat = undefined,
  enableGridY = false,
  maxValue,
  axisTop = null,
  axisBottom = null,
  axisLeft = null,
  tooltip,
  theme = defaultTheme,
  isInteractive,
  padding,
  customLegendPosition = 'bottom',
  customLegendItems = exampleBarChartLegendItems,
  total,
  legendTooltipHover = true,
  showCustomLegends = true,
  tooltipLabelValueProvided = false,
  customLayers = [],
}: BarChartPropTypes): JSX.Element => (
  <RelativeWrapper
    height={height}
    width={width}
    customLegendPosition={customLegendPosition}
  >
    <StyledBarChart
      className='barChart'
      width={width}
      height={height}
      backgroundColor={backgroundColor}
      data={data}
      keys={keys}
      indexBy={indexBy}
      margin={margin}
      padding={padding}
      layout={layout}
      colors={colors}
      enableLabel={enableLabel}
      enableGridY={enableGridY}
      maxValue={maxValue}
      axisTop={axisTop}
      axisBottom={axisBottom}
      axisLeft={axisLeft}
      tooltip={tooltip}
      theme={theme}
      isInteractive={isInteractive}
      customLegendPosition={customLegendPosition}
      showCustomLegends={showCustomLegends}
    >
      <ResponsiveBar
        data={data}
        keys={keys}
        indexBy={indexBy}
        margin={margin}
        barComponent={barComponent}
        padding={padding}
        layout={layout}
        colors={colors}
        enableLabel={enableLabel}
        labelFormat={labelFormat}
        enableGridY={enableGridY}
        maxValue={maxValue}
        axisTop={axisTop}
        axisBottom={axisBottom}
        axisLeft={axisLeft}
        tooltip={({ value, indexValue, color }) =>
          handleCustomTooltip(
            value,
            indexValue,
            color,
            tooltipLabelValueProvided
          )
        }
        theme={theme}
        isInteractive={isInteractive}
        layers={['grid', 'axes', 'bars', ...customLayers, 'markers', 'legends']}
      />
    </StyledBarChart>
    {showCustomLegends ? (
      <CustomLegends
        position={customLegendPosition}
        data={prepareCustomLegendData(data, keys)}
        total={total}
        legendItems={customLegendItems}
        tooltipHover={legendTooltipHover}
      />
    ) : null}
  </RelativeWrapper>
);

import { MenuItem } from '@material-ui/core';

import styled from 'styled-components';
import { Colors } from '../../Foundation/Colors';

export const StyledMenuItem = styled(MenuItem)`
  .Icon {
    fill: ${Colors.grey_blue_4};
    margin: 0 14px;
  }
`;

import React, { useState } from 'react';

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import {
  TopLeft,
  TopMiddle,
  TopRight,
  MiddleLeft,
  MiddleMiddle,
  MiddleRight,
  BottomLeft,
  BottomMiddle,
  BottomRight,
} from './assets';

import { PositionSelectorWrapper } from './PositionSelector.style';

export type PlacementValue =
  | 'top-left'
  | 'top-middle'
  | 'top-right'
  | 'middle-left'
  | 'middle-middle'
  | 'middle-right'
  | 'bottom-left'
  | 'bottom-middle'
  | 'bottom-right';

export type PositionSelectorProps = {
  onChange: (value: PlacementValue) => void;
  label?: string;
  defaultValue?: PlacementValue;
};

export const PositionSelector = ({
  label = '',
  onChange = () => undefined,
  defaultValue = 'middle-middle',
}: PositionSelectorProps): JSX.Element => {
  const [{ placement }, setPlacement] = useState({ placement: defaultValue });

  const handleChange = (_event, placement: PlacementValue) => {
    setPlacement({ placement });
    if (placement) {
      onChange(placement);
    }
  };

  return (
    <PositionSelectorWrapper className='text-position-form-control'>
      <span className='placement-label'>{label}</span>
      <ToggleButtonGroup
        className='placement-group'
        value={placement}
        exclusive
        onChange={handleChange}
        aria-label={label}
      >
        <ToggleButton
          className='placement-item'
          classes={{ selected: 'toggle-selected' }}
          value='top-left'
          aria-label='top-left'
        >
          <TopLeft />
        </ToggleButton>
        <ToggleButton
          className='placement-item'
          classes={{ selected: 'toggle-selected' }}
          value='top-middle'
          aria-label='top-middle'
        >
          <TopMiddle />
        </ToggleButton>
        <ToggleButton
          className='placement-item'
          classes={{ selected: 'toggle-selected' }}
          value='top-right'
          aria-label='top-right'
        >
          <TopRight />
        </ToggleButton>
        <ToggleButton
          className='placement-item'
          classes={{ selected: 'toggle-selected' }}
          value='middle-left'
          aria-label='middle-left'
        >
          <MiddleLeft />
        </ToggleButton>
        <ToggleButton
          className='placement-item'
          classes={{ selected: 'toggle-selected' }}
          value='middle-middle'
          aria-label='middle-middle'
        >
          <MiddleMiddle />
        </ToggleButton>
        <ToggleButton
          className='placement-item'
          classes={{ selected: 'toggle-selected' }}
          value='middle-right'
          aria-label='middle-right'
        >
          <MiddleRight />
        </ToggleButton>
        <ToggleButton
          className='placement-item'
          classes={{ selected: 'toggle-selected' }}
          value='bottom-left'
          aria-label='bottom-left'
        >
          <BottomLeft />
        </ToggleButton>
        <ToggleButton
          className='placement-item'
          classes={{ selected: 'toggle-selected' }}
          value='bottom-middle'
          aria-label='bottom-middle'
        >
          <BottomMiddle />
        </ToggleButton>
        <ToggleButton
          className='placement-item'
          classes={{ selected: 'toggle-selected' }}
          value='bottom-right'
          aria-label='bottom-right'
        >
          <BottomRight />
        </ToggleButton>
      </ToggleButtonGroup>
    </PositionSelectorWrapper>
  );
};

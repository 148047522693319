import React from 'react';

import { MultiValueProps } from 'react-select';
import { MenuList, MenuListProps } from '@material-ui/core';
import { StyledReactSelectChip } from '../../Atoms/Forms/StyledReactSelect/StyledReactSelect';
import { SearchIcon } from '../LazyLoadingContactsSelector/LazyLoadingContactsSelector';
import { MultiSelectProps, OptionType } from './MultiSelect.types';
import { StyledSelect } from './MultiSelect.styles';

export const CustomMenuList = (props: MenuListProps) => {
  const { children, innerRef } = props;
  return (
    <MenuList
      innerRef={innerRef}
      style={{ maxHeight: '300px', overflowY: 'auto' }}
    >
      {children}
    </MenuList>
  );
};

export const Chip = (props: MultiValueProps<OptionType>) => {
  const { removeProps, data } = props;
  const { onClick } = removeProps;
  const { label } = data;
  return <StyledReactSelectChip text={`${label}`} onDeleteClick={onClick} />;
};

export const MultiSelect = (props: MultiSelectProps) => {
  const {
    isMulti,
    isClearable,
    isSearchable,
    options,
    placeholder,
    onChangeHandler,
    defaultValue,
  } = props;
  return (
    <StyledSelect
      placeholder={placeholder}
      isMulti={isMulti}
      isClearable={isClearable}
      isSearchable={isSearchable}
      options={options}
      name='multi-select'
      defaultValue={defaultValue}
      components={{
        IndicatorSeparator: null,
        MultiValue: Chip,
        DropdownIndicator: SearchIcon,
        MenuList: CustomMenuList,
      }}
      onChange={(value) => {
        if (!value) {
          onChangeHandler([]);
        } else {
          onChangeHandler(value);
        }
      }}
    />
  );
};

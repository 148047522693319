import React from 'react';
import AsyncPaginate from 'react-select-async-paginate';
import styled from 'styled-components';
import { MenuItem, MenuList } from '@material-ui/core';
import { createStyledReactSelect } from '../../Atoms/Forms/StyledReactSelect/StyledReactSelect';

import { Spinner } from '../../Atoms/Indicators';
import { IconButtonProps, IconButton } from '../../Atoms/Navigations';
import { Colors } from '../../Foundation/Colors';
import { Fonts } from '../../Foundation/Fonts';

export const ContactsSelectorWrapper = styled.div`
  > div:first-child {
    margin-bottom: 8px;
  }
  .dropdown__indicators {
    margin-right: 12px;
  }
`;

export const StyledAsyncPaginate = styled(
  createStyledReactSelect(AsyncPaginate)
)`
  .styled-react-select__menu-notice--loading {
    display: none;
  }
`;

export const StyledIconButton = styled((props: IconButtonProps) => (
  <IconButton {...props} />
))`
  &.is-focused {
    fill: ${Colors.accent};
  }
`;

export const StyledMenuItem = styled((props) => <MenuItem {...props} />)`
  .name {
    ${Fonts.body};
    color: ${Colors.grey_blue};
  }

  .description {
    margin-left: 16px;
    ${Fonts.caption};
    color: ${Colors.grey_blue_4};
  }
`;

export const StyledLoadingIndicator = styled((props) => (
  <MenuItem {...props}>
    <Spinner size='small' /> <span>Loading</span>
  </MenuItem>
))`
  &.MuiMenuItem-root {
    ${Fonts.caption};
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

    > span {
      margin-left: 16px;
    }
  }
`;

export const StyledMenuList = styled(MenuList)`
  max-height: 300px;
  overflow-y: auto;
`;

import React, { ReactElement, useState } from 'react';
import { Dropdown } from '../../Atoms/Forms';
import { IconButton } from '../../Atoms/Navigations';
import { PaginationWrapper } from './Pagination.style';
import { PaginationPropsType } from './Pagination.types';

export const Pagination = ({
  totalDataCount,
  defaultLimit,
  defaultOffset,
  haveBorderTop,
  onChange,
}: PaginationPropsType): ReactElement => {
  const [offset, setOffset] = useState<number>(defaultOffset || 0);
  const [limit, setLimit] = useState<number>(defaultLimit || 20);

  const handleLimitchange = (value) => {
    const valueInt = Number(value);
    // Do not update limit or reset to first page
    // if limit is the same
    if (valueInt === limit) return;

    setLimit(valueInt);

    // reset to first page when limit changed
    setOffset(0);
    return onChange && onChange(valueInt, 0);
  };

  const handleArrowClick = (direction) => {
    const prevPageOffset = offset - limit;
    const nextPageOffset = offset + limit;
    const newOffset = direction === 'next' ? nextPageOffset : prevPageOffset;

    setOffset(newOffset);
    return onChange && onChange(limit, newOffset);
  };

  const totalDataToDisplay = offset + limit;
  const firstDataIndex = offset + 1;
  const totalDataDisplayed =
    totalDataToDisplay > totalDataCount ? totalDataCount : totalDataToDisplay;
  const dropdownOptions = [
    {
      icon: '',
      label: '20',
      value: '20',
    },
    {
      icon: '',
      label: '50',
      value: '50',
    },
    {
      icon: '',
      label: '100',
      value: '100',
    },
  ];

  return (
    <PaginationWrapper className={`${haveBorderTop ? 'border-top' : ''}`}>
      <div className='limit section'>
        <span>Rows per page:</span>
        <Dropdown
          options={dropdownOptions}
          value={`${limit}`}
          onChange={handleLimitchange}
          variant='filled'
        />
      </div>
      <div className='offset section'>
        <span>
          Displaying {firstDataIndex}-{totalDataDisplayed} of {totalDataCount}
        </span>
        <IconButton
          id='arrow-left'
          icon='ChevronLeft'
          className='prev'
          onClick={() => handleArrowClick('prev')}
          disabled={offset < 1}
        />
        <IconButton
          id='arrow-right'
          icon='ChevronRight'
          className='next'
          onClick={() => handleArrowClick('next')}
          disabled={offset >= totalDataCount - limit}
        />
      </div>
    </PaginationWrapper>
  );
};

import React from 'react';

import {
  RatingProps as RatingMUIProps,
  IconContainerProps,
} from '@material-ui/lab';
import {
  RatingStyles,
  RatingWrapper,
  RatingHeading,
  HelperText,
  ErrorText,
} from './Rating.styles';
import { RatingProps } from './Rating.types';

let selectedValue = 0;
const handleChange = (event, index, callback) => {
  const inputEl = event.currentTarget;
  const wrapperEl = inputEl.parentElement;
  const labelsEl = Array.prototype.slice.call(
    wrapperEl.querySelectorAll('label')
  );
  labelsEl.forEach((label) => {
    label.classList.remove('selected');
  });
  const label = wrapperEl.querySelector(
    `label[for='${event.currentTarget.id}']`
  );
  if (label) {
    label.classList.add('selected');
  }

  if (callback) {
    callback(event, index);
  }
};

const handleChangeActive = (event, value) => {
  const parentEl = event.currentTarget;
  const labelsEl = Array.prototype.slice.call(
    parentEl.querySelectorAll('label.MuiRating-label')
  );
  const selectedIndex = labelsEl.findIndex((labelEl) => {
    if (labelEl.classList.contains('selected')) {
      labelEl.classList.remove('selected');
      return true;
    }
  });
  if (selectedIndex > -1) {
    selectedValue = selectedIndex + 1;
  }
  if (value === -1 && selectedValue > 0 && labelsEl.length > 0) {
    labelsEl[selectedValue - 1].classList.add('selected');
  }
};

const getIconContainerComponent = (customIcons) => {
  const IconContainer = (props: IconContainerProps) => {
    const { value, ...other } = props;
    const customIcon = customIcons[value];
    return (
      <>
        <span {...other}>{customIcon.icon}</span>
        <span className='Mui-RatingLabel'>{customIcon.label}</span>
      </>
    );
  };
  return IconContainer;
};

export const Rating = (props: RatingProps): JSX.Element => {
  const {
    customIcons,
    header,
    paddingTop,
    paddingRight,
    paddingBottom,
    paddingLeft,
    helperText,
    errorText,
    callback,
    requiredField,
    ...otherProps
  } = props;

  const commonProps = {
    precision: 1,
    onChange: (event, index) => handleChange(event, index, callback),
    onChangeActive: (event, value) => handleChangeActive(event, value),
  };

  const allProps: RatingMUIProps = commonProps;
  if (customIcons) {
    allProps.IconContainerComponent = getIconContainerComponent(customIcons);
  }

  return (
    <RatingWrapper
      style={{
        paddingTop: `${paddingTop}px`,
        paddingRight: `${paddingRight}px`,
        paddingBottom: `${paddingBottom}px`,
        paddingLeft: `${paddingLeft}px`,
      }}
    >
      {header && (
        <RatingHeading>
          {header} {requiredField && requiredField === 'true' && '*'}
        </RatingHeading>
      )}
      <RatingStyles {...allProps} size='large' {...otherProps} />
      {errorText && <ErrorText>{errorText}</ErrorText>}
      {helperText && <HelperText>{helperText}</HelperText>}
    </RatingWrapper>
  );
};

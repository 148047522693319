import React from 'react';

import { Button } from '../../../Atoms/Navigations';
import { MulipleActionProps } from '../Header.types';

export const SecondaryActions = ({
  actions,
}: MulipleActionProps): JSX.Element => (
  <>
    {actions &&
      actions.length > 0 &&
      actions.map(({ text, disabled, action }) => (
        <Button
          key={text}
          variant='link'
          size='large'
          text={text}
          disabled={disabled}
          onClick={action}
        />
      ))}
  </>
);

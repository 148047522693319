/* eslint-disable react/destructuring-assignment */
import React, { PureComponent } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { v4 as uuid } from 'uuid';
import { SocialIconItemValues } from '../../Organisms/SocialIconsOptionsPicker/SocialIconsOptionsPicker.types';
import { TextField, Checkbox } from '../../Atoms/Forms';
import { Icon } from '../../Atoms/Icon';
import { IconButton } from '../../Atoms/Navigations';

import { TrueFalseString } from '../../DragAndDropComponents/utilityTypes';
import { Colors } from '../../Foundation/Colors';

import {
  ComponentBox,
  DragHandleWrapper,
  ComponentBoxProperties,
  DefaultOptionsWrapper,
  OptionsContainer,
  AddAnotherBtn,
} from './LegacyOptionsPicker.styles';

type Option = {
  id: number | string;
  text: string;
  checked: TrueFalseString;
};

export type OptionPickerProps = {
  filledTextAreaStyle?: boolean;
  componentId: string | number;
  optionsType?: 'radio-buttons/dropdown-menu' | 'checkboxes';
  defaultValue?: Array<Option>;
  onChange?: (valueAsString: string, value: Array<Option>) => void;
  getModifyVariableConfirmationModal?: (id: unknown) => ModalComponent;
  isButtonPicker?: boolean;
  isExtension?: boolean;
  addLinkText: string;
  renderChildren?: (item: Option, updateItem: any) => JSX.Element;
};

type ModalComponent = (props: {
  onConfirm: (...args: Array<unknown>) => void;
  onCancel: (...args: Array<unknown>) => void;
  open: boolean;
}) => JSX.Element;

type State = {
  disableDeleteButton: boolean;
  modifyVariableModal: {
    Modal: ModalComponent;
    index: number;
  } | null;
  items: Array<SocialIconItemValues>;
};

const reorder = (list, startIndex, endIndex): Array<Option> => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result as Array<Option>;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  background: isDragging ? 'none' : 'none',
  ...draggableStyle,
});

const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? `${Colors.accent_2}` : `${Colors.background}`,
  padding: 0,
  width: 250,
});

/**
 * @deprecated
 * Use the `OptionsPicker` component with updated prop types instead.
 * This component exists only to service the Legacy Content Builder,
 * and will be removed after the new Content Builder is deployed.
 *
 * TODO: delete this component after the new Content Builder is deployed.
 */
export class LegacyOptionsPicker extends PureComponent<
  OptionPickerProps,
  State
> {
  // eslint-disable-next-line id-blacklist
  static defaultProps = {
    optionsType: 'radio-buttons/dropdown-menu',
    defaultValue: [
      {
        id: 1,
        text: 'Small',
        checked: 'true',
      },
      {
        id: 2,
        text: 'Medium',
        checked: 'false',
      },
      {
        id: 3,
        text: 'Large',
        checked: 'false',
      },
    ],
    getModifyVariableConfirmationModal: () => null,
    onChange: () => undefined,
    isButtonPicker: false,
  };

  constructor(props: OptionPickerProps) {
    super(props);
    const { defaultValue } = this.props;
    this.state = {
      items:
        typeof defaultValue === 'string'
          ? JSON.parse(defaultValue)
          : defaultValue,
      disableDeleteButton: false,
      modifyVariableModal: null,
    };
    // $FlowFixMe
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  updateItem() {
    // eslint-disable-next-line react/no-access-state-in-setstate
    const updateItems = [...this.state.items];
    this.setState({
      items: updateItems,
    });
  }

  onDragEnd(result: any) {
    const { onChange = () => undefined } = this.props;
    const { items } = this.state;
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const updateItems = reorder(
      items,
      result.source.index,
      result.destination.index
    );

    this.setState({
      items: updateItems,
    });

    onChange(JSON.stringify(updateItems), updateItems);
  }

  doDelete = (index) => {
    const { items } = this.state;
    const { onChange = () => undefined } = this.props;
    const updateItems = [...items];

    if (updateItems.length > 1) {
      updateItems.splice(index, 1);
    }
    this.setState(
      {
        items: updateItems,
      },
      () =>
        updateItems.length <= 1 &&
        this.setState({
          disableDeleteButton: true,
        })
    );

    onChange(JSON.stringify(updateItems), updateItems);
  };

  onDeleteClick = (index: number) => {
    const {
      getModifyVariableConfirmationModal = () => null,
      componentId,
    } = this.props;

    const modal = getModifyVariableConfirmationModal(componentId);
    if (modal) {
      this.setState({
        modifyVariableModal: {
          Modal: modal,
          index,
        },
      });
    } else {
      this.doDelete(index);
    }
  };

  handleTextInput = (index: number) => (event: string) => {
    const { onChange = () => undefined } = this.props;
    const { items } = this.state;
    const updateItems = [...items];
    updateItems[index].text = event;

    this.setState({
      items: updateItems,
    });

    onChange(JSON.stringify(updateItems), updateItems);
  };

  handleFieldChange = (index: number, key: string) => (event: string) => {
    const { onChange = () => undefined } = this.props;
    const { items } = this.state;
    const updateItems = [...items];
    updateItems[index][key] = event;

    this.setState({
      items: updateItems,
    });

    onChange(JSON.stringify(updateItems), updateItems);
  };

  handleDefaultOption = (index: number) => (_event, nowChecked) => {
    const { onChange = () => undefined, optionsType } = this.props;
    const { items } = this.state;
    const updateItems = [...items];

    if (optionsType === 'radio-buttons/dropdown-menu') {
      updateItems.forEach((item) => {
        item.checked = 'false';
      });
    }

    updateItems[index].checked = `${nowChecked}` as TrueFalseString;

    this.setState({
      items: updateItems,
    });

    onChange(JSON.stringify(updateItems), updateItems);
  };

  handleAddAnotherOption = () => {
    const { onChange = () => undefined } = this.props;
    const { items } = this.state;
    const updateItems = [...items];
    const defaultOption = {
      id: uuid(),
      text: 'Option text',
      checked: 'false' as const,
    };

    updateItems.push(defaultOption);

    this.setState({
      items: updateItems,
      disableDeleteButton: false,
    });

    onChange(JSON.stringify(updateItems), updateItems);
  };

  onModalConfirm = () => {
    const { modifyVariableModal } = this.state;
    if (!modifyVariableModal) {
      throw new Error(
        "Attempting to call onModalConfirm when modifyVariableModal doesn't exist"
      );
    }
    const { index } = modifyVariableModal;
    this.doDelete(index);
    this.setState({ modifyVariableModal: null });
  };

  onModalCancel = () => {
    this.setState({
      modifyVariableModal: null,
    });
  };

  render() {
    const { items, disableDeleteButton, modifyVariableModal } = this.state;
    const {
      filledTextAreaStyle,
      isButtonPicker,
      isExtension,
      renderChildren,
      addLinkText = 'Add Another Option',
    } = this.props;

    return (
      <OptionsContainer>
        {modifyVariableModal && (
          <modifyVariableModal.Modal
            onConfirm={this.onModalConfirm}
            onCancel={this.onModalCancel}
            open={!!modifyVariableModal}
          />
        )}
        {isButtonPicker == false ? (
          <>
            <DragDropContext onDragEnd={this.onDragEnd}>
              <Droppable droppableId='droppable'>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {items.map((item, index) => (
                      <Draggable
                        key={item.id}
                        draggableId={item.id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <ComponentBox
                              filledTextAreaStyle={filledTextAreaStyle}
                              isExtension={isExtension}
                            >
                              <DragHandleWrapper>
                                <Icon icon='DragHandle' />
                              </DragHandleWrapper>
                              <ComponentBoxProperties>
                                {!isExtension && (
                                  <TextField
                                    label={
                                      filledTextAreaStyle
                                        ? 'Option Text'
                                        : undefined
                                    }
                                    defaultValue={item.text}
                                    onChange={this.handleTextInput(index)}
                                    variant={
                                      filledTextAreaStyle ? 'filled' : undefined
                                    }
                                  />
                                )}
                                {isExtension &&
                                  renderChildren &&
                                  renderChildren(
                                    item,
                                    this.handleFieldChange.bind(this, index)
                                  )}
                                <DefaultOptionsWrapper
                                  disableDeleteButton={disableDeleteButton}
                                  isExtension={isExtension}
                                >
                                  {!isExtension && (
                                    <Checkbox
                                      label='Default Option'
                                      ariaLabel={`${item.text} default checked`}
                                      isChecked={item.checked === 'true'}
                                      onChange={this.handleDefaultOption(index)}
                                    />
                                  )}
                                  <IconButton
                                    icon='Delete'
                                    className='options-picker__delete-btn'
                                    onClick={() => this.onDeleteClick(index)}
                                  />
                                </DefaultOptionsWrapper>
                              </ComponentBoxProperties>
                            </ComponentBox>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>

            <AddAnotherBtn type='button' onClick={this.handleAddAnotherOption}>
              <Icon icon='Plus' />
              <p>{addLinkText}</p>
            </AddAnotherBtn>
          </>
        ) : (
          <>
            {items.map((item, index) => (
              <TextField
                variant='filled'
                key={item.id}
                label={
                  filledTextAreaStyle ? `Button Label ${index + 1}` : undefined
                }
                defaultValue={item.text}
                onChange={this.handleTextInput(index)}
              />
            ))}
          </>
        )}
      </OptionsContainer>
    );
  }
}

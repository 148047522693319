import { localStorageLookup, writeToLocalStorage } from '..';

export const hydratesUpdatedWorkspacesAndReload = (
  selectedItemId: string,
  workspaces: Object[] = []
) => {
  const updatedList = workspaces.map(({ id, name, isSelected }) => ({
    id,
    name,
    isSelected: id === selectedItemId,
  }));
  writeToLocalStorage(updatedList, localStorageLookup.shared);
  redirectToDashboard();
};

export const redirectToDashboard = () => {
  const {
    location,
    location: { pathname, href },
  } = window;
  const pathList = pathname.split('/');
  const urlList = href.split('/');

  if (pathList.length > 1) {
    location.assign(`${urlList[0]}//${urlList[2]}/${pathList[1]}`);
  } else {
    location.reload();
  }
};

export const workspacesObjFormater = (workspaces = []) =>
  workspaces.map(({ name, id: selectedItemId, isSelected }) => ({
    title: name,
    isActive: isSelected,
    action: () =>
      hydratesUpdatedWorkspacesAndReload(selectedItemId, workspaces),
  }));

export function checkForContextChange({
  currentUser,
  newData,
  localWorkspaces,
  userContext,
}) {
  // update the context, if the user or workspaces have changed
  const { user } = userContext || {};
  const { userId } = user || {};
  if (currentUser.userId !== userId) {
    return { user: currentUser, workspaces: newData };
  } else if (!localWorkspaces) {
    return { user: currentUser, workspaces: newData };
  }
  const matchingWorkspaces = newData.filter(
    ({ id: workspaceId }) =>
      !!localWorkspaces.find(
        ({ id: localWorkspaceId }) => localWorkspaceId === workspaceId
      )
  );
  if (
    matchingWorkspaces.length !== localWorkspaces.length ||
    matchingWorkspaces.length !== newData.length
  ) {
    return { user: currentUser, workspaces: newData };
  }

  return null;
}

export function getSelectedWorkspaceId({
  currentWorkspaces,
  localWorkspaces,
  defaultWorkspace,
}) {
  // find the selected workspace
  const { workspaceId: defaultWorkspaceId } = defaultWorkspace || {};
  const localWorkspace = localWorkspaces
    ? localWorkspaces.find((workspace) => workspace.isSelected)
    : {};
  const { id: localWorkspaceId } = localWorkspace || {};
  const selectedWorkspace = currentWorkspaces.find(
    ({ workspaceId }) => workspaceId === localWorkspaceId
  );

  return selectedWorkspace ? localWorkspaceId : defaultWorkspaceId;
}

export function mapData({ currentWorkspaces, selectedWorkspaceId }) {
  // map the selected workspace to storage
  const data = currentWorkspaces.map(({ workspaceId, workspaceName }) => ({
    id: workspaceId,
    name: workspaceName,
    isSelected: selectedWorkspaceId === workspaceId,
  }));

  const selectedExists = data.find(({ isSelected }) => isSelected);
  if (!selectedExists && data.length > 0) {
    data[0].isSelected = true;
  }

  return data;
}

import styled from 'styled-components';
import { Colors } from '../../Foundation/Colors';
import { Fonts } from '../../Foundation/Fonts';

export const AlertWrapper = styled.div`
  .simple-banner {
    align-items: center;
  }

  .alert {
    ${Fonts.body};
    line-height: 20px;
    color: ${Colors.grey_blue};
    padding: 10px;
  }

  .icon {
    padding: 0;
    margin-right: 16px;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .action {
    align-items: start;

    .medium {
      width: 28px;
      height: 28px;

      svg {
        fill: ${Colors.grey_blue};
      }
    }
  }

  .title {
    margin: 0 0 8px 0;
    ${Fonts.link}
  }

  &&.banner {
    .message {
      padding: 0;
    }
  }

  &&.inline {
    .message {
      padding: 0;
    }
  }

  .info {
    background-color: ${Colors.accent_1};

    svg {
      fill: ${Colors.accent};
    }
  }

  .warning {
    background-color: ${Colors.warning_light};

    svg {
      fill: ${Colors.warning};
    }
  }

  .error {
    background-color: ${Colors.error_light};

    svg {
      fill: ${Colors.error};
    }
  }

  .success {
    background-color: ${Colors.success_light};

    svg {
      fill: ${Colors.success};
    }
  }

  .tip {
    background-color: ${Colors.grey_blue_0};

    svg {
      fill: ${Colors.grey_blue_4};
    }
  }
`;

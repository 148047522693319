import React, { ReactNode } from 'react';
import {
  ErrorBoundary as ReactErrorBoundary,
  ErrorBoundaryPropsWithComponent,
  FallbackProps,
} from 'react-error-boundary';

// Just add typings to the window object.
declare global {
  interface Window {
    newrelic: {
      noticeError: (error: any) => void;
    };
  }
}

const ErrorFallback = ({ error }) => {
  // Report errors to newrelic, if it exists.
  if (window.newrelic && window.newrelic.noticeError) {
    window.newrelic.noticeError(error);
  }

  return (
    <div role='alert' className='error-fallback'>
      <h1>Something went wrong:</h1>
      <pre style={{ color: 'red' }}>{error.message}</pre>
    </div>
  );
};

type Props = Omit<ErrorBoundaryPropsWithComponent, 'FallbackComponent'> & {
  children: ReactNode;
  FallbackComponent?: React.ComponentType<FallbackProps>;
};

export const ErrorBoundary = ({
  children,
  FallbackComponent = ErrorFallback,
  ...props
}: Props) => (
  <ReactErrorBoundary FallbackComponent={FallbackComponent} {...props}>
    {children}
  </ReactErrorBoundary>
);

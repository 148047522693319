import React, { createContext, ReactNode, useContext } from 'react';

export const SideDrawerContext = createContext<SideDrawerContextType>({
  content: null,
  setContent: () => undefined,
});

export const useSideDrawerContext = () => useContext(SideDrawerContext);

// What this scary looking thing does is just inject the `sideDrawer` property to the component you passed in,
// And return a component that doesn't require that property.
// See: https://github.com/typescript-cheatsheets/react-typescript-cheatsheet/blob/master/docs/hoc/excluding-props.md
// We can create a better abstraction so that we don't need to copy paste this everytime we want to create a new one of these.

type DerivedProps<T extends {}, U extends T> = Omit<U, keyof T>;

export const withSideDrawerContext = <
  T extends { drawerContext: SideDrawerContextType }
>(
  Component: React.ComponentType<T>
) => {
  return (
    newComponentProps: DerivedProps<{ drawerContext: SideDrawerContextType }, T>
  ) => {
    return (
      <SideDrawerContext.Consumer>
        {(context: SideDrawerContextType) => {
          const newProps = {
            ...newComponentProps,
            drawerContext: context,
          } as T;
          return <Component {...newProps} />;
        }}
      </SideDrawerContext.Consumer>
    );
  };
};

export type SideDrawerContextType = {
  content: ReactNode;
  setContent: (content: ReactNode) => void;
};

/* eslint-disable no-underscore-dangle */

import React, { useCallback } from 'react';
import { OptionProps, MultiValueProps } from 'react-select';
import { Chip } from '../../Atoms/DataDisplay';
import { DropdownAdornment } from '../../Atoms/Forms';
import { StyledReactSelectChip } from '../../Atoms/Forms/StyledReactSelect/StyledReactSelect';
import { Icon } from '../../Atoms/Icon';
import { Colors } from '../../Foundation/Colors';
import { VariableChip } from '../../Molecules/VariableChip';
import { VariableSelectorGroupTitle } from '../../Molecules/VariableSelectorPopover';
import {
  initialiseSelectedOptions,
  convertSelectedOptions,
  convertAvailableOptions,
  convertValuesForHandleChange,
} from './utils';
import {
  StyledMenuList,
  StyledMenuItem,
  StyledCreatableSelect,
} from './AdhocVariableSelector.styles';
import {
  ReactSelectValue,
  AdhocVariableSelectorProps,
  OurReactSelectGroupLabel,
  AdhocValidator,
} from './AdhocVariableSelector.types';

const AdhocChip = (props: MultiValueProps<ReactSelectValue>) => {
  const { data, removeProps } = props;
  const { label, value } = data;
  const { onClick } = removeProps;
  const isAdhoc = '__isNew__' in data;
  const isError = '__isError__' in data;

  if (isAdhoc) {
    return (
      <StyledReactSelectChip
        text={label}
        color='accent'
        onDeleteClick={onClick}
      />
    );
  }

  const { variableName, groupType, groupName, variableId, type } = JSON.parse(
    value
  );

  if (isError) {
    return (
      <VariableChip
        color='error'
        variable={{
          groupType,
          variableName,
          type,
          variableId,
          groupName,
        }}
        onDeleteClick={onClick}
      />
    );
  }

  return (
    <VariableChip
      variable={{
        groupType,
        variableName,
        type,
        variableId,
        groupName,
      }}
      onDeleteClick={onClick}
    />
  );
};

const AdhocOptionLabel = (
  props: OptionProps<ReactSelectValue> & { adhocValidator: AdhocValidator }
) => {
  const { data, adhocValidator, isFocused, innerProps } = props;
  const { label, value } = data;

  if ('__isNew__' in data) {
    const showErrorMessage = !adhocValidator(value).valid && props.isFocused;
    return (
      <StyledMenuItem
        {...innerProps}
        disabled={showErrorMessage}
        selected={props.isFocused}
      >
        <span className='create-label'>Send to: &nbsp;</span>
        <Chip text={value} color='accent' />
        {isFocused && (
          <div className='validation-indicator'>
            {showErrorMessage ? (
              <Icon icon='WarningOutline' style={{ fill: Colors.error }} />
            ) : (
              <Icon icon='Tick' style={{ fill: Colors.success }} />
            )}
          </div>
        )}
      </StyledMenuItem>
    );
  }
  return (
    <StyledMenuItem {...innerProps} selected={isFocused}>
      <Chip text={label} color='variable' />
    </StyledMenuItem>
  );
};

export const AdhocVariableSelector = (
  props: AdhocVariableSelectorProps
): JSX.Element => {
  const {
    availableOptions,
    selectedOptions,
    onChange,
    placeholder = 'Select...',
    adhocValidator = () => ({ type: '', valid: true }),
    variableEnabled,
    adhocValidatorOptions,
  } = props;

  const intialSelectedOptions = initialiseSelectedOptions(
    selectedOptions,
    availableOptions
  );

  const convertedSelectedOptions = convertSelectedOptions(
    intialSelectedOptions
  );

  const convertedAvailableOptions = convertAvailableOptions(availableOptions);

  const handleChange = useCallback(
    (values: Array<ReactSelectValue>) => {
      if (values === null) {
        return onChange([]);
      }
      const convertedValues = convertValuesForHandleChange(
        values,
        adhocValidator,
        adhocValidatorOptions
      );
      onChange(convertedValues);
    },
    [adhocValidator, adhocValidatorOptions, onChange]
  );

  const createOptionLabel = useCallback(
    (props) => {
      return <AdhocOptionLabel {...props} adhocValidator={adhocValidator} />;
    },
    [adhocValidator]
  );

  return (
    <StyledCreatableSelect
      isMulti
      options={variableEnabled && convertedAvailableOptions}
      value={convertedSelectedOptions}
      onChange={handleChange}
      placeholder={placeholder}
      formatGroupLabel={(groupData: OurReactSelectGroupLabel) => {
        return (
          <VariableSelectorGroupTitle
            groupName={groupData.groupLabel.name}
            groupType={groupData.groupLabel.type}
            groupCount={groupData.options.length}
          />
        );
      }}
      components={{
        Option: createOptionLabel,
        MultiValue: AdhocChip,
        DropdownIndicator: DropdownAdornment,
        IndicatorSeparator: null,
        MenuList: StyledMenuList,
      }}
    />
  );
};

import React from 'react';
import { v4 as uuid } from 'uuid';
import { Tooltip } from '../../Atoms/Dialogs';
import { Icon } from '../../Atoms/Icon';
import { EntityDataType } from '../tovDraftailUtils';
import { VariableChip } from '../../Molecules/VariableChip';
import { getTovIconDark } from '../../Utils/ToneOfVoice/toneOfVoiceUtils';
import {
  StyledVariableChip,
  TovHighlightTooltipWrapper as TovHighlightTooltipTone,
  TovTextHighlight,
} from './TovHighlight.styles';

export const TovHighlight = ({ entityKey, contentState, children }) => {
  const data: EntityDataType = contentState.getEntity(entityKey).getData();

  const { selectedTone, selectedToneScore, sentenceTones, childData } = data;

  const renderChildrenWithVariables = () => {
    const { text, ranges, entityMap } = childData;

    // if no ranges, no variables, so can just return children
    if (!ranges.length) return children;

    // split the text around variable ranges
    const splitText: Array<{
      text: string;
      key: number;
    }> = ranges.reduce((previousSplitText, { offset, length, key }, i) => {
      const previousSplitIndex =
        i === 0
          ? 0
          : previousSplitText[previousSplitText.length - 1].previousSplitIndex;

      previousSplitText.push({
        text: text.slice(previousSplitIndex, offset),
        key: -1,
        previousSplitIndex,
      });

      // always split around the variable
      previousSplitText.push({
        text: text.slice(offset, offset + length),
        key,
        previousSplitIndex: offset + length,
      });

      if (i === ranges.length - 1 && offset + length !== text.length) {
        // if sentence doesn't end with a variable, need to split from end of variable to end of sentence
        previousSplitText.push({
          text: text.slice(offset + length, text.length),
          key: -1,
          previousSplitIndex,
        });
      }

      return previousSplitText;
    }, [] as Array<{ text: string; key: number; previousSplitIndex: number }>);

    return splitText.map(({ text, key }) => {
      if (Object.keys(entityMap).includes(`${key}`)) {
        return (
          <StyledVariableChip key={`${text}_${key}`}>
            <VariableChip variable={entityMap[key]} labelOverride={text} />
          </StyledVariableChip>
        );
      }

      return <span key={`${text}_${key}`}>{text}</span>;
    });
  };

  // don't highlight sentences with 'Low' score for the selected tone,
  // but still render variables
  if (selectedToneScore < 0.33) return renderChildrenWithVariables();

  // put the selected tone at the top of the array
  const toneObj = sentenceTones.find((el) => el.tone === selectedTone);
  if (toneObj) {
    sentenceTones.splice(sentenceTones.indexOf(toneObj), 1);
    sentenceTones.unshift(toneObj);
  }

  const tooltipTitle = sentenceTones.map(
    ({ tone, score, desiredToneIntensity }) => {
      const intensity = score < 0.66 ? 'Moderate' : 'Strong';
      const toneIcon = getTovIconDark(desiredToneIntensity, intensity);

      return (
        <TovHighlightTooltipTone
          key={`${tone}_${score}_${desiredToneIntensity}`}
          toneIcon={toneIcon}
          isSelectedTone={selectedTone === tone}
        >
          <div className='tone-display'>
            <span className='tone'>{tone}</span>
            <span className='pill'>
              <span className='icon'>
                <Icon icon={toneIcon} />
              </span>
              <span className='intensity'>{intensity}</span>
            </span>
          </div>
        </TovHighlightTooltipTone>
      );
    }
  );

  return data ? (
    <Tooltip
      key={uuid()}
      arrow
      ariaLabel='Sentence Tone Highlight'
      title={tooltipTitle}
      placement='bottom'
      padding='4px 16px'
      interactive
    >
      <TovTextHighlight
        aria-label='SMS sentence tone highlight'
        selectedToneScore={selectedToneScore}
      >
        {renderChildrenWithVariables()}
      </TovTextHighlight>
    </Tooltip>
  ) : (
    children
  );
};

import React, { useState, Fragment } from 'react';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import { FormHelperText, FormLabel } from '@material-ui/core';
import {
  RadioButtonGroupWrapper,
  ComponentWrapper,
} from './RadioButtonGroup.style';

type RadioButtonGroupProps = {
  label?: string;
  helperText?: string;
  compact?: boolean;
  items: Array<{
    label: string;
    value: unknown;
  }>;
  defaultValue?: unknown;
  onChange?: (...args) => void;
  component?: React.ReactNode;
};

export const RadioButtonGroup = ({
  label,
  helperText,
  compact = false,
  items = [],
  defaultValue = null,
  onChange = () => undefined,
  component: Component,
}: RadioButtonGroupProps): JSX.Element => {
  const [value, setValue] = useState(
    defaultValue ? defaultValue : items[0] ? items[0].value : null
  );

  const handleChange = ({ target: { value } }) => {
    setValue(value);
    onChange(value);
  };

  const isActiveOption = (optionValue) => optionValue === value;

  return (
    <RadioButtonGroupWrapper compact={compact}>
      {items.length ? (
        <FormControl component='fieldset' className='button-group-form-control'>
          <FormLabel component='legend'>{label}</FormLabel>
          <RadioGroup
            aria-label='radioGroupButton'
            name='radioGroupButton'
            value={value}
            onChange={handleChange}
          >
            {items.map(({ label, value }) => (
              <Fragment key={label}>
                <FormControlLabel
                  value={value}
                  control={
                    <Radio
                      size={compact ? 'small' : undefined}
                      color='default'
                      classes={{
                        root: 'root',
                        checked: 'checked',
                      }}
                    />
                  }
                  label={label}
                />
                {Component && isActiveOption(value) && (
                  <ComponentWrapper compact={compact}>
                    {Component}
                  </ComponentWrapper>
                )}
              </Fragment>
            ))}
          </RadioGroup>
          <FormHelperText>{helperText}</FormHelperText>
        </FormControl>
      ) : (
        <span>No items found in the Radio Button list</span>
      )}
    </RadioButtonGroupWrapper>
  );
};

import React, { useState } from 'react';

import { default as MuiSlider } from '@material-ui/core/Slider';
import { FormHelperText } from '@material-ui/core';
import { TextField } from '../Forms';
import { SliderWrapper } from './Slider.style';

type Props = {
  label?: string;
  defaultValue?: number;
  onChange?: (value: number) => void;
  step?: number;
  maxValue?: number;
  minValue?: number;
  units?: string;
};

// UNCONTROLLED COMPONENT! :(
export const Slider = ({
  label = '',
  onChange = () => undefined,
  defaultValue = 0,
  maxValue = 100,
  minValue = 1,
  step = 1,
  units,
}: Props): JSX.Element => {
  const [value, setValue] = useState(defaultValue);

  const handleChange = (value: number) => {
    setValue(value);

    // Only call onChange if value is a number
    if (!isNaN(value)) {
      // Only call onChange if value is within threshold
      if (value >= minValue && value <= maxValue) {
        onChange(value);
      }
    }
  };

  const handleSliderChange = (_event, value: number | Array<number>) => {
    if (Array.isArray(value)) {
      throw new Error(
        'Got an array of numbers as a value, we were not expecting that'
      );
    }

    handleChange(value);
  };

  const handleTextChange = (value: string) => {
    const parsedValue = parseInt(value, 10);
    handleChange(parsedValue);
  };

  return (
    <SliderWrapper className='slider-form-control'>
      <FormHelperText className='slider-label' component='label' filled>
        {label}
      </FormHelperText>
      <div className='slider-input-wrapper'>
        <MuiSlider
          className='slider-controller'
          value={value}
          step={step}
          onChange={handleSliderChange}
          min={minValue}
          max={maxValue}
          classes={{
            thumb: 'thumb',
          }}
          aria-label={label}
        />
        <TextField
          value={value.toString()}
          type='number'
          onChange={handleTextChange}
          fullWidth={false}
          inputProps={{
            className: 'slider-input',
            'aria-label': label,
            max: maxValue,
            min: minValue,
          }}
          InputProps={{
            endAdornment: units ? (
              <span className='input-adornment'>{units}</span>
            ) : null,
          }}
          variant='filled'
        />
      </div>
    </SliderWrapper>
  );
};

import styled from 'styled-components';
import { Colors } from '../../Foundation/Colors';
import { Fonts } from '../../Foundation/Fonts';

export const TextInputWrapper = styled.div<{
  paddingTop: string;
  paddingBottom: string;
}>`
  width: 100%;
  padding: ${(props) => props.paddingTop}px 20px
    ${(props) => props.paddingBottom}px;
  ${Fonts.body};
  position: relative;

  .MuiFormControl-root {
    width: 100%;
  }

  .MuiInputBase-input {
    color: ${Colors.grey_blue};
  }
  .MuiTextField-root {
    .MuiFormHelperText-contained {
      margin: 8px 0 0 0;
    }
    .MuiOutlinedInput-notchedOutline {
      border-radius: 8px;
    }
  }
  p.MuiFormHelperText-root {
    margin-top: 8px;
  }

  .MuiFormLabel-root {
    ${Fonts.body};
    color: ${Colors.grey_blue_4};
  }
  .MuiInputLabel-formControl {
    position: static;
  }
  label + .MuiInput-formControl {
    margin-top: -4px;
  }
`;

import { Colors } from '../../../Foundation/Colors';

import { ModelType } from '../../utilityTypes';

export const richSubmitButtonBlockModel = (id: string): ModelType => ({
  id,
  type: 'SubmitButton',
  label: 'Submit Button',
  properties: [
    {
      name: 'text_1',
      label: 'Styles',
      control: 'TitleDivider',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'label',
      label: 'Button Text',
      control: 'TextFieldFilled',
      value: 'Submit',
      __typename: 'ComponentProperty',
    },
    {
      name: 'text_2',
      label: 'Options',
      control: 'TitleDividerNoLine',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'textColor',
      label: 'Text Color',
      control: 'ColorPicker',
      value: Colors.white,
      __typename: 'ComponentProperty',
    },
    {
      name: 'backgroundColor',
      label: 'Background Color',
      control: 'ColorPicker',
      value: Colors.accent,
      __typename: 'ComponentProperty',
    },
    {
      name: 'padding_8px_2',
      label: '',
      control: 'PaddingControl8px',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'fullWidth',
      label: 'Full Width',
      control: 'ToggleSwitch',
      value: 'false',
      __typename: 'ComponentProperty',
    },
    {
      name: 'padding',
      label: 'Padding',
      control: 'TitleDividerNoLine',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'marginTop',
      label: 'Top',
      control: 'Slider',
      value: '20',
      __typename: 'ComponentProperty',
    },
    {
      name: 'marginBottom',
      label: 'Bottom',
      control: 'Slider',
      value: '20',
      __typename: 'ComponentProperty',
    },
  ],
  __typename: 'Component',
});

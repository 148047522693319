import { Input } from '@material-ui/core';
import styled from 'styled-components';
import { Colors } from '../../../Foundation/Colors';

export const StyledSearchField = styled(Input)`
  &.MuiInputBase-root {
    border-radius: 4px;
    color: ${Colors.grey_blue_4};
    background-color: ${Colors.grey_blue_0};
    overflow: hidden;
    height: 40px;

    & svg {
      height: 16px;
      width: 16px;
      fill: ${Colors.grey_blue_4};
    }

    &.Mui-focused {
      & svg {
        fill: ${Colors.accent};
      }

      &:before {
        border-bottom: 2px solid ${Colors.accent};
      }

      &:after {
        border-bottom: 1px solid ${Colors.accent};
      }

      input[type='search']:focus::-webkit-search-cancel-button {
        display: none;
      }
    }

    & .MuiInputAdornment-root {
      width: 18px;
    }

    &:before {
      border-bottom: 0;
    }

    &:after {
      border-bottom: 0;
    }

    &:hover {
      &:not(.Mui-disabled):before {
        border-bottom: 2px solid ${Colors.accent_dark};
      }

      & svg {
        fill: ${Colors.accent_dark};
      }
    }

    & ::placeholder {
      color: ${Colors.grey_blue};
      font-family: Inter;
      font-size: 14px;
      line-height: 24px;
    }

    input {
      padding: 9px 8px;
      font-family: Inter;
      font-size: 14px;
      line-height: 24px;
      color: ${Colors.grey_blue};

      ::-webkit-search-cancel-button {
        display: none;
      }
    }

    .end-adornment {
      display: flex;
      align-items: center;

      .search-icon {
        margin-right: 16px;
      }
    }

    .clear-search-button {
      margin-right: 8px;
      display: none;

      &.show-clear-when-inactive {
        display: block;
      }

      path:first-of-type {
        fill: ${Colors.grey_blue_4};
      }
    }

    &.Mui-focused,
    &:active {
      .clear-search-button {
        display: block;
      }
    }
  }
`;

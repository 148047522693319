import React, { useState } from 'react';

import { StandaloneSearchBox, useLoadScript } from '@react-google-maps/api';

import { TextField } from '../../Atoms/Forms';
import { MAP_DEFAULT_LOCATION, mapLoadScriptConfig } from '../GoogleMap';
import type { GoogleAutoSuggestProps } from './GoogleAutoSuggest.types';

export const GoogleAutoSuggestStandalone = (props: GoogleAutoSuggestProps) => {
  const { label, onChange } = props;
  const [searchBox, setSearchBox] = useState({
    getPlaces: () => [
      {
        geometry: {
          location: {
            toJSON: () => MAP_DEFAULT_LOCATION,
          },
        },
      },
    ],
  });

  const reverseGeocode = () => {
    return JSON.stringify(searchBox.getPlaces()[0].geometry.location.toJSON());
  };

  const onLoad = (ref) => {
    setSearchBox(ref);
  };

  const onPlacesChanged = () => {
    onChange(reverseGeocode());
  };

  return (
    <StandaloneSearchBox onLoad={onLoad} onPlacesChanged={onPlacesChanged}>
      <TextField label={label} placeholder='Enter location' variant='filled' />
    </StandaloneSearchBox>
  );
};

export const GoogleAutoSuggest = (
  props: GoogleAutoSuggestProps
): JSX.Element => {
  const { isLoaded } = useLoadScript(mapLoadScriptConfig);

  if (isLoaded) {
    return <GoogleAutoSuggestStandalone {...props} />;
  }
  return <div></div>;
};

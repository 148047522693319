import styled from 'styled-components';
import { Colors } from '../../../Foundation/Colors';

// The combo of padding-top and margin allows for resizing and position of the svgs.
export const StyledDropdownAdornment = styled.button`
  cursor: pointer;
  background-color: ${Colors.voice};
  width: 36px;
  height: 18px;
  border-radius: 24px;
  border: none;
  color: ${Colors.white};
  padding: 0 6px;

  svg {
    fill: ${Colors.white};
    width: 100%;
    height: 100%;
  }

  :hover {
    background-color: ${Colors.voice};
  }
`;

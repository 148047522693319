import styled from 'styled-components';

import { Colors } from '../../Foundation/Colors/Colors';
import { Icon } from '../../Atoms/Icon';
import { Fonts } from '../../Foundation/Fonts';
import { Button } from '../../Atoms/Navigations/Button';

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.25rem;
`;

export const Title = styled.div`
  ${Fonts.link}
`;

export const StyledButton = styled(Button)`
  button {
    min-width: inherit;

    &.MuiButton-root {
      padding-left: 0.75rem;
    }
  }
`;

export const ModalHeaderWrapper = styled.div`
  svg {
    max-width: 120px;
    width: 100%;
    height: auto;
  }

  display: flex;
  justify-content: center;
  padding-left: 38px;
  margin-bottom: 1.5rem;
`;

export const ModalButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  > * + * {
    margin-left: 1rem;
  }
`;

export const ModalContentWrapper = styled.div`
  ${Fonts.body}

  > * {
    margin-bottom: 0.75rem;

    &:first-child {
      ${Fonts.headline}
    }

    &:last-child {
      margin-bottom: 1.5rem;
    }
  }
`;

export const DisplayImageWrapper = styled.div`
  width: 100%;
  height: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: linear-gradient(
      45deg,
      rgba(0, 0, 0, 0.0980392) 25%,
      transparent 25%,
      transparent 75%,
      rgba(0, 0, 0, 0.0980392) 75%,
      rgba(0, 0, 0, 0.0980392) 0
    ),
    linear-gradient(
      45deg,
      rgba(0, 0, 0, 0.0980392) 25%,
      transparent 25%,
      transparent 75%,
      rgba(0, 0, 0, 0.0980392) 75%,
      rgba(0, 0, 0, 0.0980392) 0
    ),
    white;
  background-repeat: repeat, repeat;
  background-position: 0px 0, 10px 10px;
  transform-origin: 0 0 0;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
  background-size: 20px 20px, 20px 20px;
  box-shadow: none;
  text-shadow: none;
  transition: none;
  transform: scaleX(1) scaleY(1) scaleZ(1);

  img {
    width: auto;
    height: 100%;
  }
`;

export const ImagePlaceholder = styled(Icon)`
  width: 60px;
  height: 60px;
  margin-bottom: 0.5rem;

  path {
    fill: ${Colors.accent_4};
  }
`;

import React, { useState } from 'react';
import { Icon } from '../../Atoms/Icon';
import { Button } from '../../Atoms/Navigations';
import { TovInfoBannerWrapper } from './ToneOfVoiceInfoBanner.styles';

export type ToneOfVoiceInfoBannerProps = {
  handleSetTovButtonClick: () => void;
  handleLearnMoreButtonClick: () => void;
  onClose?: () => void;
  variant: 'small' | 'large';
};

export const ToneOfVoiceInfoBanner = (props: ToneOfVoiceInfoBannerProps) => {
  const {
    handleSetTovButtonClick,
    handleLearnMoreButtonClick,
    onClose,
    variant,
  } = props;
  const [showBanner, toggleBanner] = useState(true);

  const text = `Your brand’s Tone of Voice hasn’t been set up yet. Set your brand’s Tone
        of Voice now and understand how your message may sound to readers
        ${
          variant === 'large'
            ? ' and ensure that it aligns with your brand’s voice'
            : ''
        }.`;

  const textElement = (
    <>
      {variant === 'large' && (
        <span className='emphasis-text'>Please note:&nbsp;</span>
      )}
      <span>{text}</span>
    </>
  );

  const handleClose = () => {
    toggleBanner(false);
    if (onClose) onClose();
  };

  return (
    <TovInfoBannerWrapper
      variant={variant}
      aria-label='TOV info banner'
      className='tov-info-banner'
      style={{
        display: showBanner ? 'flex' : 'none',
      }}
    >
      <span className='info-icon-wrapper'>
        <Icon icon='InfoOutline' />
      </span>
      <div className='tov-info-banner-text'>
        <span>{textElement}</span>
        <span className='tov-info-banner-buttons'>
          <Button
            component='button'
            text='Set Tone of Voice'
            variant={variant === 'large' ? 'button' : 'link'}
            onClick={handleSetTovButtonClick}
            size='medium'
            startIcon={variant === 'large' ? 'ToneOfVoice' : undefined}
          />
          {/* {variant === 'large' && (
            // commenting out while Learn more button link is unavailable
            <Button
              component='button'
              text='Learn More'
              variant='link'
              onClick={handleLearnMoreButtonClick}
              size='medium'
            />
          )} */}
        </span>
      </div>
      {variant === 'large' && (
        <span className='close-icon-wrapper'>
          <Icon
            aria-label='Close TOV info banner'
            icon='Close'
            onClick={handleClose}
          />
        </span>
      )}
    </TovInfoBannerWrapper>
  );
};

import React, { useState, useEffect } from 'react';

import {
  NumericSliderWrapper,
  SliderWrapper,
  HelperText,
  SliderHeading,
  SliderLabelWrapper,
  SliderLabel,
} from './NumericSlider.styles';
import { NumericSliderProps } from './NumericSlider.types';

export const NumericSlider = (props: NumericSliderProps): JSX.Element => {
  const {
    defaultValue,
    sliderType,
    barColor,
    sliderColor,
    textInput,
    paddingTop,
    paddingBottom,
    paddingLeft,
    paddingRight,
    leftLabel,
    centerLabel,
    rightLabel,
    helperText,
    disabled,
  } = props;

  const getSliderMarksForSliderType = () => {
    const sliderValues: Array<number> = Array.from(Array(10 + 1).keys());
    return sliderValues.map((mark: number) => ({
      value: sliderType === 'Percentage' ? mark * 10 : mark,
      label: sliderType === 'Percentage' ? `${mark * 10}%` : `${mark}`,
    }));
  };

  const [sliderValue, setSliderValue] = useState(defaultValue);
  const [sliderMarks, setSliderMarks] = useState(getSliderMarksForSliderType());

  useEffect(() => {
    setSliderValue(
      sliderType === 'Percentage' ? defaultValue : defaultValue / 10
    );
  }, [defaultValue]);

  useEffect(() => {
    setSliderMarks(getSliderMarksForSliderType());
    setSliderValue(
      sliderType === 'Percentage' ? defaultValue : defaultValue / 10
    );
  }, [sliderType]);

  // eslint-disable-next-line @typescript-eslint/ban-types
  const onChangeHandler = (
    event: React.ChangeEvent<{}>,
    value: number | Array<number>
  ) => {
    setSliderValue(value as number);
  };

  return (
    <NumericSliderWrapper
      style={{
        padding: `${paddingTop}px ${paddingRight}px ${paddingBottom}px ${paddingLeft}px`,
      }}
    >
      {textInput && <SliderHeading>{textInput}</SliderHeading>}
      <SliderWrapper
        defaultValue={defaultValue}
        sliderType={sliderType}
        textInput={textInput}
        step={1}
        min={0}
        max={sliderMarks[sliderMarks.length - 1].value}
        marks={sliderMarks}
        onChange={onChangeHandler}
        barColor={barColor}
        sliderColor={sliderColor}
        value={sliderValue}
        disabled={disabled}
      />
      <SliderLabelWrapper>
        <SliderLabel position='start'>{leftLabel}</SliderLabel>
        <SliderLabel position='center'>{centerLabel}</SliderLabel>
        <SliderLabel position='end'>{rightLabel}</SliderLabel>
      </SliderLabelWrapper>
      {helperText && <HelperText>{helperText}</HelperText>}
    </NumericSliderWrapper>
  );
};

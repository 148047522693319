import Promise from 'promise';

export const convertBase64 = (
  blob: Blob | null | undefined
): Promise<string | ArrayBuffer | null> =>
  new Promise<string | ArrayBuffer | null>((resolve, reject) => {
    if (!blob) return null;
    const reader: FileReader = new FileReader();

    reader.readAsDataURL(blob);
    reader.onerror = reject;
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });

export const formatBytes = (bytes: string) => {
  const numBytes = parseFloat(bytes);
  const kiloBytes = 1024;

  if (numBytes / kiloBytes ** 3 >= 1)
    return `${(numBytes / kiloBytes ** 3).toFixed(2)} GB`;
  if (numBytes / kiloBytes ** 2 >= 1)
    return `${(numBytes / kiloBytes ** 2).toFixed(2)} MB`;
  if (numBytes / kiloBytes >= 1)
    return `${(numBytes / kiloBytes).toFixed(2)} KB`;
  return `${numBytes} Bytes`;
};

export const filseSize10MB = (10 * 1024 * 1024).toString();

import React, { useCallback, useState } from 'react';

import { SmsTextareaWithVariables } from '../../DraftailComponents/SmsTextareaWithVariables';
import { VariableSelectorPopoverTypes } from '../../Molecules/VariableSelectorPopover';
import {
  fromHTML,
  validateRawContentStateForInvalidVariables,
} from '../../DraftailComponents/draftailUtils';
import {
  ToneType,
  TovDataType,
} from '../../Utils/ToneOfVoice/toneOfVoiceTypes';
import {
  DraftailObjectType,
  tovHighlightDraftailObject,
} from '../../DraftailComponents/tovDraftailUtils';

type SmsContentPanelProps = {
  messageTemplate?: string; // Seems like it is not needed. The draft value probably suffices. See the valueToUse comment below.
  channelName: string;

  onUpdate: (name: string, htmlString: string) => void;
  draft: string;
  showVariableMenu?: boolean;
  variableMenuData: VariableSelectorPopoverTypes['variableGroups'];
  tovData?: TovDataType;
  selectedTone?: ToneType | null | undefined;
  setTovDataNotSync?: (params: boolean) => void;
};

/**
 * This is a port of the SmsPanel from ui-lib.
 *
 * It is designed to keep the same interface as the old one.
 *
 *  * Note, this component behaves as an _uncontrolled component_.
 *
 * That is - the intial value (draft/messageTemplate) only applies once (on mount) and can not otherwise be set by the parent.
 *
 * @param props
 */

export const SmsContentPanel = (props: SmsContentPanelProps): JSX.Element => {
  const {
    messageTemplate,
    channelName,
    onUpdate,
    draft,
    showVariableMenu,
    variableMenuData,
    tovData,
    selectedTone,
    setTovDataNotSync,
  } = props;

  // ie. Use the template only if it exists, and the draft doesn't.
  const htmlToUse =
    draft.length === 0 && messageTemplate ? messageTemplate : draft;
  const [htmlContentState, setHtmlContentState] = useState(htmlToUse);
  const draftailObject = fromHTML(htmlToUse) as DraftailObjectType;

  const validatedDratailObject = validateRawContentStateForInvalidVariables({
    rawContentState: draftailObject,
    variableGroups: variableMenuData,
  });

  const valueToUse =
    tovData && selectedTone
      ? tovHighlightDraftailObject(draftailObject, tovData, selectedTone)
      : validatedDratailObject;

  const handleSave = useCallback(
    (htmlString: string) => {
      if (!selectedTone && htmlContentState !== htmlString) {
        onUpdate(channelName, htmlString);
        setHtmlContentState(htmlString);
        if (setTovDataNotSync) setTovDataNotSync(true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onUpdate, channelName, htmlContentState, selectedTone]
  );

  return (
    <SmsTextareaWithVariables
      title='Create SMS'
      variableGroups={variableMenuData}
      initialValue={valueToUse}
      showVariableButton={showVariableMenu}
      onSave={handleSave}
      selectedTone={selectedTone}
    />
  );
};

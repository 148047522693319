export {
  prefix,
  REGION,
  EXTERNAL_ROUTES,
  smsMaximumSize,
  messageCharacterLimit,
  messageTotalLimit,
  firstMessageCharacterLimit,
  ASSUMED_VARIABLE_LENGTH,
  localStorageLookup,
  pagesPathname,
  whatsappMessageCharacterLimit,
  ConfigType,
  segmentAccount,
  platform,
  GraphQLServer,
  draftailStripAndReplaceLineBreaks,
  getTotalMessageCharacterCount
} from './constants';

import styled from 'styled-components';
import { Elevations } from '../../Foundation/Elevations';
import { Fonts } from '../../Foundation/Fonts';
import { Colors } from '../../Foundation/Colors';

export const StyledWorkflowCanvasNode = styled.div`
  background-color: ${Colors.white};
  box-shadow: ${Elevations.elevation_1};
  min-width: 160px;
  padding: 12px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;

  position: relative;

  &.has-icon {
    padding: 32px 16px 16px 16px;
  }

  .canvas-node-icon {
    position: absolute;
    top: -20px;
    height: 40px;
    width: 40px;

    &.first-icon:not(.solo) {
      z-index: 2;
      // 20px = half of the icon size
      // 12px = amount of overlap
      left: calc(50% - 20px - 12px);
    }
    &.second-icon {
      left: calc(50% - 20px + 12px);
    }
  }

  .title {
    ${Fonts.capsHeading};
    color: ${Colors.grey_blue};
    margin: 8px 0 0;
  }

  .description-text {
    ${Fonts.body}
    color: ${Colors.grey_blue_4};
    margin: 4px 0 0;

    &.error {
      color: ${Colors.error};
      &:after {
        content: '';
        height: 8px;
        width: 8px;
        border-radius: 50%;
        display: inline-block;
        background-color: ${Colors.error};
        margin-left: 8px;
      }
    }
  }

  .footer-content {
    ${Fonts.body}
    color: ${Colors.grey_blue};
    margin: 0;
  }
`;

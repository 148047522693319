import React, {
  useState,
  ChangeEvent,
  MouseEvent,
  ReactElement,
  useEffect,
} from 'react';

import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';

import { Spinner } from '../../Atoms/Indicators';
import { SimpleTableMultiSelect } from '../../Molecules/SimpleTableMultiSelect';
import { SimpleTableRow } from '../../Molecules/SimpleTableRow';
import { SimpleTableWrapper } from './SimpleTable.styles';
import {
  RowData,
  SimpleTableProps,
  SortOrderType,
  ActionsType,
  CheckboxSelectedValueType,
} from './SimpleTable.types';
import { initiateSort } from './sort.service';

export const handleSortChange = (
  setOrder: (value: SortOrderType) => void,
  setOrderBy: (value: string) => void,
  name: string,
  order: SortOrderType,
  onSortChange?: (name: string, order: SortOrderType) => void
): void => {
  setOrderBy(name);
  setOrder(order);
  return onSortChange && onSortChange(name, order);
};

const renderRows = (
  rows: Array<RowData>,
  handleRadioChange: (
    event: ChangeEvent<HTMLSelectElement>,
    rowData: RowData
  ) => void,
  handleRowClick: (
    rowData: RowData,
    event?: MouseEvent<HTMLTableRowElement>
  ) => void,
  handleCheckboxChange: (
    event: ChangeEvent<HTMLSelectElement>,
    rowData: RowData
  ) => void,
  checkboxSelectedValues: Array<CheckboxSelectedValueType>,
  actions: ActionsType,
  radioSelectedValue?: string,
  activeRowValue?: string
) => {
  return rows.map((row: RowData) => (
    <SimpleTableRow
      key={row.value}
      rowData={{
        ...row,
        handleRadioChange,
        handleRowClick,
        handleCheckboxChange,
        actionItems: actions && actions.actionItems,
      }}
      activeRowValue={activeRowValue}
      radioSelectedValue={radioSelectedValue}
      checkboxSelectedValues={checkboxSelectedValues}
    />
  ));
};

export const handleRadioChange = (
  setRadioSelectedValue: (value: string) => void,
  actions: ActionsType,
  event: ChangeEvent<HTMLSelectElement>,
  rowData: RowData
): void => {
  const { value } = event.target;
  setRadioSelectedValue(value);
  actions.callback(rowData);
};

export const handleCheckboxChange = (
  setSelectedValue: (value: Array<CheckboxSelectedValueType>) => void,
  actions: ActionsType,
  event: ChangeEvent<HTMLSelectElement>,
  rowData: RowData,
  selectedValues: Array<CheckboxSelectedValueType>
): void => {
  const { value } = event.target;
  if (selectedValues.includes(value)) {
    const newSelectedValues = selectedValues.filter((cur) => cur !== value);
    setSelectedValue(newSelectedValues);
  }

  actions.callback(rowData);
  setSelectedValue([...selectedValues, value]);
};

export const handleRowClick = (
  setActiveRowValue: (value?: string) => void,
  actions: ActionsType,
  rowData: RowData,
  event?: MouseEvent<HTMLTableRowElement>
): void => {
  const { value } = rowData;
  const { callback } = actions;

  setActiveRowValue(value);
  return callback && callback(rowData, event);
};

export const SimpleTable = (props: SimpleTableProps): ReactElement | null => {
  const {
    headerData,
    bodyData,
    actions,
    defaultSortColumnName,
    defaultSelectedRowValue,
    disableSort,
    isLoading,
    onSortChange,
  } = props;
  const [activeRowValue, setActiveRowValue] = useState<string | undefined>();
  const [radioSelectedValue, setRadioSelectedValue] = useState(
    defaultSelectedRowValue
  );
  const [checkboxSelectedValues, setCheckboxSelectedValue] = useState(
    [defaultSelectedRowValue].filter(Boolean)
  );
  const [orderBy, setOrderBy] = useState(
    defaultSortColumnName || (headerData && headerData.cells[0].name)
  );
  const { sortType: sortingColumnSortType, order: sortingColumnOrder } =
    (headerData && headerData.cells.find((cell) => cell.name === orderBy)) ||
    {};
  const [order, setOrder] = useState<SortOrderType>(sortingColumnOrder);

  useEffect(() => {
    setRadioSelectedValue(defaultSelectedRowValue);
    setCheckboxSelectedValue([defaultSelectedRowValue].filter(Boolean));
  }, [defaultSelectedRowValue]);

  if (!headerData || !bodyData) {
    return null;
  }

  const handleMultiCheck = (numberOfRows: number, selectedRows: number) => {
    if (selectedRows === numberOfRows) {
      setCheckboxSelectedValue([]);
      return;
    }
    const selected = bodyData.map((row) => (row.value ? row.value : ''));
    setCheckboxSelectedValue(selected);
  };

  const columnIndex = headerData.cells.findIndex(
    (cell) => cell.name === orderBy
  );
  const rowDataSort = disableSort ? 'default' : order;
  const sortingColumnTypeInternal = sortingColumnSortType || 'string';
  const sortingColumnTypeToUse = disableSort
    ? 'none'
    : sortingColumnTypeInternal;
  const sortedRowData = initiateSort(
    bodyData,
    columnIndex,
    rowDataSort,
    sortingColumnTypeToUse
  );

  return (
    <SimpleTableWrapper className={`${isLoading ? 'disabled' : ''}`}>
      <TableHead>
        <SimpleTableRow
          rowData={{
            ...headerData,
            handleSortChange: (name: string, order: SortOrderType) =>
              handleSortChange(setOrder, setOrderBy, name, order, onSortChange),
            handleMultiCheck,
          }}
          radioSelectedValue={radioSelectedValue}
          checkboxSelectedValues={checkboxSelectedValues}
          numberOfRows={sortedRowData.length}
        />
      </TableHead>
      <TableBody className={`${isLoading ? 'data-loading' : ''}`}>
        {isLoading ? (
          <Spinner size='large' />
        ) : (
          renderRows(
            sortedRowData,
            (event: ChangeEvent<HTMLSelectElement>, rowData: RowData) =>
              handleRadioChange(setRadioSelectedValue, actions, event, rowData),
            (rowData: RowData, event?: MouseEvent<HTMLTableRowElement>) =>
              handleRowClick(setActiveRowValue, actions, rowData, event),
            (event: ChangeEvent<HTMLSelectElement>, rowData: RowData) =>
              handleCheckboxChange(
                setCheckboxSelectedValue,
                actions,
                event,
                rowData,
                checkboxSelectedValues
              ),
            checkboxSelectedValues,
            actions,
            radioSelectedValue,
            activeRowValue
          )
        )}
      </TableBody>
      {headerData.leftActionType === 'checkbox' &&
        checkboxSelectedValues.length > 0 && (
          <SimpleTableMultiSelect
            selectedCount={checkboxSelectedValues.length}
          />
        )}
    </SimpleTableWrapper>
  );
};

import React, { useState } from 'react';

import MomentUtils from '@date-io/moment';
import moment, { Moment } from 'moment';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from '@material-ui/pickers';
import { createMuiTheme } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

import { Icons } from '../../../Foundation/Icons';
import { DatePickerPropTypes } from './DatePicker.types';
import {
  DatePickerWrapper,
  materialThemeConfig,
  adornmentProps,
  arrowButtonProps,
} from './DatePicker.styles';

const momentUtils = new MomentUtils();

export const DatePicker = ({
  onChange = () => undefined,
  defaultValue = new Date(),
  dateFormat = 'DD/MM/YYYY',
  timeFormat = 'hh:mm a',
  label = 'Enter date',
  maxDate,
  maxDateMessage,
  minDate,
  minDateMessage,
  autoFocus,
  helperText,
  filled = false,
  includeTime = false,
  ...rest
}: DatePickerPropTypes): JSX.Element => {
  const [dateError, setDateError] = useState(false);
  const [timeError, setTimeError] = useState(false);
  const getStartDate = (date) => momentUtils.startOfDay(date).toDate();
  const combineDateTime = (date, time) =>
    `${moment(date).format('YYYY-MM-DD')}T${moment(time).format('HH:mm')}`;

  let defaultDate;
  const [dateValue, setDateValue] = useState(() => {
    if (defaultValue !== undefined) {
      if (momentUtils.isValid(defaultValue)) {
        defaultDate = getStartDate(momentUtils.date(defaultValue));
      } else {
        defaultDate = null;
      }
    } else {
      defaultDate = getStartDate(momentUtils.date());
    }
    return defaultDate;
  });

  const [timeValue, setTimeValue] = useState(defaultValue);

  const handleDateChange = (date: MaterialUiPickersDate) => {
    const isDateValid = date && momentUtils.isValid(date);
    setDateError(!isDateValid);
    if (isDateValid) {
      const startOfDay = momentUtils.startOfDay(date as Moment).toDate();
      setDateValue(startOfDay);
      return (
        onChange &&
        onChange(
          includeTime ? combineDateTime(startOfDay, timeValue) : startOfDay
        )
      );
    }
  };

  const handleTimeChange = (time) => {
    const isTimeValid = time && time.isValid();
    setTimeError(!isTimeValid);
    setTimeValue(time);
    return onChange && onChange(combineDateTime(dateValue, time));
  };

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <ThemeProvider theme={createMuiTheme(materialThemeConfig)}>
        <DatePickerWrapper className={filled ? 'filled' : ''}>
          <KeyboardDatePicker
            helperText={helperText}
            autoFocus={autoFocus}
            allowKeyboardControl
            label={label}
            keyboardIcon={<Icons.Date />}
            leftArrowButtonProps={{
              classes: { root: 'datepicker left' },
              ...arrowButtonProps,
            }}
            rightArrowButtonProps={{
              classes: { root: 'datepicker right' },
              ...arrowButtonProps,
            }}
            inputVariant={filled ? 'filled' : 'standard'}
            InputAdornmentProps={{ ...adornmentProps }}
            disableToolbar
            autoOk
            variant='inline'
            format={dateFormat}
            placeholder={dateFormat && dateFormat.toLowerCase()}
            value={dateValue}
            onChange={(date) => handleDateChange(date)}
            maxDate={maxDate}
            maxDateMessage={maxDateMessage}
            minDate={minDate}
            minDateMessage={minDateMessage}
            fullWidth={includeTime != false}
            style={
              includeTime
                ? { width: '75%', paddingRight: '16px' }
                : { width: '100%' }
            }
            {...rest}
            invalidDateMessage=' '
            error={dateError}
            inputProps={{
              'aria-label': `${label} date`,
            }}
          />
          {includeTime && (
            <KeyboardTimePicker
              onChange={(value) => handleTimeChange(value)}
              value={timeValue}
              fullWidth={false}
              KeyboardButtonProps={{
                disabled: true,
                style: { display: 'none' },
              }}
              style={{ width: '25%' }}
              label=' '
              mask='__:__ _m'
              inputVariant={filled ? 'filled' : 'standard'}
              invalidDateMessage=' '
              placeholder='03:30 am'
              format={timeFormat}
              error={timeError}
              inputProps={{
                'aria-label': `${label} time`,
              }}
            />
          )}
        </DatePickerWrapper>
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );
};

import React from 'react';
import { sanitize } from 'dompurify';
import { DraftailPreviewWrapper } from './DraftailPreview.styles';
import { DraftailPreviewTypes } from './DraftailPreview.types';

export const DraftailPreview = ({
  htmlContentString,
}: DraftailPreviewTypes): JSX.Element => (
  <DraftailPreviewWrapper>
    <div dangerouslySetInnerHTML={{ __html: sanitize(htmlContentString) }} />
  </DraftailPreviewWrapper>
);

import React from 'react';

import { Illustrations } from '../../Foundation/Illustrations';
import { InfoCardType } from './InfoCard.types';
import {
  StyledInfoCard,
  StyledHeading,
  StyledDescription,
  StyledIllustration,
} from './InfoCard.style';

const Illustration = ({ illustration }: any): JSX.Element | null => {
  if (!illustration) {
    return null;
  }

  const Illustration = Illustrations[illustration];

  return <Illustration />;
};

export const InfoCard = ({
  heading,
  description,
  illustration,
}: InfoCardType) => (
  <StyledInfoCard>
    <StyledIllustration id='InfoCard-Illustration'>
      <Illustration illustration={illustration} />
    </StyledIllustration>
    <StyledHeading id='InfoCard-Heading'>{heading}</StyledHeading>
    <StyledDescription id='InfoCard-Description'>
      {description}
    </StyledDescription>
  </StyledInfoCard>
);

import React, { createElement } from 'react';

import ReactSelectMaterialUi from 'react-select-material-ui';

import { FormHelperText } from '@material-ui/core';
import { DropdownFilled } from '../DropdownFilled';
import { renderIconElement } from '../../../Utils/renderIconElement';
import { CustomOptionProps } from '../DropdownFilled/DropdownFilled.types';
import { CustomSingleValueProps, DropdownProps } from './Dropdown.types';
import {
  DropdownWrapper,
  EmptyResultsMessage,
  SingleValue,
  OptionText,
  OptionIcon,
  DownArrow,
  StyledDropdownSection,
} from './Dropdown.style';

// Override default filter to only compare input against option labels
const defaultFilter = ({ label }, filterStr) =>
  new RegExp(filterStr, 'i').test(label);

const DownArrowComponent = createElement(DownArrow);

const EmptyResultsMessageComponent = () => (
  <EmptyResultsMessage>No results</EmptyResultsMessage>
);

const CustomSingleValue = ({
  data: { icon, label },
}: CustomSingleValueProps) => (
  <SingleValue>
    {icon && <OptionIcon>{renderIconElement(icon)}</OptionIcon>}
    <OptionText>{label}</OptionText>
  </SingleValue>
);

// Custom component overrides for ReactSelect components
// Reference: https://react-select.com/components
const dropDownElements = {
  DropdownIndicator: () => DownArrowComponent,
  IndicatorSeparator: () => null,
  NoOptionsMessage: EmptyResultsMessageComponent,
  SingleValue: CustomSingleValue,
};

export const Dropdown = ({
  disabled,
  customRenderers,
  components,
  label,
  options,
  onChange = () => undefined,
  value,
  variant = 'normal',
  autoFocus = false,
  className = '',
  id = '',
  isSearchable,
  orientation = 'vertical',
}: DropdownProps) => {
  return (
    <StyledDropdownSection orientation={orientation}>
      {label && (
        <FormHelperText component='label' filled htmlFor={id}>
          {label}
        </FormHelperText>
      )}
      {variant === 'normal' ? (
        <DropdownWrapper disabled={disabled}>
          <ReactSelectMaterialUi
            id={id}
            className={className}
            autoFocus={autoFocus}
            value={value}
            SelectProps={{
              classNamePrefix: 'dropdown',
              blurInputOnSelect: true,
              filterOption: defaultFilter,
              components: {
                ...components,
                ...dropDownElements,
                ...customRenderers,
              },
            }}
            options={options as Array<string> | Array<CustomOptionProps>}
            disabled={disabled}
            onChange={onChange}
          />
        </DropdownWrapper>
      ) : (
        <DropdownFilled
          id={id}
          className={className}
          customRenderers={customRenderers}
          components={components}
          options={options as Array<CustomOptionProps>}
          value={value}
          onChange={onChange}
          isSearchable={isSearchable}
        />
      )}
    </StyledDropdownSection>
  );
};

import styled from 'styled-components';
import { Colors } from '../../Foundation/Colors';
import { Fonts } from '../../Foundation/Fonts';
import { AlignmentSelectorWrapperProps } from './AlignmentSelector.types';

export const AlignmentSelectorWrapper = styled.div<AlignmentSelectorWrapperProps>`
  display: flex;
  align-items: center;
  padding: 12px 0;

  .placement-label {
    ${Fonts.link};
    color: ${Colors.grey_blue};
    text-align: left;
  }

  .MuiToggleButtonGroup-root {
    ${(props) =>
      props.label === '' ? '' : 'margin-left: auto; auto; order: 2;'}

  .MuiToggleButton-root {
    height: 40px;
    width: 64px;
  }

  .Mui-selected {
    svg {
      fill: ${Colors.accent}; 
    }
  }
  
  svg {
    fill: ${Colors.grey_blue_4}; 
  }
`;

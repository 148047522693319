import {
  getSelectedWorkSpaceId,
  getSelectedWorkSpaceName,
} from '../localStorage';

const platform = 'Sparx'; // Note that this is copy pasted from ../config
// I've removed that import because we are otherwise getting a circular dependencies issue
// https://buildkite.com/whispir/content-builder/builds/1989#184c5174-dc7c-4c40-93b0-d6b313fbef20

export const trackIdentity = ({ userId, traits = {} }) => {
  if (window.analytics) {
    window.analytics.identify(userId, {
      platform,
      userId,
      workspaceId: getSelectedWorkSpaceId(),
      workspaceName: getSelectedWorkSpaceName(),
      ...traits,
    });
  }
};

export const trackGroup = ({ userId, groupId, traits = {} }) => {
  if (window.analytics) {
    window.analytics.group(groupId, {
      platform,
      userId,
      workspaceId: getSelectedWorkSpaceId(),
      workspaceName: getSelectedWorkSpaceName(),
      ...traits,
    });
  }
};

function getUserInfo() {
  try {
    const { companyId, email } = JSON.parse(
      localStorage.getItem('ajs_user_traits')
    );
    return { companyId, email };
  } catch (e) {
    return {};
  }
}

export const trackEvent = ({ userId, event, properties = {} }) => {
  if (window.analytics) {
    window.analytics.track(event, {
      platform,
      userId,
      workspaceId: getSelectedWorkSpaceId(),
      workspaceName: getSelectedWorkSpaceName(),
      ...properties,
      ...getUserInfo(),
    });
  }
};

export const trackPage = ({ userId, page, properties = {} }) => {
  if (window.analytics && window.analytics.page) {
    window.analytics.page(page, {
      platform,
      userId,
      ...properties,
      ...getUserInfo(),
    });
  }
};

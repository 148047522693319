import { ModelType } from '../../utilityTypes';

export const formDropdownModel = (id: string): ModelType => ({
  id,
  type: 'FormDropdown',
  label: 'Dropdown',
  componentType: 'form',
  properties: [
    {
      name: 'text',
      label: 'Styles',
      control: 'TitleDivider',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'label',
      label: 'Label',
      control: 'TextFieldFilled',
      value: 'Dropdown',
      __typename: 'ComponentProperty',
    },
    {
      name: 'requiredField',
      label: 'Required Field',
      control: 'Checkbox',
      value: 'false',
      __typename: 'ComponentProperty',
    },
    {
      name: 'helperText',
      label: 'Helper Text',
      control: 'TextFieldFilled',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'padding_8px_2',
      label: '',
      control: 'PaddingControl8px',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'text1',
      label: 'Options',
      control: 'TitleDividerNoLine',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'optionsPicker',
      label: '',
      control: 'OptionsPickerFilledStyleInputs',
      value: [
        {
          id: 1,
          text: 'Option 1',
          checked: 'false',
        },
        {
          id: 2,
          text: 'Option 2',
          checked: 'false',
        },
        {
          id: 3,
          text: 'Option 3',
          checked: 'false',
        },
      ],
      __typename: 'ComponentProperty',
    },
    {
      name: 'padding_8px_3',
      label: '',
      control: 'PaddingControl8px',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'text3',
      label: 'Padding',
      control: 'TitleDividerNoLine',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'paddingTop',
      label: 'Top',
      control: 'Slider',
      value: '20',
      __typename: 'ComponentProperty',
    },
    {
      name: 'paddingBottom',
      label: 'Bottom',
      control: 'Slider',
      value: '20',
      __typename: 'ComponentProperty',
    },
  ],
  __typename: 'Component',
});

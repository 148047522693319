import styled from 'styled-components';
import { Fonts } from '../../Foundation/Fonts';

import {
  StyledButtonWrapperProps,
  ButtonProps,
} from './RichTextButtonBlock.types';

export const StyledButtonWrapper = styled.div<StyledButtonWrapperProps>`
  padding-top: ${(props) => props.paddingTop}px;
  padding-bottom: ${(props) => props.paddingBottom}px;
  width: 100%;
  background-color: ${(props) => props.backgroundColor};
`;

export const Button = styled.a<ButtonProps>`
  display: block;
  width: ${(props) => (props.fullWidth === 'true' ? '100%' : '200px')};
  ${Fonts.body};
  background-color: ${(props) => props.buttonColor};
  color: ${(props) => props.textColor};
  text-decoration: none;
  border-radius: ${(props) => props.borderRadius}px;
  text-align: center;
  word-break: break-word;
  transition: all 0.2s ease-in-out;
  padding: 10px 0;
  margin: 0 auto;
`;

import styled from 'styled-components';
import { Colors } from '../../../Foundation/Colors';
import {
  NEW_GLOBAL_NAV_WIDTH,
  TRANSITION_TIME,
} from '../../../Foundation/Spacing';

export const NavigationStyle = styled.div`
  padding: 16px 0;

  a {
    min-width: ${NEW_GLOBAL_NAV_WIDTH};
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    padding: 0;
    height: ${NEW_GLOBAL_NAV_WIDTH};
    cursor: pointer;
    outline: none;
    border-radius: 0;

    svg {
      fill: ${Colors.grey_blue_2};
      transition: fill ${TRANSITION_TIME};
    }

    &.active {
      background-color: rgb(63, 64, 76);

      svg {
        fill: ${Colors.white};
      }
    }

    &:focus,
    &:hover {
      svg {
        fill: ${Colors.white};
      }
    }
  }
`;

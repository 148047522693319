import React, { ReactNode } from 'react';

import { StyledFlatPanel } from './FlatPanel.styles';

type FlatPanelProps = {
  children: ReactNode;
  className?: string;
};

export const FlatPanel = (props: FlatPanelProps) => {
  const { children, className = '' } = props;
  return <StyledFlatPanel className={className}>{children}</StyledFlatPanel>;
};

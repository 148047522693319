import React from 'react';
import { Divider } from '../../Atoms/Layout';

import { StyledSideDrawerTitle } from './SideDrawerTitle.styles';

type SideDrawerTitleProps = {
  text: string;
};

export const SideDrawerTitle = (props: SideDrawerTitleProps) => {
  const { text } = props;
  return (
    <StyledSideDrawerTitle>
      {text}
      <Divider dividerType='solid' isHorizontal />
    </StyledSideDrawerTitle>
  );
};

/* eslint-disable react/destructuring-assignment */
import React, { PureComponent } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { v4 as uuid } from 'uuid';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { SocialIconItemValues } from '../../Organisms/SocialIconsOptionsPicker/SocialIconsOptionsPicker.types';
import { TextField, Checkbox } from '../../Atoms/Forms';
import { Icon } from '../../Atoms/Icon';
import { IconButton } from '../../Atoms/Navigations';

import { Colors } from '../../Foundation/Colors';

import { CustomOptionProps } from '../../Atoms/Forms/DropdownFilled/DropdownFilled.types';
import {
  ComponentBox,
  DragHandleWrapper,
  ComponentBoxProperties,
  DefaultOptionsWrapper,
  OptionsContainer,
  AddAnotherBtn,
  StyledSubHeader,
} from './OptionsPicker.styles';

type Option = {
  id: string;
  title?: string;
  label: string;
  value: boolean;
};

export type OptionsPickerUpdateItemType = (
  value: string
) => (key: string) => void;

export type OptionPickerProps = {
  filledTextAreaStyle?: boolean;
  componentId: string | number;
  optionsType?: 'radio-buttons/dropdown-menu' | 'checkboxes';
  defaultValue?: Array<Option>;
  onChange?: (valueAsString: string, value: Array<Option>) => void;
  getModifyVariableConfirmationModal?: (id: unknown) => ModalComponent;
  isButtonPicker?: boolean;
  isExtension?: boolean;
  addLinkText?: string;
  renderChildren?: (
    item: Option,
    updateItem: OptionsPickerUpdateItemType
  ) => JSX.Element;
  defaultSelectedOption?: SocialIconItemValues;
};

type ModalComponent = (props: {
  onConfirm: (...args: Array<unknown>) => void;
  onCancel: (...args: Array<unknown>) => void;
  open: boolean;
}) => JSX.Element;

type State = {
  disableDeleteButton: boolean;
  modifyVariableModal: {
    Modal: ModalComponent;
    index: number;
  } | null;
  items: Array<SocialIconItemValues>;
};

const reorder = (list, startIndex, endIndex): Array<Option> => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result as Array<Option>;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  background: isDragging ? 'none' : 'none',
  ...draggableStyle,
});

const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? `${Colors.accent_2}` : `${Colors.background}`,
  padding: 0,
});

export class OptionsPicker extends PureComponent<OptionPickerProps, State> {
  // eslint-disable-next-line id-blacklist
  static defaultProps = {
    optionsType: 'radio-buttons/dropdown-menu',
    defaultValue: [
      {
        id: uuid(),
        title: 'Option',
        label: 'Small',
        value: true,
      },
      {
        id: uuid(),
        title: 'Option',
        label: 'Medium',
        value: false,
      },
      {
        id: uuid(),
        title: 'Option',
        label: 'Large',
        value: false,
      },
    ],
    getModifyVariableConfirmationModal: () => null,
    onChange: () => undefined,
    isButtonPicker: false,
  };

  constructor(props: OptionPickerProps) {
    super(props);
    const { defaultValue } = this.props;
    this.state = {
      items:
        typeof defaultValue === 'string'
          ? JSON.parse(defaultValue)
          : defaultValue,
      disableDeleteButton: false,
      modifyVariableModal: null,
    };
    // $FlowFixMe
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  updateItem() {
    // eslint-disable-next-line react/no-access-state-in-setstate
    const updateItems = [...this.state.items];
    this.setState({
      items: updateItems,
    });
  }

  onDragEnd(result: any) {
    const { onChange = () => undefined } = this.props;
    const { items } = this.state;
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const updateItems = reorder(
      items,
      result.source.index,
      result.destination.index
    );

    this.setState({
      items: updateItems,
    });

    onChange(JSON.stringify(updateItems), updateItems);
  }

  doDelete = (index) => {
    const { items } = this.state;
    const { onChange = () => undefined } = this.props;
    const updateItems = [...items];

    if (updateItems.length > 1) {
      updateItems.splice(index, 1);
    }
    this.setState(
      {
        items: updateItems,
      },
      () =>
        updateItems.length <= 1 &&
        this.setState({
          disableDeleteButton: true,
        })
    );

    onChange(JSON.stringify(updateItems), updateItems);
  };

  onDeleteClick = (index: number) => {
    const {
      getModifyVariableConfirmationModal = () => null,
      componentId,
    } = this.props;

    const modal = getModifyVariableConfirmationModal(componentId);
    if (modal) {
      this.setState({
        modifyVariableModal: {
          Modal: modal,
          index,
        },
      });
    } else {
      this.doDelete(index);
    }
  };

  handleTextInput = (index: number) => (event: string) => {
    const { onChange = () => undefined } = this.props;
    const { items } = this.state;
    const updateItems = [...items];
    updateItems[index].label = event;

    this.setState({
      items: updateItems,
    });

    onChange(JSON.stringify(updateItems), updateItems);
  };

  handleFieldChange = (index: number, key: string) => (
    event: string | CustomOptionProps
  ) => {
    const { onChange = () => undefined } = this.props;
    const { items } = this.state;
    const updateItems = [...items];
    updateItems[index][key] = event;

    this.setState({
      items: updateItems,
    });

    onChange(JSON.stringify(updateItems), updateItems);
  };

  handleDefaultOption = (index: number) => (_event, nowChecked) => {
    const { onChange = () => undefined, optionsType } = this.props;
    const { items } = this.state;
    const updateItems = [...items];

    if (optionsType === 'radio-buttons/dropdown-menu') {
      updateItems.forEach((item) => {
        item.value = false;
      });
    }

    updateItems[index].value = nowChecked;

    this.setState({
      items: updateItems,
    });

    onChange(JSON.stringify(updateItems), updateItems);
  };

  handleAddAnotherOption = () => {
    const {
      defaultSelectedOption = {},
      onChange = () => undefined,
    } = this.props;
    const { items } = this.state;
    const updateItems = [...items];
    const defaultOption = {
      id: uuid(),
      label: `Option ${items.length + 1}`,
      value: false,
      ...defaultSelectedOption,
    };

    updateItems.push(defaultOption);

    this.setState({
      items: updateItems,
      disableDeleteButton: false,
    });

    onChange(JSON.stringify(updateItems), updateItems);
  };

  onModalConfirm = () => {
    const { modifyVariableModal } = this.state;
    if (!modifyVariableModal) {
      throw new Error(
        "Attempting to call onModalConfirm when modifyVariableModal doesn't exist"
      );
    }
    const { index } = modifyVariableModal;
    this.doDelete(index);
    this.setState({ modifyVariableModal: null });
  };

  onModalCancel = () => {
    this.setState({
      modifyVariableModal: null,
    });
  };

  renderRadioOrCheckbox = (item, index) => {
    const { optionsType } = this.props;
    if (optionsType === 'radio-buttons/dropdown-menu') {
      return (
        <FormControlLabel
          value={item.id}
          control={
            <Radio
              size='small'
              inputProps={{ 'aria-label': `${item.label} default checked` }}
            />
          }
          label='Default Option'
          onChange={this.handleDefaultOption(index)}
          checked={item.value}
        />
      );
    }
    return (
      <Checkbox
        label='Default Option'
        ariaLabel={`${item.label} default checked`}
        isChecked={item.value}
        onChange={this.handleDefaultOption(index)}
      />
    );
  };

  renderOptionPickerItem = (item, index, disableDeleteButton) => {
    const { filledTextAreaStyle, isExtension, renderChildren } = this.props;
    return (
      <Draggable key={item.id} draggableId={item.id} index={index}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getItemStyle(
              snapshot.isDragging,
              provided.draggableProps.style
            )}
          >
            <ComponentBox
              filledTextAreaStyle={filledTextAreaStyle}
              isExtension={isExtension}
            >
              <DragHandleWrapper>
                <Icon icon='DragHandle' />
              </DragHandleWrapper>
              <ComponentBoxProperties>
                {item.title && <StyledSubHeader>{item.title}</StyledSubHeader>}
                {!isExtension && (
                  <TextField
                    label={filledTextAreaStyle ? 'Option Text' : undefined}
                    defaultValue={item.label}
                    onChange={this.handleTextInput(index)}
                    variant={filledTextAreaStyle ? 'filled' : undefined}
                    placeholder='Option Text'
                    inputProps={{
                      'aria-label': `Option Text ${index + 1}`,
                    }}
                  />
                )}
                {isExtension &&
                  renderChildren &&
                  renderChildren(
                    item,
                    this.handleFieldChange.bind(this, index)
                  )}
                <DefaultOptionsWrapper
                  disableDeleteButton={disableDeleteButton}
                  isExtension={isExtension}
                >
                  {!isExtension && this.renderRadioOrCheckbox(item, index)}

                  <IconButton
                    icon='Delete'
                    className='options-picker__delete-btn'
                    onClick={() => this.onDeleteClick(index)}
                    ariaLabel={`delete option ${index}`}
                  />
                </DefaultOptionsWrapper>
              </ComponentBoxProperties>
            </ComponentBox>
          </div>
        )}
      </Draggable>
    );
  };

  renderOptionsPicker(data) {
    const { optionsType } = this.props;
    if (optionsType === 'radio-buttons/dropdown-menu') {
      return (
        <RadioGroup
          aria-label='Options picker radio'
          name={`radio-op-${uuid()}`}
        >
          {data}
        </RadioGroup>
      );
    }
    return data;
  }

  render() {
    const { items, disableDeleteButton, modifyVariableModal } = this.state;
    const {
      filledTextAreaStyle,
      isButtonPicker,
      addLinkText = 'Add Another Option',
    } = this.props;

    const optionsPickerContent = items.map((item, index) => {
      return this.renderOptionPickerItem(item, index, disableDeleteButton);
    });

    return (
      <OptionsContainer className={isButtonPicker ? 'button-picker' : ''}>
        {modifyVariableModal && (
          <modifyVariableModal.Modal
            onConfirm={this.onModalConfirm}
            onCancel={this.onModalCancel}
            open={!!modifyVariableModal}
          />
        )}
        {isButtonPicker == false ? (
          <>
            <DragDropContext onDragEnd={this.onDragEnd}>
              <Droppable droppableId='droppable'>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {this.renderOptionsPicker(optionsPickerContent)}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>

            <AddAnotherBtn type='button' onClick={this.handleAddAnotherOption}>
              <Icon icon='Plus' />
              <p>{addLinkText}</p>
            </AddAnotherBtn>
          </>
        ) : (
          <>
            {items.map((item, index) => (
              <TextField
                variant='filled'
                key={item.id}
                label={
                  filledTextAreaStyle ? `Button Label ${index + 1}` : undefined
                }
                defaultValue={item.label}
                onChange={this.handleTextInput(index)}
                inputProps={{
                  'aria-label': `Option Text ${index + 1}`,
                }}
              />
            ))}
          </>
        )}
      </OptionsContainer>
    );
  }
}

import styled from 'styled-components';

import { IconButton } from '@material-ui/core';
import { Colors } from '../../../Foundation/Colors';

export const StyledIconButton = styled(IconButton)`
  &.MuiIconButton-root {
    padding: 0;
  }

  &.primary {
    fill: ${Colors.grey_blue};
    &.Mui-disabled {
      fill: ${Colors.grey_blue_2};
    }
  }

  &.default {
    fill: ${Colors.grey_blue_4};
    &.Mui-disabled {
      fill: ${Colors.grey_blue_2};
    }
  }

  &.small {
    width: 24px;
    height: 24px;

    svg {
      height: 70%;
      width: 70%;
    }
  }

  &.medium {
    width: 38px;
    height: 38px;

    svg {
      height: 62%;
      width: 62%;
    }
  }
`;

import styled from 'styled-components';
import { Colors } from '../../Foundation/Colors';
import { Elevations } from '../../Foundation/Elevations';
import { Fonts } from '../../Foundation/Fonts';

import { ComponentsProps } from './DroppableSourceItems.types';

export const Components = styled.div<ComponentsProps>`
  padding: 16px 16px 40px 16px;
  opacity: ${(props) => props.disable && '0.5'};
  pointer-events: ${(props) => props.disable && 'none'};
  z-index: 2;

  background: ${Colors.white};
  transition: all 0.2s ease;

  p {
    ${Fonts.subhead};
  }

  @keyframes fadein {
    0% {
      opacity: 0;
    }
    20% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .expanded-droppables--container {
    display: flex;
    flex-wrap: wrap;

    p {
      margin: 24px 0 12px 6px;
      width: 100%;
      ${Fonts.subheadMedium};
    }
  }
`;

export const EmptyPlaceholder = styled.div`
  text-align: center;
  padding: 64px;
`;

export const ComponentTitle = styled.div`
  color: ${Colors.grey_blue};
  font-size: 12px;
  text-align: center;
  line-height: 1.2;
`;

export const ComponentIcon = styled.div`
  fill: ${Colors.grey_blue_3};
  margin: 26px 0 0;

  svg {
    width: 32px;
    height: 32px;
  }
`;

export const ComponentBox = styled.div<ComponentsProps>`
  box-sizing: border-box;
  width: 102px;
  height: 102px;
  margin: 5px 6px 5px;
  padding: 0 8px;
  text-align: left;
  align-items: center;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  border: 1px solid ${Colors.grey_blue_2};
  border-radius: 8px;
  box-shadow: ${(props) => props.isDragging && Elevations.elevation_2};
  user-select: none;
  background: ${Colors.white};

  &:hover {
    border: 1px solid ${Colors.grey_blue_4};
    transition: border 0.25s;
  }
`;

export const UtilButton = styled.button`
  border-bottom-right-radius: 50%;
  border-top-right-radius: 50%;
  margin-left: -2px;
  width: 38px;
  height: 39px;
  text-align: center;
  color: ${Colors.white};
  background-color: ${Colors.accent};
  border: 1px ${Colors.accent} solid;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -39px;
  fill: ${Colors.white};
  transition: all 0.2s ease-out;

  &:hover {
    background-color: ${Colors.black};
    border-color: ${Colors.black};
    color: ${Colors.white};
  }
  opacity: 0;
`;

export const DragController = styled.div`
  border-bottom-left-radius: 40px;
  border-top-left-radius: 40px;
  padding-top: 6px;
  width: 37px;
  height: 31px;
  line-height: 34px;
  text-align: center;
  color: ${Colors.white};
  background-color: ${Colors.accent};
  border: 1px ${Colors.accent} solid;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -39px;
  fill: ${Colors.white};
  transition: all 0.2s ease-out;

  &:hover {
    background-color: ${Colors.black};
    border-color: ${Colors.black};
    color: ${Colors.white};
  }

  opacity: 0;
`;

export const Clone = styled(ComponentBox)`
  ~ div {
    transform: none !important;
  }
`;

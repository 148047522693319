import React, { PureComponent } from 'react';

import { BLOCK_TYPE, INLINE_STYLE } from '@whispir/draftail';
import type { RawDraftContentState } from 'draft-js/lib/RawDraftContentState';

import { DraftailEditorWithVariables } from '../../DraftailComponents/DraftailEditorWithVariables';
import { Icons } from '../../Foundation/Icons';
import { DraftailEditorWrapper } from './RichTextBasicBlock.style';
import {
  RichTextBasicBlockProps,
  RichTextBasicBlockState,
} from './RichTextBasicBlock.types';

const textAreaBlockTypes = [
  { type: BLOCK_TYPE.UNSTYLED },
  { type: BLOCK_TYPE.HEADER_ONE },
  { type: BLOCK_TYPE.HEADER_TWO },
  { type: BLOCK_TYPE.HEADER_THREE },
  {
    type: BLOCK_TYPE.UNORDERED_LIST_ITEM,
    icon: <Icons.BulletedList />,
  },
  {
    type: BLOCK_TYPE.ORDERED_LIST_ITEM,
    icon: <Icons.NumberedList />,
  },
];

const textAreaInlineStyles = [
  { type: INLINE_STYLE.BOLD },
  { type: INLINE_STYLE.ITALIC },
  { type: INLINE_STYLE.UNDERLINE },
  { type: INLINE_STYLE.STRIKETHROUGH },
];

export class RichTextBasicBlock extends PureComponent<
  RichTextBasicBlockProps,
  RichTextBasicBlockState
> {
  // eslint-disable-next-line id-blacklist
  static defaultProps = {
    alignment: 'left',
  };

  constructor(props: RichTextBasicBlockProps) {
    super(props);
    this.state = {
      selectedVariable: null,
    };
  }

  componentDidUpdate(): void {
    const { focus, checkId } = this.props;
    // Overriding the beautiful-DND component library from stopping the focus shift out of the text editor components (Banner and Text components).
    // This is needed so that the Rich Text Editor component blurs when clicking a dnd component - hiding its toolbar
    const rteMenuSelector = document.querySelector(
      `.draft${checkId} .Draftail-Editor`
    );
    if (!focus) {
      if (rteMenuSelector) {
        rteMenuSelector.classList.remove('Draftail-Editor--focus');
      }
    }
  }

  onSaveHandler = (raw: null | RawDraftContentState): void => {
    const { onChange, checkId } = this.props;
    if (onChange) onChange(JSON.stringify(raw), checkId);
  };

  render(): JSX.Element {
    const {
      raw,
      backgroundColor,
      textColor,
      paddingTop,
      paddingBottom,
      checkId,
      variableMenuData,
      focus,
      activeChannel,
      alignment,
    } = this.props;

    const variableGroups =
      activeChannel !== 'form' ? variableMenuData : undefined;
    return (
      <DraftailEditorWrapper
        className={`draft${checkId}`}
        key={checkId}
        textColor={textColor}
        backgroundColor={backgroundColor}
        paddingTop={paddingTop}
        paddingBottom={paddingBottom}
        alignment={alignment}
      >
        <DraftailEditorWithVariables
          blockTypes={textAreaBlockTypes}
          inlineStyles={textAreaInlineStyles}
          includeLinkEntity
          onSaveHandler={this.onSaveHandler}
          rawContentState={raw ? JSON.parse(raw) : ''}
          placeholder='Start typing here...'
          spellCheck
          variableButtonClassName='variable-selector'
          variableGroups={variableGroups}
          hasFocus={focus}
        />
      </DraftailEditorWrapper>
    );
  }
}

import { variableIds } from '@whispir/expression-helper';
import { Colors } from '../../../Foundation/Colors';

const { NUMBER } = variableIds;

export const numericSliderBlockModel = (id: string) => ({
  id,
  type: 'FormNumericSlider',
  label: 'Numeric Slider',
  componentType: 'form',
  componentValueType: NUMBER,
  properties: [
    {
      name: 'styles',
      label: 'Styles',
      control: 'TitleDividerNoLine',
      value: 'Numeric Slider',
      __typename: 'ComponentProperty',
    },
    {
      name: 'textInput',
      label: 'Text',
      control: 'TextFieldFilled',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'helperText',
      label: 'Helper text',
      control: 'TextFieldFilled',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'padding_8px_1',
      label: '',
      control: 'PaddingControl8px',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'settings',
      label: 'Settings',
      control: 'TitleDividerNoLine',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'sliderType',
      label: 'Type of Slider__Number,Percentage',
      control: 'Dropdown',
      value: 'Number',
      __typename: 'ComponentProperty',
    },
    {
      name: 'padding_8px_1',
      label: '',
      control: 'PaddingControl8px',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'defaultValue',
      label: 'Default Value',
      control: 'Slider10Step',
      value: '50',
      __typename: 'ComponentProperty',
    },
    {
      name: 'options',
      label: 'Options',
      control: 'TitleDividerNoLine',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'barColor',
      label: 'Bar Color',
      control: 'ColorPicker',
      value: Colors.accent_3,
      __typename: 'ComponentProperty',
    },
    {
      name: 'sliderColor',
      label: 'Slider Color',
      control: 'ColorPicker',
      value: Colors.accent_dnd,
      __typename: 'ComponentProperty',
    },
    {
      name: 'padding_8px_2',
      label: '',
      control: 'PaddingControl8px',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'labels',
      label: 'Labels',
      control: 'TitleDividerNoLine',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'leftLabel',
      label: 'Left',
      control: 'TextFieldFilled',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'centerLabel',
      label: 'Center',
      control: 'TextFieldFilled',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'rightLabel',
      label: 'Right',
      control: 'TextFieldFilled',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'padding_8px_3',
      label: '',
      control: 'PaddingControl8px',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'padding',
      label: 'Padding',
      control: 'TitleDividerNoLine',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'paddingTop',
      label: 'Top',
      control: 'Slider',
      value: '20',
      __typename: 'ComponentProperty',
    },
    {
      name: 'paddingBottom',
      label: 'Bottom',
      control: 'Slider',
      value: '20',
      __typename: 'ComponentProperty',
    },
    {
      name: 'paddingLeft',
      label: 'Left',
      control: 'Slider',
      value: '20',
      __typename: 'ComponentProperty',
    },
    {
      name: 'paddingRight',
      label: 'Right',
      control: 'Slider',
      value: '20',
      __typename: 'ComponentProperty',
    },
  ],
  __typename: 'Component',
});

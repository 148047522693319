import styled from 'styled-components';
import { Colors } from '../../Foundation/Colors';
import { Fonts } from '../../Foundation/Fonts';
import { richTextEditorMenuStyles } from '../richTextEditorMenuStyles';

export const StyledSmsTextareaWithVariables = styled.div<{
  isShowingVariableMenu: boolean;
}>`

  ${richTextEditorMenuStyles}

  background-color: ${Colors.white};
  position: relative;
  border-radius: 0 0 8px 8px;
  width: 100%;

  .dropdown-adornment {
      position: absolute; 
      right:0; 
      bottom:0; 
      margin: 15px; 
      z-index:2; 
  }

  .title {
    padding: 8px 0 32px 2px;
    ${Fonts.subheadSerif}
  }

  [contenteditable] ~ grammarly-extension {
    display: none;
  }

  .Draftail-Toolbar {
    display: none;
  }

  .draftail-wrapper {

    .public-DraftEditorPlaceholder-root {
      position: relative;
    }

    .public-DraftEditorPlaceholder-inner {
      pointer-events: none;
      position: absolute;
      top: 20px;
      left: 16px;
      color: ${Colors.grey_blue_3}
    }

    .public-DraftEditor-content {
      box-sizing: border-box;
      border: 2px solid ${Colors.grey_blue_1};
      padding: ${(props) =>
        props.isShowingVariableMenu ? '15px 15px 54px 15px;' : '15px;'}
      width: 100%;
      ${Fonts.body};
      line-height: 1.7;
      min-height: 200px;

      &::placeholder {
        color: ${Colors.grey_blue_3};
      }

      &:focus {
        outline: none;
      }
    }
  }
`;

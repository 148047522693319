import React, { PureComponent } from 'react';
import moment from 'moment-timezone';
import { BLOCK_TYPE, INLINE_STYLE } from '@whispir/draftail';
import { DraftailEditorWithVariables } from '../../DraftailComponents/DraftailEditorWithVariables';
import { Icon } from '../../Atoms/Icon';
import { Icons } from '../../Foundation/Icons';
import {
  CalendarButton,
  DraftailEditorWrapper,
  ImageWrapper,
  IconWrapper,
  StyledCalendarWrapper,
  LabelWrapper,
  CalendarButtonWrapper,
} from './RichTextCalendarBlock.style';
import {
  RichTextCalendarBlockProps,
  RichTextCalendarBlockState,
} from './RichTextCalendarBlock.types';

const textAreaBlockTypes = [
  { type: BLOCK_TYPE.UNSTYLED },
  { type: BLOCK_TYPE.HEADER_ONE },
  { type: BLOCK_TYPE.HEADER_TWO },
  { type: BLOCK_TYPE.HEADER_THREE },
  {
    type: BLOCK_TYPE.UNORDERED_LIST_ITEM,
    icon: <Icons.BulletedList />,
  },
  {
    type: BLOCK_TYPE.ORDERED_LIST_ITEM,
    icon: <Icons.NumberedList />,
  },
];

const textAreaInlineStyles = [
  { type: INLINE_STYLE.BOLD },
  { type: INLINE_STYLE.ITALIC },
  { type: INLINE_STYLE.UNDERLINE },
  { type: INLINE_STYLE.STRIKETHROUGH },
];
export class RichTextCalendarBlock extends PureComponent<
  RichTextCalendarBlockProps,
  RichTextCalendarBlockState
> {
  constructor(props: RichTextCalendarBlockProps) {
    super(props);
    this.state = {
      selectedVariable: null,
    };
  }

  componentDidUpdate(): void {
    const { focus, checkId } = this.props;
    // Overriding the beautiful-DND component library from stopping the focus shift out of the text editor components (Banner and Text components).
    // This is needed so that the Rich Text Editor component blurs when clicking a dnd component - hiding its toolbar
    const rteMenuSelector = document.querySelector<HTMLElement>(
      `.draft${checkId} .Draftail-Editor`
    );
    if (!focus) {
      if (rteMenuSelector) {
        rteMenuSelector.classList.remove('Draftail-Editor--focus');
      }
    }
  }

  onSaveHandler = (raw: unknown, idx: number): void => {
    const { onChange, checkId, raw: rawArr } = this.props;
    const parsedRaw = JSON.parse(rawArr);
    parsedRaw[idx] = raw;

    onChange(JSON.stringify(parsedRaw), checkId);
  };

  render(): React.ReactNode {
    const {
      raw,
      backgroundColor,
      textColor,
      checkId,
      variableMenuData,
      focus,
      activeChannel,
      customIcon,
      hideText,
      alignment,
      iconAlignment,
      iconSrc,
      iconColor,
      hideIcon,
      paddingTop,
      paddingBottom,
      paddingLeft,
      paddingRight,
      fullWidth,
      buttonColor,
      borderRadius,
      buttonBorderColor,
      buttonTextColor,
      buttonLabel,
      inviteFromToAllDay,
      inviteFromTo,
      inviteAllDay,
      inviteTitle,
      inviteDescription,
      inviteLocation,
      inviteFileName,
    } = this.props;

    // if date not supplied return today's date
    const formatDateIso = (date?: string): string => {
      const format = 'YYYYMMDD[T]HHmmss';
      return date
        ? moment(date).format(format).valueOf()
        : moment().format(format).valueOf();
    };

    const formatIcsText = (text) => {
      // Have to decode these chars
      const textWithSlashReplaced = text.replace('&#x27;', "'");
      const textWithAmpersandReplaced = textWithSlashReplaced.replace(
        '&amp;',
        '&'
      );
      const textWithQuoteReplaced = textWithAmpersandReplaced.replace(
        '&quot;',
        '"'
      );

      // and escape backslash, semi-colon, and comma for .ics file text format.
      // See https://tools.ietf.org/html/rfc5545#section-3.2
      const textIcsFormat = textWithQuoteReplaced.replace(/[\\;,]/g, '\\$&');

      return textIcsFormat;
    };

    const timezone = moment.tz.guess();

    const parsedInviteFromTo =
      inviteFromTo && typeof inviteFromTo === 'string'
        ? JSON.parse(inviteFromTo)
        : inviteFromTo;

    const parsedInviteFromToAllDay =
      inviteFromToAllDay && typeof inviteFromToAllDay === 'string'
        ? JSON.parse(inviteFromToAllDay)
        : inviteFromToAllDay;

    const fromDate = parsedInviteFromTo
      ? formatDateIso(parsedInviteFromTo.from)
      : formatDateIso();

    const toDate = parsedInviteFromTo
      ? formatDateIso(parsedInviteFromTo.to)
      : formatDateIso();

    // remove time values for all day events
    const fromDateAllDay = parsedInviteFromToAllDay
      ? formatDateIso(parsedInviteFromToAllDay.from).split('T')[0]
      : formatDateIso().split('T')[0];

    const toDateAllDay = parsedInviteFromToAllDay
      ? formatDateIso(
          moment(parsedInviteFromToAllDay.to).add(1, 'days').toString()
        ).split('T')[0]
      : formatDateIso().split('T')[0];

    // See https://tools.ietf.org/html/rfc5545#section-3.4 for ics file specs
    const icsString = `data: text/calendar;charset=UTF-8,
        BEGIN:VCALENDAR
        VERSION:2.0
        BEGIN:VEVENT
        ${
          // when VALUE=DATE event is all day, also no timezone necessary for all day events
          inviteAllDay === 'true'
            ? `DTSTART;VALUE=DATE:${fromDateAllDay}
             DTEND;VALUE=DATE:${toDateAllDay}`
            : `DTSTART;TZID=${timezone}:${fromDate}
             DTEND;TZID=${timezone}:${toDate}`
        }
        SUMMARY:${formatIcsText(inviteTitle)} 
        DESCRIPTION:${formatIcsText(inviteDescription)}
        LOCATION:${formatIcsText(inviteLocation)}
        END:VEVENT
        END:VCALENDAR
      `.replace(/^ +/gm, '');
    // above removes leading whitespace

    const href = encodeURI(icsString);

    // check if the component instance is inside message-studio-ui - with no variableMenuItems available (yet)
    // This will toggle the VariableMenu display in message-studio-ui
    const variableGroups =
      activeChannel !== 'form' ? variableMenuData : undefined;
    const trimmedButtonLabel = buttonLabel ? buttonLabel.trim() : '';
    const parsedRaw = JSON.parse(raw);
    const PlaceholderImage = 'AddToCalendar';
    // test custom icon link: https://cdn4.iconfinder.com/data/icons/small-n-flat/24/calendar-48.png

    const renderButtonLayout = () => {
      const labelLayout =
        trimmedButtonLabel === ''
          ? []
          : [
              <LabelWrapper key={buttonLabel}>
                <span dangerouslySetInnerHTML={{ __html: buttonLabel }} />
              </LabelWrapper>,
            ];
      const defaultIconLayout = [
        <IconWrapper iconColor={iconColor} key={iconSrc}>
          <Icon icon={PlaceholderImage} />
        </IconWrapper>,
      ];
      const customIconLayout = [
        <ImageWrapper key={iconSrc}>
          {iconSrc !== '' && <img src={iconSrc} alt='Calendar Icon' />}
        </ImageWrapper>,
      ];
      if (hideIcon === 'false') {
        if (customIcon === 'false') {
          return [...defaultIconLayout, ...labelLayout];
        }
        return [...customIconLayout, ...labelLayout];
      }
      return [...labelLayout];
    };

    const ButtonLayout = renderButtonLayout();
    return (
      <StyledCalendarWrapper
        paddingTop={paddingTop}
        paddingBottom={paddingBottom}
        backgroundColor={backgroundColor}
      >
        {hideText === 'false' && (
          <DraftailEditorWrapper
            paddingLeft={paddingLeft}
            paddingRight={paddingRight}
            className={`draft${checkId}`}
            key={`${checkId}`}
            textColor={textColor}
          >
            <DraftailEditorWithVariables
              blockTypes={textAreaBlockTypes}
              inlineStyles={textAreaInlineStyles}
              includeLinkEntity
              onSaveHandler={(raw) => this.onSaveHandler(raw, 0)}
              placeholder='Start typing here...'
              rawContentState={parsedRaw[0] || null}
              spellCheck
              variableGroups={variableGroups}
              variableButtonClassName='variable-selector'
              hasFocus={focus}
            />
          </DraftailEditorWrapper>
        )}
        <CalendarButtonWrapper
          paddingLeft={paddingLeft}
          paddingRight={paddingRight}
        >
          <CalendarButton
            fullWidth={fullWidth}
            buttonColor={buttonColor}
            borderRadius={borderRadius}
            buttonBorderColor={buttonBorderColor}
            buttonTextColor={buttonTextColor}
            alignment={alignment}
            href={href}
            download={`${inviteFileName}.ics`}
          >
            {iconAlignment === 'right' ? ButtonLayout.reverse() : ButtonLayout}
          </CalendarButton>
        </CalendarButtonWrapper>
      </StyledCalendarWrapper>
    );
  }
}

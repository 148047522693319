import styled from 'styled-components';

export const StyledSideDrawerContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;

  .title-container {
    padding: 40px 24px 0px;
  }

  .header-container {
    position: absolute;
    display: flex;
    flex-flow: row nowrap;
    right: 7px;
    top: 0;
    padding: 16px 24px 24px 24px;
  }

  .content-container {
    padding: 24px 0 24px 24px;
    overflow-y: auto;
    width: 100%;
    box-sizing: border-box;
    flex: 1;
  }

  .footer-container {
    height: 82px;
    padding-left: 16px;
  }
`;

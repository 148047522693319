import styled from 'styled-components';
import { Fonts } from '../../Foundation/Fonts';

import { RichTextFooterBlockProps } from './RichTextFooterBlock.types';

export const StyledFooterWrapper = styled.div<
  Pick<
    RichTextFooterBlockProps,
    'paddingTop' | 'paddingBottom' | 'backgroundColor' | 'textColor'
  >
>`
  margin-top: ${(props) => props.paddingTop}px;
  margin-bottom: ${(props) => props.paddingBottom}px;
  background-color: ${(props) => props.backgroundColor};
  padding: 20px;
  width: 100%;
  box-sizing: border-box;

  .logo-wrapper {
    padding: 16px 0 24px 0;
  }

  .text-content-wrapper {
    ${Fonts.bodySmall};
    word-break: break-word;
    color: ${(props) => props.textColor};
  }

  .links-wrapper {
    display: flex;
    padding: 8px 0 8px;
    a {
      ${Fonts.bodySmall};
      text-decoration: none;
      padding: 16px 32px 0 0;
      color: ${(props) => props.textColor};
      position: relative;

      &:after {
        position: absolute;
        right: 15px;
        content: '|';
        color: ${(props) => props.textColor};
        font-size: 12px;
      }

      &:last-child:after {
        content: '';
      }
    }
  }
`;

import styled from 'styled-components';
import { Colors } from '../../Foundation/Colors';

export const StyledCardList = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  display: relative;

  a {
    text-decoration: none;
    color: ${Colors.grey_blue};

    &:hover {
      text-decoration: none;
    }
  }

  .card-wrapper {
    margin-bottom: 24px;
    margin-right: 24px;
    position: relative;
  }
`;

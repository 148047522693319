import React, { PureComponent } from 'react';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { v4 as uuid } from 'uuid';
import { ColorPicker } from '../../Atoms/ColorPicker';
import { TextField } from '../../Atoms/Forms';
import { IconButton } from '../../Atoms/Navigations';
import { Icon } from '../../Atoms/Icon';
import { Colors } from '../../Foundation/Colors';

import {
  DragHandleWrapper,
  ComponentBoxProperties,
  OptionsContainer,
  AddAnotherBtn,
  StyledSubHeader,
} from '../OptionsPicker/OptionsPicker.styles';

import { ComponentBox, DefaultOptionsWrapper } from './LinkGenerator.styles';

type LinkOption = {
  id: string;
  title?: string;
  text: string;
  link: string;
  background: string;
};

export type LinkGeneratorProps = {
  defaultValue: Array<LinkOption>;
  onChange: (value: string) => void;
  hasBackgroundColorPicker?: boolean;
};

type State = {
  disableDeleteButton: boolean;
  items: Array<LinkOption>;
};

const reorder = (list, startIndex, endIndex): Array<LinkOption> => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result as Array<LinkOption>;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  background: isDragging ? 'none' : 'none',
  ...draggableStyle,
});

const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? `${Colors.accent_2}` : `${Colors.background}`,
  padding: 0,
});

export class LinkGenerator extends PureComponent<LinkGeneratorProps, State> {
  // I think static defaultProps is ok for class components
  // eslint-disable-next-line id-blacklist
  static defaultProps = {
    hasBackgroundColorPicker: true,
    defaultValue: [
      {
        id: uuid(),
        title: 'Link',
        text: 'Yes',
        link: 'Enter a link',
        background: Colors.accent,
      },
      {
        id: uuid(),
        title: 'Link',
        text: 'No',
        link: 'Enter a link',
        background: Colors.warning_dark,
      },
      {
        id: uuid(),
        title: 'Link',
        text: 'Maybe',
        link: 'Enter a link',
        background: Colors.voice,
      },
    ],

    onChange: () => undefined,
  };

  constructor(props: LinkGeneratorProps) {
    super(props);
    const { defaultValue } = this.props;
    this.state = {
      items:
        typeof defaultValue === 'string'
          ? JSON.parse(defaultValue)
          : defaultValue,
      disableDeleteButton: false,
    };
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  onDragEnd(result: any) {
    const { onChange } = this.props;
    const { items } = this.state;
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const updateItems = reorder(
      items,
      result.source.index,
      result.destination.index
    );

    this.setState({
      items: updateItems,
    });

    onChange(JSON.stringify(updateItems));
  }

  handleOnDelete = (index: number) => {
    const { onChange } = this.props;
    const { items } = this.state;
    const updateItems = [...items];

    if (updateItems.length > 1) {
      updateItems.splice(index, 1);
    }

    this.setState(
      {
        items: updateItems,
      },
      () =>
        updateItems.length <= 1 &&
        this.setState({
          disableDeleteButton: true,
        })
    );

    onChange(JSON.stringify(updateItems));
  };

  handleTextInput = (index: number) => (event: string) => {
    const { onChange } = this.props;
    const { items } = this.state;
    const updateItems = [...items];
    updateItems[index].text = event;

    this.setState({
      items: updateItems,
    });

    onChange(JSON.stringify(updateItems));
  };

  handleLinkInput = (index: number) => (event: string) => {
    const { onChange } = this.props;
    const { items } = this.state;
    const updateItems = [...items];
    updateItems[index].link = event;

    this.setState({
      items: updateItems,
    });

    onChange(JSON.stringify(updateItems));
  };

  handleBackgroundOption = (index: number) => (event: string) => {
    const { onChange } = this.props;
    const { items } = this.state;
    const updateItems = [...items];

    updateItems[index].background = event.toString();

    this.setState({
      items: updateItems,
    });

    onChange(JSON.stringify(updateItems));
  };

  handleAddAnotherOption = () => {
    const { onChange, hasBackgroundColorPicker } = this.props;
    const { items } = this.state;
    const updateItems = [...items];
    const currentLength = updateItems.length;
    const defaultOption = {
      id: uuid(),
      text: hasBackgroundColorPicker
        ? `Button ${currentLength + 1}`
        : `Link ${currentLength + 1}`,
      link: 'Enter a link',
      background: '#6200EE',
    };

    updateItems.push(defaultOption);

    this.setState({
      items: updateItems,
      disableDeleteButton: false,
    });

    onChange(JSON.stringify(updateItems));
  };

  render() {
    const { items, disableDeleteButton } = this.state;
    const { hasBackgroundColorPicker } = this.props;

    return (
      <OptionsContainer>
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId='droppable'>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {items.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        <ComponentBox>
                          <DragHandleWrapper>
                            <Icon icon='DragHandle' />
                          </DragHandleWrapper>
                          <ComponentBoxProperties>
                            {item.title && (
                              <StyledSubHeader>{item.title}</StyledSubHeader>
                            )}
                            <TextField
                              label={
                                hasBackgroundColorPicker
                                  ? 'Button Text'
                                  : 'Link Text'
                              }
                              aria-label={`button text ${index}`}
                              id={`button text ${index}`} // for accessibilty when testing using RTL
                              defaultValue={item.text}
                              onChange={this.handleTextInput(index)}
                              variant='filled'
                              placeholder={
                                hasBackgroundColorPicker
                                  ? 'Button Text'
                                  : 'Link Text'
                              }
                            />
                            <TextField
                              label={
                                hasBackgroundColorPicker
                                  ? 'Button URL'
                                  : 'Link URL'
                              }
                              aria-label={`button URL ${index}`}
                              id={`button URL ${index}`} // for accessibilty when testing using RTL
                              defaultValue={item.link}
                              onChange={this.handleLinkInput(index)}
                              variant='filled'
                              placeholder='Enter a Link URL'
                            />
                            <DefaultOptionsWrapper
                              disableDeleteButton={disableDeleteButton}
                              hasBackgroundColorPicker={
                                hasBackgroundColorPicker
                              }
                            >
                              {hasBackgroundColorPicker && (
                                <ColorPicker
                                  defaultValue={item.background}
                                  onChange={this.handleBackgroundOption(index)}
                                  labelSize='small'
                                  label='Button Color'
                                />
                              )}

                              <IconButton
                                icon='Delete'
                                className='options-picker__delete-btn'
                                onClick={() => this.handleOnDelete(index)}
                                ariaLabel={`delete link option ${index}`}
                              />
                            </DefaultOptionsWrapper>
                          </ComponentBoxProperties>
                        </ComponentBox>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <AddAnotherBtn type='button' onClick={this.handleAddAnotherOption}>
          <Icon icon='Plus' />
          <p>Add Another Option</p>
        </AddAnotherBtn>
      </OptionsContainer>
    );
  }
}

import React from 'react';

import { Button } from '../Button';
import { CardButtonProps } from './CardButton.types';
import { StyledCardButton } from './CardButton.styles';

export const CardButton = (props: CardButtonProps) => {
  const { onClick, children, className = '' } = props;
  return (
    <StyledCardButton className={className} onClick={onClick}>
      {children}
    </StyledCardButton>
  );
};

import React from 'react';

import styled from 'styled-components';
import { TextField } from '../../Atoms/Forms/TextField';
import { Colors } from '../../Foundation/Colors';
import { Fonts } from '../../Foundation/Fonts';

import type { InteractiveMapProps } from './InteractiveMap.types';

export const InteractiveMapHeader = styled.div`
  ${Fonts.bodyLarge};
  color: ${Colors.grey_blue_4};
  padding-bottom: 8px;
`;

export const InteractiveMapSubHeader = styled.div<InteractiveMapProps>`
  ${Fonts.body};
  color: ${Colors.grey_blue};
  padding-bottom: 10px;
`;

// circular dependencies issue: https://github.com/styled-components/styled-components/issues/1449
export const InteractiveMapPlaceholder = styled((props) => (
  <TextField {...props} />
))`
  &.MuiFormControl-marginNormal {
    margin: 0px;
    padding-bottom: 24px;
  }
`;

export const InteractiveMapStyleWrapper = styled.div<InteractiveMapProps>`
  padding: ${({ paddingTop, paddingRight, paddingBottom, paddingLeft }) =>
    `${paddingTop}px ${paddingRight}px ${paddingBottom}px ${paddingLeft}px`};
  width: 100%;
  height: 100%;
  &.interactive-map-wrapper {
  }
`;

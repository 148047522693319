import React from 'react';

import { Icon } from '../../Atoms/Icon/Icon';
import { InfoPanelTypes } from './InfoPanel.types';
import { StyledInfoPanel } from './InfoPanel.styles';

export const InfoPanel = ({
  type = 'warning',
  icon = 'InfoOutline',
  message = 'This is a message',
}: InfoPanelTypes) => {
  return (
    <StyledInfoPanel className={type}>
      <Icon icon={icon} />
      {message}
    </StyledInfoPanel>
  );
};

import React from 'react';

import { PageTitleField } from '../PageTitleField';
import { VariableTextInput } from '../../../DraftailComponents/VariableTextInput';
import { GeneralSettingsProps } from './GeneralSettings.types';

export const GeneralSettings = (props: GeneralSettingsProps): JSX.Element => {
  const {
    defaultTitle,
    draft,
    updateLocalDraft,
    channel,
    variableMenuData,
  } = props;

  const renderSettings = (channel) => {
    switch (channel) {
      case 'web':
      case 'email':
      case 'webform':
        return (
          <VariableTextInput
            key={channel}
            variableTextInputType={channel}
            defaultTitle={defaultTitle}
            onChange={updateLocalDraft}
            defaultValue={draft[channel].title}
            variableMenuData={variableMenuData || {}}
          />
        );
      case 'form':
        return (
          // trigger forms create formVars - but do not display them atm
          <PageTitleField
            type={channel}
            defaultTitle={defaultTitle}
            pageTitle={draft[channel].title}
            updateLocalDraft={updateLocalDraft}
          />
        );

      default:
        throw new Error(`Channel type: ${channel} is not found 🚫`);
    }
  };

  return renderSettings(channel);
};

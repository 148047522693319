import React, { PureComponent } from 'react';
import { BLOCK_TYPE, INLINE_STYLE } from '@whispir/draftail';
import { DraftailEditorWithVariables } from '../../DraftailComponents/DraftailEditorWithVariables';
import { Icons } from '../../Foundation/Icons';
import {
  DraftailEditorWrapper,
  TextEditorContainer,
  HeadingContainer,
} from './RichTextBannerBlock.style';
import {
  RichTextBannerBlockProps,
  RichTextBannerBlockState,
} from './RichTextBannerBlock.types';

const textAreaBlockTypes = [
  { type: BLOCK_TYPE.UNSTYLED },
  { type: BLOCK_TYPE.HEADER_ONE },
  { type: BLOCK_TYPE.HEADER_TWO },
  { type: BLOCK_TYPE.HEADER_THREE },
  {
    type: BLOCK_TYPE.UNORDERED_LIST_ITEM,
    icon: <Icons.BulletedList />,
  },
  {
    type: BLOCK_TYPE.ORDERED_LIST_ITEM,
    icon: <Icons.NumberedList />,
  },
];

const textAreaInlineStyles = [
  { type: INLINE_STYLE.BOLD },
  { type: INLINE_STYLE.ITALIC },
  { type: INLINE_STYLE.UNDERLINE },
  { type: INLINE_STYLE.STRIKETHROUGH },
];

export class RichTextBannerBlock extends PureComponent<
  RichTextBannerBlockProps,
  RichTextBannerBlockState
> {
  // eslint-disable-next-line id-blacklist
  static defaultProps = {
    fullWidth: false,
  };

  constructor(props: RichTextBannerBlockProps) {
    super(props);
    this.state = {
      selectedVariable: null,
    };
  }

  componentDidUpdate(): void {
    const { focus, checkId } = this.props;
    // Overriding the beautiful-DND component library from stopping the focus shift out of the text editor components (Banner and Text components).
    // This is needed so that the Rich Text Editor component blurs when clicking a dnd component - hiding its toolbar
    const rteMenuSelector = document.querySelector(
      `.draft${checkId} .Draftail-Editor`
    );
    if (!focus) {
      if (rteMenuSelector) {
        rteMenuSelector.classList.remove('Draftail-Editor--focus');
      }
    }
  }

  onSaveHandler = (raw): void => {
    const { onChange, checkId } = this.props;
    if (onChange) onChange(JSON.stringify(raw), checkId);
  };

  render(): JSX.Element {
    const {
      raw,
      backgroundColor,
      textColor,
      src,
      height,
      fullWidth,
      textPosition,
      textPadding,
      marginTop,
      marginBottom,
      checkId,
      focus,
      variableMenuData,
      activeChannel,
    } = this.props;

    const imageSource = src.replace(/\\/g, '');

    const variableGroups =
      activeChannel !== 'form' ? variableMenuData : undefined;
    return (
      <TextEditorContainer
        focus={focus}
        key={checkId}
        backgroundColor={backgroundColor}
        imageSource={imageSource}
        height={height}
        fullWidth={activeChannel !== 'email' ? fullWidth : 'false'}
        marginTop={marginTop}
        marginBottom={marginBottom}
      >
        <HeadingContainer textPadding={textPadding}>
          <DraftailEditorWrapper
            className={`draft${checkId}`}
            key={checkId}
            height={height}
            textColor={textColor}
            textPosition={textPosition}
          >
            <DraftailEditorWithVariables
              placeholder=''
              includeLinkEntity
              blockTypes={textAreaBlockTypes}
              inlineStyles={textAreaInlineStyles}
              onSaveHandler={this.onSaveHandler}
              rawContentState={JSON.parse(raw)}
              spellCheck
              variableGroups={variableGroups}
              variableButtonClassName='variable-selector'
              hasFocus={focus}
            />
          </DraftailEditorWrapper>
        </HeadingContainer>
      </TextEditorContainer>
    );
  }
}

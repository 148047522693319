import styled from 'styled-components';
import { Colors } from '../../../Foundation/Colors';
import { Fonts } from '../../../Foundation/Fonts';

import { ButtonWrapperProps } from './Button.types';

export const ButtonWrapper = styled.div<ButtonWrapperProps>`
  display: inline-block;

  :active,
  :hover,
  :active {
    box-shadow: none;
  }

  :focus-within {
    box-shadow: 0 0 0 4px ${Colors.accent_2};
    border-radius: 22px;
    padding: 0;
  }

  .MuiButton-root {
    ${Fonts.link};
    height: 40px;
    padding: 8px 24px;
    border-radius: 22px;
    text-transform: none;
    background-color: ${Colors.accent};
    min-width: 31px;
    .MuiButton-label {
      height: ${({ size }) => (size === 'large' ? '24px' : '16px')};
    }
    .MuiButton-endIcon,
    .MuiButton-startIcon {
      svg {
        height: 20px;
        width: 20px;
      }
    }
    .MuiButton-startIcon {
      margin-right: 8px;
    }

    &.primary,
    &.secondary,
    &.error,
    &.warning,
    &.success {
      .MuiButton-root {
        height: 100%;

        .MuiButton-endIcon,
        .MuiButton-startIcon {
          svg {
            height: 20px;
            width: 20px;
            fill: ${Colors.white};
          }
        }
      }
    }
  }

  &.primary {
    .MuiButton-root {
      background: ${Colors.accent};

      :hover,
      :active {
        color: ${Colors.white};
        background: ${Colors.accent_dark};
      }
    }
  }

  &.secondary {
    .MuiButton-root {
      background: ${Colors.grey_blue_4};

      :hover,
      :active {
        color: ${Colors.white};
        background: ${Colors.grey_blue};
      }
    }
  }

  &.error {
    .MuiButton-root {
      background: ${Colors.error};
    }
  }

  &.warning {
    .MuiButton-root {
      background: ${Colors.warning};
    }
  }

  &.success {
    .MuiButton-root {
      background: ${Colors.success};
    }
  }

  &.error,
  &.warning,
  &.success {
    .MuiButton-root {
      :hover,
      :active {
        background: ${Colors.black};
      }
    }
  }

  &.primary,
  &.secondary,
  &.error,
  &.warning,
  &.success {
    .MuiButton-root {
      color: ${Colors.white};

      .MuiButton-endIcon,
      .MuiButton-startIcon {
        svg {
          fill: ${Colors.white};
        }
      }
    }
  }

  &.primary,
  &.secondary,
  &.error,
  &.warning,
  &.success {
    .Mui-disabled {
      background-color: ${Colors.grey_blue_1};
      color: ${Colors.grey_blue_3};

      .MuiButton-endIcon,
      .MuiButton-startIcon {
        svg {
          fill: ${Colors.grey_blue_3};
        }
      }
    }
  }
`;

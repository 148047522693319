import React, { useState } from 'react';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'; // TODO: use DatePicker from ui-lib-v2
import MomentUtils from '@date-io/moment';
import { TrueFalseString } from '../utilityTypes';
import { TextInputWrapper } from './FormDatePicker.style';

type Props = {
  uniqueName: string;
  helperText: string;
  label: string;
  paddingTop: string;
  paddingBottom: string;
  defaultValueToday: TrueFalseString;
  dateFormat: string;
  requiredField: TrueFalseString;
};

export const FormDatePicker = ({
  uniqueName,
  dateFormat,
  defaultValueToday,
  requiredField,
  helperText,
  label,
  paddingTop,
  paddingBottom,
}: Props) => {
  const today = new Date();
  const defaultValue = defaultValueToday === 'true' ? today : null;

  const [selectedDate, setSelectedDate] = useState(defaultValue);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <TextInputWrapper paddingTop={paddingTop} paddingBottom={paddingBottom}>
        <KeyboardDatePicker
          id={uniqueName}
          clearable
          label={label}
          required={requiredField === 'true'}
          helperText={helperText}
          format={dateFormat || 'DD/MM/YYYY'}
          value={defaultValueToday === 'true' ? today : selectedDate}
          onChange={handleDateChange}
          keyboardIcon=''
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </TextInputWrapper>
    </MuiPickersUtilsProvider>
  );
};

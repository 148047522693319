import React, { Fragment } from 'react';
import IconButton from '@material-ui/core/IconButton';

import { updateDragAndDropItems } from '../DroppableTargetItems/Context';
import { getDerivePropertyRules } from '../DroppableTargetItems/Blocks';
import { Icons } from '../../Foundation/Icons';
import { GeneralSettings } from './GeneralSettings';
import { DynamicComponent } from './FormControls/DynamicComponent';
import { ChannelSettingsWrapper } from './utils.styles';
import {
  ComponentPropertySettingsProps,
  ComponentPropertyControlProps,
  CloseButtonProps,
  ChannelSettingsProps,
} from './utils.types';

const { Close } = Icons;

export const applyDerivedProperties = (properties, derivedProperties) =>
  properties.map((property) => {
    const { name } = property;
    const derivedProperty = {};

    derivedProperties.forEach(({ targets = [], rules = [] }) => {
      const target = targets.find((target) => target === name);

      if (target) {
        rules.forEach(({ handler, name: ruleName }) => {
          if (typeof handler === 'function') {
            derivedProperty[ruleName] = handler(properties);
          }
        });
      }
    });

    return { ...property, ...derivedProperty };
  });

export const getAvailableProperties = ({
  properties = [],
  activeComponentId,
  draft,
  activeChannel,
}) => {
  // check activeComponent in the context belongs to current active channel

  const channelData = draft[activeChannel];
  const { components } = channelData;

  if (!activeComponentId) {
    return [];
  }

  const activeComponent = components.find(({ id }) => id === activeComponentId);

  if (!activeComponent) return [];

  const derivedProperties = getDerivePropertyRules(activeComponent.type);

  // filter out properties that doesn't need to render out
  return applyDerivedProperties(
    properties.filter(({ control }) => control !== 'none'),
    derivedProperties
  );
};

export const CloseButton = ({
  visibility,
  onHandleClick,
}: CloseButtonProps) => {
  if (visibility) {
    return (
      <IconButton
        key='close'
        className='close-button'
        aria-label='Close'
        color='inherit'
        onClick={onHandleClick}
      >
        <Close />
      </IconButton>
    );
  }
  return null;
};

const ComponentPropertyControl = ({
  componentProperty,
  setActiveComponent,
  activeComponent,
  variableMenuData,
  activeChannel,
  getModifyVariableConfirmationModal,
}: ComponentPropertyControlProps) => {
  const { id: activeComponentId } = activeComponent;
  const { control, name, value, label, hidden } = componentProperty || {};

  const changeHandler = (val: number | string) => {
    setActiveComponent({
      activeComponent: updateDragAndDropItems(
        activeComponent,
        name,
        val.toString()
      ),
    });
  };

  if (hidden) return null;

  return (
    <DynamicComponent
      type={control}
      defaultValue={value}
      label={label}
      onChange={changeHandler}
      key={`${name}${activeComponentId}`}
      componentId={activeComponentId}
      getModifyVariableConfirmationModal={getModifyVariableConfirmationModal}
      variableMenuData={variableMenuData}
      variableTextInputType='componentPropertyInput'
      activeChannel={activeChannel}
    />
  );
};

export const ComponentPropertySettings = (
  props: ComponentPropertySettingsProps
) => {
  const { componentProperties, defaultPropertyTitle } = props;

  return (
    <Fragment>
      <p className='component-property__title'>{defaultPropertyTitle}</p>
      <div className='properties-scroll-wrapper'>
        {componentProperties.map((componentProp) => (
          <ComponentPropertyControl
            key={componentProp && componentProp.name}
            componentProperty={componentProp}
            {...props}
          />
        ))}
      </div>
    </Fragment>
  );
};

export const ChannelSettings = ({
  defaultPropertyTitle,
  draft,
  activeChannel,
  updateLocalDraft,
  defaultTitle,
  variableMenuData,
}: ChannelSettingsProps) => (
  <ChannelSettingsWrapper>
    <p className='component-property__title general'>{defaultPropertyTitle}</p>
    <GeneralSettings
      draft={draft}
      channel={activeChannel}
      updateLocalDraft={updateLocalDraft}
      defaultTitle={defaultTitle}
      variableMenuData={variableMenuData}
    />
  </ChannelSettingsWrapper>
);

import React from 'react';

import { OuterWrapper, InnerWrapper } from './ContentContainer.styles';
import { ContentContainerTypes } from './ContentContainer.types';

export const ContentContainer = ({
  children,
  hasNoBackground,
}: ContentContainerTypes): JSX.Element => {
  return (
    <OuterWrapper hasNoBackground={hasNoBackground}>
      <InnerWrapper hasNoBackground={hasNoBackground}>{children}</InnerWrapper>
    </OuterWrapper>
  );
};

import styled from 'styled-components';
import { Fonts } from '../../Foundation/Fonts';
import { Colors } from '../../Foundation/Colors';

export const StyledWrapper = styled.div`
  display: flex;
  align-items: center;

  .MuiTypography-root {
    ${Fonts.caption}
    color: ${Colors.grey_blue};
    margin-left: 8px;
    margin-right: 48px;
  }

  .MuiButton-root {
    min-width: unset;
  }

  svg {
    width: 16px;
  }
`;

import styled from 'styled-components';
import { Colors } from '../../../Foundation/Colors';
import { Fonts } from '../../../Foundation/Fonts';

export const HintWrapper = styled.div`
  border-bottom: solid 1px ${Colors.grey_blue_1};
  padding: 10px 10px 10px 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  ${Fonts.bodySmall};

  span {
    padding-right: 5px;
  }
`;

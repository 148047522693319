import React from 'react';

import { TextAreaTypes } from './TextArea.types';
import { TextAreaWrapper } from './TextArea.style';

export const TextArea = (props: TextAreaTypes): JSX.Element => {
  const {
    readOnly,
    disabled,
    required,
    value,
    defaultValue,
    onChange,
    placeholder,
    variant,
    rowsMax,
    resize,
    height,
    ...otherProps
  } = props;

  const handleChange = (newValue: string): string =>
    onChange && onChange(newValue);
  const resizeClass = `resize--${resize}`;
  return (
    <TextAreaWrapper
      readOnly={readOnly}
      disabled={disabled}
      placeholder={placeholder}
      defaultValue={defaultValue}
      value={value}
      onChange={(evt) => handleChange(evt.target.value)}
      required={required}
      variant={variant}
      rowsMax={rowsMax}
      className={`${variant === 'filled' ? 'filled' : ''} ${
        resize ? resizeClass : ''
      }`}
      {...otherProps}
      style={{ height }}
    ></TextAreaWrapper>
  );
};

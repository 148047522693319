import styled from 'styled-components';
import { Colors } from '../../Foundation/Colors';

import { ComponentBoxProps } from './DragAndDropLayout.types';

export const OuterWrapper = styled.div`
  width: 100%;
  display: flex;
`;

export const Label = styled.div`
  padding: 10px;
  text-align: left;
`;

export const ComponentBox = styled.div<ComponentBoxProps>`
  padding: 10px;
  text-align: center;
  border: 1px
    ${(props) =>
      props.isDragging
        ? `dashed ${Colors.black}`
        : `solid ${Colors.grey_blue_2}`};
  user-select: none;
  background: ${Colors.white};
  position: relative;
  button {
    position: absolute;
    top: 5px;
    right: 5px;
  }
`;

export const Clone = styled(ComponentBox)`
  + div {
    display: none !important;
  }
`;

import AP1 from './ap1.json';
import AU from './au.json';
import US from './us.json';
import STAGING from './staging.json';

export default {
  AP1,
  AU,
  US,
  STAGING,
};

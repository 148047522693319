import React, { useState, useEffect } from 'react';

import { Button } from '@material-ui/core';
import { RadioButtonGroup } from '../RadioButtonGroup';
import { RadioButtonGroupWrapper } from './ConditionPicker.styles';
import { formControlDefinitions } from './model';
import {
  ConditionPickerChangeHandler,
  ConditionProps,
  OperatorLabel,
} from './definitions/operatorAndOperand';

export type FormControlType =
  | 'FormTextInput'
  | 'FormTextArea'
  | 'FormDatePicker'
  | 'FormRadioList'
  | 'FormCheckboxList'
  | 'FormDropdown'
  | 'FilterDatePicker'
  | 'FormNumericSlider'
  | 'FormYesNoButtons'
  | 'Text'
  | 'Number'
  | 'Date'
  | string; // I'm explicitly type widing here, just while we tidy up all of the consumers of this component, to make sure they have the right types

type Props = {
  defaultValues?: ConditionProps;
  onChange?: ConditionPickerChangeHandler;
  onClose?: (...args: Array<unknown>) => void;
  operandOptions?: Array<Record<string, OperatorLabel>>;
  formControlType: FormControlType; // I'm explicitly type widing here, just while we tidy up all of the consumers of this component, to make sure they have the right types
};

const getDefaultOperator = (
  defaultValues: ConditionProps,
  operatorOptions: Array<OperatorLabel>
) => {
  return (
    (defaultValues && defaultValues.operator) ||
    (operatorOptions && operatorOptions[0] && operatorOptions[0].value) ||
    null
  );
};

const getControlDefinition = (formControlType) => {
  const controlDefinition = formControlDefinitions[formControlType];
  if (!controlDefinition) {
    return { controlDefinition: {}, operators: [] };
  }

  return {
    controlDefinition,
    operators: Object.values(controlDefinition) as Array<OperatorLabel>,
  };
};

export const ConditionPicker = ({
  defaultValues = {
    operator: null,
    operand: null,
  },
  onChange = () => undefined,
  onClose = () => undefined,
  operandOptions = [],
  formControlType,
}: Props): JSX.Element => {
  const { controlDefinition, operators } = getControlDefinition(
    formControlType
  );
  const defaultOperator = getDefaultOperator(defaultValues, operators);
  const defaultConditions = { ...defaultValues, operator: defaultOperator };

  const [state, setState] = useState(defaultConditions);
  const { operator, operand } = state;
  const operandConfig = controlDefinition[state.operator] || {};

  // An operator override can be injected through the operandConfig,
  // to create an onChange event with a different operator to the one displayed.
  const { component: OperandComponent, operatorOverride } = operandConfig;

  useEffect(() => {
    const onChangeValues = {
      ...state,
      operator: operatorOverride || state.operator,
      oldOperator: operatorOverride ? state.operator : undefined,
    };
    onChange(onChangeValues);
  }, [operator, operand]);

  const handleOperatorChange = (value) => {
    setState({ ...state, operator: value });
  };
  const handleOperandChange = (value) => {
    setState({ ...state, operand: value });
  };

  return (
    <RadioButtonGroupWrapper>
      <RadioButtonGroup
        defaultValue={defaultConditions.operator}
        onChange={handleOperatorChange}
        items={operators}
        compact
        component={
          OperandComponent && (
            <OperandComponent
              defaultValue={state.operand}
              onChange={handleOperandChange}
              options={operandOptions}
            />
          )
        }
      />
      {onClose && (
        <Button onClick={onClose} classes={{ root: 'btn-done' }}>
          Done
        </Button>
      )}
    </RadioButtonGroupWrapper>
  );
};

import React from 'react';
import { UIVariable } from '@whispir/variables';
import { Chip } from '../../Atoms/DataDisplay';
import { ColorTypes } from '../../Foundation/Colors/Colors.types';
import { groupIconMap } from '../VariableSelectorPopover/groupIconMap';

type VariableChipProps = {
  color?: Extract<ColorTypes, 'accent' | 'variable' | 'error'>;
  variable: UIVariable;
  labelOverride?: React.ReactNode; // This is for use by draftail
  onClick?: () => void;
  onDeleteClick?: (event?) => void;
};

export const DELETED_VARIABLE_TEXT = 'Variable was deleted';

export const VariableChip = (props: VariableChipProps): JSX.Element => {
  const {
    variable,
    onClick,
    onDeleteClick,
    labelOverride,
    color = 'variable',
  } = props;
  let colorToUse = color;
  const { groupType, groupName, variableName } = variable;

  // temporary way of displaying deleted variable. will implement a better fix
  if (variableName === DELETED_VARIABLE_TEXT) {
    colorToUse = 'error';
  }

  const iconToUse =
    (groupType && groupIconMap[groupType]) || groupIconMap['default'];
  return (
    <Chip
      color={colorToUse}
      text={variableName}
      labelOverride={labelOverride}
      icon={iconToUse}
      tooltip={groupName && `Source: ${groupName}`}
      onClick={onClick}
      onDeleteClick={onDeleteClick}
    ></Chip>
  );
};

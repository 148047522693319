import styled from 'styled-components';
import { Colors } from '../../Foundation/Colors';
import { Fonts } from '../../Foundation/Fonts';

export const StyledDropMarker = styled.div`
  &::after {
    ${Fonts.link};
    color: ${Colors.white};
    text-align: center;
    width: 156px;
    margin: 0 auto;
    background-color: ${Colors.accent_4_5};
    border-radius: 22px;
    padding: 6px 24px;
    content: 'Drop block here';
    position: absolute;
    left: calc(50% - 78px);
    box-sizing: border-box;
    top: -8px;
    font-family: none;
  }
  display: block;
  background-color: ${Colors.accent_2};
  height: 16px;
  position: relative;
  margin: 8px 0;
`;

import React from 'react';

import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import Popper from '@material-ui/core/Popper';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { Icons } from '../../Foundation/Icons';
import {
  DataFilterWrapper,
  CheckboxWrapper,
  Suffix,
} from './DataFilters.styles';
import { DataFiltersPropTypes } from './DataFilters.types';

export const DefaultPopper = (props): JSX.Element => (
  <Popper disablePortal placement='bottom-end' {...props} />
);

export const DataFilters = ({
  disableClearable,
  selectedFilterOptions,
  filterOptions = [
    { channel: 'SMS', dataType: 'Sent' },
    { channel: 'SMS', dataType: 'Received' },
    { channel: 'SMS', dataType: 'Failed' },
  ],
  CustomPopper = DefaultPopper,
  onChange,
  onOptionSelected,
  onChipDeleted,
}: DataFiltersPropTypes): JSX.Element => {
  const handleChange = (
    event: React.ChangeEvent<{}>,
    filterOption: Array<string> | null
  ) => {
    const triggerOnChange = () => onChange && onChange(filterOption);
    triggerOnChange();
  };

  const getClassName = (option: string) =>
    option.toLowerCase().split(' ').join('-');

  return (
    <DataFilterWrapper className='dataFilters'>
      <Autocomplete
        className='autoComplete'
        multiple
        onChange={handleChange}
        disableClearable={disableClearable}
        options={filterOptions.map(
          (option) => `${option.channel} ${option.dataType}`
        )}
        value={selectedFilterOptions}
        popupIcon={
          <Suffix>
            <Icons.Plus />
            <p>Add Metrics</p>
          </Suffix>
        }
        PopperComponent={CustomPopper}
        renderOption={(option, { selected }) => (
          <CheckboxWrapper>
            <Checkbox
              onChange={() => onOptionSelected && onOptionSelected(option)}
              checked={selected}
              size='small'
            />
            {option}
          </CheckboxWrapper>
        )}
        renderTags={(
          value: Array<string>,
          getTagProps: ({ index: number }) => { onDelete?: () => void }
        ) =>
          value.map((option: string, index: number) => (
            <Chip
              {...getTagProps({ index })}
              classes={{ root: `chip-${getClassName(option)}` }}
              key={option}
              size='small'
              label={option}
              onDelete={() => {
                const { onDelete } = getTagProps({ index });

                if (onChipDeleted) {
                  onChipDeleted(option);
                }

                return onDelete && onDelete();
              }}
            />
          ))
        }
        renderInput={(params) => <TextField {...params} variant='standard' />}
      />
    </DataFilterWrapper>
  );
};

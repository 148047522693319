import React from 'react';

import { Button } from '../../Atoms/Navigations';
import { Illustrations } from '../../Foundation/Illustrations';
import { EmptyTemplateStateType } from './EmptyTemplateState.types';
import { EmptyTemplateWrapper } from './EmptyTemplateState.style';

export const EmptyTemplateState = ({
  illustration = <Illustrations.List />,
  title = '',
  description = '',
  startIcon = '',
  endIcon = '',
  buttonText = '',
  buttonVariant,
  onButtonClick,
  className,
}: EmptyTemplateStateType): JSX.Element => {
  const parseDescription = () => {
    return description.split('\n');
  };

  const renderMultiLinePraragraph = (descriptionStringArray: Array<string>) => {
    return descriptionStringArray.map((text: string) => (
      <p className='description' key={text}>
        {text}
      </p>
    ));
  };

  const renderDescription = () => {
    const descriptionStringArray =
      description && description.includes('\n') && parseDescription();
    return descriptionStringArray ? (
      renderMultiLinePraragraph(descriptionStringArray)
    ) : description ? (
      <p className='description'>{description}</p>
    ) : null;
  };

  return (
    <EmptyTemplateWrapper className={className}>
      {illustration}
      <h3 className='title'>{title}</h3>
      {renderDescription()}
      {!!buttonText && (
        <div className='btn-router-link'>
          <Button
            size='large'
            text={buttonText}
            variant={buttonVariant}
            startIcon={startIcon}
            endIcon={endIcon}
            onClick={onButtonClick}
          />
        </div>
      )}
    </EmptyTemplateWrapper>
  );
};

EmptyTemplateState.displayName = 'EmptyTemplateState';

/**
 *
 * Injects properties into model base on defined behaviour
 * - target: the target property names inside model properties
 * - name: The new property name of the injected property
 * - handler: The resulting value to be injected
 * - rules: We can do multiple injections for one or multiple targets
 * - properties: Includes all properties in the active/selected component
 */
export const interactiveMapBlockDerivedProps = [
  {
    targets: ['placeholder', 'requiredField'],
    rules: [
      {
        name: 'hidden',
        handler: (properties) => {
          const { value: strInteractive } = properties.find(
            ({ name }) => name === 'interactive'
          );
          return strInteractive === 'false';
        },
      },
    ],
  },
];

import styled from 'styled-components';
import { Colors } from '../../Foundation/Colors';
import {
  fieldHorizontalSpacing,
  fieldVerticalSpacing,
} from '../../Foundation/GlobalStyles';

export const ComponentBox = styled.div`
  display: flex;
  align-items: center;
  margin: 0;
  min-height: 180px;
  padding: 6px 0 6px;
  background: ${Colors.white};
  position: relative;
`;

export const DefaultOptionsWrapper = styled.div<{
  hasBackgroundColorPicker?: boolean;
  disableDeleteButton?: boolean;
}>`
  align-items: center;
  justify-content: ${(props) =>
    props.hasBackgroundColorPicker ? 'space-between' : 'flex-end'};
  margin: ${(props) =>
    !props.hasBackgroundColorPicker
      ? `0px ${fieldHorizontalSpacing}px`
      : `${fieldVerticalSpacing}px ${fieldHorizontalSpacing}px`};
  display: flex;
  position: relative;
  box-sizing: border-box;

  .options-picker__delete-btn {
    position: relative;
    fill: ${(props) =>
      props.disableDeleteButton ? Colors.grey_blue_2 : Colors.grey_blue_3};
    pointer-events: ${(props) => props.disableDeleteButton && 'none'};

    :hover {
      cursor: pointer;
      fill: ${Colors.accent};
    }
  }
`;

import React from 'react';
import { Tooltip } from '../../Atoms/Dialogs/Tooltip';
import { Divider } from '../../Atoms/Layout/Divider';
import { Colors } from '../../Foundation/Colors';
import { Icons } from '../../Foundation/Icons';

import {
  LegendsWrapper,
  LegendItem,
  ItemDescription,
  StyledIcon,
} from './CustomLegends.styles';
import { LegendsPropTypes } from './CustomLegends.types';

const { LogoSmall, Tick, Rss, Person, Close, Iux } = Icons;

const deliveryStatusColor = (item) => {
  const [, status] = item.split(' ');
  if (status === 'Failed') {
    return `${Colors.pink}`;
  }
  if (status === 'Sent') {
    return 'transparent';
  }
  return `${Colors.accent}`;
};

const deliveryStatusIcon = (item) => {
  const [, status] = item.split(' ');
  if (status === 'Received') {
    return <Tick />;
  }
  if (status === 'Failed') {
    return <Close />;
  }
  return undefined;
};

const getFloatPercentage = (percentage: number): string => {
  let result;

  if (percentage >= 0.1) {
    result = Number.isInteger(percentage)
      ? percentage
      : percentage.toFixed(1).split('.')[1] === '0'
      ? percentage.toFixed(1).split('.')[0]
      : percentage.toFixed(1);
  } else if (percentage < 0.1 && percentage > 0) {
    result = '< 0.1';
  } else {
    result = '0';
  }

  return result;
};

const roundedPercentage = (
  item: string,
  dataGroup: { [key: string]: number },
  total?: number
) => {
  // Create a sortable array based on the dataGroup object provided
  const sortableDataset = [] as Array<[string, number]>;
  Object.keys(dataGroup).forEach((key) =>
    sortableDataset.push([key, dataGroup[key]])
  );

  // Get a sum of the total values to create the individual percentages from if a total is not provided
  const sumValues = sortableDataset.reduce((a, b) => a + b[1], 0);

  // Create percentages
  const percentages = sortableDataset.map((value): [string, number] => [
    value[0],
    (value[1] / (total || sumValues)) * 100,
  ]);

  const itemPercentages = [] as Array<string>;
  percentages.forEach((v) => {
    if (v[0] === item) {
      itemPercentages.push(getFloatPercentage(v[1]));
    }
  });
  // Return item percentage if it exists or return 0
  return itemPercentages[0] || 0;
};

export const CustomLegends = ({
  position = 'bottom',
  legendItems,
  data,
  total,
  iconSize = '8px',
  tooltipHover = true,
}: LegendsPropTypes): JSX.Element => (
  <LegendsWrapper className='custom-legends' position={position}>
    {data &&
      data.map((dataGroup) => {
        delete dataGroup.type;
        return Object.keys(dataGroup).map((item, index) => (
          <LegendItem
            key={item}
            position={position}
            className='custom-legends-item'
          >
            {position === 'bottom' && (
              <Tooltip
                ariaLabel={`barchart legend ${item}`}
                placement='bottom'
                arrow
                disablePortal
                disableHoverListener={!tooltipHover}
                title={
                  <div className='tooltip-content'>
                    <div className='tooltip-title'>{item}</div>
                    <div className='tooltip-body'>
                      {legendItems[item] && legendItems[item].description}
                    </div>
                    <div className='tooltip-icons-group'>
                      <LogoSmall />
                      <Divider
                        dividerType='solid'
                        lineColor={Colors.accent}
                        isHorizontal
                      />
                      <Tick />
                      <Rss />
                      <Divider
                        dividerType='solid'
                        lineColor={deliveryStatusColor(item)}
                        isHorizontal
                      />
                      {deliveryStatusIcon(item)}
                      <Person />
                    </div>
                  </div>
                }
              >
                <StyledIcon
                  size={iconSize}
                  color={legendItems[item] && legendItems[item].color}
                >
                  <Iux />
                </StyledIcon>
              </Tooltip>
            )}
            <ItemDescription
              position={position}
              className='custom-legends-description'
            >
              <span className='description-title'>
                {position === 'bottom' ? `${index + 1}. ${item}` : item}
              </span>
              <span className='description-total'>{`${roundedPercentage(
                item,
                dataGroup,
                total
              )}%`}</span>
            </ItemDescription>
          </LegendItem>
        ));
      })}
  </LegendsWrapper>
);

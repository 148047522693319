import React from 'react';
import { Radio } from '../../Atoms/Forms/Radio';
import { Icons } from '../../Foundation/Icons';
import { ActionCardType, HeaderIconType } from './ActionCard.types';
import {
  StyledActionCard,
  StyledIcon,
  StyledHeading,
  StyledDescription,
} from './ActionCard.style';

const HeaderIcon = ({ icon }: HeaderIconType): JSX.Element | null => {
  if (!icon) {
    return null;
  }

  const Icon = Icons[icon];

  return <Icon />;
};

export const ActionCard = ({
  icon,
  heading,
  description,
  tooltip,
  onClick,
  isDisabled,
  variant = 'button',
  isChecked,
}: ActionCardType): JSX.Element => {
  const isRadio = variant === 'radio';

  return (
    <StyledActionCard
      type='button'
      disabled={isDisabled}
      title={tooltip}
      onClick={onClick}
    >
      <StyledIcon>
        <HeaderIcon icon={icon} />
      </StyledIcon>
      <div>
        <StyledHeading>{heading}</StyledHeading>
        <StyledDescription>{description}</StyledDescription>
      </div>
      {isRadio && (
        <Radio checked={isChecked} disabled={isDisabled} disableRipple />
      )}
    </StyledActionCard>
  );
};

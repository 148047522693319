import styled from 'styled-components';
import { Colors } from '../../Foundation/Colors';

import { DraftailPreviewWrapperTypes } from './DraftailPreview.types';

export const DraftailPreviewWrapper = styled.div<DraftailPreviewWrapperTypes>`
  width: 100%;

  p {
    margin: 0;
    word-break: break-word;
  }

  span {
    padding: 1px 8px 2px;
    font-size: 12px;
    border-radius: 12px;
    background-color: ${Colors.variable_light};
    color: ${Colors.voice};
    font-weight: 500;
    word-break: break-word;
  }

  .isError {
    background-color: ${Colors.error_light};
    color: ${Colors.error};
  }
`;

import React, { useEffect, useRef, useState } from 'react';
import { emptyArray } from '../../Utils/commonUtils';
import { Chip } from '../../Atoms/DataDisplay';
import { Icon } from '../../Atoms/Icon';
import { Button } from '../../Atoms/Navigations';

import { ConditionPicker } from '../ConditionPicker';

import { StyledPopover, StyledWrapper } from './FilterPicker.styles';
import { FilterPickerProps } from './FilterPicker.types';

const ChipLabel = ({ label, condition, isValid }) => {
  let conditionValue = '';

  if (condition) {
    const { operand, operator } = condition;
    let operandValue = '';

    if (isValid) {
      if (operand && operand.value) {
        operandValue =
          operand.valueType === 'date'
            ? new Date(operand.value).toLocaleDateString()
            : operand.value;
      }
      conditionValue = `${operator} ${operandValue}`;
    } else {
      conditionValue = 'missing value';
    }
  }

  return (
    <>
      <strong>{label}</strong>&nbsp;
      {conditionValue}
    </>
  );
};

export const FilterPicker = ({
  availableFilters,
  onChange,
  selectedFilters: defaultFilters = emptyArray(),
}: FilterPickerProps): JSX.Element => {
  const addFilterButtonRef = useRef(null);
  // Just when adding new filter
  const [isAddingFilter, setIsAddingFilter] = useState(false);
  // This value is not null when ConditionPicker is open
  const [openFilterIndex, setOpenFilterIndex] = useState<number | null>(null);
  // We maintain a reference to each of the Chips in order to position the ConditionPicker Popover
  const [chipAnchorEls, setChipAnchorEls] = useState<Array<HTMLElement | null>>(
    []
  );
  const [isValidFilter, setIsValidFilter] = useState<Array<boolean>>([]);
  const [selectedFilters, setSelectedFilters] = useState(defaultFilters);

  // NOTE: FilterPicker needs to get the updated state from parent to display updated UI
  useEffect(() => {
    setSelectedFilters(defaultFilters);
  }, [defaultFilters]);

  const handleValidate = () => {
    setIsValidFilter(
      selectedFilters.map((filter) => {
        const { label, condition } = filter;

        return !!(
          label &&
          condition &&
          condition.operator &&
          condition.operand &&
          condition.operand.value
        );
      })
    );
  };

  useEffect(() => {
    handleValidate();
    // Set initial valid state for each filter on component mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDeleteFilter = (index: number) => {
    const newFilters = [...selectedFilters];

    newFilters.splice(index, 1);
    setSelectedFilters(newFilters);
    onChange(newFilters);
    setIsValidFilter((prev) => {
      const newValues = [...prev];

      newValues.splice(index, 1);
      return newValues;
    });
  };

  const handleChangeLabel = (filter) => {
    setSelectedFilters([...selectedFilters, filter]);
  };

  const handleConditionChange = (condition) => {
    if (openFilterIndex !== null) {
      const newFilters = [...selectedFilters];
      newFilters[openFilterIndex] = {
        ...newFilters[openFilterIndex],
        condition,
      };

      setSelectedFilters(newFilters);
    }
  };

  const handleAddFilter = (event) => {
    addFilterButtonRef.current = event.target;
    setIsAddingFilter(true);
  };

  const handleClickAvailableFilter = (filter) => {
    handleChangeLabel(filter);
    setOpenFilterIndex(selectedFilters.length);
    setIsAddingFilter(false);
  };

  const handleCloseConditionPicker = () => {
    setOpenFilterIndex(null);
    handleValidate();
    onChange(selectedFilters);
  };

  return (
    <StyledWrapper className='filter-picker'>
      {selectedFilters.map((filter, index) => {
        const isValidCondition =
          typeof isValidFilter[index] === 'undefined' || isValidFilter[index];

        return (
          <Chip
            // eslint-disable-next-line react/no-array-index-key
            key={`${filter.label}-${index}`}
            className={isValidCondition ? '' : 'invalid-filter'}
            text={filter.label}
            maxWidth={260}
            onDeleteClick={() => handleDeleteFilter(index)}
            icon={filter.icon}
            labelOverride={
              <ChipLabel
                label={filter.label}
                condition={filter.condition}
                isValid={isValidCondition}
              />
            }
            ref={(element: HTMLElement | null) => {
              // Set ref 1 time
              // This function runs when the component mounts
              if (element && chipAnchorEls[index] !== element) {
                setChipAnchorEls((prev) => {
                  const newElements = [...prev];
                  newElements[index] = element;

                  return newElements;
                });
              }
            }}
            onClick={() => setOpenFilterIndex(index)}
          />
        );
      })}

      {/* ADD FILTER */}
      {availableFilters.some(
        (availableFilter) => !availableFilter.inactive
      ) && (
        <Button
          className='add-filter'
          variant='link'
          startIcon='Plus'
          text='Add Filter'
          size='medium'
          onClick={handleAddFilter}
          data-testid='add-filter'
        />
      )}
      {/* This popover is used for selecting from the list of available filters */}
      <StyledPopover
        open={isAddingFilter}
        anchorEl={addFilterButtonRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={() => setIsAddingFilter(false)}
      >
        {availableFilters
          .filter((filter) => !filter.inactive)
          .map((availableFilter) => {
            const { icon, label } = availableFilter;

            return (
              <div
                className='filter-picker-items'
                key={label}
                onClick={() => handleClickAvailableFilter(availableFilter)}
              >
                {icon && <Icon icon={icon} />}
                <span className={icon ? '' : 'no-icon'}>{label}</span>
              </div>
            );
          })}
      </StyledPopover>

      {/* CONDITION PICKER This popover is used for selecting from the ConditionPicker */}
      <StyledPopover
        open={
          openFilterIndex !== null && Boolean(chipAnchorEls[openFilterIndex])
        }
        anchorEl={
          openFilterIndex !== null && chipAnchorEls[openFilterIndex] !== null
            ? chipAnchorEls[openFilterIndex]
            : null
        }
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handleCloseConditionPicker}
      >
        <ConditionPicker
          onClose={handleCloseConditionPicker}
          onChange={(condition) => handleConditionChange(condition)}
          defaultValues={
            openFilterIndex !== null && selectedFilters[openFilterIndex]
              ? selectedFilters[openFilterIndex].condition
              : undefined
          }
          formControlType={
            openFilterIndex !== null && selectedFilters[openFilterIndex]
              ? selectedFilters[openFilterIndex].dataType
              : ''
          }
        />
      </StyledPopover>
    </StyledWrapper>
  );
};

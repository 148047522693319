import styled from 'styled-components';
import { RatingWrapperProps } from '../../../Atoms/Rating';

import { commonStyles } from '../../../Atoms/Rating/Rating.styles';
import { Colors } from '../../../Foundation/Colors';

const inactiveStyle = (iconColor, highlightColor) => `
  svg{
    fill: ${iconColor};
    color: ${Colors.white};
    path {
      stroke: ${iconColor};
    }
    circle {
      stroke: ${iconColor};
    }
  }
`;

const activeStyle = (iconColor, highlightColor) => `
  svg{
    fill: ${Colors.white};
    color: ${highlightColor};
    path {
      stroke: ${Colors.white};
    }
    circle {
      stroke: ${highlightColor};
    }
  }
`;

export const RatingWrapper = styled.div<RatingWrapperProps>`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .MuiRating-icon {
    ${({ iconColor, highlightColor }) =>
      inactiveStyle(iconColor, highlightColor)}
  }

  ${({ iconSize }) => commonStyles(iconSize)}

  .MuiRating-iconHover.MuiRating-iconActive {
    ${({ iconColor, highlightColor }) => activeStyle(iconColor, highlightColor)}
  }

  .selected .MuiRating-iconFilled {
    ${({ iconColor, highlightColor }) => activeStyle(iconColor, highlightColor)}
  }
`;

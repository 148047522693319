import { Colors } from '../../../Foundation/Colors';

// why isn't this component in ../DragAndDropComponents ?
export const ratingBlockModel = (id: string) => ({
  id,
  type: 'Rating',
  label: 'Rating',
  properties: [
    {
      name: 'text',
      label: 'Styles',
      control: 'TitleDivider',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'header',
      label: 'Text',
      control: 'TextFieldFilled',
      value: 'How would you rate your experience today?',
      __typename: 'ComponentProperty',
    },
    {
      name: 'helperText',
      label: 'Helper text',
      control: 'TextFieldFilled',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'requiredField',
      label: 'Required Field',
      control: 'Checkbox',
      value: 'true',
      __typename: 'ComponentProperty',
    },
    {
      name: 'ratingType',
      label: 'Icon Style__Stars,Emoji Faces',
      control: 'Dropdown',
      value: 'Stars',
      __typename: 'ComponentProperty',
    },
    {
      name: 'padding_8px_3',
      label: '',
      control: 'PaddingControl8px',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'iconSize',
      label: 'Icon Size',
      control: 'Slider',
      value: '20',
      __typename: 'ComponentProperty',
    },
    {
      name: 'iconColor',
      label: 'Icon Color',
      control: 'ColorPicker',
      value: Colors.accent_3,
      __typename: 'ComponentProperty',
    },
    {
      name: 'highlightColor',
      label: 'Highlight Color',
      control: 'ColorPicker',
      value: Colors.accent_dnd,
      __typename: 'ComponentProperty',
    },
    {
      name: 'padding_8px_4',
      label: '',
      control: 'PaddingControl8px',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'labels',
      label: 'Labels',
      control: 'TitleDividerNoLine',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'label1',
      label: 'Label 1',
      control: 'TextFieldFilled',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'label2',
      label: 'Label 2',
      control: 'TextFieldFilled',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'label3',
      label: 'Label 3',
      control: 'TextFieldFilled',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'label4',
      label: 'Label 4',
      control: 'TextFieldFilled',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'label5',
      label: 'Label 5',
      control: 'TextFieldFilled',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'padding_8px_5',
      label: '',
      control: 'PaddingControl8px',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'padding',
      label: 'Padding',
      control: 'TitleDividerNoLine',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'paddingTop',
      label: 'Top',
      control: 'Slider',
      value: '20',
      __typename: 'ComponentProperty',
    },
    {
      name: 'paddingBottom',
      label: 'Bottom',
      control: 'Slider',
      value: '20',
      __typename: 'ComponentProperty',
    },
    {
      name: 'paddingLeft',
      label: 'Left',
      control: 'Slider',
      value: '20',
      __typename: 'ComponentProperty',
    },
    {
      name: 'paddingRight',
      label: 'Right',
      control: 'Slider',
      value: '20',
      __typename: 'ComponentProperty',
    },
  ],
  __typename: 'Component',
});

import { isToggleEnabled } from '@whispir/feature-toggles';
import { subNavMenuItems, EXTERNAL_ROUTES } from '../config/constants';
import {
  logout,
  pagesPathname,
  getStripeCustomer,
  isFeatureShown,
  setSubnavGroupItemsCount,
} from '..';

export const getGlobalHeaderLink = `${EXTERNAL_ROUTES.LOGIN}dashboard`;

export const getGlobalNavigationItems = () => {
  const {
    store,
    workflows,
    messages,
    contacts,
    insights,
    templates,
  } = pagesPathname;

  const storeNavigationItem = {
    iconName: 'Store',
    title: 'Whispir Store',
    pathname: store,
  };

  const insightNavigationItem = {
    iconName: 'Assessment',
    title: 'Insights',
    pathname: insights,
  };

  const templatesNavigationItem = {
    iconName: 'InkPen',
    title: 'Templates',
    pathname: templates,
  };

  const navigationItems = [
    {
      iconName: 'Contacts',
      title: 'Contacts',
      pathname: contacts,
    },
    {
      iconName: 'Messages',
      title: 'Messages',
      pathname: messages,
    },
    ...(isToggleEnabled('template-studio') ? [templatesNavigationItem] : []),
    {
      iconName: 'Workflow',
      title: 'Workflows',
      pathname: workflows,
    },
    ...(isToggleEnabled('insights') ? [insightNavigationItem] : []),
    ...(isToggleEnabled('whispir-store') ? [storeNavigationItem] : []),
  ];

  return navigationItems;
};

export const getGlobalSecondaryNavigationItems = (
  graphQLAPI,
  productTier,
  stripe,
  companyRole
) => {
  const { brand, settings, activate, apiKeys } = pagesPathname;
  const { location } = window;
  const isAdmin = companyRole === 'Company Administrator';

  const secondaryNavigationItems = [
    {
      iconName: 'AccountCircle',
      title: 'Your Account',
      isActive: location.pathname === settings,
      action: () => location.assign(settings),
    },
    {
      iconName: 'Exit',
      title: 'Logout',
      // TODO: defined first args (client) so the Apollo can get rid its cache efficiently when the login/logout state changes.
      // https://www.apollographql.com/docs/react/networking/authentication/#reset-store-on-logout
      action: logout(null, graphQLAPI),
    },
  ];

  const brandSettings = {
    iconName: 'Cog',
    title: 'Brand Settings',
    isActive: location.pathname === brand,
    action: () => location.assign(brand),
  };

  isToggleEnabled('tone-of-voice') &&
    secondaryNavigationItems.splice(1, 0, brandSettings);

  if (
    productTier &&
    productTier.includes('DigitalDirect') &&
    isToggleEnabled('stripe-portal') &&
    isAdmin &&
    stripe &&
    stripe.customer
  ) {
    const { client, setLoading } = stripe;
    const beforeLogoutIndex = secondaryNavigationItems.length - 1;
    const stripeBilling = {
      iconName: 'Billing',
      title: 'Billing',
      isActive: false,
      action: () => getStripeCustomer(client, setLoading),
    };

    secondaryNavigationItems.splice(beforeLogoutIndex, 0, stripeBilling);
  }

  if (isFeatureShown('api-keys', productTier)) {
    secondaryNavigationItems.splice(1, 0, {
      iconName: 'ApiKeys',
      title: 'API Keys',
      isActive: location.pathname === apiKeys,
      action: () => location.assign(apiKeys),
    });
  }

  if (productTier === 'Trial') {
    secondaryNavigationItems.unshift({
      iconName: 'Upgrade',
      title: 'Activate',
      isActive: location.pathname === activate,
      action: () => location.assign(activate),
    });
  }

  return secondaryNavigationItems;
};

/**
 * @name getSubNavMenuItems
 * @param primaryNavItem => name of the page
 * @param primaryNavItemsCounts => appends `count` prop for each sub nav menu group items
 *    - @format : {
 *        pathname = pathname of each item in the groupitems,
 *        count = count value for the sub menu item
 *      }
 */
export const getSubNavMenuItems = (primaryNavItem, primaryNavItemsCounts) => {
  const updatedSubNavMenuItems = subNavMenuItems;
  const updatedMessageGroupItems = [
    {
      title: 'Sent',
      pathname: '/create/sent-messages',
    },
    {
      title: 'Archived',
      pathname: '/create/archived-messages',
    },
  ];

  if (!isToggleEnabled('insights-forms')) {
    const {
      Assessment: [{ groupItems }],
    } = subNavMenuItems;

    const updatedAssesmentGroupItems = groupItems.filter(
      (item) => item.title !== 'Forms'
    );

    updatedSubNavMenuItems.Assessment[0].groupItems = updatedAssesmentGroupItems;
  }

  if (isToggleEnabled('draft-messages')) {
    updatedMessageGroupItems.unshift({
      title: 'Drafts',
      pathname: '/create/draft-messages',
    });
  }

  if (isToggleEnabled('all-messages')) {
    updatedMessageGroupItems.unshift({
      title: 'All Messages',
      pathname: '/create/all-messages',
    });
  }

  if (!isToggleEnabled('whatsapp')) {
    subNavMenuItems.Messages = subNavMenuItems.Messages.filter(
      (nav) => nav.link !== '/create/whatsapp/templates'
    );
  }

  // per design, New Message must be at the top of the list
  updatedMessageGroupItems.unshift({
    title: 'New Message',
    pathname: '/create/channels',
  });

  // Seems like Sent item is no longer under a toggle as of 31st March 2021
  // There is additional Archived item not under a toggle as of 31st March 2021
  // Please do update the unit test when removing/adding a toggle in this function
  updatedSubNavMenuItems.Messages[0].groupItems = updatedMessageGroupItems;

  // append `count` prop for each sub nav menu group items
  if (primaryNavItemsCounts) {
    updatedSubNavMenuItems[primaryNavItem].forEach(({ groupItems }) =>
      setSubnavGroupItemsCount(groupItems, primaryNavItemsCounts)
    );
  }

  return updatedSubNavMenuItems[primaryNavItem];
};

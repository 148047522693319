import styled from 'styled-components';

import { RichTextImageBlockWrapperProps } from './RichTextImageBlock.types';

export const ImageWrapper = styled.div<RichTextImageBlockWrapperProps>`
  text-align: center;
  display: block;
  width: 100%;
  padding-top: ${(props) => props.paddingTop}px;
  padding-bottom: ${(props) => props.paddingBottom}px;

  img {
    max-width: 100%;
  }
`;

export const ImgLink = styled.a`
  display: inline-block;
  vertical-align: top;
`;

import React from 'react';

import { Divider } from './RichTextDividerBlock.style';
import { RichTextDividerBlockProps } from './RichTextDividerBlock.types';

export const RichTextDividerBlock = (
  props: RichTextDividerBlockProps
): JSX.Element => (
  <Divider {...props}>
    <hr />
  </Divider>
);

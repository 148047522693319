import styled from 'styled-components';
import { StyledContentPreviewProps } from './ComponentPreview.types';

export const StyledComponentPreview = styled.div<StyledContentPreviewProps>`
  width: ${({ width }) => (width ? `${width}px` : 'unset')};
  height: ${({ height }) => (height ? `${height}px` : 'unset')};
  overflow: hidden;

  img {
    width: 100%;
    object-fit: contain;
  }
`;

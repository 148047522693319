import React, { useEffect, useState } from 'react';

import { ListItem, ListItemText } from '@material-ui/core';
import { SearchField } from '../../Atoms/Forms/SearchField/SearchField';
import { ListSelectType } from './ListSelect.types';
import { StyledListSelect } from './ListSelect.styles';

export const ListSelect = ({
  dataList,
  onSelect,
  hasSearch,
}: ListSelectType): JSX.Element => {
  const [list, setDataList] = useState(dataList);

  useEffect(() => {
    setDataList(dataList);
  }, [dataList]);

  const renderList = (): JSX.Element => (
    <StyledListSelect>
      {list.map(
        (item): JSX.Element => (
          <ListItem button key={item.id} onClick={() => onSelect(item)}>
            <ListItemText primary={item.name} />
          </ListItem>
        )
      )}
      {list.length === 0 ? (
        <ListItem>
          <ListItemText primary='No Results' />
        </ListItem>
      ) : null}
    </StyledListSelect>
  );

  const searchThroughList = (val: string) => {
    const newList = dataList.filter((item) =>
      item.name.toLowerCase().includes(String(val).toLowerCase())
    );

    setDataList(newList);
  };

  return (
    <div className='list'>
      {hasSearch ? (
        <SearchField onChange={(newVal) => searchThroughList(newVal)} hasIcon />
      ) : null}
      {renderList()}
    </div>
  );
};

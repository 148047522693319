import styled from 'styled-components';
import { FlatPanel } from '../../Atoms/Layout';
import { Colors } from '../../Foundation/Colors';
import { Fonts } from '../../Foundation/Fonts';

export const StyledDurationSelector = styled(FlatPanel)`
  .error-message {
    color: ${Colors.error};
    ${Fonts.caption};
  }

  .form-control {
    width: 80px;
    margin: 0 16px 0 0;

    &:nth-child(3) {
      margin-right: 0;
    }

    label.Mui-focused {
      color: ${Colors.accent};
    }

    .MuiInput-underline {
      :after {
        border-bottom: 2px solid ${Colors.accent};
      }
    }
  }
`;

export const StyledErrorMessage = styled.p`
  margin: 4px 0;
`;

import React, { useContext, useReducer } from 'react';
import { SeverityTypes } from '../../Molecules/Snackbar/Snackbar.types';
import { Notification } from './Notification';

export const NOTIFICATION_ACTION_TYPES = {
  CREATE_NOTIFICATION: 'create-notification',
  DELETE_NOTIFICATION: 'delete-notification',
};
const { CREATE_NOTIFICATION, DELETE_NOTIFICATION } = NOTIFICATION_ACTION_TYPES;

type Notification = {
  id: number;
  message: string;
  type: SeverityTypes;
  autoHideDuration?: number;
  onClose?: (event: unknown, reason: string) => void;
};
type Action = {
  type:
    | typeof NOTIFICATION_ACTION_TYPES.CREATE_NOTIFICATION
    | typeof NOTIFICATION_ACTION_TYPES.DELETE_NOTIFICATION;
  payload?: Notification;
};
type Dispatch = (action: Action) => void;
type State = { notifications: Array<Notification> };
type Props = { children: React.ReactNode };

const NotificationStateContext = React.createContext<State | undefined>(
  undefined
);
const NotificationDispatchContext = React.createContext<
  | {
      createNotification: (payload: Notification) => void;
      deleteNotification: () => void;
    }
  | undefined
>(undefined);

const notificationReducer = (state: State, action: Action) => {
  switch (action.type) {
    case CREATE_NOTIFICATION:
      // TODO: support multiple notifications
      return { notifications: [action.payload] };
    case DELETE_NOTIFICATION:
      return { notifications: [] };
    default:
      return state;
    // throw new Error(`Unhandled action type: ${action.type}`);
  }
};

export const NotificationsProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(notificationReducer, {
    notifications: [],
  });

  const createNotification = (payload: Notification) => {
    dispatch({ type: CREATE_NOTIFICATION, payload });
  };
  const deleteNotification = () => {
    dispatch({ type: DELETE_NOTIFICATION });
  };

  return (
    <NotificationStateContext.Provider value={state}>
      <NotificationDispatchContext.Provider
        value={{ createNotification, deleteNotification }}
      >
        <Notification />
        {children}
      </NotificationDispatchContext.Provider>
    </NotificationStateContext.Provider>
  );
};

export const useNotificationState = () => {
  const context = useContext(NotificationStateContext);
  if (context === undefined) {
    throw new Error(
      'useNotificationState must be used within a NotificationProvider'
    );
  }
  return context;
};

export const useNotificationDispatch = () => {
  const context = useContext(NotificationDispatchContext);
  if (context === undefined) {
    throw new Error(
      'useNotificationState must be used within a NotificationProvider'
    );
  }
  return context;
};

export const withNotificationsContext = (Component) => {
  const contextComponent = (props) => (
    <NotificationStateContext.Consumer>
      {(stateContext) => (
        <NotificationDispatchContext.Consumer>
          {(dispatchContext) => (
            <Component
              {...props}
              notificationsCtx={{
                state: stateContext,
                dispatch: dispatchContext,
              }}
            />
          )}
        </NotificationDispatchContext.Consumer>
      )}
    </NotificationStateContext.Consumer>
  );
  return contextComponent;
};

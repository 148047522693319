import React from 'react';

import { CardMedia } from '@material-ui/core';
import { DraftailPreview } from '../../DraftailComponents/DraftailPreview';
import {
  CardMediaWrapper,
  Header,
  MediaWrapper,
  DummyCardMedia,
  UploadPlaceholder,
} from './VideoPlayer.styles';
import { VideoPlayerProps } from './VideoPlayer.types';

const getExtension = (str) => str.slice(str.lastIndexOf('.') + 1);

const isValidUrl = (url) => {
  const validExtensions = ['mp4', 'webm'];
  return validExtensions.includes(getExtension(url));
};

export const getVideoEmbedUrl = (url: string) => {
  const youtube = /(?:https?:\/\/)(?:www\.)?(?:youtu\.be\/|youtube\.com(?:\/embed\/|\/v\/|\/watch\?v=))([A-Za-z0-9-_]{10,12})/.exec(
    url
  );
  let vimeo;
  let dailymotion;
  let wistia;
  let resultVideoUrl = isValidUrl(url) ? url : '';
  if (url.indexOf('vimeo.com') > -1) {
    vimeo = url.match(/\.com(\/video|)\/(\d+)/);
  }
  if (url.indexOf('dai.ly') > -1 || url.indexOf('dailymotion.com') > -1) {
    dailymotion = url.match(/video\/([^/\b?&]+)/);
  }
  if (url.indexOf('wistia.com') > -1) {
    wistia = url.match(/wvideo[id]*=([^&]+)/);
  }

  if (youtube != null) {
    resultVideoUrl = `https://www.youtube.com/embed/${youtube[1]}?&modestbranding=true&showinfo=0&rel=0&wmode=transparent`;
  }
  if (vimeo != null) {
    resultVideoUrl = `https://player.vimeo.com/video/${vimeo[2]}?transparent=0`;
  }
  if (dailymotion != null) {
    resultVideoUrl = `//www.dailymotion.com/embed/video/${dailymotion[1]}`;
  }
  if (wistia != null) {
    resultVideoUrl = `https://fast.wistia.net/embed/iframe/${wistia[1]}`;
  }

  return resultVideoUrl;
};

export const VideoPlayer = (props: VideoPlayerProps): JSX.Element => {
  const {
    url = '',
    header = '',
    paddingTop,
    paddingLeft,
    paddingRight,
    paddingBottom,
    uploadPlaceholder = false,
  } = props;

  return (
    <MediaWrapper
      paddingTop={paddingTop}
      paddingLeft={paddingLeft}
      paddingRight={paddingRight}
      paddingBottom={paddingBottom}
    >
      {header && (
        <Header>
          <DraftailPreview htmlContentString={header} />
        </Header>
      )}
      <CardMediaWrapper>
        {url && (
          <CardMedia
            component='iframe'
            title='test'
            src={getVideoEmbedUrl(url)}
          />
        )}
        {!url && <DummyCardMedia />}
        {uploadPlaceholder && <UploadPlaceholder />}
      </CardMediaWrapper>
    </MediaWrapper>
  );
};

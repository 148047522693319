import React from 'react';
import { ToneOfVoiceCard } from '../../../Molecules/ToneOfVoiceCard';
import { toneOfVoiceCards } from '../../../Utils/exampleData';
import {
  StyledToneOfVoiceCardList,
  StyledListItem,
} from './ToneOfVoiceCardList.style';

type Props = {
  cards: Array<{
    icon: string;
    title: string;
    description?: string;
  }>;
  toneValues?: Record<string, unknown>;
  onToneChoice: (type: string, value: string) => void;
};

export const ToneOfVoiceCardList = ({
  onToneChoice,
  toneValues, // initial value comes from company TOV settings, unless set in draft/messsage
  cards = toneOfVoiceCards,
}: Props): JSX.Element => {
  // roll the tone saved value from the users company settings into the card object
  const cardsWithSettingsValue = cards.map((item) => ({
    ...item,
    toneValue: toneValues && toneValues[item.title],
  }));

  const handelOnToneChoice = (type: string, value: string) => {
    return onToneChoice(type, value);
  };

  return (
    <StyledToneOfVoiceCardList>
      {cardsWithSettingsValue.map(({ icon, title, description, toneValue }) => (
        <StyledListItem key={title}>
          <ToneOfVoiceCard
            icon={icon}
            title={title}
            description={description}
            toneValue={toneValue || ''}
            onToneChoice={handelOnToneChoice}
          />
        </StyledListItem>
      ))}
    </StyledToneOfVoiceCardList>
  );
};

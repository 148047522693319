import styled from 'styled-components';
import { Colors } from '../../Foundation/Colors';
import { Elevations } from '../../Foundation/Elevations';
import { Fonts } from '../../Foundation/Fonts';

import { ContentContainerTypes } from './ContentContainer.types';

export const OuterWrapper = styled.div`
  width: auto;
  height: 100%;
  padding: 34px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  ${Fonts.body}
  background: ${({ hasNoBackground }: ContentContainerTypes) =>
    hasNoBackground ? 'transparent' : Colors.background};
`;

export const InnerWrapper = styled.div`
  background-color: ${Colors.white};
  box-shadow: ${({ hasNoBackground }: ContentContainerTypes) =>
    hasNoBackground ? 'none' : Elevations.elevation_1};
  max-width: 600px;
  min-width: 280px;
  border-radius: 8px;
  padding: ${({ hasNoBackground }: ContentContainerTypes) =>
    hasNoBackground ? '0' : '32px'};
`;

import styled from 'styled-components';
import { Colors } from '../../Foundation/Colors';
import { Fonts } from '../../Foundation/Fonts';

export const SMSMobilePreviewWrapper = styled.div`
  .sms-preview {
    padding: 24px;
    width: 400px;
    box-sizing: border-box;
    z-index: 2;
    height: calc(100vh - 200px);
    overflow-y: auto;
    border-left: 1px solid ${Colors.background};
    top: 140px;
    position: fixed;
    right: 0;
    background-color: ${Colors.white};

    .message-counter {
      padding-bottom: 16px;

      > div {
        justify-content: flex-start;
        padding: 0;
        margin: 0;
      }
    }

    .header {
      padding: 16px 0;
      ${Fonts.capsHeading};
    }
  }
`;

import React from 'react';

import styled from 'styled-components';

import { FlatPanel } from '../../Atoms/Layout/FlatPanel';
import { Colors } from '../../Foundation/Colors';
import { Fonts } from '../../Foundation/Fonts';

// circular dependencies issue: https://github.com/styled-components/styled-components/issues/1449
export const StyledFlatPanel = styled((props) => <FlatPanel {...props} />)`
  margin-top: 24px;
  padding: 16px 16px 24.5px;
  background-color: ${Colors.grey_blue_0};

  .duration-selector-label {
    margin-bottom: 5px;
    ${Fonts.body};
  }
`;

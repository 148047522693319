import React from 'react';

import styled from 'styled-components';
import { MenuList, MenuItem } from '@material-ui/core';
import { MenuListComponentProps } from 'react-select';
import CreatableSelect from 'react-select/creatable';

import { Variable } from '@whispir/variables';
import { createStyledReactSelect } from '../../Atoms/Forms/StyledReactSelect/StyledReactSelect';

import { Colors } from '../../Foundation/Colors';
import { Fonts } from '../../Foundation/Fonts';
import { AdhocVariable } from './AdhocVariableSelector.types';

export const StyledMenuList = styled(
  (
    props: MenuListComponentProps<
      AdhocVariable | { label: string; value: string; data: Variable }
    >
  ) => {
    const { children, ...rest } = props;
    return (
      <MenuList {...rest}>
        <div className='dropdown-items'>{children}</div>
      </MenuList>
    );
  }
)`
  &.MuiList-root {
    max-height: 300px;
    overflow-y: auto;
  }

  .styled-react-select__group-heading {
    padding-left: 0;
  }

  .menu-label {
    ${Fonts.capsHeading};
    color: ${Colors.grey_blue_4};
    margin: 8px 16px 16px;
  }

  @media (min-width: 600px) {
    li {
      margin: 2px 0;
      :last-child {
        margin: 2px 0 6px;
      }
    }
  }
`;

export const StyledMenuItem = styled((props) => <MenuItem {...props} />)`
  &:hover,
  &:focus {
    background-color: ${Colors.grey_blue_1};
    cursor: pointer;
  }

  &.MuiListItem-root {
    margin: 0px;
    padding: 8px;
  }

  &.MuiMenuItem-root.Mui-disabled {
    opacity: 1;
  }
  .create-label {
    ${Fonts.body};
    color: ${Colors.grey_blue_4};
  }
  .validation-indicator {
    margin-left: auto;
    svg {
      padding-top: 2px;
      width: 18px;
    }
  }
`;

export const StyledCreatableSelect = createStyledReactSelect(CreatableSelect);

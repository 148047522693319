import React from 'react';

import { ResponsiveLine } from '@nivo/line';
import { exampleLineChartData } from '../../../Utils/exampleData';

import { Colors } from '../../../Foundation/Colors';
import { LineChartPropTypes } from './LineChart.types';
import { StyledLineChart } from './LineChart.styles';

export const LineChart = ({
  width = '100%',
  height = '400px',
  backgroundColor = Colors.white,
  data = exampleLineChartData,
  margin = { top: 0, right: 0, bottom: 0, left: 0 },
  xScale = { type: 'point' },
  yScale = {
    type: 'linear',
    min: 0,
    max: 'auto',
    stacked: true,
    reverse: false,
  },
  axisBottom = {
    orient: 'bottom',
    tickSize: 0,
    tickPadding: 20,
    tickRotation: 0,
    tickValues: ['12pm', '2pm', '4pm', '6pm', '8pm', '10pm', '12am'],
  },
  axisLeft = {
    orient: 'left',
    tickSize: 0,
    tickPadding: 15,
    tickRotation: 0,
    tickValues: 5,
  },
  theme = {
    axis: { ticks: { text: { fontSize: 12, fill: Colors.grey_blue_3 } } },
    grid: { line: { stroke: Colors.background } },
  },
  xFormat,
  colors,
  enableGridX,
  enableGridY,
  gridYValues,
  enableArea,
  isInteractive,
  sliceTooltip,
  enableSlices,
}: LineChartPropTypes): JSX.Element => (
  <StyledLineChart
    className='lineChart'
    width={width}
    height={height}
    backgroundColor={backgroundColor}
    data={data}
    margin={margin}
    xFormat={xFormat}
    xScale={xScale}
    yScale={yScale}
    axisBottom={axisBottom}
    axisLeft={axisLeft}
    colors={colors}
    enableGridX={enableGridX}
    enableGridY={enableGridY}
    gridYValues={gridYValues}
    enableArea={enableArea}
    theme={theme}
    isInteractive={isInteractive}
  >
    <div className='lineChartWrapper'>
      <ResponsiveLine
        data={data}
        margin={margin}
        xFormat={xFormat}
        xScale={xScale}
        yScale={yScale}
        axisTop={null}
        axisRight={null}
        axisBottom={axisBottom}
        axisLeft={axisLeft}
        colors={colors}
        enableGridX={enableGridX}
        enableGridY={enableGridY}
        gridYValues={gridYValues}
        enableArea={enableArea}
        pointSize={1}
        pointLabel='y'
        pointLabelYOffset={-12}
        useMesh
        theme={theme}
        isInteractive={isInteractive}
        sliceTooltip={sliceTooltip}
        enableSlices={enableSlices}
      />
    </div>
  </StyledLineChart>
);

import React, { useState, useEffect } from 'react';

import styled from 'styled-components';
import { endOfDay, sub, startOfDay, isToday, format } from 'date-fns';
import { constantGenerators } from '@whispir/expression-helper';

import { WrappedComponentProps } from '../../../definitions/operatorAndOperand';
import { DatePicker } from '../../../../../Atoms/Forms';
import { dateToISOString } from './utils';
import { BetweenDateWrapper } from './BetweenDateComponent.style';

const { generateDateBetweenConstant } = constantGenerators;

const AndWrapper = styled.div`
  margin-top: 4px;
  margin-bottom: 4px;
`;

const betweenDateDefaultValueTransform = (defaultValue) => {
  const today = new Date();
  const { context, value, valueType } = defaultValue;

  const newDateRange = {
    after: startOfDay(today),
    before: endOfDay(today),
  };

  if (isToday(new Date(value))) {
    newDateRange.before = today;
  }

  if (context) {
    if (valueType === 'number') {
      const { durationUnit } = context;
      const subOptions = {};

      subOptions[`${durationUnit}s`] = value;

      newDateRange.after = sub(today, subOptions);
      newDateRange.before = today;
    }

    if (valueType === 'date') {
      const {
        before: { value: beforeValue },
      } = context;

      newDateRange.after = startOfDay(new Date(value));
      newDateRange.before = endOfDay(new Date(beforeValue));
    }
  }

  return newDateRange;
};

export const BetweenDateComponent = ({
  defaultValue,
  onChange = (value) => undefined,
}: WrappedComponentProps): JSX.Element => {
  const defaultDate =
    defaultValue && betweenDateDefaultValueTransform(defaultValue);
  const [state, setState] = useState(defaultDate && { ...defaultDate });
  const maxDate = (state && state.before) || undefined;
  const minDate = (state && state.after) || undefined;

  useEffect(() => {
    if (state) {
      const { after, before } = state;
      const dateRange = {
        afterValue: after,
        beforeValue: before,
      };

      if (dateToISOString(after) === dateToISOString(before)) {
        dateRange.afterValue = startOfDay(new Date(after));
        dateRange.beforeValue = endOfDay(new Date(before));
      }

      const betweenDateConstant = generateDateBetweenConstant(dateRange);
      onChange(betweenDateConstant);
    }
  }, [state]);

  const handleAfterChange = (value) => {
    setState({
      ...state,
      after: value,
    });
  };

  const handleBeforeChange = (value) => {
    setState({
      ...state,
      before: value,
    });
  };

  return (
    <BetweenDateWrapper>
      <DatePicker
        autoFocus
        defaultValue={state && state.after}
        onChange={handleAfterChange}
        maxDate={maxDate}
        maxDateMessage={`Date should not be after ${format(
          new Date(maxDate),
          'dd/MM/yyyy'
        )}`}
      />
      <AndWrapper>and</AndWrapper>
      <DatePicker
        defaultValue={state && state.before}
        onChange={handleBeforeChange}
        minDate={minDate}
        minDateMessage={`Date should not be before ${format(
          new Date(minDate),
          'dd/MM/yyyy'
        )}`}
      />
    </BetweenDateWrapper>
  );
};

import React, { Fragment } from 'react';
import { ColorIcon, ColorIconPairs } from '../../Atoms/ColorIcon';
import { IconTypes } from '../../Atoms/Icon';
import { ColorTypes } from '../../Foundation/Colors';
import { Alert } from '../../Molecules/Alert';
import { WeblinkReminderInfoPanel } from '../../WorkflowStudio/WeblinkReminderInfoPanel/WeblinkReminderInfoPanel';
import { ChannelSelectorWrapper } from './ChannelSelector.styles';

type Channel = {
  id: string;
  components?: Array<unknown>;

  label?: React.ReactNode;
  updateLabel?: React.ReactNode;
  createLabel?: React.ReactNode;
  title?: unknown;
  body?: unknown;
  errorMessage?: string;
  contentId?: string;
  actions?: React.ReactNode;
  additionalChannelInfo?: Array<ChannelInfo>;
};

function channelHasContent(channel?: Channel): boolean {
  if (!channel) {
    return false;
  }

  const hasTitle = !!channel.title;
  const hasContent = !!(
    (channel.components && channel.components.length) ||
    channel.contentId
  );
  const hasBody = !!channel.body;

  const isPopulated = hasContent || hasBody || hasTitle;

  return isPopulated;
}

type ChannelTypes = 'web' | 'sms' | 'email' | 'whatsapp' | 'webform';

type ChannelsMap = Partial<Record<ChannelTypes, Channel>>;

type Props = {
  channels: ChannelsMap;
  action: (value: ChannelTypes) => void;
};

type IconAndColor = {
  icon: IconTypes;
  color: ColorTypes;
};

type ChannelInfo = {
  type: 'info' | 'warning' | 'error' | 'tip' | 'success';
  message: string;
};

const generateChannelInfo = (additionalInfo: Array<ChannelInfo> = []) => {
  return additionalInfo.map(({ type, message }) => (
    <Alert
      key={message}
      className='channel-additional-info'
      content={message}
      type='inline'
      variant='standard'
      alert={type}
    />
  ));
};

// Just converting the keys to lower case. :/
export const IconsMap: Record<ChannelTypes, IconAndColor> = Object.entries(
  ColorIconPairs
).reduce((acc, [key, value]) => {
  return {
    ...acc,
    [key.toLowerCase()]: value,
  };
}, {} as Record<ChannelTypes, IconAndColor>);

type ChannelSelectorButtonProps = {
  channel: Channel;
  icon: IconAndColor;
  onClick: (key: ChannelTypes) => void;
  channelKey: ChannelTypes;
  message?: string;
  showWeblinkReminder?: boolean;
};
const ChannelSelectorButton = (props: ChannelSelectorButtonProps) => {
  const { channel, icon, onClick, channelKey, showWeblinkReminder } = props;

  if (!channel) {
    throw new Error(`No channel found for key: ${channelKey}`);
  }

  const isPopulated = channelHasContent(channel);

  return (
    <div
      key={channelKey}
      data-wrapper='button'
      className={`channel-selector-button ${
        isPopulated ? 'button' : 'button disable'
      }`}
      role='menuitem'
      tabIndex={0}
      onKeyPress={() => onClick(channelKey)}
      onClick={() => onClick(channelKey)}
    >
      <div className='action-container'>
        <div className='icon-wrapper'>
          <ColorIcon icon={icon.icon} color={icon.color} />
        </div>
        <div className='name'>
          {channel.label ||
            (channelKey === 'web' ? (
              <Fragment>
                {channel.id} Page{' '}
                <span className='optional-message'>(Optional)</span>
              </Fragment>
            ) : (
              `${channel.id}`
            ))}
        </div>

        {channel.errorMessage && (
          <p className='error-message'>{channel.errorMessage}</p>
        )}

        <div className='actions'>
          <div className='addon' onClick={(e) => e.stopPropagation()}>
            {channel.actions}
          </div>

          <div className='link'>
            {isPopulated
              ? channel.updateLabel || 'Edit'
              : channel.createLabel || 'Create'}
          </div>
        </div>
      </div>
      {channel.additionalChannelInfo &&
        !!channel.additionalChannelInfo.length && (
          <div className='info-container'>
            {generateChannelInfo(channel.additionalChannelInfo)}
          </div>
        )}
      {showWeblinkReminder && isPopulated && <WeblinkReminderInfoPanel />}
    </div>
  );
};

export const ChannelSelector = ({ channels, action }: Props) => {
  const handleClick = (key: ChannelTypes) => {
    action(key);
  };

  const shouldShowWeblinkReminder =
    channelHasContent(channels['web']) ||
    channelHasContent(channels['webform']);

  return (
    <ChannelSelectorWrapper>
      {Object.entries(channels).map(([key, value]) => {
        return (
          <ChannelSelectorButton
            // TODO: Is there a nicer way to do this?
            key={key as ChannelTypes}
            channelKey={key as ChannelTypes}
            channel={value as Channel}
            icon={IconsMap[key]}
            onClick={handleClick}
            showWeblinkReminder={
              !!(['sms', 'email'].includes(key) && shouldShowWeblinkReminder)
            }
          />
        );
      })}
    </ChannelSelectorWrapper>
  );
};

import React from 'react';

import styled from 'styled-components';
import Checkbox from '@material-ui/core/Checkbox';
import { Colors } from '../../Foundation/Colors';
import { Fonts } from '../../Foundation/Fonts';

export const FormCheckboxListWrapper = styled.div<{
  paddingTop: string;
  paddingBottom: string;
}>`
  width: 100%;
  padding: ${(props) => props.paddingTop}px 20px
    ${(props) => props.paddingBottom}px;
  font-family: ${Fonts.body};

  legend {
    display: inline-block;
    margin: 8px 0;
  }
  .MuiFormControlLabel-label {
    color: ${Colors.grey_blue};
  }
  p.MuiFormHelperText-root {
    margin-top: 8px;
  }

  .MuiFormLabel-root {
    ${Fonts.body};
    color: ${Colors.grey_blue_4};
  }
  .MuiInputLabel-formControl {
    position: static;
  }
  label + .MuiInput-formControl {
    margin-top: -4px;
  }
`;

export const StyledCheckbox = styled(({ root, ...other }) => (
  <Checkbox color='default' classes={{ root: 'root' }} {...other} />
))`
  &.root {
    color: ${Colors.grey_blue};
    margin-right: 4px;
  }
`;

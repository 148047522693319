import styled from 'styled-components';
import { Colors } from '../../../Foundation/Colors';

import { DividerPropsTypes } from './Divider.types';

const backgroundStyleApplied = (props) => {
  const { lineColor, isHorizontal } = props;
  if (lineColor) {
    return lineColor;
  }
  if (isHorizontal) {
    return `linear-gradient(to left, transparent, ${Colors.grey_blue_1} 20%, ${Colors.grey_blue_1} 90%, transparent)`;
  }
  return `linear-gradient(${Colors.background}, ${Colors.grey_blue_1}, ${Colors.background})`;
};

export const DividerWrapper = styled.div<DividerPropsTypes>`
  height: ${(props) => (props.isHorizontal ? 'auto' : props.height || '133px')};
  width: ${(props) => (props.isHorizontal ? '100%' : 'auto')};
  padding: ${(props) =>
    props.paddingControl ? props.paddingControl : '8px 0'};
  .divider-line {
    height: ${(props) => (props.isHorizontal ? '1px' : '100%')};
    width: ${(props) => (props.isHorizontal ? 'auto' : '1px')};
    margin: 0;
    background: ${(props) =>
      props.dividerType === 'solid' ? backgroundStyleApplied(props) : 'none'};
    border-bottom: ${(props) =>
      props.isHorizontal && props.dividerType === 'dashed'
        ? `2px dashed ${props.lineColor}`
        : 'none'};
    border-left: ${(props) =>
      props.dividerType === 'dashed'
        ? `2px dashed ${props.lineColor}`
        : 'none'};
    transform: scaleY(1.5);
  }
`;

import React, { useState } from 'react';

import { Modal } from '../../Atoms/Dialogs';
import { Button } from '../../Atoms/Navigations/Button';
import { Illustrations } from '../../Foundation/Illustrations';
import { Icon } from '../../Atoms/Icon';
import * as S from './UploadedImage.styles';

type Props = {
  // onReplace: () => void;
  onDelete: () => void;
  src?: string;
  title?: string;
  onClose?: () => void;
  onDeleteIconClicked?: () => void;
};

const DisplayModal = ({
  onDelete,
  displayModal,
  setDisplayModal,
  onClose,
}: {
  onDelete: () => void;
  displayModal: boolean;
  setDisplayModal: (p: boolean) => void;
  onClose?: () => void;
}): JSX.Element => (
  <Modal
    width='480px'
    isOpen={displayModal}
    handleExit={() => setDisplayModal(false)}
    headerContent={
      <S.ModalHeaderWrapper>
        <Illustrations.Delete />
      </S.ModalHeaderWrapper>
    }
    bodyContent={
      <S.ModalContentWrapper>
        <div>Are you sure you want to remove this image?</div>
        <div>
          Removing this image will delete the image from your component in the
          canvas. Any replacement images will need to be re-uploaded.
        </div>
      </S.ModalContentWrapper>
    }
    footerContent={
      <S.ModalButtonWrapper>
        <Button
          component='button'
          text='Cancel'
          variant='link'
          onClick={() => {
            if (onClose) onClose();
            setDisplayModal(false);
          }}
          size='large'
        />
        <Button
          type='error'
          component='button'
          text='Remove image'
          variant='button'
          onClick={() => {
            setDisplayModal(false);
            if (onDelete) onDelete();
          }}
          size='large'
        />
      </S.ModalButtonWrapper>
    }
  />
);

export const UploadedImage = ({
  // onReplace,
  onDelete,
  src,
  title,
  onClose,
  onDeleteIconClicked,
}: Props): JSX.Element => {
  const [displayModal, setDisplayModal] = useState<boolean>(false);

  return (
    <>
      <DisplayModal
        onDelete={onDelete}
        displayModal={displayModal}
        setDisplayModal={setDisplayModal}
        onClose={onClose}
      />
      <div>
        <S.TitleWrapper>
          {title && <S.Title>{title}</S.Title>}
          <div>
            {/* First iteration we are not going to be able to replace image */}
            {/* <Button
          type='primary'
          variant='link'
          onClick={onReplace}
          role='Replace'
          aria-label='Replace'
        >
          Replace
        </Button> */}
            <S.StyledButton
              type='primary'
              variant='link'
              onClick={() => {
                if (onDeleteIconClicked) onDeleteIconClicked();
                setDisplayModal(true);
              }}
              role='button'
              aria-label='Delete'
            >
              <Icon icon='Delete' />
            </S.StyledButton>
          </div>
        </S.TitleWrapper>
        <S.DisplayImageWrapper>
          {src && <img src={src} alt='Displaying uploaded image' />}
        </S.DisplayImageWrapper>
      </div>
    </>
  );
};

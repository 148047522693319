import { mapBlockModel } from '../../../Molecules/GoogleMap';

const extendMolecule = (model) => {
  model.type = 'InteractiveMap';
  model.label = 'Interactive Map';
  model.properties.splice(
    3,
    0,
    {
      name: 'options-divider',
      label: 'Options',
      control: 'TitleDividerNoLine',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'interactive',
      label: 'Interactive',
      control: 'ToggleSwitch',
      value: 'true',
      __typename: 'ComponentProperty',
    },
    {
      name: 'placeholder',
      label: 'Placeholder Text',
      control: 'TextFieldFilled',
      value: 'Enter your location here...',
      __typename: 'ComponentProperty',
    },
    {
      name: 'requiredField',
      label: 'Required Field',
      control: 'Checkbox',
      value: 'false',
      __typename: 'ComponentProperty',
    }
  );
  return model;
};

export const interactiveMapBlockModel = (id: string) => {
  const model = mapBlockModel(id);
  return extendMolecule(model);
};

import React from 'react';
import { StepsIndicator } from '../../Molecules/StepsIndicator';
import { HeaderTitle } from '../../Molecules/HeaderTitle';
import { HeaderProps } from './Header.types';
import {
  StyledHeader,
  StyledTitle,
  StyledActionsContainer,
  StyledIndicator,
} from './Header.styles';
import { PrimaryAction, SecondaryActions, CloseAction } from './actions';

const Indicator = ({ indicator, indicatorData }) => {
  let component;

  switch (indicator) {
    case 'StepsIndicator':
      component = <StepsIndicator {...indicatorData} />;
      break;

    default:
      return null;
  }

  return <StyledIndicator>{component}</StyledIndicator>;
};

export const Header = ({
  title,
  primaryAction,
  secondaryActions,
  closeAction,
  closeActionText,
  isFixedTop = false,
  isTitleEditable = false,
  onChangeTtile,
  indicator,
  indicatorData,
}: HeaderProps): JSX.Element => (
  <StyledHeader className={isFixedTop ? 'sticky-top' : ''}>
    <StyledTitle>
      <HeaderTitle
        text={title}
        isEditable={isTitleEditable}
        onChange={onChangeTtile}
      />
    </StyledTitle>
    <Indicator indicator={indicator} indicatorData={indicatorData} />
    <StyledActionsContainer>
      {secondaryActions && <SecondaryActions actions={secondaryActions} />}
      {primaryAction && <PrimaryAction {...primaryAction} />}
      {closeAction && (
        <CloseAction text={closeActionText || ''} action={closeAction} />
      )}
    </StyledActionsContainer>
  </StyledHeader>
);

import Promise from 'promise';

export const convertBase64 = (
  blob: Blob | null | undefined
): Promise<string | ArrayBuffer | null> =>
  new Promise<string | ArrayBuffer | null>((resolve, reject) => {
    if (!blob) return null;
    const reader: FileReader = new FileReader();

    reader.readAsDataURL(blob);
    reader.onerror = reject;
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });

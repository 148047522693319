import React, { useState, ReactNode } from 'react';

import { SideDrawerContext } from '../../Contexts/SideDrawerContext';
import { SideDrawer } from './SideDrawer';
import { SideDrawerProviderProps } from './SideDrawerProvider.types';

export const SideDrawerProvider = ({
  children,
  fullScreen,
}: SideDrawerProviderProps) => {
  const [drawContent, setDrawerContent] = useState<ReactNode | null>(null);

  return (
    <SideDrawerContext.Provider
      value={{
        content: drawContent,
        setContent: setDrawerContent,
      }}
    >
      {children}
      <SideDrawer fullScreen={fullScreen} position='right' />
    </SideDrawerContext.Provider>
  );
};

import React, { PureComponent, SyntheticEvent, MouseEvent } from 'react';

import {
  Droppable,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
} from 'react-beautiful-dnd';
import {
  ComponentPropertiesType,
  ComponentType,
} from '../../Contexts/global.types';
import { Colors } from '../../Foundation/Colors';
import { Elevations } from '../../Foundation/Elevations';
import { Icons } from '../../Foundation/Icons';

import { DynamicBlock } from './Blocks/DynamicBlock';
import { DragAndDropContext, updateDragAndDropItems } from './Context';
import {
  ComponentBoxOnCanvas,
  EmptyPlaceholder,
  UtilButton,
  DragController,
} from './DroppableTargetItems.style';
import {
  DroppableTargetItemsProps,
  DroppableTargetItemsState,
} from './DroppableTargetItems.types';

const { Delete, DragHandle } = Icons;

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  background: isDragging ? 'none' : 'lightgrey',
  boxShadow: isDragging ? Elevations.elevation_1 : 'none',
  ...draggableStyle,
});

const getTargetStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? Colors.accent_3 : Colors.white,
  padding: '24px',
  flex: 1,
  marginTop: '60px', // give room to draftail toolbar
});

const emptyPlaceholder = (activeChannel: string) => (
  <EmptyPlaceholder>
    <div className='title'>
      {activeChannel === 'web'
        ? 'Create Web Page'
        : activeChannel === 'email'
        ? 'Create Email'
        : 'Create Form'}
    </div>
    Drag and drop components here to create your template
  </EmptyPlaceholder>
);

export class DroppableTargetItems extends PureComponent<
  DroppableTargetItemsProps,
  DroppableTargetItemsState
> {
  // eslint-disable-next-line id-blacklist
  static defaultProps = {
    variableMenuData: {},
    getDeleteConfirmationModal: () => null,
  };

  static contextType = DragAndDropContext;

  constructor(props) {
    super(props);
    this.state = {
      deleteModal: undefined,
    };
  }

  doDelete = (channel: string, index: number, id: number | string) => {
    // get all components on the canvas
    const { localDraft, saveTemplate } = this.props;
    const { components } = localDraft[channel];

    const { type } = components.length && components[index];

    if (window.analytics) {
      window.analytics.track('Component Deleted', {
        componentName: type,
      });
    }

    // create a copy of all components on canvas
    const newSelected = components || [];

    // remove component with the right index
    newSelected.splice(index, 1);

    // update components
    saveTemplate(channel, [...newSelected]);

    // Compare the currently activeComponent's id with the id of the component just deleted
    const [{ activeComponent }, setActiveComponent] = this.context;
    const { id: idActive } = activeComponent || {
      id: '',
    };

    if (idActive === id) {
      // If the deleted component was also the currently active component, then reset the active to null
      setActiveComponent({
        activeComponent: null,
      });
      return;
    }

    // If the deleted component was NOT the active component, then retain that component as active to force a render.
    setActiveComponent({
      activeComponent: {
        ...activeComponent,
      },
    });
  };

  onDeleteClick = (channel: string, index: number, id: number | string) => (
    event: SyntheticEvent<HTMLElement>
  ) => {
    event.stopPropagation();
    const { getDeleteConfirmationModal } = this.props;

    // If this thing returns a modal, we store the data as state and display the modal
    // If it returns null, we just delete the thing immediately;
    const modal = getDeleteConfirmationModal && getDeleteConfirmationModal(id);

    if (modal) {
      this.setState({
        deleteModal: {
          Modal: modal,
          data: {
            channel,
            index,
            id,
          },
        },
      });
    } else {
      this.doDelete(channel, index, id);
    }

    return null;
  };

  onSelectComponent = (component: ComponentType) => (event: MouseEvent) => {
    const [, setActiveComponent] = this.context;
    setActiveComponent({ activeComponent: component });
    const { target, currentTarget } = event;

    // if (
    //   // $FlowFixMe
    //   currentTarget.parentElement.hasAttribute('draggable') &&
    //   !target.isContentEditable
    // ) {
    // }
  };

  textChangeHandler = (rawValue: string, id?: number | string) => {
    const [{ activeComponent }, setActiveComponent] = this.context;

    if (activeComponent && activeComponent.id === id) {
      setActiveComponent({
        activeComponent: updateDragAndDropItems(
          activeComponent,
          'raw',
          rawValue
        ),
      });
    }
  };

  widthChangeHandler = (widthValue: number | string) => {
    const [{ activeComponent }, setActiveComponent] = this.context;

    setActiveComponent({
      activeComponent: updateDragAndDropItems(
        activeComponent,
        'width',
        widthValue
      ),
    });
  };

  renderDynamicBlock = (
    activeChannel: string,
    type: string,
    properties: ComponentPropertiesType,
    id: string | number,
    activeId: string
  ) => {
    const { variableMenuData } = this.props;

    if (type === 'Image') {
      return (
        <DynamicBlock
          type={type}
          properties={properties}
          onChange={this.widthChangeHandler}
        />
      );
    } else if (
      type === 'Calendar' ||
      type === 'Text' ||
      type === 'Banner' ||
      type === 'Article' ||
      type === 'FullWidthBanner'
    ) {
      const focus = activeId === id;
      return (
        <DynamicBlock
          activeChannel={activeChannel}
          focus={focus}
          type={type}
          checkId={id}
          properties={properties}
          onChange={this.textChangeHandler}
          variableMenuData={variableMenuData ? variableMenuData : {}}
        />
      );
    }
    return <DynamicBlock type={type} properties={properties} />;
  };

  renderDraggable = (component: ComponentType, index: number) => (
    provided: DraggableProvided,
    snapshot: DraggableStateSnapshot
  ) => {
    const [{ activeComponent }] = this.context;
    const { activeChannel } = this.props;
    const { draggableProps, dragHandleProps, innerRef } = provided;
    const { type, id, properties } = component;
    const { id: activeId } = activeComponent || {};
    const isSubmit = type === 'SubmitButton';

    return (
      <div
        ref={innerRef}
        {...draggableProps}
        {...dragHandleProps}
        style={getItemStyle(snapshot.isDragging, draggableProps.style)}
      >
        <ComponentBoxOnCanvas
          onClick={this.onSelectComponent(component)}
          isSelected={id === activeId}
        >
          <DragController>
            <DragHandle />
          </DragController>
          <UtilButton
            disabled={isSubmit}
            onClick={this.onDeleteClick(activeChannel.toString(), index, id)}
            type={type}
          >
            <Delete />
          </UtilButton>

          {this.renderDynamicBlock(
            activeChannel,
            type,
            properties,
            id,
            activeId
          )}
        </ComponentBoxOnCanvas>
      </div>
    );
  };

  renderComponent = (component: ComponentType, index: number) => (
    <Draggable key={index} draggableId={component.id} index={index}>
      {this.renderDraggable(component, index)}
    </Draggable>
  );

  renderTarget = () => (
    provided: DraggableProvided,
    snapshot: DraggableStateSnapshot
  ) => {
    const { placeholder, innerRef } = provided;
    const { localDraft, activeChannel } = this.props;

    const { components = [] } = localDraft[activeChannel] || {};
    const containerStyle = getTargetStyle(snapshot.isDraggingOver);

    const content = components.map(this.renderComponent);

    const shouldShowEmptyPlaceholder =
      components.length === 0 ||
      (components.length === 1 && components[0].type === 'SubmitButton');

    return (
      <div ref={innerRef} style={containerStyle}>
        {shouldShowEmptyPlaceholder && emptyPlaceholder(activeChannel)}
        {content}
        {placeholder}
      </div>
    );
  };

  onModalConfirmDelete = () => {
    const { deleteModal } = this.state;
    if (!deleteModal) {
      throw new Error(
        "Attempting to call onConfirmDeleteDialog when deleteDialog doesn't exist"
      );
    }
    const { data } = deleteModal;
    const values = Object.values(data);
    this.doDelete(
      values[0] as string,
      values[1] as number,
      values[2] as number
    );
    this.setState({ deleteModal: undefined });
  };

  onModalCancelDelete = () => {
    this.setState({
      deleteModal: undefined,
    });
  };

  render() {
    const { deleteModal } = this.state;
    return (
      <React.Fragment>
        {deleteModal && (
          <deleteModal.Modal
            onConfirm={this.onModalConfirmDelete}
            onCancel={this.onModalCancelDelete}
            open={!!deleteModal}
          />
        )}
        <Droppable droppableId='target'>{this.renderTarget()}</Droppable>
      </React.Fragment>
    );
  }
}

import axios from 'axios';

export const graphQL = async (GraphQLServer, query) => {
  if (!GraphQLServer) return null;

  let res;

  try {
    res = await axios({
      url: GraphQLServer,
      method: 'post',
      withCredentials: true,
      data: { query },
    });
  } catch (e) {
    console.error('graphQL error: ', { e });
    return e;
  }

  const { data: { data, errors } = {} } = res;

  if (errors && errors.length > 0) {
    throw new Error(errors[0].message);
  }

  if (data && data.auth && data.auth.status !== '200') {
    throw new Error(data.auth.errorSummary);
  }

  return res.data;
};

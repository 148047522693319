import React from 'react';
import { RatingProps, CustomIconsType, Rating } from '../../../Atoms/Rating';
import { Icons } from '../../../Foundation/Icons';

import { RatingWrapper } from './EmojiRating.styles';

const {
  SentimentVeryDissatisfied,
  SentimentDissatisfied,
  SentimentSatisfied,
  SentimentSatisfiedAlt,
  SentimentVerySatisfied,
} = Icons;

export const EmojiRating = (props: RatingProps): JSX.Element => {
  const {
    iconSize,
    label1,
    label2,
    label3,
    label4,
    label5,
    iconColor,
    highlightColor,
  } = props;
  const customIcons: CustomIconsType = {
    1: {
      icon: <SentimentVeryDissatisfied />,
      label: label1,
    },
    2: {
      icon: <SentimentDissatisfied />,
      label: label2,
    },
    3: {
      icon: <SentimentSatisfied />,
      label: label3,
    },
    4: {
      icon: <SentimentSatisfiedAlt />,
      label: label4,
    },
    5: {
      icon: <SentimentVerySatisfied />,
      label: label5,
    },
  };
  return (
    <RatingWrapper
      iconSize={iconSize}
      iconColor={iconColor}
      highlightColor={highlightColor}
    >
      <Rating customIcons={customIcons} {...props} />
    </RatingWrapper>
  );
};

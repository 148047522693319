import { Colors } from '../../../Foundation/Colors';
import { ModelType } from '../../utilityTypes';

export const formYesNoButtonsModel = (id: string): ModelType => ({
  id,
  type: 'FormYesNoButtons',
  label: 'Yes/No Buttons',
  componentType: 'form',
  properties: [
    {
      name: 'styles',
      label: 'Styles',
      control: 'TitleDivider',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'label',
      label: 'Label',
      control: 'TextFieldFilled',
      value: 'Yes/No',
      __typename: 'ComponentProperty',
    },
    {
      name: 'requiredField',
      label: 'Required Field',
      control: 'Checkbox',
      value: 'false',
      __typename: 'ComponentProperty',
    },
    {
      name: 'helperText',
      label: 'Helper Text',
      control: 'TextFieldFilled',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'padding_8px_1',
      label: '',
      control: 'PaddingControl8px',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'buttons',
      label: 'Buttons',
      control: 'TitleDividerNoLine',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'buttonTextColor',
      label: 'Button Text Colour',
      control: 'ColorPicker',
      value: Colors.white,
      __typename: 'ComponentProperty',
    },
    {
      name: 'buttonColor',
      label: 'Button Colour',
      control: 'ColorPicker',
      value: Colors.accent_dnd,
      __typename: 'ComponentProperty',
    },
    {
      name: 'iconColor',
      label: 'Icon Colour',
      control: 'ColorPicker',
      value: Colors.white,
      __typename: 'ComponentProperty',
    },
    {
      name: 'buttonBorderColor',
      label: 'Border Colour',
      control: 'ColorPicker',
      value: Colors.grey_blue_2,
      __typename: 'ComponentProperty',
    },
    {
      name: 'borderRadius',
      label: 'Border Radius',
      control: 'Slider',
      value: '5',
      __typename: 'ComponentProperty',
    },
    {
      name: 'optionsPicker',
      label: '',
      control: 'OptionsPickerTwoButtonLabels',
      value: [
        {
          id: 0,
          text: 'Yes',
          checked: 'false',
        },
        {
          id: 1,
          text: 'No',
          checked: 'false',
        },
      ],
      __typename: 'ComponentProperty',
    },
    {
      name: 'iconAlignment',
      label: 'Icon Alignment',
      control: 'IconAlignmentSelector',
      value: 'left',
      __typename: 'ComponentProperty',
    },
    {
      name: 'customIcon',
      label: 'Custom Icon',
      control: 'ToggleSwitch',
      value: 'false',
      __typename: 'ComponentProperty',
    },
    {
      name: 'yesIconSrc',
      label: 'Icon 1 Image Source',
      control: 'TextFieldFilled',
      value:
        'https://cdn-au.whispir.com/public/resources/31c0e3dd97833bf32b5fe3c50bd05c5cde.svg',
      __typename: 'ComponentProperty',
    },
    {
      name: 'noIconSrc',
      label: 'Icon 2 Image Source',
      control: 'TextFieldFilled',
      value:
        'https://cdn-au.whispir.com/public/resources/31c7dd701a94d97f63d25e08021831bec2.svg',
      __typename: 'ComponentProperty',
    },
    {
      name: 'hideIcon',
      label: 'Hide Icon',
      control: 'ToggleSwitch',
      value: 'false',
      __typename: 'ComponentProperty',
    },
    {
      name: 'padding_8px_2',
      label: '',
      control: 'PaddingControl8px',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'alignmentTitle',
      label: 'Alignment',
      control: 'TitleDividerNoLine',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'alignment',
      label: 'Button',
      control: 'ItemsAlignmentSelector',
      value: 'center',
      __typename: 'ComponentProperty',
    },
    {
      name: 'padding_8px_3',
      label: '',
      control: 'PaddingControl8px',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'padding',
      label: 'Padding',
      control: 'TitleDividerNoLine',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'paddingTop',
      label: 'Top',
      control: 'Slider',
      value: '20',
      __typename: 'ComponentProperty',
    },
    {
      name: 'paddingBottom',
      label: 'Bottom',
      control: 'Slider',
      value: '20',
      __typename: 'ComponentProperty',
    },
  ],
  __typename: 'Component',
});

import React from 'react';

import {
  FormGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  FormHelperText,
} from '@material-ui/core'; // TODO: use CheckboxList from ui-lib-v2

import { TrueFalseString } from '../utilityTypes';

import { Checkbox } from '../../Atoms/Forms';
import { FormCheckboxListWrapper } from './FormCheckboxList.style';

type Props = {
  label: string;
  helperText: string;
  requiredField: TrueFalseString;
  paddingTop: string;
  paddingBottom: string;

  optionsPicker:
    | Array<{
        id: string;
        text: string;
        checked: TrueFalseString;
      }>
    | string;
};

export const FormCheckboxList = ({
  label,
  helperText,
  requiredField,
  paddingTop,
  paddingBottom,
  optionsPicker: defaultValue,
}: Props): JSX.Element => {
  // This scares me - is it going to be easy to fix?
  const defaultListItems =
    typeof defaultValue === 'string' ? JSON.parse(defaultValue) : defaultValue;

  if (!Array.isArray(defaultListItems)) {
    throw new Error(
      'The optionsPicker object was not an array or stringified array'
    );
  }

  return (
    <FormCheckboxListWrapper
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
    >
      <FormControl component='fieldset' required={requiredField === 'true'}>
        <FormLabel component='legend'>{label}</FormLabel>
        <FormGroup>
          {defaultListItems &&
            defaultListItems.map(({ id, text, checked }) => (
              <Checkbox
                key={id}
                label={text}
                isChecked={JSON.parse(checked)}
                value={text}
              />
            ))}
        </FormGroup>
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
    </FormCheckboxListWrapper>
  );
};

import styled from 'styled-components';

import { FormHelperText } from '@material-ui/core';

import { Colors } from '../../../Foundation/Colors';
import { Elevations } from '../../../Foundation/Elevations';
import { Fonts } from '../../../Foundation/Fonts';
import { TRANSITION_TIME } from '../../../Foundation/Spacing';
import { DropdownWrapperProps, DropdownProps } from './Dropdown.types';

export const DropdownWrapper = styled.div<DropdownWrapperProps>`
  width: 100%;
  margin: 0;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};

  .MuiFormControl-root {
    width: 100%;

    .MuiInputLabel-root {
      /* Use important to override inline styling from react-select-material-ui */
      color: ${({ disabled }) =>
        disabled ? Colors.grey_blue_3 : Colors.grey_blue_4} !important;
    }

    .MuiInputLabel-shrink,
    .MuiInputLabel-focussed {
      margin-bottom: 8px;
    }

    :focus-within,
    :focus-within:hover {
      .MuiInputLabel-shrink {
        /* Use important to override inline styling from react-select-material-ui */
        color: ${Colors.accent} !important;
      }

      .dropdown__control {
        border-color: ${Colors.accent};
        border-width: 0 0 2px 0;
        background: none;
        margin-right: 0;
      }
    }

    :hover {
      .MuiInputLabel-root {
        /* Use important to override inline styling from react-select-material-ui */
        color: ${Colors.accent_dark} !important;
        margin-right: 0;
      }

      .dropdown__control {
        border-color: ${Colors.accent_dark};
        border-width: 0 0 2px 0;
        background: none;
        margin-right: 0;
      }
    }
  }

  .MuiInputLabel-root {
    ${Fonts.body};
  }

  .MuiInputLabel-shrink {
    ${Fonts.caption};
    font-weight: 500;
    transform: translate(0, 1.5px);
  }

  .dropdown__control {
    background-color: transparent;
    border-radius: 0;
    border-color: ${Colors.grey_blue_3};
    border-width: 0 0 1px 0;
    box-shadow: none;
    min-height: 28px;
    height: 28px;
    margin-right: 0;
  }

  .dropdown__value-container {
    ${Fonts.body};
    padding-left: 0;
    color: ${Colors.grey_blue};
    cursor: text;
  }

  .dropdown__input {
    margin-left: 0;
    color: ${Colors.grey_blue_4};
  }

  .dropdown__menu-portal {
    z-index: 1500;
    transform: translateX(-8px);
  }

  .dropdown__menu {
    margin-top: 0;
    border-radius: 8px;
    width: 90%;
    box-shadow: ${Elevations.elevation_3};
    background-color: ${Colors.white};
    width: 100%;
  }

  .dropdown__menu-list {
    margin: 8px 0;
  }

  .dropdown__option {
    ${Fonts.body};
    color: ${Colors.grey_blue};
    padding: 5px 16px;
    cursor: pointer;
    transition: background-color ${TRANSITION_TIME};
    background-color: ${Colors.white};
  }

  .dropdown__option--is-focused {
    background-color: ${Colors.grey_blue_1};
  }

  .dropdown__indicators {
    margin-right: 0;
  }

  .dropdown__control--menu-is-open > .dropdown__indicators > div {
    transform: rotate(180deg);
  }

  .dropdown__control--is-disabled {
    .dropdown__value-container {
      color: ${Colors.grey_blue_3};
    }

    .dropdown__indicators {
      div {
        border-top: 6px solid ${Colors.grey_blue_3};
      }
    }
  }
`;

export const HelperText = styled(FormHelperText)`
  margin-top: 8px;
`;

export const DownArrow = styled.div`
  border: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid ${Colors.grey_blue};
  margin-right: 8px;
  transition: all ${TRANSITION_TIME};
`;

export const EmptyResultsMessage = styled.div`
  color: ${Colors.grey_blue_4};
  padding: 10px 20px;
`;

export const OptionIcon = styled.span`
  display: flex;
  padding-right: 8px;

  svg {
    fill: ${Colors.grey_blue_4};
    width: 20px;
    height: 20px;
  }
`;

export const SingleValue = styled.div`
  display: flex;
`;

export const OptionText = styled.div`
  display: flex;
`;

const getOrientationStyles = (orientation) => {
  if (orientation === 'horizontal') {
    return `
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      & > label {
        flex: 2;
        margin-right: 20px;
      }

      & > div {
        flex: 1;
        min-width: 159px;
        max-width: 250px;
      }
    `;
  }
  return ``;
};

export const StyledDropdownSection = styled.div<{
  orientation: DropdownProps['orientation'];
}>`
  ${(props) => getOrientationStyles(props.orientation)}

  label {
    ${Fonts.link};
    color: ${Colors.grey_blue};
    text-align: left;
  }
`;

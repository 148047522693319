import React from 'react';
import { StyledEmptyState } from './EmptyState.styles';

type EmptyStateProps = {
  className?: string;
  style?: React.CSSProperties;
};

export const EmptyState = ({ className, style }: EmptyStateProps) => {
  return (
    <StyledEmptyState className={className} style={style}>
      No content. Drag and drop content blocks from the right.
    </StyledEmptyState>
  );
};

import React from 'react';
import { smsMaximumSize } from '@whispir/utils-js';
import { MessageCounter } from '../MessageCounter';
import { MobilePreview } from '../MobilePreview';
import { SmsPreviewDisplayTypes } from './SmsPreviewDisplay.types';
import { SMSMobilePreviewWrapper } from './SmsPreviewDisplay.styles';

export const SmsPreviewDisplay = ({
  message,
  type,
  media,
  title = 'Preview',
}: SmsPreviewDisplayTypes): JSX.Element => {
  const messageMaxLength = type === 'whatsapp' ? 1024 : smsMaximumSize();

  return (
    <SMSMobilePreviewWrapper>
      <div className='sms-preview'>
        {title && <div className='header'>{title}</div>}
        <div className='message-counter'>
          <MessageCounter message={message} maxLength={messageMaxLength} />
        </div>
        <MobilePreview {...{ message, type, media }} />
      </div>
    </SMSMobilePreviewWrapper>
  );
};

import React from 'react';
import { IconTypes } from '../../Atoms/Icon';
import { ActionCard } from '../ActionCard/ActionCard';

type ActionCardItem<T> = {
  heading: string;
  description: string;
  value: T;
  icon: IconTypes;
  tooltip?: string;
};

type ActionCardRadioGroupProps<T> = {
  items: Array<ActionCardItem<T>>;
  isDisabled?: boolean;
  selectedValue: T | null;
  onChange: (value: T) => void;
};

export const ActionCardRadioGroup = <T,>(
  props: ActionCardRadioGroupProps<T>
) => {
  const { items, isDisabled, selectedValue, onChange } = props;

  return (
    <div>
      {items.map((v) => (
        <ActionCard
          key={JSON.stringify(v.value)}
          icon={v.icon}
          heading={v.heading}
          description={v.description}
          tooltip={v.tooltip}
          isDisabled={isDisabled}
          isChecked={selectedValue === v.value}
          onClick={() => onChange(v.value)}
          variant='radio'
        />
      ))}
    </div>
  );
};

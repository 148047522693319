import styled, { css } from 'styled-components';
import { TextField as TextFieldComponent } from '../../Atoms/Forms';
import { Fonts } from '../../Foundation/Fonts';
import { Colors } from '../../Foundation/Colors';

const sharedWrapperStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${Colors.background};
  border: 1px dashed ${Colors.grey_blue_1};
  border-radius: 0.25rem;
`;

export const HeadingContainer = styled.div`
  width: 100%;
  text-align: left;
  ${Fonts.headline}
`;

export const BodyContainer = styled.div`
  ${Fonts.body};
  padding: 2rem 0 0.5rem;
`;

export const FileUploadButtonWrapper = styled.div`
  text-align: right;
  padding-top: 0.5rem;
`;

export const LoadingWrapper = styled.div`
  ${sharedWrapperStyles}
  ${Fonts.caption}
  flex-direction: column;
  padding: 1rem;
  height: 220px;
  box-sizing: border-box;

  > * + * {
    margin-top: 1rem;
  }
`;

export const PreviewImageWrapper = styled.div`
  ${sharedWrapperStyles}
`;

export const ErrorMessagesWrapper = styled.div`
  margin-top: 1rem;
  overflow: hidden;
`;

export const Image = styled.img`
  max-height: 224px;
  height: 100%;
  max-width: 100%;
  border-radius: 4px;
  object-fit: cover;
  border: 1px solid ${Colors.accent_5};
`;

export const InputWrapper = styled.div`
  > *:first-child {
    ${Fonts.body}
    margin-bottom: 0.5rem;
  }
`;

export const TextField = styled(TextFieldComponent)`
  .MuiFormHelperText-root {
    font-size: 12px;
    font-weight: 300;
  }
`;

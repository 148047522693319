import React, { forwardRef } from 'react';

import { Button as MUIButton } from '@material-ui/core';

import { renderIconElement } from '../../../Utils/renderIconElement';
import { ButtonProps } from './Button.types';
import { ButtonWrapper } from './Button.styles';
import { LinkWrapper } from './Link.styles';

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    const {
      type = 'primary',
      size = 'large',
      variant = 'button',
      disabled,
      startIcon,
      endIcon,
      text,
      onClick,
      children,
      href,
      component = 'button',
      className = '',
      ...otherProps
    } = props;

    const WrapperToUse = variant === 'button' ? ButtonWrapper : LinkWrapper;

    return (
      <WrapperToUse type={type} size={size} className={`${type} ${className}`}>
        <MUIButton
          ref={ref}
          disabled={disabled}
          startIcon={renderIconElement(startIcon)}
          endIcon={renderIconElement(endIcon)}
          onClick={onClick}
          disableRipple
          href={href}
          component={component || (href ? 'a' : 'p')}
          {...otherProps}
        >
          <span className='content'>{text}</span>
          {children}
        </MUIButton>
      </WrapperToUse>
    );
  }
);

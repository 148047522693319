import styled from 'styled-components';
import { Colors } from '../../Foundation/Colors';
import { Fonts } from '../../Foundation/Fonts';
import { Elevations } from '../../Foundation/Elevations';

export const TovInfoBannerWrapper = styled.div<{ variant: 'small' | 'large' }>`
  display: flex;
  justify-content: space-between;
  background-color: ${Colors.accent_1};
  border-radius: 4px;
  box-sizing: border-box;
  padding: 16px;
  box-shadow: ${Elevations.elevation_1};

  .tov-info-banner-text {
    display: flex;
    flex-direction: column;
    ${Fonts.body}
    padding: 0px 16px;
  }

  .emphasis-text {
    ${Fonts.link}
  }

  .tov-info-banner-buttons > div {
    margin-right: 24px;
  }

  .tov-info-banner-buttons {
    margin-top: ${(props) => (props.variant === 'large' ? '24px' : '8px')};
  }

  .Icon {
    box-sizing: border-box;
    height: 24px;
    width: 24px;
  }

  .info-icon-wrapper {
    svg {
      fill: ${Colors.accent};
    }
  }

  .close-icon-wrapper {
    svg {
      cursor: pointer;
      fill: ${Colors.grey_blue};
    }
  }
`;

import React, { useState, useRef } from 'react';

import Truncate from 'react-truncate';
import { Paper, ClickAwayListener, Popper } from '@material-ui/core';

import { Tooltip } from '../../Atoms/Dialogs';
import { Icons } from '../../Foundation/Icons';
import { CardType, HeaderIconType } from './Card.types';
import iconDefinition from './iconDefinition.json';
import { StyledCard } from './Card.style';

const HeaderIcon = ({ icon }: HeaderIconType): JSX.Element | null => {
  if (!icon) {
    return null;
  }

  const iconName = iconDefinition[icon];
  const Icon = Icons[iconName];

  return <Icon />;
};

export const Card = ({
  id,
  title,
  modified,
  createdBy,
  icon,
  status,
  cardOptions = [],
}: CardType): JSX.Element => {
  const [open, setOpen] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);
  const cardRef = useRef(null);

  const toggleOptionsPopperOpen = (e) => {
    e.preventDefault();
    setOpen(!open);
  };

  const handleTruncate = (isTextTruncated: boolean) => {
    setIsTruncated(isTextTruncated);
  };

  return (
    <StyledCard status={status} hasFocus={open || isTruncated}>
      <div className='card-header'>
        <HeaderIcon icon={icon} />
      </div>
      <div className='card-content'>
        <Tooltip
          title={title}
          disableHoverListener={!isTruncated}
          arrow
          ariaLabel={title}
          disablePortal
          placement='right'
        >
          <Truncate
            className='card-title'
            lines={2}
            ellipsis='...'
            onTruncate={handleTruncate}
          >
            {title}
          </Truncate>
        </Tooltip>
        <div className='card-creator'>Created by: {createdBy}</div>
        <div className='card-modified'>{modified}</div>
      </div>
      <div className='card-footer'>
        <div className='card-status'>{status}</div>
        <button
          type='button'
          className='options-button'
          ref={cardRef}
          onClick={toggleOptionsPopperOpen}
        >
          <Icons.Elipsis />
        </button>
        <Popper
          anchorEl={cardRef.current}
          open={open}
          placement='bottom'
          disablePortal
        >
          <ClickAwayListener onClickAway={toggleOptionsPopperOpen}>
            <Paper className='paper-popper'>
              {cardOptions.map(({ label, clickHandler }) => (
                <Paper
                  className='paper-option'
                  key={label}
                  onClick={(e) => {
                    e.preventDefault();
                    setOpen(false);
                    clickHandler(id);
                  }}
                >
                  {label}
                </Paper>
              ))}
            </Paper>
          </ClickAwayListener>
        </Popper>
      </div>
    </StyledCard>
  );
};

import { BLOCK_TYPE, INLINE_STYLE } from '@whispir/draftail';

// This stuff is good, but it shouldn't live in Contexts.

export type UpdateLocalDraftType = (string, any) => void;

// VARIABLE STUFF DEPRECATED!
// IF YOU ARE GOING TO EDIT IT, MOVE IT TO @whispir/variables
// https://bitbucket.org/whispirdevops/variables/src/master/
// And edit there.

export type SystemVariableType = {
  variableId: string;
  variableName: string;
  type: string;
  description: string;
};

export type SystemVariablesType = Array<SystemVariableType>;

export type VariableMenuDataType = {
  system: SystemVariablesType;
};

export type DraftType = any;

export type WhatsappDraftType = {
  type: string;
  name: string;
  parameters: any;
  __typename: 'DraftBody';
  body: string;
};

export type LocalDraftType = {
  web?: any;
  email?: any;
  sms?: any;
  whatsapp?: WhatsappDraftType;
  messageTemplateName?: string;
  templateId?: string;
};

export type ComponentPropertyType = {
  name: string;
  label: string;
  control: string;
  value: string;
  hidden?: string;
  __typename?: string;
};

export type ComponentPropertiesType = Array<ComponentPropertyType>;

export type ComponentType = {
  id: string | number;
  type: string;
  label: string;
  properties: ComponentPropertiesType;
  Icon?: React.ElementType;
  title?: string;
};

export type MessageLinkType = {
  uri: string;
  rel: string;
  method: string;
};

export type MessageTemplateType = {
  messageTemplateName: string;
  messageTemplateDescription: string;
  id: string;
  link: Array<MessageLinkType>;
};

export type MessageTemplatesType = Array<MessageTemplateType>;

export type DeleteModalType = {
  Modal: React.ElementType;
  data: {
    channel: string;
    index: number;
    id: number | string;
  };
};

export type DraftailEditorWrapperProps = {
  textColor: string;
  paddingTop: string;
  paddingBottom: string;
  backgroundColor: string;
};

export type TagButtonWrapperProps = {
  focus: boolean;
};

export const textAreaBlockTypes = [
  { type: BLOCK_TYPE.UNSTYLED },
  { type: BLOCK_TYPE.HEADER_ONE },
  { type: BLOCK_TYPE.HEADER_TWO },
  { type: BLOCK_TYPE.HEADER_THREE },
];

export const textAreaInlineStyles = [
  { type: INLINE_STYLE.BOLD },
  { type: INLINE_STYLE.ITALIC },
  { type: INLINE_STYLE.UNDERLINE },
  { type: INLINE_STYLE.STRIKETHROUGH },
];

import React, { useState } from 'react';
import { Button } from '../../Atoms/Navigations';
import { InfoPanel, Modal } from '../../Atoms/Dialogs';
import { Spinner } from '../../Atoms/Indicators';
import { FileSelector } from '../FileSelector';
import {
  FileUploadModalType,
  DisplayBodyContentProps,
} from './FileUploadModal.types';
import * as S from './FileUploadModal.style';

const DisplayBodyContent = ({
  step,
  fileCategory,
  imageUrl,
  setImageSource,
  imageSource,
  uploadPrompt,
  acceptedText,
  fileTypes,
  onFileSelected,
}: DisplayBodyContentProps) => {
  if (step === 'loading')
    return (
      <S.LoadingWrapper>
        <Spinner size='large' />
        <div>{`Uploading ${fileCategory}`}</div>
      </S.LoadingWrapper>
    );

  if (step === 'source') {
    return (
      <S.InputWrapper>
        <div>Image source</div>
        <S.TextField
          variant='filled'
          onChange={setImageSource}
          value={imageSource}
        />
      </S.InputWrapper>
    );
  }

  if (step === 'rename')
    return (
      <>
        {imageUrl && (
          <S.PreviewImageWrapper>
            <S.Image src={imageUrl} />
          </S.PreviewImageWrapper>
        )}
        {/* The first iteration we are not giving the user ability to rename just commenting it out for now */}
        {/* <S.InputWrapper>
          <div>Image name (optional)</div>
          <S.TextField
            variant='filled'
            onChange={setImageName}
            value={imageName}
            helperText='This will also show as the Alt Description unless changed.'
          />
        </S.InputWrapper> */}
      </>
    );

  return (
    <FileSelector
      uploadPrompt={uploadPrompt}
      acceptedText={acceptedText}
      acceptedExtension={fileTypes.toString()}
      blobType='both'
      onFileChange={onFileSelected}
      size='large'
      disableFileInfo
      // setting the maxSizeByte limit to a high value because we want the API to do the validation
      maxSizeByte={(99999 * 1024 * 1024).toString()}
    />
  );
};

export const FileUploadModal = ({
  uploadPrompt,
  fileCategory = 'file',
  fileTypes,
  onFileSelected,
  handleExit,
  isOpen,
  sizeLimitMB,
  onInsertButtonClicked,
  errorMessages = [],
  step = 'upload',
  imageUrl,
}: FileUploadModalType): JSX.Element => {
  const [imageSource, setImageSource] = useState('');
  const currentStepImageSource = step === 'source' ? imageSource : imageUrl;
  const buttonDisabled =
    step === 'upload' ||
    step === 'loading' ||
    errorMessages.length > 0 ||
    !onInsertButtonClicked ||
    !currentStepImageSource;
  const displayFileTypes = fileTypes.toUpperCase().split('.');
  const acceptedText = `${sizeLimitMB}MB maximum. ${displayFileTypes
    .splice(0, displayFileTypes.length - 1)
    .join('')} and ${displayFileTypes[displayFileTypes.length - 1]} format`;

  const uploadHeaderContent = (
    <S.HeadingContainer>{`Upload ${
      step === 'rename' ? fileCategory : 'from device'
    }`}</S.HeadingContainer>
  );

  const urlHeaderContent = (
    <S.HeadingContainer>Upload from URL</S.HeadingContainer>
  );

  const bodyContent = (
    <S.BodyContainer>
      <DisplayBodyContent
        step={step}
        fileCategory={fileCategory}
        imageUrl={imageUrl}
        setImageSource={setImageSource}
        imageSource={imageSource}
        uploadPrompt={uploadPrompt}
        acceptedText={acceptedText}
        fileTypes={fileTypes}
        onFileSelected={onFileSelected}
      />
      <S.ErrorMessagesWrapper>
        {errorMessages.length > 0 &&
          errorMessages?.map((message) => (
            <InfoPanel
              key={message}
              type='alert'
              message={message}
              icon='ErrorOutline'
            />
          ))}
      </S.ErrorMessagesWrapper>
    </S.BodyContainer>
  );

  return (
    <Modal
      width='480px'
      isOpen={isOpen}
      handleExit={handleExit}
      headerContent={step === 'source' ? urlHeaderContent : uploadHeaderContent}
      bodyContent={bodyContent}
      footerContent={
        <S.FileUploadButtonWrapper>
          <Button
            component='button'
            text='Insert'
            variant='button'
            onClick={() => {
              if (onInsertButtonClicked) onInsertButtonClicked(imageSource);
            }}
            size='large'
            disabled={buttonDisabled}
          />
        </S.FileUploadButtonWrapper>
      }
    />
  );
};

import styled from 'styled-components';
import { Colors } from '../../Foundation/Colors';
import { Fonts } from '../../Foundation/Fonts';
import { shadeHexColor } from '../../Utils/commonUtils';
import {
  YesNoButtonsButtonProps,
  YesNoIconWrapperProps,
  StyledYesNoButtonsWrapperProps,
} from './FormYesNoButtons.types';

const shadeAmount = -20;

export const StyledYesNoButtonsWrapper = styled.div<StyledYesNoButtonsWrapperProps>`
  padding: ${({ paddingTop, paddingBottom }) =>
    `${paddingTop}px 12px ${paddingBottom}px`};
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: ${({ alignment }) =>
    (alignment === 'left' && 'flex-start') ||
    (alignment === 'center' && 'center') ||
    (alignment === 'right' && 'flex-end')};
  align-items: center;
`;

export const LabelWrapper = styled.div`
  color: ${Colors.grey_blue_4};
  font-size: 14px;
  width: 100%;
  margin: 0px 0;
  padding: 0 8px;
`;

export const HelperTextWrapper = styled.div`
  margin-top: 8px;
  color: rgba(0, 0, 0, 0.54);
  font-size: 0.75rem;
  text-align: left;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
`;

export const YesNoButtonsButton = styled.a<YesNoButtonsButtonProps>`
  min-width: 137px;
  height: 40px;
  margin: 12px 8px;
  padding: 0 12px;
  ${Fonts.body};
  color: ${({ buttonTextColor }) => `${buttonTextColor}`};
  text-decoration: none;
  border: 1px solid ${({ buttonBorderColor }) => `${buttonBorderColor}`};
  border-radius: ${({ borderRadius }) => `${borderRadius}px`};
  text-align: center;
  word-break: break-word;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 11px;

  &.YesButton {
    background-color: ${({ selected, buttonColor }) =>
      `${
        selected === 'yes'
          ? `${shadeHexColor(buttonColor, shadeAmount)}`
          : `${buttonColor}`
      }`};
  }
  &.NoButton {
    background-color: ${({ selected, buttonColor }) =>
      `${
        selected === 'no'
          ? `${shadeHexColor(buttonColor, shadeAmount)}`
          : `${buttonColor}`
      }`};
  }
  transition: all 0.2s ease-in-out;
`;

export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;

  img {
    max-width: 20px;
    max-height: 20px;
  }
  padding: 0 5px;
`;

export const ButtonTextWrapper = styled.span`
  padding: 0 5px;
`;

export const IconWrapper = styled.div<YesNoIconWrapperProps>`
  display: flex;
  align-items: center;
  height: 20px;
  width: 20px;
  padding: 0 5px;

  svg {
    fill: ${({ iconColor }) => `${iconColor}`};
  }
`;

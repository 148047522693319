import React from 'react';

import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { PopoverType } from './Popover.types';
import { ContentWrapper, StyledPopoverMui } from './Popover.styles';

export const Popover = ({
  anchorOrigin = {
    vertical: 'top',
    horizontal: 'right',
  },
  transformOrigin = {
    vertical: 'top',
    horizontal: 'left',
  },
  content,
  children,
  ...otherProps
}: PopoverType): JSX.Element => {
  return (
    <PopupState variant='popover' popupId='popover-wrapper'>
      {(popupState) => (
        <>
          <ContentWrapper {...bindTrigger(popupState)}>
            {children}
          </ContentWrapper>
          <StyledPopoverMui
            {...bindPopover(popupState)}
            anchorOrigin={anchorOrigin}
            transformOrigin={transformOrigin}
            elevation={4}
            {...otherProps}
          >
            {content}
          </StyledPopoverMui>
        </>
      )}
    </PopupState>
  );
};

import styled from 'styled-components';
import { Colors } from '../../Foundation/Colors';
import { Fonts } from '../../Foundation/Fonts';

export const StyledPrebuiltTemplatesSelector = styled.div`
  .cancel-button {
    position: absolute;
    right: 37px;
    top: 37px;
  }

  .main-wrapper {
    margin: 0px 116px;
  }

  .main-title {
    ${Fonts.headline};

    margin-top: 48px;
    text-align: center;
    margin-bottom: 8px;
  }
`;

export const TemplateGroupWrapper = styled.div`
  padding: 24px 0px;
`;

export const StyledSubHeader = styled.div`
  ${Fonts.body};
  padding-bottom: 16px;

  .subhead-type {
    ${Fonts.subheadSmall};
    margin-right: 16px;
  }

  .subhead-description {
    color: ${Colors.grey_blue_4};
  }
`;

export const CardContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  /* NOTE: -12px is to align the leftmost and rightmost card of the row to the container
    thus, removing visually removing their horizontal margin */
  margin: 0px -12px;

  & > * {
    margin: 12px;
  }

  &.from-scratch .svg {
    background: ${Colors.accent_5};
    box-sizing: border-box;
    border-radius: 50%;

    height: 48px;
    width: 48px;
    padding: 14px;
    margin-top: 21px;

    > path {
      fill: ${Colors.grey_blue_0};
    }
  }
`;

import styled from 'styled-components';
import { Colors } from '../../Foundation/Colors';
import { Fonts } from '../../Foundation/Fonts';

export const StyledEmptyState = styled.div`
  padding: 36px;
  color: ${Colors.accent_5};
  background-color: ${Colors.accent_1};
  border: 1px dashed ${Colors.accent_2};

  ${Fonts.body};
  text-align: center;
`;

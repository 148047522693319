import { HIDDEN } from '../../derivedPropertyNames';

/**
 * Injects properties into model base on defined behaviour
 * - target: the target property names inside model properties
 * - name: The new property name of the injected property
 * - handler: The resulting value to be injected
 * - rules: We can do multiple injections for one or multiple targets
 * - properties: Includes all properties in the active/selected component
 */
export const richTextCalendarBlockDerivedProperties = [
  {
    targets: ['inviteFromTo'],
    rules: [
      {
        name: HIDDEN,
        handler: (properties) => {
          const { value: allDayCheckbox } = properties.find(
            ({ name }) => name === 'inviteAllDay'
          );

          return allDayCheckbox === 'true';
        },
      },
    ],
  },
  {
    targets: ['inviteFromToAllDay'],
    rules: [
      {
        name: HIDDEN,
        handler: (properties) => {
          const { value: allDayCheckbox } = properties.find(
            ({ name }) => name === 'inviteAllDay'
          );

          return allDayCheckbox === 'false';
        },
      },
    ],
  },
  {
    targets: ['iconSrc'],
    rules: [
      {
        name: HIDDEN,
        handler: (properties) => {
          const { value: customIconCheckbox } = properties.find(
            ({ name }) => name === 'customIcon'
          );
          const { value: hideIconCheckbox } = properties.find(
            ({ name }) => name === 'hideIcon'
          );

          return customIconCheckbox === 'false' || hideIconCheckbox === 'true';
        },
      },
    ],
  },
  {
    targets: ['iconColor'],
    rules: [
      {
        name: HIDDEN,
        handler: (properties) => {
          const { value: customIconCheckbox } = properties.find(
            ({ name }) => name === 'customIcon'
          );
          const { value: hideIconCheckbox } = properties.find(
            ({ name }) => name === 'hideIcon'
          );

          return customIconCheckbox === 'true' || hideIconCheckbox === 'true';
        },
      },
    ],
  },
  {
    targets: ['customIcon', 'iconAlignment'],
    rules: [
      {
        name: HIDDEN,
        handler: (properties) => {
          const { value: hideIconCheckbox } = properties.find(
            ({ name }) => name === 'hideIcon'
          );

          return hideIconCheckbox === 'true';
        },
      },
    ],
  },
  {
    targets: ['textColor'],
    rules: [
      {
        name: HIDDEN,
        handler: (properties) => {
          const { value: hideTextCheckbox } = properties.find(
            ({ name }) => name === 'hideText'
          );

          return hideTextCheckbox === 'true';
        },
      },
    ],
  },
  {
    targets: ['alignment', 'alignmentTitle', 'padding_8px_4'],
    rules: [
      {
        name: HIDDEN,
        handler: (properties) => {
          const { value: fullWidthCheckbox } = properties.find(
            ({ name }) => name === 'fullWidth'
          );

          return fullWidthCheckbox === 'true';
        },
      },
    ],
  },
];

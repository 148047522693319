import styled from 'styled-components';
import { Colors } from '../../Foundation/Colors';
import { Fonts } from '../../Foundation/Fonts';
import { TabComponentTypes } from './TabComponent.types';

export const TabsContainer = styled.div<TabComponentTypes>`
  width: 100%;
  height: 100%;
  * {
    box-sizing: border-box;
  }

  .MuiTabs-flexContainer {
    .tabs-action-pusher {
      margin-left: auto;
    }
    border-bottom: 1px solid ${Colors.grey_blue_1};
    button {
      span {
        padding: 10px;
        margin-bottom: 0px;
        text-transform: capitalize;
        ${(props) => (props.size === 'small' ? Fonts.body : Fonts.bodyLarge)}
        font-weight: 500;
        line-height: 22px;
        font-size: ${(props): string =>
          props.size === 'small' ? '14px' : '18px'};
      }
      svg {
        width: ${(props): string => (props.size === 'small' ? '14px' : '18px')};
        height: ${(props): string =>
          props.size === 'small' ? '14px' : '18px'};
        fill: ${Colors.grey_blue_4};
        vertical-align: middle;
        margin: 0 5px 0 0;
      }
    }
    button {
      &.is-active {
        > span {
          color: ${Colors.accent};
          border-color: ${Colors.accent};
        }
        svg {
          fill: ${Colors.accent};
        }
      }
    }
  }

  & > .MuiTypography-root {
    width: 100%;
    height: 100%;
    padding: 24px;
    overflow: auto;
    ${(props) => (props.size === 'small' ? Fonts.body : Fonts.bodyLarge)}
  }

  &.prebuilt-templates-selector {
    .MuiTabs-flexContainer {
      border-bottom: none;
    }

    .MuiTypography-root {
      overflow: hidden;
      padding: 0;
    }
  }

  .is-invalid {
    color: ${Colors.error};
  }

  .tabs-indicator {
    background-color: ${Colors.accent};
    height: 2px;
  }
`;

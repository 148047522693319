import styled from 'styled-components';
import { Colors } from '../../Foundation/Colors';

export const StyledModalHeader = styled.div`
  height: 64px;
  background: ${Colors.background};
  display: flex;
  flex-direction: row;
  align-items: center;

  .modalHeader-icon {
    fill: ${Colors.accent_5};
    width: 32px;
    height: 32px;
    margin: 0 24px;
  }

  .modalHeader-actions {
    margin-left: auto;
    margin-right: 24px;
    display: flex;
    align-items: center;
  }

  .modalHeader-close-button {
    margin-left: 24px;
  }

  .modalHeader-title {
    .back-button {
      padding: 0 24px;
    }
    display: flex;
    flex-direction: row;
    align-items: center;

    .MuiFormControl-root {
      margin-top: 8px; // Mui input has extra top margin
    }
  }

  .edit-icon {
    svg {
      fill: none;
      stroke: ${Colors.grey_blue_4};
    }
  }
`;

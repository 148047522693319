import styled from 'styled-components';

export const StyledLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  .MuiBadge-root {
    margin-left: 10px;
  }
  svg {
    fill: #1515ff;
    width: 16px;
    height: 16px;
  }
`;

import styled from 'styled-components';

import { Rating as RatingMUI } from '@material-ui/lab';
import { Colors } from '../../Foundation/Colors';
import { Fonts } from '../../Foundation/Fonts';

const gutterSpace = 12;
export const RatingStyles = styled(RatingMUI)`
  && {
    display: inline-flex;
    margin: 0 auto;
  }

  .MuiRating-label {
    padding: 0px ${gutterSpace}px;
    position: relative;
    min-width: 24px;
    max-width: calc(20% - ${gutterSpace * 2}px);
  }
`;

export const RatingWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const RatingHeading = styled.div`
  ${Fonts.bodyLarge};
  color: ${Colors.grey_blue_4};
  padding-bottom: 20px;
  align-self: flex-start;
`;

const calculateIconSize = (iconSize) => (iconSize * 76) / 100 + 24;
const getPaddingTopValue = (iconSize) => 8 + (8 * iconSize) / 100;
const customLabelStyles = (iconSize) => `
  ${Fonts.caption};
  white-space: nowrap;
  padding-top: ${getPaddingTopValue(iconSize)}px;
`;

const activeLabelStyles = (iconSize) => `
  padding-top: ${4 + getPaddingTopValue(iconSize)}px;
`;

export const commonStyles = (iconSize: number): string => `
  svg {
    width: ${calculateIconSize(iconSize)}px;
    height: ${calculateIconSize(iconSize)}px;
  }

  .MuiRating-root{
    flex-wrap: wrap;
  }

  .MuiRating-root::after{
    content: 'abc';
    color: transparent;
    ${customLabelStyles(iconSize)}
    min-width: 100%;
    flex: auto;
  }

  .Mui-RatingLabel{
    ${customLabelStyles(iconSize)}
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 100%;
    display: none;
    color: ${Colors.black};
  }

  .selected .MuiRating-iconFilled ~ .Mui-RatingLabel{
    display: block;
  }

  .MuiRating-iconActive ~ .Mui-RatingLabel{
    display: block;
  }

  .MuiRating-iconActive ~ .Mui-RatingLabel{
    ${activeLabelStyles(iconSize)}
  }
`;

export const HelperText = styled.div`
  ${Fonts.caption};
  margin-top: 8px;
  color: ${Colors.grey_blue_4};
  text-align: left;
  align-self: stretch;
`;

export const ErrorText = styled.div`
  ${Fonts.caption};
  margin-top: 8px;
  color: ${Colors.error};
  text-align: left;
  align-self: stretch;
`;

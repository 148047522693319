import {
  default as parsePhoneNumber,
  isValidPhoneNumber,
  NumberType,
  CountryCode,
  isSupportedCountry,
} from 'libphonenumber-js';

export const parseWorkflowDefinition = (definition) => {
  if (definition && typeof definition === 'string') {
    return JSON.parse(definition);
  }
  return definition;
};

export const parseDate = (value) => {
  let date = new Date(value).valueOf();

  if (Number.isNaN(date)) {
    let parsedValue;
    // If value cannot be parsed initially, try Number.parse the value
    try {
      parsedValue = Number.parseInt(value, 10);
      date = new Date(parsedValue).valueOf();
    } catch (e) {
      throw new Error(`Value: ${value} could not be parsed`);
    }
  }

  return date;
};

export const generateRandomColor = (): string => {
  const generateColor = () =>
    `#${Math.floor(Math.random() * 16777215).toString(16)}`;
  let color = generateColor();

  // Make sure color string is a valid hex and color isn't white
  while (color.length !== 7 || color.toLocaleLowerCase().includes('fff')) {
    color = generateColor();
  }

  return color;
};

// NOTE: Used to initialize default array value of props
// This prevents it to create new reference each render
const instance = Object.freeze([]);
// https://github.com/facebook/react/issues/18123
export const emptyArray = <T>(): Array<T> => instance;

// this also exists in html-transformers/src/utils
export const shadeHexColor = (hexInput, percent) => {
  let hex = hexInput;

  // strip the leading # if it's there
  hex = hex.replace(/^\s*#|\s*$/g, '');

  // convert 3 char codes --> 6, e.g. `E0F` --> `EE00FF`
  if (hex.length === 3) {
    hex = hex.replace(/(.)/g, '$1$1');
  }

  let r = parseInt(hex.substr(0, 2), 16);
  let g = parseInt(hex.substr(2, 2), 16);
  let b = parseInt(hex.substr(4, 2), 16);

  const calculatedPercent = (100 + percent) / 100;

  r = Math.round(Math.min(255, Math.max(0, r * calculatedPercent)));
  g = Math.round(Math.min(255, Math.max(0, g * calculatedPercent)));
  b = Math.round(Math.min(255, Math.max(0, b * calculatedPercent)));

  return `#${r.toString(16).toUpperCase()}${g
    .toString(16)
    .toUpperCase()}${b.toString(16).toUpperCase()}`;
};

export type ValidateNumberOption = {
  validateMobile?: boolean;
  targetCountries?: Array<CountryCode>;
};

export const validateMobileNumber = (
  number: string,
  options?: ValidateNumberOption
) => {
  const { targetCountries = [], validateMobile = false } = options || {};

  const parsedNumber = parsePhoneNumber(number, { extract: false });
  const isValid = parsedNumber?.isValid();
  let isMobile = true;
  let isCountryValid = true;

  if (validateMobile)
    isMobile = parsedNumber?.getType() === ('MOBILE' as NumberType);
  if (targetCountries.length)
    isCountryValid = targetCountries
      .filter((code) => isSupportedCountry(code))
      .some((code) => isValidPhoneNumber(number, code));

  return isValid && isMobile && isCountryValid;
};

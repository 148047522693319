import styled from 'styled-components';
import { Fonts } from '../../Foundation/Fonts';

export const ChannelSettingsWrapper = styled.div`
  margin-left: 24px;
  
  .component-property__title {
    text-transform: capitalize;
    ${Fonts.subheadSerif};
    margin-top: 40px;

  .variable-text-input {
    bottom: 38px;
    right: 34px;
  }
`;

/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ComponentType } from 'react';
/**
 * This solves an odd typescript quirk with React.memo losing typing information when using generics.
 * https://github.com/DefinitelyTyped/DefinitelyTyped/issues/37087
 *
 * Our specfic  repro here:
 * https://www.typescriptlang.org/play?#code/JYWwDg9gTgLgBAJQKYEMDG8BmUIjgIilQ3wG44AoCmATzCTgAUcwBnOAXjgG8K5+4mCBABccVjCjAAdgHNyFAL5Va9OAGVcSAOJJpSKWgA8AFThIAHjD0ATdhKlyAfJx58BAIxRQxJhYoUKS0hYOFUGBAgAdwARFBgUV2Q0aBsjBxlZABo4AFdpAGtpaOkXADI3fmAbMQy5f1IVOgYAYQgAG1yQaTiE03MrW3ZI2PiUHIBpAetpOzgCpBoITDgzCrrZLgWllbXxSUyXLl5+IhQbCGl2mkFgJHaauAmFfgALaqQAfjgxD2F21DSF5wV6oGwGAByKBASFqB3qlFOtgMLXu7U+YgAFAA3FCdWGrADaEwAujkbGMxCNeigAJScFwAKXUAA0AHQAUQBMOkMEa-FY0BgADFpN8sSgxPkiiUch4pYVilFpPSOC5pF0PAZ-FQqMEhWFmqtmjY2p1uv1LDM5tSxkd5otlqtpkM4DJMAYnnBPu4plbXZjtk69ht6d8zV0emNTJMXGJ9NjPfGkImoI0mmo2uB2sA0PEkDZdPpDJbBrNhtEafbKgIcFFWGIAIJQKAoGimJzA-gpc3SBtwZut9smE0Ri0mJydyjKCgpPvwACyNCzYCSxBgbJhIAgmMxYBY-eYEDYqpcRhswGxAHonLT07PLhI4EuVwAmNfoDdbnel632eGyE4e4HmImgwkWBi5h2p5wOel43nelAPvOcArjmebWDYADy+iuL+rq2gkWRAfux79mhub5oWeiQcYE4wXB15TshT7hDYC5INuYgdpiaC+DBZhcMgn6bpxEDpnOT4URhBY4UgHHbq4bEKT+Zj+uWiCVmMxHAWRYjSVREElvRDKwReTGIbqkmLigMh4WpZY2lpRFwAAqi6GkbDkQF1v2g5th2OQ9pGfktgFI70KaHSRh2Tg5A4YiuTBvDuEiMC5FA0hmZeTipbBL64KuQgQK4+Cgu07QQPgN55UYBXgO+XhQK43AOIoNW1QZmFyXAvktb5ygCHAwXdOwxwjX27W5UNQ1GF1sn6CpvXRGNPADXlE2rdwm1TXluqzVe5m5YoQA
 */
export const typedMemo: <T extends ComponentType<any>>(
  c: T,
  areEqual?: (
    prev: React.ComponentProps<T>,
    next: React.ComponentProps<T>
  ) => boolean
) => T = React.memo;

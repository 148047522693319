import { Colors } from '../../../Foundation/Colors';
import { ModelType } from '../../utilityTypes';

export const richTextButtonBlockModel = (id: string): ModelType => ({
  id,
  type: 'Button',
  label: 'Button',
  properties: [
    {
      name: 'text',
      label: 'Styles',
      control: 'TitleDivider',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'label',
      label: 'Button Text',
      control: 'VariableTextInput',
      value: 'Button Text',
      __typename: 'ComponentProperty',
    },
    {
      name: 'url',
      label: 'Link URL',
      control: 'VariableTextInput',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'padding_8px_1',
      label: '',
      control: 'PaddingControl8px',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'textColor',
      label: 'Text Colour',
      control: 'ColorPicker',
      value: Colors.white,
      __typename: 'ComponentProperty',
    },
    {
      name: 'buttonColor',
      label: 'Button Colour',
      control: 'ColorPicker',
      value: Colors.accent,
      __typename: 'ComponentProperty',
    },
    {
      name: 'backgroundColor',
      label: 'Background Colour',
      control: 'ColorPicker',
      value: Colors.white,
      __typename: 'ComponentProperty',
    },
    {
      name: 'borderRadius',
      label: 'Border Radius',
      control: 'Slider',
      value: '5',
      __typename: 'ComponentProperty',
    },
    {
      name: 'fullWidth',
      label: 'Full Width',
      control: 'ToggleSwitch',
      value: 'false',
      __typename: 'ComponentProperty',
    },
    {
      name: 'padding_8px_4',
      label: '',
      control: 'PaddingControl8px',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'padding',
      label: 'Padding',
      control: 'TitleDividerNoLine',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'paddingTop',
      label: 'Top',
      control: 'Slider',
      value: '20',
      __typename: 'ComponentProperty',
    },
    {
      name: 'paddingBottom',
      label: 'Bottom',
      control: 'Slider',
      value: '20',
      __typename: 'ComponentProperty',
    },
  ],
  __typename: 'Component',
});

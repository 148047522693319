import React, { useEffect, useState } from 'react';
import { resolveExpression } from '@whispir/expression-helper';

import { DataTable } from '../DataTable';
import {
  DataTableProps,
  RowData,
  TypedColumn,
} from '../DataTable/DataTable.types';
import { FilterToolbar } from '../FilterToolbar';
import { AvailableFilters, SelectedFilter } from '../../Molecules/FilterPicker';
import { generateExpression } from './utils';
import { FilterTableWrapper } from './FilterTable.styles';

const getAvailableFilter = <T extends RowData>(
  columns: Array<FilterTableTypedColumn<T>>
): Array<AvailableFilters> =>
  columns.map(({ field, headerName, dataType = 'Text' }) => ({
    label: headerName,
    columnKey: field,
    dataType,
  }));

type FilterTableTypedColumn<T extends RowData> = TypedColumn<T> & {
  dataType?: 'Text' | 'Number' | 'Date';
};

type FilterTableProps<T extends RowData> = {
  searchOnColumnKey: string;
  searchLabel: string;
  columns: Array<FilterTableTypedColumn<T>>;
  availableFilters?: Array<AvailableFilters>;
} & DataTableProps<T>;

export const FilterTable = <T extends RowData>({
  rows,
  columns,
  searchLabel,
  searchOnColumnKey,
  maxHeight = '100%',
  isLoading,
  availableFilters,
  ...dataTableProps
}: FilterTableProps<T>): JSX.Element => {
  const [filteredRows, setFilteredRows] = useState(rows);
  const [selectedFilters, setSelectedFilters] = useState<Array<SelectedFilter>>(
    []
  );
  const [searchFilter, setSearchFilter] = useState<SelectedFilter>();
  const [isApplyingFilter, setIsApplyingFilter] = useState(false);

  // When the filter is applied, we put a 500ms delay to display the spinner
  // and make the animation not look jumpy
  const handleLoadingState = async (state) => {
    await new Promise<void>((resolve) =>
      setTimeout(() => {
        setIsApplyingFilter(state);
        resolve();
      }, 500)
    );
  };

  useEffect(() => {
    const filterRows = async () => {
      setIsApplyingFilter(true);

      const allFilters = [
        ...selectedFilters,
        ...[searchFilter].filter((item) => !!item),
      ];

      const validFilters = allFilters.filter((filter) => {
        if (!filter) return false;

        const { condition } = filter;

        return (
          condition &&
          condition.operator &&
          condition.operand &&
          condition.operand.value
        );
      }) as Array<SelectedFilter>;

      if (validFilters.length === 0) {
        await handleLoadingState(false);
        return setFilteredRows(rows);
      }

      const rowProms = rows.map(async (row) => {
        const allFilterPromises = validFilters.map(async (filter) => {
          const { columnKey, condition } = filter;
          const expression = generateExpression(condition, row[columnKey]);
          return resolveExpression(expression);
        });

        const allFilterResults = await Promise.all(allFilterPromises);
        return {
          isValid: allFilterResults.every((v) => v),
          row,
        };
      });

      const rowPromsResults = await Promise.all(rowProms);

      const theseFilteredRows = rowPromsResults
        .filter((v) => v.isValid)
        .map((v) => v.row);
      setFilteredRows(theseFilteredRows);

      await handleLoadingState(false);
    };

    filterRows();
  }, [selectedFilters, searchFilter, rows]);

  return (
    <FilterTableWrapper maxHeight={maxHeight}>
      <FilterToolbar
        searchOnColumnKey={searchOnColumnKey}
        searchLabel={searchLabel}
        onChangeFilters={setSelectedFilters}
        onChangeSearch={setSearchFilter}
        availableFilters={availableFilters || getAvailableFilter(columns)}
      />
      <DataTable
        {...dataTableProps}
        rows={filteredRows}
        columns={columns}
        isLoading={isLoading || isApplyingFilter}
      />
    </FilterTableWrapper>
  );
};

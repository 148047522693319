import styled from 'styled-components';
import { Fonts } from '../../Foundation/Fonts';

export const FieldIdContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-content: flex-end;
  align-items: flex-end;

  .FieldId-label {
    padding-right: 8px;
    margin: 0;
    ${Fonts.subheadMedium};
  }

  .tooltip-wrapper {
    min-width: 130px;
  }

  svg {
    margin-bottom: 1px;
  }
`;

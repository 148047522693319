import styled from 'styled-components';
import { Colors } from '../../../Foundation/Colors';
import { Fonts } from '../../../Foundation/Fonts';
import { TRANSITION_TIME } from '../../../Foundation/Spacing';

export const StyledSecondaryNavigation = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  padding: 0;
  height: 24px;
  cursor: pointer;
  outline: none;
  margin-bottom: 28px;

  svg {
    fill: ${Colors.grey_blue_2};
    transition: fill ${TRANSITION_TIME};
  }

  &.active,
  &:focus,
  &:hover {
    svg {
      fill: ${Colors.white};
    }
  }
`;

export const StyledTooltipContent = styled.div`
  ${Fonts.caption}

  p {
    ${Fonts.body}
    padding: 0;
    margin: 0;
  }
`;

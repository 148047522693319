import React, { useCallback, useRef } from 'react';
import { DraftailEditor, ENTITY_TYPE } from '@whispir/draftail';
import { EditorState, Modifier, RawDraftContentState } from 'draft-js';
import { UIVariable, UIVariableGroup } from '@whispir/variables';
import { VariableSelectorPopover } from '../../Molecules/VariableSelectorPopover';
import { TovHighlightEntityType } from '../draftailUtils';
import { DropdownAdornment } from '../../Atoms/Forms';
import { Icons } from '../../Foundation/Icons';
import { createLinkSource, Link } from '../DraftailLink';
import { TovHighlight } from '../DraftailTovHighlight';
import { DraftailBlock, DraftailInlineStyle } from './draftailTypes';
import { StyledDraftailEditorWrapper } from './DraftailEditorWithVariables.styles';
import { useControlled, useLegacyFunctionality } from './hooks';
import { VariablePill } from './VariablePill';

type Props = {
  className?: string;
  ariaLabel?: string;
  label?: string;
  inlineStyles?: Array<DraftailInlineStyle>; // standard draftail option, will be passed straight through
  blockTypes?: Array<DraftailBlock>; // standard draftail option, will be passed straight through
  includeLinkEntity?: boolean;
  onSaveHandler: (raw: unknown) => void;
  placeholder: string;
  rawContentState: unknown | null;
  spellCheck?: unknown;
  variableGroups?: Array<UIVariableGroup>;
  variableButtonClassName?: string; // Use this to change the position of the button.
  readOnly?: boolean;
  controlled?: boolean;
  valueToUpdateOn?: unknown;
  hasFocus?: boolean; // This legacy functionality from DND around controlling whether the the variables are shown or not.
  // Defaults true, so you don't have to use it.
  // Can possibly also use it to actually control focus of the comps
};

/** *
 * DraftailEditorWithVariables
 *
 * This component is essentially a convenience wrapper around Draftail.
 *
 * What it is responsible for is:
 *  - Putting in the purple 'variable selector' button
 *  - Inserting selected variables styled properly.
 *
 * What is not responsible for is:
 *  - Converting to/from html
 *
 * Usage guide:
 *
 * - You can put standard Draftail blockTypes/inlineStyles in as you ordinarily would
 * - Use the `variableButtonClassName` to change the styling/position of the purple button
 * - Pass in a list of VariableGroups if you want to use Variables. If undefined the button won't show up.
 * - The onSaveHandler and rawContentState are both the JSON array draft-js data structure. This can be converted in to HTML.
 *
 *
 * WATCH OUT!
 *
 * The entity blocks being created by draftail/draftjs here are being saved to the workflow defintions!
 *
 */

/**
 * Notes about draftail/draft-js
 *
 * YOU CAN'T UPDATE DRAFT-JS AT THIS POINT!
 * https://github.com/springload/draftail/issues/213 There is a bug with draftail using versions of draft-js beyond 10.5
 *
 * draft-js is quite a difficult library to use - be warned.
 *
 * The documentation is not great.
 *
 * Draftail to its credit provides some good hints.
 *
 * The functionality that Draftail provides (toolbars, standard 'bold', 'italic') works fine, and is easy to use.
 *
 * However, for creating custom entities (like the Variable selection),  you will need to be referencing the draftjs api methods
 *
 * it looks like there might be a memory leak issue too:
 * https://github.com/facebook/draft-js/issues/2391
 *
 *
 *
 *
 * GENERAL TIPS FOR USE:
 *
 * - Use the draftail documentation for general examples of how to do things
 *  -- eg. https://www.draftail.org/docs/entities#sources
 *
 *
 *
 *
 *
 *
 *
 */

// This is the component to display the variable selection menu. ie. it is the _source_ of the new entity
// https://www.draftail.org/docs/entities#sources
// Note that it's a curried function, to give it access to the ref and variable groups
// Note that it has access to editorState, onComplete etc.
const createVariableSource = (
  variableGroups: Array<UIVariableGroup>,
  buttonRef
) => ({ editorState, entityType, onComplete }) => {
  const handleClose = useCallback(() => {
    // just close without doing anything
    onComplete();
  }, [onComplete]);

  const handleVariableSelect = useCallback(
    (selectedVariable: UIVariable) => {
      // do the stuff required to create an entitty

      const { type } = selectedVariable || {};

      // This is standard draft-js way of inserting content
      const contentState = editorState.getCurrentContent();
      const selection = editorState.getSelection();

      const isSystemVar = type === 'IUX';
      const newContentState = contentState.createEntity(
        entityType.type,
        'IMMUTABLE',
        {
          ...selectedVariable,
          variableType: isSystemVar ? 'SYSTEM_VAR' : 'FORM_VAR',
        }
      );
      const entityKey = newContentState.getLastCreatedEntityKey();

      const newContent = Modifier.replaceText(
        contentState,
        selection,
        `${selectedVariable.variableName}`,
        null,
        entityKey
      );

      const nextState = EditorState.push(
        editorState,
        newContent,
        'insert-characters'
      );

      onComplete(nextState);
    },
    [editorState, entityType.type, onComplete]
  );

  return buttonRef.current ? (
    <VariableSelectorPopover
      anchorEl={buttonRef.current}
      variableGroups={variableGroups}
      open
      onClose={handleClose}
      onVariableSelect={handleVariableSelect}
    />
  ) : null;
};

type SharedProps = Props & {
  parsedRawContentState: RawDraftContentState;
  bottomToolbar: React.MutableRefObject<(props: any) => JSX.Element>;
  entityTypes?: any;
};

// for tone of voice specific use case (dynamic highlighting)
const Controlled = ({
  className,
  label,
  ariaLabel,
  onSaveHandler,
  placeholder,
  parsedRawContentState,
  spellCheck,
  blockTypes,
  inlineStyles,
  readOnly = false,
  valueToUpdateOn,
  bottomToolbar,
  entityTypes,
}: SharedProps) => {
  const { editorState, handleEditorChange } = useControlled({
    onSaveHandler,
    parsedRawContentState,
    valueToUpdateOn,
  });

  return (
    <DraftailEditor
      ariaDescribedBy='foo'
      ariaLabel={ariaLabel || label}
      inlineStyles={inlineStyles}
      spellCheck={spellCheck}
      rawContentState={parsedRawContentState}
      blockTypes={blockTypes}
      onSave={onSaveHandler}
      placeholder={placeholder}
      className={className}
      bottomToolbar={bottomToolbar.current}
      readOnly={readOnly}
      entityTypes={entityTypes}
      editorState={editorState}
      onChange={handleEditorChange}
    />
  );
};

const UnControlled = ({
  className,
  label,
  ariaLabel,
  onSaveHandler,
  placeholder,
  parsedRawContentState,
  spellCheck,
  blockTypes,
  inlineStyles,
  readOnly = false,
  bottomToolbar,
  entityTypes,
}: SharedProps) => (
  <DraftailEditor
    ariaDescribedBy='foo'
    ariaLabel={ariaLabel || label}
    inlineStyles={inlineStyles}
    spellCheck={spellCheck}
    rawContentState={parsedRawContentState}
    blockTypes={blockTypes}
    onSave={onSaveHandler}
    placeholder={placeholder}
    className={className}
    bottomToolbar={bottomToolbar.current}
    entityTypes={entityTypes}
    readOnly={readOnly}
  />
);

export const DraftailEditorWithVariables = (props: Props): JSX.Element => {
  const {
    className,
    label,
    ariaLabel,
    rawContentState,
    variableGroups,
    variableButtonClassName,
    hasFocus = true,
    controlled,
    includeLinkEntity,
  } = props;
  const { parsedRawContentState } = useLegacyFunctionality({ rawContentState });

  const buttonRef = useRef<HTMLButtonElement>(null);
  const linkRef = useRef(null);
  const entityTypes = (() => {
    const variableEntity = {
      type: 'VARIABLE',
      source: createVariableSource(variableGroups || [], buttonRef),
      decorator: VariablePill,
    };

    const linkEntity = {
      type: ENTITY_TYPE.LINK,
      icon: (
        <span ref={linkRef}>
          <Icons.Link />
        </span>
      ),
      source: createLinkSource(linkRef),
      decorator: Link,
    };

    const tovHighlightEntity = {
      type: TovHighlightEntityType,
      // no source or icon needed
      decorator: TovHighlight,
    };

    return includeLinkEntity
      ? [variableEntity, linkEntity]
      : [variableEntity, tovHighlightEntity];
  })();

  // Note this very unconventional thing is neccessary.
  // It's because the DropdownAdornment uses a ref
  // We don't want to keep redeclaring this component function
  // (The repro for the problem this is fixing - is look at the VariableSelectorPopover for Variable Text Input)
  // If you can think of a tidier way to do this, feel free.
  // Warning - one problem with this is that if you later get rid of variables groups, this thing will not be remade.
  const bottomToolbar = useRef((props) => {
    return (
      <div>
        {variableGroups && !!variableGroups.length && (
          <DropdownAdornment
            ariaLabel={`Select variables for ${ariaLabel || label}`}
            className={`Draftail-dropdown-adornment ${variableButtonClassName}`}
            ref={buttonRef}
            onClick={() => props.onRequestSource('VARIABLE')}
          />
        )}
      </div>
    );
  });

  return (
    // Class name so you can target it from parents :)

    <StyledDraftailEditorWrapper
      className={`${className} Draftail-Wrapper ${hasFocus ? 'has-focus' : ''}`}
    >
      {controlled ? (
        <Controlled
          {...props}
          parsedRawContentState={parsedRawContentState}
          bottomToolbar={bottomToolbar}
          entityTypes={entityTypes}
        />
      ) : (
        <UnControlled
          {...props}
          parsedRawContentState={parsedRawContentState}
          bottomToolbar={bottomToolbar}
          entityTypes={entityTypes}
        />
      )}
    </StyledDraftailEditorWrapper>
  );
};

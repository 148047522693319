import React from 'react';
import { Illustrations } from '../../Foundation/Illustrations';
import { ErrorScreenProps } from './ErrorScreen.types';
import { MainWrapper } from './ErrorScreen.styles';

export const ErrorScreen = ({
  banner = <Illustrations.ListDesaturated />,
  title = 'Looks like something went wrong...',
  errorMessage = 'We encountered an error while trying to display this page. We’re currently working on fixing this issue. If needed, please reach out via the Intercom for assistance.',
  backButton = null,
}: ErrorScreenProps) => {
  return (
    <MainWrapper className='error-wrapper'>
      {banner}
      <span className='headline'>{title}</span>
      <span className='body'>{errorMessage}</span>
      {backButton}
    </MainWrapper>
  );
};

import styled from 'styled-components';
import { Colors } from '../../Foundation/Colors';
import { Fonts } from '../../Foundation/Fonts';

export const ToggleSwitchWrapper = styled.div`
  .MuiFormControlLabel-label {
    ${Fonts.bodySmall};
    color: ${Colors.grey_blue};
  }

  .MuiSwitch-switchBase {
    .MuiSwitch-thumb {
      color: ${Colors.background};
    }
    & + .MuiSwitch-track {
      background-color: ${Colors.grey_blue_2};
      opacity: 1;
    }
  }

  .MuiSwitch-switchBase.Mui-checked {
    .MuiSwitch-thumb {
      color: ${Colors.accent};
    }
    & + .MuiSwitch-track {
      background-color: ${Colors.accent_4};
      opacity: 1;
    }
  }

  .MuiFormControlLabel-root {
    display: flex;
    justify-content: space-between;
    margin-left: 0px;
    margin-right: 0px;
    width: 100%;
  }

  .MuiFormHelperText-root {
    ${Fonts.link};
    color: ${Colors.grey_blue};
    text-align: left;
  }
`;

import React, { useCallback } from 'react';
import {
  TableCell,
  TableHead,
  TableSortLabel,
  TableRow as MUITableRow,
} from '@material-ui/core';
import { Checkbox } from '../../Atoms/Forms';
import { Icon } from '../../Atoms/Icon';
import { Button } from '../../Atoms/Navigations';
import { ResponsiveLabel } from '../../Molecules/ResponsiveLabel';
import {
  SelectMode,
  SelectionState,
  DataTableTypedColumn,
  BatchAction,
  RowData,
  SELECTION_STATE,
} from './QueryDataTable.types';

const HeaderCheckbox = (props: {
  selectionState: SelectionState;
  onClick: () => void;
}) => {
  const { selectionState, onClick } = props;
  return (
    <Checkbox
      isIndeterminated={selectionState === SELECTION_STATE.SOME}
      isChecked={selectionState !== SELECTION_STATE.NONE}
      onChange={onClick}
    />
  );
};

export const EnhancedTableHead = <T extends RowData>(props: {
  columns: Array<DataTableTypedColumn<T>>;
  selectMode: SelectMode;
  selectAllClick: (mode: boolean) => void;
  selectAllInPageClick: () => void;
  selectionState: SelectionState;
  columnSortKey: keyof T;
  columnSortAscending: boolean;
  onSortClick: (columnKey: string) => void;
  hasRowActionsColumn: boolean;
  selectedRows: Array<string | number>;
  batchActions: Array<BatchAction<T>>;
  totalNumberOfRows: number;
  isDisabledSort?: boolean;
}) => {
  const {
    columns,
    selectMode,
    selectAllClick,
    selectAllInPageClick,
    selectionState,
    columnSortKey,
    columnSortAscending,
    onSortClick,
    hasRowActionsColumn,
    selectedRows,
    batchActions,
    totalNumberOfRows,
    isDisabledSort,
  } = props;

  const showBatchActions =
    selectMode === 'multi' && selectionState !== SELECTION_STATE.NONE;
  const showDeselectAllButton = selectionState === SELECTION_STATE.ALL_PAGES;

  const handleSelectAllClick = useCallback(() => {
    selectAllClick(true);
  }, [selectAllClick]);

  const handleDeselectAllClick = useCallback(() => {
    selectAllClick(false);
  }, [selectAllClick]);

  return (
    <TableHead>
      <MUITableRow></MUITableRow>
      <MUITableRow>
        {selectMode === 'multi' ? (
          <TableCell className='checkbox-cell'>
            <HeaderCheckbox
              data-testid='master-checkbox'
              selectionState={selectionState}
              onClick={selectAllInPageClick}
            />
          </TableCell>
        ) : null}

        {showBatchActions ? (
          <TableCell colSpan={columns.length}>
            <span className='n-rows-selected-label'>
              {selectedRows.length} Rows selected:
            </span>
            {showDeselectAllButton ? (
              <Button
                className='select-all-button'
                variant='link'
                onClick={handleDeselectAllClick}
                text='Deselect all rows'
              />
            ) : (
              <Button
                className='select-all-button'
                variant='link'
                onClick={handleSelectAllClick}
                text={`Select all ${totalNumberOfRows} rows`}
              />
            )}
            {batchActions.map(({ label, icon, action }) => {
              return (
                <Button
                  className='batch-action-button'
                  variant='link'
                  type='secondary'
                  key={label}
                  startIcon={icon}
                  text={label}
                  onClick={(e) => {
                    action(selectedRows, e, selectAllInPageClick);
                  }}
                />
              );
            })}
          </TableCell>
        ) : null}

        {!showBatchActions &&
          columns.map((column) => {
            const {
              field,
              headerName,
              disableColumnSort,
              truncateLongText,
              maxWidth,
            } = column;
            const isActiveSort = columnSortKey === field;

            const headerLabel =
              truncateLongText && maxWidth ? (
                <ResponsiveLabel
                  className='truncatedable'
                  text={headerName}
                  maxWidth={maxWidth}
                />
              ) : (
                headerName
              );

            return (
              <TableCell key={field} className={`${field}-cell`}>
                <TableSortLabel
                  active={!disableColumnSort && !isDisabledSort && isActiveSort}
                  direction={columnSortAscending ? 'asc' : 'desc'}
                  onClick={() =>
                    !disableColumnSort && !isDisabledSort && onSortClick(field)
                  }
                  IconComponent={({ className, ...props }) => (
                    <Icon
                      icon='DownArrow'
                      className={`${className} sort-arrow-icon`}
                      {...props}
                    />
                  )}
                >
                  {headerLabel}{' '}
                  {!disableColumnSort && !isDisabledSort && !isActiveSort && (
                    <Icon icon='Sort' className='sort-icon' />
                  )}
                </TableSortLabel>
              </TableCell>
            );
          })}

        {hasRowActionsColumn && <TableCell className='row-action-cell' />}
      </MUITableRow>
    </TableHead>
  );
};

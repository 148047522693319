import styled from 'styled-components';
import { Colors } from '../../Foundation/Colors';
import { Fonts } from '../../Foundation/Fonts';

export const StyledHeaderTitle = styled.div`
  fieldset {
    border: none;
  }

  .edit-icon {
    width: 48px;
  }

  & .MuiOutlinedInput-root {
    min-width: 125px;
    max-width: 435px;
    width: min-content;
  }

  & input {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    ${Fonts.headline};

    :focus {
      + .edit-icon {
        display: none;
        pointer-events: none;
      }
    }
  }
`;

export const StyledEditButton = styled.div`
  display: flex;
  width: 48px;
  height: 48px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  position: absolute;

  svg {
    fill: ${Colors.grey_blue_4};
  }

  :hover {
    background-color: ${Colors.grey_blue_1};
  }
`;

export const StyledStaticTitle = styled.div`
  ${Fonts.subheadSerif};
`;

import React from 'react';
import { Snackbar as SnackbarContent } from '../../Molecules/Snackbar';
import {
  useNotificationDispatch,
  useNotificationState,
} from './NotificationsProvider';
import { StyledSnackbar } from './Notification.styles';

export const Notification = () => {
  const state = useNotificationState();
  const { deleteNotification } = useNotificationDispatch();

  const handleClose = () => deleteNotification();

  if (!state.notifications.length) {
    return null;
  }
  const [
    { id, message, type, autoHideDuration = 6000, onClose = handleClose },
  ] = state.notifications;

  return (
    <StyledSnackbar
      key={id}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open
      autoHideDuration={autoHideDuration}
      onClose={onClose}
      className='wrapper'
    >
      <SnackbarContent
        severity={type}
        message={message}
        onClose={handleClose}
      />
    </StyledSnackbar>
  );
};

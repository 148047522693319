import styled from 'styled-components';
import { Colors } from '../../Foundation/Colors';
import { Fonts } from '../../Foundation/Fonts';

export const HeaderWrapper = styled.div`
  .illustrationWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;

    svg {
      width: 120px;
      height: 120px;
    }
  }

  .titleWrapper {
    ${Fonts.headline};
    color: ${Colors.grey_blue};
    margin: 0;
  }
`;

export const ContentWrapper = styled.p`
  ${Fonts.body};
  color: ${Colors.grey_blue};
  margin: 24px 0;
`;

export const FooterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    cursor: pointer;
  }

  > div:first-child {
    margin-right: 20px;
    padding-top: 8px;
  }
`;

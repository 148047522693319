import React from 'react';

import { Button } from '../../../Atoms/Navigations';
import { ActionProps } from '../Header.types';

export const PrimaryAction = ({
  text,
  disabled,
  action,
}: ActionProps): JSX.Element => (
  <Button
    variant='button'
    size='large'
    text={text}
    disabled={disabled}
    onClick={action}
  />
);

/**
 * FEATURE TOGGLE
 *
 * Notes:
 * - Toggles are initialised in the App entry point, in order to guarantee they are available within components
 * - Toggles will not be available at compile time - must be used within called methods
 * - New toggles must be created for every region
 * - Toggles should always default to FALSE
 *
 * Toggle Format:
 * --------------
 * "toggle-name": {
 *   "isEnabled": false,
 *   "data": {
 *     // data is optional, any valid JSON allowed
 *   }
 * }
 *
 *
 * Usage:
 * ------
 * import { isToggleEnabled } from 'utils/featureToggles';
 * if (isToggleEnabled('my-feature')) {
 *   ...
 * }
 */

import localData from './public/index';

/**
 * Define property that can be overridden using a value in local-storage
 * @param {string} storageKey
 * @param {*} value
 * @returns {*}
 */
const overridable = (storageKey, value) => {
  const localValue = localStorage.getItem(storageKey);
  if (localValue) {
    console.info(
      `%c🚀 Using local override for %c${storageKey} [${localValue}]`,
      'color: lightBlue;',
      'color: yellow;'
    );
  }

  return localValue || value;
};

const getOverrideKey = (toggleName) => `toggle:${toggleName}`;
let toggles;

/**
 * Load a set of feature toggles
 * @param {string} region
 */
const loadToggles = (region) => {
  return localData[region.toUpperCase()];
};

/**
 * Parse a raw feature toggle and check for local override
 * @param {string} key
 * @param {any} value
 * @returns {Object}
 */
const parseToggle = (key, value) => {
  const override = overridable(getOverrideKey(key), value.isEnabled);

  return {
    ...value,
    isEnabled: override.toString() === 'true',
  };
};

/**
 * Load region-specific feature toggle set
 * Checks for localStorage overrides for each toggle
 *
 * @example
 *  // Override state for toggle: 'show-logo'
 *  localStorage.setItem('toggle:show-logo', true);
 *
 *  Note: overriding value is NOT currently supported
 */
const initFeatureToggles = (region) => {
  try {
    const features = loadToggles(region);
    toggles = Object.entries(features).reduce(
      (items, [key, value]) => ({
        ...items,
        [key]: parseToggle(key, value),
      }),
      {}
    );
  } catch (err) {
    console.error(err);
    throw new Error(
      `Failed to load/parse features for region '${region.toUpperCase()}'`
    );
  }
};

/**
 * Extract a toggle
 *
 * @param {string} toggleName
 * @returns {FeatureToggle}
 * @throws {Error} when toggle not defined
 */
const getToggle = (toggleName) => {
  if (!toggles.hasOwnProperty(toggleName)) {
    throw new Error(`Feature Toggle '${toggleName}' not defined`);
  }

  return toggles[toggleName];
};

/**
 * Read a feature toggle state. Defaults to FALSE on error
 * @param {string} toggleName
 * @returns {boolean}
 */
const isToggleEnabled = (toggleName) => {
  try {
    return getToggle(toggleName).isEnabled;
  } catch (err) {
    return false;
  }
};

/**
 * Extract available toggle data
 * @param {string} toggleName
 * @returns {Object}
 */
const getToggleData = (toggleName) => {
  try {
    return getToggle(toggleName).data || {};
  } catch (err) {
    return {};
  }
};

const isDemoEnabled = () => {
  const demoToggle = isToggleEnabled('demo-only');
  const {
    location: { hostname },
  } = window;

  return (
    demoToggle &&
    (hostname.includes('.us.') || hostname.includes('.whispirdev.'))
  );
};

// Public API
module.exports = {
  initFeatureToggles,
  isToggleEnabled,
  getToggleData,
  isDemoEnabled,
};

import {
  expressionGenerators,
  operatorIds,
  variableIds,
} from '@whispir/expression-helper';
import { startOfDay } from 'date-fns';

const {
  generateStringConstantExpression,
  generateDateConstantExpression,
  generateDateBetweenExpression,
  generateNumberBetweenExpression,
  generateDateInTheNextExpression,
  generateNumberConstantExpression,
  generateDateHasPassedExpression,
} = expressionGenerators;

const { BETWEEN, IN_THE_NEXT, HAS_PASSED } = operatorIds;

const { NUMBER, DATE } = variableIds;

export const generateExpression = (
  { operator, operand },
  value: unknown
): unknown => {
  switch (operator) {
    case BETWEEN:
      switch (operand.valueType) {
        case DATE: {
          return generateDateBetweenExpression({
            leftValue: value,
            beforeValue: operand.context.before.value,
            afterValue: operand.value,
          });
        }
        case NUMBER:
        default:
          return generateNumberBetweenExpression({
            leftValue: value,
            greaterThanValue: operand.value,
            lessThanValue: operand.context.lessThan.value,
          });
      }
    case IN_THE_NEXT:
      return generateDateInTheNextExpression({
        leftValue: value,
        durationValue: operand.value,
        durationUnit: operand.context.durationUnit,
      });
    case HAS_PASSED:
      return generateDateHasPassedExpression({
        leftValue: value,
        durationValue: operand.value,
        durationUnit: operand.context.durationUnit,
      });
    default: {
      let leftValue = value;
      if (operand.valueType === 'date')
        leftValue = startOfDay(new Date(value as string)).toISOString();

      const expressionParams = {
        operator,
        leftValue,
        rightValue: operand.value,
      };

      switch (operand.valueType) {
        case 'date':
          return generateDateConstantExpression(expressionParams);
        case 'number':
          return generateNumberConstantExpression(expressionParams);
        default:
          return generateStringConstantExpression(expressionParams);
      }
    }
  }
};

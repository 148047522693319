import React from 'react';
import { Icons } from '../../../Foundation/Icons';

import { HeaderNavigationTypes } from '../GlobalNavigation.types';

export const HeaderNavigation = ({
  headerLink,
}: HeaderNavigationTypes): JSX.Element => {
  const path = window.location.pathname;
  const className = path === headerLink ? 'active' : '';

  return (
    <a key={headerLink} className={className} href={headerLink}>
      <Icons.LogoDots />
    </a>
  );
};

import React from 'react';

import {
  RadioGroup,
  FormHelperText,
  FormLabel,
  FormControl,
  FormControlLabel,
} from '@material-ui/core'; // TODO: use RadioList from ui-lib-v2

import { RadioButtonListWrapper, StyledRadio } from './FormRadioList.style';

type Props = {
  label: string;
  helperText: string;
  requiredField: string;
  paddingTop: string;
  paddingBottom: string;
  optionsPicker: unknown;
};

export const FormRadioList = ({
  label,
  helperText,
  requiredField,
  paddingTop,
  paddingBottom,
  optionsPicker: defaultValue,
}: Props) => {
  const defaultListItems =
    typeof defaultValue === 'string' ? JSON.parse(defaultValue) : defaultValue;
  const defaultCheckedItem = defaultListItems.find(
    ({ checked }) => checked === 'true'
  );

  const { id: defaultCheckedValue } = defaultCheckedItem || { id: '' };

  return (
    <RadioButtonListWrapper
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
    >
      <FormControl component='fieldset' required={requiredField === 'true'}>
        <FormLabel component='legend'>{label}</FormLabel>
        <RadioGroup aria-label={label} name={label} value={defaultCheckedValue}>
          {defaultListItems.map(({ id, text, checked }) => (
            <FormControlLabel
              value={id}
              control={<StyledRadio />}
              label={text}
              key={id}
            />
          ))}
        </RadioGroup>
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
    </RadioButtonListWrapper>
  );
};

import React from 'react';

import { Tooltip } from '../../Dialogs/Tooltip';
import { Icon } from '../../Icon';
import { ChipProps } from './Chip.types';
import { StyledChip } from './Chip.styles';

const DEFAULT_MAX_WIDTH = 200;

export const Chip = React.forwardRef<HTMLDivElement, ChipProps>(
  (props: ChipProps, ref) => {
    const {
      className = '',
      color,
      text,
      tooltip,
      onDeleteClick,
      tooltipPlacement = 'left',
      onClick,
      maxWidth = DEFAULT_MAX_WIDTH,
      icon,
      labelOverride,
      size = 'small',
    } = props;

    // Always show the tooltip if the tooltip text is provided
    // Disable the tooltip when the maximum length is not exceeded.
    // TODO: Find a better way to derive value of disableHoverListener
    // NOTE: font-size of chip is 14px divided by 2 for width of each letter === 7
    // 200 is max-width of Chip minus ellipsis width === 3 * 7 === 21
    const tooltipText = tooltip || text || '';
    const maxChipTextLengthExceeded =
      text && text.length * 7 > maxWidth - 7 * 3;
    const disableHoverListener = tooltip ? false : !maxChipTextLengthExceeded;

    return (
      <Tooltip
        title={tooltipText}
        disableHoverListener={disableHoverListener}
        arrow
        ariaLabel={tooltipText}
        placement={tooltipPlacement}
      >
        <StyledChip
          onClick={onClick}
          className={`${color} ${className} ${onClick ? 'clickable' : ''}`}
          label={labelOverride || text}
          onDelete={onDeleteClick}
          icon={icon && <Icon icon={icon} />}
          ref={ref}
          size={size}
        />
      </Tooltip>
    );
  }
);

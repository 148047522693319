import React, { ReactNode } from 'react';
import { formatDistanceToNowStrict } from 'date-fns';
import { Illustrations } from '../../Foundation/Illustrations';
import { Icon, IconTypes } from '../../Atoms/Icon';
import { IconButton } from '../../Atoms/Navigations/IconButton';
import { PopoverMenu } from '../../Molecules/PopoverMenu';
import { StyledTemplateCard, StyledNewCard } from './TemplateCard.styles';

export type TemplateCardProps = {
  title: string;
  variant?: 'basic' | 'templates' | 'workspace' | 'new';
  channel?: 'sms' | 'email' | 'web' | 'webform';
  lastUpdated?: string;
  isActive?: boolean;
  children?: ReactNode;
  onPreviewClick?: () => void; // click on the search icon
  onEditMenuClick?: () => void; // click on Edit menu
  onDuplicateMenuClick?: () => void; // click on Duplicate menu
  onDeleteMenuClick?: () => void; // click on Delete menu
  onNewCardClick?: () => void; // click on New Card
};

const channelIcon = {
  sms: 'Message',
  email: 'Email',
  web: 'Web',
  webform: 'WebForm',
};

export const TemplateCard = ({
  children,
  title,
  variant = 'basic',
  channel,
  lastUpdated,
  isActive = false,
  onPreviewClick = () => null,
  onEditMenuClick = () => null,
  onDuplicateMenuClick = () => null,
  onDeleteMenuClick = () => null,
  onNewCardClick = () => null,
}: TemplateCardProps) => {
  const linkItems = [
    {
      title: 'Edit',
      iconName: 'EditPen',
      action: onEditMenuClick,
    },
    {
      title: 'Delete',
      iconName: 'Delete',
      action: onDeleteMenuClick,
    },
    {
      title: 'Duplicate',
      iconName: 'Copy',
      action: onDuplicateMenuClick,
    },
  ];

  const getLastUpdatedCopy = () => {
    let lastUpdatedCopy = '';

    if (lastUpdated) {
      const updatedCopy = formatDistanceToNowStrict(new Date(lastUpdated), {
        addSuffix: true,
      });
      // set 1 minute as the smallest increment
      lastUpdatedCopy = updatedCopy.includes('second')
        ? '1 minute ago'
        : updatedCopy;
    }

    return lastUpdatedCopy;
  };

  return (
    <StyledTemplateCard isActive={isActive}>
      <div className='preview'>
        {variant === 'new' ? (
          <StyledNewCard onClick={onNewCardClick}>
            <Illustrations.Plus />
          </StyledNewCard>
        ) : (
          children
        )}
      </div>
      <div className='details'>
        <div className='details-title'>
          {title}
          {variant === 'templates' && channel ? (
            <div className={['channel', channel || ''].join(' ')}>
              <Icon icon={channelIcon[channel] as IconTypes} />
            </div>
          ) : null}
        </div>
        {/* show only the Last updated date for Templates and User's workspace template */}
        {['templates', 'workspace'].includes(variant) ? (
          <div className='details-date-actions'>
            Updated {getLastUpdatedCopy()}
            {variant === 'templates' ? (
              <PopoverMenu
                linkItems={linkItems}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <IconButton
                  size='medium'
                  icon='Elipsis'
                  color='primary'
                  ariaLabel='template-card-menu'
                />
              </PopoverMenu>
            ) : null}
          </div>
        ) : null}
      </div>
      {variant !== 'new' ? (
        <div className='magnifier' onClick={onPreviewClick}>
          <Icon icon='Eye' />
        </div>
      ) : null}
    </StyledTemplateCard>
  );
};

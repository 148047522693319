import React from 'react';

import { StyledFooterWrapper } from './RichTextFooterBlock.style';
import { RichTextFooterBlockProps } from './RichTextFooterBlock.types';

export const RichTextFooterBlock = ({
  textColor,
  backgroundColor,
  logoLink,
  logoAltText,
  logoWidth,
  textContent,
  linkGenerator: defaultValue,
  paddingTop,
  paddingBottom,
}: RichTextFooterBlockProps): JSX.Element => {
  const defaultLinkItems =
    typeof defaultValue === 'string' ? JSON.parse(defaultValue) : defaultValue;

  return (
    <StyledFooterWrapper
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      textColor={textColor}
      backgroundColor={backgroundColor}
    >
      <div className='logo-wrapper'>
        {logoLink ? (
          <img src={logoLink} alt={logoAltText} width={logoWidth} />
        ) : null}
      </div>
      <div className='text-content-wrapper'>{textContent}</div>
      <div className='links-wrapper'>
        {defaultLinkItems &&
          defaultLinkItems.map(({ id, link, text }) => (
            <a src={link} key={id}>
              {text}
            </a>
            // TODO WHAT'S MEANT TO BE HAPPNIGN HERE?
          ))}
      </div>
    </StyledFooterWrapper>
  );
};

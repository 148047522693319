import styled from 'styled-components';
import { Colors } from '../../Foundation/Colors';
import { Elevations } from '../../Foundation/Elevations';
import { Fonts } from '../../Foundation/Fonts';
import { TRANSITION_TIME } from '../../Foundation/Spacing';

import { StyledDashboardCardProps } from './DashboardCard.types';

export const StyledDashboardCard = styled.div<StyledDashboardCardProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 240px;
  height: 260px;
  background-color: ${Colors.white};
  border-radius: 8px;
  box-sizing: border-box;
  padding: 24px;
  box-shadow: ${Elevations.elevation_1};
  transition: all ${TRANSITION_TIME};
  position: relative;
  cursor: pointer;

  z-index: ${({ hasFocus }) => hasFocus && '1000'};

  &:hover {
    box-shadow: ${Elevations.elevation_3};
    transform: translateY(-4px);
  }

  .card-header {
    svg {
      height: 28px;
      width: 28px;
      fill: ${Colors.grey_blue_4};
    }
  }

  .card-content {
    flex-grow: 2;
    margin-top: 22px;
    .card-title {
      ${Fonts.subheadSerif};
      padding: 24px 0 8px;
    }
    .card-description {
      ${Fonts.body};
      color: ${Colors.grey_blue_4};
      padding: 16px 0;
    }

    .MuiTooltip-tooltip {
      ${Fonts.body}
    }
  }

  .card-footer {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    margin-bottom: -12px;
  }
`;

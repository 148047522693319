import React, { useCallback } from 'react';

import { TableCell, TableRow as MUITableRow } from '@material-ui/core';
import { PopoverMenuSimpleIcon } from '../../Molecules/PopoverMenuSimpleIcon';

import { Checkbox } from '../../Atoms/Forms';
import { typedMemo } from '../../Utils/typedMemo';
import {
  ActionButtonProps,
  RowData,
  DataTableRowProps,
} from './DataTable.types';

const ActionButton = <T extends RowData>(props: ActionButtonProps<T>) => {
  const { actions, rowData } = props;

  return (
    <>
      <PopoverMenuSimpleIcon
        icon='Elipsis'
        menuItems={actions
          .filter((action) => {
            const { check } = action;
            let renderAction = true;

            if (typeof check === 'function') renderAction = check(rowData);
            if (renderAction) return action;
          })
          .map(({ action, label }) => {
            return {
              label,
              onClick: () => {
                action(rowData);
              },
            };
          })}
      />
    </>
  );
};

export const TableRow = typedMemo(
  <T extends RowData>(props: DataTableRowProps<T>) => {
    const {
      rowData,
      isSelected,
      selectMode,
      onRowClick,
      columns,
      defaultRenderMethod,
      rowActions,
      isSelectedRow,
    } = props;
    const handleRowClick = useCallback(
      (isCheckbox: boolean) => {
        onRowClick(rowData, isCheckbox);
      },
      [onRowClick, rowData]
    );

    return (
      <MUITableRow
        key={rowData.id}
        onClick={() => handleRowClick(false)}
        className={isSelected || isSelectedRow ? 'row-selected' : ''}
      >
        {selectMode === 'multi' && (
          <TableCell
            className='checkbox-cell'
            onClick={(e) => e.stopPropagation()}
            role='data-table-row-checkbox'
          >
            <Checkbox
              onChange={() => handleRowClick(true)}
              isChecked={isSelected}
            />
          </TableCell>
        )}

        {columns.map((columnData) => {
          const { field, renderCell } = columnData;
          const cellData = rowData[columnData.field];
          const renderMethod = renderCell || defaultRenderMethod;

          return (
            <TableCell
              key={`${rowData.id}-${field}`}
              className={`${field}-cell`}
            >
              {renderMethod(cellData, rowData)}
            </TableCell>
          );
        })}

        {rowActions.length > 0 && (
          <TableCell className='row-action-cell'>
            <ActionButton actions={rowActions} rowData={rowData} />
          </TableCell>
        )}
      </MUITableRow>
    );
  }
  // NOTE: We can optimize the table by memoizing it
  // Be aware that this may cause some issues when certain data is changed
  // (prevProps, nextProps) => {
  //   return (
  //     prevProps.rowData.id === nextProps.rowData.id ||
  //     prevProps.selectMode === nextProps.selectMode ||
  //     prevProps.isSelected === nextProps.isSelected
  //   );
  // }
);

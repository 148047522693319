import { graphQL } from '../helpers/api';

export const stripeCustomerQuery = (client) =>
  graphQL(
    client,
    `{
      stripeSelfServePortal(returnUrl: "${window.location.origin}/signin/dashboard"){
        status
        errorText
        stripePortalUrl
      }
    }`
  );

export const getStripeCustomer = async (client, setLoading) => {
  setLoading(true);

  const customer = await stripeCustomerQuery(client);

  if (customer && customer.data && customer.data.stripeSelfServePortal) {
    const {
      data: {
        stripeSelfServePortal: { stripePortalUrl },
      },
    } = customer;

    window.location.href = stripePortalUrl;
  } else {
    setLoading(false);
  }
};

import React, { forwardRef } from 'react';

import { Icon, IconTypes } from '../../Icon/Icon';
import { StyledIconButton } from './StyledIconButton.style';

export type IconButtonProps = {
  id?: string;
  icon: IconTypes;
  size?: 'small' | 'medium';
  color?: 'primary' | 'default';
  disabled?: boolean;
  onClick?: () => void;
  className?: string;
  // possibly add variant
  ariaLabel?: string;
};
export const IconButton = forwardRef((props: IconButtonProps, ref) => {
  const {
    id,
    icon,
    size = 'medium',
    color = 'default',
    disabled = false,
    className = '',
    onClick = () => undefined,
    ariaLabel,
  } = props;
  return (
    <StyledIconButton
      id={id}
      className={`${size} ${color} ${className}`}
      disabled={disabled}
      onClick={onClick}
      ref={ref}
      data-testid='styled-icon-btn'
      aria-label={ariaLabel}
    >
      <Icon icon={icon} />
    </StyledIconButton>
  );
});

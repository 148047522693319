import React, { useEffect, useRef, useState } from 'react';

import { VariableTextInput } from '../../../DraftailComponents/VariableTextInput';
import { DropdownProps } from '../../../Atoms/Forms/Dropdown/Dropdown.types';

import { OptionsPicker } from '../../../Molecules/OptionsPicker/OptionsPicker';
import { LegacyOptionsPicker } from '../../../Molecules/LegacyOptionsPicker/LegacyOptionsPicker';
import { LinkGenerator } from '../../../Molecules/LinkGenerator/LinkGenerator';
import { PositionSelector } from '../../../Molecules/PositionSelector/PositionSelector';
import { GoogleAutoSuggest } from '../../../Molecules/GoogleAutoSuggest';
import { DateFormatPicker } from '../../../Atoms/DateFormatPicker';
import { Slider } from '../../../Atoms/Slider';
import { Dropdown as DropdownUILib } from '../../../Atoms/Forms/Dropdown';
import { ColorPicker } from '../../../Atoms/ColorPicker';
import { ToggleSwitch } from '../../../Atoms/ToggleSwitch';
import { TextField } from '../../../Atoms/Forms/TextField';
import { Checkbox as WhispirCheckbox } from '../../../Atoms/Forms/Checkbox';
import { FieldId } from '../../../Molecules/FieldId';
import { Divider } from '../../../Atoms/Layout';
import { DatePicker } from '../../../Atoms/Forms/DatePicker';
import { DatePickerFromTo } from '../../../Atoms/Forms/DatePickerFromTo';

import { AlignmentSelector } from '../../../Atoms/AlignmentSelector';
import { SocialIconsOptionsPicker } from '../../SocialIconsOptionsPicker';

import {
  SliderProps,
  DynamicComponentProps,
  TitleDividerProps,
  TextFieldProps,
  DateFormatPickerProps,
} from './DynamicComponent.types';
import { DropdownWrapper } from './DynamicComponent.styles';

const OptionsPickerForCheckbox = (props): JSX.Element => (
  <LegacyOptionsPicker
    {...props}
    filledTextAreaStyle
    optionsType='checkboxes'
  />
);
const OptionsPickerFilledStyleInputs = (props): JSX.Element => (
  <LegacyOptionsPicker
    {...props}
    filledTextAreaStyle
    optionsType='radio-buttons/dropdown-menu'
  />
);
const OptionsPickerTwoButtonLabels = (props): JSX.Element => (
  <LegacyOptionsPicker
    {...props}
    filledTextAreaStyle
    optionsType='radio-buttons/dropdown-menu'
    isButtonPicker
  />
);
const DateFormatPickerFilled = (props: DateFormatPickerProps): JSX.Element => (
  <DateFormatPicker {...props} filled />
);
const TextFieldFilled = (props: TextFieldProps): JSX.Element => (
  <TextField {...props} variant='filled' />
);

// this should probably be brought out into its own component file
const TitleDivider = ({
  label,
  paddingControl,
  withDividerLine = true,
  withLabelText = true,
  className,
}) => (
  <>
    <Divider
      dividerType={withDividerLine ? 'solid' : 'none'}
      isHorizontal
      paddingControl={paddingControl}
      className={className}
    />
    {withLabelText && (
      <div style={{ padding: '16px 0' }} className='divider-title'>
        {label.toUpperCase()}
      </div>
    )}
  </>
);

const TitleDividerNoLine = (props: TitleDividerProps): JSX.Element => (
  <TitleDivider {...props} paddingControl='8px 0' withDividerLine={false} />
);
const TitleDividerLine = (props: TitleDividerProps): JSX.Element => (
  <TitleDivider {...props} paddingControl='8px 0' withLabelText={false} />
);
const PaddingControl16px = (props: TitleDividerProps): JSX.Element => (
  <TitleDivider
    {...props}
    paddingControl='16px 0'
    withDividerLine={false}
    withLabelText={false}
  />
);
const PaddingControl8px = (props: TitleDividerProps): JSX.Element => (
  <TitleDivider
    {...props}
    paddingControl='8px 0'
    withDividerLine={false}
    withLabelText={false}
  />
);
const PaddingControl4px = (props: TitleDividerProps): JSX.Element => (
  <TitleDivider
    {...props}
    paddingControl='4px 0'
    withDividerLine={false}
    withLabelText={false}
  />
);
const DisableFullWidthProperty = (): JSX.Element => (
  <span className='disable-full-width-property' />
);
const Slider480 = (props: SliderProps): JSX.Element => (
  <Slider {...props} maxValue={480} />
);
const Slider800 = (props: SliderProps): JSX.Element => (
  <Slider {...props} maxValue={800} />
);
const Slider600 = (props: SliderProps): JSX.Element => (
  <Slider {...props} maxValue={600} />
);
const Slider480MinRestrict200 = (props: SliderProps): JSX.Element => (
  <Slider {...props} maxValue={480} minValue={200} />
);
const LinkGeneratorWithoutColorPicker = (props): JSX.Element => (
  <LinkGenerator {...props} hasBackgroundColorPicker={false} />
);
const Dropdown = (
  props: DynamicComponentProps & DropdownProps
): JSX.Element => {
  const { label } = props;
  let options: Array<any> = [];
  let changedLabel = label;
  if (label) {
    options = (label as string).replace(/^[^__]+__/, '').split(',');
    changedLabel = (label as string).replace(/[__].*$/, '');
  }
  return (
    <DropdownWrapper>
      <DropdownUILib {...props} options={options} label={changedLabel} />
    </DropdownWrapper>
  );
};

// Just reproducing the old version of the checkbox
const Checkbox = ({ label, onChange, defaultValue }) => {
  const [{ checked }, setState] = useState({
    checked: defaultValue === 'true',
  });

  const isFirstRun = useRef(true);

  const onCheck = () => {
    setState({ checked: !checked });
    if (onChange) {
      onChange(!checked);
    }
  };

  useEffect(() => {
    const { current } = isFirstRun;
    if (current) {
      isFirstRun.current = false;
      return;
    }
    setState({ checked: defaultValue === 'true' });
  }, [defaultValue]);

  return (
    <WhispirCheckbox label={label} isChecked={checked} onChange={onCheck} />
  );
};

const Slider10Step = (props: SliderProps): JSX.Element => (
  <Slider {...props} step={10} />
);
const TextAlignmentSelector = (props): JSX.Element => (
  <AlignmentSelector includeCenterAlignment iconType='text' {...props} />
);

const TextPosition = PositionSelector;
const IconAlignmentSelector = (props): JSX.Element => (
  <AlignmentSelector
    {...props}
    includeCenterAlignment={false}
    iconType='leftright'
  />
);
const ItemsAlignmentSelector = (props): JSX.Element => (
  <AlignmentSelector {...props} includeCenterAlignment iconType='items' />
);

const DateTimePicker = (props): JSX.Element => (
  <DatePicker {...props} filled includeTime />
);

const DateTimePickerFromTo = (props): JSX.Element => (
  <DatePickerFromTo includeTime {...props} />
);

export const FormControls = {
  Checkbox,
  ColorPicker,
  DateFormatPicker,
  TextField,
  TextPosition,
  Slider,
  TitleDivider,
  TitleDividerNoLine,
  TitleDividerLine,
  FieldId,
  OptionsPicker,
  OptionsPickerForCheckbox,
  VariableTextInput,
  LinkGenerator,
  Slider480,
  Slider480MinRestrict200,
  LinkGeneratorWithoutColorPicker,
  GoogleAutoSuggest,
  Dropdown,
  Slider10Step,
  ToggleSwitch,
  PaddingControl16px,
  PaddingControl8px,
  PaddingControl4px,
  Slider800,
  Slider600,
  TextFieldFilled,
  DateFormatPickerFilled,
  OptionsPickerFilledStyleInputs,
  OptionsPickerTwoButtonLabels,
  SocialIconsOptionsPicker,
  DisableFullWidthProperty,
  IconAlignmentSelector,
  ItemsAlignmentSelector,
  TextAlignmentSelector,
  DateTimePicker,
  DatePickerFromTo,
  DateTimePickerFromTo,
};

import React, { useState } from 'react';
import { Slider } from '../../Atoms/Slider';
import { ToggleSwitch } from '../../Atoms/ToggleSwitch';
import { TextField } from '../../Atoms/Forms';

export type LabelsMakerLabelItemType = {
  value: string;
  label: string;
};

export type RatingType = 'Stars' | 'Emojis' | 'Hearts';

export type LabelsMakerValueType = {
  count: number;
  includeLabels: boolean;
  labels: Array<LabelsMakerLabelItemType>;
};

export type LabelsMakerProps = {
  id: string;
  ratingType: RatingType;
  defaultValue: LabelsMakerValueType;
  onChange: (value: LabelsMakerValueType) => void;
};

export const LabelsMaker = (props: LabelsMakerProps) => {
  const { id, defaultValue, ratingType, onChange } = props;
  const [labelsMaker, setLabelsMaker] = useState<LabelsMakerValueType>(
    defaultValue
  );

  const getDummyLabel = (index: number) => ({
    label: `Label ${index + 1}`,
    value: '',
  });

  const changeSliderValue = (value) => {
    labelsMaker.count = value;
    setLabelsMaker({ ...labelsMaker });
    onChange(labelsMaker);
  };

  const handleToggleClick = (value) => {
    labelsMaker.includeLabels = value;
    setLabelsMaker({ ...labelsMaker });
    onChange(labelsMaker);
  };

  const changeLabel = (index: number) => (value: string) => {
    if (!labelsMaker.labels[index]) {
      labelsMaker.labels[index] = getDummyLabel(index);
    }
    labelsMaker.labels[index].value = value;
    setLabelsMaker({ ...labelsMaker });
    onChange(labelsMaker);
  };

  const renderCountPropertyControl = (
    count: number,
    ratingType: RatingType
  ) => {
    if (ratingType !== 'Emojis') {
      return (
        <div className='property-control'>
          <Slider
            label='Count'
            minValue={1}
            maxValue={10}
            defaultValue={count}
            step={1}
            onChange={changeSliderValue}
          />
        </div>
      );
    }
  };

  const renderIncludeLabelPropertyControl = (includeLabel: boolean) => {
    return (
      <div className='property-control'>
        <ToggleSwitch
          label='Include Labels'
          defaultValue={includeLabel}
          onChange={handleToggleClick}
        />
      </div>
    );
  };

  const renderLabelsPropertyControl = (
    id,
    labels: Array<LabelsMakerLabelItemType>,
    count: number
  ) => {
    return Array(count)
      .fill(1)
      .map((item, index: number) => {
        const fieldId = `${id}-${index.toString()}`;
        const labelItem = labels[index] ? labels[index] : getDummyLabel(index);
        return (
          <div key={`${fieldId}`} className='property-control'>
            <TextField
              id={`${fieldId}`}
              label={labelItem.label}
              type='text'
              defaultValue={labelItem.value}
              onChange={changeLabel(index)}
              variant='filled'
            />
          </div>
        );
      });
  };

  const { count, includeLabels, labels } = labelsMaker;
  return (
    <>
      {renderCountPropertyControl(count, ratingType)}
      {renderIncludeLabelPropertyControl(includeLabels)}
      {includeLabels &&
        renderLabelsPropertyControl(
          id,
          labels,
          ratingType === 'Emojis' ? 5 : count
        )}
    </>
  );
};

import React from 'react';
import {
  ToneCardDataArrayType,
  ToneSettingsType,
  ToneType,
  ToneValueType,
} from '../../../Utils/ToneOfVoice/toneOfVoiceTypes';
import { ToneOfVoiceSettingsCard } from '../../../Molecules/ToneOfVoiceSettingsCard';
import {
  StyledToneOfVoiceSettingsCardList,
  StyledListItem,
} from './ToneOfVoiceSettingsCardList.style';

type Props = {
  cards: ToneCardDataArrayType;
  toneValues: ToneSettingsType;
  onToneChoice: (tone: ToneType, value: ToneValueType) => void;
};

export const ToneOfVoiceSettingsCardList = ({
  onToneChoice,
  toneValues,
  cards,
}: Props): JSX.Element => {
  // roll the tone saved value from the users company settings into the card object
  const cardsWithSettingsValue = cards.map((item) => ({
    ...item,
    toneValue: toneValues[item.tone],
  }));

  const handelOnToneChoice = (tone: ToneType, value: ToneValueType) => {
    return onToneChoice(tone, value);
  };
  const hasNoTones = Object.keys(toneValues).length === 0;

  return (
    <StyledToneOfVoiceSettingsCardList>
      {cardsWithSettingsValue.map(({ data, tone, toneValue }) => (
        <StyledListItem key={tone}>
          <ToneOfVoiceSettingsCard
            emoji={data.emoji}
            tone={tone}
            example={data.example || ''}
            toneValue={toneValue}
            onToneChoice={handelOnToneChoice}
            disabled={hasNoTones}
          />
        </StyledListItem>
      ))}
    </StyledToneOfVoiceSettingsCardList>
  );
};

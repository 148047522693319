// $FlowFixMe

import React, { Fragment, PureComponent, MouseEvent } from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { GrowProps } from '@material-ui/core';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';

import { Icon } from '../Icon/Icon';
import { Colors } from '../../Foundation/Colors';
import { MenuDropdownButton } from './TagButton.style';
import { TagButtonPropType, TagButtonStateType } from './TagButton.types';

type RenderContentProps = {
  TransitionProps: GrowProps;
};

export class TagButton extends PureComponent<
  TagButtonPropType,
  TagButtonStateType
> {
  // eslint-disable-next-line id-blacklist
  static defaultProps = {
    compact: true,
    buttonIcon: <Icon icon='Code' />,
    popPlacement: 'right-start',
    buttonColor: Colors.voice,
    children: (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '150px',
          height: '150px',
          background: 'lightgrey',
        }}
      >
        placeholder
      </div>
    ),
  };

  state = { open: false };

  anchorEl: HTMLDivElement | null = null;

  handleToggle = (): void => {
    const { open } = this.state;
    this.setState({ open: !open });
  };

  handleClose = (): void => {
    this.setState({ open: false });
  };

  renderContent = ({ TransitionProps }: RenderContentProps): JSX.Element => {
    const { children } = this.props;
    const otherProps = {
      style: {
        transformOrigin: 'top',
      },
      className: 'variable-menulist',
    };

    return (
      <ClickAwayListener onClickAway={this.handleClose}>
        <Grow {...TransitionProps} {...otherProps}>
          <Paper>{children}</Paper>
        </Grow>
      </ClickAwayListener>
    );
  };

  handleClick = (event: MouseEvent<HTMLDivElement>): void => {
    const classNamesToCloseOn = [
      'item-lozenge',
      'type-desc',
      'variable-menu-dropdown-item',
    ];
    if (
      classNamesToCloseOn.some((className) =>
        (event.target as HTMLDivElement).classList.contains(className)
      )
    ) {
      this.setState({ open: false });
    }
  };

  render(): JSX.Element {
    const { buttonIcon, popPlacement, buttonColor, compact } = this.props;
    const { open } = this.state;

    return (
      <Fragment>
        {/* The MenuDropDownButton has been moved to ui-lib-v2, renamed to 
        'DropdownAdornment' however it has no dropdown feature & is display only */}
        <MenuDropdownButton
          compact={compact}
          unselectable='on'
          buttonColor={buttonColor}
          onClick={this.handleToggle}
          open={open}
          ref={(el: HTMLDivElement) => {
            this.anchorEl = el;
          }}
        >
          {buttonIcon}
        </MenuDropdownButton>
        <Popper
          onClick={this.handleClick}
          unselectable='on'
          className='menu-container'
          open={open}
          anchorEl={this.anchorEl}
          transition
          disablePortal
          placement={popPlacement}
        >
          {this.renderContent}
        </Popper>
      </Fragment>
    );
  }
}

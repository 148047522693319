import styled from 'styled-components';

import { Popover as PopoverMui } from '@material-ui/core';

export const ContentWrapper = styled.div`
  display: inline-block;
  cursor: pointer;
`;

export const StyledPopoverMui = styled(PopoverMui)`
  .MuiPaper-root {
    margin: 8px 0 0 8px;
    max-height: calc(100% - 128px);
  }
`;

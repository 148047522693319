import styled from 'styled-components';
import { Colors } from '../../Foundation/Colors';
import { Fonts } from '../../Foundation/Fonts';
import { FileSelectorTypes } from './FileSelector.types';

export const FileSelectorWrapper = styled.div<Pick<FileSelectorTypes, 'size'>>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${({ size }) => (size === 'small' ? '116px' : '220px')};
  background: ${Colors.background};
  border-radius: 4px;
  border: 1px dashed ${Colors.grey_blue_1};
  position: relative;
  box-sizing: border-box;

  .select-file {
    width: 100%;
    height: 100%;

    > button {
      height: 100%;
    }

    .MuiButton-label {
      width: 100%;

      display: flex;
      flex-direction: column;
      align-items: center;
      ${Fonts.caption};
      color: ${Colors.grey_blue};

      input {
        display: none;
      }

      svg {
        fill: ${Colors.accent};
        width: 40px;
        height: 40px;
        margin-bottom: 8px;
      }

      & .accepted-text {
        color: ${Colors.grey_blue_4};
      }
    }
  }

  .details {
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      fill: ${Colors.grey_blue_4};
      width: 40px;
      height: 40px;
    }

    .filename {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      ${Fonts.link};
      color: ${Colors.grey_blue};

      .filesize {
        ${Fonts.caption};
        color: ${Colors.grey_blue_4};

        .error {
          color: ${Colors.error};
        }
      }
    }

    .close-button {
      position: absolute;
      top: 16px;
      right: 16px;

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
`;

import { IconTypes } from '../../Atoms/Icon';
import {
  TovApiPredictionsType,
  ToneAndScoreType,
  ToneIntensityType,
  ToneScoreArrayType,
  ToneScoreType,
  ToneSettingsType,
  ToneType,
  ToneValueType,
  TonePredictionsType,
} from './toneOfVoiceTypes';

export const valueOrIntensityToNumber = (
  value: ToneValueType | ToneIntensityType
): ToneScoreType => {
  switch (value) {
    case 'medium':
    case 'Moderate':
      return 1;
    case 'high':
    case 'Strong':
      return 2;
    default:
      return 0;
  }
};

const getTovIcon = (
  desiredToneIntensity: ToneIntensityType,
  messageToneIntensity: ToneIntensityType,
  dark: boolean
): IconTypes => {
  const desiredTone = valueOrIntensityToNumber(desiredToneIntensity);
  const messageTone = valueOrIntensityToNumber(messageToneIntensity);
  if (desiredTone > messageTone) {
    return dark ? 'StatusUpDark' : 'StatusUpLight';
  }
  return desiredTone == messageTone
    ? 'StatusTick'
    : dark
    ? 'StatusDownDark'
    : 'StatusDownLight';
};

export const getTovIconLight = (
  desiredToneIntensity: ToneIntensityType,
  messageToneIntensity: ToneIntensityType
) => getTovIcon(desiredToneIntensity, messageToneIntensity, false);

export const getTovIconDark = (
  desiredToneIntensity: ToneIntensityType,
  messageToneIntensity: ToneIntensityType
) => getTovIcon(desiredToneIntensity, messageToneIntensity, true);

export const valueToIntensity = (value: ToneValueType): ToneIntensityType => {
  switch (value) {
    case 'low':
      return 'Low';
    case 'medium':
      return 'Moderate';
    case 'high':
      return 'Strong';
    default:
      return value;
  }
};

export const calculateToneScore = (desiredTone, messageTone): ToneScoreType =>
  (2 -
    Math.abs(
      valueOrIntensityToNumber(desiredTone) -
        valueOrIntensityToNumber(messageTone)
    )) as ToneScoreType;

export const calculateTotalTOVScore = (toneScoreCalcArray) => {
  let totalScore = 0;
  const maxScore = toneScoreCalcArray.length * 2;

  if (toneScoreCalcArray)
    toneScoreCalcArray.forEach(({ score }) => {
      totalScore += score;
    });
  return Math.round((totalScore / maxScore) * 100);
};

const apiToneToUITone = (tone) => {
  switch (tone) {
    case 'joy':
      return 'joyful';
    case 'surprise':
      return 'surprised';
    case 'sadness':
      return 'sad';
    case 'love':
      return 'warm';
    case 'anger':
      return 'angry';
    case 'fear':
      return 'fearful';
    default:
      return tone;
  }
};

const calculateSentenceToneHighlights = (
  sentenceHighlights: number,
  score: number
) => {
  if (score < 0.33) {
    return sentenceHighlights;
  }

  if (score < 0.66) {
    return sentenceHighlights + 1;
  }

  return sentenceHighlights + 2;
};

export const getToneAndHighlightIntensityFromPredictions = (
  tone: ToneType,
  predictions: TonePredictionsType
): { toneIntensity: ToneIntensityType; sentenceHighlights: number } => {
  let score = 0;
  let sentenceHighlights = 0;

  predictions.forEach(({ tones }) => {
    const sentenceTone = tones.find(
      (obj) => apiToneToUITone(obj.tone) === tone
    );
    if (sentenceTone) {
      score += sentenceTone.score;
      sentenceHighlights = calculateSentenceToneHighlights(
        sentenceHighlights,
        sentenceTone.score
      );
    }
  });

  score = score / predictions.length;

  if (score < 0.33) return { toneIntensity: 'Low', sentenceHighlights };
  if (score < 0.66) return { toneIntensity: 'Moderate', sentenceHighlights };
  return { toneIntensity: 'Strong', sentenceHighlights };
};

const updateToNewJoyScore = (score: number) => {
  // linear regression for each itensity range
  if (score < 0.88) return 0.38 * score;
  if (score < 0.95) return 4.714 * score - 3.819;
  return 6.8 * score - 5.8;
};

export const parseTovData = (tovPredictions: TovApiPredictionsType) => {
  const formattedPredictions: TonePredictionsType = tovPredictions.map(
    (sentence) => ({
      ...sentence,
      tones: sentence.tones.map(({ tone, score }) => {
        if (tone === 'joy') {
          return {
            tone: apiToneToUITone(tone),
            score: updateToNewJoyScore(score),
          };
        }
        return {
          score,
          tone: apiToneToUITone(tone),
        };
      }),
    })
  );

  return formattedPredictions;
};

export const makeTovArrays = (
  toneSettings: ToneSettingsType,
  predictions: TovApiPredictionsType
) => {
  if (!predictions.length) return {};
  const parsedPredictions = parseTovData(predictions);
  const toneScoreObjectArray: ToneScoreArrayType = [];
  const toneScoreCalcArray: Array<ToneAndScoreType> = [];

  for (const [tone, desiredToneValue] of Object.entries(toneSettings)) {
    const typedTone = tone as ToneType;
    const {
      toneIntensity,
      sentenceHighlights,
    } = getToneAndHighlightIntensityFromPredictions(
      typedTone,
      parsedPredictions
    );
    const toneScore = calculateToneScore(desiredToneValue, toneIntensity);

    toneScoreObjectArray.push({
      desiredToneIntensity: valueToIntensity(desiredToneValue),
      messageToneIntensity: toneIntensity,
      tone: typedTone,
      score: toneScore,
      sentenceHighlights,
    });
    toneScoreCalcArray.push({ tone: typedTone, score: toneScore });
  }

  return { toneScoreObjectArray, toneScoreCalcArray };
};

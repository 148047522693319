import React, { PureComponent, Fragment } from 'react';

import { Link } from 'react-router-dom';
import LinesEllipsis from 'react-lines-ellipsis';
import { Popper, Paper } from '@material-ui/core';
import { ColorIcon } from '../../Atoms/ColorIcon';
import { Icons } from '../../Foundation/Icons';
import { IconsMap } from '../../Organisms/ChannelSelector';
import {
  CardListWrapper,
  CardWrapper,
  CardContainer,
  IconFrame,
  Title,
  Description,
} from './LegacyCardList.style';

export type CardProps = {
  cardTitle?: string;
  cardDescription?: string;
  image?: JSX.Element;
  imageFullWidth?: boolean;
  disabled?: boolean;
  cardWidth?: string;
  colorise?: boolean;
};

export const LegacyCard = ({
  cardTitle = '',
  image = <Icons.Devices />,
  disabled = false,
  cardDescription = '',
  cardWidth = '240',
  imageFullWidth = false,
  colorise = false,
}: CardProps) => (
  <CardContainer
    cardWidth={cardWidth}
    cardDisabled={disabled}
    hasDescription={!!cardDescription}
    className='card-container'
  >
    <IconFrame>{image}</IconFrame>
    <div className='footer-content'>
      <Title disabled={disabled}>
        <LinesEllipsis
          text={cardTitle}
          maxLine={2}
          ellipsis='...'
          trimRight
          basedOn='letters'
        />
      </Title>
      <div className='classic-lozenge'>Classic Template</div>
    </div>
    {cardDescription && (
      <Description disabled={disabled}>
        <LinesEllipsis
          text={cardDescription}
          maxLine={4}
          ellipsis='...'
          trimRight
          basedOn='letters'
        />
      </Description>
    )}
  </CardContainer>
);

type Props = {
  baseUrl: string;
  hasRouterLink: boolean;
  hasOptionsMenu: boolean;
  cardType: 'messageStudio' | 'workflowStudio' | 'classic';
  items: Array<{
    id: number;
    name: string;
    selectedChannels: unknown;
  }>;
  optionsMenuItems: Array<unknown>;
  onClick: () => void;
};

type State = {
  anchorEl: HTMLElement | null;
  selectedItem: unknown;
};

export class LegacyCardList extends PureComponent<Props, State> {
  // eslint-disable-next-line id-blacklist
  static defaultProps = {
    items: [
      {
        id: 111,
        name: 'example 1',
      },
      {
        id: 222,
        name: 'example 2',
      },
    ],
    optionsMenuItems: [
      {
        label: 'Delete',
        clickHandler: () => {},
      },
    ],
    hasRouterLink: true,
    hasOptionsMenu: true,
    baseUrl: '/test',
    cardType: 'workflowStudio',
  };

  state = {
    anchorEl: null,
    selectedItem: {
      id: null,
      name: null,
    },
  };

  handleOptionsMenuOpen = (
    event: React.MouseEvent<HTMLElement>,
    selectedItem: unknown
  ) => {
    const { currentTarget } = event;
    const { anchorEl } = this.state;

    setTimeout(() => {
      if (document.querySelector('body')) {
        document.querySelector('body').style.overflowY = 'auto';
      }
    }, 0);

    if (anchorEl) {
      this.setState({
        anchorEl: null,
      });
    } else {
      this.setState({
        anchorEl: currentTarget,
        selectedItem,
      });
    }
  };

  handleOptionsClose = () => {
    this.setState({ anchorEl: null });
  };

  optionsClickHandler = (clickHandler) => {
    const { selectedItem } = this.state;
    this.setState({ anchorEl: null });
    clickHandler(selectedItem);
  };

  handleCardType = (type, baseUrl, onClick, item, idx) => {
    switch (type) {
      case 'messageStudio': {
        const { selectedChannels } = item;
        const channels = Object.keys(selectedChannels).reduce(
          (accum: Array<string>, key) => {
            if (selectedChannels[key]) {
              accum.push(key);
            }

            return accum;
          },
          []
        );

        return (
          <Link to={`${baseUrl}`} onClick={() => onClick(item)}>
            <LegacyCard
              cardTitle={item.name}
              image={
                <div className='group-icons'>
                  {channels.map((icon) => (
                    <div key={icon} className={icon}>
                      <ColorIcon {...IconsMap[icon]} />
                    </div>
                  ))}
                </div>
              }
            />
          </Link>
        );
      }
      case 'workflowStudio':
        return (
          <Link to={`${baseUrl}/${item.id}`}>
            <LegacyCard
              colorise
              cardTitle={item.name}
              image={<Icons.Workflow className='workflow-icon' />}
            />
          </Link>
        );
      case 'classic':
        return item.isSupported ? (
          <Link to={`${baseUrl}`} onClick={() => onClick(item)}>
            <LegacyCard
              imageFullWidth
              cardTitle={item.name}
              image={<Icons.ClassicThumbnail />}
            />
          </Link>
        ) : (
          <div className='disabled-template' key={item.id}>
            <LegacyCard
              imageFullWidth
              cardTitle={item.name}
              image={<Icons.ClassicThumbnail />}
            />
          </div>
        );
      default:
        return false;
    }
  };

  render() {
    const {
      items,
      baseUrl,
      hasRouterLink,
      cardType,
      hasOptionsMenu,
      optionsMenuItems,
      onClick,
    } = this.props;
    const { anchorEl } = this.state;
    const open = !!anchorEl;
    const popperId = open ? 'no-transition-popper' : undefined;

    return (
      <CardListWrapper>
        {items.map((item, idx) => (
          <CardWrapper key={item.id}>
            {hasRouterLink ? (
              this.handleCardType(cardType, baseUrl, onClick, item, idx)
            ) : (
              <LegacyCard cardTitle={item.name} />
            )}
            {hasOptionsMenu && (
              <Fragment>
                <div className='more-options-wrapper'>
                  <Icons.Elipsis
                    className='menu-more-options'
                    aria-describedby={item.id}
                    variant='contained'
                    onClick={(event) =>
                      this.handleOptionsMenuOpen(event, {
                        id: item.id,
                        name: item.name,
                      })
                    }
                  />
                </div>
              </Fragment>
            )}
          </CardWrapper>
        ))}
        {hasOptionsMenu && (
          <Popper
            id={popperId}
            anchorEl={anchorEl}
            open={open}
            onClose={this.handleOptionsClose}
          >
            <Paper style={{ cursor: 'pointer' }}>
              {optionsMenuItems.map(({ label, clickHandler }) => (
                <Paper
                  key={label}
                  classes={{ root: 'workflow-delete-btn--popper-paper' }}
                  onClick={() => this.optionsClickHandler(clickHandler)}
                  style={{
                    padding: '12px 16px',
                    boxShadow: 'none',
                    cursor: 'pointer',
                  }}
                >
                  {label}
                </Paper>
              ))}
            </Paper>
          </Popper>
        )}
      </CardListWrapper>
    );
  }
}

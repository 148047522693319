import React from 'react';

import { Spinner } from '../../Atoms/Indicators';
import { Icons } from '../../Foundation/Icons';
import { SnackbarTypes } from './Snackbar.types';
import { StyledSnackbar } from './Snackbar.styles';

export const Snackbar = ({
  severity,
  message,
  onClose,
}: SnackbarTypes): JSX.Element => {
  const severityIcons = {
    error: <Icons.Warning />,
    warning: <Icons.Warning />,
    success: <Icons.Received />,
    info: <Icons.Info />,
    loading: <Spinner size='medium' />,
  };

  return (
    <StyledSnackbar>
      <div className={`icon ${severity}`}>{severityIcons[severity]}</div>
      <div className='message'>{message}</div>
      <div
        className='exit-button'
        onClick={() => onClose()}
        onKeyPress={() => onClose()}
        role='button'
        tabIndex={0}
      >
        <Icons.Close />
      </div>
    </StyledSnackbar>
  );
};

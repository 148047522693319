import React, { useState } from 'react';

import Truncate from 'react-truncate';

import { Tooltip } from '../../Atoms/Dialogs';
import { Button } from '../../Atoms/Navigations';
import { Icons } from '../../Foundation/Icons';
import { StyledDashboardCard } from './DashboardCard.style';
import { DashboardCardType, HeaderIconType } from './DashboardCard.types';

const HeaderIcon = ({ icon }: HeaderIconType): JSX.Element | null => {
  if (!icon) {
    return null;
  }

  const Icon = Icons[icon];

  return <Icon />;
};

export const DashboardCard = ({
  icon,
  title,
  description,
  label,
  onClick,
}: DashboardCardType): JSX.Element => {
  const [isTruncated, setIsTruncated] = useState(false);

  const handleTruncate = (isTextTruncated: boolean) => {
    setIsTruncated(isTextTruncated);
  };

  return (
    <StyledDashboardCard hasFocus={isTruncated} onClick={onClick}>
      <div className='card-header'>
        <HeaderIcon icon={icon} />
      </div>
      <div className='card-content'>
        <Tooltip
          title={title}
          disableHoverListener={!isTruncated}
          arrow
          ariaLabel={title}
          disablePortal
          placement='right'
        >
          <Truncate
            className='card-title'
            lines={2}
            ellipsis='...'
            onTruncate={handleTruncate}
          >
            {title}
          </Truncate>
        </Tooltip>
        <div className='card-description'>{description}</div>
      </div>
      <div className='card-footer'>
        <Button size='medium' text={label} variant='link' />
      </div>
    </StyledDashboardCard>
  );
};

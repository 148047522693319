import styled from 'styled-components';
import { Colors } from '../../../Foundation/Colors';

export const TagsWrapper = styled.div`
  padding-top: 10px;
  max-height: 300px;
  border: 1px solid ${Colors.accent};
  overflow-y: scroll;
`;

export const Tag = styled.div`
  padding: 12px 20px;
  display: flex;

  :focus {
    outline: 0;
    background-color: ${Colors.grey_blue_2};
  }

  :hover {
    background-color: ${Colors.grey_blue_1};
    cursor: pointer;
  }

  .tag-description,
  .tag-name {
    pointer-events: none;
    font-size: 12px;
    letter-spacing: 0.5px;
  }

  .tag-name {
    font-weight: bold
    color: ${Colors.grey_blue_4};
    padding-right: 10px;
  }

  .tag-description {
    font-weight: bold;
    color: ${Colors.grey_blue_4};
  }
`;

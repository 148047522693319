import styled from 'styled-components';
import { Colors } from '../../Foundation/Colors';
import { Fonts } from '../../Foundation/Fonts';

export const SliderWrapper = styled.div`
  align-items: center;
  width: 100%;

  .slider-label {
    flex: 1;
    ${Fonts.link};
    color: ${Colors.grey_blue};
    text-align: left;
  }

  .slider-input-wrapper {
    display: flex;
    align-items: center;

    .slider-controller {
      flex: 10;
    }

    .text-field-wrapper {
      margin-left: 16px;
      input {
        padding: 12px 0px 12px 8px;
      }
    }
  }

  .thumb {
    background-color: ${Colors.accent};
  }

  .input-adornment {
    color: ${Colors.grey_blue_3};
  }

  .MuiInputBase-root,
  .MuiTextField-root {
    display: flex;
  }
`;

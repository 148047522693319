import React from 'react';

import { Icon } from '../../Icon/Icon';
import { IconWrapper } from './ExpandIcon.style';
import { ExpandIconProps } from './ExpandIcon.types';

export const ExpandIcon = ({ isExpanded, toggleDrawer }: ExpandIconProps) => {
  const icon = isExpanded ? 'ChevronLeft' : 'ChevronRight';
  return (
    <IconWrapper isExpanded={isExpanded}>
      <Icon icon={icon} size='small' onClick={() => toggleDrawer()} />
    </IconWrapper>
  );
};

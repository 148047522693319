import styled from 'styled-components';
import { DataTableProps, RowData } from '../DataTable/DataTable.types';

export const FilterTableWrapper = styled.div<
  Pick<DataTableProps<RowData>, 'maxHeight'>
>`
  height: ${({ maxHeight }) => maxHeight};

  .data-table-wrapper {
    /* 86px is the height of the filter toolbar */
    height: calc(100% - 86px);
  }
`;

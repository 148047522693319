import _TopLeft from './topLeft.svg';
import _TopMiddle from './topMiddle.svg';
import _TopRight from './topRight.svg';
import _MiddleLeft from './middleLeft.svg';
import _MiddleMiddle from './middleMiddle.svg';
import _MiddleRight from './middleRight.svg';
import _BottomLeft from './bottomLeft.svg';
import _BottomMiddle from './bottomMiddle.svg';
import _BottomRight from './bottomRight.svg';

export const TopLeft = _TopLeft;
export const TopMiddle = _TopMiddle;
export const TopRight = _TopRight;
export const MiddleLeft = _MiddleLeft;
export const MiddleMiddle = _MiddleMiddle;
export const MiddleRight = _MiddleRight;
export const BottomLeft = _BottomLeft;
export const BottomMiddle = _BottomMiddle;
export const BottomRight = _BottomRight;

import styled from 'styled-components';
import { Colors } from '../../Foundation/Colors/Colors';
import { Fonts } from '../../Foundation/Fonts/Fonts';

export const StyledReplyNode = styled.div`
  ${Fonts.capsHeading};
  padding: 3px 16px;
  border-radius: 2px;

  &.on-response {
    color: ${Colors.success_dark};
    background-color: ${Colors.success_light};
  }

  &.on-no-response {
    color: ${Colors.error};
    background-color: ${Colors.error_light};
  }
`;

import styled from 'styled-components';
import { Colors } from '../../Foundation/Colors';
import { Fonts } from '../../Foundation/Fonts';

export const SimpleTableMultiSelectWrapper = styled.div`
  position: absolute;
  top: 14px;
  left: 94px;
  padding: 8px 0 12px;
  ${Fonts.caption};

  span {
    line-height: 20px;
  }

  width: 100%;
  display: flex;
  background-color: ${Colors.white};

  * {
    display: block;
  }

  svg {
    margin-left: 10px;
  }
`;

import { useEffect } from 'react';

import { operatorIds, constantGenerators } from '@whispir/expression-helper';
import {
  startOfToday,
  startOfMonth,
  endOfMonth,
  subDays,
  subMonths,
  isToday,
  isSameDay,
  endOfDay,
} from 'date-fns';
import {
  OperandConfiguration,
  OperatorLabel,
} from '../../definitions/operatorAndOperand';
import { BetweenDateComponent } from './dateComponents/BetweenDateComponent';
import { dateToISOString, formatDate } from './dateComponents/utils';

const {
  generateDateConstant,
  generateDateRangeConstant,
  generateDateBetweenConstant,
} = constantGenerators;

const { AFTER, BETWEEN } = operatorIds;

const durationUnits = {
  HOUR: 'hour',
  DAY: 'day',
};

const { HOUR, DAY } = durationUnits;

const operators = {
  LAST_HOUR: 'last-hour',
  LAST_DAY: 'last-day',
  TODAY: 'today',
  YESTERDAY: 'yesterday',
  LAST_WEEK: 'last-week',
  THIS_MONTH: 'this-month',
  PREVIOUS_MONTH: 'previous-month',
  IN_THE_PAST: 'in-the-past',
};

const {
  LAST_HOUR,
  LAST_DAY,
  TODAY,
  YESTERDAY,
  LAST_WEEK,
  THIS_MONTH,
  PREVIOUS_MONTH,
  IN_THE_PAST,
} = operators;

export const operatorFilterLabelObjects: { [key: string]: OperatorLabel } = {
  [LAST_HOUR]: { value: LAST_HOUR, label: 'Last Hour' },
  [LAST_DAY]: { value: LAST_DAY, label: 'Last 24 Hours' },
  [TODAY]: { value: TODAY, label: 'Today' },
  [YESTERDAY]: { value: YESTERDAY, label: 'Yesterday' },
  [LAST_WEEK]: { value: LAST_WEEK, label: 'Last 7 Days' },
  [THIS_MONTH]: { value: THIS_MONTH, label: 'This Month' },
  [PREVIOUS_MONTH]: { value: PREVIOUS_MONTH, label: 'Previous Month' },
  [BETWEEN]: { value: BETWEEN, label: 'Between' },
};

const defaultFilterOptions = {
  dateFormat: 'dd MMMM yyyy',
};

export const createFilterLabel = (
  { operator, operand },
  { dateFormat } = defaultFilterOptions
) => {
  if (!operator) {
    return 'Select dates';
  }

  let filterLabel;
  switch (operator) {
    case BETWEEN: {
      const afterValue = operand && operand.value;
      const beforeValue =
        operand &&
        operand.context &&
        operand.context.before &&
        operand.context.before.value;

      if (!afterValue || !beforeValue) {
        filterLabel = 'Select dates';
        break;
      }

      if (isSameDay(new Date(afterValue), new Date(beforeValue))) {
        filterLabel = formatDate(afterValue, dateFormat);
        break;
      }

      const afterValueFormatted =
        afterValue && formatDate(afterValue, dateFormat);
      const beforeValueFormatted =
        beforeValue && formatDate(beforeValue, dateFormat);
      filterLabel = `${afterValueFormatted}-${beforeValueFormatted}`;
      break;
    }
    case IN_THE_PAST: {
      const durationValue = operand && operand.value;
      const durationUnit =
        operand && operand.context && operand.context.durationUnit;

      if (!durationUnit || !durationValue) {
        filterLabel = 'Select dates';
        break;
      }

      const durationFormatted =
        durationValue !== '1'
          ? `${durationValue} ${durationUnit}s`
          : durationUnit;
      filterLabel = `Last ${durationFormatted}`;
      break;
    }
    case AFTER: {
      const value = operand && operand.value;

      if (!value) {
        filterLabel = 'Select dates';
        break;
      }

      const formattedValue = formatDate(value, dateFormat);

      if (isToday(new Date(value))) {
        filterLabel = formattedValue;
        break;
      }

      const formattedToday = formatDate(startOfToday(), dateFormat);
      filterLabel = `${formattedValue}-${formattedToday}`;
      break;
    }
    default:
      throw new Error(`Invalid operator: ${operator}`);
  }

  return filterLabel;
};

export const LastHourComponent = ({ onChange }) => {
  useEffect(() => {
    onChange(
      generateDateRangeConstant({ durationValue: '1', durationUnit: HOUR })
    );
  }, []);
  return null;
};

export const Last24HoursComponent = ({ onChange }) => {
  useEffect(() => {
    onChange(
      generateDateRangeConstant({ durationValue: '24', durationUnit: HOUR })
    );
  }, []);
  return null;
};

export const Last7DaysComponent = ({ onChange }) => {
  useEffect(() => {
    onChange(
      generateDateRangeConstant({ durationValue: '7', durationUnit: DAY })
    );
  }, []);
  return null;
};

export const TodayComponent = ({ onChange }) => {
  useEffect(() => {
    onChange(generateDateConstant({ value: dateToISOString(startOfToday()) }));
  }, []);
  return null;
};

export const YesterdayComponent = ({ onChange }) => {
  const startOfYesterday = subDays(startOfToday(), 1);
  const endOfYesterday = endOfDay(startOfYesterday);
  useEffect(() => {
    onChange(
      generateDateBetweenConstant({
        afterValue: dateToISOString(startOfYesterday),
        beforeValue: dateToISOString(endOfYesterday),
      })
    );
  }, []);
  return null;
};

export const ThisMonthComponent = ({ onChange }) => {
  useEffect(() => {
    onChange(
      generateDateConstant({
        value: dateToISOString(startOfMonth(startOfToday())),
      })
    );
  }, []);
  return null;
};

export const PreviousMonthComponent = ({ onChange }) => {
  useEffect(() => {
    const todayMinusOneMonth = subMonths(startOfToday(), 1);
    onChange(
      generateDateBetweenConstant({
        afterValue: dateToISOString(startOfMonth(todayMinusOneMonth)),
        beforeValue: dateToISOString(endOfMonth(todayMinusOneMonth)),
      })
    );
  }, []);
  return null;
};

export const dateFilterComponents: OperandConfiguration = {
  [LAST_HOUR]: {
    ...operatorFilterLabelObjects[LAST_HOUR],
    operatorOverride: IN_THE_PAST,
    component: LastHourComponent,
  },
  [LAST_DAY]: {
    ...operatorFilterLabelObjects[LAST_DAY],
    operatorOverride: IN_THE_PAST,
    component: Last24HoursComponent,
  },
  [TODAY]: {
    ...operatorFilterLabelObjects[TODAY],
    operatorOverride: AFTER,
    component: TodayComponent,
  },
  [YESTERDAY]: {
    ...operatorFilterLabelObjects[YESTERDAY],
    operatorOverride: BETWEEN,
    component: YesterdayComponent,
  },
  [LAST_WEEK]: {
    ...operatorFilterLabelObjects[LAST_WEEK],
    operatorOverride: IN_THE_PAST,
    component: Last7DaysComponent,
  },
  [THIS_MONTH]: {
    ...operatorFilterLabelObjects[THIS_MONTH],
    operatorOverride: AFTER,
    component: ThisMonthComponent,
  },
  [PREVIOUS_MONTH]: {
    ...operatorFilterLabelObjects[PREVIOUS_MONTH],
    operatorOverride: BETWEEN,
    component: PreviousMonthComponent,
  },
  [BETWEEN]: {
    ...operatorFilterLabelObjects[BETWEEN],
    component: BetweenDateComponent,
  },
};

import React, { PureComponent, Fragment } from 'react';

import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd';
import {
  OuterWrapper,
  Label,
  ComponentBox,
  Clone,
} from './DragAndDropLayout.styles';
import {
  Items,
  DragAndDropLayoutProps,
  DragAndDropLayoutState,
} from './DragAndDropLayout.types';

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',

  // change background colour if dragging
  background: isDragging ? 'none' : 'lightgrey',

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? 'lightblue' : 'lightgrey',
  padding: grid,
  width: 250,
});

// a little function to help us with reordering the result
const reorder = (list: Items, startIndex: number, endIndex: number): Items => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export class DragAndDropLayout extends PureComponent<
  DragAndDropLayoutProps,
  DragAndDropLayoutState
> {
  // eslint-disable-next-line id-blacklist
  static defaultProps = {
    items: [
      {
        title: 'Default Component Name',
        id: 1,
      },
    ],
    selected: [
      {
        title: 'Default Selected Component Name',
        id: 2,
      },
    ],
  };

  static displayName: string;

  constructor(props: DragAndDropLayoutProps) {
    super(props);
    const { items, selected } = this.props;
    this.state = {
      isHover: false,
      items,
      selected,
    };
  }

  /**
   * A semi-generic way to handle multiple lists. Matches
   * the IDs of the droppable container to the names of the
   * source arrays stored in the state.
   */
  id2List = {
    source: 'items',
    target: 'selected',
  };

  // eslint-disable-next-line
  getList = (id: string): Items => this.state[this.id2List[id]];

  onDragEnd = (result: DropResult) => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    // reoder - if user drags and drops in target, we want to update the order of components
    if (source.droppableId === destination.droppableId) {
      if (source.droppableId === 'target') {
        // get reordered items on the canvas
        const items = reorder(
          this.getList(source.droppableId),
          source.index,
          destination.index
        );

        // update the state of the canvas with reorderd items
        this.setState({
          selected: items,
        });
      }
    } else if (destination.droppableId === 'target') {
      // copy - if the user drags a component from source (components list), we want to copy it to canvas

      // get all components from the list (items) and all components on canvas (selected)
      const { items, selected } = this.state;

      // make a copy of the dragged component
      const newComponent = { ...items[source.index] };

      // create a new id for the dragged component
      newComponent.id = Math.floor(Math.random() * 3000 + 1);

      // get all components currently on the canvas
      const newSelected = selected;

      // add new component to canvas at the right index
      newSelected.splice(destination.index, 0, newComponent);

      // update the state of the canvas
      this.setState({
        selected: [...newSelected],
      });
    }
  };

  onDelete = (index: number) => {
    // get all components on the canvas
    const { selected } = this.state;

    // create a copy of all components on canvas
    const newSelected = selected;

    // remove component with the right index
    newSelected.splice(index, 1);

    // update the state of the canvas
    this.setState({
      selected: [...newSelected],
    });
  };

  render() {
    const { items, selected } = this.state;
    return (
      <OuterWrapper>
        <DragDropContext onDragEnd={this.onDragEnd}>
          <div>
            <Label>Source</Label>
            <Droppable droppableId='source' isDropDisabled>
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  {items && items.length > 0 ? (
                    items.map((item, index) => (
                      <Draggable
                        key={item.id}
                        draggableId={item.id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <Fragment>
                            <ComponentBox
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              isDragging={snapshot.isDragging}
                              style={provided.draggableProps.style}
                            >
                              {item.title}
                            </ComponentBox>
                            {snapshot.isDragging && <Clone>{item.title}</Clone>}
                          </Fragment>
                        )}
                      </Draggable>
                    ))
                  ) : (
                    <p>Empty list</p>
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>

          <div>
            <Label>Target</Label>
            <Droppable droppableId='target'>
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  {selected && selected.length > 0 ? (
                    selected.map((item, index) => (
                      <Draggable
                        className='foo'
                        key={item.id}
                        draggableId={item.id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <ComponentBox>
                              <button
                                onClick={() => this.onDelete(index)}
                                type='button'
                              >
                                X
                              </button>
                              {item.title}
                            </ComponentBox>
                          </div>
                        )}
                      </Draggable>
                    ))
                  ) : (
                    <p>Empty Canvas</p>
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        </DragDropContext>
      </OuterWrapper>
    );
  }
}

DragAndDropLayout.displayName = 'DragAndDropLayout';

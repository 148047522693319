import React, { useState } from 'react';

import { PlaceholderImage } from './assets/PlaceholderImage';
import { ImageWrapper, ImgLink } from './RichTextImageBlock.style';
import { RichTextImageBlockProps } from './RichTextImageBlock.types';

export const RichTextImageBlock = (props: RichTextImageBlockProps) => {
  const {
    src,
    altText,
    onChange,
    width,
    paddingTop,
    paddingBottom,
    imgLink,
  } = props;
  const imageSource = src !== '' ? src : PlaceholderImage;
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const handleOnLoad = (evt) => {
    if (!isImageLoaded) {
      const value = evt.target.naturalWidth;
      setIsImageLoaded(true);
      if (onChange) onChange(value);
    }
  };

  const img = (
    <img src={imageSource} alt={altText} onLoad={handleOnLoad} width={width} />
  );

  return (
    <ImageWrapper paddingTop={paddingTop} paddingBottom={paddingBottom}>
      {imgLink ? (
        <ImgLink href={imgLink} target='_blank'>
          {img}
        </ImgLink>
      ) : (
        img
      )}
    </ImageWrapper>
  );
};

import { Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { formatDistanceToNow } from 'date-fns';
// eslint-disable-next-line import/no-named-as-default
import useInterval from 'use-interval';
import { Icon } from '../Icon';
import { Spinner } from '../Indicators/Spinner';
import { Button } from '../Navigations/Button';
import { StyledWrapper } from './AutoSaveDialog.styles';
import { AutoSaveDialogProps } from './AutoSaveDialog.types';

export const AutoSaveDialog = (props: AutoSaveDialogProps) => {
  const {
    isSaving = false,
    hasUnsavedChanges = false,
    onSaveClick,
    lastSavedDate,
  } = props;
  const [lastSavedElapsedTime, setLastSavedElapsedTime] = useState<
    string | null
  >(lastSavedDate ? formatDistanceToNow(lastSavedDate) : null);

  useInterval(() => {
    if (lastSavedDate) {
      setLastSavedElapsedTime(formatDistanceToNow(lastSavedDate));
    }
    // Trigger every minute
  }, 60 * 1000);

  /**
   * Don't wait for interval to be triggered
   * Immediately update the `lastSavedElapsedTime` when the `lastSavedDate` value changes
   */
  useEffect(() => {
    if (lastSavedDate) {
      setLastSavedElapsedTime(formatDistanceToNow(lastSavedDate));
    }
  }, [lastSavedDate]);

  const getStatus = () => {
    if (isSaving) {
      return (
        <>
          <Spinner size='small' />
          <Typography>Saving your work</Typography>
        </>
      );
    }

    if (lastSavedDate && lastSavedElapsedTime) {
      return (
        <>
          <Icon icon='StatusTick' />
          <Typography>{`Last saved ${lastSavedElapsedTime} ago`}</Typography>
        </>
      );
    }

    if (hasUnsavedChanges) {
      return <Typography>Unsaved Changes</Typography>;
    }

    return null;
  };

  return (
    <StyledWrapper>
      {getStatus()}
      <Button
        size='medium'
        text='Save'
        type='primary'
        variant='link'
        onClick={onSaveClick}
        disabled={isSaving}
      />
    </StyledWrapper>
  );
};

import styled from 'styled-components';

import { Chip as MuiChip } from '@material-ui/core';
import { Colors } from '../../../Foundation/Colors';
import { Fonts } from '../../../Foundation/Fonts';

export const StyledChip = styled(MuiChip)`
  &.MuiChip-root {
    ${Fonts.link};
    height: ${({ size }) => (size === 'small' ? '24px' : '32px')};
    max-width: 200px;
    margin: 0 2px 0;

    &.clickable:hover {
      cursor: pointer;
    }
  }

  &.accent {
    color: ${Colors.accent};
    background-color: ${Colors.accent_2};
    .MuiSvgIcon-root {
      width: 16px;
      color: ${Colors.accent};
    }

    .MuiChip-icon {
      fill: ${Colors.accent};
    }

    &:hover {
      background-color: ${Colors.accent_3};
    }
  }

  &.variable {
    color: ${Colors.variable};
    background-color: ${Colors.variable_light};

    .MuiSvgIcon-root {
      width: 16px;
      color: ${Colors.variable};
    }

    .MuiChip-icon {
      fill: ${Colors.variable};
    }

    &:hover {
      background-color: ${Colors.variable_light};
    }
  }

  &.error {
    color: ${Colors.error};
    background-color: ${Colors.error_light};

    .MuiSvgIcon-root {
      width: 16px;
      color: ${Colors.error};
    }

    .MuiChip-icon {
      fill: ${Colors.error};
    }

    &:hover {
      background-color: ${Colors.error_light};
    }
  }

  .MuiChip-icon {
    width: 12px;
    margin-left: 8px;
  }
`;

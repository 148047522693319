export const prefix = process.env.PUBLIC_URL || '';
export const REGION = process.env.REGION;
export const segmentAccount = process.env.SEGMENT_ACCOUNT;
export const platform = 'Sparx';

export type ConfigType = { key: string, version: number };

export const EXTERNAL_ROUTES = {
  TRACK: '/track/',
  LOGIN: '/signin/'
};

export const USER_MANAGEMENT_UI = '/signin';
export const userUiManagementPages = {
  login: USER_MANAGEMENT_UI,
  settings: `${USER_MANAGEMENT_UI}/settings`,
  brand: `${USER_MANAGEMENT_UI}/brand`,
  activate: `${USER_MANAGEMENT_UI}/activate`,
  apiKeys: `${USER_MANAGEMENT_UI}/apikeys`,
};

export const TEMPLATE_STUDIO_UI = '/templates';
export const templateStudioPages = {
  templates: TEMPLATE_STUDIO_UI,
  emails: `${TEMPLATE_STUDIO_UI}/emails`,
  sms: `${TEMPLATE_STUDIO_UI}/sms`,
  webpages: `${TEMPLATE_STUDIO_UI}/webpages`,
  forms: `${TEMPLATE_STUDIO_UI}/forms`,
};


export const pagesPathname = {
  contacts: '/contacts',
  insights: '/analytics/activity',
  messages: '/create',
  store: '/store',
  workflows: '/workflow',
  ...templateStudioPages,
  ...userUiManagementPages
};

export const subNavMenuItems = {
  'Workflow': [
    {
      groupName: "Workflows",
      groupItems: [{
        title: "All Workflows",
        pathname: "/workflow"
      }],
      title: 'Workflow Studio',
      icon: 'WorkflowOutline'
    }
  ],
  'Messages': [
    {
      groupName: "Message Studio",
      groupItems: [
        {
          title: 'Sent',
          pathname: '/create/sent-messages',
        },
        {
          title: 'Archived',
          pathname: '/create/archived-messages',
        },
        {
          title: "New Message",
          pathname: "/create/channels"
        },
      ],
      title: 'Message Studio',
      icon: 'MessagesOutline'
    },
    {
      groupName: "WhatsApp Templates",
      title: 'WhatsApp Templates',
      renderAs: 'link',
      link: "/create/whatsapp/templates"
    },
    {
      groupName: "Classic Templates",
      title: 'Classic Templates',
      renderAs: 'link',
      link: "/create/classic"
    }
  ],
  'Contacts': [
    {
      groupName: "Contacts",
      groupItems: [{
        title: "All Contacts",
        pathname: "/contacts"
      },
      {
        title: "All Lists",
        pathname: "/contacts/lists"
      }],
      title: 'Contacts',
      icon: 'ContactsOutline'
    }
  ],
  'Assessment': [
    {
      groupName: 'Insights',
      groupItems: [{
        title: 'Activity',
        pathname: '/analytics/activity'
      },
      {
        title: 'Engagement',
        pathname: '/analytics/engagement'
      },
      {
        title: 'Recipient',
        pathname: '/analytics/messages/recipients'
      },
      {
        title: 'Forms',
        pathname: '/analytics/forms'
    }],
      title: 'Insights',
      icon: 'AnalyticsOutline'
    }
  ],
  'Settings': [
    {
      groupName: "Settings",
      groupItems: [{
        title: "Account Info ",
        pathname: "/contacts/settings"
      },
      {
        title: "Billing",
        // The URL for this is still yet to be decided
        pathname: "#"
      }
      ]
    }
  ],
  'Templates': [
    {
      groupName: "Templates",
      groupItems: [{
        title: "All Templates",
        pathname: templateStudioPages.templates
      },
      {
        title: "Email",
        pathname: templateStudioPages.emails
      },
      {
        title: "SMS",
        pathname: templateStudioPages.sms
      },
      {
        title: "Webpages",
        pathname: templateStudioPages.webpages
      },
      {
        title: "Forms",
        pathname: templateStudioPages.forms
      }],
      title: 'Content Studio',
      icon: 'InkPenOutline'
    }
  ],
}

export const localStorageLookup = {
  app: {
    key: 'track',
    version: 1
  },
  shared: {
    key: 'sparx',
    version: 1
  }
};

export const firstMessageCharacterLimit = 160;
export const messageCharacterLimit = 152;
export const messageTotalLimit = 4;
export const ASSUMED_VARIABLE_LENGTH = 10;

export const draftailStripAndReplaceLineBreaks = (message) => {
  // Remove all p tags
  return message.replace(/(?:<p>|<\/p>)/g, '')
}

export const smsMaximumSize = () => messageCharacterLimit * messageTotalLimit;

export const whatsappMessageCharacterLimit = 1024;

export const SmartTagListEmpty = [
  {
    variableName: 'no results',
    description: 'There are no smart tags matching your search'
  }
];

const includeVariablesCount = (strippedMessage, variablesCount) =>
  strippedMessage.length + variablesCount * ASSUMED_VARIABLE_LENGTH;

const removeVariables = (variables) => {
  variables.forEach((variable) => {
    if (variable.parentElement) variable.parentElement.removeChild(variable);
  });
};

export const getTotalMessageCharacterCount = (message) => {
  const div = document.createElement('div');
  div.innerHTML = message;
  const variables = div.querySelectorAll('span[data-systemvariable]');
  removeVariables(variables);
  return includeVariablesCount(div.innerHTML, variables.length);
};

import React, { useState, useEffect } from 'react';

import { variableIds, constantGenerators } from '@whispir/expression-helper';

import { WrappedComponentProps } from '../../../definitions/operatorAndOperand';
import { TextField } from '../../../../../Atoms/Forms';
import {
  WithinRangeWrapper,
  StyledDropdown,
} from './WithinRangeDateComponent.style';

const { NUMBER } = variableIds;

const { generateDateRangeConstant } = constantGenerators;

const durationUnitOptions = [
  {
    label: 'Days',
    value: 'day',
  },
  {
    label: 'Weeks',
    value: 'week',
  },
  {
    label: 'Months',
    value: 'month',
  },
  {
    label: 'Years',
    value: 'year',
  },
];

const withinRangeDateDefaultValueTransform = (defaultValue) => {
  let defaultRangeValue = {
    durationValue: '1',
    durationUnit: 'day',
  };

  if (defaultValue && defaultValue.valueType === NUMBER) {
    defaultRangeValue = {
      durationValue: defaultValue.value || null,
      durationUnit:
        (defaultValue.context && defaultValue.context.durationUnit) || null,
    };
  }

  return defaultRangeValue;
};

export const WithinRangeDateComponent = ({
  defaultValue,
  onChange = () => undefined,
  ...rest
}: WrappedComponentProps) => {
  const [state, setState] = useState(
    withinRangeDateDefaultValueTransform(defaultValue)
  );
  const foundDuration = durationUnitOptions.find(
    (v) => v.value === state.durationUnit
  );
  const selectedDuration = foundDuration ? foundDuration.value : null;

  useEffect(() => {
    const { durationValue, durationUnit } = state;
    const dateRangeConstant = generateDateRangeConstant({
      durationValue,
      durationUnit,
    });
    onChange(dateRangeConstant);
  }, [state]);

  const handleUnitChange = (value) => {
    setState({
      ...state,
      durationValue: value,
    });
  };

  const handleDurationUnitChange = (value) => {
    setState({
      ...state,
      durationUnit: value,
    });
  };

  return (
    <WithinRangeWrapper>
      <TextField
        autoFocus
        type='number'
        placeholder='1'
        defaultValue={state.durationValue}
        onChange={handleUnitChange}
      />
      <StyledDropdown
        options={durationUnitOptions}
        value={selectedDuration}
        onChange={handleDurationUnitChange}
      />
    </WithinRangeWrapper>
  );
};

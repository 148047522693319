import React from 'react';
import { IconTypes, Icon } from '../Icon';
import { StyledSquareIconButton } from './SquareIconButton.styles';

type SquareIconButtonProps = {
  icon: IconTypes;
  label: string;
  onClick?: () => void;
  disabled?: boolean;
};

export const SquareIconButton = (props: SquareIconButtonProps) => {
  const { icon, label, onClick, disabled } = props;

  return (
    <StyledSquareIconButton onClick={onClick} disabled={disabled}>
      <div className='icon-wrapper'>
        <Icon icon={icon} />
      </div>
      <span>{label}</span>
    </StyledSquareIconButton>
  );
};

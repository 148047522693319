import styled from 'styled-components';
import { Colors } from '../../Foundation/Colors';

export const LinkSourceTextWrapper = styled.div`
  margin: 16px;
  width: 280px;

  .LinkSrcField {
    margin-bottom: 24px;
  }

  .MuiInputBase-adornedEnd {
    padding: 0;
  }

  .MuiInputAdornment-positionEnd {
    margin-left: -4px;
  }

  .LinkOutAdornment {
    margin: 8px 2px 8px 4px;
    padding: 4px;
  }

  .CloseAdornment {
    margin: 8px 4px 8px 2px;
    padding: 4px;
  }
`;

export const LinkAdornmentStyle = {
  fill: Colors.black,
  height: '16px',
  width: '16px',
};

import React, { useState } from 'react';
import { SearchField } from '../../Atoms/Forms';
import { IconButton } from '../../Atoms/Navigations';
import { FilterPicker } from '../../Molecules/FilterPicker';

import { StyledWrapper } from './FilterToolbar.styles';
import { FilterToolbarProps } from './FilterToolbar.types';

export const FilterToolbar = ({
  availableFilters,
  onChangeFilters,
  selectedFilters,
  searchLabel = '',
  searchDefaultValue = '',
  searchOnColumnKey = '',
  onChangeSearch,
  onSearchExpanded,
}: FilterToolbarProps): JSX.Element => {
  const [isSearchFieldVisible, setIsSearchVisible] = useState(false);

  const handleToggleSearchVisibility = () => {
    if (onSearchExpanded) onSearchExpanded(!isSearchFieldVisible);
    setIsSearchVisible((prev) => !prev);
  };

  const handleSearch = (value) => {
    onChangeSearch({
      label: searchLabel,
      dataType: 'FormTextInput',
      columnKey: searchOnColumnKey,
      condition: {
        operator: 'contains',
        operand: {
          value,
          valueType: 'string',
        },
      },
    });
  };

  return (
    <StyledWrapper>
      <div className='filter-toolbar-actions'>
        <FilterPicker
          availableFilters={availableFilters}
          selectedFilters={selectedFilters}
          onChange={onChangeFilters}
          // NOTE: Set 10 as limit for now
          maxFilters={10}
        />
        {!!searchLabel.length && !!searchOnColumnKey.length && (
          <IconButton
            id='filter-toolbar-search'
            icon={isSearchFieldVisible ? 'Close' : 'Search'}
            onClick={handleToggleSearchVisibility}
          />
        )}
      </div>
      {isSearchFieldVisible && (
        <SearchField
          placeholder={`Search ${searchLabel}`}
          onChange={handleSearch}
          defaultValue={searchDefaultValue}
          autoFocus
        />
      )}
    </StyledWrapper>
  );
};

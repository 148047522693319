import moment from 'moment';

import { RowData, SortOrderType, Cell } from './SimpleTable.types';

const getTargetColumnValueInCellsForString = (
  row: RowData,
  cellIndex: number
) => {
  const cell = row.cells[cellIndex];
  return (cell && cell.name) || '';
};

const getTargetColumnValueInCellsForDate = (
  row: RowData,
  cellIndex: number
) => {
  const cell = row.cells[cellIndex];
  return moment((cell && cell.name) || '').toDate();
};

const stringSort = (
  firstRow: RowData,
  secondRow: RowData,
  columnIndexToSort: number,
  isAscendingOrder: boolean
) => {
  const firstString: string = getTargetColumnValueInCellsForString(
    firstRow,
    columnIndexToSort
  );
  const secondString: string = getTargetColumnValueInCellsForString(
    secondRow,
    columnIndexToSort
  );
  if (isAscendingOrder) {
    return firstString.localeCompare(secondString);
  }
  return secondString.localeCompare(firstString);
};

const dateSort = (
  firstRow: RowData,
  secondRow: RowData,
  columnIndexToSort: number,
  isAscendingOrder: boolean
) => {
  const firstDate: string = getTargetColumnValueInCellsForDate(
    firstRow,
    columnIndexToSort
  );
  const secondDate: string = getTargetColumnValueInCellsForDate(
    secondRow,
    columnIndexToSort
  );
  const firstDateTime = firstDate.getTime();
  const secondDateTime = secondDate.getTime();
  if (isAscendingOrder) {
    return firstDateTime - secondDateTime;
  }
  return secondDateTime - firstDateTime;
};

export const initiateSort = (
  bodyData: Array<RowData>,
  columnIndexToSort: number,
  sortOrderType: SortOrderType,
  sortType: Cell.sortType
) => {
  return bodyData.sort((firstRow, secondRow): number => {
    if (sortType === 'string') {
      return stringSort(
        firstRow,
        secondRow,
        columnIndexToSort,
        sortOrderType === 'asc'
      );
    }
    if (sortType === 'date') {
      return dateSort(
        firstRow,
        secondRow,
        columnIndexToSort,
        sortOrderType === 'asc'
      );
    }
    return 0;
  });
};

import styled from 'styled-components';
import { Colors } from '../../../Foundation/Colors';

import { LineChartPropTypes } from './LineChart.types';

export const StyledLineChart = styled.div<LineChartPropTypes>`
  width: ${(props): string => props.width || '100%'};
  height: ${(props): string => props.height || '400px'};
  background-color: ${(props): string => props.backgroundColor || Colors.white};
  position: relative;

  .lineChartWrapper {
    position: absolute;
    width: 100%;
    height: 100%;

    svg {
      overflow: visible;
    }
  }
`;

import { createGlobalStyle } from 'styled-components';
import { Colors } from '../Colors';
import { Depth, Elevations } from '../Elevations';
import { BaseFonts, Fonts } from '../Fonts';

const maxDropDownHeight = '408px';

export const GlobalStyle = createGlobalStyle`
  ${BaseFonts};

  html,
  body {
    padding: 0;
    height: 100%;
    margin: 0;
    font-family: 'Inter', 'Capisce-Display', 'Capisce-Italic', 'Capisce-Text', sans-serif;
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: grayscale;
    overscroll-behavior: none;
  }

  .css-canary {
    border: dashed 1px red; 
    font-family: 'Capisce-Display'; 
    font-size: 72px; 
  }
  .css-canary::before {
    content: "css canary"; 
  }

  #no-transition-popper {
    .workflow-delete-btn--popper-paper {
      :hover {
        background-color: ${Colors.background};
      }
    }
  }

  .hidden {
    position:absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
  }

  div[id^="menu-"] {
    max-height: ${maxDropDownHeight};
    z-index: calc(${Depth.header} + 2);

    li {
      ${Fonts.bodySmall};
      color: ${Colors.grey_blue};
    }

    div[role="document"] {
      box-shadow: ${Elevations.elevation_1};
    }
  }
  .mui-fixed {
    z-index: calc(${Depth.header} + 1);
  }
  #css-transition-container {
    width: 100%;
    height: 100%;
  }
  #root {
    width: 100%;
    min-height: 100%;
    background-color: ${Colors.background};
  }
  // disable blue focus halo for all elements
  *:focus {
     outline: none;
  }

  .checked-checkbox {
    color: ${Colors.accent} !important;
  }
`;

export const fieldVerticalSpacing = 8;
export const fieldHorizontalSpacing = 15;
export const fieldWrapperStyles = `
  margin: 0px -${fieldHorizontalSpacing}px;
  & > * {
    margin: ${fieldVerticalSpacing}px ${fieldHorizontalSpacing}px;
  }
`;

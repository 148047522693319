import React from 'react';
import { Button } from '../../Atoms/Navigations';
import { TabComponent } from '../../Molecules/TabComponent';
import { PrebuiltTemplateCardButton } from '../PrebuildTemplateCardButton';
import {
  CardContainer,
  StyledPrebuiltTemplatesSelector,
  StyledSubHeader,
  TemplateGroupWrapper,
} from './PrebuiltTemplatesSelector.styles';
import { PrebuiltTemplatesSelectorProps } from './PrebuiltTemplatesSelector.types';

const SubHeader = ({
  title,
  description,
}: {
  title: string;
  description: string;
}) => (
  <StyledSubHeader>
    <span className='subhead-type'>{title}</span>
    <span className='subhead-description'>{description}</span>
  </StyledSubHeader>
);

export const PrebuiltTemplatesSelector = (
  props: PrebuiltTemplatesSelectorProps
): JSX.Element => {
  const {
    onCancelClick,
    onTemplateClick,
    startFromScratchTemplates,
    prebuiltTemplates,
    surveyTemplates,
  } = props;

  const LegacyStarterTemplates = () => (
    <TemplateGroupWrapper>
      <SubHeader
        title='Start with a Pre-Built Workflow'
        description='Choose from one our our templated workflow definitions.'
      />
      <CardContainer>
        {prebuiltTemplates.map(({ image, title, text, templateId }) => (
          <PrebuiltTemplateCardButton
            Image={image}
            title={title}
            text={text}
            onClick={() => onTemplateClick(templateId)}
            key={templateId}
            buttonText='Use Template'
          />
        ))}
      </CardContainer>
    </TemplateGroupWrapper>
  );

  const tabScreens = [
    {
      id: 0,
      name: 'Use A Template',
      content: <LegacyStarterTemplates />,
    },
    {
      id: 1,
      name: 'Starter Surveys',
      content: (
        <TemplateGroupWrapper>
          <SubHeader
            title='Start with a Pre-Built Survey'
            description='Use a prebuilt survey as a starting point for your workflow'
          />
          <CardContainer>
            {surveyTemplates.map(({ image, title, text, templateId }) => (
              <PrebuiltTemplateCardButton
                Image={image}
                title={title}
                text={text}
                onClick={() => onTemplateClick(templateId)}
                key={templateId}
                buttonText='Use Survey'
              />
            ))}
          </CardContainer>
        </TemplateGroupWrapper>
      ),
    },
  ];

  return (
    <StyledPrebuiltTemplatesSelector>
      <Button
        className='cancel-button'
        text='Cancel'
        endIcon='Close'
        type='secondary'
        onClick={onCancelClick}
        variant='link'
        size='medium'
      />
      <div className='main-wrapper'>
        <div className='main-title'>Create A New Workflow</div>
        <TemplateGroupWrapper>
          <SubHeader
            title='Start From Scratch'
            description='Select your trigger. Every time this trigger event happens, your workflow will run.'
          />
          <CardContainer className='from-scratch'>
            {startFromScratchTemplates.map(
              ({ image, title, text, templateId }) => (
                <PrebuiltTemplateCardButton
                  Image={image}
                  title={title}
                  text={text}
                  onClick={() => onTemplateClick(templateId)}
                  key={templateId}
                  buttonText='Use Trigger'
                />
              )
            )}
          </CardContainer>
        </TemplateGroupWrapper>
        {surveyTemplates.length === 0 ? (
          <LegacyStarterTemplates />
        ) : (
          <TabComponent
            tabs={tabScreens}
            size='small'
            className='prebuilt-templates-selector'
          />
        )}
      </div>
    </StyledPrebuiltTemplatesSelector>
  );
};

import React, { useCallback } from 'react';
import { BLOCK_TYPE, INLINE_STYLE } from '@whispir/draftail';
import { UIVariableGroup } from '@whispir/variables';
import {
  DraftailBlock,
  DraftailInlineStyle,
} from '../DraftailEditorWithVariables';
import { DraftailEditorWithVariables } from '../DraftailEditorWithVariables/DraftailEditorWithVariables';
import { Icons } from '../../Foundation/Icons';
import {
  fromHTML,
  toHTML,
  validateRawContentStateForInvalidVariables,
} from '../draftailUtils';
import { StyledGenericTextareaWithVariables } from './GenericTextareaWithVariables.styles';

export type GenericTextareaWithVariablesProps = {
  ariaLabel?: string;
  showVariableButton?: boolean;
  variableGroups: Array<UIVariableGroup>;
  title?: string;
  initialValue: string;
  onSave: (htmlString: string) => void;
  blockTypes?: Array<DraftailBlock>;
  inlineStyles?: Array<DraftailInlineStyle>;
  includeLinkEntity?: boolean;
};

const DEFAULT_BLOCK_TYPES = [
  { type: BLOCK_TYPE.UNSTYLED },
  { type: BLOCK_TYPE.HEADER_ONE },
  { type: BLOCK_TYPE.HEADER_TWO },
  { type: BLOCK_TYPE.HEADER_THREE },
  {
    type: BLOCK_TYPE.UNORDERED_LIST_ITEM,
    icon: <Icons.BulletedList />,
  },
  {
    type: BLOCK_TYPE.ORDERED_LIST_ITEM,
    icon: <Icons.NumberedList />,
  },
];

const DEFAULT_INLINE_STYLES = [
  { type: INLINE_STYLE.BOLD },
  { type: INLINE_STYLE.ITALIC },
  { type: INLINE_STYLE.UNDERLINE },
  { type: INLINE_STYLE.STRIKETHROUGH },
];

/**
 * Note, this component behaves as an _uncontrolled component_.
 *
 * That is - the intial value only applies once (on mount) and can not otherwise be set by the parent.
 * @param props
 */
export const GenericTextareaWithVariables = (
  props: GenericTextareaWithVariablesProps
): JSX.Element => {
  const {
    ariaLabel,
    title,
    initialValue,
    onSave,
    showVariableButton = true,
    variableGroups,
    blockTypes = DEFAULT_BLOCK_TYPES,
    inlineStyles = DEFAULT_INLINE_STYLES,
    includeLinkEntity = true,
  } = props;

  const validatedDratailObject = validateRawContentStateForInvalidVariables({
    rawContentState: fromHTML(initialValue),
    variableGroups,
  });

  const handleSave = useCallback(
    (draftailContent) => {
      const html = toHTML(draftailContent);
      onSave(html);
    },
    [onSave]
  );

  return (
    <StyledGenericTextareaWithVariables
      isShowingVariableMenu={showVariableButton}
      hasTitle={!!title}
    >
      {title && <div className='title'>{title}</div>}

      <div className='draftail-wrapper'>
        <DraftailEditorWithVariables
          ariaLabel={ariaLabel}
          label={title}
          blockTypes={blockTypes}
          inlineStyles={inlineStyles}
          includeLinkEntity={includeLinkEntity}
          rawContentState={validatedDratailObject}
          onSaveHandler={handleSave}
          placeholder='Enter Text...'
          variableGroups={variableGroups}
          variableButtonClassName='dropdown-adornment'
        />
      </div>
    </StyledGenericTextareaWithVariables>
  );
};

export const SmartTagList = [
  {
    tagName: 'date',
    tagDescription: 'Current date (for example, yyyy/mm/dd)',
  },
  {
    tagName: 'day',
    tagDescription: 'Day in spoken form (for example, Wednesday)',
  },
  {
    tagName: 'dd',
    tagDescription: 'Current day with leading zero (for example, 08)',
  },
  {
    tagName: 'distributionlistname',
    tagDescription: 'Distribution list name',
  },
  {
    tagName: 'first_name',
    tagDescription: 'Recipient first name',
  },
  {
    tagName: 'hrs',
    tagDescription:
      'Current hour in 24-hour format, with leading zero (for example, 08)',
  },
  {
    tagName: 'last_name',
    tagDescription: 'Recipient last name',
  },
  {
    tagName: 'min',
    tagDescription: 'Current minute with leading zero (for example, 07)',
  },
  {
    tagName: 'mm',
    tagDescription: 'Current month with leading zero (for example, 06)',
  },
  {
    tagName: 'month',
    tagDescription: 'Month in spoken form (for example, June)',
  },
  {
    tagName: 'personal_address_primary',
    tagDescription: 'Recipient primary personal address',
  },
  {
    tagName: 'personal_email_primary',
    tagDescription: 'Recipient primary personal email address',
  },
  {
    tagName: 'personal_phone_primary',
    tagDescription: 'Recipient primary personal phone',
  },
  {
    tagName: 'recipient_additionalrole',
    tagDescription: 'The existing Additional Role field',
  },
  {
    tagName: 'recipient_email',
    tagDescription:
      'Recipient current default email address for the Email channel',
  },
  {
    tagName: 'recipient_first_name',
    tagDescription: 'Recipient first name',
  },
  {
    tagName: 'recipient_last_name',
    tagDescription: 'Recipient last name',
  },
  {
    tagName: 'recipient_role',
    tagDescription: 'Recipient role',
  },
  {
    tagName: 'recipient_sms',
    tagDescription:
      'Recipient current default phone number for the Mobile channel',
  },
  {
    tagName: 'recipient_voice',
    tagDescription:
      'Recipient current default phone number for the Voice channel',
  },
  {
    tagName: 'sec',
    tagDescription: 'Current second with leading zero (for example, 09)',
  },
  {
    tagName: 'sender_email',
    tagDescription: 'Message author current default email address',
  },
  {
    tagName: 'sender_first_name',
    tagDescription: 'Message author first name',
  },
  {
    tagName: 'sender_full_name',
    tagDescription: 'Message author full name',
  },
  {
    tagName: 'sender_last_name',
    tagDescription: 'Message author last name',
  },
  {
    tagName: 'sender_sms',
    tagDescription: 'Message author current default SMS number',
  },
  {
    tagName: 'short_hour',
    tagDescription: 'Current time in 12-hour format (for example, 01:05 am)',
  },
  {
    tagName: 'time',
    tagDescription: 'Current time in 24 -hour format',
  },
  {
    tagName: 'work_address_primary',
    tagDescription: 'Recipient primary work address',
  },
  {
    tagName: 'work_mobile_secondary',
    tagDescription: 'Recipient secondary work mobile phone',
  },
  {
    tagName: 'yy',
    tagDescription: 'Current year, short form (for example, 18)',
  },
  {
    tagName: 'yyyy',
    tagDescription: 'Current year, long form (for example, 2018)',
  },
  {
    tagName: 'web_link',
    tagDescription: 'web content link',
  },
];

export const SmartTagListEmpty = [
  {
    tagName: 'no results',
    tagDescription: 'There are no smart tags matching your search',
  },
];

import styled from 'styled-components';
import { Colors } from '../../../Foundation/Colors';
import { Fonts } from '../../../Foundation/Fonts';

export const ToneOfVoiceSmsPanelWrapper = styled.div`
  .tone-helper-text {
    ${Fonts.caption}
    color: ${Colors.grey_blue_4};
    padding-bottom: 4px;
  }

  .settings-description-wrapper {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: ${Colors.white};
    padding: 0px 0px 16px;
  }

  .settings-helper-text {
    ${Fonts.caption}
    color: ${Colors.grey_blue_4};
  }

  .settings-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 8px;
  }

  .settings-bottom-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 52px;
  }

  .tov-info-banner {
    margin-bottom: 24px;
  }
`;

export const StyledInteractiveTooltip = styled.div`
  width: 166px;
  height: 54px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .learn-more-button {
    color: ${Colors.accent_2};
    cursor: pointer;
  }
`;

export const SettingsHeadingWrapper = styled.div`
  display: flex;
  align-items: center;
  ${Fonts.capsHeading}
  color: ${Colors.grey_blue_4};

  svg {
    fill: ${Colors.grey_blue_4};
    height: 16px;
    width: 16px;
    margin-right: 8px;
  }
`;

export const CloseIconWrapper = styled.div`
  display: flex;

  svg {
    cursor: pointer;
    fill: ${Colors.grey_blue_4};
  }
`;

export const InfoPanelWrapper = styled.div`
  > * {
    width: 100%;
    padding: 0.5rem 1rem 0.5rem 0;
    margin-top: 0.5rem;
  }
`;

export const ToneOfVoiceInfoWrapper = styled.div`
  margin-top: 1rem;

  > .tov-info-banner {
    margin-bottom: 0;
  }
`;

import React from 'react';
import {
  ToneType,
  ToneValueType,
} from '../../Utils/ToneOfVoice/toneOfVoiceTypes';
import { HorizontalSelector } from '../../Atoms/HorizontalSelector';
import { StyledToneCard } from './ToneOfVoiceSettingsCard.style';

type Props = {
  onToneChoice: (tone: ToneType, value: ToneValueType) => void;
  emoji: string;
  tone: ToneType;
  example: string;
  toneValue: ToneValueType;
  disabled?: boolean;
};

export const ToneOfVoiceSettingsCard = ({
  onToneChoice,
  emoji,
  tone,
  example,
  toneValue,
  disabled = false,
}: Props): JSX.Element => {
  const handleOnToneChoice = (tone, value) => {
    return onToneChoice(tone, value);
  };

  return (
    <StyledToneCard disabled={disabled}>
      <div className='title'>
        <span className='icon'>{emoji}</span>
        {tone}
      </div>
      <div className='selector'>
        <HorizontalSelector
          type={tone}
          toneValue={toneValue}
          items={[
            {
              label: 'Low',
              value: 'low',
            },
            {
              label: 'Moderate',
              value: 'medium',
            },
            {
              label: 'Strong',
              value: 'high',
            },
          ]}
          onChange={handleOnToneChoice}
          fullWidth
          disabled={disabled}
        />
      </div>
      <div className='description'>
        <span className='bold'>Example:</span> {example}
      </div>
    </StyledToneCard>
  );
};

import styled from 'styled-components';
import { Colors } from '../../../Foundation/Colors';
import { Fonts } from '../../../Foundation/Fonts';

export const TextFieldWrapperStyles = `
  .MuiFormLabel-root {
    color: ${Colors.grey_blue};
    ${Fonts.link};
    display: block;
    padding: 4px 0;

    .Mui-focused {
      color: ${Colors.accent};
      ${Fonts.link};
    }

    .Mui-error {
      color: ${Colors.error};
    }

    &.MuiInputLabel-shrink {
      transform: none;
      transform-origin: top left;
    }
  }

  .MuiFormControl-marginNormal {
    margin-bottom: 0px;
    margin-top: 0px;
  }

  .MuiInput-underline {
    :hover {
      box-shadow: inset 0px -1px 0px 0px ${Colors.accent_dark};

      :not(.Mui-disabled):before {
        box-shadow: inset 0px -1px 0px 0px ${Colors.accent_dark};
      }
    }

    :before {
      box-shadow: inset 0px -1px 0px 0px ${Colors.grey_blue_3};
    }

    &.Mui-disabled {
      :before {
        border: 0;
      }
    }

    &.Mui-error {
      box-shadow: inset 0px -1px 0px 0px ${Colors.error};
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;

      :after {
        border-bottom-width: 1px;
      }
    }

    &.Mui-focused {
      box-shadow: inset 0px -1px 0px 0px ${Colors.accent};
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;

      &.Mui-error {
        box-shadow: inset 0px -1px 0px 0px ${Colors.error};

        :after {
          box-shadow: inset 0px -1px 0px 0px ${Colors.error};
        }
      }

      ::after,
      ::before {
        box-shadow: inset 0px -1px 0px 0px ${Colors.accent};
      }
    }
  }

  .MuiFormHelperText-root {
    color: ${Colors.grey_blue};
    ${Fonts.link};
    text-align: left;
    white-space: nowrap;

    &.Mui-error {
      color: ${Colors.error};
    }
  }

  .MuiInput-input {
    ${Fonts.body};
    color: ${Colors.grey_blue};

    ::placeholder {
      color: ${Colors.grey_blue};
      ${Fonts.body};
    }
  }

  /* The following rules override the default behaviour of MUI
     See below for more details
     https://github.com/mui-org/material-ui/issues/12255#issuecomment-511693322
  **/
  .MuiInputLabel-formControl {
    position: static;
  }
  label + .MuiInput-formControl {
    margin-top: -4px;
  }

  .MuiFilledInput-root,
  .MuiFilledInput-root:hover,
  .MuiFilledInput-root.Mui-focused,
  .MuiFilledInput-root.Mui-error,
  .MuiFilledInput-root.Mui-disabled {
    background-color: ${Colors.grey_blue_0};
  }

  .MuiFilledInput-root {
    border-radius: 4px;

    &.Mui-focused {
      box-shadow: inset 0px -2px 0px 0px ${Colors.accent};
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    }

    &.Mui-error {
      box-shadow: inset 0px -2px 0px 0px ${Colors.error};
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    }

    &.MuiFilledInput-underline:before,
    &.MuiFilledInput-underline:after {
      border: none;
    }

    .MuiFilledInput-input {
      ${Fonts.body};
      color: ${Colors.grey_blue};
      padding: 12px;

      &.Mui-disabled {
        color: ${Colors.grey_blue_3};
      }
    }
  }

  .MuiFilledInput-root + .MuiFormHelperText-contained {
    margin-left: 0;
    margin-top: 8px;

    &.Mui-error {
      ${Fonts.error};
    }
  }
`;

export const TextFieldWrapper = styled.div`
  ${TextFieldWrapperStyles}
`;

import React, { Fragment } from 'react';

import { Divider } from '../../Atoms/Layout';
import { InsightSectionTypes } from './InsightSection.types';
import { ParentWrapper } from './InsightSection.styles';

export const InsightSection = ({
  childComponents,
  dividerHeight,
}: InsightSectionTypes) => {
  return (
    <ParentWrapper
      className='insight-section'
      childComponents={childComponents}
    >
      {childComponents &&
        childComponents.length <= 5 &&
        childComponents.map((childComponent, idx) => {
          const { label, component } = childComponent;
          const childPosition = idx + 1;

          return (
            <Fragment key={`${childPosition}`}>
              <div
                key={`${childPosition}`}
                className={`child-${childPosition} children`}
              >
                <span className='label'>{label}</span>
                <div className='component-wrapper'>{component}</div>
              </div>
              {childPosition !== childComponents.length && (
                <Divider height={dividerHeight} dividerType='solid' />
              )}
            </Fragment>
          );
        })}
    </ParentWrapper>
  );
};

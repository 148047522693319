import { withStyles, List } from '@material-ui/core';
import { Colors } from '../../Foundation/Colors';

export const StyledPopoverMenu = withStyles({
  root: {
    padding: '8px 0',

    '& .MuiButtonBase-root': {
      color: Colors.grey_blue,
      padding: '8px 16px',
      height: '32px',

      '&:hover': {
        backgroundColor: Colors.background,
      },
    },

    '& .MuiListItemIcon-root': {
      fill: Colors.grey_blue_3,
      minWidth: '26px',

      '& svg': {
        width: '14px',
        height: '14px',
      },
    },

    '& .MuiTypography-root': {
      fontFamily: 'Inter',
      fontSize: '14px',
      lineHeight: '22px',
      color: Colors.grey_blue,
    },
  },
})(List);

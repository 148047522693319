import React from 'react';

import { InputLabel, FormHelperText } from '@material-ui/core';

import { TrueFalseString } from '../utilityTypes';
import { Dropdown, DropdownOnChangeType } from '../../Atoms/Forms';
import { FormDropdownWrapper } from './FormDropdown.style';

export type FormDropdownProps = {
  onChange?: DropdownOnChangeType;
  label: string;
  helperText: string;
  requiredField: TrueFalseString;
  paddingTop: string;
  paddingBottom: string;
  optionsPicker:
    | Array<{
        id: unknown;
        text: string;
        checked: TrueFalseString;
      }>
    | string;
};

export const FormDropdown = ({
  onChange = () => undefined,
  helperText,
  requiredField,
  label,
  paddingTop,
  paddingBottom,
  optionsPicker,
}: FormDropdownProps): JSX.Element => {
  const defaultListItemsRaw =
    typeof optionsPicker === 'string'
      ? JSON.parse(optionsPicker)
      : optionsPicker;

  if (!Array.isArray(defaultListItemsRaw)) {
    throw new Error('Options picker was not an array or stringified aray');
  }

  const defaultListItems = defaultListItemsRaw.map(({ id, text, checked }) => ({
    label: text,
    value: id,
    checked: !!checked && checked.toString().toLowerCase() === 'true',
  }));
  const defaultCheckedItem = defaultListItems.find(({ checked }) => checked);

  return (
    <FormDropdownWrapper paddingTop={paddingTop} paddingBottom={paddingBottom}>
      <InputLabel className='input-label' filled shrink>
        {label} {requiredField === 'true' && '*'}
      </InputLabel>
      <Dropdown
        options={defaultListItems}
        value={(defaultCheckedItem && defaultCheckedItem.value) || ''}
        onChange={onChange}
      />
      <FormHelperText>{helperText}</FormHelperText>
    </FormDropdownWrapper>
  );
};

import styled from 'styled-components';

export const QueryFilterTableWrapper = styled.div`
  height: 800px;

  &.full-size {
    height: 100%;
  }

  &.searching {
    .data-table-wrapper {
      /* 126px is the height of the filter toolbar when search bar expanded */
      height: calc(100% - 126px);
    }
  }

  .data-table-wrapper {
    /* 86px is the height of the filter toolbar when search bar closed */
    height: calc(100% - 86px);
  }
`;

import styled from 'styled-components';
import { Colors } from '../../Foundation/Colors';
import { Fonts } from '../../Foundation/Fonts';

export const StyledTemplateSelection = styled.div``;

export const StyledStarterTemplates = styled.div`
  margin: 16px -24px; // -24px: remove the horizontal padding from the Modal body

  .featured {
    margin-top: 48px;

    .featured-title {
      ${Fonts.subheadSerif};
      margin-bottom: 22px;
    }
  }
`;

export const StyledUserTemplates = styled.div`
  margin: 16px -24px; // -24px: remove the horizontal padding from the Modal body

  .featured {
    margin-top: 48px;

    .featured-title {
      ${Fonts.subheadSerif};
      margin-bottom: 22px;
    }
  }
`;

export const StyledTemplatesList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
`;

export const StyledBodyContent = styled.div`
  padding: 0 32px;

  .title-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 40px 0 32px;

    .title {
      ${Fonts.headline};
      color: ${Colors.grey_blue};
    }
  }
`;

import styled from 'styled-components';
import { Colors } from '../../Foundation/Colors';
import { Fonts } from '../../Foundation/Fonts';

export const IconWrapper = styled.i`
  padding-top: 2px;
  padding-left: 6px;

  svg {
    width: auto;
    height: 1rem;
  }
`;

export const LabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const FilterWrapper = styled.div`
  .fp-expression-popper {
    z-index: 99;
  }

  .caption {
    &.opened {
      color: ${Colors.accent};
    }
  }
`;

export const StyledCaptionWrapper = styled.div`
  ${Fonts.caption};
  color: ${Colors.grey_blue_4};
  font-weight: 500;
`;

export const StyledDateWrapper = styled.div`
  width: fit-content;

  .chip {
    border-radius: 4px;
    height: 40px;
    min-width: 240px;
    justify-content: left;

    .MuiChip-label {
      ${Fonts.body};
    }
  }

  &.normal .chip {
    border-bottom: 2px solid ${Colors.white};
    background-color: ${Colors.white} !important; /* important to override the hover effect */
  }

  &.filled {
    .dropdown {
      position: relative;
      right: 32px;
      top: 6px;
      pointer-events: none;
      fill: ${Colors.grey_blue_4};
    }

    .chip {
      border-bottom: 2px solid ${Colors.grey_blue_0};
      background-color: ${Colors.grey_blue_0} !important; /* important to override the hover effect */
      padding-right: 32px;
    }
  }

  &:hover,
  &.opened {
    .chip {
      border-radius: 4px 4px 0 0;
      border-bottom: 2px solid #1515ff;
    }
  }
`;

import styled from 'styled-components';
import { DraftailEditorWrapperProps } from '../../Contexts/global.types';
import { richTextEditorMenuStyles } from '../../DraftailComponents/richTextEditorMenuStyles';
import { Colors } from '../../Foundation/Colors';

export const DraftailEditorWrapper = styled.div`
  position: relative;
  ${richTextEditorMenuStyles}
  padding-bottom: 12px;
  width: 100%;
  .variable-selector {
    position: absolute;
    right: 12px;
    bottom: 12px;
  }
  .Draftail-block {
    &--unstyled,
    &--header-one,
    &--header-two,
    &--header-three {
      color: ${(props: DraftailEditorWrapperProps) => props.textColor};
    }
  }

  .public-DraftEditor-content {
    &:focus {
      cursor: text;
    }
  }

  .DraftEditor-editorContainer,
  .public-DraftEditorPlaceholder-inner {
    padding-top: ${(props: DraftailEditorWrapperProps) => props.paddingTop}px;
    padding-bottom: ${(props: DraftailEditorWrapperProps) =>
      props.paddingBottom}px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 2px;
    background-color: ${(props: DraftailEditorWrapperProps) =>
      props.backgroundColor};
  }

  .public-DraftEditorPlaceholder-inner {
    color: ${Colors.grey_blue};
    font-style: italic;
    position: absolute;
    pointer-events: none;
  }

  .DraftEditor-editorContainer {
    text-align: ${({ alignment }) => alignment};
  }

  .public-DraftEditorPlaceholder-inner {
    left: ${({ alignment }) =>
      (alignment === 'left' && '0') || (alignment === 'center' && '50%')};
    right: ${({ alignment }) => alignment === 'right' && '0'};
    transform: ${({ alignment }) =>
      alignment === 'center' && 'translate(-50%, 0)'};
  }
`;

import React from 'react';
import { ColorTypes } from '../../Foundation/Colors';
import { Icon, IconTypes } from '../Icon';
import { StyledColorIcon } from './ColorIcon.styles';

export type IconColorPair = {
  icon: IconTypes;
  color: ColorTypes;
};

type ColorIconProps = {
  icon: IconTypes;
  color: ColorTypes;
  className?: string;
};

export const ColorIcon = (props: ColorIconProps) => {
  const { icon, color, className = '' } = props;

  return (
    <StyledColorIcon className={className} color={color}>
      <Icon icon={icon}></Icon>
    </StyledColorIcon>
  );
};

export const ColorIconPairs: Record<string, IconColorPair> = {
  Sms: {
    color: 'sms',
    icon: 'Message',
  },
  SmsDraftState: {
    color: 'grey_blue_3',
    icon: 'Message',
  },
  Email: {
    color: 'email',
    icon: 'Email',
  },
  EmailDraftState: {
    color: 'grey_blue_3',
    icon: 'Email',
  },
  Voice: {
    color: 'voice',
    icon: 'Voice',
  },
  Whatsapp: {
    color: 'whatsapp',
    icon: 'Whatsapp',
  },
  Facebook: {
    color: 'blue',
    icon: 'Facebook',
  },
  Web: {
    color: 'web',
    icon: 'Web',
  },
  Send: {
    color: 'grey_blue_4',
    icon: 'Messages',
  },
  FormTrigger: {
    color: 'accent_dark',
    icon: 'WebformTrigger',
  },
  ManualTrigger: {
    color: 'accent_dark',
    icon: 'ManualTrigger',
  },
  WebForm: {
    color: 'success',
    icon: 'WebForm',
  },
  WebFormDraftState: {
    color: 'grey_blue_3',
    icon: 'WebForm',
  },
};

export const ColoredIcons = Object.entries(ColorIconPairs).reduce(
  (acc, [key, value]) => {
    return {
      ...acc,
      [key]: () => <ColorIcon color={value.color} icon={value.icon} />,
    };
  },
  {}
);

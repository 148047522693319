import React from 'react';
import { ColorIconPairs, IconColorPair } from '../../Atoms/ColorIcon';
import { WorkflowCanvasNode } from './WorkflowCanvasNode';

type SendWebformNodeProps = {
  mode: 'not-selected' | 'sms' | 'email';
  recipientCount?: number;
  name: string;
  nodeHasMessageContent: boolean;
  nodeHasLinkContent: boolean;
  isValid: boolean;
};

const DEFAULT_RECIPIENT_COUNT = 0;

export const SendWebformNode = (props: SendWebformNodeProps) => {
  const {
    mode,
    recipientCount = DEFAULT_RECIPIENT_COUNT,
    name,
    nodeHasMessageContent,
    nodeHasLinkContent,
    isValid,
  } = props;

  let description = 'Send Web Form';
  let secondaryIcon: IconColorPair | null = null;
  switch (mode) {
    case 'not-selected': {
      secondaryIcon = null;
      break;
    }

    case 'sms': {
      secondaryIcon = nodeHasMessageContent
        ? ColorIconPairs['Sms']
        : ColorIconPairs['SmsDraftState'];
      description = 'Send Web Form via SMS';
      break;
    }

    case 'email': {
      secondaryIcon = nodeHasMessageContent
        ? ColorIconPairs['Email']
        : ColorIconPairs['EmailDraftState'];
      description = 'Send Web Form via email';
    }
  }

  return (
    <WorkflowCanvasNode
      titleText={name}
      descriptionText={description}
      footerContent={`to ${recipientCount} Recipients`}
      primaryIcon={
        nodeHasLinkContent
          ? ColorIconPairs['WebForm']
          : ColorIconPairs['WebFormDraftState']
      }
      secondaryIcon={secondaryIcon}
      errorMessage={isValid ? null : 'Create message'}
    />
  );
};

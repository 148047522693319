import { ModelType } from '../../utilityTypes';

export const formTextInputModel = (id: string): ModelType => ({
  id,
  type: 'FormTextInput',
  label: 'Text Input',
  componentType: 'form',
  properties: [
    {
      name: 'text',
      label: 'Styles',
      control: 'TitleDivider',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'label',
      label: 'Label',
      control: 'TextFieldFilled',
      value: 'Text Input',
      __typename: 'ComponentProperty',
    },
    {
      name: 'padding_8px_1',
      label: '',
      control: ' ',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'requiredField',
      label: 'Required Field',
      control: 'Checkbox',
      value: 'false',
      __typename: 'ComponentProperty',
    },
    {
      name: 'helperText',
      label: 'Helper Text',
      control: 'VariableTextInput',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'defaultValue',
      label: 'Default Value',
      control: 'VariableTextInput',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'padding',
      label: 'Padding',
      control: 'TitleDividerNoLine',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'paddingTop',
      label: 'Top',
      control: 'Slider',
      value: 20,
      __typename: 'ComponentProperty',
    },
    {
      name: 'paddingBottom',
      label: 'Bottom',
      control: 'Slider',
      value: 20,
      __typename: 'ComponentProperty',
    },
  ],
  __typename: 'Component',
});

import styled from 'styled-components';
import { Colors } from '../../Foundation/Colors';
import { Fonts } from '../../Foundation/Fonts';

export const RadioButtonGroupWrapper = styled.div<{
  compact?: boolean;
}>`
  .button-group-form-control {
    padding: 0;

    .dropdown {
    }

    .dropdown,
    .text-field-form-control input {
      font-size: ${(props) => props.compact && '14px'};
    }

    label {
      span:last-child {
        ${(props) => (props.compact ? Fonts.bodySmall : Fonts.body)};
        color: ${Colors.grey_blue_4};
      }
    }
  }

  .root,
  .checked {
    padding: ${(props) => props.compact && '6px'};
  }

  .root {
    color: ${Colors.grey_blue_4};
    margin-right: 4px;
  }

  .checked {
    color: ${Colors.accent};
  }
`;

export const ComponentWrapper = styled.div<{
  compact?: boolean;
}>`
  padding-left: ${(props) => (props.compact ? '13px' : '26px')};
  margin-left: 9px;
  margin-right: 16px;
  & > :first-child {
    margin: 0px;
  }
`;

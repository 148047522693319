import React from 'react';

import { Modal } from '../../Atoms/Dialogs';
import { Button } from '../../Atoms/Navigations';
import { Illustrations } from '../../Foundation/Illustrations';
import {
  HeaderWrapper,
  ContentWrapper,
  FooterWrapper,
} from './ConfirmationModal.styles';
import { ConfirmationModalTypes } from './ConfirmationModal.types';

export const ConfirmationModal = ({
  title,
  content,
  isOpen,
  handleAction,
  handleExit,
  logo,
  actionButtonText = 'Confirm',
  exitButtonText = 'Cancel',
  hideLogo,
  size = 'small',
  disableAction,
}: ConfirmationModalTypes): JSX.Element => {
  return (
    <Modal
      size={size}
      isOpen={isOpen}
      handleExit={handleExit}
      headerContent={
        <HeaderWrapper>
          {!hideLogo ? (
            <div className='illustrationWrapper'>
              {logo || <Illustrations.Confirm />}
            </div>
          ) : null}
          <p className='titleWrapper'>{title}</p>
        </HeaderWrapper>
      }
      bodyContent={<ContentWrapper>{content}</ContentWrapper>}
      footerContent={
        <FooterWrapper>
          <Button
            variant='link'
            text={exitButtonText}
            onClick={handleExit}
            size='large'
          />
          <Button
            size='large'
            text={actionButtonText}
            onClick={handleAction}
            disabled={disableAction}
          />
        </FooterWrapper>
      }
    />
  );
};

import styled, { css } from 'styled-components';
import { Colors } from '../../Foundation/Colors';
import { Fonts } from '../../Foundation/Fonts';

export const ToneOfVoiceBetaHeaderWrapper = styled.div<{
  minimal: boolean;
}>`
  align-self: flex-start;
  display: flex;
  align-items: center;
  padding: ${({ minimal }) => (!minimal ? '32px 16px 16px' : '0 0 12px')};

  > * + * {
    margin-left: 4px;
  }

  ${(props) =>
    !props.minimal &&
    css`
      > *:first-child {
        margin-right: 4px;
      }
    `}

  .tone-icon {
    width: 16px;
    height: 16px;
    fill: ${Colors.grey_blue_4};
  }

  .info-icon {
    width: 16px;
    height: 16px;
    fill: ${Colors.grey_blue_4};
    position: relative;
    top: 2px;
  }

  .subhead {
    ${Fonts.capsHeading};
    color: ${({ minimal }) =>
      !minimal ? Colors.grey_blue : Colors.grey_blue_4};
  }

  .lozenge-small {
    border-radius: 3px;
    padding: 1px 8px 0 8px;
    background-color: ${Colors.accent_2};
    color: ${Colors.accent_5};
    ${Fonts.capsHeading};
    font-size: 12px;
    ${({ minimal }) => (!minimal ? 'margin-left: 4px;' : '')}
  }
`;

export const StyledInteractiveTooltip = styled.div`
  ${Fonts.caption}
  width: 150px;
  height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .learn-more-button {
    color: ${Colors.accent_2};
    cursor: pointer;
  }
`;

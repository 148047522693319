import styled from 'styled-components';
import { Colors } from '../../../../Foundation/Colors';
import { Fonts } from '../../../../Foundation/Fonts';

export const StyledExpressionLabel = styled.span`
  ${Fonts.bodySmall};
  color: ${Colors.grey_blue_4};
  .value {
    font-weight: bold;
  }
`;

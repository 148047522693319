import React from 'react';

export const PhoneClouds = () => (
  <svg
    width='103'
    height='45'
    viewBox='0 0 103 45'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <defs>
      <linearGradient
        id='phone-cloud-gradient'
        x1='0'
        y1='0'
        x2='0'
        y2='51.0398'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#2F39A2' />
        <stop offset='1' stopColor='#316CD9' />
      </linearGradient>
    </defs>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M80.1595 28.1862C80.4547 28.1862 80.7461 28.2044 81.0323 28.2392C81.8313 28.3365 82.6491 28.1683 83.307 27.7042C84.8279 26.631 86.6825 26 88.6854 26C91.9572 26 94.8355 27.6824 96.505 30.2287C96.7827 30.6525 97.2712 30.8733 97.7781 30.8723H97.7869C100.258 30.8723 102.261 32.8755 102.261 35.3469H98.0308H93.3129H87.3189H79.3397H73C73 31.3921 76.2054 28.1862 80.1595 28.1862'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M75.4338 9.45182C75.4338 4.2356 71.1982 0 65.982 0H9.45182C4.2356 0 0 4.2356 0 9.45182V41.588C0 46.8042 4.2356 51.0398 9.45182 51.0398H65.982C71.1982 51.0398 75.4338 46.8042 75.4338 41.588V9.45182Z'
      fill='url(#phone-cloud-gradient)'
    />
    <rect x='8' y='31' width='45' height='1' fill='white' fillOpacity='0.7' />
    <rect x='8' y='19' width='61' height='1' fill='white' fillOpacity='0.7' />
    <rect x='8' y='35' width='45' height='1' fill='white' fillOpacity='0.7' />
    <rect x='8' y='23' width='61' height='1' fill='white' fillOpacity='0.7' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.24824 11.0052C8.0045 11.0052 8.61757 10.417 8.61757 9.69137C8.61757 8.96577 8.0045 8.37756 7.24824 8.37756C6.49198 8.37756 5.87891 8.96577 5.87891 9.69137C5.87891 10.417 6.49198 11.0052 7.24824 11.0052Z'
      fill='white'
      fillOpacity='0.7'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.6955 11.0052C12.4518 11.0052 13.0648 10.417 13.0648 9.69137C13.0648 8.96577 12.4518 8.37756 11.6955 8.37756C10.9392 8.37756 10.3262 8.96577 10.3262 9.69137C10.3262 10.417 10.9392 11.0052 11.6955 11.0052Z'
      fill='white'
      fillOpacity='0.7'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16.1447 11.0052C16.901 11.0052 17.5141 10.417 17.5141 9.69137C17.5141 8.96577 16.901 8.37756 16.1447 8.37756C15.3885 8.37756 14.7754 8.96577 14.7754 9.69137C14.7754 10.417 15.3885 11.0052 16.1447 11.0052Z'
      fill='white'
      fillOpacity='0.7'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M68.8936 5.63754C69.0956 5.63754 69.2946 5.65072 69.4903 5.67707C70.0364 5.75023 70.5953 5.62391 71.0448 5.27678C72.0843 4.473 73.3523 4 74.7211 4C76.9577 4 78.9247 5.25996 80.0658 7.16695C80.2558 7.48455 80.5895 7.64994 80.9362 7.64903H80.942C82.6308 7.64903 84 9.14936 84 11H81.1087H77.884H73.7873H68.3335H64C64 8.03843 66.191 5.63754 68.8936 5.63754'
      fill='white'
    />
  </svg>
);

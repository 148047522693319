import React, { useEffect } from 'react';

import { UIVariableGroup } from '@whispir/variables';
import { ToneType } from '../../Utils/ToneOfVoice/toneOfVoiceTypes';
import { DraftailEditorWithVariables } from '../DraftailEditorWithVariables/DraftailEditorWithVariables';
import { StyledSmsTextareaWithVariables } from './SmsTextareaWithVariables.styles';

type SmsTextareaWithVariablesProps = {
  showVariableButton?: boolean;
  variableGroups: Array<UIVariableGroup>;
  title?: string;
  initialValue: any;
  selectedTone?: ToneType | null;
  onSave?: (htmlString: string) => void;
  onFocus?: () => void;
};

/**
 * Note, this component behaves as an _uncontrolled component_.
 *
 * That is - the intial value only applies once (on mount) and can not otherwise be set by the parent.
 * @param props
 */
export const SmsTextareaWithVariables = (
  props: SmsTextareaWithVariablesProps
): JSX.Element => {
  const {
    title,
    initialValue,
    showVariableButton = true,
    variableGroups,
    onSave,
    selectedTone,
  } = props;

  useEffect(() => {
    const { document } = window;
    const disableGrammarlyStyles = `
      grammarly-desktop-integration, grammarly-popups, grammarly-extension {
        display: none;
      }
    `;
    const head = document.head || document.querySelector('head');
    const styleElement = document.createElement('style');
    styleElement.innerHTML = disableGrammarlyStyles;
    head.append(styleElement);
    return function cleanup() {
      head.removeChild(styleElement);
    };
  });

  return (
    <StyledSmsTextareaWithVariables isShowingVariableMenu={showVariableButton}>
      {title && <div className='title'>{title}</div>}

      <div className='draftail-wrapper'>
        <DraftailEditorWithVariables
          label={title}
          includeLinkEntity={false}
          rawContentState={initialValue}
          placeholder='Enter Text...'
          variableGroups={variableGroups}
          variableButtonClassName='dropdown-adornment'
          onSaveHandler={onSave}
          spellCheck
          controlled
          valueToUpdateOn={selectedTone}
        />
      </div>
    </StyledSmsTextareaWithVariables>
  );
};

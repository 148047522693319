import React, { useRef, useState } from 'react';
import { Button, IconButton } from '../../Atoms/Navigations';
import { Icon } from '../../Atoms/Icon';
import { convertBase64, formatBytes, filseSize10MB } from './Utils';
import { FileSelectorTypes } from './FileSelector.types';
import { FileSelectorWrapper } from './FileSelector.styles';

export const FileSelector = ({
  uploadPrompt = 'Select your file or drag and drop it here',
  acceptedText = '',
  acceptedExtension = '',
  blobType = 'raw',
  onFileChange,
  maxSizeByte = filseSize10MB,
  size = 'small',
  disableFileInfo = false,
}: FileSelectorTypes): JSX.Element => {
  const inputElement = useRef(null);
  const [fileName, setFileName] = useState('');
  const [fileSize, setFileSize] = useState('');

  const handleInputChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    fileSelectionType: 'drag' | 'browse'
  ) => {
    const { currentTarget: { files } = {} } = event;
    const file = files && files[0];
    setFileSize(file ? file.size.toString() : '');
    setFileName(file ? file.name : '');
    if (file && file.size > parseInt(maxSizeByte)) {
      onFileChange(null);
    } else if (blobType !== 'raw') {
      const base64 = await convertBase64(file);
      const parsedBase64 = (base64 as string).split(',').pop();
      if (blobType === 'both' && file && parsedBase64) {
        onFileChange({ base64: parsedBase64, file, fileSelectionType });
      } else {
        onFileChange(parsedBase64);
      }
    } else onFileChange(file);
  };

  const handleDelete = () => {
    setFileSize('');
    setFileName('');
    onFileChange(null);
  };

  const handleSelect = ({ current }) => current.click();
  const sizeLimit = parseInt(fileSize) > parseInt(maxSizeByte);

  /** Handle drag and drop */
  const handleDragEvent = (event) => event.preventDefault();
  const handleDropEvent = (event) => {
    // prevent browser download
    event.preventDefault();

    const {
      dataTransfer: { files },
    } = event;

    // validate the uploaded file type
    if (acceptedExtension && files) {
      // remove spaces then split into array of extensions
      const validExtensions = acceptedExtension.replace(/ /g, '').split(',');
      const [{ name: fileName }] = files;
      const hasValidExtension = validExtensions.some((ext) =>
        fileName.endsWith(ext)
      );

      if (!hasValidExtension) return;
    }

    // convert Drop event to file File InputEvent
    const newInputEvent = {
      currentTarget: { files },
    } as React.ChangeEvent<HTMLInputElement>;
    handleInputChange(newInputEvent, 'drag');
  };

  return (
    <FileSelectorWrapper size={size}>
      {!disableFileInfo && fileName && fileSize ? (
        <div className='details'>
          <div className='icon'>
            <Icon icon='UploadSuccess' />
          </div>
          <div className='filename'>
            {fileName}
            <div className='filesize'>
              {sizeLimit ? (
                <span className='error'>{`${fileName} is over the size limit`}</span>
              ) : (
                formatBytes(fileSize)
              )}
            </div>
          </div>
          <IconButton
            className='close-button'
            icon='Close'
            size='small'
            onClick={handleDelete}
          />
        </div>
      ) : (
        <Button
          className='select-file'
          aria-label='file-selector-button'
          variant='link'
          onClick={() => handleSelect(inputElement)}
          fullWidth
          onDrop={handleDropEvent}
          /** Needed to handle drag event to stop browser from downloading the file */
          onDragOver={handleDragEvent}
        >
          <Icon icon='CloudUpload' />
          <input
            aria-label='file-selector-input'
            type='file'
            ref={inputElement}
            name='files'
            onChange={(event) => handleInputChange(event, 'browse')}
            accept={acceptedExtension}
          />
          <div>{uploadPrompt}</div>
          <div className='accepted-text'>{acceptedText}</div>
        </Button>
      )}
    </FileSelectorWrapper>
  );
};

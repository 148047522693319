import React, { useState } from 'react';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { FormHelperText } from '@material-ui/core';
import { Icon } from '../Icon';
import { AlignmentSelectorWrapper } from './AlignmentSelector.styles';

export type AlignmentSelectorValue = 'left' | 'center' | 'right';

export type AlignmentSelectorProps = {
  includeCenterAlignment: boolean;
  onChange: (value: AlignmentSelectorValue) => void;
  label?: string;
  defaultValue?: AlignmentSelectorValue;
  iconType: 'leftright' | 'items' | 'text';
};

export const AlignmentSelector = ({
  iconType,
  includeCenterAlignment = true,
  label = '',
  onChange = () => undefined,
  defaultValue = includeCenterAlignment ? 'center' : 'left',
}: AlignmentSelectorProps): JSX.Element => {
  const [{ placement }, setPlacement] = useState({ placement: defaultValue });

  const handleChange = (_event, placement: AlignmentSelectorValue) => {
    setPlacement({ placement });
    if (placement) {
      onChange(placement);
    }
  };

  const iconLeft =
    iconType === 'leftright'
      ? 'IconAlignmentLeft'
      : iconType === 'items'
      ? 'AlignmentLeft'
      : 'ParagraphAlignLeft';

  const iconRight =
    iconType === 'leftright'
      ? 'IconAlignmentRight'
      : iconType === 'items'
      ? 'AlignmentRight'
      : 'ParagraphAlignRight';

  const iconCenter =
    iconType === 'items' ? 'AlignmentMiddle' : 'ParagraphAlignCentre';

  return (
    <AlignmentSelectorWrapper
      label={label}
      className='text-position-form-control'
    >
      <FormHelperText className='placement-label' component='label' filled>
        {label}
      </FormHelperText>
      <ToggleButtonGroup
        className='placement-group'
        aria-label={label}
        value={placement}
        exclusive
        onChange={handleChange}
      >
        <ToggleButton
          className='placement-item'
          classes={{ selected: 'toggle-selected' }}
          value='left'
          aria-label='left aligned'
        >
          <Icon icon={iconLeft} />
        </ToggleButton>

        {includeCenterAlignment && (
          <ToggleButton
            className='placement-item'
            classes={{ selected: 'toggle-selected' }}
            value='center'
            aria-label='center aligned'
          >
            <Icon icon={iconCenter} />
          </ToggleButton>
        )}

        <ToggleButton
          className='placement-item'
          classes={{ selected: 'toggle-selected' }}
          value='right'
          aria-label='right aligned'
        >
          <Icon icon={iconRight} />
        </ToggleButton>
      </ToggleButtonGroup>
    </AlignmentSelectorWrapper>
  );
};

import { FormHelperText } from '@material-ui/core';
import styled from 'styled-components';
import { Fonts } from '../../../Foundation/Fonts';
import { Colors } from '../../../Foundation/Colors';

export const DatePickerFromToPadding = styled.div`
  padding-bottom: 16px;
`;

export const StyledFormHelperText = styled(FormHelperText)`
  &.MuiFormHelperText-root {
    ${Fonts.caption};
    color: ${Colors.error};
  }
`;

import React, { useEffect, useState } from 'react';

import { InfoPanel } from '../../../Molecules/InfoPanel';
import { Divider } from '../../../Atoms/Layout';
import { Button } from '../../../Atoms/Navigations';
import { Icon } from '../../../Atoms/Icon';
import { Tooltip } from '../../../Atoms/Dialogs';
import { ToneDetectorFeedback } from '../../../Molecules/ToneDetectorFeedback';
import { ToneDetectorScore } from '../../../Molecules/ToneDetectorScore';
import {
  ToneSettingsType,
  ToneType,
  ToneValueType,
  ToneAndFeedbackType,
} from '../../../Utils/ToneOfVoice/toneOfVoiceTypes';
import { ToneOfVoiceSettingsCardList } from '../ToneOfVoiceSettingsCardList';
import { ToneScoreCardList } from '../ToneScoreCardList';
import { ToneOfVoiceInfoBanner } from '../../../Molecules/ToneOfVoiceInfoBanner';
import { ToneFeedbackModal } from '../../../Molecules/ToneFeedbackModal';
import {
  calculateTotalTOVScore,
  makeTovArrays,
} from '../../../Utils/ToneOfVoice/toneOfVoiceUtils';
import { toneCardDataArray } from '../../../Utils/exampleData';
import {
  ToneOfVoiceSmsPanelProps,
  ToneSettingsProps,
} from './ToneOfVoiceSmsPanel.types';
import * as S from './ToneOfVoiceSmsPanel.styles';

const DEFAULT_TONE_SCORE_ARRAY = [
  {
    desiredToneIntensity: 'Moderate',
    messageToneIntensity: 'Low',
    tone: 'joyful',
    score: 1,
    sentenceHighlights: 0,
  },
  {
    desiredToneIntensity: 'Moderate',
    messageToneIntensity: 'Low',
    tone: 'warm',
    score: 1,
    sentenceHighlights: 0,
  },
  {
    desiredToneIntensity: 'Moderate',
    messageToneIntensity: 'Low',
    tone: 'surprised',
    score: 1,
    sentenceHighlights: 0,
  },
  {
    desiredToneIntensity: 'Moderate',
    messageToneIntensity: 'Low',
    tone: 'angry',
    score: 1,
    sentenceHighlights: 0,
  },
  {
    desiredToneIntensity: 'Moderate',
    messageToneIntensity: 'Low',
    tone: 'fearful',
    score: 1,
    sentenceHighlights: 0,
  },
  {
    desiredToneIntensity: 'Moderate',
    messageToneIntensity: 'Low',
    tone: 'sad',
    score: 1,
    sentenceHighlights: 0,
  },
];

const ToneSettings = ({
  companyToneSettings,
  campaignToneSettings,
  cardsData,
  setSettingsOpen,
  saveCampaignSettings,
  onSaveCampaignSettings,
  onResetToBrand,
}: ToneSettingsProps): JSX.Element => {
  const [updatedSettings, setUpdatedSettings] = useState<ToneSettingsType>(
    JSON.parse(JSON.stringify(campaignToneSettings))
  );

  const checkCompanySettingsEquality = (): boolean => {
    return (
      JSON.stringify(companyToneSettings) === JSON.stringify(updatedSettings)
    );
  };
  const [
    companySettingsAreEqual,
    setCompanySettingsAreEqual,
  ] = useState<boolean>(checkCompanySettingsEquality());

  const [toneHasChanged, setToneHasChanged] = useState<boolean>(false);

  const handleResetToBrandClick = () => {
    setToneHasChanged(true);
    setUpdatedSettings(JSON.parse(JSON.stringify(companyToneSettings)));
    setCompanySettingsAreEqual(true);
    if (onResetToBrand) onResetToBrand();
  };

  const handleSettingsSaveChangesClick = () => {
    saveCampaignSettings(updatedSettings);
    if (setSettingsOpen) setSettingsOpen(false);
    if (onSaveCampaignSettings) onSaveCampaignSettings();
  };

  const handleOnToneChoice = (tone: ToneType, value: ToneValueType) => {
    setToneHasChanged(true);
    setUpdatedSettings((currentSettings) => ({
      ...currentSettings,
      [tone]: value,
    }));
  };

  useEffect(() => {
    setCompanySettingsAreEqual(checkCompanySettingsEquality());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedSettings]);

  return (
    <>
      <div className='settings-description-wrapper'>
        <div className='settings-heading'>
          <S.SettingsHeadingWrapper>
            <Icon icon='SmartReview' />
            <span>SETTINGS</span>
          </S.SettingsHeadingWrapper>
          <S.CloseIconWrapper>
            <Icon
              icon='Close'
              onClick={() => setSettingsOpen && setSettingsOpen(false)}
            />
          </S.CloseIconWrapper>
        </div>
        {Object.keys(companyToneSettings).length === 0 && (
          <ToneOfVoiceInfoBanner
            handleSetTovButtonClick={() =>
              window.location.assign('/signin/brand')
            }
            handleLearnMoreButtonClick={() =>
              console.info('TOV - Learn more clicked')
            }
            variant='small'
          />
        )}
        <div className='settings-helper-text'>
          Set the desired bracket for each tone for this message campaign. This
          will change the overall Tone of Voice Score.
        </div>
      </div>
      <ToneOfVoiceSettingsCardList
        cards={cardsData}
        toneValues={updatedSettings}
        onToneChoice={handleOnToneChoice}
      />
      <div className='settings-bottom-buttons'>
        <Button
          disabled={companySettingsAreEqual}
          text='Reset to Brand'
          // using 'as any' to surpress type error
          variant={'link' as any}
          onClick={handleResetToBrandClick}
          size='large'
        />
        <Button
          disabled={!toneHasChanged}
          text='Save Changes'
          variant={'button' as any}
          onClick={handleSettingsSaveChangesClick}
          size='large'
        />
      </div>
    </>
  );
};

export const ToneOfVoiceSmsPanel = ({
  companyToneSettings,
  campaignToneSettings,
  saveCampaignSettings,
  onRecalculateScore,
  canRecalculateScore,
  onToneFeedback,
  onToneCardFocused,
  onOverallToneFeedback,
  onEditTones,
  onSaveCampaignSettings,
  onResetToBrand,
  tovPredictions,
  selectedTone,
  onToneCardBlur,
  tovDataLoading,
  tovDataNotSync,
}: ToneOfVoiceSmsPanelProps): JSX.Element => {
  const cardsData = toneCardDataArray;
  const [settingsOpen, setSettingsOpen] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [feedbackState, setFeedbackState] = useState<ToneAndFeedbackType>();
  const noPredictions = tovPredictions.length === 0;
  const [score, setScoreState] = useState<number>(0);
  const { toneScoreObjectArray, toneScoreCalcArray } = makeTovArrays(
    campaignToneSettings || companyToneSettings,
    tovPredictions
  );

  const toneScoreKey = JSON.stringify(toneScoreCalcArray);

  useEffect(() => {
    if (canRecalculateScore && !noPredictions) {
      setScoreState(calculateTotalTOVScore(toneScoreCalcArray));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toneScoreKey, canRecalculateScore]);

  const handleEditTonesClick = () => {
    setSettingsOpen(!settingsOpen);
    if (onEditTones) onEditTones();
    onToneCardFocused(null);
  };

  const handleFeedback = (feedbackState) => {
    setFeedbackState(feedbackState);
  };

  useEffect(() => {
    if (feedbackState) {
      onToneFeedback(feedbackState);
      setFeedbackState(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(toneScoreCalcArray)]);

  const hasNoCompanySettings = Object.keys(companyToneSettings).length === 0;

  return (
    <S.ToneOfVoiceSmsPanelWrapper>
      {settingsOpen ? (
        <ToneSettings
          campaignToneSettings={campaignToneSettings}
          companyToneSettings={companyToneSettings}
          cardsData={cardsData}
          setSettingsOpen={setSettingsOpen}
          saveCampaignSettings={saveCampaignSettings}
          onSaveCampaignSettings={onSaveCampaignSettings}
          onResetToBrand={onResetToBrand}
        />
      ) : (
        <>
          <ToneDetectorScore
            canRecalculate={canRecalculateScore}
            recalculateScore={onRecalculateScore}
            score={score}
          />
          {!tovDataLoading &&
            (tovDataNotSync || noPredictions || !canRecalculateScore) && (
              <S.InfoPanelWrapper>
                <InfoPanel
                  icon='InfoOutline'
                  type='hint'
                  message={
                    <span>
                      <b>Recalculate</b> to reflect latest changes.
                    </span>
                  }
                />
              </S.InfoPanelWrapper>
            )}
          {Object.keys(companyToneSettings).length === 0 && (
            <S.ToneOfVoiceInfoWrapper>
              <ToneOfVoiceInfoBanner
                handleSetTovButtonClick={() =>
                  window.location.assign('/signin/brand')
                }
                handleLearnMoreButtonClick={() =>
                  console.info('TOV - Learn more clicked')
                }
                variant='small'
              />
            </S.ToneOfVoiceInfoWrapper>
          )}
          <Divider dividerType='solid' isHorizontal paddingControl='24px 0px' />
          <div>
            <div className='tone-helper-text'>
              Here’s how your message sounds. Select a tone below to highlight
              where in your message the tone has been detected.
            </div>
            <Tooltip
              arrow
              ariaLabel='Edit Desired Tones'
              title={
                <S.StyledInteractiveTooltip>
                  <div>Change this message campaign’s tone settings.</div>
                  {/* <a
                    className='learn-more-button'
                    onClick={() => {
                      console.info('Learn more clicked!');
                    }}
                  >
                    Learn more
                  </a> */}
                </S.StyledInteractiveTooltip>
              }
              placement='bottom'
              padding='8px 16px'
              interactive
            >
              <Button
                text='Edit Desired Tones'
                variant={'link' as any}
                onClick={handleEditTonesClick}
                size='large'
              />
            </Tooltip>
          </div>
          <Divider dividerType='none' isHorizontal paddingControl='8px 0px' />
          <ToneScoreCardList
            toneScores={toneScoreObjectArray || DEFAULT_TONE_SCORE_ARRAY}
            handleFeedback={handleFeedback}
            handleFocusedToneCard={onToneCardFocused}
            disabled={tovDataNotSync || noPredictions || !canRecalculateScore}
            selectedTone={selectedTone}
            onToneCardBlur={onToneCardBlur}
          />
          <Divider dividerType='none' isHorizontal />
          <ToneDetectorFeedback
            disabled={noPredictions || !canRecalculateScore}
            heading='Not Accurate?'
            feedback={
              "Help us improve Whispir's Tone Detector by giving us your feedback."
            }
            buttonText='Give Feedback'
            handleButtonClick={() => setModalOpen(true)}
          />
        </>
      )}
      {modalOpen && (
        <ToneFeedbackModal
          isOpen={modalOpen}
          onLeaveFeedback={onOverallToneFeedback}
          handleExit={() => setModalOpen(false)}
          missingTones={[
            'Neutral',
            'Confident',
            'Optimistic',
            'Friendly',
            'Urgent',
            'Analytical',
            'Respectful',
            'Informal',
            'Formal',
          ]}
        />
      )}
    </S.ToneOfVoiceSmsPanelWrapper>
  );
};

import React from 'react';

import { TextField } from '@material-ui/core'; // TODO: use TextField from ui-lib-v2
import { DraftailPreview } from '../../DraftailComponents/DraftailPreview';
import { TextAreaInputWrapper } from './FormTextAreaInput.style';

type Props = {
  label: string;
  helperText: string;
  defaultValue: string;
  requiredField: string;
  paddingTop: string;
  paddingBottom: string;
};

export const FormTextAreaInput = ({
  label,
  helperText,
  defaultValue,
  requiredField,
  paddingTop,
  paddingBottom,
}: Props) => {
  const uniqueName = new Date().toString();
  return (
    <TextAreaInputWrapper paddingTop={paddingTop} paddingBottom={paddingBottom}>
      <TextField
        InputProps={{
          // Setting the startAdornment here to preview the `defaultValue`,
          // which may include variables.
          // This is necessary given that the value provided to the `defaultValue`
          // and `value` props can only be a string, and we want to style the variable pill.
          startAdornment: <DraftailPreview htmlContentString={defaultValue} />,
        }}
        id={uniqueName}
        label={label}
        required={requiredField === 'true'}
        helperText={<DraftailPreview htmlContentString={helperText} />}
        multiline
      />
    </TextAreaInputWrapper>
  );
};

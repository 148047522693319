import styled from 'styled-components';

import { Fonts } from '../../Foundation/Fonts';

export const StyledSideDrawerTitle = styled.div`
  ${Fonts.subheadSerif};

  .divider-form-control {
    margin-top: 13px;
  }
`;

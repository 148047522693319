import React from 'react';

import { Button } from './RichSubmitButtonBlock.style';
import { RichSubmitButtonBlockProps } from './RichSubmitButtonBlock.types';

export const RichSubmitButtonBlock = (
  props: RichSubmitButtonBlockProps
): JSX.Element => {
  const { label, ...styles } = props;

  return <Button {...styles}>{label}</Button>;
};

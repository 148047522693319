import styled from 'styled-components';

export const StyledWrapper = styled.div`
  padding: 16px 24px;

  .filter-toolbar-actions {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    .filter-picker {
      flex: 1;
    }
  }
`;

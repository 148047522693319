import styled from 'styled-components';

import { TextareaAutosize as TextField } from '@material-ui/core';
// $FlowFixMe

import { Colors } from '../../../Foundation/Colors';
import { Fonts } from '../../../Foundation/Fonts';
import { TextAreaTypes } from './TextArea.types';

const normalBackgroundColor = Colors.white;
const filledBackgroundColor = Colors.grey_blue_0;

const underlineStyling = (borderWidth, borderColor) => `
  border-bottom: ${borderWidth} solid ${borderColor};

  :hover {
    border-bottom: ${borderWidth} solid ${Colors.accent_dark};
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;

    :not(.Mui-disabled):before {
      border-bottom: ${borderWidth} solid ${Colors.accent_dark};
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  }

  :before {
    border-bottom: ${borderWidth} solid ${borderColor};
  }

  &.Mui-disabled {
    :before {
      border: 0;
    }
  }

  &.Mui-error {
    border-bottom: ${borderWidth} solid ${Colors.error};
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;

    :after {
      border-bottom-width: ${borderWidth};
    }
  }
  
  &.Mui-focused, &:focus {
    border-bottom: ${borderWidth} solid ${Colors.accent};
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;

    &.Mui-error {
      border-bottom: ${borderWidth} solid ${Colors.error};

      :after {
        border-bottom: ${borderWidth} solid ${Colors.error};
      }
    }

    ::after,
    ::before {
      border-bottom: ${borderWidth} solid ${Colors.accent};
    }
  }
`;

export const TextAreaWrapper = styled(TextField)<TextAreaTypes>`
  border: none;
  align-items: flex-start;
  background-color: ${normalBackgroundColor};
  overflow: auto;
  flex: 1;
  color: ${Colors.grey_blue};
  padding: 10px;
  box-sizing: border-box;
  ${Fonts.body};

  &.filled {
    background-color: ${filledBackgroundColor};
    border-radius: 4px;
    ${underlineStyling('2px', filledBackgroundColor)}
  }

  &.resize--horizontal {
    resize: horizontal;
  }

  &.resize--vertical {
    resize: vertical;
  }

  &.resize--none {
    resize: none;
  }
`;

import { graphQL } from '../helpers/api';

export const REFRESH_TOKEN_TIMEOUT = 20 * 60000; // minutes
export const REFRESH_TOKEN_TIMEOUT_ALLOWANCE = 1 * 60000; // minutes
export const REFRESH_TOKEN_QUERY = `{
    refreshToken {
        status,
        errorSummary,
        errorText,
        errorDetails
    }
}`;

export const startRefreshTokenTimer = async (
  GraphQLServer,
  refreshAndStart = true
) => {
  // refresh token on load page then start timer
  if (refreshAndStart) {
    await refreshToken(GraphQLServer);
  }

  const timeout = REFRESH_TOKEN_TIMEOUT - REFRESH_TOKEN_TIMEOUT_ALLOWANCE;
  return setInterval(() => refreshToken(GraphQLServer), timeout);
};

export const refreshToken = async (GraphQLServer) => {
  await graphQL(GraphQLServer, REFRESH_TOKEN_QUERY);
};

import * as React from 'react';
import { Colors } from '../../Foundation/Colors';
import { NumberCircleProps } from './NumberCircle.types';

const radius = 100;
const circumference = Math.round(Math.PI * radius * 2);
const getOffset = (val = 0) =>
  Math.round(((100 - Math.min(val, 100)) / 100) * circumference);

export const NumberCircle = ({
  number,
  showNumber = true,
  progressColor = Colors.accent_4_5,
  backgroundColor = Colors.grey_blue_1,
  emptyColor = Colors.accent_3,
  textColor = Colors.grey_blue,
  svgSize,
  // lineWidth should be less than 50 (half of radius)
  lineWidth = 40,
  roundedStroke = true,
  flatStyle = false,
  animate = true,
  animationDuration = '0.5s',
  onTransitionEnd,
}: NumberCircleProps) => {
  const hasProgress = typeof number === 'number';

  const text = showNumber ? (
    <text
      style={{ font: `600 60px/72px Inter, Helvetica` }}
      fill={textColor}
      x={radius}
      y={radius}
      textAnchor='middle'
      dominantBaseline='central'
    >
      {hasProgress ? number : '?'}
    </text>
  ) : (
    ''
  );

  const strokeDashoffset = getOffset(number);
  const transition = animate
    ? `stroke-dashoffset ${animationDuration} ease-out`
    : undefined;
  const strokeLinecap = roundedStroke ? 'round' : 'butt';
  const strokeColor = hasProgress ? backgroundColor : emptyColor;

  return (
    <svg
      width={svgSize || '100%'}
      height={svgSize || '100%'}
      // viewBox calculation breaks when lineWidth > radius/2
      viewBox={`${-(lineWidth / 2)} ${-(lineWidth / 2)} ${
        radius * 2 + lineWidth
      } ${radius * 2 + lineWidth}`}
      data-testid='NumberCircle'
    >
      <title>NumberCircle</title>
      <g filter={flatStyle ? '' : 'url(#filter)'}>
        <circle
          stroke={strokeColor}
          cx={radius}
          cy={radius}
          r={radius}
          strokeWidth={lineWidth}
          fill='none'
        />
        {hasProgress && (
          <circle
            stroke={progressColor}
            transform={`rotate(-90 ${radius} ${radius})`}
            cx={radius}
            cy={radius}
            r={radius}
            strokeDasharray={circumference}
            strokeWidth={lineWidth}
            strokeDashoffset={circumference}
            strokeLinecap={strokeLinecap}
            fill='none'
            style={{ strokeDashoffset, transition }}
            onTransitionEnd={onTransitionEnd}
          />
        )}
      </g>
      {text}
      <defs>
        <filter
          id='filter'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx='5' dy='5' />
          <feGaussianBlur stdDeviation='3.65625' />
          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0'
          />
          <feBlend mode='normal' in2='shape' result='effect1_innerShadow' />
        </filter>
      </defs>
    </svg>
  );
};

import styled from 'styled-components';
import { Colors } from '../../../Foundation/Colors';
import { Fonts } from '../../../Foundation/Fonts';

import { LinkWrapperProps } from './Link.types';

export const LinkWrapper = styled.div<LinkWrapperProps>`
  display: inline-block;

  :active,
  :hover,
  :active {
    box-shadow: none;
  }

  :focus-within {
    background: ${Colors.accent_2};
  }

  .MuiButton-root {
    ${Fonts.link};
    text-transform: none;
    padding: 0;

    .MuiButton-endIcon,
    .MuiButton-startIcon {
      svg {
        height: 20px;
        width: 20px;
      }
    }
  }

  &.primary {
    background: none;

    .MuiButton-root {
      color: ${Colors.accent};
      background: none;

      svg {
        fill: ${Colors.accent};
      }

      :hover,
      :active {
        color: ${Colors.accent_dark};
        background: none;

        .MuiButton-endIcon,
        .MuiButton-startIcon {
          svg {
            fill: ${Colors.accent_dark};
          }
        }
      }
    }
  }

  &.secondary {
    background: none;

    .MuiButton-root {
      color: ${Colors.grey_blue_4};
      background: none;

      svg {
        fill: ${Colors.grey_blue_4};
      }

      :hover,
      :active {
        color: ${Colors.grey_blue};
        background: none;

        .MuiButton-endIcon,
        .MuiButton-startIcon {
          svg {
            fill: ${Colors.grey_blue};
          }
        }
      }
    }
  }

  .MuiButton-startIcon {
    margin-right: 8px;
  }

  .MuiButton-endIcon {
    margin-left: 8px;
  }

  &.primary,
  &.secondary {
    .Mui-disabled {
      color: ${Colors.grey_blue_2};

      .MuiButton-endIcon,
      .MuiButton-startIcon {
        svg {
          fill: ${Colors.grey_blue_2};
        }
      }
    }
  }
`;

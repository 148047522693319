import styled from 'styled-components';
import { Colors } from '../../Foundation/Colors';
import { Elevations } from '../../Foundation/Elevations';
import { Fonts } from '../../Foundation/Fonts';

import { ParentPropsTypes } from './InsightSection.types';

const position = (idx) => idx + 1;

const getSize = (children) => {
  const childrenWidth = children.map(
    (child, idx) => `
    .child-${position(idx)} {
      width: ${child.size ? child.size : 'auto'};
    }`
  );
  return childrenWidth.join(' ');
};

export const ParentWrapper = styled.div<ParentPropsTypes>`
  display: flex;
  flex-direction: row;
  position: relative;
  background: ${Colors.white};
  border-radius: 10px;
  box-shadow: ${Elevations.elevation_1};
  flex-wrap: wrap;
  padding: 12px 24px;

  .divider-form-control {
    align-self: center;
    margin: 0 24px;
  }

  .children {
    padding-top: 10px;

    .label {
      display: block;
      min-height: 16px;
      ${Fonts.capsHeading};
      color: ${Colors.grey_blue_4};
      div {
        display: flex;
        align-items: center;
        svg {
          width: 20px;
          height: 20px;
          fill: ${Colors.grey_blue_4};
          margin: 0 5px 0 0;
        }
      }
    }

    .component-wrapper {
      margin-top: 8px;
    }
  }

  ${(props) => getSize(props.childComponents)}

  @media (max-width: 1400px) {
    .child-${position(3)} {
      margin-bottom: 45px;

      + .divider-form-control {
        display: none;
      }
    }

    .child-${position(4)} {
      margin-bottom: 45px;
    }
  }

  @media (max-width: 1200px) {
    .child-${position(2)} {
      + .divider-form-control {
        display: none;
      }
    }
    .child-${position(3)} {
      margin-bottom: 45px;

      + .divider-form-control {
        display: block;
      }
    }
  }
`;

import { withStyles, List } from '@material-ui/core';
import { Colors } from '../../Foundation/Colors';

export const StyledListSelect = withStyles({
  root: {
    '& .MuiButtonBase-root': {
      color: Colors.grey_blue,

      '&:hover': {
        backgroundColor: Colors.grey_blue_1,
      },
    },

    '& .MuiTypography-root': {
      fontFamily: 'Inter',
      fontSize: '16px',
      lineHeight: '24px',
      color: Colors.grey_blue,
    },
  },
})(List);

import React from 'react';

import { Button, IconButton } from '../../../Atoms/Navigations';
import { ActionProps } from '../Header.types';

export const CloseAction = ({ text, action }: ActionProps): JSX.Element =>
  text ? (
    <Button
      id='header-close-icon'
      text={text}
      endIcon='Close'
      type='secondary'
      onClick={action}
      variant='link'
      size='medium'
    />
  ) : (
    <IconButton
      id='header-close-icon'
      size='medium'
      icon='Close'
      onClick={action}
    />
  );

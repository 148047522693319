import styled from 'styled-components';
import { Colors } from '../../Foundation/Colors';

import { MenuDropdownButtonProps } from './TagButton.types';

export const MenuDropdownButton = styled.button`
  background-color: ${(props: MenuDropdownButtonProps) => props.buttonColor};
  cursor: pointer;
  padding: ${({ compact }) => (compact ? '2px 6px 0' : '2px 10px 0')};
  border-radius: 24px;
  font-size: 11px;
  border: none;
  color: ${Colors.white};
  svg {
    height: ${({ compact }) => (compact ? '18px' : '24px')};
    fill: ${Colors.white};
    margin: -2px;
  }
  :hover {
    background-color: ${Colors.grey_blue};
  }
`;

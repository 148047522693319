import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { toPng } from 'html-to-image';
import { Spinner } from '../Indicators/Spinner';
import { ContentPreviewProps } from './ComponentPreview.types';
import { StyledComponentPreview } from './ComponentPreview.styles';

export const ComponentPreview = ({
  children,
  width = 200,
  height,
}: ContentPreviewProps) => {
  const [preview, setPreview] = useState('');

  useEffect(() => {
    const htmlBody = document.body;
    /** we need to render the component to be able to get an screenshot of it. */
    const container = document.createElement('div');
    // setting the necessary style will prevent flickering upon rendering the container.
    container.setAttribute('style', 'height: 0px; overflow: hidden');

    ReactDOM.render(
      <div style={{ display: 'inline-block' }}>{children}</div>, // extra div to group the multiple components
      container,
      async () => {
        htmlBody.appendChild(container);

        // screenshot the components
        const targetElement = container.firstChild;
        const dataUrl = await toPng(targetElement as HTMLElement);
        setPreview(dataUrl);

        // immediately remove it from DOM after we've got screenshot, so it will not show in the browser.
        htmlBody.removeChild(container);
      }
    );
  }, [children]);

  return (
    <StyledComponentPreview width={width} height={height}>
      {preview ? (
        <img id={`${Date.now()}`} src={preview} alt='' />
      ) : (
        <Spinner size='medium' />
      )}
    </StyledComponentPreview>
  );
};

import styled, { css } from 'styled-components';
import { Colors } from '../../Foundation/Colors';
import { Fonts } from '../../Foundation/Fonts';
import { TRANSITION_TIME } from '../../Foundation/Spacing';
import {
  FeedbackIconsType,
  ToneScoreCardIconType,
} from './ToneScoreCard.types';

export const StyledToneScoreCard = styled.button<{
  selected: boolean;
  includeFeedback: boolean;
  disabled: boolean;
}>`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  text-align: left;
  color: ${Colors.grey_blue_4};
  background-color: ${Colors.white};
  padding: 16px 16px 8px;
  width: 100%;
  min-height: 96px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid ${Colors.grey_blue_1};
  user-select: text;
  position: relative;
  transition: border ${TRANSITION_TIME};

  ${(props) => {
    if (props.disabled) {
      return css`
        cursor: inherit;
        opacity: 0.7;
      `;
    }

    return css`
      &:hover {
        border-color: ${Colors.grey_blue_3};
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
      }
    `;
  }}

  .feedback-icons {
    visibility: hidden;
  }

  ${({ selected, includeFeedback }) =>
    selected
      ? `
    outline: none;
    border-color: ${Colors.grey_blue_3};
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
    ${
      includeFeedback
        ? `
    .feedback-icons {
      visibility: visible;
    } `
        : ''
    }
    `
      : ''}
`;

export const StyledHeading = styled.span`
  width: 100%;
  display: flex;
  ${Fonts.subheadSmall};
  color: ${Colors.grey_blue};
  text-align: left;
  margin-bottom: 5px;
`;

export const StyledHeadingText = styled.span`
  flex: 1;
  text-transform: capitalize;
`;

export const StyledEmoji = styled.span`
  margin-right: 6px;
`;

export const StyledIcon = styled.span<ToneScoreCardIconType>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 16px;
  width: 16px;
`;

export const StyledDescription = styled.div`
  ${Fonts.caption};
`;

export const StyledFeedbackIcons = styled.span<FeedbackIconsType>`
  display: flex;
  justify-content: flex-end;
  text-align: center;
  padding-top: 4px;
  width: 100%;

  .feedback-icon {
    border-radius: 9px;
    height: 18px;
    width: 18px;
    box-sizing: border-box;
    align-items: center;
    &:hover {
      background-color: ${Colors.grey_blue_1};
    }
  }

  .ThumbsUp-icon {
    margin-right: 4px;
    svg {
      fill: ${(props) =>
        props.feedback === 'positive' ? Colors.accent : Colors.grey_blue_2};
    }
    &:hover {
      svg {
        fill: ${Colors.accent_dark};
      }
    }
  }

  .ThumbsDown-icon {
    margin-left: 4px;
    svg {
      fill: ${(props) =>
        props.feedback === 'negative' ? Colors.accent : Colors.grey_blue_2};
    }
    &:hover {
      svg {
        fill: ${Colors.accent_dark};
      }
    }
  }

  .MuiTooltip-tooltip {
    ${Fonts.caption}
  }

  svg {
    fill: ${Colors.grey_blue_2};
    height: 14px;
    width: 14px;
    position: relative;
    top: 2px;
  }
`;

export const ScoreTooltip = styled.div`
  color: ${Colors.grey_blue_3};

  .tone-score-tooltip {
    color: ${Colors.white};
    text-transform: capitalize;
  }
`;

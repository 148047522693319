import { RatingDnD, ratingBlockModel } from '../../RatingDnD';
import {
  interactiveMapBlockModel,
  InteractiveMap,
  interactiveMapBlockDerivedProps,
} from '../../InteractiveMap';
import {
  NumericSlider,
  numericSliderBlockModel,
  numericSliderBlockDerivedProps,
} from '../../../Atoms/NumericSlider';
import {
  VideoPlayer,
  videoPlayerModel,
  videoPlayerBlockDeriveProps,
} from '../../../Atoms/VideoPlayer';
import {
  FormCheckboxList,
  formCheckboxListModel,
} from '../../../DragAndDropComponents/FormCheckboxList';
import {
  FormDatePicker,
  formDatePickerModel,
} from '../../../DragAndDropComponents/FormDatePicker';
import {
  FormDropdown,
  formDropdownModel,
} from '../../../DragAndDropComponents/FormDropdown';
import {
  FormRadioList,
  formRadioListModel,
} from '../../../DragAndDropComponents/FormRadioList';
import {
  FormTextAreaInput,
  formTextAreaInputModel,
} from '../../../DragAndDropComponents/FormTextAreaInput';
import {
  FormTextInput,
  formTextInputModel,
} from '../../../DragAndDropComponents/FormTextInput';
import {
  RichArticleBlock,
  richArticleBlockModel,
  richArticleBlockDerivedProperties,
} from '../../../DragAndDropComponents/RichArticleBlock';
import {
  RichSubmitButtonBlock,
  richSubmitButtonBlockModel,
} from '../../../DragAndDropComponents/RichSubmitButtonBlock';
import {
  RichTextBannerBlock,
  richTextBannerBlockModel,
} from '../../../DragAndDropComponents/RichTextBannerBlock';
import {
  RichTextBasicBlock,
  richTextBasicBlockModel,
} from '../../../DragAndDropComponents/RichTextBasicBlock';
import {
  RichTextButtonBlock,
  richTextButtonBlockModel,
} from '../../../DragAndDropComponents/RichTextButtonBlock';
import {
  RichTextButtonGroupBlock,
  richTextButtonGroupBlockModel,
} from '../../../DragAndDropComponents/RichTextButtonGroupBlock';
import {
  RichTextDividerBlock,
  richTextDividerBlockModel,
} from '../../../DragAndDropComponents/RichTextDividerBlock';
import {
  RichTextFooterBlock,
  richTextFooterBlockModel,
} from '../../../DragAndDropComponents/RichTextFooterBlock';
import {
  RichTextFullWidthBannerBlock,
  richTextFullWidthBannerBlockModel,
} from '../../../DragAndDropComponents/RichTextFullWidthBannerBlock';
import {
  RichTextImageBlock,
  richTextImageBlockModel,
} from '../../../DragAndDropComponents/RichTextImageBlock';
import {
  FormYesNoButtons,
  formYesNoButtonsModel,
  formYesNoButtonsDerivedProperties,
} from '../../../DragAndDropComponents/FormYesNoButtons';
import { mapBlockModel, GoogleMap } from '../../../Molecules/GoogleMap';
import {
  RichTextCalendarBlock,
  richTextCalendarBlockModel,
  richTextCalendarBlockDerivedProperties,
} from '../../../DragAndDropComponents/RichTextCalendarBlock';

const blocks = {
  Image: {
    Block: RichTextImageBlock,
    model: richTextImageBlockModel,
  },
  Text: {
    Block: RichTextBasicBlock,
    model: richTextBasicBlockModel,
  },
  Button: {
    Block: RichTextButtonBlock,
    model: richTextButtonBlockModel,
  },
  SubmitButton: {
    Block: RichSubmitButtonBlock,
    model: richSubmitButtonBlockModel,
  },
  Calendar: {
    Block: RichTextCalendarBlock,
    model: richTextCalendarBlockModel,
    derivedProps: richTextCalendarBlockDerivedProperties,
  },
  Banner: {
    Block: RichTextBannerBlock,
    model: richTextBannerBlockModel,
  },
  Divider: {
    Block: RichTextDividerBlock,
    model: richTextDividerBlockModel,
  },
  Article: {
    Block: RichArticleBlock,
    model: richArticleBlockModel,
    derivedProps: richArticleBlockDerivedProperties,
  },
  FormDatePicker: {
    Block: FormDatePicker,
    model: formDatePickerModel,
  },
  FormTextArea: {
    Block: FormTextAreaInput,
    model: formTextAreaInputModel,
  },
  FormTextInput: {
    Block: FormTextInput,
    model: formTextInputModel,
  },
  FormRadioList: {
    Block: FormRadioList,
    model: formRadioListModel,
  },
  FormDropdown: {
    Block: FormDropdown,
    model: formDropdownModel,
  },
  FormCheckboxList: {
    Block: FormCheckboxList,
    model: formCheckboxListModel,
  },
  FullWidthBanner: {
    Block: RichTextFullWidthBannerBlock,
    model: richTextFullWidthBannerBlockModel,
  },
  ButtonGroup: {
    Block: RichTextButtonGroupBlock,
    model: richTextButtonGroupBlockModel,
  },
  Footer: {
    Block: RichTextFooterBlock,
    model: richTextFooterBlockModel,
  },
  Map: {
    Block: GoogleMap,
    model: mapBlockModel,
  },
  Rating: {
    Block: RatingDnD,
    model: ratingBlockModel,
  },
  FormNumericSlider: {
    Block: NumericSlider,
    model: numericSliderBlockModel,
    derivedProps: numericSliderBlockDerivedProps,
  },
  InteractiveMap: {
    Block: InteractiveMap,
    model: interactiveMapBlockModel,
    derivedProps: interactiveMapBlockDerivedProps,
  },
  VideoPlayer: {
    Block: VideoPlayer,
    model: videoPlayerModel,
    derivedProps: videoPlayerBlockDeriveProps,
  },
  FormYesNoButtons: {
    Block: FormYesNoButtons,
    model: formYesNoButtonsModel,
    derivedProps: formYesNoButtonsDerivedProperties,
  },
};

const getBlockConfig = (type: string) => {
  const block = blocks[type];
  if (!block) throw new Error(`Cannot find definition for block: '${type}'`);

  return block;
};

export const createComponent = (type: string) => {
  const blockConfig = getBlockConfig(type);
  if (!blockConfig.Block) {
    throw new Error(`Found no block config for type: "${type}"`);
  }

  return getBlockConfig(type).Block;
};

export const createDroppableTargetItemsModel = (type: string, id: number) => {
  const blockConfig = getBlockConfig(type);
  const { model } = blockConfig;

  if (!model) {
    throw new Error(`No block model found for type: "${type}"`);
  }

  return model(id);
};

export const getDerivePropertyRules = (type: string) =>
  getBlockConfig(type).derivedProps || [];

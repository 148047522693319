import styled from 'styled-components';
import { Colors } from '../../Foundation/Colors';
import { Fonts } from '../../Foundation/Fonts';

export const SmsPanelWrapper = styled.div`
  background-color: ${Colors.white};
  position: relative;
  padding: 0 0 24px;
  border-radius: 0 0 8px 8px;
  width: 100%;
`;

export const TextareaField = styled.div`
  padding: 24px 24px 0 24px;

  textarea {
    box-sizing: border-box;
    border: 2px solid ${Colors.grey_blue_1};
    padding: 15px;
    width: 100%;
    resize: vertical;
    ${Fonts.body};
    &::placeholder {
      color: ${Colors.grey_blue_3};
    }
    &:focus {
      outline: none;
    }

    &.error {
      border: solid 2px ${Colors.error};
    }
  }
`;

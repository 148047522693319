import styled from 'styled-components';

import Table from '@material-ui/core/Table';
import { Colors } from '../../Foundation/Colors';

export const SimpleTableWrapper = styled(Table)`
  &.disabled {
    pointer-events: none;
  }

  &.MuiTable-root {
    table-layout: fixed;
    position: relative;
  }

  .data-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 100vw;
    padding: 48px 0;
  }

  th {
    border-top: 1px solid ${Colors.grey_blue_1};
    border-bottom: 1px solid ${Colors.grey_blue_1};
    padding: 12px;
  }
  td {
    background-image: linear-gradient(
      to top,
      ${Colors.grey_blue_2} 0px,
      ${Colors.grey_blue_2} 1px,
      transparent 0
    );
    border-bottom: none;
    background-repeat: no-repeat;
    background-position: 0px 0px;
    background-clip: border-box;
    background-origin: border-box;
    padding: 20px 12px;
  }
`;

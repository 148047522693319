import React, { Fragment } from 'react';
import { UIVariable, UIVariableGroup } from '@whispir/variables';
import {
  AdhocVariableSelector,
  AdhocVariable,
  AdhocValidatorOptions,
} from '../AdhocVariableSelector';
import { LazyLoadingContactsSelector } from '../LazyLoadingContactsSelector';
import { LazyLoadingDistributionListSelector } from '../LazyLoadingDistributionListSelector';
import { validateMobileNumber } from '../../Utils/commonUtils';
import {
  ContactType,
  LoadMoreFunction,
} from '../LazyLoadingContactsSelector/LazyLoadingContactsSelector.types';
import {
  DistributionListType,
  LoadMoreFunction as LoadMoreFunctionDistributionLists,
} from '../LazyLoadingDistributionListSelector/LazyLoadingDistributionListSelector.types';

import { FormItem } from '../../Atoms/Forms';
import { AdhocVariableSelectorWrapper } from './RecipientSelector.style';

/*
  The email regex below matches 99.99% of emails.
  Taken from: https://emailregex.com/
*/
const EMAIL_REGEX = new RegExp(
  // eslint-disable-next-line no-control-regex
  /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/
);

const PHONE_REGEX = new RegExp(/^([0-9\s]{1,13})$/);

type RecipientSelectorProps = {
  mode: 'email' | 'sms' | 'all';
  variableEnabled: boolean;

  // variables and adhoc
  availableVariables: Array<UIVariableGroup>;
  selectedVariables: Array<UIVariable | AdhocVariable>;
  onChangeVariables: (values: Array<UIVariable | AdhocVariable>) => void;
  adhocValidatorOptions: AdhocValidatorOptions;

  // contacts
  selectedContacts: Array<ContactType>;
  loadMoreContactsFunction: LoadMoreFunction;
  onChangeContacts: (values: Array<ContactType>) => void;

  // Distribution lists
  selectedDistributionLists: Array<DistributionListType>;
  loadMoreDistributionListsFunction: LoadMoreFunctionDistributionLists;
  onChangeDistributionLists: (values: Array<DistributionListType>) => void;
};

type Validator = { type: string; valid: boolean };

export const modeValidator = (
  value: string,
  adhocValidatorOptions?: AdhocValidatorOptions
) => {
  let type = '';

  const validateSMS = () => {
    const valid =
      !!value.match(PHONE_REGEX) ||
      validateMobileNumber(value, adhocValidatorOptions?.sms);
    if (valid) type = 'sms';

    return valid;
  };

  const validateEmail = () => {
    const valid = !!value.match(EMAIL_REGEX);
    if (valid) type = 'email';

    return valid;
  };

  return { valid: validateSMS() || validateEmail(), type };
};

export const RecipientSelector = (props: RecipientSelectorProps) => {
  const {
    mode,
    availableVariables,
    selectedVariables,
    onChangeVariables,
    selectedContacts,
    loadMoreContactsFunction,
    onChangeContacts,
    selectedDistributionLists,
    loadMoreDistributionListsFunction,
    onChangeDistributionLists,
    variableEnabled,
    adhocValidatorOptions,
  } = props;

  return (
    <Fragment>
      <FormItem label='Select From Your Lists'>
        <LazyLoadingDistributionListSelector
          value={selectedDistributionLists}
          loadMoreFunction={loadMoreDistributionListsFunction}
          onChange={onChangeDistributionLists}
        />
      </FormItem>

      <FormItem label='Select From Your Contacts'>
        <LazyLoadingContactsSelector
          mode={mode}
          value={selectedContacts}
          loadMoreFunction={loadMoreContactsFunction}
          onChange={onChangeContacts}
        />
      </FormItem>
      {/* NOTE: last-form-item class should always be attached to the last <FormItem /> element */}
      <FormItem
        className='last-form-item'
        label={
          !variableEnabled
            ? 'Enter number or email'
            : `Enter ${mode === 'sms' ? 'Number' : 'Email'} Or Use A Variable`
        }
      >
        <AdhocVariableSelectorWrapper variableEnabled={variableEnabled}>
          <AdhocVariableSelector
            variableEnabled={variableEnabled}
            availableOptions={availableVariables}
            placeholder={
              !variableEnabled
                ? 'Enter a phone number or an email address'
                : `Use an Adhoc ${
                    mode === 'sms' ? 'Number' : 'Email'
                  } or a Variable`
            }
            selectedOptions={selectedVariables}
            adhocValidator={modeValidator}
            adhocValidatorOptions={adhocValidatorOptions}
            onChange={onChangeVariables}
          />
        </AdhocVariableSelectorWrapper>
      </FormItem>
    </Fragment>
  );
};

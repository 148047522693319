import { HIDDEN } from '../../derivedPropertyNames';

/**
 * Injects properties into model base on defined behaviour
 * - target: the target property names inside model properties
 * - name: The new property name of the injected property
 * - handler: The resulting value to be injected
 * - rules: We can do multiple injections for one or multiple targets
 * - properties: Includes all properties in the active/selected component
 */
export const richArticleBlockDerivedProperties = [
  {
    targets: ['secondImageSrc', 'secondImageAlt'],
    rules: [
      {
        name: HIDDEN,
        handler: (properties) => {
          const { value: imageCheckbox } = properties.find(
            ({ name }) => name === 'imageBoth'
          );
          const { value: textCheckbox } = properties.find(
            ({ name }) => name === 'textBoth'
          );

          return textCheckbox === 'true' || imageCheckbox === 'false';
        },
      },
    ],
  },
  {
    targets: ['firstImageSrc', 'firstImageAlt', 'imageTitle', 'padding_8px_4'],
    rules: [
      {
        name: HIDDEN,
        handler: (properties) => {
          const { value: textCheckbox } = properties.find(
            ({ name }) => name === 'textBoth'
          );
          const { value: imageCheckbox } = properties.find(
            ({ name }) => name === 'imageBoth'
          );

          return textCheckbox === 'true' && imageCheckbox === 'false';
        },
      },
    ],
  },
];

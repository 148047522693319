import React from 'react';
import { Tooltip } from '../../Atoms/Dialogs/Tooltip';
import { FieldIdContainer } from './FieldId.style';

type Props = {
  iconColor: string;
  bgColor: string;
  label: string;
};

export const FieldId = ({ iconColor, bgColor, label }: Props) => {
  const tooltipContent = (
    <div key='FieldId-container'>
      <p>
        This ID can be used as you build your workflow. It allows the form
        field&apos;s values to populate the relevant parts of the workflow. The
        unique name is based on the label you gave this field.
      </p>
    </div>
  );
  return (
    <FieldIdContainer className='FieldId-container'>
      <Tooltip
        className='FieldId-tooltip'
        ariaLabel='field-id-tooltip'
        title={tooltipContent}
      >
        <p className='FieldId-label'>{label}</p>
      </Tooltip>
    </FieldIdContainer>
  );
};

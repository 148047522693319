import React from 'react';
import { TextField } from '../../Atoms/Forms/TextField';
import { TrueFalseString } from '../utilityTypes';
import { DraftailPreview } from '../../DraftailComponents/DraftailPreview';
import { TextInputWrapper } from './FormTextInput.style';

type Props = {
  label: string;
  helperText: string;
  defaultValue: string;
  requiredField: TrueFalseString;
  paddingTop: string;
  paddingBottom: string;
};

export const FormTextInput = ({
  label,
  helperText,
  defaultValue,
  requiredField,
  paddingTop,
  paddingBottom,
}: Props): JSX.Element => {
  const uniqueName = new Date().toString();
  return (
    <TextInputWrapper paddingTop={paddingTop} paddingBottom={paddingBottom}>
      <TextField
        InputProps={{
          // Setting the startAdornment here to preview the `defaultValue`,
          // which may include variables.
          // This is necessary given that the value provided to the `defaultValue`
          // and `value` props can only be a string, and we want to style the variable pill.
          startAdornment: <DraftailPreview htmlContentString={defaultValue} />,
        }}
        id={uniqueName}
        label={label}
        required={requiredField === 'true'}
        helperText={<DraftailPreview htmlContentString={helperText} />}
      />
    </TextInputWrapper>
  );
};

import React, { useState, useEffect, useRef, useCallback } from 'react';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableContainer,
  TableSortLabel,
  TableRow as MUITableRow,
} from '@material-ui/core';
import { Checkbox } from '../../Atoms/Forms';
import { Icon } from '../../Atoms/Icon';
import { Button } from '../../Atoms/Navigations';
import { typedMemo } from '../../Utils/typedMemo';
import { Spinner } from '../../Atoms/Indicators';
import { Illustrations } from '../../Foundation/Illustrations';
import { EmptyTemplateState } from '../../Molecules/EmptyTemplateState';
import {
  DataTableProps,
  SelectMode,
  SelectionState,
  TypedColumn,
  BatchAction,
  RowData,
} from './DataTable.types';
import { StyledTableWrapper } from './DataTable.styles';
import { TableRow } from './TableRow';

const DEFAULT_FIRST_SORT_ORDER = true; // First click of a column will sort ascending.
const DISABLE_SORT_ON_LOAD_KEY = 'NO_SORT';

export const SELECTION_STATES = {
  SOME: 'some-on-page',
  ALL: 'all-on-page',
  NONE: 'none-on-page',
};

/**
 *  Potential optimisations in this file. 
 * 
 * - Memoize the batchAction  callbacks (only recreate the function when the selected data changes) 
 
 */

/**
 * Default render method if renderCell isn't provided.
 * @param data
 */
const defaultRenderMethod = (data: unknown) => {
  if (typeof data === 'string' || typeof data === 'number') {
    return data;
  }

  if (data === undefined || data === null) {
    return JSON.stringify(data);
  }

  if (data instanceof Date) {
    return data.toISOString();
  }

  return JSON.stringify(data);
};

/**
 * Default sort function if sortFn isn't provided.
 * @param a
 * @param b
 */

function defaultSortComparator<T>(a: T, b: T): number {
  if (a < b) {
    return -1;
  }
  if (b < a) {
    return 1;
  }

  return 0;
}

const HeaderCheckbox = (props: {
  selectionState: SelectionState;
  onClick: () => void;
}) => {
  const { selectionState, onClick } = props;
  return (
    <Checkbox
      isIndeterminated={selectionState === SELECTION_STATES.SOME}
      isChecked={selectionState !== SELECTION_STATES.NONE}
      onChange={onClick}
    />
  );
};

const EnhancedTableHead = <T extends RowData>(props: {
  columns: Array<TypedColumn<T>>;
  selectMode: SelectMode;
  selectAllClick: (mode: boolean) => void;
  selectAllInPageClick: () => void;
  selectionState: SelectionState;
  columnSortKey: keyof T;
  columnSortAscending: boolean;
  onSortClick: (columnKey: string) => void;
  hasRowActionsColumn: boolean;
  selectionIndex: Record<string, T>;
  batchActions: Array<BatchAction<T>>;
  allRowsAreSelected: boolean;
  totalNumberOfRows: number;
}) => {
  const {
    columns,
    selectMode,
    selectAllClick,
    selectAllInPageClick,
    selectionState,
    columnSortKey,
    columnSortAscending,
    onSortClick,
    hasRowActionsColumn,
    selectionIndex,
    batchActions,
    allRowsAreSelected,
    totalNumberOfRows,
  } = props;

  const selectedRows = Object.values(selectionIndex);
  const showBatchActions = selectMode === 'multi' && selectedRows.length > 0;

  // Remember that some rows may be selected on other pages.
  const showDeselectAllButton =
    allRowsAreSelected ||
    (selectionState === SELECTION_STATES.NONE && selectedRows.length > 0);

  const handleSelectAllClick = useCallback(() => {
    selectAllClick(true);
  }, [selectAllClick]);

  const handleDeselectAllClick = useCallback(() => {
    selectAllClick(false);
  }, [selectAllClick]);

  return (
    <TableHead>
      <MUITableRow></MUITableRow>
      <MUITableRow>
        {selectMode === 'multi' && (
          <TableCell className='checkbox-cell'>
            <HeaderCheckbox
              data-testid='master-checkbox'
              selectionState={selectionState}
              onClick={selectAllInPageClick}
            />
          </TableCell>
        )}

        {showBatchActions && (
          <TableCell colSpan={columns.length}>
            <span className='n-rows-selected-label'>
              {Object.values(selectionIndex).length} Rows selected:
            </span>
            {showDeselectAllButton ? (
              <Button
                className='select-all-button'
                variant='link'
                onClick={handleDeselectAllClick}
                text='Deselect all rows'
              />
            ) : (
              <Button
                className='select-all-button'
                variant='link'
                onClick={handleSelectAllClick}
                text={`Select all ${totalNumberOfRows} rows`}
              />
            )}
            {batchActions.map(({ label, icon, action }) => {
              return (
                <Button
                  className='batch-action-button'
                  variant='link'
                  type='secondary'
                  key={label}
                  startIcon={icon}
                  text={label}
                  onClick={(e) => {
                    action(Object.values(selectionIndex), e);
                  }}
                />
              );
            })}
          </TableCell>
        )}

        {!showBatchActions &&
          columns.map((column) => {
            const { field, headerName, disableSort = false } = column;
            const isActiveSort = columnSortKey === field;
            let header = (
              <TableSortLabel
                active={isActiveSort}
                direction={columnSortAscending ? 'asc' : 'desc'}
                onClick={() => onSortClick(field)}
                IconComponent={({ className, ...props }) => (
                  <Icon
                    icon='DownArrow'
                    className={`${className} sort-arrow-icon`}
                    {...props}
                  />
                )}
              >
                {headerName}{' '}
                {!isActiveSort && <Icon icon='Sort' className='sort-icon' />}
              </TableSortLabel>
            );

            if (disableSort) header = <div>{headerName} </div>;
            return (
              <TableCell key={field} className={`${field}-cell`}>
                {header}
              </TableCell>
            );
          })}

        {hasRowActionsColumn && <TableCell className='row-action-cell' />}
      </MUITableRow>
    </TableHead>
  );
};
const defaultRowActions = [];

export const DataTable = typedMemo(
  <T extends RowData>(props: DataTableProps<T>): JSX.Element => {
    const {
      rows,
      columns,
      selectMode = 'none',
      rowActions = defaultRowActions,
      batchActions = [],
      onRowSelect = () => undefined,
      className,
      maxHeight,
      rowsPerPage = 25,
      isLoading = false,
      disableSortOnLoad = false,
      rowsPerPageOptions = [5, 10, 25, 50, 100, { label: 'All', value: -1 }],
      emptyTablePlaceholder = (
        <EmptyTemplateState
          illustration={<Illustrations.EmptyState />}
          title='No records found'
        />
      ),
      selectOnRowClick = true,
      onRowClick = () => null,
    } = props;

    /**
     * STATE
     */

    // Keep track of which rows are selected
    const [selectionIndex, setSelectionIndex] = useState(
      {} as Record<string, T>
    );
    // This basically just used to determine what the select all checkbox looks like
    const [selectionState, setSelectionState] = useState<SelectionState>(
      SELECTION_STATES.NONE
    );

    // Keep track of pagination data
    const [currentPage, setCurrentPage] = useState(0);
    const [currentRowsPerPage, setCurrentRowsPerPage] = useState(rowsPerPage);

    // keep track of selected row on row click
    const [selectedRow, setSelectedRow] = useState({});

    // Just a lookup of the columns by field id.
    const [columnIndex, setColumnIndex] = useState(
      columns.reduce((acc, cur) => {
        return {
          ...acc,
          [cur.field]: cur,
        };
      }, {} as Record<keyof T, TypedColumn<T>>)
    );

    // Keep track of sort data
    const defaultSortKey = disableSortOnLoad
      ? DISABLE_SORT_ON_LOAD_KEY
      : columns[0].field;
    const [columnSortKey, setColumnSortKey] = useState(defaultSortKey);
    const [columnSortAscending, setColumnSortAscending] = useState(
      DEFAULT_FIRST_SORT_ORDER
    );

    // Sorted rows
    const [sortedRows, setSortedRows] = useState(rows);
    const rowsToDisplay =
      currentRowsPerPage > 1
        ? sortedRows.slice(
            currentPage * currentRowsPerPage,
            currentPage * currentRowsPerPage + currentRowsPerPage
          )
        : sortedRows;

    // Spinner state
    const [spinnerState, setSpinnerState] = useState({
      loading: false,
      callback: null,
    });

    // Update the column index if the columns happen to change
    useEffect(() => {
      setColumnIndex(
        columns.reduce((acc, cur) => {
          return {
            ...acc,
            [cur.field]: cur,
          };
        }, {} as Record<keyof T, TypedColumn<T>>)
      );
    }, [columns]);

    /** SELECTION HANDLERS */
    const handleRowClick = useCallback(
      (row: T, isCheckbox: boolean) => {
        if (selectMode === 'none') {
          return;
        }

        if (selectMode === 'single') {
          setSelectionIndex({
            [row.id]: row,
          });
          return;
        }

        if (selectMode === 'multi') {
          if (!selectOnRowClick && !isCheckbox) {
            onRowClick(row);
            setSelectedRow(row);
            return;
          }
          const newIndex = { ...selectionIndex };
          if (newIndex[row.id]) {
            delete newIndex[row.id];
          } else {
            newIndex[row.id] = row;
          }
          setSelectionIndex(newIndex);
        }
      },
      [onRowClick, selectMode, selectOnRowClick, selectionIndex]
    );

    const handleSelectAllInPageClick = () => {
      let newIndex = {};
      if (
        [SELECTION_STATES.ALL, SELECTION_STATES.SOME].includes(selectionState)
      ) {
        rowsToDisplay.forEach((v) => {
          delete newIndex[v.id];
        });
      } else {
        // Add all of the rows to display to the selection index - clobbering if needed.
        newIndex = rowsToDisplay.reduce((acc, cur) => {
          return {
            ...acc,
            [cur.id]: cur,
          };
        }, newIndex);
      }
      setSelectionIndex(newIndex);
    };

    const handleSelectAllClick = (selectAllMode: boolean) => {
      if (selectAllMode) {
        setSpinnerState({
          loading: true,
          callback: () => {
            const newIndex = rows.reduce((acc, cur) => {
              return {
                ...acc,
                [cur.id]: cur,
              };
            }, {});
            setSelectionIndex(newIndex);
          },
        });
      } else {
        setSelectionIndex({});
      }
    };

    useEffect(() => {
      const areAllDisplayedRowsCurrentSelected = rowsToDisplay.every(
        (row) => !!selectionIndex[row.id]
      );
      if (areAllDisplayedRowsCurrentSelected) {
        setSelectionState(SELECTION_STATES.ALL);
        return;
      }

      const areAnyDisplayedRowsCurrentlySelected = rowsToDisplay.find(
        (row) => !!selectionIndex[row.id]
      );
      if (areAnyDisplayedRowsCurrentlySelected) {
        setSelectionState(SELECTION_STATES.SOME);
        return;
      }

      setSelectionState(SELECTION_STATES.NONE);
    }, [selectionIndex, rowsToDisplay]);

    // Using a ref to selectively fire useEffect hook on rows change only
    // https://stackoverflow.com/questions/55724642/react-useeffect-hook-when-only-one-of-the-effects-deps-changes-but-not-the-oth
    const selectionIndexRef = useRef(selectionIndex);
    useEffect(() => {
      selectionIndexRef.current = selectionIndex;
    });
    // When rows update, update the selection index. (Remove any items that may no longer exist)
    useEffect(() => {
      const indexedRows = rows.reduce((acc, cur) => {
        return {
          ...acc,
          [cur.id]: cur,
        };
      }, {} as Record<string, T>);

      const newIndex = { ...selectionIndexRef.current };
      const selectedRows = Object.values(newIndex);

      selectedRows.forEach((row) => {
        if (!indexedRows[row.id]) {
          delete newIndex[row.id];
        }
      });

      setSelectionIndex(newIndex);
    }, [rows]);

    useEffect(() => {
      onRowSelect(Object.values(selectionIndex));
    }, [selectionIndex, onRowSelect]);

    const allRowsAreSelected =
      Object.values(rows).length === Object.values(selectionIndex).length;

    // Set pagination state to false when currentRowsPerPage is set
    useEffect(() => {
      setSpinnerState({ loading: false, callback: null });
    }, [currentRowsPerPage, selectionIndex]);

    // Call callback function after 500ms to display spinner
    useEffect(() => {
      const { callback, loading } = spinnerState;

      if (loading && callback) {
        setTimeout(() => {
          callback();
        }, 500);
      }
    }, [spinnerState]);

    /**
     * PAGINATION HANDLERS
     *
     */
    const handleChangePage = (_event, v) => {
      setCurrentPage(v);
    };

    const handleChangeRowsPerPage = (event) => {
      setSpinnerState({
        loading: true,
        callback: () => {
          setCurrentRowsPerPage(event.target.value);
          setCurrentPage(0);
        },
      });
    };

    /**
     * SORT HANDLERS
     *
     * */

    useEffect(() => {
      if (columnSortKey === DISABLE_SORT_ON_LOAD_KEY) {
        setSortedRows(rows);
        return;
      }

      const columnData = columnIndex[columnSortKey];
      if (!columnData) {
        throw new Error(
          "Something's gone wrong - we're trying to sort the data by a column key that doesn't exist in the columnIndex"
        );
      }
      const sortFunction = columnData.sortFn || defaultSortComparator;
      const sortedRows = [...rows].sort((rowA, rowB) => {
        // Be aware that Array.sort() sorts in place.
        const a = rowA[columnSortKey];
        const b = rowB[columnSortKey];
        if (columnSortAscending) {
          return sortFunction(a, b);
        }
        return sortFunction(b, a);
      });

      setSortedRows(sortedRows);
    }, [rows, columnSortKey, columnSortAscending, columnIndex]);

    const handleSortClick = (columnKey: string) => {
      // If we are already sorting this column, just invert the order
      if (columnKey === columnSortKey) {
        setColumnSortAscending(!columnSortAscending);
      } else {
        // If it is a new key, sort using default order
        setColumnSortKey(columnKey);
        setColumnSortAscending(DEFAULT_FIRST_SORT_ORDER);
      }
    };
    const showSpinner = isLoading || spinnerState.loading;
    const hasRowsToDisplay = !!rows.length;

    return (
      <StyledTableWrapper maxHeight={maxHeight} className='data-table-wrapper'>
        <TableContainer className={`${className} select-mode-${selectMode}`}>
          <Table
            stickyHeader
            className={
              showSpinner || !hasRowsToDisplay ? 'spinner-wrapper' : ''
            }
          >
            <EnhancedTableHead
              columns={columns}
              selectMode={selectMode}
              selectAllClick={handleSelectAllClick}
              selectAllInPageClick={handleSelectAllInPageClick}
              selectionState={selectionState}
              onSortClick={handleSortClick}
              columnSortKey={columnSortKey}
              columnSortAscending={columnSortAscending}
              hasRowActionsColumn={rowActions.length > 0}
              batchActions={batchActions}
              selectionIndex={selectionIndex}
              allRowsAreSelected={allRowsAreSelected}
              totalNumberOfRows={rows.length}
            />
            <TableBody>
              {showSpinner ? (
                <Spinner />
              ) : hasRowsToDisplay ? (
                rowsToDisplay.map((rowData) => {
                  const isSelected = !!selectionIndex[rowData.id];
                  return (
                    <TableRow
                      key={rowData.id}
                      rowData={rowData}
                      isSelected={isSelected}
                      selectMode={selectMode}
                      onRowClick={handleRowClick}
                      columns={columns}
                      defaultRenderMethod={defaultRenderMethod}
                      rowActions={rowActions}
                      isSelectedRow={selectedRow.id === rowData.id}
                    />
                  );
                })
              ) : (
                emptyTablePlaceholder
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component='div'
          page={currentPage}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          count={rows.length}
          rowsPerPage={currentRowsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
          labelDisplayedRows={({ from, to, count }) =>
            `Displaying ${from}-${to} of ${count}`
          }
        />
      </StyledTableWrapper>
    );
  }
);

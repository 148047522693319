import React, { useCallback } from 'react';

import { MultiValueProps, OptionProps, IndicatorProps } from 'react-select';
import { MenuList } from '@material-ui/core';
import { wrapMenuList } from 'react-select-async-paginate'; // Is anyone else getting an error message here - please let me know
import { StyledReactSelectChip } from '../../Atoms/Forms/StyledReactSelect/StyledReactSelect';
import {
  StyledAsyncPaginate,
  StyledMenuItem,
  StyledLoadingIndicator,
  StyledIconButton,
  StyledMenuList,
} from '../LazyLoadingContactsSelector/LazyLoadingContactsSelector.styles';
import {
  DistributionListType,
  LazyLoadingDistributionListProps,
  DropdownIndicatorType,
} from './LazyLoadingDistributionListSelector.types';
import {
  DownArrow,
  UpArrow,
} from './LazyLoadingDistributionListSelector.styles';

const CustomMenuList = wrapMenuList((props) => {
  const { children, isLoading, innerRef } = props;
  return (
    <StyledMenuList
      ref={innerRef}
      style={{ maxHeight: '300px', overflowY: 'auto' }}
    >
      {children}
      {isLoading && <StyledLoadingIndicator />}
    </StyledMenuList>
  );
});

const MenuItem = (props: OptionProps<DistributionListType>) => {
  const { data, innerProps, isFocused } = props;
  const { name } = data;
  return (
    <StyledMenuItem {...innerProps} selected={isFocused}>
      <span className='name'>{name} </span>
    </StyledMenuItem>
  );
};

const DropdownIndicator = <T,>(props: IndicatorProps<T>) => {
  const {
    selectProps: { menuIsOpen },
  } = props;

  return menuIsOpen ? <UpArrow /> : <DownArrow />;
};

const Chip = (props: MultiValueProps<DistributionListType>) => {
  const { removeProps, data } = props;
  const { onClick } = removeProps;
  const { name } = data;
  return (
    <StyledReactSelectChip color='accent' text={name} onDeleteClick={onClick} />
  );
};

export const LazyLoadingDistributionListSelector = (
  props: LazyLoadingDistributionListProps
) => {
  const { value, loadMoreFunction, onChange } = props;

  const loadOptions = (
    search: string,
    prevOptions: Array<DistributionListType>
  ) => {
    return loadMoreFunction(search, 'name', prevOptions);
  };

  // Because we are currently using this one as a single, we'll just convert the result of it back into an array.
  const handleChange = useCallback(
    (value: DistributionListType) => {
      if (value === null) {
        onChange([]);
      } else {
        onChange([value]);
      }
    },
    [onChange]
  );

  return (
    <>
      <StyledAsyncPaginate
        value={value}
        isClearable
        loadOptions={loadOptions}
        getOptionValue={(v) => v.id}
        getOptionLabel={(v) => v.name}
        placeholder='Select List'
        onChange={handleChange}
        additional={{
          page: 1,
        }}
        components={{
          IndicatorSeparator: null,
          DropdownIndicator,
          MultiValue: Chip,
          MenuList: CustomMenuList,
          Option: MenuItem,
        }}
      />
    </>
  );
};

import React, { useState } from 'react';
import { ContentState, EditorState, Modifier, RichUtils } from 'draft-js';
import type { EntityInstance } from 'draft-js';
import getContentStateFragment from 'draft-js/lib/getContentStateFragment';
import { IconButton, InputAdornment, Popover } from '@material-ui/core';
import { TextField } from '../../Atoms/Forms';
import { Icons } from '../../Foundation/Icons';
import { LinkAdornmentStyle, LinkSourceTextWrapper } from './LinkSource.styles';

type LinkSourceProps = {
  // The editorState is available for arbitrary content manipulation.
  editorState: EditorState;
  // Takes the updated editorState, or null if there are no changes.
  onComplete: (nextState: EditorState) => void;
  // Closes the source, without focusing the editor again.
  onClose: () => void;
  // Whole entityType configuration, as provided to the editor.
  entityType: { type: string };
  // Current entityKey to edit, if any.
  entityKey?: string;
  // Current entity to edit, if any.
  entity?: EntityInstance;
};

export const createLinkSource = (ref: React.MutableRefObject<null>) => (
  props: LinkSourceProps
) => {
  const {
    editorState,
    onComplete,
    onClose,
    entityType,
    entity,
    entityKey,
  }: LinkSourceProps = props;

  const httpsPrefix = 'https://';
  const currentSrc = (entity && entity.getData().src) || '';
  const [src, setSrc] = useState<string | undefined>(currentSrc);

  const contentState = editorState.getCurrentContent();
  const selection = editorState.getSelection();
  const fragment = getContentStateFragment(contentState, selection);
  const selectedText = fragment.map((block) => block.getText()).join('\n');
  const [plainText, setPlainText] = useState(selectedText);

  const handleSrcChange = (input) => {
    let url = input ? input.trim() : '';
    // add https:// to string if not present
    if (!/^(?:f|ht)tps?:\/\//.test(input)) {
      url = `${httpsPrefix}${input}`;
    }
    setSrc(url);
  };

  const newContentState = contentState.createEntity(
    entityType.type,
    'IMMUTABLE',
    {
      src,
    }
  );

  const handleRemove = () => {
    const removedEntityContent = Modifier.replaceText(
      contentState,
      selection,
      plainText,
      undefined,
      undefined
    );

    const removedEntityState = EditorState.push(
      editorState,
      removedEntityContent,
      'insert-characters'
    );
    onComplete(removedEntityState);
    onClose();
  };

  const getEntityKey = (contentState: ContentState) =>
    currentSrc === src && entityKey
      ? (entityKey as string)
      : contentState.getLastCreatedEntityKey();

  const handleClose = () => {
    if (src === httpsPrefix) {
      handleRemove();
      return;
    }
    if (selectedText === plainText) {
      const nextState = RichUtils.toggleLink(
        editorState,
        editorState.getSelection(),
        getEntityKey(newContentState)
      );
      onComplete(nextState);
    } else {
      const srcContentState = Modifier.applyEntity(
        newContentState,
        editorState.getSelection(),
        getEntityKey(newContentState)
      );

      const textReplaceContentState = Modifier.replaceText(
        srcContentState,
        srcContentState.getSelectionAfter(),
        plainText,
        undefined,
        getEntityKey(srcContentState)
      );

      const textReplaceEditorState = EditorState.push(
        editorState,
        textReplaceContentState,
        'apply-entity'
      );

      onComplete(textReplaceEditorState);
    }
    onClose();
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleClose();
    }
  };

  const handleLinkOut = () => {
    if (src) {
      window.open(src, '_blank');
    }
  };

  const removeHttpString = (url) => {
    return url.replace(/(^\w+:|^)\/\//, '');
  };

  return ref.current ? (
    <Popover
      open
      anchorEl={ref.current}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      style={{ top: '5px' }}
    >
      <LinkSourceTextWrapper>
        <TextField
          className='LinkSrcField'
          label='Link address'
          placeholder='Enter Link URL'
          variant='filled'
          defaultValue={currentSrc && removeHttpString(currentSrc)}
          onChange={handleSrcChange}
          autoFocus
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  onClick={handleLinkOut}
                  className='LinkOutAdornment'
                  title='Open link in new tab'
                >
                  <Icons.LinkOut style={LinkAdornmentStyle} />
                </IconButton>
                <IconButton
                  onClick={handleRemove}
                  className='CloseAdornment'
                  title='Remove link from text'
                >
                  <Icons.Close style={LinkAdornmentStyle} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          onKeyPress={handleKeyPress}
        />
        <TextField
          className='LinkTextField'
          label='Text to display'
          value={plainText}
          variant='filled'
          onChange={(e) => setPlainText(e)}
          onKeyPress={handleKeyPress}
        />
      </LinkSourceTextWrapper>
    </Popover>
  ) : null;
};

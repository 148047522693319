import styled from 'styled-components';
import { Colors } from '../../Foundation/Colors';

export const StyledWebLinkReminderInfoPanel = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;

  padding: 8px;
  margin: 16px 0px 0px;

  background-color: ${Colors.accent_1};

  span {
    margin: 0 8px;
  }
  .icon {
    fill: ${Colors.accent};
  }
`;

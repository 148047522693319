import React from 'react';
import { ContentState } from 'draft-js';

type LinkProps = {
  entityKey: string;
  contentState: ContentState;
  children: Node;
  onEdit: (string) => void;
};

export const Link = ({
  entityKey,
  contentState,
  children,
  onEdit,
}: LinkProps) => {
  const data = contentState.getEntity(entityKey).getData();
  const { src } = data;

  const handleClick = () => {
    onEdit(entityKey);
  };

  return src ? (
    <a
      onClick={handleClick}
      title={src}
      style={{ textDecoration: 'underline', color: 'inherit' }}
      className='Link'
    >
      {children}
    </a>
  ) : (
    children
  );
};

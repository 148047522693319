import React, { forwardRef, memo, useCallback } from 'react';
import { IconButtonProps, IconButton } from '../../Atoms/Navigations';

import { PopoverMenuBase, PopoverMenuBaseProps } from '../PopoverMenuBase';

type PopoverMenuSimpleProps = {
  menuItems: PopoverMenuBaseProps['menuItems'];
} & Exclude<IconButtonProps, 'onClick'>;

/**
 * Simple Popover Menu with a IconButton as a trigger.
 * Note that all of the button props can be passed in to style, etc.
 * @param props
 */
export const PopoverMenuSimpleIcon = memo(
  (props: PopoverMenuSimpleProps): JSX.Element => {
    const { menuItems, ...rest } = props;

    const triggerComponent = useCallback(
      forwardRef(({ onClick }, ref) => {
        return <IconButton onClick={onClick} ref={ref} {...rest} />;
      }),
      []
    );

    return (
      <PopoverMenuBase
        menuItems={menuItems}
        TriggerComponent={triggerComponent}
      />
    );
  }
);

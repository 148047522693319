import React, { useState } from 'react';
import { ToggleSwitch } from '../../Atoms/ToggleSwitch';
import { DurationSelector } from '../../Molecules/DurationSelector';

import { StyledFlatPanel } from './CollectResponses.styles';
import { CollectResponsesProps } from './CollectResponses.types';

export const CollectResponses = ({
  onChange,
  selectedDuration,
  errors,
  enabled,
  defaultDuration = {
    days: 0,
    hours: 0,
    minutes: 0,
  },
}: CollectResponsesProps): JSX.Element => {
  const [tempDuration, setTempDuration] = useState(
    selectedDuration || defaultDuration
  );
  const handleDurationChange = (duration) => {
    setTempDuration(duration);
    onChange(duration);
  };

  const handleCheckboxChange = (checked) => {
    if (checked) {
      onChange(tempDuration);
    } else {
      onChange(null);
    }
  };

  return (
    <div>
      <ToggleSwitch
        label='Collect Responses'
        onChange={handleCheckboxChange}
        defaultValue={enabled}
      />
      {!!selectedDuration && (
        <StyledFlatPanel>
          <div className='duration-selector-label'>
            Collect Responses for Up to:
          </div>
          <DurationSelector
            selectedDuration={tempDuration}
            onChange={handleDurationChange}
            errors={errors}
          />
        </StyledFlatPanel>
      )}
    </div>
  );
};

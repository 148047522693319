import { Colors } from '../../../Foundation/Colors';
import { ModelType } from '../../utilityTypes';

export const richTextDividerBlockModel = (id: string): ModelType => ({
  id,
  type: 'Divider',
  label: 'Divider',
  properties: [
    {
      name: 'divider',
      label: 'divider',
      control: 'line divider',
      value: 'Line Divider',
      __typename: 'ComponentProperty',
    },
    {
      name: 'text',
      label: 'Styles',
      control: 'TitleDivider',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'dividerColor',
      label: 'Line Colour',
      control: 'ColorPicker',
      value: Colors.black,
      __typename: 'ComponentProperty',
    },
    {
      name: 'backgroundColor',
      label: 'Background Colour',
      control: 'ColorPicker',
      value: Colors.white,
      __typename: 'ComponentProperty',
    },
    {
      name: 'lineThickness',
      label: 'Weight',
      control: 'Slider',
      value: '3',
      __typename: 'ComponentProperty',
    },
    {
      name: 'margin',
      label: 'Padding',
      control: 'TitleDividerNoLine',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'marginTop',
      label: 'Top',
      control: 'Slider',
      value: '20',
      __typename: 'ComponentProperty',
    },
    {
      name: 'marginBottom',
      label: 'Bottom',
      control: 'Slider',
      value: '20',
      __typename: 'ComponentProperty',
    },
  ],
  __typename: 'Component',
});

import React from 'react';

import { Accordion } from '../../Atoms/Navigations/Accordion/Accordion';
import { AccordionGroupWrapper } from './AccordionGroup.style';
import { AccordionGroupProps } from './AccordionGroup.types';

export const AccordionGroup = ({ accordions }: AccordionGroupProps) => {
  const renderAccordions = accordions.map(
    ({ groupName, groupItems }, index) => (
      <Accordion
        key={groupName}
        groupName={groupName}
        groupItems={groupItems}
        isExpanded={index === 0}
      />
    )
  );

  return <AccordionGroupWrapper>{renderAccordions}</AccordionGroupWrapper>;
};

import React, { Fragment, useState } from 'react';
import { StyledContainer } from './HorizontalSelector.styles';

export type HorizontalSelectorProps = {
  items: Array<{
    label: string;
    value: string;
  }>;
  type: string;
  toneValue: string;
  onChange: (type: string, value: string) => void;
  fullWidth?: boolean;
  disabled?: boolean;
};

export const HorizontalSelector = ({
  type,
  toneValue,
  items,
  onChange,
  fullWidth = false,
  disabled = false,
}: HorizontalSelectorProps): JSX.Element => {
  const handleOnChange = (event: React.FormEvent<HTMLInputElement>) => {
    const { value } = event.target;
    onChange(type, value);
  };

  return (
    <StyledContainer fullWidth={fullWidth} disabled={disabled}>
      {items.map(({ label, value }) => (
        <Fragment key={label}>
          <input
            type='radio'
            id={`${type}-${label}`}
            name={type}
            value={value}
            onChange={handleOnChange}
            checked={value === toneValue}
            disabled={disabled}
          />
          <label htmlFor={`${type}-${label}`}>{label}</label>
        </Fragment>
      ))}
    </StyledContainer>
  );
};

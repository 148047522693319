import styled, { css } from 'styled-components';
import { Colors } from '../../Foundation/Colors';
import { Fonts } from '../../Foundation/Fonts';

export const StyledToneCard = styled.div<{ disabled: boolean }>`
  display: flex;
  flex-direction: column;
  height: 134px;
  background-color: ${Colors.white};
  padding: 16px;
  transition: all 0.2s ease;
  position: relative;
  border: 1px solid ${Colors.accent_4};
  box-sizing: border-box;
  border-radius: 4px;

  .icon {
    padding-right: 4px;
    ${Fonts.subheadSmall}
    font-size: 15px;
  }

  .title {
    display: flex;
    ${Fonts.link}
    text-transform: capitalize;
  }

  .selector {
    padding: 8px 0px;
    width: 100%;
  }

  .description {
    ${Fonts.caption};
    color: ${Colors.grey_blue_4};
    .bold {
      font-weight: 600;
    }
  }

  ${(props) =>
    props.disabled &&
    css`
      cursor: default;
      opacity: 0.7;

      .selector {
        cursor: default;
      }
    `}
`;

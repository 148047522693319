import styled from 'styled-components';
import { Colors } from '../../Foundation/Colors';
import { Fonts } from '../../Foundation/Fonts';

export const DataFilterWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  .MuiAutocomplete-root {
    min-width: 200px;
    padding: 0 0 0 2px;
  }

  .MuiAutocomplete-inputRoot[class*='MuiInput-root'] {
    padding-right: 0;
  }

  .MuiAutocomplete-inputRoot {
    flex-wrap: wrap;
    margin-left: 30px;
    padding: 0;
    justify-content: flex-end;

    .MuiAutocomplete-input {
      display: none;
    }

    .MuiAutocomplete-endAdornment {
      position: inherit;
    }
  }

  .MuiInput-underline:before {
    border-bottom: none;
  }

  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: none;
  }

  .MuiInput-underline:after {
    border-bottom: none;
  }

  .MuiAutocomplete-popupIndicatorOpen {
    transform: none;
  }

  .MuiIconButton-root:hover {
    background-color: transparent;
  }

  .MuiChip-root {
    padding: 0 4px 0 8px;
    ${Fonts.link}
  }

  .chip-sms-sent {
    background-color: ${Colors.blue_light};

    svg {
      fill: ${Colors.blue};
    }
  }

  .chip-sms-received {
    background-color: ${Colors.accent_2};

    svg {
      fill: ${Colors.accent};
    }
  }

  .chip-sms-failed {
    background-color: ${Colors.grey_blue_0};

    svg {
      fill: ${Colors.grey_blue};
    }
  }

  .MuiAutocomplete-popper {
    max-width: fit-content;
  }
`;

export const IconWrapper = styled.div`
  display: inline-block;
  vertical-align: middle;
  width: 24px;
  padding: 8px 0 0 0;

  svg {
    fill: ${Colors.grey_blue_4};
  }
`;

export const Suffix = styled.div`
  padding: 4px 0 0 24px;

  svg {
    display: inline-block;
    fill: ${Colors.accent};
    width: 20px;
    height: 20px;
  }

  p {
    display: inline-block;
    ${Fonts.link}
    color: ${Colors.accent};
    margin: 2px 0 0 8px;
    vertical-align: top;
  }
`;

export const CheckboxWrapper = styled.div`
  margin: 0 15px 0 0;
  ${Fonts.link}
  color: ${Colors.grey_blue};

  .Mui-checked {
    svg {
      fill: ${Colors.accent};
    }
  }
`;

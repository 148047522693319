import React, { useState } from 'react';
import moment from 'moment';
import { DatePicker } from '../DatePicker';
import { DatePickerFromToPropTypes } from './DatePickerFromTo.types';
import {
  DatePickerFromToPadding,
  StyledFormHelperText,
} from './DatePickerFromTo.styles';

export const DatePickerFromTo = ({
  defaultValue,
  onChange = () => undefined,
  dateFormat = 'DD/MM/YYYY',
  includeTime = false,
}: DatePickerFromToPropTypes): JSX.Element => {
  const dateTimeFormat = 'YYYY-MM-DD[T]HH:mm';
  const formatValue = (value) => moment(value).format(dateTimeFormat);

  const defaultDate =
    typeof defaultValue === 'string' ? JSON.parse(defaultValue) : defaultValue;

  const [state, setState] = useState(defaultDate);

  const handleFromChange = (value) => {
    setState({
      ...state,
      from: formatValue(value),
    });
    return (
      onChange &&
      onChange(
        JSON.stringify({
          ...state,
          from: formatValue(value),
        })
      )
    );
  };

  const handleToChange = (value) => {
    setState({
      ...state,
      to: formatValue(value),
    });
    return (
      onChange &&
      onChange(
        JSON.stringify({
          ...state,
          to: formatValue(value),
        })
      )
    );
  };

  const minDate = state && state.from;

  const getDate = (dateTime) => moment(dateTime).format('YYYY-MM-DD');

  const errorMessage = () => {
    if (moment(getDate(state.from)).isAfter(getDate(state.to))) {
      return "'To' date must be after 'From' date";
    }
    if (moment(state.from).isAfter(state.to)) {
      return "'To' time must be after 'From' time";
    }
    return ' ';
  };

  const sharedProps = {
    includeTime,
    dateFormat,
    filled: true,
  };

  const fromProps = {
    label: 'From',
    defaultValue: state.from,
    onChange: (value) => handleFromChange(value),
    ...sharedProps,
  };

  const toProps = {
    label: 'To',
    defaultValue: state.to,
    onChange: (value) => handleToChange(value),
    minDate,
    ...sharedProps,
  };

  return (
    <>
      <DatePicker {...fromProps} />
      <DatePickerFromToPadding />
      <DatePicker {...toProps} />
      <StyledFormHelperText>{errorMessage()}</StyledFormHelperText>
    </>
  );
};

import React from 'react';
import { Badge } from '@material-ui/core';
import { Icon } from '../Icon';
import { Tooltip } from '../Dialogs/Tooltip';
import { LabelWithTooltipProps } from './LabelWithTooltip.types';
import { StyledLabelWrapper } from './LabelWithTooltip.style';

export const LabelWithTooltip = ({ label, info }: LabelWithTooltipProps) => {
  if (info) {
    return (
      <StyledLabelWrapper>
        <span>{label}</span>
        <Tooltip arrow title={info} ariaLabel='info' placement='bottom'>
          <Badge>
            <Icon icon='InfoOutline' />
          </Badge>
        </Tooltip>
      </StyledLabelWrapper>
    );
  }
  return <span>{label}</span>;
};

import React from 'react';
import { Icon } from '../../../Atoms/Icon';

import { NavigationTypes } from '../GlobalNavigation.types';
import { NavigationStyle } from './Navigation.styles';

export const Navigation = ({ linkItems }: NavigationTypes): JSX.Element => {
  const path = window.location.pathname;

  const navigationItems = linkItems.map(({ iconName, pathname, title }) => {
    const getPrefix = (pathname) => pathname && pathname.split('/')[1];

    // active link to match prefix pathname with subroutes prefix
    const activeLink = getPrefix(path) === getPrefix(pathname) ? 'active' : '';

    return (
      <a
        key={pathname}
        className={activeLink}
        data-link={title}
        href={pathname}
      >
        <Icon icon={iconName} />
      </a>
    );
  });

  return <NavigationStyle>{navigationItems}</NavigationStyle>;
};

import styled from 'styled-components';
import { TEMPORARY_INFO_BANNER_HEIGHT } from '../../Foundation/Spacing';

export const TemporaryInfoBannerWrapper = styled.div`
  display: flex;
  width: 100%;
  height: ${TEMPORARY_INFO_BANNER_HEIGHT};
  opacity: 0.8;
  color: ${({ color }) => color};
`;

export const CloseIconWrapper = styled.button`
  width: 5%;
  border: none;
  background: transparent;

  svg {
    fill: ${({ color }) => (color ? `${color} !important` : null)};
  }
`;

export const IllustrationWrapper = styled.div`
  display: flex;
  flex-flow: column-reverse;
`;

export const StartIllustrationWrapper = styled(IllustrationWrapper as any)`
  width: 15%;
`;

export const EndIllustrationWrapper = styled(IllustrationWrapper as any)`
  width: 10%;
`;

export const CardContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 70%;
`;

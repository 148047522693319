import React from 'react';

import { VariableChip } from '../../Molecules/VariableChip';

// This is the component for _displaying_ the variable. ie. it is the _decorator_ of the new entitty
// https://www.draftail.org/docs/entities#decorators
// Pretty straightforward.
// YOU HAVE TO RENDER THE CHILDREN. I think the library is listeing to events on it to detect deletion etc.
// https://github.com/facebook/draft-js/issues/2480
export const VariablePill = (props): JSX.Element => {
  const { contentState, entityKey, children } = props;
  const data = contentState.getEntity(entityKey).getData();

  // Gotta make sure you're using the children as the text content of the VariableChip.
  // So that you can do the 'cursor to the middle of it and start typing'
  return (
    <>
      <VariableChip
        variable={data}
        labelOverride={children}
        color={data.isError ? 'error' : 'variable'}
      />
    </>
  );
};

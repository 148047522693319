import React from 'react';
import { LetterAvatar } from '../../../../Atoms/DataDisplay';
import { Tooltip } from '../../../../Atoms/Dialogs/Tooltip';

import { Icons } from '../../../../Foundation/Icons';
import { PopoverMenu } from '../../../../Molecules/PopoverMenu';
import { WorkspaceSelectorTypes } from '../../GlobalNavigation.types';
import {
  StyledTooltipContent,
  StyledWorkspaceSelector,
  StyledWorkspaceSelectorContent,
  StyledWorkspaceName,
  StyledWorkspaceSubTitle,
  StyledWorkspaceIcon,
} from './WorkspaceSelector.styles';

export const WorkspaceSelector = ({
  workspaceLinkItems,
}: WorkspaceSelectorTypes): JSX.Element => {
  const findSelectedWorkSpaceName = (): string => {
    const workspace =
      workspaceLinkItems.find((workspace) => workspace.isActive) ||
      workspaceLinkItems[0];
    return workspace ? workspace.title : '';
  };

  const hasMultipleWorkspaces =
    workspaceLinkItems && workspaceLinkItems.length > 1;
  const sortedWokspaces = hasMultipleWorkspaces
    ? workspaceLinkItems.sort((a, b) => (a.title < b.title ? -1 : 1))
    : workspaceLinkItems;
  const activeWorkspaceName = findSelectedWorkSpaceName();

  return (
    <StyledWorkspaceSelector
      className={hasMultipleWorkspaces ? '' : 'single-workspace'}
      aria-label='workspace'
      role='navigation'
    >
      <PopoverMenu
        linkItems={sortedWokspaces}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        <Tooltip
          arrow
          ariaLabel='Tooltip'
          placement='right'
          title={
            <StyledTooltipContent>
              Workspace
              <p>{activeWorkspaceName}</p>
            </StyledTooltipContent>
          }
        >
          <StyledWorkspaceSelectorContent data-link='WorkspaceSelector'>
            <LetterAvatar content={activeWorkspaceName} />
            <StyledWorkspaceName>
              {activeWorkspaceName}
              <StyledWorkspaceSubTitle>
                {hasMultipleWorkspaces ? 'Switch Workspace' : ''}
              </StyledWorkspaceSubTitle>
            </StyledWorkspaceName>
            <StyledWorkspaceIcon>
              {hasMultipleWorkspaces ? <Icons.ChevronRight /> : ''}
            </StyledWorkspaceIcon>
          </StyledWorkspaceSelectorContent>
        </Tooltip>
      </PopoverMenu>
    </StyledWorkspaceSelector>
  );
};

import React, { useEffect, useState } from 'react';
import { useSegmentContext } from '../SegmentContextProvider';

type User = {
  userId: string;
} & Record<string, string>;

type UserProvider = {
  user: User | null;
};

const UserProviderContext = React.createContext<UserProvider>({
  user: null,
});

export const UserProvider = (
  props: React.PropsWithChildren<Partial<UserProvider>>
) => {
  const { children, user } = props;

  const [userState, setUserJwtState] = useState(user || null);

  const { trackIdentity } = useSegmentContext();

  // Note - examining the cookie for the JWT is not possible - as it is an HTTP only cookie.
  // https://developer.mozilla.org/en-US/docs/Web/HTTP/Cookies#restrict_access_to_cookies

  // Possibly in the future this component may contain logic for retrieving the JWT itself (eg making a call to an API).
  useEffect(() => {
    setUserJwtState(user || null);
  }, [user]);

  // Track identity whenever the userJwt changes
  useEffect(() => {
    // Is it ok to pass userId = null if there is no user?
    // Will that create an anonymous id? https://segment.com/docs/connections/spec/identify/#anonymous-id
    const jwt = userState || { userId: null };
    const { userId, ...rest } = jwt;

    trackIdentity({
      userId,
      traits: rest,
    });
  }, [userState, trackIdentity]);

  return (
    <UserProviderContext.Provider value={{ user: userState }}>
      {children}
    </UserProviderContext.Provider>
  );
};

export const useUser = (): UserProvider['user'] => {
  return React.useContext(UserProviderContext).user;
};

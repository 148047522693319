import styled from 'styled-components';
import { Colors } from '../../../Foundation/Colors';
import { Elevations } from '../../../Foundation/Elevations';
import { TRANSITION_TIME } from '../../../Foundation/Spacing';

export const StyledCardButton = styled.button`
  width: 264px;
  height: 272px;
  padding: 16px;
  border-radius: 8px;
  background-color: ${Colors.white};
  border: 0;
  box-shadow: ${Elevations.elevation_1};
  transition: all ${TRANSITION_TIME};
  box-sizing: border-box;
  outline: none;

  &:hover {
    cursor: 'pointer';
    box-shadow: ${Elevations.elevation_3};
    transform: translateY(-4px);
  }
`;

import React, { PureComponent, Fragment } from 'react';

import { DragAndDropContext } from '../DroppableTargetItems/Context';
import { DroppableSourceItems } from '../DroppableSourceItems/DroppableSourceItems';
import { ComponentPropertyContainer } from './DroppableItemProperties.styles';
import {
  getAvailableProperties,
  CloseButton,
  ComponentPropertySettings,
} from './utils';
import { DroppableItemPropertiesProps } from './DroppableItemProperties.types';

export const renderSettings = (
  draft,
  activeComponent,
  setActiveComponent,
  activeChannel,
  getModifyVariableConfirmationModal,
  variableMenuData,
  components,
  togglePropertyPanel
) => {
  const { label, properties, id } = activeComponent || {};

  const handleClose = () => {
    setActiveComponent({ activeComponent: null });
  };

  const formProperties = getAvailableProperties({
    properties,
    activeComponentId: id,
    draft,
    activeChannel,
  });

  const isComponentSelected = formProperties.length !== 0;

  const handleComponentSelect = (value: boolean) => {
    togglePropertyPanel(value);
  };

  const defaultPropertyTitle =
    isComponentSelected && label ? label : `${activeChannel} Settings`;

  return (
    <ComponentPropertyContainer
      onChange={handleComponentSelect(isComponentSelected)}
      className='component-property-panel'
      activeChannel={activeChannel}
    >
      <CloseButton
        visibility={activeComponent && isComponentSelected}
        onHandleClick={handleClose}
      />
      {isComponentSelected ? (
        <ComponentPropertySettings
          defaultPropertyTitle={defaultPropertyTitle}
          componentProperties={formProperties}
          setActiveComponent={setActiveComponent}
          activeComponent={activeComponent}
          variableMenuData={variableMenuData}
          activeChannel={activeChannel}
          getModifyVariableConfirmationModal={
            getModifyVariableConfirmationModal
          }
        />
      ) : (
        <Fragment>
          <DroppableSourceItems
            title='Components'
            componentList={components}
            isEnabled={Boolean(draft && !draft.templateId)}
          />
        </Fragment>
      )}
    </ComponentPropertyContainer>
  );
};

export class DroppableItemProperties extends PureComponent<DroppableItemPropertiesProps> {
  static contextType = DragAndDropContext;

  render() {
    const {
      draft,
      activeChannel,
      getModifyVariableConfirmationModal = () => null,
      variableMenuData,
      components,
      togglePropertyPanel,
    } = this.props;
    const [{ activeComponent }, setActiveComponent] = this.context;

    return renderSettings(
      draft,
      activeComponent,
      setActiveComponent,
      activeChannel,
      getModifyVariableConfirmationModal,
      variableMenuData,
      components,
      togglePropertyPanel
    );
  }
}

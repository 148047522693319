import React from 'react';

import { DynamicComponentProps } from './DynamicComponent.types';
import { FormControls as Controls } from './index';

export const DynamicComponent = (props: DynamicComponentProps) => {
  const { type } = props;
  const CustomComponent = Controls[type];

  if (CustomComponent === undefined) {
    return null;
  }
  return <CustomComponent {...props} />;
};

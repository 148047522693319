import React, {
  useState,
  useEffect,
  ReactElement,
  forwardRef,
  useRef,
} from 'react';
import { Tooltip } from '../../Atoms/Dialogs/Tooltip';
import { ResponsiveLabelType } from './ResponsiveLabel.types';
import { LabelWrapper } from './ResponsiveLabel.style';

export const ResponsiveLabel = forwardRef<HTMLDivElement, ResponsiveLabelType>(
  ({ className, text, maxWidth, tooltipPlacement }, ref): ReactElement => {
    const textRef = useRef<HTMLDivElement | null>(null);
    const [isOverflow, setIsOverflow] = useState(false);

    useEffect(() => {
      if (
        textRef.current &&
        textRef.current.offsetWidth < textRef.current.scrollWidth
      ) {
        setIsOverflow(true);
      }
    }, [isOverflow]);

    return (
      <Tooltip
        role='tooltip'
        title={text}
        disableHoverListener={!isOverflow}
        arrow
        ariaLabel={isOverflow ? 'hover enabled' : 'hover disabled'}
        placement={tooltipPlacement}
      >
        <LabelWrapper ref={ref} maxWidth={maxWidth}>
          <p
            className={`responsive-label ${
              isOverflow ? `has-tooltip ${className}` : `${className}`
            }`}
            ref={textRef}
            aria-expanded={!isOverflow}
          >
            {text}
          </p>
        </LabelWrapper>
      </Tooltip>
    );
  }
);

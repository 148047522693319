import styled from 'styled-components';
import { Colors } from '../../../../Foundation/Colors';
import { Fonts } from '../../../../Foundation/Fonts';

export const StyledWorkspaceSelector = styled.div`
  &.single-workspace {
    pointer-events: none;
  }
`;

export const StyledWorkspaceSelectorContent = styled.div`
  display: flex;
  padding: 16px;
  width: fit-content;
  box-sizing: border-box;
  border-bottom: 1px solid ${Colors.grey_blue_1};

  .MuiAvatar-root {
    width: 32px;
    height: 32px;
    align-self: flex-end;
  }
`;

export const StyledWorkspaceName = styled.div`
  margin: 0 16px;
  align-self: flex-end;
  ${Fonts.body}
  color: ${Colors.grey_blue};
`;

export const StyledWorkspaceSubTitle = styled.div`
  ${Fonts.caption}
`;

export const StyledWorkspaceIcon = styled.div`
  svg {
    height: 20px;
  }
`;

export const StyledTooltipContent = styled.div`
  ${Fonts.caption}

  p {
    ${Fonts.body}
    padding: 0;
    margin: 0;
  }
`;

// $FlowFixMe

import styled from 'styled-components';
import { Colors } from '../../../Foundation/Colors';
import { Fonts } from '../../../Foundation/Fonts';

export const StyledTextCounter = styled.div`
  && {
    font: ${Fonts.caption};
  }
  &.error {
    color: ${Colors.error};
  }
  &.primary {
    color: ${Colors.grey_blue};
  }
`;

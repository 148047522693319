import React from 'react';
import { Icon } from '../../Atoms/Icon';
import { Tooltip } from '../../Atoms/Dialogs/Tooltip';
import {
  ToneOfVoiceBetaHeaderWrapper,
  StyledInteractiveTooltip,
} from './ToneOfVoiceBetaHeader.style';

type PropType = {
  minimal?: boolean;
};

export const ToneOfVoiceBetaHeader = ({ minimal = false }: PropType) => (
  <ToneOfVoiceBetaHeaderWrapper minimal={minimal}>
    {!minimal && <Icon icon='ToneOfVoice' className='tone-icon' />}
    <div className='subhead'>Tone Detector</div>
    {!minimal && (
      <Tooltip
        arrow
        ariaLabel='Tone of Voice Beta Tooltip'
        interactive
        placement='bottom'
        padding='8px 16px'
        title={
          <StyledInteractiveTooltip>
            <div>
              Whispir analyses your message’s language for brand alignment.
            </div>
            {/* <a
              className='learn-more-button'
              onClick={() => {
                console.info('Learn more clicked!');
              }}
            >
              Learn more
            </a> */}
          </StyledInteractiveTooltip>
        }
      >
        <span>
          <Icon icon='InfoOutline' className='info-icon' />
        </span>
      </Tooltip>
    )}
    <div className='lozenge-small'> BETA </div>
  </ToneOfVoiceBetaHeaderWrapper>
);

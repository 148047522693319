import React, { ReactNode } from 'react';

import { StyledButtonGroup } from './ButtonGroup.styles';

export type ButtonGroupProps = {
  orientation?: 'vertical' | 'horizontal';
  align?: 'start' | 'center' | 'end';
  className?: string;
  children: ReactNode;
};

export const ButtonGroup = (props: ButtonGroupProps) => {
  const {
    orientation = 'horizontal',
    align = 'start',
    className = '',
    children,
  } = props;
  return (
    <StyledButtonGroup className={`${orientation} ${align} ${className}`}>
      {children}
    </StyledButtonGroup>
  );
};

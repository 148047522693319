import React from 'react';
import { DynamicBlockProps } from './DynamicBlock.types';
import { createComponent } from '.';

export const DynamicBlock = ({
  type,
  properties,
  ...restProperties
}: DynamicBlockProps) => {
  const CustomComponent = createComponent(type);
  const props = {};

  if (properties) {
    properties.forEach(({ name, value }) => {
      props[name] = value;
    });
  }

  return <CustomComponent {...props} {...restProperties} />;
};

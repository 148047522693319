import React, { useEffect, useRef, useState } from 'react';
import { ToneAndFeedbackType } from '../../../Utils/ToneOfVoice/toneOfVoiceTypes';
import { ToneScoreCard } from '../../../Molecules/ToneScoreCard';
import { ToneScoreCardWrapper } from './ToneScoreCardList.styles';
import { ToneScoreCardListProps } from './ToneScoreCardList.types';

const useHandleClickOutsideToneScoreCards = ({
  selectedTone,
  onToneCardBlur,
}: {
  selectedTone: ToneScoreCardListProps['selectedTone'];
  onToneCardBlur: ToneScoreCardListProps['onToneCardBlur'];
}) => {
  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current && selectedTone) {
      const clikedOutsideToneScoreCards = (e: MouseEvent) => {
        if (!containerRef.current.contains(e.target)) {
          onToneCardBlur();
        }
      };

      document.addEventListener('mousedown', clikedOutsideToneScoreCards);

      return () =>
        document.removeEventListener('mousedown', clikedOutsideToneScoreCards);
    }
  }, [containerRef, selectedTone, onToneCardBlur]);

  return {
    containerRef,
  };
};

export const ToneScoreCardList = ({
  toneScores,
  handleFeedback,
  handleFocusedToneCard,
  disabled = false,
  selectedTone,
  onToneCardBlur,
}: ToneScoreCardListProps): JSX.Element => {
  const { containerRef } = useHandleClickOutsideToneScoreCards({
    selectedTone,
    onToneCardBlur,
  });
  const [feedbackState, setFeedbackState] = useState<ToneAndFeedbackType>({
    joyful: undefined,
    surprised: undefined,
    sad: undefined,
    warm: undefined,
    angry: undefined,
    fearful: undefined,
  });
  const cardFocused = (tone) => {
    if (tone !== selectedTone) {
      handleFocusedToneCard(tone);
    }
  };

  const toneScoresWithFeedback = toneScores.length
    ? toneScores.map((item) => ({
        ...item,
        feedback: feedbackState[item.tone],
      }))
    : [];

  if (toneScoresWithFeedback.length)
    toneScoresWithFeedback.sort(
      (a, b) => b.sentenceHighlights - a.sentenceHighlights
    );

  const handleSetFeedbackState = (feedback, tone) => {
    const newFeedbackState = { ...feedbackState, [tone]: feedback };
    setFeedbackState(newFeedbackState);
    handleFeedback(newFeedbackState);
    toneScoresWithFeedback.map((item) => ({
      ...item,
      feedback: feedbackState[item.tone],
    }));
  };

  return (
    <div ref={containerRef}>
      {!!toneScoresWithFeedback.length &&
        toneScoresWithFeedback.map(
          ({
            tone,
            score,
            desiredToneIntensity,
            messageToneIntensity,
            feedback,
          }) => (
            <ToneScoreCardWrapper key={tone}>
              <ToneScoreCard
                disabled={disabled}
                tone={tone}
                toneScore={score}
                desiredToneIntensity={desiredToneIntensity}
                messageToneIntensity={messageToneIntensity}
                setFeedbackState={(feedback) =>
                  handleSetFeedbackState(feedback, tone)
                }
                handleFocus={cardFocused}
                selected={selectedTone === tone}
                includeFeedback
                feedback={feedback}
              />
            </ToneScoreCardWrapper>
          )
        )}
    </div>
  );
};

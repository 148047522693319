import styled from 'styled-components';
import { Colors } from '../../Foundation/Colors';
import { Elevations } from '../../Foundation/Elevations';
import { Fonts } from '../../Foundation/Fonts';

const notSupported = 'This template is not editable in Whispir Beta yet';

export const CardListWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  display: relative;

  a {
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  .disabled-template {
    pointer-events: none;
    position: relative;

    &:after {
      content: ${notSupported};
      position: absolute;
      transform: translate(-50%, -50%);
      top: 30%;
      left: 50%;
      width: 80%;
      text-align: center;
      box-sizing: border-box;
      border-radius: 4px;
      padding: 12px;
      background-color: ${Colors.grey_blue_3};
      color: ${Colors.white};
      ${Fonts.caption};
    }
  }
`;

export const CardWrapper = styled.div`
  margin-bottom: 24px;
  margin-right: 24px;
  position: relative;

  &:hover .more-options-wrapper {
    bottom: 18px;
  }

  &:hover .card-container {
    cursor: pointer;
    top: -8px;
    background-color: ${Colors.white};
    box-shadow: ${Elevations.elevation_2};
  }

  .workflow-icon {
    fill: ${Colors.white};
  }

  .group-icons {
    margin-left: calc(8px);
    display: flex;
    .sms,
    .email,
    .web {
      margin-left: calc(-8px);
    }
  }

  .more-options-wrapper {
    position: absolute;
    bottom: 8px;
    right: 16px;
    transition: all 0.2s ease-in-out;

    &:hover {
      cursor: pointer;
    }
  }

  .menu-more-options {
    fill: ${Colors.grey_blue_4};
    width: 24px;
    height: 24px;
  }
`;

// Card styles
type CardContainer = {
  cardWidth: string;
  hasDescription: boolean;
  cardDisabled: boolean;
  className: string;
};

export const CardContainer = styled.div<CardContainer>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: ${(props) => props.cardWidth}px;
  height: ${(props) => (props.hasDescription ? '330px' : '258px')};
  opacity: ${(props) => props.cardDisabled && '0.5'};
  pointer-events: ${(props) => props.cardDisabled && 'none'};
  background-color: ${Colors.white};
  border-radius: 8px;
  box-sizing: border-box;
  transition: all 0.2s ease-in-out;
  box-shadow: ${Elevations.elevation_1};
  position: relative;
  top: 0;
  overflow: hidden;
  background-color: ${Colors.background};
  border: 1px solid ${Colors.grey_blue_1};

  &:hover {
    cursor: pointer;
    top: -8px;
    box-shadow: ${Elevations.elevation_2};
    // to override the MUI card default hover color
    background-color: ${Colors.grey_blue_0} !important;
  }

  .footer-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px;
    height: 62px;
    background-color: ${Colors.white};
    border-top: 1px solid ${Colors.grey_blue_1};
  }

  .classic-lozenge {
    width: 145px;
    border-radius: 2px;
    padding: 2px 3px 1px 10px;
    background-color: ${Colors.accent}20;
    color: ${Colors.accent};
    ${Fonts.capsHeading};
    font-size: 12px;
    margin-top: 8px;
  }
`;

export const IconFrame = styled.div`
  margin: 24px 24px 0;
  background-color: ${Colors.accent_1};
  height: 148px;
  position: relative;
  overflow: hidden;
  box-shadow: 0px -1px 4px 0px ${Colors.grey_blue_2};
`;

export const Title = styled.div<{ disabled: boolean }>`
  margin: 0;
  overflow: hidden;
  color: ${(props) => (props.disabled ? Colors.grey_blue_4 : Colors.grey_blue)};
  ${Fonts.subheadSmall};
`;

export const Description = styled.div<{ disabled: boolean }>`
  margin: 0;
  padding-top: 16px;
  overflow: hidden;
  ${Fonts.caption};
  color: ${(props) => (props.disabled ? Colors.grey_blue_4 : Colors.grey_blue)};
  max-height: 70px;
`;

import styled from 'styled-components';
import Avatar from '@material-ui/core/Avatar';
import { Colors } from '../../../Foundation/Colors';
import { Fonts } from '../../../Foundation/Fonts';

export const StyledAvatar = styled(Avatar)`
  text-transform: uppercase;

  &.MuiAvatar-root {
    ${Fonts.subheadSmall}
    borderRadius: 4px;
    width: 40px;
    height: 40px;
  }

  &.MuiAvatar-colorDefault {
    background-color: ${Colors.accent};
    color: ${Colors.white};
  }

  &.circular-small {
    &.MuiAvatar-root {
      ${Fonts.link}
      border-radius: 50%;
      width: 32px;
      height: 32px;
    }

    &.MuiAvatar-colorDefault {
      background-color: ${Colors.background};
      color: ${Colors.accent_5};
    }
  }

  &.circular-medium {
    &.MuiAvatar-root {
      ${Fonts.subhead}
      border-radius: 50%;
      width: 56px;
      height: 56px;
    }

    &.MuiAvatar-colorDefault {
      background-color: ${Colors.background};
      color: ${Colors.accent_5};
    }
  }
`;

import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { parseDate } from '../../Utils/commonUtils';
import { Card } from '../../Molecules/Card';
import { CardListProps } from './CardList.types';
import { StyledCardList } from './CardList.styles';
import {
  getWorkflowTriggerType,
  getWorkflowCardMenuItems,
} from './CardListUtils';

export const CardList = ({
  cardType = 'workflow',
  items,
  baseUrl,
  optionsMenuItems,
  optionsMenuWhitelist,
  icon,
}: CardListProps): JSX.Element => {
  const getIcon = (definition) => {
    if (cardType === 'workflow') {
      // based on the trigger type, different icons will be used
      return getWorkflowTriggerType(definition);
    }

    return icon;
  };

  const filterCardOptions = ({ status, definition }) => {
    let whitelist = optionsMenuWhitelist[status];

    if (cardType === 'workflow') {
      whitelist = getWorkflowCardMenuItems(whitelist, definition);
    }

    const filteredOptions = optionsMenuItems.filter(({ action }) =>
      whitelist.includes(action)
    );

    return filteredOptions;
  };

  return (
    <StyledCardList>
      {items.map((item) => {
        const modified =
          item.status === 'ARCHIVED'
            ? `Archived: ${moment(parseDate(item.archivedDate)).format(
                'MMM D, YYYY h.mma'
              )}`
            : `Updated: ${moment(parseDate(item.modified)).format(
                'MMM D, YYYY h.mma'
              )}`;
        const cardLink =
          item.status === 'ARCHIVED' ? '#' : `${baseUrl}/${item.id}`;
        return (
          <div key={item.id} className='card-wrapper'>
            <Link to={cardLink}>
              <Card
                id={item.id}
                title={item.name}
                modified={modified}
                createdBy={item.creatorUserName}
                icon={getIcon(item.definition)}
                status={item.status}
                cardOptions={filterCardOptions(item)}
              />
            </Link>
          </div>
        );
      })}
    </StyledCardList>
  );
};

import React from 'react';

import styled from 'styled-components';

import { Icon } from '../../Atoms/Icon/Icon';
import { Colors } from '../../Foundation/Colors';
import { Fonts } from '../../Foundation/Fonts';

export const StyledAccordianStepper = styled.div`
  margin: 16px 16px 16px 4px;

  .MuiStepper-root {
    padding: 0;
  }

  .MuiStep-vertical {
    border-left: 1px solid ${Colors.grey_blue_1};
    &:last-child {
      border-left: none;
    }
  }

  .MuiStepButton-root {
    padding: 0;
  }

  .MuiCollapse-wrapperInner {
    > div > div {
      border: none;
    }
  }

  .MuiStepLabel-labelContainer {
    margin: 1px 0 0 8px;
  }

  .MuiStepConnector-line {
    min-height: 0;
  }

  .MuiStepLabel-root {
    display: flex;
    align-items: flex-start;
  }

  .MuiStepLabel-iconContainer {
    margin-left: -4px;
    background-color: ${Colors.white};
  }

  .title {
    ${Fonts.subheadSerif}
  }

  .buttons {
    > * {
      display: inline-block;
      margin: 10px 4px 46px 0;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  .step-content {
    border-left: none;
    padding: 20px 20px 20px 28px;
    margin: 0;
  }

  .step-content.active {
    padding: 20px 20px 72px 28px;
  }

  .step-title {
    display: block;
    margin: 0;
    padding-bottom: 12px;
    text-align: left;
    color: ${Colors['grey_blue_4']};

    .title {
      ${Fonts.subheadSmall};
    }

    .optional {
      ${Fonts.body};
      font-weight: normal;
    }
  }

  .step-title.active {
    color: ${Colors['grey_blue']};
  }

  .step-description {
    display: block;
    margin: 0 0 8px 0;
    text-align: left;
    ${Fonts.body}
  }
`;

export const StyledIcon = styled(({ isValid, isActive, ...rest }) => (
  <Icon icon={isValid && !isActive ? 'Tick' : 'Dot'} {...rest} />
))`
  fill: ${({ isActive, isValid }) =>
    isActive || isValid ? Colors['accent'] : Colors['grey_blue_3']};
`;

export const StepExtraInfo = styled.div`
  padding: 0 20px 20px;
  background: ${Colors.white};
`;

import styled from 'styled-components';
import { Colors } from '../../Foundation/Colors';
import { Fonts } from '../../Foundation/Fonts';

export const FormDropdownWrapper = styled.div<{
  paddingTop: string;
  paddingBottom: string;
}>`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  padding: ${(props) => props.paddingTop}px 20px
    ${(props) => props.paddingBottom}px;
  ${Fonts.body};

  .dropdown {
    margin: -4px 0 0;
  }

  .input-label {
    font-size: 0.75rem;
  }

  p.MuiFormHelperText-root {
    margin-top: 8px;
  }

  .MuiFormLabel-root {
    ${Fonts.body};
    color: ${Colors.grey_blue_4};
  }
  .MuiInputLabel-formControl {
    position: static;
  }
  label + .MuiInput-formControl {
    margin-top: -4px;
  }
`;

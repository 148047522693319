const isString = (entity) => typeof entity === 'string';

export const extractOriginalError = (error, defaultMessage) => {
  try {
    if (isString(error.graphQLErrors[0].errorMessage)) {
      return error.graphQLErrors[0].errorMessage;
    }

    return error.graphQLErrors[0].errorMessage.errorText;
  } catch (e) {
    return defaultMessage;
  }
};

import React from 'react';
import MUIStepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { Icon } from '../../Atoms/Icon/Icon';
import { StepperProps } from './StepsIndicator.types';
import {
  StyledStepsIndicator,
  StyledStepIcon,
  StyledStepDotIcon,
  StyledStepCustomIcon,
  StyledStepCompletedIcon,
  StyledStepConnector,
} from './StepsIndicator.styles';

const StepIcon = (props) => {
  const { active, completed, icon } = props;
  const className = active ? 'active' : '';

  return (
    <StyledStepIcon>
      {completed ? (
        <StyledStepCompletedIcon>
          <Icon icon={icon ? icon : 'Tick'} />
        </StyledStepCompletedIcon>
      ) : icon ? (
        <StyledStepCustomIcon className={className}>
          <Icon icon={icon} />
        </StyledStepCustomIcon>
      ) : (
        <StyledStepDotIcon className={className} />
      )}
    </StyledStepIcon>
  );
};

const StepConnector = (props) => {
  const { active, completed } = props;
  const className = active | completed ? 'active' : '';
  return <StyledStepConnector className={className} />;
};

export const StepsIndicator = ({ steps, activeStep }: StepperProps) => {
  const stepRenderer = (props) => {
    const { label, icon } = props;

    // steps + 1 = completed last step.
    if (activeStep < 0 || activeStep > steps.length) {
      throw new Error(
        '<Stepper>: activeStep is less than 0 or greater than number of steps'
      );
    }

    return (
      <Step key={`${label}`}>
        <StepLabel icon={icon} StepIconComponent={StepIcon}>
          {label}
        </StepLabel>
      </Step>
    );
  };

  return (
    <StyledStepsIndicator>
      <MUIStepper
        data-testid='step-indicator'
        alternativeLabel
        orientation='horizontal'
        activeStep={activeStep}
        connector={<StepConnector />}
      >
        {steps.map(stepRenderer)}
      </MUIStepper>
    </StyledStepsIndicator>
  );
};

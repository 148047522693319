import styled from 'styled-components';
import { Colors } from '../../Foundation/Colors';
import { Fonts } from '../../Foundation/Fonts';

const approxColorButtonWidth = 55;
const approxInputSidePaddingWidth = 24;

export const ColorPickerWrapper = styled.div`
  border: none;
  background-color: ${Colors.white};
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  &.color-picker-long-label {
    .color-picker-label {
      padding-right: ${approxColorButtonWidth + 10}px;
    }
  }

  .color-picker-label {
    ${Fonts.link};
    color: ${Colors.grey_blue};
    text-align: left;
  }
`;

export const Arrow = styled.div`
  width: 24px;
  min-width: 24px;
  height: 24px;
  font-size: 18px;
  cursor: pointer;
  fill: ${Colors.grey_blue};
  margin-left: -3px;
`;

export const ColorSwitch = styled.button`
  background: none;
  border: none;
  padding: 0;
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 10px;
`;

export const Color = styled.span<{
  color: string;
}>`
  display: block;
  width: 28px;
  min-width: 28px;
  height: 28px;
  background-color: ${(props) => props.color};
  border-radius: 50%;
  cursor: pointer;
  border: ${Colors.grey_blue_2} 1px solid;
`;

export const PopOver = styled.div`
  position: absolute;
  z-index: 3;
  right: ${0 - approxColorButtonWidth}px;
  top: 44px;
`;

export const StyledTextField = styled.div<{ includeAlpha: boolean }>`
  width: calc(
    ${approxInputSidePaddingWidth}px +
      ${(props) => (props.includeAlpha ? '76px' : '70px')}
  );
  min-width: calc(
    ${approxInputSidePaddingWidth}px +
      ${(props) => (props.includeAlpha ? '76px' : '70px')}
  );

  .MuiFilledInput-root {
    input.MuiFilledInput-input {
      min-width: calc(100% - ${approxInputSidePaddingWidth}px);
      ${Fonts.link};
      font-size: 14px;
      line-height: 20px;
      text-transform: uppercase;
    }
  }

  .color-picker-long-label & .MuiInputBase-root {
    margin-left: -${approxColorButtonWidth + 10}px;
  }

  .MuiFilledInput-adornedEnd {
    padding-right: 0px;
  }
`;

export const InputWrapper = styled.div`
  cursor: pointer;
`;

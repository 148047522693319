import styled from 'styled-components';

// It looks like Draftail doesn't like accepting a className, so we'll just use a div wrapper.
export const StyledDraftailEditorWrapper = styled.div`
  button[name='VARIABLE'] {
    display: none;
  }

  .Draftail-Editor {
    position: relative;
  }

  .Draftail-dropdown-adornment {
    display: none;
  }

  &.has-focus {
    .Draftail-dropdown-adornment {
      display: initial;
    }
  }
  height: 100%;
`;

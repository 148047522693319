import { format as dateFnsFormat } from 'date-fns';

export const isDateValid = (date) => date instanceof Date && !isNaN(date);

export const dateToISOString = (date) =>
  date && date.toISOString && isDateValid(date) ? date.toISOString() : null;

export const formatDate = (date, format = 'dd/MM/yyyy') => {
  let formattedDate;
  const parsedDate = new Date(date);
  if (isDateValid(parsedDate)) {
    formattedDate = dateFnsFormat(parsedDate, format);
  } else {
    formattedDate = 'Invalid date';
  }

  return formattedDate;
};

export const SIDE_DRAWER_WIDTH = '416px';

export const GLOBAL_NAV_DEPTH = '1001';
export const GLOBAL_NAV_WIDTH = '80px';
// NEW_GLOBAL_NAV_WIDTH is the width used for the navigation bar with the submenu
// We will end up with one variable after the development of the components are complete
export const NEW_GLOBAL_NAV_WIDTH = '64px';
export const TRANSITION_TIME = '.2s ease';
export const HEADER_HEIGHT = '80px';
export const TEMPORARY_INFO_BANNER_HEIGHT = '56px';

/* eslint-disable no-unused-expressions */

import React from 'react';
import { TagsWrapper, Tag } from './SmartTagPicker.style';

type Props = {
  smartTagListObj: Array<unknown>;
  active: boolean;
  onClick: (...args: Array<unknown>) => void;
  onKeyUp: (...args: Array<unknown>) => void;
};

const handleClick = (onClick) => (event) => {
  const { target } = event;
  const tagTitle = target.querySelector('.tag-name').innerHTML;
  onClick(tagTitle);
};

const handleKeyPressForNagivation = (onKeyUp) => (event) => {
  const { nextElementSibling, previousElementSibling } = document.activeElement;
  const tagName = document.activeElement.querySelector('.tag-name');

  event.which === 40 && nextElementSibling && nextElementSibling.focus();
  event.which === 38 &&
    previousElementSibling &&
    previousElementSibling.focus();
  event.which === 13 && onKeyUp(tagName.innerHTML);
};

export const SmartTagPicker = ({
  smartTagListObj,
  active,
  onClick,
  onKeyUp,
}: Props) => {
  if (!active) return null;
  return (
    <TagsWrapper>
      {smartTagListObj.map((tag, idx) => (
        <Tag
          className='smart-tag'
          key={tag.tagName}
          onClick={handleClick(onClick)}
          onKeyUp={handleKeyPressForNagivation(onKeyUp)}
          tabIndex={idx}
        >
          <div
            dangerouslySetInnerHTML={{ __html: tag.tagName }}
            className='tag-name'
          />
          <div className='tag-description'>{tag.tagDescription}</div>
        </Tag>
      ))}
    </TagsWrapper>
  );
};

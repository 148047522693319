import React from 'react';
import { initFeatureToggles, isToggleEnabled } from '@whispir/feature-toggles';

type IsToggleEnabledFunction = (toggleName: string) => boolean;

type FeatureToggleContext = {
  isToggleEnabled: IsToggleEnabledFunction;
};

type FeatureToggleProviderProps = {
  isToggleEnabled?: IsToggleEnabledFunction;
};

const FeatureToggleContext = React.createContext<FeatureToggleContext>({
  // By default we will just say the feature toggles are false.
  isToggleEnabled: () => false,
});

export const createIsToggleEnabled = (env: string): IsToggleEnabledFunction => {
  initFeatureToggles(env);
  return isToggleEnabled;
};

export const FeatureToggleProvider = (
  props: React.PropsWithChildren<FeatureToggleProviderProps>
) => {
  const { isToggleEnabled = () => false, children } = props;

  return (
    <FeatureToggleContext.Provider value={{ isToggleEnabled }}>
      {children}
    </FeatureToggleContext.Provider>
  );
};

export const useFeatureToggle = (): FeatureToggleContext => {
  return React.useContext(FeatureToggleContext);
};

export function withFeatureToggle(SourceComponent) {
  const WrapperComponent = (props) => (
    <FeatureToggleContext.Consumer>
      {(context) => <SourceComponent {...props} isToggleEnabled={context} />}
    </FeatureToggleContext.Consumer>
  );

  return WrapperComponent;
}

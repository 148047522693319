import React from 'react';
import { SearchField } from '../../Atoms/Forms';
import { FilterPicker } from '../../Molecules/FilterPicker';

import { StyledWrapper } from './QueryFilterToolbar.styles';
import { FilterToolbarProps } from './QueryFilterToolbar.types';

export const FilterToolbar = ({
  availableFilters,
  onChangeFilters,
  formFilters,
  searchFilter,
  searchLabel = '',
  searchDefaultValue = '',
  searchOnColumnKey = '',
  onChangeSearch,
  showClearButtonOnOutFocus = false,
}: FilterToolbarProps): JSX.Element => {
  const handleSearch = (value) => {
    onChangeSearch({
      label: searchLabel,
      dataType: 'FormTextInput',
      columnKey: searchOnColumnKey,
      condition: {
        operator: 'contains',
        operand: {
          value,
          valueType: 'string',
        },
      },
    });
  };

  return (
    <StyledWrapper>
      <div className='filter-toolbar-actions'>
        <FilterPicker
          availableFilters={availableFilters}
          selectedFilters={formFilters}
          onChange={onChangeFilters}
          // NOTE: Set 10 as limit for now
          maxFilters={10}
        />
        <SearchField
          placeholder={`Search ${searchLabel}`}
          onChange={handleSearch}
          defaultValue={
            searchDefaultValue || searchFilter?.condition?.operand?.value
          }
          hasIcon
          showClearButtonOnOutFocus={showClearButtonOnOutFocus}
        />
      </div>
    </StyledWrapper>
  );
};

import styled, { css } from 'styled-components';
import { Colors } from '../../Foundation/Colors';
import { Fonts } from '../../Foundation/Fonts';
import { HorizontalSelectorProps } from './HorizontalSelector';

export const StyledContainer = styled.div<
  Pick<HorizontalSelectorProps, 'fullWidth' | 'disabled'>
>`
  display: flex;
  justify-content: ${(props) => (props.fullWidth ? 'space-between' : 'center')};
  background-color: ${Colors.background};
  border-radius: 20px;
  width: ${(props) => (props.fullWidth ? 'auto' : 'fit-content')};
  padding: 4px 6px;

  input[type='radio'] {
    cursor: pointer;
    opacity: 0;
    position: fixed;
    width: 0;

    :checked + label {
      background-color: ${Colors.variable_light};
      color: ${Colors.variable};
    }

    ${(props) =>
      props.disabled &&
      css`
        cursor: default;
      `}
  }

  label {
    cursor: pointer;
    margin-right: 4px;
    padding: 2px 12px;
    border-radius: 20px;
    ${Fonts.body};
    color: ${Colors.grey_blue};

    :last-child {
      margin-right: 0;
    }

    :hover {
      color: ${Colors.variable};
    }

    ${(props) =>
      props.disabled &&
      css`
        cursor: default;

        :hover {
          color: inherit;
        }
      `}
  }
`;

import styled from 'styled-components';
import { Colors } from '../../Foundation/Colors';
import { Fonts } from '../../Foundation/Fonts';

export const ToneDetectorScoreWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

export const ScoreTextWrapper = styled.div`
  ${Fonts.caption}
  color: ${Colors.grey_blue_4};
  width: 227px;

  .ScoreText {
    display: inline-block;
    padding: 4px 0px;
  }
`;

export const ScoreTitleWrapper = styled.div`
  ${Fonts.capsHeading}
  display: flex;
  align-items: center;

  svg {
    height: 16px;
    width: 16px;
    fill: ${Colors.grey_blue_4};
    position: relative;
    bottom: 1px;
  }

  span {
    padding-left: 8px;
  }
`;

export const NumberCircleWrapper = styled.div`
  flex-grow: 0;
  padding-left: 30px;
`;

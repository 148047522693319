import React, { ReactElement } from 'react';

import {
  Accordion as MaterialAccordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { MenuItem } from '../MenuItem/MenuItem';
import { Icons } from '../../../Foundation/Icons';
import { AccordionWrapper } from './Accordion.style';
import { AccordionProps } from './Accordion.types';

export const Accordion = ({
  groupName,
  groupItems,
  isExpanded,
  onChange,
}: AccordionProps): ReactElement => {
  const renderGroupItems = () => {
    return groupItems.map((groupItem) => {
      if ('title' in groupItem) {
        const { pathname } = groupItem;
        return (
          <div key={pathname} className='menuitem'>
            <MenuItem {...groupItem} />
          </div>
        );
      }

      return groupItem;
    });
  };

  return (
    <AccordionWrapper>
      <MaterialAccordion defaultExpanded={isExpanded} onChange={onChange}>
        <AccordionSummary expandIcon={<Icons.Accordian />}>
          <Typography>{groupName}</Typography>
        </AccordionSummary>
        <AccordionDetails>{renderGroupItems()}</AccordionDetails>
      </MaterialAccordion>
    </AccordionWrapper>
  );
};

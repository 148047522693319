import React from 'react';
import { Button } from '../../Atoms/Navigations';
import { PrebuiltTemplateCardButtonProps } from './PrebuiltTemplateCardButton.types';
import { StyledPrebuiltTemplateCardButton } from './PrebuiltTemplateCardButton.styles';

export const PrebuiltTemplateCardButton = (
  props: PrebuiltTemplateCardButtonProps
): JSX.Element => {
  const { Image, title, text, onClick, buttonText } = props;
  return (
    <StyledPrebuiltTemplateCardButton onClick={onClick}>
      <Image className='svg' />
      <div className='title'>{title}</div>
      <div className='text'>{text}</div>
      <Button
        text={buttonText}
        component='span'
        variant='link'
        size='medium'
        type='primary'
      />
    </StyledPrebuiltTemplateCardButton>
  );
};

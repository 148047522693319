import React from 'react';

import { Checkbox as MuiCheckbox } from '@material-ui/core';
import { CheckboxTypes } from './Checkbox.types';
import { StyledFormControlLabel } from './Checkbox.style';

export const Checkbox = ({
  isChecked,
  label = '',
  isIndeterminated,
  isDisabled,
  value = 'on',
  onChange = () => undefined,
  labelPlacement = 'end',
  size = 'small',
  ariaLabel,
}: CheckboxTypes): JSX.Element => {
  return (
    <StyledFormControlLabel
      control={
        <MuiCheckbox
          inputProps={{
            'aria-label': ariaLabel,
          }}
          checked={isChecked}
          indeterminate={isIndeterminated}
          disabled={isDisabled}
          value={value}
          color='primary'
          size={size}
          onChange={(event) => onChange(event, !isChecked)}
        />
      }
      classes={{
        root: 'checkbox-control-form',
      }}
      labelPlacement={labelPlacement}
      label={label}
    />
  );
};

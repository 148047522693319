import React from 'react';

import { Button } from '../../Atoms/Navigations';
import { SimpleTableMultiSelectProps } from './SimpleTableMultiSelect.types';
import { SimpleTableMultiSelectWrapper } from './SimpleTableMultiSelect.styles';

export const SimpleTableMultiSelect = ({
  selectedCount,
  handleClick,
}: SimpleTableMultiSelectProps) => {
  return (
    <SimpleTableMultiSelectWrapper>
      <span>{selectedCount} Messages Selected:</span>
      <Button
        type='secondary'
        variant='link'
        size='large'
        text='Delete All'
        startIcon='Delete'
        onClick={handleClick}
      />
    </SimpleTableMultiSelectWrapper>
  );
};

import { IconTypes } from '../../Atoms/Icon';

import { VariableGroupType } from './VariableSelectorPopover.types';

export const groupIconMap: Record<VariableGroupType, IconTypes> = {
  formVariables: 'WebformTrigger',
  smsMessageVariables: 'SmsOutline',
  systemVariables: 'SalesForceTrigger',
  emailMessageVariables: 'EmailOutline',
  onReplyVariables: 'ReplyOutline',
  default: 'SalesForceTrigger',
};

import styled from 'styled-components';
import { rolloverTransition } from '../../Foundation/Animations';
import { Colors } from '../../Foundation/Colors';
import { Fonts } from '../../Foundation/Fonts';
import { HomepageBannerProps } from './HomepageBanner';

export const HomepageBannerWrapper = styled.div<
  Pick<
    HomepageBannerProps,
    'backgroundColor' | 'bannerHeight' | 'textColor' | 'hasButton'
  >
>`
  box-sizing: border-box;
  background-color: ${(props) => props.backgroundColor};
  padding-left: 30px;
  display: flex;
  width: 100%;
  height: ${(props) => props.bannerHeight};

  .content-wrapper {
    margin: 40px 0 50px;

    h1 {
      ${Fonts.headlineLarge};
      color: ${(props) => props.textColor};
      margin: 0;
    }

    p {
      ${Fonts.bodyLarge}
      color: ${(props) => props.textColor};
      width: 370px;
      margin: 19px 0 24px;
    }

    .btn-router-link {
      display: ${(props) => !props.hasButton && 'none'};
      margin: 40px 0;

      a {
        border-radius: 22px;
        background-color: ${Colors.accent};
        padding: 12px 40px;
        text-align: center;
        text-decoration: none;
        color: ${Colors.white};
        ${rolloverTransition('background-color')};
      }
    }
  }
`;

export const BannerImage = styled.div<Pick<HomepageBannerProps, 'imageHeight'>>`
  z-index: 1;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: ${(props) => props.imageHeight};
  overflow: hidden;
  /* Needed for overflow effect of image */
  margin-bottom: -33px;

  svg {
    overflow: hidden;
    max-height: 100%;
    width: 800px;
    position: absolute;
    top: 0;
    right: 0;
  }
`;

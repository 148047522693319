import styled from 'styled-components';
import { Colors } from '../../Foundation/Colors';
import { Fonts } from '../../Foundation/Fonts';

export const OptionsContainer = styled.div`
  margin-top: -6px;
  > div:first-child {
    min-width: 330px;
  }
`;

export const AddAnotherBtn = styled.button`
  display: flex;
  padding: 0 0 8px 2px;
  border: none;
  align-items: center;
  background-color: transparent;
  cursor: pointer;
  background: transparent;
  align-items: flex-end;
  svg {
    padding-right: 4px;
    fill: ${Colors.accent};
  }
  p {
    ${Fonts.bodySmall};
    margin: 8px 0 0;
    color: ${Colors.accent};
  }
`;

export const ComponentBox = styled.div<{
  filledTextAreaStyle?: boolean;
  isExtension?: boolean;
}>`
  display: flex;
  align-items: center;
  margin: 0;
  height: ${({ filledTextAreaStyle, isExtension }) =>
    isExtension ? 'auto' : filledTextAreaStyle ? '120px' : '95px'};
  padding: 0 0 16px;
  text-align: center;
  background: ${Colors.white};
  position: relative;

  button {
    right: 5px;
  }
`;

export const DragHandleWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 16px 0 0;
  fill: ${Colors.grey_blue_3};
`;

export const ComponentBoxProperties = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;

  .text-field-form-control {
    label {
      text-align: left;
    }
  }

  > div:first-child.text-field-form-control {
    margin: 20px 0 0;
    padding: 0;
    width: 100%;
  }
`;

export const DefaultOptionsWrapper = styled.div<{
  disableDeleteButton?: boolean;
  isExtension?: boolean;
}>`
  align-items: center;
  justify-content: ${(props) =>
    props.isExtension ? 'flex-end' : 'space-between'};
  display: flex;

  > label.checkbox-control-form {
    width: auto;
    margin-left: -8px;
    text-align: left;
  }

  > div.label.span:last-child {
    padding: 24px 0;
  }

  .options-picker__delete-btn {
    fill: ${(props) =>
      props.disableDeleteButton ? Colors.grey_blue_2 : Colors.grey_blue_3};
    pointer-events: ${(props) => props.disableDeleteButton && 'none'};

    :hover {
      cursor: pointer;
      fill: ${Colors.accent};
    }
  }
`;

import styled from 'styled-components';
import { Colors } from '../../Foundation/Colors';
import { Fonts } from '../../Foundation/Fonts';

import {
  ComponentPropertyContainerProps,
  SubjectWrapperProps,
} from './DroppableItemProperties.types';

export const ComponentPropertyContainer = styled.div<ComponentPropertyContainerProps>`
  &.component-property-panel {
    min-height: 160px;
  }
  pointer-events: ${(props) => props.disable && 'none'};
  opacity: ${(props) => props.disable && '0.5'};
  z-index: 1;
  background: ${Colors.white};
  ${Fonts.link};
  color: ${Colors.disabled_bg};

  .properties-scroll-wrapper {
    height: 100%;
    overflow-x: hidden;
    padding-bottom: 16px;
    padding-left: 24px;
    padding-right: 24px;

    .MuiTypography-root {
      padding: 0;
      ${Fonts.propertyLabel};
      color: ${Colors.grey_blue};
    }
    .checkbox-control-form .MuiTypography-root {
      ${Fonts.bodySmall};
    }
  }

  .close-button {
    position: absolute;
    top: -14px;
    right: 16px;
    svg {
      fill: ${Colors.grey_blue_4};
    }
  }

  .component-property__title {
    text-transform: capitalize;
    ${Fonts.subheadSerif};
    margin: 40px 0 0 24px;

    &.general {
      margin: 20px 0 16px 24px;
    }
  }

  label {
    width: 100%;
  }

  .checked-checkbox {
    color: ${Colors.accent} !important;
  }

  .color-picker-form-control {
    padding-left: 0;
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0;
    position: relative;
  }

  .color-picker-label {
    ${Fonts.propertyLabel};
  }
  .placement-label {
    ${Fonts.propertyLabel};
  }
  .divider-title {
    ${Fonts.dividerTitle}
  }

  .error-message {
    color: ${Colors.error};
    ${Fonts.error};
  }

  .text-field-form-control {
    display: block;
    padding: 2px 0 0;

    div {
      width: 100%;

      input {
        ${Fonts.body};
      }
    }

    label {
      padding-left: 0;
      ${Fonts.caption};
      color: ${Colors.grey_blue_4};
    }
  }

  .text-field-wrapper {
    padding: 8px 0;
  }

  .divider-title {
    padding-top: 8px;
  }

  // this is to disable the 'full-width' toggle switch property in the Banner component when it is
  // used in the email channel - as email clients do not support fullwidth elements
  // the 'DisableFullWidthProperty' form controller is used in the Banner model to target
  // and disable the element according to the activeChannel
  .disable-full-width-property + .toggle-switch-wrapper {
    ${(props) => props.activeChannel === 'email' && 'display: none;'}
  }
`;

export const SubjectWrapper = styled.div<SubjectWrapperProps>`
  margin: 0 24px 8px 24px;
  border-bottom: ${(props) =>
    props.originalStyling ? `solid 1px ${Colors.grey_blue_1}` : ''};
  padding: ${(props) => (props.originalStyling ? '20px 20px 10px 20px' : '')};

  .subject-field {
    width: 100%;
    margin-top: -4px;
    > div > input {
      &::placeholder {
        font-style: italic;
      }
    }
  }
`;

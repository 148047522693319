import React from 'react';

import styled from 'styled-components';
import { Colors } from '../../Foundation/Colors';

export const DownArrow = styled.div`
  width: 0;
  height: 0;
  border: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid ${Colors.black};
  margin-right: 10px;

  transition-duration: 0.8s;
  transition-property: transform;
`;

export const UpArrow = styled(DownArrow)`
  transform: rotate(180deg);
`;

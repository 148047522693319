import React, { useState } from 'react';
import { ChromePicker, ColorResult } from 'react-color';
import { FormHelperText } from '@material-ui/core';
import useHandleClickOutsideOfElement from '../../hooks/useHandleClickOutsideOfElement';
import { TextField } from '../Forms';
import { Icon } from '../Icon';
import { Colors } from '../../Foundation/Colors';
import {
  ColorPickerWrapper,
  ColorSwitch,
  PopOver,
  Arrow,
  Color,
  StyledTextField,
  InputWrapper,
} from './ColorPicker.style';

export type ColorPickerProps = {
  onChange: (value: string) => void;
  label?: string;
  defaultValue?: string;
  labelSize?: 'large' | 'small';
  includeAlpha?: boolean;
};

const getHexWithAlpha = (color: ColorResult) => {
  const alphaHex = Math.round(Number(color.rgb.a) * 255).toString(16);
  const twoDigitsAlphaHex = alphaHex.padStart(2, '0');
  return `${color.hex}${twoDigitsAlphaHex}`;
};

export const ColorPicker = ({
  onChange,
  label = '',
  defaultValue = Colors.grey_blue,
  labelSize = 'large',
  includeAlpha = false,
}: ColorPickerProps) => {
  const [value, setValue] = useState<string>(defaultValue);
  const [active, setActive] = useState<boolean>(false);
  const [fullColorValue, setFullColorValue] = useState<
    ColorResult | undefined
  >();
  const { containerRef } = useHandleClickOutsideOfElement(() => {
    setActive(false);
  });

  const handleChangeComplete = (color: ColorResult): void => {
    const colorToUse = includeAlpha ? getHexWithAlpha(color) : color.hex;
    setFullColorValue(color);
    setValue(colorToUse);
    onChange(colorToUse);
  };

  const handleClick = () => {
    setActive(true);
  };

  const handleColorValue = (value) => {
    const newValue = `#${value}`;
    setValue(newValue);
    onChange(newValue);
  };

  const shortLabel = labelSize === 'small';
  const [_, textValue = ''] = value.match(/^#(.*)/) || [];

  return (
    <ColorPickerWrapper
      className={`color-picker-form-control${
        !shortLabel ? ' color-picker-long-label' : ''
      }`}
    >
      {!shortLabel && (
        <FormHelperText className='color-picker-label' component='label'>
          {label}
        </FormHelperText>
      )}
      <StyledTextField includeAlpha={includeAlpha}>
        <TextField
          variant='filled'
          value={textValue}
          label={shortLabel ? label : ''}
          onChange={handleColorValue}
          InputProps={{
            endAdornment: (
              <InputWrapper>
                <ColorSwitch
                  role='button'
                  aria-label={`${label} color picker`}
                  onClick={handleClick}
                >
                  <Color color={value} />
                  <Arrow>
                    {active ? (
                      <Icon icon='DropupArrow' />
                    ) : (
                      <Icon icon='DropdownArrow' />
                    )}
                  </Arrow>
                </ColorSwitch>
                {active && (
                  <PopOver ref={containerRef}>
                    <ChromePicker
                      color={fullColorValue ? fullColorValue.rgb : value}
                      onChangeComplete={handleChangeComplete}
                      disableAlpha={!includeAlpha}
                    />
                  </PopOver>
                )}
              </InputWrapper>
            ),
          }}
        />
      </StyledTextField>
    </ColorPickerWrapper>
  );
};

/* eslint-disable @typescript-eslint/camelcase */

import { ElevationsTypes } from './Elevations.types';

const shadow = 'rgba(0,0,0,.08)';
const shadowDark = 'rgba(0,0,0,.14)';

export const Elevations: ElevationsTypes = {
  elevation_1: `0 2px 8px 0 ${shadow}`,
  elevation_2: `0 2px 4px -1px ${shadow},0 4px 5px 0 ${shadowDark},0 1px 10px 0 ${shadowDark}`,
  elevation_3: `0 5px 0 -3px ${shadow},0 8px 10px 1px ${shadowDark},0 5px 14px 2px ${shadowDark}`,
  elevation_4: `0 11px 15px -7px ${shadow},0 24px 38px 3px ${shadowDark},0 9px 46px 8px ${shadowDark}`,
};

export const Depth = {
  dropDown: 1500,
  header: 1000,
  drawer: 1001,
};

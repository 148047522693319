import React, { useCallback, useState, useEffect } from 'react';

import { FormControl, TextField } from '@material-ui/core';
import {
  StyledDurationSelector,
  StyledErrorMessage,
} from './DurationSelector.styles';
import { DurationSelectorProps } from './DurationSelector.types';

export const DurationSelector = (props: DurationSelectorProps) => {
  const {
    selectedDuration,
    onChange,
    readOnly = false,
    errors,
    description,
    defaultDuration = {
      days: 1,
      hours: 0,
      minutes: 0,
    },
  } = props;

  const [thisDuration, setThisDuration] = useState(
    selectedDuration || defaultDuration
  );
  const { days, hours, minutes } = thisDuration;

  // Note about the statefulness of this compoment
  // This component is intended to be a controlled component (ie. parent sets the value)
  // However, if the user leaves a field blank, then the value should be set as 0
  // So this logic is done in this component, and the onChange callback is fired on blur and change.
  // Possible problem - what about handling unmount, and blur not firing?

  const handleChange = useCallback(
    (event) => {
      const number = parseInt(event.currentTarget.value, 10);
      const newValue = {
        ...thisDuration,
        [event.currentTarget.name]: number,
      };

      setThisDuration(newValue);
      onChange(newValue);
    },
    [onChange, thisDuration]
  );

  useEffect(() => {
    setThisDuration(selectedDuration);
  }, [selectedDuration]);

  const handleBlur = useCallback(() => {
    const { days, hours, minutes } = thisDuration;
    const valueToSend = {
      days: isNaN(days) ? 0 : days,
      hours: isNaN(hours) ? 0 : hours,
      minutes: isNaN(minutes) ? 0 : minutes,
    };
    onChange(valueToSend);
  }, [thisDuration, onChange]);

  return (
    <StyledDurationSelector>
      <p className='description'>{description}</p>
      <form autoComplete='off' noValidate className='form'>
        <FormControl className='form-control'>
          <TextField
            inputProps={{
              min: '0',
            }}
            label='Days'
            name='days'
            value={isNaN(days) ? '' : days}
            type='number'
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={readOnly}
          />
        </FormControl>
        <FormControl className='form-control'>
          <TextField
            inputProps={{
              min: '0',
            }}
            label='Hours'
            name='hours'
            value={isNaN(hours) ? '' : hours}
            type='number'
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={readOnly}
          />
        </FormControl>
        <FormControl className='form-control'>
          <TextField
            inputProps={{
              min: '0',
            }}
            label='Minutes'
            name='minutes'
            value={isNaN(minutes) ? '' : minutes}
            type='number'
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={readOnly}
          />
        </FormControl>
        {errors && (
          <div className='error-message'>
            {errors.map((error) => (
              <StyledErrorMessage key={error}>{error}</StyledErrorMessage>
            ))}
          </div>
        )}
      </form>
    </StyledDurationSelector>
  );
};

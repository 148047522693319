import { isToggleEnabled } from '@whispir/feature-toggles';

/**
 * Check if feature is visible to the user
 * @name isFeatureShown
 * @param {*} name => name of the feature to show
 * @param {*} config => any value to check if we want to show the Nav item
 */
export const isFeatureShown = (name, config) => {
  let isShown = false;

  switch (name) {
    case 'api-keys':
      const hiddenOnProductTiers = ['DigitalDirect', 'Marketplace'];
      const isTrial = config && config.includes('Trial');

      if (!hiddenOnProductTiers.includes(config) && !isTrial) {
        isShown = true;
      }
      isShown = isToggleEnabled('api-keys') ? isShown : false;
      break;
    default:
      break;
  }

  return isShown;
};

export const getUserAgent = () => (navigator ? navigator.userAgent : '');
/**
 * @name setSubnavGroupItemsCount
 * @param groupItems => group items in the subnav menu
 * @param groupItemsCounts => appends `count` prop for each sub nav menu group items
 *    - @format : {
 *        pathname = pathname of each item in the groupitems,
 *        count = count value for the sub menu item
 *      }
 */
export const setSubnavGroupItemsCount = (groupItems, groupItemsCounts) => {
  for (const item of groupItems) {
    const { count = null } =
      groupItemsCounts.find(({ pathname }) => pathname === item.pathname) || {};
    if (count !== null) {
      item.count = count;
    }
  }
};

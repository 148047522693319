import React, { useEffect, useState } from 'react';
import { QueryDataTable } from '../QueryDataTable/QueryDataTable';
import { RowData } from '../QueryDataTable/QueryDataTable.types';
import { FilterToolbar } from '../QueryFilterToolbar';
import { AvailableFilters, SelectedFilter } from '../../Molecules/FilterPicker';
import { QueryFilterTableWrapper } from './QueryFilterTable.styles';
import {
  FilterTableProps,
  FilterTableTypedColumn,
  FilterTableQueryParams,
} from './QueryFilterTable.types';

const getAvailableFilter = <T extends RowData>(
  columns: Array<FilterTableTypedColumn<T>>
): Array<AvailableFilters> =>
  columns.map(({ field, headerName, dataType = 'Text' }) => ({
    label: headerName,
    columnKey: field,
    dataType,
  }));

export const QueryFilterTable = <T extends RowData>({
  rows,
  columns,
  searchLabel,
  searchDefaultValue,
  searchOnColumnKey,
  isLoading,
  availableFilters,
  onTableViewChange = () => undefined,
  onSelectionChange = () => undefined,
  fullSize,
  initialTableView,
  rowsPerPageOptions,
  ...dataTableProps
}: FilterTableProps<T>): JSX.Element => {
  const defaultTableView = {
    page: 0,
    rowsPerPage: rowsPerPageOptions ? rowsPerPageOptions[0] : 5,
  };

  const getInitFormFilters = (initialTableView) => {
    if (initialTableView) {
      const { filters = [] } = initialTableView;
      const formFilters = filters.filter((f) => f.columnKey !== 'name');
      return formFilters;
    }
  };

  const getInitSearchFilter = (initialTableView) => {
    if (initialTableView) {
      const { filters = [] } = initialTableView;
      const searchFilter = filters.filter((f) => f.columnKey === 'name');
      if (searchFilter.length) return searchFilter[0];
    }
  };

  const [dataTableQuery, setDataTableQuery] = useState<FilterTableQueryParams>(
    initialTableView || defaultTableView
  );
  const [formFilters, setFormFilters] = useState<Array<SelectedFilter>>(
    getInitFormFilters(initialTableView) || []
  );

  const [searchFilter, setSearchFilter] = useState<SelectedFilter>(
    getInitSearchFilter(initialTableView)
  );

  useEffect(() => {
    const newFormFilters = [...formFilters];
    if (searchFilter) newFormFilters.push({ ...searchFilter });
    const newDataTableQuery = {
      ...dataTableQuery,
      page: 0,
      filters: newFormFilters,
    };

    setDataTableQuery(newDataTableQuery as FilterTableQueryParams);
  }, [formFilters, searchFilter]);

  useEffect(() => {
    if (dataTableQuery) onTableViewChange(dataTableQuery);
  }, [dataTableQuery]);

  const onDataTableViewChange = (props: FilterTableQueryParams) => {
    const newDataTableQuery = dataTableQuery
      ? { ...dataTableQuery, ...props }
      : { ...props };
    setDataTableQuery(newDataTableQuery);
  };

  const { page, rowsPerPage, columnToSort, sortAscending } = dataTableQuery;

  return (
    <QueryFilterTableWrapper
      className={`query-filter-table-wrapper ${fullSize ? 'full-size' : ''}`}
    >
      <FilterToolbar
        {...dataTableProps}
        searchOnColumnKey={searchOnColumnKey}
        searchLabel={searchLabel}
        searchDefaultValue={searchDefaultValue}
        onChangeFilters={setFormFilters}
        onChangeSearch={setSearchFilter}
        availableFilters={availableFilters || getAvailableFilter(columns)}
        formFilters={formFilters}
        searchFilter={searchFilter}
      />
      <QueryDataTable
        {...dataTableProps}
        rows={rows}
        columns={columns}
        isLoading={isLoading}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={rowsPerPageOptions}
        onTableViewChange={onDataTableViewChange}
        onSelectionChange={onSelectionChange}
        defaultSortAscending={sortAscending}
        defaultSortedColumn={columnToSort}
      />
    </QueryFilterTableWrapper>
  );
};

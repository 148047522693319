import styled from 'styled-components';
import { Colors } from '../../Foundation/Colors';
import { Fonts } from '../../Foundation/Fonts';

export const StyledStepsIndicator = styled.div`
  .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
    ${Fonts.caption};
    margin-top: 8px;
  }
`;

export const StyledStepIcon = styled.div`
  display: flex;
  align-items: center;
  height: 16px;
`;

export const StyledStepDotIcon = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: ${Colors.grey_blue_2};

  &.active {
    background: ${Colors.accent};
  }
`;

export const StyledStepCustomIcon = styled.div`
  svg {
    fill: ${Colors.grey_blue_2};
  }

  &.active {
    svg {
      fill: ${Colors.accent};
    }
  }
`;

export const StyledStepCompletedIcon = styled.div`
  svg {
    fill: ${Colors.accent};
  }
`;

export const StyledStepConnector = styled.div`
  background: ${Colors.grey_blue_1};
  height: 3px;
  position: absolute;
  border-radius: 2px;
  left: calc(-50% + 16px);
  right: calc(50% + 16px);
  top: calc(50% + -13px);

  &.active {
    background: ${Colors.accent};
  }
`;

import { UIVariable, UIVariableGroup } from '@whispir/variables';
import {
  PossibleReturnValues,
  AdhocVariableSelectorProps,
  OurReactSelectGroupLabel,
  AdhocValidatorOptions,
  ReactSelectValue,
} from './AdhocVariableSelector.types';

/** Note on how we're doing the adhoc validation
 *
 * From what I can see - react-select provides the ability to validate adhocs, but if they're not valid it won't display the `Create...` text,
 * Which is what we want it to do.
 *
 * To get around this, we validate the when creating the label, and display the validation status when the item selected.
 * And on actually creating the adhocs, we filter them out if they are not valid.
 */

export const convertSelectedOptions = (
  selectedOptions: PossibleReturnValues
) => {
  return selectedOptions.map((v) => {
    if ('variableName' in v) {
      if ('isError' in v) {
        return {
          __isError__: true,
          label: v.variableName,
          // JSON Stringify the data to ensure unique value, which is used by
          // React-Select library to only display unselected variables
          value: JSON.stringify(v),
          data: v,
        };
      }
      return {
        label: v.variableName,
        // JSON Stringify the data to ensure unique value, which is used by
        // React-Select library to only display unselected variables
        value: JSON.stringify(v),
        data: v,
      };
    }
    return {
      label: v.value,
      value: v.value,
      __isNew__: true,
    };
  });
};

export const convertAvailableOptions = (
  availableOptions: Array<UIVariableGroup>
): Array<OurReactSelectGroupLabel> => {
  return availableOptions.map((v) => {
    return {
      groupLabel: {
        name: v.groupName,
        type: v.groupType,
      },
      options: v.variables.map((w) => ({
        label: w.variableName,
        // JSON Stringify the data to ensure unique value, which is used by
        // React-Select library to only display unselected variables
        value: JSON.stringify(w),
        data: w,
      })),
    };
  });
};

export const initialiseSelectedOptions = (
  initialSelectedVariables: PossibleReturnValues,
  availableVariables: Array<UIVariableGroup>
) => {
  // exit early if there is no initial selected variables
  if (!initialSelectedVariables.length) {
    return [];
  }

  const flattenedAvailableVariables = availableVariables.reduce((acc, cur) => {
    return [...acc, ...cur.variables];
  }, [] as Array<UIVariable>);

  const copiedSelectedVariables = [...initialSelectedVariables];

  const deletedVariables = copiedSelectedVariables.reduce((acc, cur) => {
    // only checks if currently selected values are type of variables and not 'adhoc'
    if ('variableName' in cur) {
      const doesVariableExist = flattenedAvailableVariables.find(
        (availableVar) => availableVar.variableId === cur.variableId
      );
      // return the variable that does not exist in 'availableVariables'
      if (!doesVariableExist) {
        return [...acc, cur];
      }
    }
    return [...acc];
  }, [] as AdhocVariableSelectorProps['selectedOptions']);

  if (deletedVariables) {
    deletedVariables.forEach((v) => {
      const index = copiedSelectedVariables.indexOf(v);
      // once we found all of the invalid variables, mark them with 'isError'
      copiedSelectedVariables.splice(index, 1, { ...v, isError: true });
    });
  }

  return copiedSelectedVariables;
};

export const convertValuesForHandleChange = (
  values: Array<ReactSelectValue>,
  adhocValidator: (
    value: string,
    adhocValidatorOptions?: AdhocValidatorOptions
  ) => { valid: boolean; type: string },
  adhocValidatorOptions?: AdhocValidatorOptions
): PossibleReturnValues =>
  values.reduce((acc, cur) => {
    if ('__isNew__' in cur) {
      const { valid, type } = adhocValidator(cur.value, adhocValidatorOptions);

      if (valid) {
        return [
          ...acc,
          {
            value: cur.value,
            isAdhoc: true,
            type,
          },
        ];
      }
      return acc;
    }
    return [...acc, cur.data];
  }, [] as PossibleReturnValues);

import React, { useCallback, useEffect, useState } from 'react';

import {
  UIVariable,
  UIVariableGroup,
  UIVariableGroupType,
} from '@whispir/variables';
import { SearchField } from '../../Atoms/Forms/SearchField';

import { Icon } from '../../Atoms/Icon';
import { VariableSelectorPopoverTypes } from './VariableSelectorPopover.types';

import {
  StyledVariableGroup,
  StyledPopoverMenu,
  StyledGroupTitle,
  StyledButton,
  StyledVariableChip,
} from './VariableSelectorPopover.styles';
import { groupIconMap } from './groupIconMap';

export const VariableSelectorGroupTitle = (props: {
  groupName: string;
  groupType: UIVariableGroupType;
  groupCount: number;
}): JSX.Element => {
  const { groupName, groupType, groupCount } = props;

  const iconToUse = groupIconMap[groupType] || groupIconMap['default'];

  return (
    <StyledGroupTitle>
      <div className='group-icon'>
        <Icon icon={iconToUse} />
      </div>
      <div className='group-title'>{groupName}</div>
      <div className='group-counter--wrapper'>
        <span className='counter-badge'>{groupCount}</span>
      </div>
    </StyledGroupTitle>
  );
};

export const VariableSelectorGroupItem = (props: {
  onClick: () => void;
  label: string;
}): JSX.Element => {
  const { onClick, label } = props;
  return (
    <StyledButton type='button' className='chip-item' onClick={onClick}>
      <StyledVariableChip className='variable' text={label} />
    </StyledButton>
  );
};

const VariableSelectorGroup = (props: {
  variableGroup: UIVariableGroup;
  onClick: (variable: UIVariable) => void;
}) => {
  const { variableGroup, onClick } = props;
  return (
    <StyledVariableGroup>
      <VariableSelectorGroupTitle
        groupName={variableGroup.groupName}
        groupType={variableGroup.groupType}
        groupCount={variableGroup.variables.length}
      />
      <div className='chip-wrapper'>
        {variableGroup.variables.map((variable) => (
          <VariableSelectorGroupItem
            onClick={() => onClick(variable)}
            label={variable.variableName}
            key={variable.variableId}
          />
        ))}
      </div>
    </StyledVariableGroup>
  );
};

const doesVariableMatchSearchText = (variable: UIVariable, text: string) => {
  // empty text means 'allow everything'
  if (text.trim().length === 0) {
    return true;
  }
  return variable.variableName.toLowerCase().includes(text.toLowerCase());
};

export const VariableSelectorPopover = (
  props: VariableSelectorPopoverTypes
): JSX.Element | null => {
  const {
    className,
    onClose,
    open,
    anchorEl,
    anchorOrigin = {
      vertical: 'bottom',
      horizontal: 'center',
    },
    variableGroups,
    onVariableSelect,
  } = props;

  const [textValue, setTextValue] = useState('');

  // Reset the text when you open/close
  useEffect(() => {
    setTextValue('');
  }, [open]);

  const handleTextChange = useCallback((value) => {
    setTextValue(value);
  }, []);

  const filteredVariableGroups = variableGroups.reduce((acc, cur) => {
    const filteredVariables = cur.variables.filter((v) =>
      doesVariableMatchSearchText(v, textValue)
    );
    if (filteredVariables.length !== 0) {
      return [
        ...acc,
        {
          ...cur,
          variables: filteredVariables,
        },
      ];
    }
    return acc;
  }, [] as Array<UIVariableGroup>);

  // It's possible that the anchorEl isn't ready yet, so we just return null until then.
  return anchorEl ? (
    <StyledPopoverMenu
      className={className}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={anchorOrigin}
      onClose={onClose}
    >
      <div className='search-field-wrapper'>
        <SearchField
          autoFocus
          onChange={handleTextChange}
          placeholder='Search Available Variables'
        />
      </div>
      <div className='content-wrapper'>
        {filteredVariableGroups.length !== 0 ? (
          filteredVariableGroups.map((v) => (
            <VariableSelectorGroup
              variableGroup={v}
              key={v.groupName}
              onClick={onVariableSelect}
            />
          ))
        ) : (
          <div className='no-result-found'>No Results Found</div>
        )}
      </div>
    </StyledPopoverMenu>
  ) : null;
};

import React, { useEffect, ReactNode, useCallback } from 'react';

import { IconButton } from '../../Atoms/Navigations/IconButton';
import { useSideDrawerContext } from '../../Contexts/SideDrawerContext';
import { StyledSideDrawerContent } from './SideDrawerContent.styles';
import { SideDrawerTitle } from './SideDrawerTitle';

export type SideDrawerContentProps = {
  onUnmount?: () => void;
  children: ReactNode;
  title?: string;
  header?: ReactNode;
  footer?: ReactNode;
  onCloseClick?: () => void;
};

/**
 * The purpose of this component is just to detect when it unmounts, so that the thing that created it can do some handling
 * (like setting an 'active' flag');
 *
 * It also provides a sticky header and footer
 */
export const SideDrawerContent = ({
  children,
  title,
  header,
  footer,
  onUnmount = () => undefined,
  onCloseClick = () => undefined,
}: SideDrawerContentProps) => {
  // This is how to do a componentWillUnmount for hooks
  // It's not intuitive at all
  // https://stackoverflow.com/questions/55139386/componentwillunmount-with-react-useeffect-hook
  // https://codesandbox.io/s/unmounting-and-keys-whwge?file=/src/App.js

  const valueForCleanup = React.useRef<() => void>(onUnmount);

  // onUnmount - just run once
  useEffect(() => {
    return () => {
      valueForCleanup.current();
    };
  }, []);

  // if the onUnmount function changes, make sure we maintain a reference to it.
  useEffect(() => {
    valueForCleanup.current = onUnmount;
  }, [onUnmount]);

  const { setContent } = useSideDrawerContext();
  const handleClose = useCallback(() => {
    onCloseClick();
    setContent(null);
  }, [setContent, onCloseClick]);

  return (
    <StyledSideDrawerContent>
      <div className='title-container'>
        {title && <SideDrawerTitle text={title} />}
      </div>
      <div className='header-container'>
        {header}
        <div className='close-button'>
          <IconButton size='medium' icon='Close' onClick={handleClose} />
        </div>
      </div>
      <div className='content-container'>{children}</div>

      {!!footer && <div className='footer-container'>{footer} </div>}
    </StyledSideDrawerContent>
  );
};

import styled from 'styled-components';
import { Colors } from '../../Foundation/Colors';
import { Fonts } from '../../Foundation/Fonts';

export const TextAreaInputWrapper = styled.div<{
  paddingBottom: string;
  paddingTop: string;
}>`
  width: 100%;
  padding: ${(props) => props.paddingTop}px 20px
    ${(props) => props.paddingBottom}px;
  ${Fonts.body};

  .MuiFormControl-root {
    width: 100%;
  }

  .MuiInputBase-input {
    color: ${Colors.grey_blue};
  }

  p.MuiFormHelperText-root {
    margin-top: 8px;
  }

  .MuiFormLabel-root {
    ${Fonts.body};
    color: ${Colors.grey_blue_4};
    pointer-events: none;
  }

  .MuiInputLabel-formControl {
    position: static;
  }

  label + .MuiInput-formControl {
    margin-top: -2px;
  }
`;

import React, { memo, useEffect, useState, useCallback } from 'react';

import {
  GoogleMap as GoogleMapComp,
  Marker,
  useLoadScript,
} from '@react-google-maps/api';

import {
  MAP_DEFAULT_LOCATION,
  mapLoadScriptConfig,
} from '../../Molecules/GoogleMap';
import { InteractiveMapProps } from './InteractiveMap.types';
import {
  InteractiveMapHeader,
  InteractiveMapStyleWrapper,
  InteractiveMapSubHeader,
  InteractiveMapPlaceholder,
} from './InteractiveMap.styles';

const defaultCenter = MAP_DEFAULT_LOCATION;
const MAX_ZOOM = 16;

export const InteractiveMapWrapper = (
  props: InteractiveMapProps
): JSX.Element => {
  const [map, setMap] = useState(null);
  const {
    zoom = 14,
    mapHeader,
    mapSubHeading,
    interactive,
    placeholder,
    locationText,
    requiredField,
  } = props;

  const containerStyle = {
    width: '100%',
    height: '200px',
  };

  const onLoad = useCallback((map) => {
    setMap(map);
  }, []);

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  const [showPlaceholderField, setPlaceholderField] = useState(true);
  const actualCenter =
    (locationText && JSON.parse(locationText)) || defaultCenter;
  const [location, setLocation] = useState(actualCenter);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function (position) {
      setLocation({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
    });
  }, []);

  useEffect(() => {
    if (JSON.parse(interactive)) setPlaceholderField(true);
    else setPlaceholderField(false);

    setLocation((locationText && JSON.parse(locationText)) || defaultCenter);
  }, [interactive, locationText]);

  return (
    <InteractiveMapStyleWrapper {...props} className='interactive-map-wrapper'>
      {mapHeader && <InteractiveMapHeader>{mapHeader}</InteractiveMapHeader>}
      {mapSubHeading && (
        <InteractiveMapSubHeader>{mapSubHeading}</InteractiveMapSubHeader>
      )}
      {showPlaceholderField && (
        <InteractiveMapPlaceholder placeholder={placeholder} />
      )}
      <GoogleMapComp
        mapContainerStyle={containerStyle}
        center={location}
        zoom={(zoom && (zoom * MAX_ZOOM) / 100) || 14}
        onLoad={onLoad}
        onUnmount={onUnmount}
        options={{
          draggable: false,
          fullscreenControl: false,
          zoomControl: false,
          streetViewControl: false,
          mapTypeControl: false,
        }}
      >
        <Marker onLoad={onLoad} position={location} />
      </GoogleMapComp>
    </InteractiveMapStyleWrapper>
  );
};

export const InteractiveMapComponent = (
  props: InteractiveMapProps
): JSX.Element => {
  const { isLoaded } = useLoadScript(mapLoadScriptConfig);

  if (isLoaded) {
    return <InteractiveMapWrapper {...props} />;
  }
  return <div></div>;
};

export const InteractiveMap = memo(InteractiveMapComponent);

import React, { forwardRef } from 'react';

import { TooltipProps } from '@material-ui/core';
import { Tooltip } from '../../Dialogs/Tooltip';
import { Icon } from '../../Icon/Icon';
import { StyledDropdownAdornment } from './DropdownAdornment.styles';

type DropdownAdornmentProps = {
  className?: string;
  onClick?: () => void;
  tooltipText?: string;
  tooltipPlacement?: TooltipProps['placement'];
  ariaLabel?: string;
};

export const DropdownAdornment = forwardRef<
  HTMLButtonElement,
  DropdownAdornmentProps
>((props, ref) => {
  const {
    className,
    onClick,
    tooltipText,
    tooltipPlacement = 'left',
    ariaLabel = 'Select Variable',
  } = props;
  const content = (
    <StyledDropdownAdornment
      aria-label={ariaLabel}
      className={className}
      onClick={onClick}
      ref={ref}
    >
      <Icon icon='Code' />
    </StyledDropdownAdornment>
  );

  return tooltipText ? (
    <Tooltip
      title={tooltipText}
      ariaLabel={tooltipText}
      placement={tooltipPlacement}
      arrow
    >
      {content}
    </Tooltip>
  ) : (
    content
  );
});

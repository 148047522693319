import React from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { TextField as MuiTextField, FormHelperText } from '@material-ui/core';
import { TextFieldTypes } from './TextField.types';
import { TextFieldWrapper } from './TextField.style';

export const TextField = ({
  label,
  placeholder,
  helperText,
  errorText,
  inputRef,
  multiline,
  rowsMax,
  error,
  defaultValue,
  onChange = () => undefined,
  isDisabled,
  autoFocus,
  type,
  variant,
  onChangeDelay = 0,
  // Generate uuid by default for filled variant to be accessible
  // textfield needs an id for helper text to by assigned as its label
  ...otherProps
}: TextFieldTypes): JSX.Element => {
  const debouncedHandleChange = useDebouncedCallback((value) => {
    onChange(value);
  }, onChangeDelay);

  return (
    <TextFieldWrapper className='text-field-wrapper'>
      {variant === 'filled' && label && (
        <FormHelperText component='label'>{label}</FormHelperText>
      )}
      <MuiTextField
        disabled={isDisabled}
        label={variant === 'filled' ? null : label}
        multiline={multiline}
        rowsMax={rowsMax}
        placeholder={placeholder}
        helperText={error ? errorText : helperText}
        error={error}
        inputRef={inputRef}
        defaultValue={defaultValue}
        onChange={(event) => {
          if (onChangeDelay === 0) {
            onChange(event.target.value);
          } else {
            debouncedHandleChange(event.target.value);
          }
        }}
        margin='normal'
        classes={{ root: 'text-field-form-control' }}
        fullWidth
        autoFocus={autoFocus}
        type={type}
        variant={variant}
        inputProps={{
          'aria-label': label as string,
        }}
        {...otherProps}
      />
    </TextFieldWrapper>
  );
};

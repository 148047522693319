import React, { ComponentType } from 'react';
import { RatingProps } from '../../Atoms/Rating';

import * as Ratings from '../../Molecules/Ratings';

import type { RatingDnDProps } from './RatingDnD.types';

const types = {
  Stars: 'StarRating',
  'Emoji Faces': 'EmojiRating',
};

export const RatingDnD = (props: RatingDnDProps): JSX.Element => {
  const { ratingType, ...otherProps } = props;

  // Link: https://stackoverflow.com/a/39605753/1577396
  /* eslint import/namespace: ['error', { allowComputed: true }] */

  const RatingComp = Ratings[types[ratingType]] as ComponentType<RatingProps>;

  return <RatingComp {...otherProps} />;
};

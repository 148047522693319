import { FormControlLabel } from '@material-ui/core';
import styled from 'styled-components';
import { Colors } from '../../../Foundation/Colors';
import { Fonts } from '../../../Foundation/Fonts';

export const StyledFormControlLabel = styled(FormControlLabel)`
  .MuiCheckbox-root {
    color: ${Colors.grey_blue_4};

    .MuiSvgIcon-fontSizeSmall {
      /* Makes the actual checkbox 14px */
      height: 19px;
      width: 19px;
    }
  }

  .MuiCheckbox-colorPrimary.Mui-checked {
    color: ${Colors.accent};
  }

  .MuiFormControlLabel-label {
    ${Fonts.body};
  }
`;

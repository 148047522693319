import axios from 'axios';
import {
  getSelectedWorkSpaceId,
  getSelectedWorkSpaceName,
} from '../localStorage';
import { clearLocalStorage, pagesPathname } from '..';

const loginPath = pagesPathname ? pagesPathname.login : '';

// this function will clear local storage data with sensitive informations.
const clearPrivateDataCache = () => {
  const privateDataCacheLookup = {
    apolloCache: 'apollo-cache-persist',
    userDetails: 'ajs_user_traits',
  };
  const keys = Object.values(privateDataCacheLookup);
  keys.forEach((key) => localStorage.removeItem(key));
};

export const logout = (client, endpoint) => async () => {
  try {
    const mutation = `mutation {
      logout {
        status
      }
    }`;

    await axios.post(endpoint, { query: mutation }, { withCredentials: true });

    // https://www.apollographql.com/docs/react/networking/authentication/#reset-store-on-logout
    // Since Apollo caches all of your query results, it’s important to get rid of them when the login state changes.
    if (client) {
      client.resetStore();
    }
    clearLocalStorage();
    clearPrivateDataCache();

    if (window.analytics) {
      window.analytics.track('User Logged Out', {
        userId: window.localStorage.getItem('ajs_user_id'),
        workspaceId: getSelectedWorkSpaceId(),
        workspaceName: getSelectedWorkSpaceName(),
      });
    }
    window.location.assign(loginPath);
  } catch (ex) {
    console.info(`Logout failed: ${ex.message}`);
  }
};

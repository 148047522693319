import React from 'react';

import { Button } from '../../Atoms/Navigations/Button';
import { Icon } from '../../Atoms/Icon';
import * as S from './AddImage.styles';

type Props = {
  onClick: () => void;
  title?: string;
};

export const AddImage = ({ onClick, title }: Props): JSX.Element => (
  <div>
    {title && (
      <S.TitleWrapper>
        <S.Title>{title}</S.Title>
      </S.TitleWrapper>
    )}
    <S.PlaceholderWrapper onClick={onClick}>
      <S.ImagePlaceholder icon='PlaceholderImage' />
      <Button type='primary' variant='link'>
        <Icon icon='Plus' />
        Add image
      </Button>
    </S.PlaceholderWrapper>
  </div>
);

import React from 'react';
import {
  firstMessageCharacterLimit,
  messageCharacterLimit,
  messageTotalLimit,
  smsMaximumSize,
  getTotalMessageCharacterCount,
} from '@whispir/utils-js';
import {
  CharacterCount,
  FooterWrapper,
  MessageCountWrapper,
} from './MessageCounter.style';

type Props = {
  message: string;
  maxLength: number;
};

const draftailStripAndReplaceLineBreaks = (message) => {
  // Remove all p tags
  return message.replace(/<p><p\/p>/g).replace(/(?:<p>|<\/p>)/g, '');
};

export const MessageCounter = ({
  message,
  maxLength = smsMaximumSize(),
}: Props): JSX.Element => {
  const parsedMessage = draftailStripAndReplaceLineBreaks(message) || '';
  const totalMessageCharacterCount = getTotalMessageCharacterCount(
    parsedMessage
  );

  const getMessageCount = () => {
    return totalMessageCharacterCount <= firstMessageCharacterLimit
      ? 1
      : Math.ceil(totalMessageCharacterCount / messageCharacterLimit);
  };

  const getCountInfo = () => {
    const messageCount = getMessageCount();
    return (
      <FooterWrapper>
        {!!messageCount && (
          <MessageCountWrapper>
            {`
              ${Math.min(messageCount, messageTotalLimit)} Message${
              messageCount === 1 ? '' : 's'
            }
            `}
          </MessageCountWrapper>
        )}
        <CharacterCount error={totalMessageCharacterCount > maxLength}>
          {`~${totalMessageCharacterCount}/${maxLength}`}
        </CharacterCount>
      </FooterWrapper>
    );
  };

  return getCountInfo();
};

import styled from 'styled-components';
import { Colors } from '../../Foundation/Colors';
import { Fonts } from '../../Foundation/Fonts';

export const ChannelSelectorWrapper = styled.div`
  .channel-selector-button {
    ${Fonts.body};
    color: ${Colors.grey_blue};
    margin: 8px;
    border: solid 1px ${Colors.grey_blue_1};
    border-radius: 4px;
  }

  .optional-message {
    ${Fonts.caption};
  }

  .error-message {
    color: ${Colors.error};
    ${Fonts.caption};
    margin-left: 16px;
  }

  .info-container {
    margin-top: 16px;
    width: 50%;

    .channel-additional-info {
      margin-top: 8px;

      span {
        font-weight: 500;
      }
    }
  }

  .action-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }

  .button {
    cursor: pointer;
    padding: 10px 16px;
    background-color: ${Colors.white};
    border-radius: 4px;
    transition: background-color 0.25s ease;

    &:hover {
      background-color: ${Colors.grey_blue_1};
    }

    &.disable {
      .icon-wrapper {
        > div {
          background-color: ${Colors.grey_blue_2};
        }
      }

      .name {
        color: ${Colors.grey_blue_3};
      }
    }

    .icon-wrapper {
      > div {
        width: 24px;
        height: 24px;

        svg {
          width: 16px;
          height: 16px;
        }
      }
    }

    .name {
      text-transform: capitalize;
      margin-left: 8px;
    }

    .actions {
      flex-grow: 1;
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-end;

      .link {
        ${Fonts.link};
        color: ${Colors.accent};
      }
    }
  }
`;

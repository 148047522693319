import { localStorageLookup } from '..';

export function getStoreKey(config) {
  return `${config.key}:v${config.version}`;
}

export function clearLocalStorage() {
  const keys = Object.values(localStorageLookup).reduce(
    (col, config) => [...col, getStoreKey(config)],
    []
  );

  keys.forEach((key) => localStorage.removeItem(key));
}

export function writeToLocalStorage(data, config) {
  try {
    localStorage.setItem(
      getStoreKey(config),
      typeof data === 'string' ? data : JSON.stringify(data)
    );
  } catch (err) {
    console.log(err);
    console.info(`Failed to write data for '${getStoreKey(config)}'`);
  }
}

export function readFromLocalStorage(config) {
  try {
    const data = JSON.parse(localStorage.getItem(getStoreKey(config)) || '');
    if (data !== null) {
      return data;
    }
  } catch (err) {
    console.info(`Failed to parse data for '${getStoreKey(config)}'`);
    return [];
  }
  return [];
}

export function getSelectedWorkSpaceId() {
  const workspaces = readFromLocalStorage(localStorageLookup.shared);
  try {
    const { id } =
      workspaces.find((workspace) => workspace.isSelected) || workspaces[0];
    return id;
  } catch (err) {}
  return '';
}

export function getSelectedWorkSpaceName() {
  const workspaces = readFromLocalStorage(localStorageLookup.shared);
  try {
    const { name } =
      workspaces.find((workspace) => workspace.isSelected) || workspaces[0];
    return name;
  } catch (err) {}
  return '';
}

/**
 * Define property that can be overridden using a value in local-storage
 * @param {string} storageKey
 * @param {*} value
 * @returns {*}
 */
export const overridable = (storageKey, value) => {
  const localValue = localStorage.getItem(storageKey);
  if (localValue) {
    console.info(
      `%c🚀 Using local override for %c${storageKey} [${localValue}]`,
      'color: lightBlue;',
      'color: yellow;'
    );
  }

  return localValue || value;
};

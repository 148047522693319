import React, { useState, useEffect, ChangeEvent, useRef } from 'react';

import { Switch, FormControlLabel, FormHelperText } from '@material-ui/core';
import { ToggleSwitchWrapper } from './ToggleSwitch.styles';
import { ToggleSwitchProps } from './ToggleSwitch.types';

export const ToggleSwitch = ({
  label = '',
  onChange,
  defaultValue,
}: ToggleSwitchProps): JSX.Element => {
  const defaultCheckedState = defaultValue && defaultValue.toString();
  const [{ checked }, setCheckedState] = useState({
    checked: defaultCheckedState === 'true',
  });

  const isFirstRun = useRef(false);

  const onChangeHandler = () => {
    setCheckedState({ checked: !checked });
    if (onChange) onChange(!checked);
  };

  useEffect(() => {
    const { current } = isFirstRun;
    if (current) {
      isFirstRun.current = false;
      return;
    }
    setCheckedState({ checked: defaultCheckedState === 'true' });
  }, [defaultValue]);

  return (
    <ToggleSwitchWrapper className='toggle-switch-wrapper'>
      <FormControlLabel
        labelPlacement='start'
        control={
          <Switch
            checked={checked}
            color='primary'
            onChange={onChangeHandler}
          />
        }
        label={<FormHelperText component='label'>{label}</FormHelperText>}
      />
    </ToggleSwitchWrapper>
  );
};

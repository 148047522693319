import styled from 'styled-components';
import { Colors } from '../../Foundation/Colors';
import { Fonts } from '../../Foundation/Fonts';
import { Elevations } from '../../Foundation/Elevations';
import { TRANSITION_TIME } from '../../Foundation/Spacing';

export const StyledTemplateCard = styled.div<{ isActive: boolean }>`
  position: relative;
  width: 240px;
  height: 264px;
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 8px;
  border: 1px solid
    ${({ isActive }) => (isActive ? Colors.accent_4_5 : Colors.grey_blue_1)};
  background: ${Colors.background};
  box-shadow: ${Elevations.elevation_1};
  transition: all ${TRANSITION_TIME};

  .preview {
    margin: 24px;
    overflow: hidden;
  }

  .details {
    position: absolute;
    bottom: 0;
    width: 100%;
    min-height: 60px;
    padding: 16px 24px 24px;
    box-sizing: border-box;
    background: ${Colors.white};

    .details-title {
      color: ${Colors.grey_blue};
      ${Fonts.subheadSmall};
      display: flex;
      align-items: center;

      .channel {
        border-radius: 50%;
        margin-left: 8px;
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 10px;
          height: 10px;
        }

        &.sms {
          background: ${Colors.sms_light};

          svg {
            fill: ${Colors.sms};
          }
        }

        &.email {
          background: ${Colors.email_light};

          svg {
            fill: ${Colors.email_dark};
          }
        }

        &.web {
          background: ${Colors.warning_light};

          svg {
            fill: ${Colors.web};
          }
        }

        &.webform {
          background: ${Colors.success_light};

          svg {
            fill: ${Colors.success};
          }
        }
      }
    }

    .details-date-actions {
      height: 16px;
      margin-top: 8px;
      box-sizing: border-box;
      color: ${Colors.grey_blue_4};
      ${Fonts.caption};
      display: flex;
      align-items: center;
      justify-content: space-between;

      svg {
        fill: ${Colors.grey_blue_4};
      }
    }
  }

  .magnifier {
    position: absolute;
    top: 16px;
    right: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    opacity: 0.48;
    border-radius: 50%;
    background: ${Colors.background_dark};
    visibility: hidden;
    z-index: 1;
    cursor: pointer;

    svg {
      fill: ${Colors.white};
      width: 20px;
      height: 20px;
    }
  }

  &:hover {
    box-shadow: ${Elevations.elevation_2};
    transform: translateY(-4px);

    .magnifier {
      visibility: visible;
      transition: visibility ${TRANSITION_TIME};

      &:hover {
        opacity: 0.85;
        transition: opacity ${TRANSITION_TIME};
      }
    }
  }
`;

export const StyledNewCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  margin-top: -24px; // -24px: remove paading of the parent just for this item
  cursor: pointer;
`;

import React from 'react';
import styled from 'styled-components';
import { Button } from '@material-ui/core';
import { Colors } from '../../Foundation/Colors';
import { Fonts } from '../../Foundation/Fonts';

// Not sure why styled isn't accepting just styled(Button)
export const StyledSquareIconButton = styled(
  ({ onClick, children, className, disabled }) => (
    <Button className={className} onClick={onClick} disabled={disabled}>
      {children}
    </Button>
  )
)`
  &.MuiButton-root {
    height: 102px;
    width: 102px;
    border-radius: 8px;
    border: 1px solid ${Colors.grey_blue_2};
  }

  .MuiButton-label {
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    height: 48px;
    display: inline-flex;

    span {
      ${Fonts.caption};
      text-transform: capitalize;
      margin-top: 8px;
    }

    .icon-wrapper {
      height: 24px;
      width: 24px;

      svg {
        fill: ${Colors.grey_blue_4};
      }
    }
  }
`;

import React from 'react';
import { HorizontalSelector } from '../../Atoms/HorizontalSelector';
import { StyledToneCard } from './ToneOfVoiceCard.style';

type Props = {
  onToneChoice: (type: string, value: string) => void;
  icon?: string;
  title?: string;
  description?: string;
  toneValue?: string;
};

export const ToneOfVoiceCard = ({
  onToneChoice,
  icon = '🙂',
  title = 'Joyful',
  description = "We're delighted to have you with us today.",
  toneValue = 'low',
}: Props): JSX.Element => {
  const handleOnToneChoice = (type: string, value: string) => {
    return onToneChoice(type, value);
  };

  return (
    <StyledToneCard>
      <div className='icon'>{icon}</div>
      <div className='title'>{title}</div>
      <div className='selector'>
        <HorizontalSelector
          type={title}
          toneValue={toneValue}
          items={[
            {
              label: 'Low',
              value: 'low',
            },
            {
              label: 'Moderate',
              value: 'medium',
            },
            {
              label: 'Strong',
              value: 'high',
            },
          ]}
          onChange={handleOnToneChoice}
        />
      </div>
      <div className='description'>
        <span className='bold'>Example:</span> {description}
      </div>
    </StyledToneCard>
  );
};

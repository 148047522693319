import styled from 'styled-components';

type AdhocVariableSelectorWrapperTypes = {
  variableEnabled: boolean;
};

export const AdhocVariableSelectorWrapper = styled.div<AdhocVariableSelectorWrapperTypes>`
  .menu-label {
    display: ${({ variableEnabled }) => !variableEnabled && 'none'};
  }
  .styled-react-select__indicators {
    display: ${({ variableEnabled }) => !variableEnabled && 'none'};
  }
`;

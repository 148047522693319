import styled from 'styled-components';

import { DonutChartPropTypes } from './DonutChart.types';

export const StyledDonutChart = styled.div<DonutChartPropTypes>`
  display: flex;
  justify-content: center;

  .chart {
    position: absolute;
    width: 67px;
    height: 67px;
  }

  .icon-layer {
    position: absolute;
    top: 0;
    right: 8px;
    left: 8px;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    .svg-wrapper {
      background-color: ${(props) => props.iconBackgroundColor};
      padding: 8px 8px 4px;
      border-radius: 50%;

      svg {
        fill: ${(props) => props.colors[0]};
      }
    }
  }
`;

import React, { PureComponent, ChangeEvent } from 'react';

import TextField from '@material-ui/core/TextField';
import { SubjectWrapper } from '../DroppableItemProperties.styles';
import {
  PageTitleFieldProps,
  PageTitleFieldState,
} from './PageTitleField.types';

export class PageTitleField extends PureComponent<
  PageTitleFieldProps,
  PageTitleFieldState
> {
  state = { pageTitle: '' };

  componentDidMount() {
    const { defaultTitle, pageTitle, updateLocalDraft, type } = this.props;

    if (!pageTitle) {
      updateLocalDraft(type, defaultTitle);
      this.setState({ pageTitle: defaultTitle });
    } else {
      this.setState({ pageTitle });
    }
  }

  onInputChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      currentTarget: { value },
    } = event;
    const { updateLocalDraft, type } = this.props;

    this.setState({ pageTitle: value }, () => {
      updateLocalDraft(type, value);
    });
  };

  render() {
    const { pageTitle } = this.state;

    return (
      <SubjectWrapper>
        <TextField
          classes={{ root: 'subject-field' }}
          label='Page Title'
          placeholder='Untitled Page'
          value={pageTitle || ''}
          onChange={this.onInputChanged}
        />
      </SubjectWrapper>
    );
  }
}

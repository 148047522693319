import styled from 'styled-components';
import { Colors } from '../../Foundation/Colors';
import { Fonts } from '../../Foundation/Fonts';

export const RadioButtonGroupWrapper = styled.div`
  padding: 12px 24px 12px;

  .btn-done {
    width: 100%;
    margin: 10px 0 -2px;
    padding: 2px;
    border-radius: 20px;
    display: block;
    ${Fonts.subheadSmall};
    font-size: 14px;
    color: ${Colors.accent};
    cursor: pointer;
    text-transform: none;
  }
`;

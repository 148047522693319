import React from 'react';

import { StyledFormItem } from './FormItem.styles';
import { FormItemProps } from './FormItem.types';

export const FormItem = (props: FormItemProps) => {
  const { className = '', children, label } = props;
  return (
    <StyledFormItem className={className}>
      <label className='label'>{label}</label>
      {children}
    </StyledFormItem>
  );
};

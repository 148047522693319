import styled from 'styled-components';
import { Colors } from '../../Foundation/Colors';
import { Fonts } from '../../Foundation/Fonts';

import { LegendsWrapperPropTypes, IconProps } from './CustomLegends.types';

export const LegendsWrapper = styled.div<LegendsWrapperPropTypes>`
  position: absolute;
  top: ${(props): string => (props.position === 'left' ? '30px' : '40px')};
  width: ${(props): string => (props.position === 'left' ? 'auto' : '100%')};
  display: flex;
  padding: ${(props): string =>
    props.position === 'left' ? '0' : '0 10px 4px 0'};
  flex-wrap: wrap;
  flex-direction: ${(props): string =>
    props.position === 'left' ? 'column' : 'row'};
`;

export const LegendItem = styled.div<LegendsWrapperPropTypes>`
  display: ${(props): string => (props.position === 'left' ? 'block' : 'flex')};
  width: ${(props): string => (props.position === 'left' ? '100%' : 'auto')};
  align-items: ${(props): string | undefined =>
    props.position === 'left' ? 'unset' : 'center'};
  margin: ${(props): string =>
    props.position === 'left' ? '0 10px 5px 0' : '0 20px 0 0'};
  ${Fonts.caption};

  .tooltip-content {
    width: 172px;
    ${Fonts.body}
  }
  .tooltip-icon {
    margin: 2px 6px 0 0;
  }
  .tooltip-body {
    white-space: break-spaces;
  }
  .divider-line {
    width: 58px;
  }
  .tooltip-icons-group {
    display: inline-flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    margin-top: 10px;

    .divider-form-control {
      margin: 0;
    }

    svg {
      fill: ${Colors.white};
    }
  }
`;

export const ItemDescription = styled.div<LegendsWrapperPropTypes>`
  margin-left: 4px;
  display: flex;
  justify-content: ${(props): string =>
    props.position === 'left' ? 'space-between' : 'initial'};
  ${Fonts.body};

  .description-total {
    font-weight: ${(props): string =>
      props.position === 'left' ? '400' : '500'};
    margin: 0 5px 0 5px;
  }
`;

export const StyledIcon = styled.p<IconProps>`
  svg {
    height: ${({ size }) => size};
    width: ${({ size }) => size};
    fill: ${({ color }) => color};
  }
`;

import styled from 'styled-components';
import { Colors } from '../../../Foundation/Colors';

import { Fonts } from '../../../Foundation/Fonts';
import { StyledMenuItemProps } from './MenuItem.types';

export const StyledMenuItem = styled.span<StyledMenuItemProps>`
  ${Fonts.body};
  line-height: 32px;

  a {
    color: ${(props) => (props.active ? Colors.accent : Colors.grey_blue_4)};
    text-decoration: none;

    :hover {
      color: ${Colors.grey_blue};
    }

    .count {
      float: right;
    }
  }
`;

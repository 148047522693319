import styled from 'styled-components';
import { Colors } from '../../Foundation/Colors';
import { Elevations } from '../../Foundation/Elevations';
import { Fonts } from '../../Foundation/Fonts';

export const SearchFilterWrapper = styled.div`
  display: inline-block;

  .filter-title {
    display: block;
    ${Fonts.caption};
    line-height: 20px;
    font-weight: 500;
    color: ${Colors.grey_blue_4};
    margin-bottom: 2px;
  }

  .search-filter-chip {
    margin: 0 8px;
  }

  .secondary {
    :focus-within {
      box-shadow: none;
    }

    .more-than-two-suffix {
      margin: 0 8px;
    }

    .MuiButton-root {
      .MuiButton-startIcon {
        margin-right: 0;

        svg {
          fill: ${Colors.grey_blue};
        }
      }

      .MuiButton-endIcon {
        svg {
          fill: ${Colors.grey_blue};
        }
      }

      .placeholder {
        color: ${Colors.grey_blue_3};
        margin-left: 4px;
      }

      .content {
        text-overflow: ellipsis;
        max-width: 314px;
        overflow: hidden;
        white-space: nowrap;
        margin-left: 8px;
        color: ${Colors.grey_blue};
      }
    }
  }

  &.multi-select {
    .secondary {
      .MuiButton-root {
        background: ${Colors.grey_blue_0};
        color: ${Colors.accent};
        border-radius: 4px;

        :hover,
        :active {
          background: ${Colors.grey_blue_1};
          color: ${Colors.grey_blue};
        }
      }
    }
  }

  &.single-select {
    .secondary {
      .MuiButton-root {
        background: ${Colors.grey_blue_1};
        color: ${Colors.grey_blue};
      }
    }
  }

  .Mui-disabled {
    .MuiButton-endIcon,
    .MuiButton-startIcon {
      svg {
        fill: ${Colors.grey_blue_3};
      }
    }
  }
`;

export const FilterWrapper = styled.div`
  display: none;
  background: ${Colors.white};
  border-radius: 8px;
  box-shadow: ${Elevations.elevation_3};
  padding: 16px;
  z-index: 2;
  min-width: 378px;
  position: absolute;

  &.open {
    display: block;
  }

  .divider-and-done {
    width: 100%;
    text-align: center;
  }

  .search-filter-wrapper {
    background: ${Colors.grey_blue_0};
    border: none;
    cursor: text;

    .MuiFilledInput-root {
      ${Fonts.bodySmall};
      color: ${Colors.grey_blue};
      padding-top: 4px;
      border-radius: 4px;
      background-color: transparent;
    }

    .MuiInputBase-input::placeholder {
      ${Fonts.bodySmall};
      color: ${Colors.grey_blue_3};
    }

    .autocomplete-tag {
      display: none;
    }

    .MuiFilledInput-underline {
      :after {
        border: 1px solid ${Colors.accent};
      }
      :before {
        border: none;
      }
    }
  }
  .option-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-width: 320px;
    color: ${Colors.grey_blue};

    .secondary {
      width: 100%;

      .MuiButton-root {
        background-color: transparent;
        width: 100%;
        justify-content: space-between;

        .content {
          color: ${Colors.grey_blue};
          ${Fonts.body};
        }
      }
    }

    .MuiFormControlLabel-label {
      text-overflow: ellipsis;
      max-width: 230px;
      overflow: hidden;
      white-space: nowrap;
      ${Fonts.body};
    }
  }

  .time-text {
    white-space: nowrap;
    color: ${Colors.grey_blue_3};
    ${Fonts.caption};
    width: 100%;
    max-width: 172px;
    text-align: right;
  }

  .autocomplete-popper {
    margin: 0;
    min-width: 100%;
    display: contents;
  }

  .autocomplete-paper {
    box-shadow: none;
  }

  .MuiIconButton-label {
    color: ${Colors.grey_blue_4};
  }

  .autocomplete-end-adornment > .autocomplete-popup-indicator {
    display: none;
  }

  .Mui-checked {
    .MuiIconButton-label {
      color: ${Colors.accent};
    }
  }

  .autocomplete-option {
    padding: 6px;

    &[data-focus='true'],
    &[aria-selected='true'],
    &:active {
      background: transparent;
    }
  }

  .autocomplete-no-options {
    padding: 20px 0;
  }

  .not-found-message,
  .no-message {
    display: flex;
    align-items: center;
    ${Fonts.body};
    color: ${Colors.grey_blue_4};

    svg {
      margin-right: 10px;
      fill: ${Colors.grey_blue_3};
      width: 15px;
      height: 15px;
    }
  }

  .button {
    :hover {
      background: ${Colors.grey_blue_1};
    }

    .MuiButton-root {
      background: transparent;

      :hover,
      :active {
        background: transparent;
      }
    }
  }
`;

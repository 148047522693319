import React, { useState } from 'react';

import { Icons } from '../../../Foundation/Icons';

import { MenuItem } from '../../../Atoms/Navigations/MenuItem/MenuItem';

import { Icon } from '../../../Atoms/Icon';
import { Divider } from '../../../Atoms/Layout';
import { ExpandIcon } from '../../../Atoms/Navigations';
import { AccordionGroup } from '../../../Molecules/AccordionGroup';
import {
  StyledDrawer,
  Footer,
  DividerWrapper,
  ExpandIconWrapper,
  ModuleNameWrapper,
  LinkWrapper,
} from './SideDrawer.styles';
import { SideDrawerTypes } from './SideDrawer.types';

export const SideDrawer = ({
  isSubnavOpen,
  subNavMenuItems,
  toggleDrawer = () => null,
}: SideDrawerTypes): JSX.Element => {
  const [isMouseOverExpandIcon, setIsMouseOverExpandIcon] = useState(false);
  const toggleIsMouseOverExpandIcon = (bln) => setIsMouseOverExpandIcon(bln);
  const [activeNavItem] = subNavMenuItems;
  const { title: moduleName, icon: moduleIcon } = activeNavItem;

  return (
    <StyledDrawer
      variant='permanent'
      className={`globalnav-side-drawer ${isSubnavOpen ? 'open' : ''} ${
        isMouseOverExpandIcon ? '' : 'mouse-on-drawer-area'
      }`}
    >
      <ModuleNameWrapper onClick={() => !isSubnavOpen && toggleDrawer()}>
        {moduleIcon && <Icon icon={moduleIcon} />}
        {moduleName}
      </ModuleNameWrapper>
      {moduleName && (
        <DividerWrapper>
          <Divider dividerType='solid' isHorizontal />
        </DividerWrapper>
      )}
      {subNavMenuItems.map((navItem) => {
        const { renderAs, link = '/', title } = navItem;

        if (renderAs === 'link') {
          return (
            <LinkWrapper key={title}>
              <MenuItem title={title} pathname={link} />
            </LinkWrapper>
          );
        }

        return <AccordionGroup key={title} accordions={[navItem]} />;
      })}
      <ExpandIconWrapper
        onMouseEnter={() => toggleIsMouseOverExpandIcon(true)}
        onMouseLeave={() => toggleIsMouseOverExpandIcon(false)}
      >
        <ExpandIcon isExpanded={isSubnavOpen} toggleDrawer={toggleDrawer} />
      </ExpandIconWrapper>
      <Footer>
        <Icons.Logo />
      </Footer>
    </StyledDrawer>
  );
};

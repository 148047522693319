import styled from 'styled-components';

export const StyledToneOfVoiceCardList = styled.div`
  display: flex;
  flex-flow: wrap;
`;

export const StyledListItem = styled.div`
  margin-right: 24px;
  margin-bottom: 24px;
`;

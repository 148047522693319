import React from 'react';

import {
  StyledButtonGroupWrapper,
  StyledLink,
} from './RichTextButtonGroupBlock.style';
import { RichTextButtonGroupBlockProps } from './RichTextButtonGroupBlock.types';

export const RichTextButtonGroupBlock = ({
  textColor,
  fixedWidth,
  buttonMargin,
  vertStacked,
  linkGenerator: defaultValue,
  paddingTop,
  paddingBottom,
  backgroundColor,
  borderRadius,
}: RichTextButtonGroupBlockProps): JSX.Element => {
  const defaultButtonValues =
    typeof defaultValue === 'string' ? JSON.parse(defaultValue) : defaultValue;

  return (
    <StyledButtonGroupWrapper
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      vertStacked={vertStacked}
      backgroundColor={backgroundColor}
    >
      {defaultButtonValues &&
        defaultButtonValues.map(({ id, text, background }) => (
          <StyledLink
            textColor={textColor}
            borderRadius={borderRadius}
            buttonMargin={buttonMargin}
            fixedWidth={fixedWidth}
            background={background}
            key={id}
          >
            {text}
          </StyledLink>
        ))}
    </StyledButtonGroupWrapper>
  );
};

import styled from 'styled-components';
import { Colors } from '../../Foundation/Colors';
import { Fonts } from '../../Foundation/Fonts';
import { TRANSITION_TIME } from '../../Foundation/Spacing';

export const StyledActionCard = styled.button`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  ${Fonts.body};
  text-align: left;
  color: ${Colors.grey_blue_4};
  background-color: ${Colors.white};
  transition: background-color ${TRANSITION_TIME};
  padding: 12px 24px 12px 62px;
  width: 100%;
  min-height: 88px;
  margin-bottom: 8px;
  border: 1px ${Colors.grey_blue_2} solid;
  border-radius: 8px;
  cursor: pointer;
  position: relative;
  outline: none;

  &:hover {
    background: ${Colors.grey_blue_1};
  }

  &:focus {
    border: 1px ${Colors.accent} solid;
  }

  .MuiRadio-root {
    &:hover,
    &.Mui-checked:hover {
      background-color: inherit;
      transition: none;
    }

    padding: 0;
    &.MuiSvgIcon-root {
      color: ${Colors.accent};
      fill: ${Colors.accent};
      height: 16px;
      width: 16px;
    }
  }
`;

export const StyledHeading = styled.div`
  ${Fonts.subheadSmall};
  color: ${Colors.grey_blue};
  text-align: left;
  margin: 0 0 2px;
`;

export const StyledIcon = styled.div`
  position: absolute;
  left: 24px;
  top: 50%;
  transform: translateY(-50%);

  svg {
    fill: ${Colors.grey_blue_4};
  }
`;

export const StyledDescription = styled.span`
  display: inline-block;
  ${Fonts.body};
`;

import React from 'react';

import Drawer from '@material-ui/core/Drawer';
import { useSideDrawerContext } from '../../Contexts/SideDrawerContext';
import { SideDrawerProps } from './SideDrawer.types';
import { StyledSideDrawer } from './SideDrawer.styles';

export const SideDrawer = ({
  position,
  fullScreen,
}: SideDrawerProps): JSX.Element => {
  const { content } = useSideDrawerContext();

  return (
    <StyledSideDrawer
      className='side-drawer'
      fullScreen={fullScreen}
      dimmer={content !== null}
    >
      <Drawer anchor={position} variant='persistent' open={content !== null}>
        {content}
      </Drawer>
    </StyledSideDrawer>
  );
};

import React, { useEffect, useState } from 'react';
import { overridable } from '@whispir/utils-js';
import { Spinner } from '../../Atoms/Indicators/Spinner';

// For now, we will just say that EnvironmentProperties is a map of string:unknown
// Future state is that we could define a set list of properties
type EnvironmentProperties = Record<string, unknown>;

type EnvContext = {
  env: EnvironmentProperties;
};

type EnvProviderProps = {
  fetchEnv?: () => Promise<EnvironmentProperties>;
  fetchFn?: typeof fetch;
  envLocation?: string;
  initialEnv?: EnvironmentProperties;
  preventFetch?: boolean; // Use in tests if you don't want any async behaviour
};

const EnvProviderContext = React.createContext<EnvContext>({
  env: {},
});

const createDefaultFetchEnv = (fetchFn: typeof fetch) => async (
  envLocation: string
) => {
  try {
    const result = await fetchFn(envLocation);
    if (!result.ok) {
      throw new Error(result.statusText);
    }

    const data = await result.json();
    const env = Object.keys(data).reduce(
      (props, key) => ({
        ...props,
        [key]: overridable(key, data[key]),
      }),
      {}
    );

    return env;
  } catch (err) {
    throw new Error(`Failed to load ${envLocation}`);
  }
};

/**
 * EnvProvider provides a list of key/values
 * Some of those values are used for feature toggles,
 * But some are not (eg. for API URLS).
 * @param props
 * @returns
 */

export const EnvProvider = (
  props: React.PropsWithChildren<EnvProviderProps>
) => {
  const {
    children,
    // By default the env locaiton is `/env.json` but you probably want to override it with
    // `/workflows/env.json` for example
    envLocation = `/env.json`,

    // This is just for testing - providing a mechanism for mocking fetch from jest.
    fetchFn = fetch,
    // Just an async function that will return the env object.
    // You may want to use this for testing
    fetchEnv = createDefaultFetchEnv(fetchFn),

    // These two can be used in tests if you want to prevent async behaviour
    // The `initialEnv` will be immediately available
    // Removing the need for async behaviour
    preventFetch = false,
    initialEnv = {}, // I'm not sure this is needed
  } = props;

  const [env, setEnv] = useState(initialEnv);
  const [isLoading, setIsLoading] = useState(!preventFetch); // Component starts as loading, unless we are preventing fetch.

  useEffect(() => {
    if (!preventFetch) {
      fetchEnv(envLocation)
        .then((data) => {
          setEnv(data);
          setIsLoading(false);
        })
        .catch((err) => {
          // If  we want we could display an error message
          // or report here

          throw err;
        });
    }
    // Adding `fetchEnv` to dependency array causes infinite rerender when using the default value
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [envLocation, preventFetch]);

  return (
    <EnvProviderContext.Provider
      value={{
        env,
      }}
    >
      {isLoading ? <Spinner size='large' /> : children}
    </EnvProviderContext.Provider>
  );
};

export const useEnv = (): EnvContext => {
  return React.useContext(EnvProviderContext);
};

export function withEnv(SourceComponent) {
  const WrapperComponent = (props) => (
    <EnvProviderContext.Consumer>
      {(context) => <SourceComponent {...props} envCtx={context} />}
    </EnvProviderContext.Consumer>
  );

  return WrapperComponent;
}

import { variableIds } from '@whispir/expression-helper';

import { ModelType } from '../../utilityTypes';

const { ARRAY_OF_STRING } = variableIds;

export const formCheckboxListModel = (id: string): ModelType => ({
  id,
  type: 'FormCheckboxList',
  label: 'Multi Choice',
  componentType: 'form',
  componentValueType: ARRAY_OF_STRING,
  properties: [
    {
      name: 'text',
      label: 'Styles',
      control: 'TitleDivider',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'label',
      label: 'Label',
      control: 'TextFieldFilled',
      value: 'Multi Choice',
      __typename: 'ComponentProperty',
    },
    {
      name: 'requiredField',
      label: 'Required Field',
      control: 'Checkbox',
      value: 'false',
      __typename: 'ComponentProperty',
    },
    {
      name: 'helperText',
      label: 'Helper Text',
      control: 'TextFieldFilled',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'padding_8px_2',
      label: '',
      control: 'PaddingControl8px',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'text',
      label: 'Options',
      control: 'TitleDividerNoLine',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'optionsPicker',
      label: 'Options Picker',
      control: 'OptionsPickerForCheckbox',
      value: [
        {
          id: 1,
          text: 'Small',
          checked: 'true',
        },
        {
          id: 2,
          text: 'Medium',
          checked: 'false',
        },
        {
          id: 3,
          text: 'Large',
          checked: 'false',
        },
      ],
      __typename: 'ComponentProperty',
    },
    {
      name: 'padding_8px_3',
      label: '',
      control: 'PaddingControl8px',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'padding',
      label: 'Padding',
      control: 'TitleDividerNoLine',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'paddingTop',
      label: 'Top',
      control: 'Slider',
      value: '20',
      __typename: 'ComponentProperty',
    },
    {
      name: 'paddingBottom',
      label: 'Bottom',
      control: 'Slider',
      value: '20',
      __typename: 'ComponentProperty',
    },
  ],
  __typename: 'Component',
});

import styled from 'styled-components';
import { Colors } from '../../Foundation/Colors';
import { Elevations } from '../../Foundation/Elevations';
import { Fonts } from '../../Foundation/Fonts';

import { ComponentBoxProps } from './DroppableTargetItems.types';

export const ComponentBox = styled.div<ComponentBoxProps>`
  padding: 13px;
  text-align: left;
  align-items: center;
  margin-bottom: 8px;
  display: flex;
  justify-content: flex-start;
  border: 1px solid ${Colors.grey_blue_2};
  border-radius: 8px;
  box-shadow: ${(props) => props.isDragging && Elevations.elevation_2};
  user-select: none;
  background: ${Colors.white};
`;

export const DragController = styled.div`
  z-index: 2;
  border-bottom-left-radius: 40px;
  border-top-left-radius: 40px;
  padding-top: 6px;
  width: 37px;
  height: 31px;
  line-height: 34px;
  text-align: center;
  color: ${Colors.white};
  background-color: ${Colors.accent};
  border: 1px ${Colors.accent} solid;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -39px;
  fill: ${Colors.white};
  transition: all 0.1s;
  opacity: 0;

  &:hover {
    background-color: ${Colors.black};
    border-color: ${Colors.black};
    color: ${Colors.white};
  }

  svg {
    margin-left: 2px;
  }
`;

type UtilButtonProps = {
  type?: 'button' | 'submit' | 'reset' | 'SubmitButton' | undefined;
};

export const UtilButton = styled.button<UtilButtonProps>`
  z-index: 2;
  border-bottom-right-radius: 50%;
  border-top-right-radius: 50%;
  margin-left: -2px;
  width: 38px;
  height: 39px;
  text-align: center;
  color: ${Colors.white};
  background-color: ${(props) =>
    props.type === 'SubmitButton' ? Colors.grey_blue_2 : Colors.accent};

  border: 1px
    ${(props) =>
      props.type === 'SubmitButton' ? Colors.grey_blue_2 : Colors.accent}
    solid;
  cursor: ${(props) =>
    props.type === 'SubmitButton' ? 'not-allowed' : 'pointer'};
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -39px;
  fill: ${Colors.white};
  transition: all 0.1s;
  opacity: 0;

  &:hover {
    background-color: ${(props) =>
      props.type === 'SubmitButton' ? Colors.grey_blue_2 : Colors.black};
    border-color: ${(props) =>
      props.type === 'SubmitButton' ? Colors.grey_blue_2 : Colors.black};
    color: ${Colors.white};
  }

  svg {
    margin-left: -2px;
  }
`;

export const EmptyPlaceholder = styled.div`
  .title {
    ${Fonts.headline}
    padding-bottom: 12px;
  }
  text-align: center;
  padding: 60px;
  color: ${Colors.accent}90;
  background-color: ${Colors.accent_2}60;
  border: 1px solid ${Colors.accent_2};
`;

export const ComponentBoxOnCanvas = styled(ComponentBox)`
  border-radius: 0;
  margin-bottom: 0;
  border: 1px ${(props) => (props.isSelected ? Colors.accent : Colors.white)}
    solid;
  text-align: left;
  padding: 0;
  position: relative;
  box-shadow: none;

  &:hover,
  &:focus {
    border-color: ${Colors.accent};
  }

  &:hover
    ${UtilButton},
    &:hover
    ${DragController},
    &:focus
    ${UtilButton},
    &:focus
    ${DragController} {
    opacity: 1;
  }
  .MuiInputBase-input,
  .MuiInputBase-root,
  .MuiButtonBase-root,
  .MuiIconButton-root,
  .dropdown {
    cursor: grab;
    pointer-events: none;
  }
`;

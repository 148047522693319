import styled from 'styled-components';
import { Colors } from '../../Foundation/Colors';
import { Fonts } from '../../Foundation/Fonts';

export const TovHighlightTooltipWrapper = styled.div<{
  toneIcon: string;
  isSelectedTone: boolean;
}>`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin: 4px 0px;

  .tone-display {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .tone {
    text-transform: capitalize;
    ${Fonts.caption}
    color: ${({ isSelectedTone }) =>
      isSelectedTone ? Colors.white : Colors.grey_blue_2};
    width: 56px;
    margin-right: 8px;
  }

  .pill {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    background-color: ${({ toneIcon }) =>
      toneIcon === 'StatusTick' ? Colors.success_light : Colors.grey_blue_1};
    border-radius: 4px;
    padding: 0px 8px;
    height: 16px;
  }

  .icon {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 10px;
    width: 10px;
    margin-right: 4px;
  }

  .intensity {
    color: ${({ toneIcon }) =>
      toneIcon === 'StatusTick' ? '#009E73' : Colors.grey_blue_4};
    box-sizing: border-box;
    text-transform: uppercase;
    ${Fonts.capsHeading}
    position: relative;
    top: 1px;
  }
`;

export const TovTextHighlight = styled.span<{
  selectedToneScore: number;
}>`
  background-color: ${({ selectedToneScore }) =>
    selectedToneScore < 0.66 ? Colors.accent_3 : '#AAAAFA'};
  &:hover {
    cursor: pointer;
    background-color: ${({ selectedToneScore }) =>
      selectedToneScore < 0.66 ? Colors.accent_4 : Colors.accent_4_5};
  }
  padding: 1px 0;
`;

export const StyledVariableChip = styled.span`
  background-color: ${Colors.white};
  padding: 1px 0;
`;

import styled from 'styled-components';

import { Colors } from '../../Foundation/Colors/Colors';
import { Icon } from '../../Atoms/Icon';
import { Fonts } from '../../Foundation/Fonts';

export const PlaceholderWrapper = styled.div`
  width: 100%;
  height: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid ${Colors.accent};
  background: ${Colors.accent_1};
  cursor: pointer;
`;

export const ImagePlaceholder = styled(Icon)`
  width: 60px;
  height: 60px;
  margin-bottom: 0.5rem;

  path {
    fill: ${Colors.accent_4};
  }
`;

export const Title = styled.div`
  ${Fonts.link}
`;

export const TitleWrapper = styled.div`
  margin-bottom: 0.5rem;
`;

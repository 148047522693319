import styled from 'styled-components';
import { Colors } from '../../Foundation/Colors';
import { Elevations } from '../../Foundation/Elevations';

import { SIDE_DRAWER_WIDTH } from '../../Foundation/Spacing';
import { StyledSideDrawerProps } from './SideDrawer.types';

export const StyledSideDrawer = styled.div<StyledSideDrawerProps>`
  :before {
    content: '';
    display: ${(props) => !props.fullScreen && 'none'};
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: ${(props) => props.dimmer && `${Colors.grey_blue}60`};
    transition: all 0.3s;
    pointer-events: none;
    z-index: 1;
  }

  & .MuiDrawer-paper {
    position: ${(props) => (props.fullScreen ? 'fixed' : 'absolute')};
    box-shadow: ${Elevations.elevation_1};
    min-width: ${SIDE_DRAWER_WIDTH};
    border: none;
  }
`;

import styled from 'styled-components';

import { CardButton } from '../../Atoms/Navigations/CardButton';
import { Colors } from '../../Foundation/Colors';
import { Fonts } from '../../Foundation/Fonts';

export const StyledPrebuiltTemplateCardButton = styled(CardButton)`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 24px 16px;
  width: 264px;

  .title {
    padding-top: 16px;
    ${Fonts.subheadSmall};
    color: ${Colors.grey_blue};
  }

  .svg {
    margin: 0 auto;
    width: 80px;
    height: 80px;
  }

  .text {
    ${Fonts.body};
    color: ${Colors.grey_blue_4};
    padding-top: 8px;
    padding-bottom: 16px;
    flex: 1;
  }
`;

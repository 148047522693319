import styled from 'styled-components';
import { Colors } from '../../Foundation/Colors';
import { Elevations } from '../../Foundation/Elevations';
import { Fonts } from '../../Foundation/Fonts';
import { TRANSITION_TIME } from '../../Foundation/Spacing';

import { StyledCardProps } from './Card.types';

export const StyledCard = styled.div<StyledCardProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 240px;
  height: 260px;
  background-color: ${Colors.white};
  border-radius: 8px;
  box-sizing: border-box;
  padding: 24px;
  box-shadow: ${Elevations.elevation_1};
  transition: all ${TRANSITION_TIME};
  position: relative;
  color: ${({ status }) =>
    status === 'ARCHIVED' ? Colors.grey_blue_4 : undefined};
  svg {
    fill: ${({ status }) =>
      status === 'ARCHIVED' ? Colors.grey_blue_4 : undefined};
  }

  z-index: ${({ hasFocus }) => hasFocus && '1000'};

  &:hover {
    cursor: ${({ status }) =>
      status === 'ARCHIVED' ? 'not-allowed' : 'pointer'};
    box-shadow: ${Elevations.elevation_3};
    transform: translateY(-4px);
  }

  .card-header {
    svg {
      height: 28px;
      width: 28px;
    }
  }

  .card-content {
    flex-grow: 2;
    margin-top: 22px;
    .card-title {
      ${Fonts.subheadMedium};
      padding: 27px 0 8px;
    }

    .card-creator {
      ${Fonts.caption};
      padding-top: 16px;
    }

    .card-modified {
      ${Fonts.caption};
      color: ${Colors.grey_blue_4};
      padding-top: 4px;
    }

    .MuiTooltip-tooltip {
      ${Fonts.body}
    }
  }

  .card-footer {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    margin-bottom: -12px;

    .card-status {
      color: ${({ status }) =>
        status === 'ACTIVE' ? Colors.accent : Colors.grey_blue_4};
      ${Fonts.capsHeading}
    }

    .options-button {
      border: 0;
      width: 40px;
      height: 40px;
      border-radius: 20px;
      padding: 8px;
      margin-right: -8px;
      outline: 0;
      transition: all ${TRANSITION_TIME};
      background: ${Colors.white};

      svg {
        fill: ${Colors.grey_blue};
        width: 24px;
        height: 24px;
      }

      &:hover {
        cursor: pointer;
        background-color: ${Colors.grey_blue_0};
      }

      &:focus {
        background-color: ${Colors.accent_2};
      }
    }

    .paper-popper {
      cursor: pointer;
    }

    .paper-option {
      padding: 12px 16px;
      box-shadow: none;
      cursor: pointer;
      ${Fonts.body}

      :hover {
        background-color: ${Colors.grey_blue_0};
      }
    }
  }
`;

import React from 'react';

import styled from 'styled-components';
import { Colors } from '../../../Foundation/Colors';
import { Fonts } from '../../../Foundation/Fonts';

import { Chip } from '../../DataDisplay/Chip/Chip';

export const StyledReactSelectChip = styled((props) => <Chip {...props} />)`
  margin: 4px;
  &.MuiChip-root {
    overflow: hidden;
    max-width: 230px;
  }
`;

/**
 * Use this function to apply consistent Whispir styling to react-select components, including their derivations (eg. react-select/creatable')
 * @param Component
 */
export const createStyledReactSelect = <T,>(
  Component: React.ComponentType<T>
) => styled((props) => (
  <Component classNamePrefix='styled-react-select' {...props} />
))`
  .styled-react-select__indicators {
    padding: 8px 12px 8px 0;
  }

  .styled-react-select__single-value {
    ${Fonts.body};
    padding-left: 8px;
  }

  .styled-react-select__clear-indicator {
    svg {
      background: ${Colors.grey_blue_2};
      border-radius: 50%;
      fill: ${Colors.white};
      width: 14px;
      height: 14px;
      :hover {
        cursor: pointer;
        fill: ${Colors.white};
        background: ${Colors.grey_blue_3};
      }
    }
  }

  .styled-react-select__control {
    min-height: 40px;
    border: none;
    background-color: ${Colors.grey_blue_0};
    border-color: transparent;
    box-shadow: none;
    outline: none;
    border-radius: 4px;
    border-bottom: 2px solid transparent;
    box-sizing: content-box;
    margin: 0;

    &:hover {
      border-bottom: 2px solid ${Colors.accent_dark};
    }
  }

  .styled-react-select__value-container {
    padding: 0;
  }

  .styled-react-select__menu {
    margin: 0;
  }

  .styled-react-select__control--is-focused {
    border-bottom: 2px solid ${Colors.accent};
    &:hover {
      border-color: ${Colors.accent};
    }
  }

  .styled-react-select__clear-indicator {
    padding: 0;
    padding-right: 8px;
  }

  .styled-react-select__placeholder {
    color: ${Colors.grey_blue_4};
    ${Fonts.body};
    margin-left: 8px;
  }

  .styled-react-select__input input {
    margin-left: 4px;
  }

  .styled-react-select__menu-notice--no-options {
    ${Fonts.caption};
  }

  .MuiChip-root {
    margin: 4px;
  }
`;

import styled from 'styled-components';
import { Colors } from '../../../Foundation/Colors';
import { TRANSITION_TIME } from '../../../Foundation/Spacing';

import { IconWrapperProps } from './ExpandIcon.types';

export const IconWrapper = styled.i<IconWrapperProps>`
  background-color: ${Colors.white};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  height: 24px;
  width: 24px;
  border: 1px solid ${Colors.grey_blue_1};
  box-sizing: border-box;

  svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin: auto;
    height: 16px;
    width: 16px;
    text-align: center;
    padding: ${({ isExpanded }) =>
      isExpanded ? '0 2px 0 1px' : '0 1px 0 2px'};
  }

  :hover {
    cursor: pointer;
    background-color: ${Colors.accent};
    svg {
      fill: ${Colors.white};
      transition: fill ${TRANSITION_TIME};
    }
  }
`;

import React from 'react';

import { SpinnerTypes } from './Spinner.types';
import { StyledSpinner } from './Spinner.styles';

export const Spinner = ({ size = 'large' }: SpinnerTypes) => {
  const propSizeToPixel = (() => {
    switch (size) {
      case 'small':
        return '16px';
      case 'medium':
        return '24px';
      case 'large':
        return '64px';
      default:
        return '64px';
    }
  })();

  return (
    <StyledSpinner
      aria-live='polite'
      aria-label='Do not refresh the page'
      size={propSizeToPixel}
    />
  );
};

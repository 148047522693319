import styled from 'styled-components';
import { Colors } from '../../Foundation/Colors';
import { Fonts } from '../../Foundation/Fonts';

export const StyledInfoCard = styled.div`
  position: relative;
  background-color: ${Colors.accent_5};
  padding: 24px 32px;
  border-radius: 8px;
  width: 504px;
  height: 272px;
  box-sizing: border-box;
`;

export const StyledHeading = styled.div`
  ${Fonts.headline};
  color: ${Colors.white};
  margin-bottom: 16px;
`;

export const StyledDescription = styled.div`
  ${Fonts.bodyLarge};
  color: ${Colors.accent_1};
  width: 376px;
`;

export const StyledIllustration = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  height: calc(100% - 16px);
  display: flex;
  align-items: flex-end;

  svg {
    height: 100%;
  }
`;

import styled from 'styled-components';
import { Colors } from '../../../Foundation/Colors';

import { Fonts } from '../../../Foundation/Fonts/Fonts';

export const StyledFormItem = styled.div`
  .label {
    ${Fonts.capsHeading};
    color: ${Colors.grey_blue_4};
    margin-bottom: 8px;
    display: block;
  }

  > * {
    margin-bottom: 8px;
  }

  margin: 32px 0;
`;

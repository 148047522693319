import { CircularProgress, withStyles } from '@material-ui/core';
import { Colors } from '../../../Foundation/Colors';

export const StyledSpinner = withStyles({
  root: {
    '& .MuiCircularProgress-svg': {
      color: Colors.accent,
    },
  },
})(CircularProgress);

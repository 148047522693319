export const richTextImageBlockModel = (id: string | number) => ({
  id,
  type: 'Image',
  label: 'Image',
  properties: [
    {
      name: 'text',
      label: 'Styles',
      control: 'TitleDivider',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'src',
      label: 'Image Source',
      control: 'VariableTextInput',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'padding_8px_1',
      label: '',
      control: 'PaddingControl8px',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'width',
      label: 'Max Width',
      control: 'Slider600',
      value: '600',
      __typename: 'ComponentProperty',
    },
    {
      name: 'imgLink',
      label: 'Image link',
      control: 'TextFieldFilled',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'altText',
      label: 'Alt Text',
      control: 'TextFieldFilled',
      value: 'Alt Description',
      __typename: 'ComponentProperty',
    },
    {
      name: 'padding_8px_2',
      label: '',
      control: 'PaddingControl8px',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'padding',
      label: 'Padding',
      control: 'TitleDividerNoLine',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'paddingTop',
      label: 'Top',
      control: 'Slider',
      value: '20',
      __typename: 'ComponentProperty',
    },
    {
      name: 'paddingBottom',
      label: 'Bottom',
      control: 'Slider',
      value: '20',
      __typename: 'ComponentProperty',
    },
  ],
  __typename: 'Component',
});

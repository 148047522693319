import React, { useState, useEffect, useRef } from 'react';

import { Popper, Paper, Chip } from '@material-ui/core';
import {
  ConditionPicker,
  createFilterLabel,
  ConditionProps,
} from '../ConditionPicker';

import { Icon } from '../../Atoms/Icon';
import {
  FilterWrapper,
  LabelWrapper,
  IconWrapper,
  StyledCaptionWrapper,
  StyledDateWrapper,
} from './LegacyFilterPicker.styles';

type Interval = ConditionProps & {
  oldOperator?: string | null;
};
type Props = {
  filterType: 'date';
  interval: Interval;
  onChange: (value: Interval) => void;
  onDoneClick?: (label: string) => void;
  variant?: 'normal' | 'filled';
};

const filterTypeMap = {
  date: {
    formControlType: 'FilterDatePicker',
    prefixIcon: <Icon icon='Date' />,
  },
};

const defaultFilterState = { operator: null, operand: null };

export const LegacyFilterPicker = ({
  filterType = 'date',
  onChange = () => undefined,
  onDoneClick,
  interval,
  variant = 'normal',
}: Props) => {
  const [filterState, setFilterState] = useState(
    interval || defaultFilterState
  );
  const [open, setOpen] = useState(false);
  const refChip = useRef(null);

  useEffect(() => {
    const conditionState = {
      operator: filterState.operator,
      operand: filterState.operand,
    };

    onChange(conditionState);
  }, [filterState]);

  const { formControlType, prefixIcon } = filterTypeMap[filterType];
  const filterLabel = createFilterLabel(filterState);

  const toggleOpen = () => {
    setOpen(!open);

    if (open && onDoneClick) {
      const { oldOperator } = filterState;
      onDoneClick(oldOperator ? oldOperator : 'Between');
    }
  };

  const renderLabel = () => (
    <LabelWrapper>
      <span>{filterLabel}</span>
    </LabelWrapper>
  );

  // Using oldOperator allows the default values to be restored correctly
  const conditionDefaultValues = {
    operator: filterState.oldOperator || filterState.operator,
    operand: filterState.operand,
  };

  const getClassNames = () => {
    let classNames = variant;
    classNames += open ? ' opened' : '';
    return classNames;
  };

  return (
    <FilterWrapper>
      <StyledCaptionWrapper className={`caption ${getClassNames()}`}>
        {filterLabel && filterLabel.includes('-') ? 'Date Range' : 'Date'}
      </StyledCaptionWrapper>
      <StyledDateWrapper className={getClassNames()}>
        <Chip
          label={renderLabel()}
          icon={<IconWrapper>{prefixIcon}</IconWrapper>}
          innerRef={refChip}
          onClick={toggleOpen}
          classes={{
            root: 'chip',
          }}
        />
        {variant === 'filled' ? (
          <Icon icon='DropdownArrow' class='dropdown' />
        ) : null}
      </StyledDateWrapper>
      <Popper
        disablePortal
        anchorEl={refChip.current}
        open={open}
        placement='bottom-start'
        className='fp-expression-popper'
      >
        <Paper>
          <ConditionPicker
            defaultValues={conditionDefaultValues}
            onChange={setFilterState}
            formControlType={formControlType}
            onClose={toggleOpen}
          />
        </Paper>
      </Popper>
    </FilterWrapper>
  );
};

LegacyFilterPicker.displayName = 'LegacyFilterPicker';

import { DraftailEntity } from '@whispir/draftail';
/**
 *
 * The types in the folder are meant to be specific types to draftail.
 * _not_ types specific for our use.
 *
 * There is a github issue requesting that they add a .d.ts to the project.
 * https://github.com/springload/draftail/issues/388
 */

// https://www.draftail.org/docs/inline-styles
export type DraftailInlineStyle = {
  type: string;
};

// https://www.draftail.org/docs/blocks
export type DraftailBlock = {
  type: string;
};

// https://www.draftail.org/docs/entities
export type DraftailEntity = {
  type: string;
  icon: any;
  source: any;
  decorator: any;
};

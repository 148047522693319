const { createCryptors } = require('@whispir/id-encryption');

const { encrypt, decrypt } = createCryptors({
  password: process.env.APPS_ENCRYPTION_PASSWORD || '',
  salt: process.env.APPS_ENCRYPTION_SALT || '',
  iterations: process.env.APPS_ENCRYPTION_ITERATIONS || '',
});

module.exports = {
  encrypt,
  decrypt,
};

import { IconButtonTypeMap, InputAdornmentTypeMap } from '@material-ui/core';
import { OverrideProps } from '@material-ui/core/OverridableComponent';
import styled from 'styled-components';
import { Colors } from '../../../Foundation/Colors';
import { Elevations } from '../../../Foundation/Elevations';
import { Fonts } from '../../../Foundation/Fonts';
import { TextFieldWrapperStyles } from '../TextField';

import { DatePickerPropTypes } from './DatePicker.types';

export const DatePickerWrapper = styled.div<DatePickerPropTypes>`
  display: flex;
  align-items: flex-end;
  ${TextFieldWrapperStyles}
  .MuiFormLabel-root {
    font-size: 12px;
    color: ${Colors.grey_blue_4};
  }
  &&& {
    /* Increasing the specificity by using the same class as there are no other classes */
    .MuiInputAdornment-root {
      margin-top: 0px;
    }

    .MuiInputBase-adornedStart {
      padding-left: 0px;
    }

    .MuiInputAdornment-positionStart {
      margin-right: 0px;
      & + input {
        padding-left: 0px;
      }
    }
  }

  .MuiFormControl-root .MuiFormHelperText-root {
    display: none;
  }
`;

export const materialThemeConfig = {
  palette: {
    primary: {
      main: Colors.accent,
    },
  },
  overrides: {
    MuiFormLabel: {
      root: {
        fontFamily: 'Inter',
        fontWeight: 500,
        fontSize: 14,
        lineHeight: '20px',
      },
    },
    MuiTypography: {
      body1: {
        fontFamily: `${Fonts.subheadSmall}`,
      },
    },
    MuiIconButton: {
      root: {
        '&.datepicker.left': {
          marginLeft: 18,
        },
        '&.datepicker.right': {
          marginRight: 18,
        },
      },
    },
    MuiPaper: {
      elevation8: {
        boxShadow: Elevations.elevation_2,
      },
      rounded: {
        borderRadius: 8,
      },
    },
  },
};

export const adornmentProps: Partial<
  OverrideProps<InputAdornmentTypeMap<unknown, 'div'>, 'div'>
> = {
  position: 'start',
  classes: {
    root: 'icon-position',
  },
};

export const arrowButtonProps: Partial<
  OverrideProps<IconButtonTypeMap<unknown, 'button'>, 'button'>
> = {
  size: 'small',
};

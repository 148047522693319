import { Colors } from '../../../Foundation/Colors';
import { ModelType } from '../../utilityTypes';

export const richArticleBlockModel = (id: string): ModelType => ({
  id,
  type: 'Article',
  label: 'Article Layout',
  properties: [
    {
      name: 'raw',
      label: 'raw',
      control: 'none',
      value: '[]',
      __typename: 'ComponentProperty',
    },
    {
      name: 'button group general',
      label: 'Layout',
      control: 'TitleDivider',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'imageBoth',
      label: 'Both Image',
      control: 'Checkbox',
      value: 'false',
      __typename: 'ComponentProperty',
    },
    {
      name: 'padding_8px_2',
      label: '',
      control: 'PaddingControl8px',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'textBoth',
      label: 'Both Text',
      control: 'Checkbox',
      value: 'false',
      __typename: 'ComponentProperty',
    },
    {
      name: 'padding_8px_3',
      label: '',
      control: 'PaddingControl8px',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'switchPosition',
      label: 'Switch Position',
      control: 'Checkbox',
      value: 'false',
      __typename: 'ComponentProperty',
    },
    {
      name: 'padding_8px_1',
      label: '',
      control: 'PaddingControl8px',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'styles',
      label: 'Styles',
      control: 'TitleDividerNoLine',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'textColor',
      label: 'Text Colour',
      control: 'ColorPicker',
      value: Colors.black,
      __typename: 'ComponentProperty',
    },
    {
      name: 'backgroundColor',
      label: 'Background Color',
      control: 'ColorPicker',
      value: Colors.grey_blue_0,
      __typename: 'ComponentProperty',
    },
    {
      name: 'alignment',
      label: 'Text Alignment',
      control: 'TextAlignmentSelector',
      value: 'left',
      __typename: 'ComponentProperty',
    },
    {
      name: 'padding_8px_4',
      label: '',
      control: 'PaddingControl8px',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'imageTitle',
      label: 'Image',
      control: 'TitleDividerNoLine',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'firstImageSrc',
      label: 'First Image Source',
      control: 'VariableTextInput',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'firstImageAlt',
      label: 'First Image Alt Text',
      control: 'TextFieldFilled',
      value: 'First Alt Description',
      __typename: 'ComponentProperty',
    },
    {
      name: 'secondImageSrc',
      label: 'Second Image Source',
      control: 'VariableTextInput',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'secondImageAlt',
      label: 'Second Image Alt Text',
      control: 'TextFieldFilled',
      value: 'Second Alt Description',
      __typename: 'ComponentProperty',
    },
    {
      name: 'padding_8px_8',
      label: '',
      control: 'PaddingControl8px',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'article padding',
      label: 'Padding',
      control: 'TitleDividerNoLine',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'padding',
      label: 'Inner',
      control: 'Slider',
      value: '20',
      __typename: 'ComponentProperty',
    },
    {
      name: 'paddingTop',
      label: 'Top',
      control: 'Slider',
      value: '20',
      __typename: 'ComponentProperty',
    },
    {
      name: 'paddingBottom',
      label: 'Bottom',
      control: 'Slider',
      value: '20',
      __typename: 'ComponentProperty',
    },
  ],
  __typename: 'Component',
});

import React from 'react';
import { Icon } from '../../Atoms/Icon';
import { VariableChip } from '../../Molecules/VariableChip';
import { StyledWebLinkReminderInfoPanel } from './WeblinkReminderInfoPanel.styles';

export type WebLinkReminderInfoPanelProps = {
  className?: string;
};

export const WeblinkReminderInfoPanel = (
  props: WebLinkReminderInfoPanelProps
) => {
  const { className } = props;
  return (
    <StyledWebLinkReminderInfoPanel className={className}>
      <Icon icon='InfoOutline' className='icon' />
      <span>Remember to insert</span>
      <VariableChip
        variable={{
          variableId: 'none',
          type: '',
          variableName: 'Web content link',
        }}
      />
    </StyledWebLinkReminderInfoPanel>
  );
};

import React, { ReactElement } from 'react';

import { Alert as MUIAlert } from '@material-ui/lab';

import { IconButton } from '../../Atoms/Navigations';
import { Icons } from '../../Foundation/Icons';
import { AlertWrapper } from './Alert.style';
import { AlertPropsTypes } from './Alert.types';

export const Alert = ({
  title,
  type,
  alert,
  content,
  onClose,
  ...other
}: AlertPropsTypes): ReactElement => {
  const isSimpleBanner = type === 'banner' && !title;
  const isTitledBanner = type === 'banner' && title;
  const isTitledInline = type === 'inline' && title;
  const isDismissableBanner = type === 'banner' && onClose;
  const { WarningOutline, InfoOutline, ErrorOutline, Tick, Tip } = Icons;

  return (
    <AlertWrapper className={`${type}`}>
      <MUIAlert
        classes={{
          root: `alert ${alert} ${isSimpleBanner && 'simple-banner'}`,
          icon: 'icon',
          message: 'message',
          action: 'action',
        }}
        icon={alert === 'tip' ? <Tip /> : undefined}
        onClose={onClose}
        action={
          isDismissableBanner && (
            <IconButton id='close' icon='Close' onClick={onClose} />
          )
        }
        variant='filled'
        severity={alert !== 'tip' ? alert : undefined}
        iconMapping={{
          warning: <WarningOutline />,
          error: <ErrorOutline />,
          info: <InfoOutline />,
          success: <Tick />,
        }}
        {...other}
      >
        <div className='content-wrapper'>
          {isTitledBanner && <div className='title'>{title}</div>}
          {isTitledInline && <span className='title'>{title}</span>}
          {content}
        </div>
      </MUIAlert>
    </AlertWrapper>
  );
};

import styled from 'styled-components';
import { ColorTypes, Colors } from '../../Foundation/Colors';

export const StyledColorIcon = styled.div<{
  color: ColorTypes;
}>`
  background-color: ${(props) => Colors[props.color]};
  height: 48px;
  width: 48px;
  border-radius: 50%;
  border: solid 2px ${Colors.white};

  display: inline-flex;
  justify-content: center;
  align-items: center;

  svg {
    fill: ${Colors.white};
  }
`;

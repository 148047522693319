import React, { PureComponent, Fragment } from 'react';

import { BLOCK_TYPE, INLINE_STYLE } from '@whispir/draftail';
import { Variable } from '@whispir/variables';
import { Icon } from '../../Atoms/Icon';
import { DraftailEditorWithVariables } from '../../DraftailComponents/DraftailEditorWithVariables';

import PlaceholderImage from './assets/PlaceholderImage';
import { DraftailEditorWrapper, ImageWrapper } from './RichArticleBlock.style';
import { RichArticleBlockProps } from './RichArticleBlock.types';

type State = {
  selectedVariable: Variable | null;
};

const textAreaBlockTypes = [
  { type: BLOCK_TYPE.UNSTYLED },
  { type: BLOCK_TYPE.HEADER_ONE },
  { type: BLOCK_TYPE.HEADER_TWO },
  { type: BLOCK_TYPE.HEADER_THREE },
  {
    type: BLOCK_TYPE.UNORDERED_LIST_ITEM,
    icon: <Icon icon='BulletedList' />,
  },
  {
    type: BLOCK_TYPE.ORDERED_LIST_ITEM,
    icon: <Icon icon='NumberedList' />,
  },
];

const textAreaInlineStyles = [
  { type: INLINE_STYLE.BOLD },
  { type: INLINE_STYLE.ITALIC },
  { type: INLINE_STYLE.UNDERLINE },
  { type: INLINE_STYLE.STRIKETHROUGH },
];

export class RichArticleBlock extends PureComponent<
  RichArticleBlockProps,
  State
> {
  // eslint-disable-next-line id-blacklist
  static defaultProps = {
    alignment: 'left',
  };

  constructor(props: RichArticleBlockProps) {
    super(props);
    this.state = {
      selectedVariable: null,
    };
  }

  componentDidUpdate(): void {
    const { focus, checkId } = this.props;
    // Overriding the beautiful-DND component library from stopping the focus shift out of the text editor components (Banner and Text components).
    // This is needed so that the Rich Text Editor component blurs when clicking a dnd component - hiding its toolbar
    const rteMenuSelector = document.querySelector<HTMLElement>(
      `.draft${checkId} .Draftail-Editor`
    );
    if (!focus) {
      if (rteMenuSelector) {
        rteMenuSelector.classList.remove('Draftail-Editor--focus');
      }
    }
  }

  onSaveHandler = (raw: unknown, idx: number): void => {
    const { onChange, checkId, raw: rawArr } = this.props;
    const parsedRaw = JSON.parse(rawArr);
    parsedRaw[idx] = raw;

    onChange(JSON.stringify(parsedRaw), checkId);
  };

  render(): React.ReactNode {
    const {
      raw,
      backgroundColor,
      textColor,
      alignment,
      padding,
      checkId,
      variableMenuData,
      focus,
      activeChannel,
      firstImageSrc,
      secondImageSrc,
      firstImageAlt,
      secondImageAlt,
      onImageChange = (value: unknown) => undefined,
      paddingTop,
      paddingBottom,
      switchPosition,
      imageBoth,
      textBoth,
    } = this.props;

    const firstImageSource =
      firstImageSrc !== '' ? firstImageSrc : PlaceholderImage;
    const secondImageSource =
      secondImageSrc !== '' ? secondImageSrc : PlaceholderImage;

    const handleOnLoadImage = (evt) => {
      const value = evt.target.naturalWidth;
      onImageChange(value);
    };

    // check if the component instance is inside message-studio-ui - with no variableMenuItems available (yet)
    // This will toggle the VariableMenu display in message-studio-ui
    const renderLayout = () => {
      const parsedRaw = JSON.parse(raw);

      const bothSideImage = imageBoth === 'true' && textBoth !== 'true';
      const bothSideText = textBoth === 'true' && imageBoth !== 'true';

      const variableGroups =
        activeChannel !== 'form' ? variableMenuData : undefined;

      const imageOnly = [
        <ImageWrapper key={firstImageSource}>
          <img
            src={firstImageSource}
            alt={firstImageAlt || ''}
            onLoad={handleOnLoadImage}
          />
        </ImageWrapper>,
      ];

      const textOnly = [
        <Fragment key='draftail-editor'>
          <DraftailEditorWithVariables
            blockTypes={textAreaBlockTypes}
            inlineStyles={textAreaInlineStyles}
            onSaveHandler={(raw) => this.onSaveHandler(raw, 1)}
            placeholder='Start typing here...'
            rawContentState={parsedRaw[1] || null}
            spellCheck
            includeLinkEntity
            variableGroups={variableGroups}
            variableButtonClassName='variable-selector'
            hasFocus={focus}
          />
        </Fragment>,
      ];

      if (bothSideImage) {
        return imageOnly.concat([
          <ImageWrapper key='bothSideImage'>
            <img
              src={secondImageSource}
              alt={secondImageAlt || ''}
              onLoad={handleOnLoadImage}
            />
          </ImageWrapper>,
        ]);
      }

      if (bothSideText) {
        return textOnly.concat([
          <DraftailEditorWithVariables
            key='bothSideText'
            blockTypes={textAreaBlockTypes}
            inlineStyles={textAreaInlineStyles}
            includeLinkEntity
            onSaveHandler={(raw) => this.onSaveHandler(raw, 0)}
            placeholder='Start typing here...'
            rawContentState={parsedRaw[0] || null}
            spellCheck
            variableGroups={variableGroups}
            variableButtonClassName='variable-selector'
            hasFocus={focus}
          />,
        ]);
      }

      return [...imageOnly, ...textOnly];
    };

    const layout = renderLayout();
    return (
      <DraftailEditorWrapper
        className={`draft${checkId}`}
        key={checkId}
        textColor={textColor}
        alignment={alignment}
        backgroundColor={backgroundColor}
        padding={padding}
        paddingTop={paddingTop}
        paddingBottom={paddingBottom}
        imageBoth={imageBoth}
        textBoth={textBoth}
      >
        {switchPosition === 'true' ? layout.reverse() : layout}
      </DraftailEditorWrapper>
    );
  }
}

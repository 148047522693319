import { withStyles } from '@material-ui/core';

import MuiAlert from '@material-ui/lab/Alert';
import { Colors } from '../../Foundation/Colors';
import { Elevations } from '../../Foundation/Elevations';

export const StyledSnackbar = withStyles({
  message: {
    'font-family': 'Inter',
    'font-weight': 400,
    'font-size': '14px',
    'line-height': '22px',
    color: Colors.white,
    padding: '0',
    'word-break': 'break-word',
  },
  root: {
    padding: 0,
    background: Colors.grey_blue,
    'box-shadow': Elevations.elevation_3,
    overflow: 'hidden',
    'border-radius': '8px',
    'max-width': '568px',

    '& .message': {
      flex: '1',
      padding: '16px',
    },

    '& .MuiAlert-message': {
      display: 'flex',
    },

    '& .MuiAlert-icon': {
      display: 'none',
    },

    '& .icon': {
      width: '48px',
      display: 'flex',
      'align-items': 'center',
      'justify-content': 'center',
    },

    '& .exit-button': {
      cursor: 'pointer',
      border: 'none',
      outline: 'none',
      padding: '0',
      display: 'flex',
      'align-items': 'center',
      'margin-right': '16px',
    },

    '& svg': {
      fill: Colors.white,
      width: '24px',
    },

    '& .error': {
      'background-color': Colors.error,
    },

    '& .success': {
      'background-color': Colors.success,
    },

    '& .warning': {
      'background-color': Colors.warning,
    },

    '& .info': {
      'background-color': Colors.grey_blue_4,
    },

    '& .loading': {
      'background-color': Colors.white,
    },
  },
})(MuiAlert);

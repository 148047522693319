/* eslint-disable @typescript-eslint/no-explicit-any */
// We don't know how to type these from DraftJS
import { useEffect, useState } from 'react';

import { useDebouncedCallback } from 'use-debounce';

import {
  convertToRaw,
  EditorState,
  convertFromRaw,
  CompositeDecorator,
  RawDraftContentState,
} from 'draft-js';

import {
  convertLegacyEntity,
  toHTML,
  TovHighlightEntityType,
} from '../draftailUtils';
import { TovHighlight } from '../DraftailTovHighlight';
import { VariablePill } from './VariablePill';

const findEntities = (type: string) => (
  contentBlock: any,
  callback: any,
  contentState: any
) => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();

    return (
      entityKey !== null && contentState.getEntity(entityKey).getType() === type
    );
  }, callback);
};

const decorators = new CompositeDecorator([
  {
    strategy: findEntities('VARIABLE'),
    component: VariablePill,
  },
  {
    strategy: findEntities(TovHighlightEntityType),
    component: TovHighlight,
  },
]);

/**
 * BEGIN LEGACY FUNCTIONALITY
 *
 * Some workflow definitions have been saved with Draftail entities that contain legacy data.
 *
 * Given that the shape of the entity data is unlikely to change much over time, we will continue
 * to support the legacy data shape for now.
 */
export const useLegacyFunctionality = ({ rawContentState }) => {
  let parsedRawContentState = rawContentState;

  if (rawContentState) {
    const { entityMap } = rawContentState;

    const parsedEntityMap = Object.entries(entityMap).reduce(
      (acc, [id, entity]) => {
        const { type } = entity;

        let parsedEntity = entity;
        if (type === 'FORM_VAR' || type === 'SYSTEM_VAR') {
          parsedEntity = convertLegacyEntity(entity);
        }

        return { ...acc, [id]: parsedEntity };
      },
      {}
    );

    parsedRawContentState = {
      ...rawContentState,
      entityMap: parsedEntityMap,
    };
  }

  return {
    parsedRawContentState,
  };
};
/**
 * END LEGACY FUNCTIONALITY
 */

export const useControlled = ({
  onSaveHandler,
  parsedRawContentState,
  valueToUpdateOn,
}: {
  valueToUpdateOn: any;
  parsedRawContentState: RawDraftContentState;
  onSaveHandler: (raw: unknown) => void;
}) => {
  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createEmpty()
  );
  const handleSaveDebounced = useDebouncedCallback(onSaveHandler, 250);
  const handleEditorChange = (currentEditorState) => {
    const convertedRawState = convertToRaw(
      currentEditorState.getCurrentContent()
    );
    const convertedToHTML = toHTML(convertedRawState);
    if (convertedToHTML !== '<p></p>') {
      handleSaveDebounced(convertedToHTML);
    } else {
      handleSaveDebounced('');
    }
    setEditorState(currentEditorState);
  };

  useEffect(() => {
    const mountedEditorState = EditorState.createWithContent(
      convertFromRaw(parsedRawContentState),
      decorators
    );

    setEditorState(mountedEditorState);
    handleEditorChange(mountedEditorState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const alreadyFoundBlockKeys: Record<string, boolean> = {};
    const currentBlocks = Object.values(
      editorState.getCurrentContent().getBlockMap().toJS()
    );

    const parsedRawContentWithUpdatedBlockKeys = {
      ...parsedRawContentState,
      blocks: parsedRawContentState?.blocks.map((rawBlock) => {
        const foundExistingBlock = currentBlocks.find(
          ({ text, key }) =>
            !(key in alreadyFoundBlockKeys) && text === rawBlock.text
        );

        if (foundExistingBlock) {
          alreadyFoundBlockKeys[foundExistingBlock.key] = true;

          return {
            ...rawBlock,
            key: foundExistingBlock.key,
          };
        }

        return rawBlock;
      }),
    };

    const newEditorState = EditorState.createWithContent(
      convertFromRaw(parsedRawContentWithUpdatedBlockKeys),
      decorators
    );

    setEditorState(
      EditorState.set(
        EditorState.push(
          editorState,
          editorState
            .getCurrentContent()
            .merge(newEditorState.getCurrentContent()),
          'apply-entity'
        ),
        { decorator: decorators }
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueToUpdateOn]);

  return {
    editorState,
    handleEditorChange,
  };
};

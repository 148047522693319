import React from 'react';

import { FeedbackType } from '../../Utils/ToneOfVoice/toneOfVoiceTypes';
import { Icon } from '../../Atoms/Icon';
import { Tooltip } from '../../Atoms/Dialogs';
import {
  toneCardDataArray,
  toneScoreHighDescription,
} from '../../Utils/exampleData';
import { getTovIconLight } from '../../Utils/ToneOfVoice/toneOfVoiceUtils';
import {
  StyledToneScoreCard,
  StyledEmoji,
  StyledHeading,
  StyledDescription,
  StyledHeadingText,
  StyledFeedbackIcons,
  ScoreTooltip,
  StyledIcon,
} from './ToneScoreCard.style';
import { ToneScoreCardType } from './ToneScoreCard.types';

type GetDescriptionType = Pick<
  ToneScoreCardType,
  'desiredToneIntensity' | 'messageToneIntensity' | 'toneScore'
>;

const getDescriptionType = ({
  desiredToneIntensity,
  messageToneIntensity,
  toneScore,
}: GetDescriptionType) => {
  if (desiredToneIntensity === 'Moderate') {
    return messageToneIntensity === 'Low' ? 'lower' : 'higher';
  }

  return toneScore === 1 ? 'higher' : 'lower';
};

export const ToneScoreCard = ({
  tone,
  toneScore,
  desiredToneIntensity,
  messageToneIntensity,
  setFeedbackState,
  handleFocus,
  selected = false,
  includeFeedback = false,
  feedback,
  disabled = false,
}: ToneScoreCardType): JSX.Element => {
  const toneData = toneCardDataArray.find((entry) => {
    return entry.tone === tone;
  });

  const emoji = toneData && toneData.data.emoji;

  const getDescription = () => {
    if (toneScore === 2) return toneScoreHighDescription;

    const descriptionType = getDescriptionType({
      desiredToneIntensity,
      messageToneIntensity,
      toneScore,
    });

    if (toneData && toneData?.data?.descriptions && descriptionType) {
      return toneData.data.descriptions[desiredToneIntensity.toLowerCase()][
        descriptionType
      ];
    }
  };

  const handleFeedbackClick = (userFeedback: FeedbackType) => {
    setFeedbackState(userFeedback !== feedback ? userFeedback : undefined);
  };

  return (
    <StyledToneScoreCard
      data-testid='ToneScoreCard'
      selected={selected}
      includeFeedback={includeFeedback}
      onClick={() => {
        if (!disabled) {
          handleFocus(tone);
        }
      }}
      disabled={disabled}
    >
      <StyledHeading>
        <StyledEmoji>{emoji}</StyledEmoji>
        <StyledHeadingText>{tone}</StyledHeadingText>
        {disabled ? (
          <StyledIcon toneScore={toneScore}>
            <Icon
              icon={getTovIconLight(desiredToneIntensity, messageToneIntensity)}
              aria-label={`Icon ${toneScore}`}
            />
          </StyledIcon>
        ) : (
          <Tooltip
            arrow
            ariaLabel='Tone Intensities'
            title={
              <ScoreTooltip>
                <div>
                  Desired Tone:
                  <span className='tone-score-tooltip'>
                    {` ${desiredToneIntensity}`}
                  </span>
                </div>
                <div>
                  Message Average:
                  <span className='tone-score-tooltip'>
                    {` ${messageToneIntensity}`}
                  </span>
                </div>
              </ScoreTooltip>
            }
            placement='top-end'
            offset='12, 0'
            padding='8px 16px'
          >
            <StyledIcon toneScore={toneScore}>
              <Icon
                icon={getTovIconLight(
                  desiredToneIntensity,
                  messageToneIntensity
                )}
                aria-label={`Icon ${toneScore}`}
              />
            </StyledIcon>
          </Tooltip>
        )}
      </StyledHeading>

      <StyledDescription>{getDescription()}</StyledDescription>
      <StyledFeedbackIcons feedback={feedback} className='feedback-icons'>
        <Tooltip
          arrow
          ariaLabel='Positive tone feedback tooltip'
          title='This tone seems right.'
          placement='top'
          padding='8px 16px'
        >
          <span
            aria-label='Positive tone feedback icon'
            className='feedback-icon ThumbsUp-icon'
            onClick={() => handleFeedbackClick('positive')}
          >
            <Icon icon='ThumbsUp' />
          </span>
        </Tooltip>
        <Tooltip
          arrow
          ariaLabel='Negative tone feedback tooltip'
          title={"This tone doesn't seem right."}
          placement='top'
          padding='8px 16px'
        >
          <span
            aria-label='Negative tone feedback icon'
            className='feedback-icon ThumbsDown-icon'
            onClick={() => handleFeedbackClick('negative')}
          >
            <Icon icon='ThumbsDown' />
          </span>
        </Tooltip>
      </StyledFeedbackIcons>
    </StyledToneScoreCard>
  );
};

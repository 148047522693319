import styled from 'styled-components';
import { Colors } from '../../Foundation/Colors';
import { Fonts } from '../../Foundation/Fonts';

export const MessageCountWrapper = styled.span`
  padding-right: 8px;
  ${Fonts.bodySmall};
`;

export const CharacterCount = styled.span<{ error: boolean }>`
  ${Fonts.bodySmall};
  color: ${(props) => (props.error ? Colors.error : Colors.grey_blue_4)};
`;

export const FooterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  font-size: 12px;
  color: ${Colors.grey_blue_3};
  padding: 8px 24px 0 24px;
`;

export const ThemeTransition = (factor, targets = 'all') =>
  `${targets} ${(Math.random() * (factor - 0.5) + 0.5).toFixed(
    1
  )}s ease-in-out`;

export const rolloverTransition = (type: string): string => `
  transition: ${type} 250ms;
`;

export const slideTransition = (type: string): string => `
  transition: ${type} 225ms cubic-bezier(0, 0, 0.2, 1);
`;

export const transitionStyle = '0.2s ease';

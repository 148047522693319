import styled from 'styled-components';

import { Slider } from '@material-ui/core';

import { Colors } from '../../Foundation/Colors';
import { Fonts } from '../../Foundation/Fonts';
import { NumericSliderProps, SliderLabelProps } from './NumericSlider.types';

export const NumericSliderWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const SliderHeading = styled.div`
  ${Fonts.bodyLarge};
  color: ${Colors.grey_blue_4};
  display: flex;
  padding-bottom: 20px;
`;

export const SliderWrapper = styled(Slider)`
  && {
    display: inline-flex;
    margin: 0 auto;
  }

  .MuiSlider-root {
    height: 8px;
  }

  .MuiSlider-rail {
    height: 8px;
    border-radius: 100px;
    background-color: ${(props: NumericSliderProps) => props.barColor};
    opacity: 1;
    bottom: 50%;
    transform: translateY(50%);
  }

  .MuiSlider-track {
    height: 8px;
    background-color: ${(props: NumericSliderProps) => props.sliderColor};
    border-radius: 100px;
    bottom: 50%;
    top: auto;
    transform: translateY(50%);
  }

  .MuiSlider-thumb,
  .MuiSlider-thumb.Mui-disabled {
    width: 20px;
    height: 20px;
    background-color: ${(props: NumericSliderProps) => props.sliderColor};
    bottom: 50%;
    transform: translate(-50%, 50%);
    margin-left: 0px;
    &:focus, &:hover, &$active: {
      box-shadow: inherit;
    }
  }

  .MuiSlider-active {
    height: 8px;
  }

  .MuiSlider-markLabel {
    color: #aaaaaa;
    padding-top: 8px;
    ${Fonts.caption};
    &[data-index='0'] {
      transform: translateX(0px);
    }
    &[data-index='10'] {
      transform: translateX(-100%);
    }
  }

  .MuiSlider-mark {
    width: 0;
  }
`;

export const SliderLabel = styled.div`
  ${Fonts.body};
  color: ${Colors.grey_blue};
  padding-top: 35px;
  padding-bottom: 8px;
  align-self: ${(props: SliderLabelProps) => props.position};
  margin: 0;
`;

export const SliderLabelWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const HelperText = styled.div`
  ${Fonts.caption};
  margin-top: 8px;
  color: ${Colors.grey_blue_4};
  text-align: left;
  align-self: stretch;
`;

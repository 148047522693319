import styled from 'styled-components';

import { Radio } from '@material-ui/core';
import { Colors } from '../../../Foundation/Colors';

export const RadioStyle = styled(Radio)`
  &.MuiRadio-colorSecondary {
    &.Mui-checked {
      color: ${Colors.accent};
    }
    &.Mui-checked:hover,
    &:hover {
      background-color: ${Colors.grey_blue_0};
    }
  }
`;

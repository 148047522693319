import React from 'react';
import { ENTITY_TYPE } from '@whispir/draftail';
import { convertFromHTML, convertToHTML } from 'draft-convert';
import { convertFromRaw, convertToRaw } from 'draft-js';
import { DraftType } from '../Contexts/global.types';

export const TovHighlightEntityType = 'TOV_HIGHLIGHT';

/**
 * WARNING
 * THIS FILE/CODE EXISTS IN TWO PLACES
 *
 *  html-transformers (utils/index.js)
 *  ui-lib-v2  (molecules/GenericTextFieldWithVariables/draftailUtils.tsx)
 *
 * I tried pulling it out into its own package.
 * But due to an issue with ui-lib-v2 we can't import any monorepo packages into ui-lib-v2 -see :
 * https://bitbucket.org/whispirdevops/sparx/issues/1/prestart-postinstall-scripts-on-ui-lib-v2
 *
 * When we resolve that issue we can probably keep this code in HTML transformers, and import it into ui-lib-v2.
 *
 */
const importerConfig = {
  htmlToEntity: (nodeName, node, createEntity) => {
    if (nodeName === 'a') {
      return createEntity(ENTITY_TYPE.LINK, 'IMMUTABLE', { src: node.href });
    }

    if (nodeName === 'span' && node.dataset.systemvariable) {
      return createEntity('VARIABLE', 'IMMUTABLE', {
        variableId: node.dataset.systemvariable,
        variableName: node.textContent,
        variableType: 'SYSTEM_VAR',
      });
    }

    if (nodeName === 'span') {
      return createEntity('VARIABLE', 'IMMUTABLE', {
        stepId: node.dataset.stepid,
        variableId: node.dataset.variableid,
        variableName: node.textContent,
        variableType: 'FORM_VAR',
      });
    }

    return null;
  },
};

/**
 * The following block of code converts Draftail entities that are of the shape:
 *
 * {
 *   "type": "FORM_VAR",
 *   "mutability": "IMMUTABLE",
 *   "data": {
 *     "stepID": "0",
 *     "variableId": "5f98467d-a920-40a8-b62d-91758a9bd182"
 *   }
 * }
 *
 * to:
 *
 * {
 *   "type": "VARIABLE",
 *   "mutability": "IMMUTABLE",
 *   "data": {
 *     "type": "FORM_VAR",
 *     "stepID": "0",
 *     "variableId": "5f98467d-a920-40a8-b62d-91758a9bd182"
 *   }
 * }
 *
 */
export const convertLegacyEntity = (entity: DraftType): DraftType => ({
  ...entity,
  type: 'VARIABLE',
  data: {
    ...entity.data,
    type: entity.type,
  },
});

/**
 * The same exporterConfig is duplicated in html-transformer.
 * It is kept this way to prevent establishing dependencies between the two projects
 *  */

const exporterConfig = {
  // draft-js doesn't provide strikethrough styling, so we need to provide a custom styleToHTML.
  styleToHTML: (style) => {
    if (style === 'STRIKETHROUGH') {
      return <span style={{ textDecoration: 'line-through' }} />;
    }
  },
  entityToHTML: (entity, originalText) => {
    // LEGACY FUNCTIONALITY!
    // Existing tests, and workflow defintions actually save the draftjs blocks as part of the defintion
    // See: packages/html-transformers/src/web/templates/richTextTemplate.test.js for example
    // Here, if the saved entity type is one of the older ones, we set the variableType here.
    const { type } = entity;

    let parsedEntity = entity;
    /**
     * BEGIN LEGACY FUNCTIONALITY
     *
     * Some Draftail entities have been saved with legacy data.
     *
     * Given that the shape of the entity data is unlikely to change much over time, we will continue
     * to support the legacy data shape for now.
     */
    if (type === 'FORM_VAR' || type === 'SYSTEM_VAR') {
      parsedEntity = convertLegacyEntity(entity);
    }
    /**
     * END LEGACY FUNCTIONALITY
     */

    if (type === 'LINK') {
      const { src } = entity.data;
      return src ? (
        <a
          href={src}
          title={src}
          className='Link'
          style={{ color: 'inherit', textDecoration: 'underline' }}
          target='_blank'
          rel='noreferrer'
        >
          {originalText}
        </a>
      ) : (
        originalText
      );
    }

    const {
      variableId,
      stepId,
      variableType,
      variableName,
    } = parsedEntity.data;
    const isVariableInvalid = parsedEntity.data.isError;
    if (variableType === 'FORM_VAR') {
      if (isVariableInvalid) {
        return (
          <span
            data-stepid={stepId}
            data-variableid={variableId}
            className='isError'
          >
            {variableName}
          </span>
        );
      }
      return (
        <span data-stepid={stepId} data-variableid={variableId}>
          {variableName}
        </span>
      );
    }

    if (variableType === 'SYSTEM_VAR') {
      return <span data-systemvariable={variableId}>{variableName}</span>;
    }
    return originalText;
  },
};

export const toHTML = (value: unknown): string => {
  return value ? convertToHTML(exporterConfig)(convertFromRaw(value)) : '';
};

export const fromHTML = (html: string): unknown =>
  convertToRaw(convertFromHTML(importerConfig)(html));

export const contentToHTML = (value: unknown): string =>
  value ? convertToHTML(exporterConfig)(value) : '';

/**
 * This function responsibles for checking "used" variables from the "rawContentState" and compare them with the
 * "variableGroups" (a.k.a availableVariables) to mark variables that might have been deleted.
 */
export const validateRawContentStateForInvalidVariables = ({
  rawContentState,
  variableGroups,
}) => {
  if (!variableGroups || !Array.isArray(variableGroups)) {
    return rawContentState;
  }

  const copiedRawContentState = rawContentState;

  // always flatten the variable groups
  const flattenedVariableGroups = variableGroups.reduce((acc, cur) => {
    return [...acc, ...cur.variables];
  }, [] as Array<UIVariable>);

  const entityMapWithInvalidVariables =
    copiedRawContentState &&
    Object.entries(copiedRawContentState.entityMap).reduce(
      (acc, [key, entity]) => {
        if (entity.type === 'VARIABLE') {
          const variableFound = flattenedVariableGroups.find(
            (variable) => variable.variableId === entity.data.variableId
          );

          // if the variable can't be found from variable groups, add 'isError' attribute
          if (variableFound === undefined) {
            return {
              ...acc,
              [key]: { ...entity, data: { ...entity.data, isError: true } },
            };
          }
          return {
            ...acc,
            [key]: entity,
          };
        }
        return {
          ...acc,
          [key]: entity,
        };
      },
      {}
    );

  return {
    ...rawContentState,
    entityMap: entityMapWithInvalidVariables,
  };
};

import { parse, getRootStep } from '@whispir/workflow-definition';

// This function to determine what the trigger type is a bit redundant.
export const getWorkflowTriggerType = (definition) => {
  const parsedDefinition = parse(definition);
  const startNode = getRootStep(parsedDefinition);

  if (startNode && startNode.data && startNode.data.triggers) {
    return startNode.data.triggers[0].type;
  }

  throw new Error('No trigger type found');
};

export const getWorkflowCardMenuItems = (whitelist, definition) => {
  const triggerType = getWorkflowTriggerType(definition);
  // remove "copy webform url" from the card menu if it's manual triggered workflow
  if (triggerType === 'manual') {
    const res = whitelist.filter((menuItem) => menuItem !== 'copy webform url');
    return res;
  }

  return whitelist;
};

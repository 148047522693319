import React, { ReactNode } from 'react';
import { Icon } from '../../Atoms/Icon';
import { Button } from '../../Atoms/Navigations/Button';
import { HeaderTitle } from '../HeaderTitle';
import { StyledModalHeader } from './ModalHeader.styles';

type ModalHeaderProps<T extends ReactNode> = {
  title: T;
  onCloseClick?: () => void;
  closeButtonText?: string;
  actions?: ReactNode;
  closeButtonDisabled?: boolean;
  closeButtonChildren?: JSX.Element;
  onTitleChange?: (value: string) => void;
  isTitleEditable?: T extends string ? boolean : false;
  showLogo?: boolean;
  backButtonText?: string;
  onBackClick?: () => void;
};

export const ModalHeader = <T extends ReactNode>(
  props: ModalHeaderProps<T>
) => {
  const {
    title,
    onCloseClick,
    closeButtonText = '',
    actions,
    closeButtonDisabled = false,
    closeButtonChildren,
    onTitleChange,
    isTitleEditable,
    showLogo = true,
    backButtonText,
    onBackClick,
  } = props;

  if (typeof title !== 'string' && isTitleEditable) {
    throw new Error('Editable title can only be used with string type titles');
  }

  return (
    <StyledModalHeader>
      {showLogo && <Icon className='modalHeader-icon' icon='LogoDots' />}
      <div className='modalHeader-title'>
        {backButtonText && (
          <div className='back-button'>
            <Button
              text={backButtonText}
              size='large'
              startIcon='ChevronLeft'
              onClick={onBackClick}
              type='primary'
              aria-label='back to previous'
              variant='link'
            />
          </div>
        )}
        <HeaderTitle
          text={title}
          onChange={({ target: { value } }) =>
            onTitleChange && onTitleChange(value)
          }
          isEditable={isTitleEditable}
        />
      </div>
      <div className='modalHeader-actions'>
        {actions && actions}
        {(closeButtonText || closeButtonChildren) && (
          <Button
            className='modalHeader-close-button'
            text={closeButtonText || ''}
            color='primary'
            onClick={onCloseClick}
            size='large'
            disabled={closeButtonDisabled}
          >
            {closeButtonChildren}
          </Button>
        )}
      </div>
    </StyledModalHeader>
  );
};

/* eslint-disable no-unused-expressions */

import React, { PureComponent } from 'react';
import { messageCharacterLimit, messageTotalLimit } from '@whispir/utils-js';
import { MessageCounter } from '../../Molecules/MessageCounter';
import { SmartTagList, SmartTagListEmpty } from './smartTagData';
import { Hint } from './Hint';
import { SmartTagPicker } from './SmartTagPicker';
import { SmsPanelWrapper, TextareaField } from './SmsPanelOriginal.style';

type Props = {
  messageTemplate: string;
  updateLocalDraft: (...args: Array<unknown>) => unknown;
  channelName: string;
  draft: string;
  hasCharacterCount?: boolean;
  hasSmartTag?: boolean;
  hasHint?: boolean;
};

type State = {
  isDirty: Array<unknown>;
  smartTagActive: boolean;
  smartTagListObj: unknown;
  message: string;
};

/**
 *
 * THIS COMPONENT ISN'T ACTUALLY BEING USED ANYWHERE
 *
 * IT IS IN
 * message-studio-ui/.../facebook chanel | emailchaneel
 *
 * But those aren't actually being used
 *
 * TODO: clean up sometime.
 *
 * @legacy
 * This is a legacy component
 *
 * It has been migrated to ui-lib-v2, because it is importing MessageCounter, which is also used by SmsPreviewDisplay, which was back-importing from ui-lib
 *
 */
export class SmsPanelOriginal extends PureComponent<Props, State> {
  state = {
    message: '',
    isDirty: [],
    smartTagActive: false,
    smartTagListObj: SmartTagList,
  };

  componentDidMount() {
    this.updateState();
  }

  currentTextArea = null;

  activeTagStartPos = -1;

  searchText = '';

  updateState = () => {
    const { draft } = this.props;
    this.setState({ message: draft });
  };

  tagsShouldOpen = (value: string, pos: number) => {
    // check if before cursor pos there is a @@
    const smartTagTrigger = ' @@';
    const { smartTagActive } = this.state;

    // if space @@ has been typed - open it
    if (!smartTagActive && value.slice(pos - 2, pos + 1) === smartTagTrigger) {
      this.activeTagStartPos = pos + 1;
      return true;
    }

    // if no other text exists - override space@@ smartTagTrigger rule
    if (value === '@@') {
      this.activeTagStartPos = 2;
      return true;
    }

    // if typing ahead of start position - open it
    if (smartTagActive && pos + 1 >= this.activeTagStartPos) {
      return true;
    }

    // if deleting back from @@ to @ - close it
    if (smartTagActive && pos + 1 < this.activeTagStartPos) {
      this.activeTagStartPos = -1;
      return false;
    }

    return false;
  };

  tagSearch = (value: string, cursorPosition: number) => {
    const currentValueToFilter = value
      .slice(this.activeTagStartPos, cursorPosition + 1)
      .toLowerCase();

    let newSmartTagListObj: any = {};

    this.searchText = currentValueToFilter;

    // if the search value has any spaces turn it off
    /\s/.test(currentValueToFilter) || /[@]+/.test(currentValueToFilter)
      ? this.setState({ smartTagActive: false }, () => {
          this.activeTagStartPos = -1;
        })
      : // re construct smartag object with search highlights
        (newSmartTagListObj =
          currentValueToFilter === ''
            ? SmartTagList
            : SmartTagList.reduce((accumulator, item) => {
                if (item.tagName.includes(currentValueToFilter)) {
                  const markedItem = item.tagName.replace(
                    new RegExp(currentValueToFilter, 'g'),
                    `<mark>${currentValueToFilter}</mark>`
                  );
                  accumulator.push({
                    tagName: markedItem,
                    tagDescription: item.tagDescription,
                  });
                }
                return accumulator;
              }, [] as Array<any>));

    newSmartTagListObj.length > 0
      ? this.setState({ smartTagListObj: newSmartTagListObj })
      : this.setState({ smartTagListObj: SmartTagListEmpty });
  };

  handleChosenSmartTag = (tagTitle: string) => {
    const { updateLocalDraft } = this.props;
    const target = this.currentTextArea;
    const name = target.getAttribute('data-tab');
    const currentTextAreaContent = target.value;

    // get text on the left side of the tag injection
    const left = currentTextAreaContent.slice(0, this.activeTagStartPos);

    // get text on the right side of the tag injection
    const right = currentTextAreaContent.slice(
      this.activeTagStartPos + this.searchText.length,
      currentTextAreaContent.length
    );

    // put it all back together again
    // remove highlighing markup from injection
    const regex = /(<([^>]+)>)/gi;
    const cleanTagTitle = tagTitle.replace(regex, '');
    const newContent = `${left}${cleanTagTitle}@@${right}`;

    this.setState(
      {
        message: newContent,
        smartTagActive: false,
        smartTagListObj: SmartTagList,
      },
      () => {
        // re-focus on texteara and place cursor at end of injection
        if (target) {
          target.focus();
          target.selectionEnd =
            this.activeTagStartPos + cleanTagTitle.length + 2;
        }
      }
    );

    updateLocalDraft(name, newContent);
  };

  handleKeyPressWithinTextarea = (event: SyntheticEvent<HTMLInputElement>) => {
    const { hasSmartTag } = this.props;
    if (hasSmartTag) {
      // close search when esc or spacebar is pressed
      if (event.which === 27 || event.which === 13) {
        this.setState({
          smartTagActive: false,
          smartTagListObj: SmartTagList,
        });
      }
      // place focus on first item in search when arrowdown is pressed
      if (event.which === 40) {
        document.querySelector('.smart-tag[tabIndex="0"]') &&
          document.querySelector('.smart-tag[tabIndex="0"]').focus();
      }
    }
  };

  onTextareaChanged = (event: SyntheticEvent<HTMLInputElement>) => {
    const { target = {} } = event;
    const name = target.getAttribute('data-tab') || target.name;

    this.currentTextArea = target;
    const { value } = target;
    const { updateLocalDraft, hasSmartTag } = this.props;
    const { isDirty } = this.state;

    const newIsDirty = isDirty.includes(name)
      ? [...isDirty]
      : [...isDirty, name];

    this.setState({
      message: value,
      isDirty: newIsDirty,
    });

    updateLocalDraft(name, value);

    if (hasSmartTag) {
      // handle smartag open and search feature
      const cursorPosition = target.selectionStart - 1;
      const tagsShouldOpen = this.tagsShouldOpen(value, cursorPosition);

      this.setState({ smartTagActive: tagsShouldOpen }, () => {
        this.tagSearch(value, cursorPosition);
      });
    }
  };

  getValue = () => {
    let value;

    const { messageTemplate, channelName } = this.props;
    const { isDirty, message } = this.state;

    // if the user edited this field
    if (isDirty.includes(channelName)) {
      // show the draft body
      value = message;

      // if the user has not edit the field or on page load
    } else {
      // show draft body on page reload unless it is different to template body

      const isNotEmpty = message !== '';
      const isNotSame = message !== messageTemplate;
      value = isNotEmpty && isNotSame ? message : messageTemplate;
    }
    return value;
  };

  getClassNames = () => {
    const { hasCharacterCount } = this.props;
    const { message } = this.state;
    if (!hasCharacterCount) return '';

    return message.length > messageCharacterLimit * messageTotalLimit
      ? 'error'
      : '';
  };

  render(): JSX.Element {
    const {
      channelName,
      hasCharacterCount = true,
      hasSmartTag = true,
      hasHint = true,
    } = this.props;
    const { smartTagActive, smartTagListObj, message } = this.state;

    return (
      <SmsPanelWrapper>
        {hasHint ? <Hint /> : null}
        <TextareaField>
          <textarea
            rows={12}
            name='body'
            data-tab={channelName}
            value={this.getValue() || ''}
            onChange={(evt) => this.onTextareaChanged(evt)}
            onKeyUp={this.handleKeyPressWithinTextarea}
            placeholder='Enter text here'
            className={this.getClassNames()}
          />
          {hasSmartTag && (
            <SmartTagPicker
              active={smartTagActive}
              smartTagListObj={smartTagListObj}
              onClick={this.handleChosenSmartTag}
              onKeyUp={this.handleChosenSmartTag}
            />
          )}
        </TextareaField>
        {hasCharacterCount ? <MessageCounter message={message} /> : null}
      </SmsPanelWrapper>
    );
  }
}

import React, { ReactElement, ReactNode } from 'react';

import { ResponsivePie } from '@nivo/pie';

import { Icons } from '../../../Foundation/Icons';
import { DonutChartPropTypes, InnerIconPropTypes } from './DonutChart.types';
import { StyledDonutChart } from './DonutChart.styles';

export const InnerIcon = ({ name }: InnerIconPropTypes): ReactElement => {
  const Icon = Icons[name];
  const iconClass = name.toLowerCase();

  return (
    <div className={`svg-wrapper ${iconClass}`}>
      <Icon />
    </div>
  );
};

export const DonutChart = ({
  data,
  colors,
  iconName,
  iconBackgroundColor,
  ...otherProps
}: DonutChartPropTypes): ReactNode => (
  <StyledDonutChart
    className='donut-wrapper'
    // These props are added to component styled with styled-component
    // to overcome Storybook Readme issue:
    // https://github.com/tuchk4/storybook-readme/issues/137#issuecomment-481307652
    data={data}
    colors={colors}
    iconName={iconName}
    iconBackgroundColor={iconBackgroundColor}
    {...otherProps}
  >
    <div className='chart'>
      <ResponsivePie
        data={data}
        colors={colors}
        enableRadialLabels={false}
        enableSlicesLabels={false}
        {...otherProps}
      />
      <div className='icon-layer'>
        {iconName && <InnerIcon name={iconName} />}
      </div>
    </div>
  </StyledDonutChart>
);

import styled from 'styled-components';
import { Colors } from '../../Foundation/Colors';
import { Fonts } from '../../Foundation/Fonts';

export const DateFormatPickerWrapper = styled.div<{
  filled?: boolean;
}>`
  padding: 16px 0 0;
  display: flex;
  flex-direction: column;
  align-items: left;

  .dropdown {
    min-width: auto;
    ${({ filled }) =>
      filled &&
      `
      background-color: ${Colors.grey_blue_1};
      ${Fonts.bodySmall};
      border-radius: 4px/2px;
      > div {
        background-color: transparent;
        border-bottom: 0 solid transparent;
        padding-left: 8px;
      }
    `}
  }
`;

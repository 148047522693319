import styled from 'styled-components';
import { Colors } from '../../Foundation/Colors';
import { Elevations } from '../../Foundation/Elevations';
import { Fonts } from '../../Foundation/Fonts';

export const StyledToneCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 264px;
  height: 220px;
  background-color: ${Colors.white};
  border-radius: 8px;
  box-sizing: border-box;
  align-items: center;
  padding: 24px;
  box-shadow: ${Elevations.elevation_1};
  transition: all 0.2s ease;
  position: relative;
  cursor: pointer;

  &:hover {
    box-shadow: ${Elevations.elevation_3};
    transform: translateY(-4px);
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 9px 13px 7px 13px;
    background-color: ${Colors.grey_blue_1};
    border-radius: 50%;
    font-size: 24px;
  }

  .title {
    ${Fonts.subheadSmall};
    padding-top: 12px;
    text-transform: capitalize;
  }

  .selector {
    padding: 16px 0;
  }

  .description {
    ${Fonts.caption};
    color: ${Colors.grey_blue_4};
    .bold {
      font-weight: 600;
    }
  }
`;

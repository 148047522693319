import React, { useState } from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import { IconButton } from '../../Navigations';
import { Icons } from '../../../Foundation/Icons';
import { TextFieldTypes } from './SearchField.types';
import { StyledSearchField } from './SearchField.style';

export const SearchField = ({
  placeholder = 'Search',
  onChange,
  hasIcon,
  searchIconPosition = 'start',
  defaultValue = '',
  showClearButtonOnOutFocus,
  ...otherProps
}: TextFieldTypes): JSX.Element => {
  const [value, setValue] = useState(defaultValue);

  const handleChange = (newValue: string) => {
    if (onChange) {
      onChange(newValue);
    }

    setValue(newValue);
  };

  return (
    <StyledSearchField
      className='search-field'
      placeholder={placeholder}
      fullWidth
      type='search'
      onChange={(evt) => handleChange(evt.target.value)}
      startAdornment={
        hasIcon && searchIconPosition === 'start' ? (
          <InputAdornment position='end'>
            <Icons.Search />
          </InputAdornment>
        ) : null
      }
      endAdornment={
        <div className='end-adornment'>
          {value && (
            <IconButton
              className={`clear-search-button ${
                showClearButtonOnOutFocus ? 'show-clear-when-inactive' : ''
              }`}
              ariaLabel='clear'
              size='small'
              icon='CloseCircle'
              onClick={() => handleChange('')}
            />
          )}
          {hasIcon && searchIconPosition === 'end' && (
            <Icons.Search className='search-icon' />
          )}
        </div>
      }
      value={value}
      {...otherProps}
    />
  );
};

import React, { useState, useCallback } from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import { StepButton } from '@material-ui/core';
import { Button } from '../../Atoms/Navigations';
import { AccordianStepperProps } from './AccordianStepper.types';
import {
  StyledIcon,
  StyledAccordianStepper,
  StepExtraInfo,
} from './AccordianStepper.styles';

export const AccordianStepper = (props: AccordianStepperProps) => {
  const {
    steps,
    onNextClick,
    onFinishClick,
    actionsOnLastStep = true,
    nonLinear = false,
    isValid = true,
    className = '',
    showStepButtons = true,
  } = props;
  const [activeStep, setActiveStep] = useState(0);

  const handleNextClick = useCallback(() => {
    if (activeStep < steps.length - 1) {
      if (onNextClick) {
        onNextClick(activeStep);
      }
      setActiveStep(activeStep + 1);
    }
  }, [activeStep, steps, onNextClick]);

  const handlePrevClick = useCallback(() => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1);
    }
  }, [activeStep]);

  const handleButtonClick = useCallback(
    (i) => {
      if (nonLinear) {
        setActiveStep(i);
      }
    },
    [nonLinear]
  );

  return (
    <StyledAccordianStepper className={className}>
      <Stepper
        orientation='vertical'
        activeStep={activeStep}
        nonLinear={nonLinear}
      >
        {steps.map((v, i) => {
          if (activeStep < 0 || activeStep >= steps.length) {
            throw new Error(
              'activeStep is less than 0 or greater than number of steps'
            );
          }
          const isFirstStep = i === 0;
          const isLastStep = i === steps.length - 1;
          const allowNextClick = v.isOptional || nonLinear || v.isValid;
          const isActive = i === activeStep;

          return (
            <Step key={typeof v.title === 'string' ? v.title : `Step_${i}`}>
              <StepButton
                onClick={() => handleButtonClick(i)}
                disabled={!nonLinear}
                icon={<StyledIcon isValid={v.isValid} isActive={isActive} />}
              >
                <StepLabel>
                  <span className={`step-title ${isActive ? 'active' : ''}`}>
                    <span className='title'>{v.title} </span>
                    {v.isOptional && (
                      <span className='optional'>(optional)</span>
                    )}
                  </span>
                  <span className='step-description'>{v.description}</span>
                </StepLabel>
              </StepButton>
              {v.extraInfo && <StepExtraInfo>{v.extraInfo}</StepExtraInfo>}
              <StepContent
                className={`step-content ${isActive ? 'active' : ''}`}
              >
                <div>
                  {v.content}
                  {showStepButtons && (
                    <div className='buttons'>
                      {!isFirstStep && (
                        <Button
                          variant='link'
                          text='Back'
                          startIcon=''
                          onClick={handlePrevClick}
                        />
                      )}

                      {actionsOnLastStep && isLastStep && (
                        <Button
                          size='medium'
                          text='Finish'
                          onClick={onFinishClick}
                          disabled={!allowNextClick || !isValid}
                        />
                      )}

                      {!isLastStep && (
                        <Button
                          size='medium'
                          text='Next'
                          onClick={handleNextClick}
                          disabled={!allowNextClick}
                        />
                      )}
                    </div>
                  )}
                </div>
              </StepContent>
            </Step>
          );
        })}
      </Stepper>
    </StyledAccordianStepper>
  );
};

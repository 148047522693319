import React, { PureComponent } from 'react';
import { Icon } from '../../Atoms/Icon';
import {
  YesNoButtonsButton,
  LabelWrapper,
  ImageWrapper,
  IconWrapper,
  StyledYesNoButtonsWrapper,
  ButtonTextWrapper,
  HelperTextWrapper,
} from './FormYesNoButtons.style';
import {
  FormYesNoButtonsProps,
  FormYesNoButtonsState,
  OptionsPickerType,
  YesNoString,
} from './FormYesNoButtons.types';

// keeping this as a PureComponent since eventually it
// will be updated to use draftail
export class FormYesNoButtons extends PureComponent<
  FormYesNoButtonsProps,
  FormYesNoButtonsState
> {
  constructor(props: FormYesNoButtonsProps) {
    super(props);
    this.state = {
      value: null,
    };
  }

  onClickHandler = (selection: YesNoString) => {
    this.setState({ value: selection });
  };

  render(): React.ReactNode {
    const {
      label,
      requiredField,
      helperText,
      customIcon,
      alignment,
      iconAlignment,
      yesIconSrc,
      noIconSrc,
      iconColor,
      hideIcon,
      paddingTop,
      paddingBottom,
      buttonColor,
      borderRadius,
      buttonBorderColor,
      buttonTextColor,
      optionsPicker: defaultValue,
    } = this.props;

    const defaultListItems: OptionsPickerType =
      typeof defaultValue === 'string'
        ? JSON.parse(defaultValue)
        : defaultValue;

    const requiredLabel = label + (requiredField === 'true' ? ' *' : '');
    const defaultYesIcon = 'ThumbsUp';
    const defaultNoIcon = 'ThumbsDown';
    const yesButtonLabel = defaultListItems[0].text;
    const noButtonLabel = defaultListItems[1].text;
    const selected = this.state.value;
    const yesString: YesNoString = 'yes';
    const noString: YesNoString = 'no';

    const renderButtonLayout = (buttonType: YesNoString) => {
      const buttonLabel =
        buttonType === yesString ? yesButtonLabel.trim() : noButtonLabel.trim();
      const defaultIcon =
        buttonType === yesString ? defaultYesIcon : defaultNoIcon;
      const iconSrc = buttonType === yesString ? yesIconSrc : noIconSrc;
      const iconSrcAlt =
        buttonType === yesString ? 'Yes Button Icon' : 'No Button Icon';

      const labelLayout = [
        <ButtonTextWrapper key={buttonLabel}>{buttonLabel}</ButtonTextWrapper>,
      ];

      const defaultIconLayout = [
        <IconWrapper iconColor={iconColor} key={defaultIcon}>
          <Icon icon={defaultIcon} />
        </IconWrapper>,
      ];

      const customIconLayout = [
        <ImageWrapper key={iconSrc}>
          {iconSrc !== '' && <img src={iconSrc} alt={iconSrcAlt} />}
        </ImageWrapper>,
      ];

      if (hideIcon === 'false') {
        if (customIcon === 'false') {
          return [...defaultIconLayout, ...labelLayout];
        }
        return [...customIconLayout, ...labelLayout];
      }
      return [...labelLayout];
    };

    const yesButtonLayout = renderButtonLayout(yesString);
    const noButtonLayout = renderButtonLayout(noString);
    return (
      <StyledYesNoButtonsWrapper
        paddingTop={paddingTop}
        paddingBottom={paddingBottom}
        alignment={alignment}
      >
        <LabelWrapper>{requiredLabel}</LabelWrapper>
        <YesNoButtonsButton
          buttonColor={buttonColor}
          borderRadius={borderRadius}
          buttonBorderColor={buttonBorderColor}
          buttonTextColor={buttonTextColor}
          selected={selected}
          onClick={() => this.onClickHandler(yesString)}
          className='YesButton'
        >
          {iconAlignment === 'right'
            ? yesButtonLayout.reverse()
            : yesButtonLayout}
        </YesNoButtonsButton>
        <YesNoButtonsButton
          buttonColor={buttonColor}
          borderRadius={borderRadius}
          buttonBorderColor={buttonBorderColor}
          buttonTextColor={buttonTextColor}
          selected={selected}
          onClick={() => this.onClickHandler(noString)}
          className='NoButton'
        >
          {iconAlignment === 'right'
            ? noButtonLayout.reverse()
            : noButtonLayout}
        </YesNoButtonsButton>
        <LabelWrapper>
          <HelperTextWrapper>{helperText}</HelperTextWrapper>
        </LabelWrapper>
      </StyledYesNoButtonsWrapper>
    );
  }
}

import styled from 'styled-components';

export const LabelWrapper = styled.div<{ maxWidth: string }>`
  max-width: ${({ maxWidth }) => maxWidth};

  .responsive-label {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0;
  }

  .has-tooltip {
    cursor: pointer;
  }
`;

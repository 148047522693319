import styled from 'styled-components';
import { Colors } from '../../Foundation/Colors';
import { Fonts } from '../../Foundation/Fonts';

export const HeadingContainer = styled.div`
  width: 100%;
  text-align: left;
  margin-top: 60px;

  .tov-feedback-heading {
    ${Fonts.subhead}
  }
`;

export const StyledDescription = styled.div`
  display: flex;
  flex-direction: column;
  ${Fonts.body};

  .tov-feedback-body-label {
    padding-bottom: 12px;
    margin-top: 32px;
  }

  .tov-feedback-text-area {
    display: flex;
    width: 100%;
    padding-bottom: 34px;
  }

  .MuiChip-root {
    margin: 6px 4px;
  }
`;

export const ToneFeedbackButtonWrapper = styled.div`
  text-align: right;
  padding-top: 8px;

  > div:first-child {
    padding-right: 16px;
  }
`;

export const StyledAgreement = styled.div`
  text-align: center;
  position: relative;
  left: 16px;
`;

export const StyledBody = styled.div`
  margin: 24px 0;
  font-family: Capisce-Display;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
`;

export const StyledFooter = styled.div`
  ${Fonts.body}
`;

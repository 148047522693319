import React from 'react';

import { StyledMenuItem } from './MenuItem.style';
import { MenuItemProps } from './MenuItem.types';

export const MenuItem = ({ title, pathname, count }: MenuItemProps) => {
  const isActive = window.location.pathname === pathname;

  return (
    <StyledMenuItem active={isActive}>
      <a href={pathname}>
        {title}
        <span className='count'>{count}</span>
      </a>
    </StyledMenuItem>
  );
};

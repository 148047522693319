import { Colors } from '../../../Foundation/Colors';
import { ModelType } from '../../utilityTypes';

export const richTextButtonGroupBlockModel = (id: string): ModelType => ({
  id,
  type: 'ButtonGroup',
  label: 'Button Group',
  properties: [
    {
      name: 'button group general',
      label: 'Styles',
      control: 'TitleDivider',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'textColor',
      label: 'Button Text Colour',
      control: 'ColorPicker',
      value: Colors.white,
      __typename: 'ComponentProperty',
    },
    {
      name: 'backgroundColor',
      label: 'Background Colour',
      control: 'ColorPicker',
      value: Colors.white,
      __typename: 'ComponentProperty',
    },
    {
      name: 'borderRadius',
      label: 'Border Radius',
      control: 'Slider',
      value: '5',
      __typename: 'ComponentProperty',
    },
    {
      name: 'buttonMargin',
      label: 'Space Between Buttons',
      control: 'Slider',
      value: '5',
      __typename: 'ComponentProperty',
    },
    {
      name: 'fixedWidth',
      label: 'Button Fixed Width',
      control: 'Slider480',
      value: '140',
      __typename: 'ComponentProperty',
    },
    {
      name: 'vertStacked',
      label: 'Stack vertically',
      control: 'ToggleSwitch',
      value: 'false',
      __typename: 'ComponentProperty',
    },
    {
      name: 'padding_8px_4',
      label: '',
      control: 'PaddingControl8px',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'text',
      label: 'Button Options',
      control: 'TitleDividerNoLine',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'linkGenerator',
      label: 'Options Picker',
      control: 'LinkGenerator',
      value: [
        {
          id: 1,
          text: 'Yes',
          link: 'Enter a link',
          background: 'blue',
        },
        {
          id: 2,
          text: 'No',
          link: 'Enter a link',
          background: 'red',
        },
        {
          id: 3,
          text: 'Maybe',
          link: 'Enter a link',
          background: 'orange',
        },
      ],
      __typename: 'ComponentProperty',
    },
    {
      name: 'padding_8px_3',
      label: '',
      control: 'PaddingControl8px',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'padding',
      label: 'Padding',
      control: 'TitleDividerNoLine',
      value: '',
      __typename: 'ComponentProperty',
    },
    {
      name: 'paddingTop',
      label: 'Top',
      control: 'Slider',
      value: '20',
      __typename: 'ComponentProperty',
    },
    {
      name: 'paddingBottom',
      label: 'Bottom',
      control: 'Slider',
      value: '20',
      __typename: 'ComponentProperty',
    },
  ],
  __typename: 'Component',
});

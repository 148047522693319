import React, { useRef, useState } from 'react';

import { Button } from '../../Atoms/Navigations';
import { convertBase64 } from './Utils';
import { FileSelectTypes } from './FileSelect.types';
import { FileSelectWrapper } from './FileSelect.styles';

export const FileSelect = ({
  acceptedExtension = '',
  blobType = 'raw',
  getBlob,
  maxSizeByte = '10000000000',
}: FileSelectTypes): JSX.Element => {
  const inputElement = useRef(null);
  const [fileName, setFileName] = useState('');
  const [fileSize, setFileSize] = useState('');

  const handleInputChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { currentTarget: { files } = {} } = event;
    const file = files && files[0];
    setFileSize(file ? file.size.toString() : '');
    setFileName(file ? file.name : '');

    if (file && maxSizeByte && file.size > parseInt(maxSizeByte)) return null;

    if (blobType === 'base64') {
      const base64 = await convertBase64(file);

      return (base64 as string).split(',').pop();
    }

    return file;
  };

  const simulateClick = ({ current }) => current.click();
  const maxSize = maxSizeByte || '10000000000';
  const sizeLimit = parseInt(fileSize) > parseInt(maxSize);

  return (
    <FileSelectWrapper>
      <input
        data-testid='file-select-input'
        type='file'
        ref={inputElement}
        name='files'
        onChange={async (event) => getBlob(await handleInputChange(event))}
        accept={acceptedExtension}
      />
      <Button
        variant='link'
        onClick={() => simulateClick(inputElement)}
        text={`Select${fileName && !sizeLimit ? 'ed' : ''} File`}
        startIcon='Upload'
      />
      <span className='file-name'>
        {sizeLimit ? (
          <span className='error'>{`${fileName} is over the size limit`}</span>
        ) : (
          fileName
        )}
      </span>
    </FileSelectWrapper>
  );
};

FileSelect.displayName = 'FileSelect';

import React from 'react';

import { DividerWrapper } from './Divider.styles';
import { DividerPropsTypes } from './Divider.types';

export const Divider = ({
  dividerType,
  height,
  lineColor,
  isHorizontal,
  paddingControl,
  className = '',
}: DividerPropsTypes) => (
  <DividerWrapper
    className={`divider-form-control ${className}`}
    dividerType={dividerType}
    height={height}
    lineColor={lineColor}
    isHorizontal={isHorizontal}
    paddingControl={paddingControl}
  >
    <div className='divider-line' />
  </DividerWrapper>
);

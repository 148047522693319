import styled from 'styled-components';

import { Colors } from '../../../../../index';

export const BetweenNumberWrapper = styled.div<{
  error?: boolean;
}>`
  display: flex;
  flex-direction: column;

  .icon-position > button {
    pointer-events: ${(props) => (props.error ? 'none' : 'all')};

    svg {
      fill: ${(props) => props.error && Colors.grey_blue_3};
    }
  }
`;

export const AndWrapper = styled.div`
  margin-top: 4px;
  margin-bottom: 4px;
`;

import React, { ReactNode } from 'react';

import { Link } from 'react-router-dom';
import { Button } from '../../Atoms/Navigations';
import { Colors } from '../../Foundation/Colors';
import { HomepageBannerWrapper, BannerImage } from './HomepageBanner.styles';
import BackgroundImageSource from './assets/placeholderImage.svg';

export type HomepageBannerProps = {
  title?: string;
  bodyText?: React.ReactNode;
  buttonText?: string;
  baseUrl?: string;
  backgroundColor?: string;
  textColor?: string;
  hasButton?: boolean;
  hasRouterLink?: boolean;
  onClick?: () => void;
  bannerHeight?: string;
  image?: ReactNode;
  imageHeight?: string;
};

export const HomepageBanner = ({
  title = 'Title text',
  bodyText = 'Cras montes amet fusce. Turpis quis porta platea! Conubia primis metus, magnis viverra ante quisque.',
  buttonText = 'Example button text',
  baseUrl = 'example url',
  image = <BackgroundImageSource />,
  hasButton = true,
  backgroundColor = 'white',
  textColor = Colors.grey_blue,
  hasRouterLink = true,
  onClick = () => undefined,
  bannerHeight = '300px',
  imageHeight,
}: HomepageBannerProps): JSX.Element => (
  <HomepageBannerWrapper
    hasButton={hasButton}
    backgroundColor={backgroundColor}
    textColor={textColor}
    bannerHeight={bannerHeight}
  >
    <div className='content-wrapper'>
      <h1>{title}</h1>
      <p>{bodyText}</p>
      {hasRouterLink ? (
        <Link to={`${baseUrl}`}>
          <Button
            size='medium'
            type='primary'
            variant='button'
            text={buttonText}
          />
        </Link>
      ) : (
        <Button
          tabIndex={0}
          size='medium'
          type='primary'
          variant='button'
          text={buttonText}
          onClick={onClick}
        />
      )}
    </div>
    <BannerImage className='homepage-banner__image' imageHeight={imageHeight}>
      {image}
    </BannerImage>
  </HomepageBannerWrapper>
);

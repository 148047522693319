import styled from 'styled-components';

export const StyledToneOfVoiceSettingsCardList = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
`;

export const StyledListItem = styled.div`
  margin-bottom: 8px;
`;

import { Popover } from '@material-ui/core';

import styled from 'styled-components';
import { Colors } from '../../Foundation/Colors';
import { Fonts } from '../../Foundation/Fonts';

export const StyledPopover = styled(Popover)`
  .MuiPaper-root {
    margin-left: 0;
    padding: 8px 0px;
    min-width: 240px;
  }

  .filter-picker-items {
    ${Fonts.body}
    display: flex;
    align-items: center;
    padding: 5px 12px;

    svg {
      height: 16px;
      width: 16px;
    }

    &:hover {
      background: ${Colors.grey_blue_1};
    }

    > span {
      margin-left: 12px;

      &.no-icon {
        margin-left: 36px;
      }
    }
  }
`;

export const StyledWrapper = styled.div`
  > div {
    margin: 5px;
    padding: 0;
  }

  .MuiChip-root {
    max-width: 300px;

    &.invalid-filter {
      background-color: ${Colors.error_light};

      .MuiChip-label,
      svg {
        color: ${Colors.error};
      }
    }
  }
`;

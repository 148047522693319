import React, { createContext, useState, PropsWithChildren } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { ComponentType } from '../../../Contexts/global.types';

import {
  DragAndDropContextType,
  DragAndDropProviderProps,
} from './DragAndDropContext.types';

const initialState: DragAndDropContextType = { activeComponent: undefined };

export const DragAndDropContext = createContext({});

const onDragEnd = (onDragEndSupplied, setState) => (result: DropResult) => {
  const component = onDragEndSupplied ? onDragEndSupplied(result) : null;
  if (component) setState({ activeComponent: { ...component } });
};

export const DragAndDropProvider = ({
  onDragEnd: onDragEndSupplied,
  onComponentUpdate,
  children,
}: PropsWithChildren<DragAndDropProviderProps>) => {
  const [state, setState] = useState(initialState);

  const setComponents = (args) => {
    setState(args);

    if (onComponentUpdate) {
      onComponentUpdate();
    }
  };

  return (
    <DragAndDropContext.Provider value={[state, setComponents]}>
      <DragDropContext onDragEnd={onDragEnd(onDragEndSupplied, setState)}>
        {children}
      </DragDropContext>
    </DragAndDropContext.Provider>
  );
};

export const updateDragAndDropItems = (
  activeComponent: ComponentType,
  name: string,
  val: string | number
) => {
  const { properties = [] } = activeComponent || {};
  const updatedComponent = {
    ...activeComponent,
    properties: [...properties],
  };

  const { properties: newProperties } = updatedComponent;

  newProperties.forEach((currentProperty) => {
    const { name: currentName } = currentProperty;
    if (currentName === name) {
      currentProperty.value = val.toString();
    }
  });

  return updatedComponent;
};

import React from 'react';
import { StyledReplyNode } from './BaseOnReplyNode.styles';

type ReplyTypes = 'response' | 'no-response';

export const BaseOnReplyNode = ({
  type,
  label,
}: {
  type: ReplyTypes;
  label: string;
}) => {
  return <StyledReplyNode className={`on-${type}`}>{label}</StyledReplyNode>;
};

import { Popover } from '@material-ui/core';

import styled from 'styled-components';

import { Chip } from '../../Atoms/DataDisplay/Chip';
import { Colors } from '../../Foundation/Colors';
import { Fonts } from '../../Foundation/Fonts';

export const MAX_WIDTH_BEFORE_TRUNCATION = 370;

export const StyledVariableChip = styled(Chip)`
  &.MuiChip-root {
    max-width: ${MAX_WIDTH_BEFORE_TRUNCATION}px;
  }
`;

// https://stackoverflow.com/questions/20804016/editing-input-type-search-pseudo-element-button-x
export const StyledPopoverMenu = styled(Popover)`
  .MuiPopover-paper {
    width: 432px;
    max-height: 368px;
    border-radius: 8px;
    position: absolute;
  }

  .search-field-wrapper {
    background: ${Colors.white};
    position: sticky;
    top: 0;
    padding: 16px 16px 0 16px;

    > div {
      > input::-webkit-search-cancel-button {
        -webkit-appearance: none;
        background-color: ${Colors.grey_blue_4};
        background-repeat: no-repeat;
        padding: 1px;
        background-position: center center;
        background-size: 12px 12px;
        background-image: -webkit-gradient(
            linear,
            left top,
            right bottom,
            from(transparent),
            color-stop(0.455, transparent),
            color-stop(0.455, #fff),
            color-stop(0.55, #fff),
            color-stop(0.55, transparent),
            to(transparent)
          ),
          -webkit-gradient(linear, right top, left bottom, from(transparent), color-stop(0.455, transparent), color-stop(0.455, #fff), color-stop(0.55, #fff), color-stop(0.55, transparent), to(transparent)) !important;
        border: 2px solid ${Colors.grey_blue_4};
        box-sizing: border-box;
        height: 14px;
        width: 14px;
        display: inline-block;
        vertical-align: middle;
        border-radius: 10px;
        cursor: pointer;
      }
    }
  }

  .content-wrapper {
    padding-top: 16px;
    overflow-y: auto;
  }

  .no-result-found {
    padding: 8px;
    text-align: center;
    color: ${Colors.grey_blue_4};
    ${Fonts.body}
  }
`;

export const StyledVariableGroup = styled.div`
  padding-bottom: 8px;

  .chip-wrapper {
    display: flex;
    flex-direction: column;
    width: fit-contet;
  }
`;

export const StyledButton = styled.button`
  background-color: white;
  border: none;
  text-align: start;
  padding: 8px 0 8px 16px;

  &:hover,
  &:focus {
    background-color: ${Colors.grey_blue_1};
    cursor: pointer;
  }
`;

export const StyledGroupTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 0 16px 8px 16px;

  .group-title {
    font-size: ${Fonts.capsHeading};
    color: ${Colors.grey_blue_4};
    text-transform: uppercase;
  }

  .group-icon {
    padding-right: 8px;
    svg {
      width: 16px;
      height: 16px;
      fill: ${Colors.grey_blue_4};
    }
  }

  .group-counter--wrapper {
    flex: 2;
    text-align: right;

    .counter-badge {
      background-color: ${Colors.grey_blue_1};
      border-radius: 2em;
      color: ${Colors.grey_blue};
      display: inline-block;
      ${Fonts.caption}
      padding: 2px 8px;
      text-align: center;
    }
  }
`;

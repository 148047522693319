// this formComponentTypes will be deprecated once we decided to make a full transition to content builder
// TODO: remove fallback when fully migrated using content builder
export const formComponentTypes = Object.freeze({
  FORM_TEXT_INPUT: 'FormTextInput',
  FORM_TEXT_AREA: 'FormTextArea',
  FORM_DATE_PICKER: 'FormDatePicker',
  FORM_RADIO_LIST: 'FormRadioList',
  FORM_CHECKBOX_LIST: 'FormCheckboxList',
  FORM_DROPDOWN: 'FormDropdown',
  FILTER_DATE_PICKER: 'FilterDatePicker',
  FORM_NUMERIC_SLIDER: 'FormNumericSlider',
  FORM_YES_NO_BUTTONS: 'FormYesNoButtons',
});

export const formComponentRemasteredTypes = Object.freeze({
  REMASTERED_FORM_TEXT_INPUT: 'form-text-input',
  REMASTERED_FORM_TEXT_AREA: 'form-text-area',
  REMASTERED_FORM_DATE_PICKER: 'form-date-input',
  REMASTERED_FORM_RADIO_LIST: 'form-radio-list',
  REMASTERED_FORM_CHECKBOX_LIST: 'form-checkbox-list',
  REMASTERED_FORM_DROPDOWN: 'form-dropdown',
  REMASTERED_FORM_NUMERIC_SLIDER: 'form-slider',
  REMASTERED_FORM_YES_NO_BUTTONS: 'form-yes-no-buttons',
  REMASTERED_FORM_LOCATION: 'form-location',
  REMASTERED_FORM_RATING: 'form-ratings',
});

export type FormComponent =
  | 'FormTextInput'
  | 'FormTextArea'
  | 'FormDatePicker'
  | 'FormRadioList'
  | 'FormCheckboxList'
  | 'FormDropdown'
  | 'FilterDatePicker'
  | 'FormNumericSlider'
  | 'FormYesNoButtons';

export type FormComponentRemastered =
  | 'form-text-input'
  | 'form-text-area'
  | 'form-radio-list'
  | 'form-checkbox-list'
  | 'form-date-input'
  | 'form-dropdown'
  | 'form-yes-no-buttons'
  | 'form-ratings'
  | 'form-slider'
  | 'form-location';

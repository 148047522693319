import styled from 'styled-components';
import { Colors } from '../../Foundation/Colors';
import { Fonts } from '../../Foundation/Fonts';

export const MainWrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .headline {
    ${Fonts.headline};
    color: ${Colors.grey_blue};
    margin-top: 16px;
    margin-bottom: 4px;
  }
  .body {
    ${Fonts.body};
    color: ${Colors.grey_blue_4};
    margin-bottom: 16px;
    max-width: 480px;
    text-align: center;
  }
`;

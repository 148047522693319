import React, { useState } from 'react';

import moment from 'moment';
import { format as dateFnsFormat } from 'date-fns';
import { Dropdown } from '../Forms/Dropdown';
import { DateFormatPickerWrapper } from './DateFormatPicker.style';

type Props = {
  exampleDate?: Date;
  dateLibrary?: 'moment' | 'date-fns';
  label?: string;
  onChange?: (value: string) => void;
  filled?: boolean;
  includeFormatInLabel?: boolean;
  defaultFormat?: string;
};

export const DateFormatPicker = ({
  exampleDate = new Date(2019, 11, 31),
  dateLibrary = 'moment',
  onChange = () => undefined,
  label = 'Date Format',
  filled = false,
  includeFormatInLabel,
  defaultFormat,
}: Props) => {
  const momentFormats = [
    { value: 'DD/MM/YY' },
    { value: 'DD/MM/YYYY' },
    { value: 'DD MMM[,] YYYY' },
    { value: 'DD MMMM[,] YYYY' },
    { value: 'MM/DD/YY' },
    { value: 'MM/DD/YYYY' },
    { value: 'MMM DD[,] YYYY' },
    { value: 'MMMM DD[,] YYYY' },
  ].map((data) => ({
    ...data,
    label: `${moment(exampleDate).format(data.value)} ${
      includeFormatInLabel ? `(${data.value})` : ''
    }`.toLowerCase(),
  }));
  const dateFnsFormats = [
    { value: 'dd/MM/yy' },
    { value: 'dd/MM/yyyy' },
    { value: 'dd MMM, yyyy' },
    { value: 'dd MMMM, yyyy' },
    { value: 'MM/dd/yy' },
    { value: 'MM/dd/yyyy' },
    { value: 'MMM dd, yyyy' },
    { value: 'MMMM dd, yyyy' },
  ].map((data) => ({
    ...data,
    label: `${dateFnsFormat(exampleDate, data.value)} ${
      includeFormatInLabel ? `(${data.value})` : ''
    }`.toLowerCase(),
  }));

  const dateLibraryMap = {
    moment: momentFormats,
    'date-fns': dateFnsFormats,
  };

  const formats = dateLibraryMap[dateLibrary];
  const [value, setValue] = useState(defaultFormat || formats[0].value);

  const handleChange = (value: string) => {
    setValue(value);
    onChange(value);
  };

  return (
    <DateFormatPickerWrapper
      filled={filled}
      className='dateformat-form-control'
    >
      <Dropdown
        id={label}
        label={label}
        value={value}
        options={formats}
        onChange={handleChange}
        variant={filled ? 'filled' : 'normal'}
      />
    </DateFormatPickerWrapper>
  );
};

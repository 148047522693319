import styled from 'styled-components';

export const AccordionGroupWrapper = styled.div`
  margin-left: 24px;

  .MuiPaper-root {
    background-color: inherit;
  }
`;

export const MenuItemWrapper = styled.div`
  line-height: 42px;
`;

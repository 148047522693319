import styled from 'styled-components';
import { Colors } from '../../Foundation/Colors';
import { Fonts } from '../../Foundation/Fonts';

export const PaginationWrapper = styled.div`
  background: ${Colors.white};
  display: flex;

  &.border-top {
    border-top: 1px solid ${Colors.grey_blue_1};
  }

  .section {
    width: 50%;
    display: flex;
    align-items: center;
    ${Fonts.caption};
    color: ${Colors.grey_blue_4};
  }

  .offset {
    justify-content: flex-end;
  }

  .limit {
    padding-left: 12px;
  }

  .dropdown-filled {
    width: 56px;

    .dropdown__indicators {
      margin: 1px 4px 0 0;
    }

    .MuiFormControl-root {
      background: transparent;

      .dropdown__control {
        padding: 4px 8px;
      }
    }

    .dropdown__option {
      ${Fonts.caption};
      color: ${Colors.grey_blue_4};
    }

    .dropdown__single-value {
      ${Fonts.caption};
      color: ${Colors.grey_blue_4};
      margin-top: 1px;
    }
  }
`;

import React from 'react';
import { Button } from '../Button';
import { Icon } from '../../Icon';
import { StyledBreadcrumbs, StyledBreadcrumbsLinks } from './Breadcrumbs.style';
import { BreadcrumbsType } from './Breadcrumbs.types';

export const Breadcrumbs = ({ links, onBackClick }: BreadcrumbsType) => {
  return (
    <StyledBreadcrumbs aria-label='breadcrumbs'>
      {onBackClick && (
        <Button
          text='Back'
          startIcon='ChevronLeft'
          onClick={onBackClick}
          variant='link'
          type='secondary'
          aria-label='breadcrumb-back'
        />
      )}
      <StyledBreadcrumbsLinks
        separator={
          <Icon
            data-testid='Chevron Right Separator'
            className='breadcrumbs-separator'
            icon='ChevronRight'
          />
        }
      >
        {links?.map(({ title, onClick }) => (
          <Button
            key={title}
            text={title}
            onClick={onClick}
            variant='link'
            type='secondary'
            aria-label={`breadcrumb-${title.toLowerCase()}`}
          />
        ))}
      </StyledBreadcrumbsLinks>
    </StyledBreadcrumbs>
  );
};

import React, { PureComponent, Fragment } from 'react';

import {
  Droppable,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
} from 'react-beautiful-dnd';
import {
  Components,
  ComponentTitle,
  ComponentIcon,
  ComponentBox,
  Clone,
} from './DroppableSourceItems.style';
import { DroppableSourceItemsProps } from './DroppableSourceItems.types';

export class DroppableSourceItems extends PureComponent<DroppableSourceItemsProps> {
  static displayName: string;

  renderDraggable = ({ Icon, title }) => (
    provided: DraggableProvided,
    snapshot: DraggableStateSnapshot
  ) => (
    <Fragment>
      <ComponentBox
        data-component='box'
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        isDragging={snapshot.isDragging}
        style={provided.draggableProps.style}
      >
        <ComponentIcon data-component='icon'>
          <Icon />
        </ComponentIcon>
        <ComponentTitle data-component='title'>{title}</ComponentTitle>
      </ComponentBox>
      {snapshot.isDragging && (
        <Clone data-component='clone'>
          <ComponentIcon data-component='icon'>
            <Icon />
          </ComponentIcon>
          <ComponentTitle data-component='title'>{title}</ComponentTitle>
        </Clone>
      )}
    </Fragment>
  );

  renderDroppable = () => (
    provided: DraggableProvided,
    snapshot: DraggableStateSnapshot
  ) => {
    const { componentList } = this.props;
    const hasItems = componentList && componentList.length > 0;

    return (
      <div className='expanded-droppables'>
        <div className='expanded-droppables--container' ref={provided.innerRef}>
          {hasItems ? (
            componentList.map((component, index) => {
              const { title, type, Icon } = component;
              if (component.type === 'Heading') {
                return <p key={title}>{title}</p>;
              }
              return (
                <Draggable key={title} draggableId={type} index={index}>
                  {Icon && this.renderDraggable({ Icon, title })}
                </Draggable>
              );
            })
          ) : (
            <p>Empty list</p>
          )}
          {provided.placeholder}
        </div>
      </div>
    );
  };

  render() {
    const { componentList, isEnabled } = this.props;
    return (
      <Components disable={!isEnabled} data-component='droppable-source-items'>
        <Droppable droppableId='source' isDropDisabled>
          {this.renderDroppable()}
        </Droppable>
      </Components>
    );
  }
}

DroppableSourceItems.displayName = 'DroppableSourceItems';

import styled from 'styled-components';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Colors } from '../../Foundation/Colors';
import { Fonts } from '../../Foundation/Fonts';

export const SimpleTableRowWrapper = styled(TableRow)`
  &.MuiTableRow-root {
    &.header {
      & .cell {
        color: ${Colors.grey_blue_4};
      }
      & .cell--sort-active {
        color: ${Colors.grey_blue};
      }
    }
    &.row {
      & .cell {
        color: ${Colors.grey_blue};
      }
      &.active {
        background-color: ${Colors.accent_1};
      }
    }
  }
  display: flex;
  align-items: center;
  ${Fonts.body};
  .wrapper {
    flex: 1;
    display: flex;
    justify-content: space-around;
    justify-content: space-evenly;
    padding: 16px;

    .cell {
      flex: 1;
      padding: 0px 10px;
      text-overflow: ellipsis;
    }
  }
`;

export const SimpleTableCell = styled(TableCell)`
  &.radio-table-cell {
    width: 60px;
    margin: 0 auto;
    background-size: 0px 0px;
  }
  &.right-actions-table-cell {
    background-size: 70% 100%;
    width: 72px;
    margin: 0 auto;
  }

  .cell {
    justify-content: flex-start;
  }

  .cell.ellipsis {
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    overflow: hidden;
    display: block;
  }

  .right-render {
    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    path {
      fill: ${Colors.grey_blue_4};
    }
  }

  .left-render,
  .right-render {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .cell {
    display: flex;
    align-items: center;
    ${Fonts.body};
  }

  .sort-action {
    height: 10px;
    width: 10px;
    margin-left: 10px;
    display: flex;
    align-items: center;
    svg {
      max-width: 100%;
      max-height: 100%;
    }

    path {
      fill: ${Colors.grey_blue_3};
    }
  }

  .sort--asc,
  .sort--desc {
    path {
      fill: ${Colors.accent};
    }
  }
`;

export const MultiSelectWrapper = styled.div`
  display: flex;
  width: 300%;
  line-height: 60px;

  svg {
    height: 58px;
    margin-left: 10px;
    margin-right: 5px;
    fill: ${Colors.grey_blue_4};
  }

  span:nth-child(2) {
    color: ${Colors.grey_blue_4};
  }
`;

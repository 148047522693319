import { localStorageLookup } from '../config';
import { readFromLocalStorage, writeToLocalStorage } from '../localStorage';
import {
  checkForContextChange,
  getSelectedWorkspaceId,
  mapData,
} from './userWorkspacesUtils';

export function checkForChangeInUserWorkspaces(userWorkspaces, userContext) {
  const {
    user: currentUser,
    workspaces: currentWorkspaces,
    defaultWorkspace,
  } = userWorkspaces;
  // update the context, if the user or workspaces have changed
  const localWorkspaces = readFromLocalStorage(localStorageLookup.shared);

  // Find the currently selected workspace
  const selectedWorkspaceId = getSelectedWorkspaceId({
    currentWorkspaces,
    localWorkspaces,
    defaultWorkspace,
  });

  // set the context for the current user + workspace information
  const newData = mapData({ currentWorkspaces, selectedWorkspaceId });
  return checkForContextChange({
    currentUser,
    newData,
    localWorkspaces,
    userContext,
  });
}

export function saveUserWorkspaces({ currentUser, newData, setUserContext }) {
  setUserContext({ user: currentUser, workspaces: newData });
  // Store the data for later use.
  writeToLocalStorage(newData, localStorageLookup.shared);
}

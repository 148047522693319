import React, { useCallback, useRef, useState } from 'react';

import { Variable } from '@whispir/variables';
import { DropdownAdornment } from '../../Atoms/Forms/DropdownAdornment';
import { VariableSelectorPopover } from '../VariableSelectorPopover';
import { VariableSelectorPopoverTypes } from '../VariableSelectorPopover/VariableSelectorPopover.types';

type VariableSelectorProps = {
  onVariableSelect: (variable: Variable) => void;
  variableGroups: VariableSelectorPopoverTypes['variableGroups'];

  anchorOrigin?: VariableSelectorPopoverTypes['anchorOrigin'];
  buttonClassName?: string;
  popoverClassname?: string;
  tooltipText?: string;
  ariaLabel?: string;
};

export const VariableSelector = ({
  buttonClassName,
  popoverClassname,
  onVariableSelect,
  anchorOrigin,
  variableGroups,
  tooltipText,
  ariaLabel = 'Select Variable',
}: VariableSelectorProps): JSX.Element => {
  const ref = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);
  const handleVariableSelect = useCallback(
    (variable) => {
      setOpen(false);
      onVariableSelect(variable);
    },
    [onVariableSelect]
  );
  return (
    <>
      <DropdownAdornment
        className={buttonClassName}
        ref={ref}
        onClick={() => setOpen(!open)}
        tooltipText={tooltipText}
        ariaLabel={ariaLabel}
      />
      <VariableSelectorPopover
        className={popoverClassname}
        onClose={() => setOpen(false)}
        anchorEl={ref.current}
        open={open}
        onVariableSelect={handleVariableSelect}
        variableGroups={variableGroups}
        anchorOrigin={anchorOrigin}
      />
    </>
  );
};

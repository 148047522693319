import styled from 'styled-components';

import { richTextEditorMenuStyles } from '../../DraftailComponents/richTextEditorMenuStyles';
import { Colors } from '../../Foundation/Colors';
import { StyledRichArticleBlockProps } from './RichArticleBlock.types';

export const DraftailEditorWrapper = styled.div<StyledRichArticleBlockProps>`
  position: relative;
  ${richTextEditorMenuStyles}

  display: grid;
  grid-template-columns: 1fr 1fr;
  min-height: 250px;
  width: 100%;

  padding: ${(props) => props.padding}px;
  background-color: ${(props) => props.backgroundColor};
  margin-top: ${(props) => props.paddingTop}px;
  margin-bottom: ${(props) => props.paddingBottom}px;

  .variable-selector {
    position: absolute;
    right: 12px;
    bottom: 12px;
  }

  .Draftail-block {
    &--unstyled,
    &--header-one,
    &--header-two,
    &--header-three {
      font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
      color: ${(props) => props.textColor};
    }
  }

  .Draftail-Editor {
    width: 100%;
    ${({ textBoth }) => textBoth === 'true' && 'overflow-x: auto;'}
  }

  .public-DraftEditor-content {
    &:focus {
      cursor: text;
    }
  }

  .DraftEditor-editorContainer,
  .public-DraftEditorPlaceholder-inner {
    padding: 0 20px 20px;
  }

  .DraftEditor-editorContainer {
    text-align: ${({ alignment }) => alignment};
  }

  .public-DraftEditorPlaceholder-root {
    position: relative;
  }

  .public-DraftEditorPlaceholder-inner {
    color: ${Colors.grey_blue_4};
    font-style: italic;
    position: absolute;
    pointer-events: none;
    // !important to overide inline styles on draftail placeholder text
    white-space: nowrap !important;
    left: ${({ alignment }) =>
      (alignment === 'left' && '0') || (alignment === 'center' && '50%')};
    right: ${({ alignment }) => alignment === 'right' && '0'};
    transform: ${({ alignment }) =>
      alignment === 'center' && 'translate(-50%, 0)'};
  }

  ${({ imageBoth, textBoth, padding }) =>
    imageBoth === 'true' &&
    textBoth !== 'true' &&
    `
    div:nth-child(1) {
      padding-right: ${padding / 2}px;
    }

    div:nth-child(2) {
      padding-left: ${padding / 2}px;
    }
  `}
`;

export const ImageWrapper = styled.div`
  img {
    max-width: 100%;
  }
  display: flex;
  justify-content: center;
  align-items: center;
`;

import styled from 'styled-components';
import { Colors } from '../../Foundation/Colors';
import { Fonts } from '../../Foundation/Fonts';

export const StyledCopyText = styled.div`
  .copy-text-wrapper {
    display: flex;
    background-color: ${Colors.background};
    border-radius: 4px;
    height: 32px;
    align-items: center;
    ${Fonts.code};

    .MuiDivider-root {
      background-color: ${Colors.grey_blue_1};
    }
  }

  .helper-text {
    ${Fonts.caption};
    color: ${Colors.grey_blue_4};
  }

  .text-to-copy {
    padding: 8px 12px;
    flex: 1;
    background-color: transparent;
    border: none;
    ${Fonts.code};
    color: ${Colors.accent_5};
    width: 100%;
    text-overflow: ellipsis;
  }

  .copy-button {
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    padding: 8px;
    cursor: pointer;

    svg {
      fill: ${Colors.grey_blue_4};
    }

    :hover {
      svg {
        fill: ${Colors.accent_5};
      }
    }
  }

  .warning-wrapper {
    display: flex;
    background-color: ${Colors.warning_light};
    padding: 12px 24px 12px 12px;
    align-items: center;
    margin-top: 24px;

    .warning-text {
      margin: 0 0 0 12px;
      ${Fonts.body_small};
      line-height: 1.36;
      color: ${Colors.grey_blue};
    }

    svg {
      fill: ${Colors.warning};
      width: 38.62px;
      height: 19px;
      padding: 0 5px;
    }
  }
`;

import styled from 'styled-components';

import { Drawer } from '@material-ui/core';
import { Colors } from '../../../Foundation/Colors';
import { Fonts } from '../../../Foundation/Fonts';
import {
  GLOBAL_NAV_DEPTH,
  NEW_GLOBAL_NAV_WIDTH,
} from '../../../Foundation/Spacing';
import { AccordionGroupWrapper } from '../../../Molecules/AccordionGroup/AccordionGroup.style';

export const StyledDrawer = styled(Drawer)`
  & .MuiDrawer-paper {
    width: 240px;
    left: -160px;
    z-index: calc(${GLOBAL_NAV_DEPTH} - 1);
    overflow-y: initial;
    transition: all 0.3s ease-in-out;
    background-color: ${Colors.grey_blue_0};
  }

  &.open,
  &.mouse-on-drawer-area:hover {
    .MuiDrawer-paper {
      left: ${NEW_GLOBAL_NAV_WIDTH};
    }
  }

  ${AccordionGroupWrapper} {
    margin: 0 24px;
  }
`;

export const DividerWrapper = styled.div`
  padding: 18px 24px 0 24px;
`;

export const Footer = styled.div`
  position: absolute;
  bottom: 0;
  height: 68px;
  margin-bottom: 20px;

  svg {
    margin-left: 22px;
    height: auto;
    width: 98px;
  }
`;

export const ModuleNameWrapper = styled.div`
  ${Fonts.subheadSmall}
  display: flex;
  margin: 26px 24px 0;
  width: fit-content;
  align-items: center;

  svg {
    width: 32px;
    height: 32px;
    padding: 8px;
    border-radius: 4px;
    background: ${Colors.accent_3};
    fill: ${Colors.accent_5};
    margin-right: 10px;
    box-sizing: border-box;
  }
`;

export const ExpandIconWrapper = styled.i`
  position: absolute;
  top: 32px;
  right: -12px;
`;

export const LinkWrapper = styled.div`
  margin-left: 24px;
  line-height: 42px;

  .MuiPaper-root {
    background-color: inherit;
  }
`;

import React from 'react';
import { Modal } from '../../Atoms/Dialogs/Modal';
import { Button } from '../../Atoms/Navigations/Button';
import { TabComponent } from '../../Molecules/TabComponent';
import { ModalHeader } from '../../Molecules/ModalHeader';
import { TemplateCard, TemplateCardProps } from '../TemplateCard/TemplateCard';
import {
  StyledTemplateSelection,
  StyledBodyContent,
  StyledStarterTemplates,
  StyledUserTemplates,
  StyledTemplatesList,
} from './TemplateSelection.styles';

type TemplateProps = TemplateCardProps & {
  id?: string;
  content: JSX.Element;
};

type TemplateSelectionProps = {
  isOpen: boolean;
  basicTemplates: Array<TemplateProps> | undefined;
  featuredTemplates: Array<TemplateProps> | undefined;
  userTemplates: Array<TemplateProps> | undefined;
  onStartFromScratchClick: () => void;
  handleExit: () => void;
};

const getId = (id: string) => {
  if (id) return id;
  return (Math.random() * 10 ** 17).toString();
};

const StarterForms = ({
  basicTemplates,
  featuredTemplates,
  onStartFromScratchClick,
}) => (
  <StyledStarterTemplates>
    <StyledTemplatesList role='template-list' aria-label='basic-template-list'>
      <TemplateCard
        title='Start From Scratch'
        onNewCardClick={onStartFromScratchClick}
        variant='new'
      />
      {basicTemplates &&
        basicTemplates.map(({ id, content, title, ...props }) => (
          <TemplateCard key={getId(id)} title={title} {...props}>
            {content}
          </TemplateCard>
        ))}
    </StyledTemplatesList>
    {featuredTemplates ? (
      <div className='featured'>
        <div className='featured-title'>Featured</div>
        <StyledTemplatesList
          role='template-list'
          aria-label='featured-template-list'
        >
          {featuredTemplates.map(({ id, content, title, ...props }) => (
            <TemplateCard key={getId(id)} title={title} {...props}>
              {content}
            </TemplateCard>
          ))}
        </StyledTemplatesList>
      </div>
    ) : null}
  </StyledStarterTemplates>
);

const MyTemplates = ({ userTemplates }) => (
  <StyledUserTemplates>
    <StyledTemplatesList role='template-list' aria-label='user-template-list'>
      {userTemplates &&
        userTemplates.map(({ id, content, title, ...props }) => (
          <TemplateCard key={getId(id)} title={title} {...props}>
            {content}
          </TemplateCard>
        ))}
    </StyledTemplatesList>
  </StyledUserTemplates>
);

export const TemplateSelection = ({
  isOpen,
  basicTemplates,
  featuredTemplates,
  userTemplates,
  onStartFromScratchClick,
  handleExit,
}: TemplateSelectionProps) => {
  const BodyContent = () => (
    <StyledBodyContent>
      <div className='title-header'>
        <div className='title'>Select A Template</div>
        <div>
          <Button
            variant='link'
            endIcon='Close'
            type='secondary'
            onClick={handleExit}
          >
            <span>Cancel</span>
          </Button>
        </div>
      </div>
      <TabComponent
        size='small'
        tabs={[
          {
            id: Math.random(),
            name: 'Starter Templates',
            content: (
              <StarterForms
                basicTemplates={basicTemplates}
                featuredTemplates={featuredTemplates}
                onStartFromScratchClick={onStartFromScratchClick}
              />
            ),
          },
          {
            id: Math.random(),
            name: 'My Templates',
            content: <MyTemplates userTemplates={userTemplates} />,
          },
        ]}
      />
    </StyledBodyContent>
  );

  return (
    <StyledTemplateSelection>
      <Modal
        isOpen={isOpen}
        isFullScreen
        headerContent={<ModalHeader title='' />}
        closeButton={<span />}
        bodyContent={<BodyContent />}
        handleExit={handleExit}
      />
    </StyledTemplateSelection>
  );
};

import React, { ReactNode } from 'react';
import { ColorIcon, IconColorPair } from '../../Atoms/ColorIcon';
import { StyledWorkflowCanvasNode } from './WorkflowCanvasNode.styles';

type WorkflowCanvasNodeProps = {
  className?: string;
  primaryIcon: IconColorPair | null;
  secondaryIcon?: IconColorPair | null;
  iconShape?: 'round' | 'diamond'; // TODO
  titleText?: string;
  descriptionText?: string;
  footerContent?: ReactNode;
  contentOverride?: ReactNode;
  errorMessage?: ReactNode;
};

/**
 * Note that this thing isn't actually a clickable node (button) itself.
 *
 * It is the content that goes inside the node.
 * @param props
 */

export const WorkflowCanvasNode = (props: WorkflowCanvasNodeProps) => {
  const {
    className = '',
    primaryIcon,
    secondaryIcon,
    iconShape = 'round',
    titleText,
    descriptionText,
    footerContent = null,
    contentOverride,
    errorMessage,
  } = props;
  return (
    <StyledWorkflowCanvasNode
      className={`${className} ${
        primaryIcon || secondaryIcon ? 'has-icon' : ''
      }`}
    >
      {primaryIcon && (
        <ColorIcon
          className={`canvas-node-icon first-icon ${
            secondaryIcon ? '' : 'solo'
          }`}
          color={primaryIcon.color}
          icon={primaryIcon.icon}
        />
      )}
      {secondaryIcon && (
        <ColorIcon
          className='canvas-node-icon second-icon'
          color={secondaryIcon.color}
          icon={secondaryIcon.icon}
        />
      )}
      {contentOverride ? (
        contentOverride
      ) : (
        <>
          <p className='title'>{titleText}</p>
          <p className={`description-text ${errorMessage && 'error'}`}>
            {errorMessage ? errorMessage : descriptionText}
          </p>
          <p className='footer-content'>{footerContent}</p>
        </>
      )}
    </StyledWorkflowCanvasNode>
  );
};

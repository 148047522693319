import styled, { css } from 'styled-components';
import { Colors } from '../../Foundation/Colors';
import { Fonts } from '../../Foundation/Fonts';

export const ToneDetectorFeedbackContainer = styled.div<{ disabled: boolean }>`
  display: flex;
  flex-direction: column;
  text-align: left;
  color: ${Colors.grey_blue};
  background-color: ${Colors.accent_1};
  padding: 16px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 8px;

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.7;
    `}
`;

export const HeadingContainer = styled.span`
  width: 100%;
  display: flex;
  text-align: left;
  margin-bottom: 5px;
`;

export const StyledHeadingText = styled.span`
  flex: 1;
  ${Fonts.link}
`;

export const StyledIcon = styled.span`
  margin-right: 8px;

  svg: {
    height: 20px;
    width: 20px;
  }
`;

export const StyledDescription = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 28px;
  ${Fonts.body};
`;

export const ToneFeedbackButtonWrapper = styled.div`
  padding-top: 8px;
`;

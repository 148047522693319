/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  makeTovArrays,
  parseTovData,
} from '../Utils/ToneOfVoice/toneOfVoiceUtils';
import {
  ToneIntensityType,
  ToneType,
  TovDataType,
} from '../Utils/ToneOfVoice/toneOfVoiceTypes';
import { TovHighlightEntityType } from './draftailUtils';

type EntityRangeType = { offset: number; length: number; key: number };
type EntityMapType = Record<
  number,
  { type: string; mutability: string; data: any }
>;

export type EntityDataType = {
  selectedTone: ToneType;
  selectedToneScore: number;
  sentenceTones: Array<{
    tone: ToneType;
    score: number;
    desiredToneIntensity: ToneIntensityType;
  }>;
  childData: {
    text: string;
    ranges: Array<EntityRangeType>;
    entityMap: EntityMapType;
  };
};

export type DraftailObjectType = {
  blocks: [
    {
      key: string;
      text: string;
      type: string;
      depth: number;
      inlineStyleRanges: Array<{
        offset: number;
        length: number;
        style: string;
      }>;
      entityRanges: Array<EntityRangeType>;
      data: unknown;
    }
  ];
  entityMap: EntityMapType;
};

// add TOV highlight entities to draftail object while keeping variable entity data in childData
export const tovHighlightDraftailObject = (
  draftailObject: DraftailObjectType,
  tovData: TovDataType,
  selectedTone: ToneType
): DraftailObjectType => {
  const { predictions, toneSettings } = tovData;
  const { toneScoreObjectArray } = makeTovArrays(toneSettings, predictions);
  const parsedPredictions = parseTovData(predictions);
  const outputDraftailObject: DraftailObjectType = { ...draftailObject };
  const entityKeyValueStore = {};

  if (
    parsedPredictions.length &&
    selectedTone &&
    toneScoreObjectArray &&
    toneScoreObjectArray.length
  ) {
    let hasHighlights = false;
    let key = Object.keys(outputDraftailObject.entityMap).length;

    const highlightedDraftailObject = {
      blocks: outputDraftailObject.blocks.map((block) => {
        let prevSentenceLength = 0;
        const foundPredictionsInBlock = parsedPredictions.filter((parsedTone) =>
          block.text.includes(parsedTone.text)
        );

        if (foundPredictionsInBlock.length > 0) {
          const entityRanges: Array<{
            offset: number;
            length: number;
            key: number;
          }> = [];

          foundPredictionsInBlock.forEach((foundPrediction) => {
            const offset = block.text.indexOf(
              foundPrediction.text,
              prevSentenceLength <= 0
                ? prevSentenceLength
                : prevSentenceLength - 1
            );

            const length = offset === -1 ? -1 : foundPrediction.text.length;

            if (offset !== -1) {
              const selectedToneScore = foundPrediction.tones.find(
                ({ tone }) => tone === selectedTone
              )?.score;

              if (selectedToneScore && selectedToneScore > 0.33) {
                hasHighlights = true;
              }

              entityKeyValueStore[key] = {
                selectedTone,
                selectedToneScore,
                sentenceTones: foundPrediction.tones.reduce(
                  (tones: any, currentTone: any) => {
                    if (currentTone.score > 0.33) {
                      const { desiredToneIntensity } =
                        toneScoreObjectArray.find((el) => {
                          return el && el.tone === currentTone.tone;
                        }) || {};

                      if (desiredToneIntensity)
                        return [
                          ...tones,
                          {
                            tone: currentTone.tone,
                            score: currentTone.score,
                            desiredToneIntensity,
                          },
                        ];
                    }

                    return tones;
                  },
                  []
                ),
                childData: {
                  text: foundPrediction.text,
                  ranges: block.entityRanges.reduce(
                    (previousEntityRanges: any, currentEntityRange: any) => {
                      if (
                        length > 0 &&
                        currentEntityRange.offset >= offset &&
                        currentEntityRange.offset + currentEntityRange.length <=
                          offset + length
                      ) {
                        return [
                          ...previousEntityRanges,
                          {
                            ...currentEntityRange,
                            offset:
                              currentEntityRange.offset - prevSentenceLength,
                          },
                        ];
                      }

                      return previousEntityRanges;
                    },
                    []
                  ),
                  entityMap: outputDraftailObject.entityMap,
                },
              };

              entityRanges.push({ offset, length, key });
              key += 1;
              prevSentenceLength += foundPrediction.text.length + 1;
            }
          });

          return {
            ...block,
            entityRanges: [...block.entityRanges, ...entityRanges],
          };
        }

        return block;
      }),
      entityMap: {
        ...draftailObject.entityMap,
        ...Object.keys(entityKeyValueStore).reduce((previousValue, key) => {
          return {
            ...previousValue,
            [key]: {
              data: entityKeyValueStore[key],
              mutability: 'IMMUTABLE',
              type: TovHighlightEntityType,
            },
          };
        }, {}),
      },
    } as DraftailObjectType;

    if (hasHighlights) {
      return highlightedDraftailObject;
    }

    return draftailObject;
  }
  return draftailObject;
};

import styled from 'styled-components';

export const StyledButtonGroup = styled.div`
  display: flex;

  &.horizontal {
    flex-flow: row no-wrap;
    align-items: center;

    &.start {
      justify-content: flex-start;
    }

    &.end {
      justify-content: flex-end;
    }

    &.center {
      justify-content: center;
    }
  }

  &.vertical {
    flex-flow: column nowrap;

    &.start {
      align-items: flex-start;
    }

    &.end {
      align-items: flex-end;
    }

    &.center {
      align-items: center;
    }
  }

  & > * {
    margin: 8px;
  }
`;
